import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { COLUMNS_TYPE } from "../../../constants/constants";
import { CustomGptService } from "../../../services/customGptServices";
import Style from "./sqlCapabilit.style";
import ConditionalRender from "src/components/conditionalRender";

export default function CapabilityColumn({ name, configData }) {
  return (
    <div>
      <Form.List name={[name, "columns"]}>
        {(fields, { add, remove }) => (
          <>
            <p>{'Columns'}</p>
            {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
              <>
                <ConditionalRender shouldRender={fields.length > 1 && idx !== 0}>
                  <Divider />
                </ConditionalRender>
                <div style={{display: 'flex'}}>
                  <div className={Style.table}>
                    <Row gutter={[16, 0]}>
                      <Col xs={23} lg={8}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent:'space-between', gap:8 }}>
                          <Form.Item valuePropName="checked" {...restField} name={[name, "is_active"]} fieldKey={[fieldKey, "is_active"]}>
                            <Checkbox/>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            label="Name*"
                            style={{ width:'100%'}}
                            rules={CustomGptService.validations(
                              { required: true, max_length_string: true },
                              "Name",
                              configData?.config?.tables?.fields?.columns?.fields?.name
                            )}
                          >
                            <Input />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col xs={24} lg={15}>
                        <Form.Item
                          {...restField}
                          name={[name, "type"]}
                          fieldKey={[fieldKey, "type"]}
                          label="Type*"
                          rules={CustomGptService.validations(
                            { required: true, max_length_string: true },
                            "Type",
                            configData?.config?.tables?.fields?.columns?.fields?.type
                          )}
                        >
                          <Select>
                            {COLUMNS_TYPE.map((item) => {
                              return <Select.Option value={item}>{item}</Select.Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          fieldKey={[fieldKey, "description"]}
                          label="Description"
                          rules={CustomGptService.validations(
                            { max_length_string: true },
                            "Description",
                            configData?.config?.tables?.fields?.columns?.fields?.description
                          )}
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div style={{ marginTop: '20px'}}>
                    <DeleteOutlined className="delete-btn" onClick={() => remove(name)} />
                  </div>
                </div>
              </>
            ))}
            <div className={Style.addRowContainer} style={{ marginTop: 0 }} onClick={() => add()}>
              <PlusCircleOutlined />
              &nbsp;<span>Add Column</span>
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
}
