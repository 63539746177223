import React from "react";
import Style from "./horizontalLabelDivider.style";

const HorizontolLabelDivider = ({ text, width = '100%', noMarginBottom = false }) => {
  return (
    <div className={Style.divider} style={{width:width, marginBottom: noMarginBottom? '0' : '32px'}}>
      <span className={Style.dividerText}>{text}</span>
      <hr className={Style.dividerBorder} />
    </div>
  );
};
export default HorizontolLabelDivider;
