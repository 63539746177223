import { notification } from 'antd';
import { useState, useEffect, } from 'react';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { AiAppApiServices } from '../services/aiAppApiServices';

export default function useAiAppContainerHook({ sessionId, history, appId, mode, clientId}) {
  const mAiApp = appId;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [singleChatSessions, setSingleChatSessions] = useState([]);
  const [singleChatSessionsDetails, setSingleChatSessionsDetails] = useState(null);
  const [multipleChatSessions, setMultipleChatSessions] = useState([]);
  const [starterConversation, setStarterConversation] = useState([]);
  const [sessionToggle, setSessionToggle] = useState("MY_CHAT");
  const [sessionLoading, setSessionLoading] = useState(false);

  const [singleChatLoaded, setSingleChatLoaded] = useState(false);
  const [multipleChatLoaded, setMultipleChatLoaded] = useState(false);
  const [starterConversationLoaded, setStarterConversationLoaded] = useState(false);

  const getSingleChatSessions = async () => {
    try {
      let type = "SINGLE_USER";
      let page = 1;
      const res = await AiAppApiServices.getsessionList(mAiApp, type, page);
      if (!sessionId && res?.data?.data?.length > 0) {
        history.push(appUrls.APP_PAGE(clientId, mAiApp, res?.data?.data?.[0]?.id, mode));
      }
      setSingleChatSessions(res.data.data);
      setSingleChatSessionsDetails(res.data);
      setSingleChatLoaded(true);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const getMultipleChatSessions = async () => {
    try {
      let type = "MULTI_USER";
      let page = 1;
      const res = await AiAppApiServices.getsessionList(mAiApp, type, page);
      if (sessionId) {
        res.data.data.forEach((item) => {
          if (item.id === sessionId) {
            setSessionToggle('MULTI_CHAT');
          }
        })
      }
      setMultipleChatSessions(res.data.data);
      setMultipleChatLoaded(true);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  }

  const getStarterConversation = async (sessionId) => {
    try {
      const res = await AiAppApiServices.getStarterConversation(mAiApp, sessionId);
      setData(res.data);
      setStarterConversation(res.data.conversation_starters);
      setStarterConversationLoaded(true);
    } catch (error) {
      const { msg, details, status } = getErrorMessageFromResponse(error);
      if (status === 403) {
        notification.error({
          message: "You are not authorized to access this app",
          duration: 6,
          placement: "bottomRight"
        });
        history.push(appUrls.HOMEPAGE);
        window.location.reload();
      }
      notification.error({
        message: msg,
        duration: 6,
        placement: "bottomRight"
      });
    }
  };

  const addNewSession = async (data) => {
    let tempArr = singleChatSessions
    if (singleChatSessions.length === 5) {
      tempArr = singleChatSessions.slice(0, singleChatSessions.length - 1);
    }
    setSingleChatSessions([data, ...tempArr]);
  }

  const renameSession = async (data, idx) => {
    let tempArr = singleChatSessions
    tempArr[idx] = data;
    setSingleChatSessions([...tempArr]);
  }

  const scrollSingleSesion = async () => {
    try {
      if (singleChatSessionsDetails?.current_page < singleChatSessionsDetails?.total_pages) {
        setSessionLoading(true);
        let type = "SINGLE_USER";
        let page = singleChatSessionsDetails?.current_page + 1;
        const res = await AiAppApiServices.getsessionList(mAiApp, type, page)
        setSingleChatSessionsDetails(res.data)
        setSingleChatSessions([...singleChatSessions, ...res.data.data]);
        setSessionLoading(false);
      }
    } catch (error) {
      
    }
  }

  const getsessionHistory = async () => {
    try {
      await getStarterConversation();
      await getSingleChatSessions();
      if (!data.is_private && appId !== "5a6907c5-5441-4643-8d8f-978c70dfe30f") {
        await getMultipleChatSessions();
      } else {
        setMultipleChatLoaded(true);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  }

  useEffect(() => {
    getsessionHistory();
  }, [])

  useEffect(()=>{
    if (starterConversationLoaded && singleChatLoaded && multipleChatLoaded) {
      setLoading(false);
    }
  }, [starterConversationLoaded, singleChatLoaded, multipleChatLoaded])

  return {
    loading,
    singleChatSessions,
    multipleChatSessions,
    starterConversation,
    sessionToggle,
    setSessionToggle,
    addNewSession,
    sessionLoading,
    scrollSingleSesion,
    renameSession,
    data
  }
}