import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Divider, Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import ConditionalRender from "src/components/conditionalRender";
import { COLUMNS_TYPE_GOOGLE_SHEET } from "src/modules/CustomGpt/constants/constants";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./googleSheetCapability.style";

export default function StructuredData({ name, configData }) {
  return (
    <>
      <div className={Style.columnsHeader}>Columns</div>
      <div>
        <Form.List name={[name, "structured_data", "columns"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                <>
                  <Row gutter={[20, 0]} style={{ marginLeft: "1rem" }}>
                    <Col span={1}>
                      <Form.Item valuePropName="checked" {...restField} name={[name, "is_active"]} fieldKey={[fieldKey, "is_active"]}>
                        <Checkbox style={{ marginTop: "35px" }} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        label="Name"
                        rules={CustomGptService.validations(
                          { required: true, max_length_string: true },
                          "Name",
                          configData?.config?.files?.fields?.structured_data?.fields?.columns?.fields?.name
                        )}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "data_type"]}
                        fieldKey={[fieldKey, "data_type"]}
                        label="Data type"
                        rules={CustomGptService.validations(
                          { required: true, max_length_string: true },
                          "Data Type",
                          configData?.config?.files?.fields?.structured_data?.fields?.columns?.fields?.data_type
                        )}
                      >
                        <Select style={{ width: "100%", borderRadius: "5px" }}>
                          {COLUMNS_TYPE_GOOGLE_SHEET.map((item) => {
                            return <Select.Option value={item}>{item}</Select.Option>;
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        fieldKey={[fieldKey, "description"]}
                        label="Description"
                        rules={CustomGptService.validations(
                          { max_length_string: true },
                          "Description",
                          configData?.config?.files?.fields?.structured_data?.fields?.columns?.fields?.description
                        )}
                      >
                        <TextArea autoSize={{ minRows: 1, maxRows: 5 }} />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <DeleteOutlined className="delete-btn" style={{ marginTop: "40px" }} onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  <ConditionalRender shouldRender={idx !== fields.length - 1}>
                    <Divider />
                  </ConditionalRender>
                </>
              ))}
              <div className={Style.addRowContainer} onClick={() => add({is_active: true})}>
                <PlusCircleOutlined />
                &nbsp;<span>Add column</span>
              </div>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
}
