import { css } from "@linaria/core";

const appIconContainer = css`
  border-radius:50%;
  background:var(--color-full-black);
  display:flex;
  justify-content:center;
  align-items:center;
  img {
    width:50%;
    height:50%;
  }
`;

export default {
  appIconContainer,
}