import { notification } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import AppUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse } from "src/lib/utils";
import audienceListService from "../../services/audienceListService";

export default function useAudienceListingHook(id, history) {
  const [loading, setLoading] = useState(true);
  const [audienceListData, setAudienceListData] = useState([]);
  const [audienceListTotal, setAudienceListTotal] = useState(0);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [isAudienceDetailsOpen, setIsAudienceDetailsOpen] = useState(false);
  const debouncedSearch = useRef();
  const timeRef = useRef();

  const filterRef = useRef({
    currentPage: 1,
    searchName: '',
    filterType: '',
    sortBy: 'created_on',
    isAscending: false,
    statusFilter: '',
  });

  const handleAudienceClick = (audience) => {
    history.push(AppUrls.AUDIENCE_DETAIL(id, audience.id));
  };

  const handleCloseAudienceDetails = () => {
    setIsAudienceDetailsOpen(false);
    setSelectedAudience(null);
  };

  const convertAudienceData = (data) => {
    return data.map((item, index) => ({
      key: (index + 1).toString(),
      id: item.id,
      name: item.name,
      description: item.description,
      type: item.audience_type,
      jsonData: item.json_data,
      createdOn: moment(item.created_on).format('MMMM D, YYYY'),
      state: item.state,
      audienceSize: item.profile?.audience_size !== undefined && item.profile.audience_size !== null ? item.profile.audience_size : "N/A",
      createdBy: item.created_by.first_name + ' ' + item.created_by.last_name,
      isActive: item.is_active
    }));
  };

  const handleAudienceEdit = (audienceId) => {
    history.push(AppUrls.EDIT_AUDIENCE(id, audienceId));
  };

  const fetchData = async () => {
    try {
      const { currentPage, searchName, filterType, sortBy, isAscending, statusFilter } = filterRef.current;
      const res = await audienceListService.getAudienceList(id, currentPage, searchName, filterType, sortBy, isAscending, statusFilter);
      setAudienceListTotal(res.data.total);
      setAudienceListData(convertAudienceData(res.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleAudienceArchive = async (audienceId, action) => {
    try {
      await audienceListService.archiveAudience(audienceId, action);
      notification.success({
        message: `Audience ${action ? 'archived' : 'restored'} successfully`,
        duration: 6,
        placement: "bottomRight"
      });
      setLoading(true);
      fetchData();
    } catch (error) {
      const { name, details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = `Failed to ${action ? 'archive' : 'restore'} audience`;
      notification.error({
        key: details?.resource_name || "Error",
        message: errorMessage,
        duration: 5,
        placement: "bottomRight",
      });
    }
  };

  const refreshData = async () => {
    try {
      const { currentPage, searchName, filterType, sortBy, isAscending, statusFilter } = filterRef.current;
      const res = await audienceListService.getAudienceList(id, currentPage, searchName, filterType, sortBy, isAscending, statusFilter);
      setAudienceListTotal(res.data.total);
      setAudienceListData(convertAudienceData(res.data.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [filterRef.current]);

  useEffect(() => {
    fetchData();
    timeRef.current = window.setInterval(async () => {
      await refreshData();
    }, 10000);
    return () => {
      if (timeRef.current) {
        window.clearInterval(timeRef.current);
      }
    };
  }, []);

  const setCurrentPage = (page) => {
    filterRef.current.currentPage = page;
    refreshData();
  };

  const setSearchName = (name) => {
    filterRef.current.searchName = name;
    refreshData();
  };

  const setFilterType = (type) => {
    filterRef.current.filterType = type;
    refreshData();
  };

  const setSortBy = (sort) => {
    filterRef.current.sortBy = sort;
    refreshData();
  };

  const setIsAscending = (ascending) => {
    filterRef.current.isAscending = ascending;
    refreshData();
  };

  const setStatusFilter = (status) => {
    filterRef.current.statusFilter = status;
    refreshData();
  };

  return {
    loading,
    audienceListData,
    audienceListTotal,
    currentPage: filterRef.current.currentPage,
    setCurrentPage,
    isAudienceDetailsOpen,
    handleCloseAudienceDetails,
    selectedAudience,
    searchName: filterRef.current.searchName,
    setSearchName,
    filterType: filterRef.current.filterType,
    setFilterType,
    sortBy: filterRef.current.sortBy,
    setSortBy,
    debouncedSearch,
    isAscending: filterRef.current.isAscending,
    setIsAscending,
    handleAudienceClick,
    handleAudienceEdit,
    handleAudienceArchive,
    statusFilter: filterRef.current.statusFilter,
    setStatusFilter,
  };
}