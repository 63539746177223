import { formatNumber, formatNumberWithPercentageAndCeil } from "src/lib/utils";

export const AUDIENCE_INDEX_COLUMNS = [
  {
    title: 'Feature',
    dataIndex: 'feature_readable_name',
    key: 'feature_readable_name',
    render: (text, record) => `${record.feature_readable_name} - ${record.feature_readable_value}`,
  },
  {
    title: 'Audience Percentage',
    dataIndex: 'audience_feature_percentage',
    key: 'audience_feature_percentage',
    render: (text) => `${text === 0 ? '0%' : formatNumberWithPercentageAndCeil(text)}`,
    sorter: true,
  },
  {
    title: 'Index',
    dataIndex: 'index_value',
    key: 'index_value',
    render: (text) => `${text === 0 ? '0' : formatNumber(text)}`,
    sorter: true,
  },
  {
    title: 'Source',
    dataIndex: 'feature_source',
    key: 'feature_source',
  }
];

export const DEMOGRAPHIC = 'DEMOGRAPHIC';
export const BEHAVIOURAL = 'BEHAVIOURAL';
export const BRAND_AFFINITY = 'BRAND_AFFINITY';
export const GEOGRAPHIC = 'GEOGRAPHIC';