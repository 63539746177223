import React, { useEffect, useState } from "react";
import Style from "../../aiApp.style";
import ConditionalRender from "src/components/conditionalRender";
import { Collapse, Row, Skeleton } from "antd";
import StreamingResponseOutput from "./StreamingResponseOutput";
import MarkdownRender from "../../MarkdownRender";
import AssistantAppIcon from "src/modules/assistantAppIcon/assistantAppIcon";
import CaretIconGenerator from "src/components/CaretIconGenerator";
import AiLoader from "src/components/AiLoader";
import IconGenerator from "src/components/IconGenerator";

export default function StreamingResponse({
  data,
  userMessage,
  streamingData,
  llmOutput,
  streamingUserData,
}) {
  const [latestActionValue , setLatestActionValue] = useState('');
  const [action, setAction] = useState(0);

  useEffect(()=>{
    let actionCnt = 0;
    streamingData?.forEach((item, idx) => {
      if (["ACTION", "TREE"].includes(item.type)) {
        actionCnt += 1;
        setAction(actionCnt);
        setLatestActionValue(item.value);
      }
    });
  }, [streamingData]);

  return (
    <>
      {streamingData && <div className={Style.chatMessage}>
        <div className={Style.chatMessageContainer}>
          <div className={Style.chatDesc + ' right-align'}>
            <ConditionalRender shouldRender={streamingUserData}>
              <Row justify="end" style={{gap:8}}>
                <p className={Style.userLabel}>{streamingUserData?.first_name ? streamingUserData?.first_name + ' ' + streamingUserData?.last_name : streamingUserData?.email }</p>
                <IconGenerator initial={streamingUserData?.first_name ? streamingUserData?.first_name[0] : streamingUserData?.email?.[0]} width={24} height={24} backgroundColor="white" color="black"></IconGenerator>
              </Row>
            </ConditionalRender>
            <div className={Style.questionContainer} style={{ whiteSpace: 'pre-line' }}>{userMessage}</div>
            <div className={Style.responseFootnote}>
              <span style={{width:30, height:10, background:'var(--color-charcoal-black'}} />
            </div>
          </div>
        </div>
        <div className={Style.chatMessageContainer}>
          <div className={Style.botLogoNameContainer}>
            <AssistantAppIcon data={data} showBackground={true} size={24} theme="dark"/>
            <div className={Style.messageLabelBot}>
              <span>{data?.assistant_display_name || data?.name}</span>
            </div>
          </div>
          <div className={Style.chatDesc}>
            <ConditionalRender shouldRender={streamingData?.length > 0}>
              <ConditionalRender shouldRender={action > 0}>
                <Collapse className={'analysisCollapse'} expandIcon={({ isActive }) => <CaretIconGenerator size={16} rotate={isActive ? 180 : 0} />}>
                  <Collapse.Panel
                    header={latestActionValue ? latestActionValue : "View Analysis"}
                    extra={<AiLoader width={20} height={20}/>}
                  >
                    <StreamingResponseOutput streamingData={streamingData} />
                  </Collapse.Panel>
                </Collapse>
              </ConditionalRender>
              <div>
                <ConditionalRender shouldRender={!!llmOutput}>
                  <div className={Style.streamingResponseAccordianActionText}>
                    <MarkdownRender children={llmOutput} />
                  </div>
                </ConditionalRender>
              </div>
            </ConditionalRender>
            <div>
              <ConditionalRender shouldRender={streamingData?.length === 0}>
                <Skeleton paragraph={{ rows: 3 }} active className={Style.skeleton} />
              </ConditionalRender>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
