import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Form, Select, Tooltip } from "antd";
import React, { useCallback, useState } from "react";
import AutocompleteTextarea from "../../AutoCompleteTextArea";
import * as Styles from "./styles";

const { Option } = Select;

const OutputColumnsForm = ({ form, columns, selectedInputColumns }) => {
  const [hoveredRow, setHoveredRow] = useState(null);

  const validateOutputColumns = useCallback(async (_, outputColumns) => {
    if (!outputColumns?.length) {
      return Promise.reject(
        new Error("At least one output column is required.")
      );
    }
  }, []);

  const availableColumns = React.useMemo(() => {
    return columns.filter(
      (column) => !selectedInputColumns.includes(column.name)
    );
  }, [columns, selectedInputColumns]);

  return (
    <Form.List
      name="outputColumns"
      rules={[{ validator: validateOutputColumns }]}
    >
      {(fields, { add, remove }, { errors }) => (
        <div className={Styles.formContainer}>
          <div className={Styles.formHeader}>
            <div className={Styles.titleContainer}>
              <h3 className={Styles.formTitle}>Configure Output Columns</h3>
              <Tooltip
                overlayInnerStyle={{
                  width: "300px",
                  whiteSpace: "normal",
                  wordBreak: "break-word",
                }}
                title="Output columns define what will be produced by the analysis. Select columns and provide descriptions that can reference input columns using @column_name syntax."
                placement="right"
              >
                <InfoCircleOutlined className={Styles.infoIcon} />
              </Tooltip>
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => add()}
              className={Styles.addColumnButton}
            >
              Add Column
            </Button>
          </div>

          {fields.length === 0 ? (
            <div className={Styles.emptyState}>
              <p>
                No output columns added yet. Click "Add Column" to get started.
              </p>
            </div>
          ) : (
            <div className={Styles.columnsContainer}>
              <div className={Styles.columnHeaders}>
                <div className={Styles.headerColumn}>COLUMN</div>
                <div className={Styles.headerColumn}>DESCRIPTION</div>
                <div className={Styles.headerAction}></div>
              </div>
              <div className={Styles.scrollableContainer}>
                {fields.map((field, index) => (
                  <div
                    className={Styles.inputColumnRow}
                    key={field.key}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <Form.Item
                      name={[field.name, "name"]}
                      rules={[
                        { required: true, message: "Column is required" },
                      ]}
                    >
                      <Select
                        className={Styles.columnSelect}
                        placeholder="Output Column"
                        showSearch
                        optionFilterProp="children"
                      >
                        {availableColumns.map((col) => (
                          <Option key={col.name} value={col.name}>
                            {col.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={[field.name, "description"]}
                      rules={[
                        { required: true, message: "Description is required" },
                      ]}
                    >
                      <AutocompleteTextarea
                        value={form.getFieldValue([
                          "outputColumns",
                          field.name,
                          "description",
                        ])}
                        columns={form.getFieldValue("inputColumns") || []}
                        onChange={(value) => {
                          const outputColumns =
                            form.getFieldValue("outputColumns");
                          outputColumns[field.name].description = value;
                          form.setFieldsValue({ outputColumns });
                        }}
                        placeholder="Enter description and reference input columns using @column_name"
                      />
                    </Form.Item>

                    <Tooltip title="Delete column">
                      <DeleteOutlined
                        className={`${Styles.deleteIcon} ${
                          hoveredRow === index ? Styles.deleteIconVisible : ""
                        }`}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          )}

          {errors && (
            <Form.ErrorList errors={errors} className={Styles.errorList} />
          )}
        </div>
      )}
    </Form.List>
  );
};

export default React.memo(OutputColumnsForm);
