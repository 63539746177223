import { Col, Row } from "antd";
import React from "react";
import Style from './conversationStarters.style'

export default function ConversationStarters({ starterConversation, getResponse, sessionId, createSession }) {
  return (
    <div>
      <Row gutter={[20,20]}>
        {starterConversation?.map((item, index) => {
          return (
            <Col xs={24} lg={12} key={"conversation-starter-"+index}>
              <ConversationBox question={item} getResponse={getResponse} sessionId={sessionId} createSession={createSession}/>
            </Col>
          )
        })}
      </Row>
    </div>
  );
}

function ConversationBox({ question, getResponse, sessionId, createSession }) {
  return (
    <div className={Style.conversationBox} onClick={() => { 
      if (sessionId) {
        getResponse({message: question})
      } else {
        createSession(question);
      }
    }}>
      {question}
    </div>
  )
}
