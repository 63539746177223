import { Modal, Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ConditionalRender from "src/components/conditionalRender";
import Style from "./aiApp.style";
import Assets from "src/assets";
import useAiAppHook from "../hooks/useAiAppHook";
import AppContext from "src/context/appContext";
import LoadingSpinner from "src/components/loadingSpinner";
import QuestionTabs from "src/modules/socialInsights/components/questionAnswerSideBar/components/QuestionCard";
import SideNavBar from "src/modules/ConversationContainer/sideNavBar/components/sideNavBar/SideNavBar";
import ConversationStarters from "src/modules/ConversationContainer/conversations/components/ConversationStarters";
import StreamingResponse from "src/modules/ConversationContainer/conversations/components/StreamingResponse";
import InputContainer from "src/modules/ConversationContainer/inputContainer/InputContainer";
import FeedbackForm from "src/modules/ConversationContainer/FeedbackForm";
import FileUploadForm from "src/modules/ConversationContainer/FileUploadForm";
import Footer from "src/modules/footer/Footer";
import { PageLayoutContext } from "src/context/pageLayoutContext";
import AssistantAppIcon from "src/modules/assistantAppIcon/assistantAppIcon";
import CaretIconGenerator from "src/components/CaretIconGenerator";
import Conversation from "src/modules/ConversationContainer/conversations/components/Conversation";

export default function AiApp({
  history,
  match,
  singleChatSessions,
  multipleChatSessions,
  starterConversation,
  data,
  sessionId,
  sessionToggle,
  setSessionToggle,
  addNewSession,
  sessionLoading,
  scrollSingleSesion,
  renameSession,
  mode,
  clientId,
}) {
  const appContext = React.useContext(AppContext.Context);
  const { isNavOpen, setIsNavOpen, inDesktop, setInDesktop } = useContext(PageLayoutContext);
  const [socialInsightsExpanded, setSocialInsightExpanded] = useState(true);
  const scrollRef = React.useRef([]);
  const {
    session,
    initialLoading,
    getResponse,
    userMessage,
    generatingResponse,
    setUserMessage,
    feedbackFormModal,
    feedbackData,
    closeFeedBackmodal,
    submitFeedback,
    feedbackReaction,
    feedbackLoading,
    deleteChatHIstory,
    streamingData,
    formUploadModal,
    setFormUploadModal,
    uploadFile,
    fileList,
    deleteFile,
    filePreview,
    fileDelete,
    llmOutput,
    terminateChatProcessing,
    streamingChatID,
    terminatingStream,
    toggleSession,
    chatWindowLoading,
    streamingUserData,
    cancellationStreamingRef,
    setStreamingChatID,
    setGeneratingResponse,
    createSession,
    createNewSessionRef,
    openFeedBackFormModal,
    cancelApiCall,
    createSesionWithFile,
    loadNextPage,
    nextPageLoading,
  } = useAiAppHook({
    history,
    data,
    sessionId,
    appContext,
    addNewSession,
    mode,
    clientId,
    scrollRef
  });

  const renderNoMessageState = () => {
    return (
      <div className={Style.noMessageContainer}>
        <div className={Style.helpContainer}>
          <div className={Style.helpImage}>
            <AssistantAppIcon data={data} size={64} showBackground={false} />
          </div>
          <div className={Style.helpImage}>How can I help you today?</div>
        </div>
        <div className={Style.conversationStarterContainer}>
          <ConversationStarters
            starterConversation={starterConversation}
            getResponse={getResponse}
            sessionId={sessionId}
            createSession={createSession}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={Style.layoutContainer}>
      <ConditionalRender shouldRender={initialLoading}>
        <div className={Style.mainContainer}>
          <LoadingSpinner className={Style.sessionLoader} centered={true} />
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!initialLoading}>
        <SideNavBar
          data={data}
          deleteChatHIstory={deleteChatHIstory}
          session={session}
          fileList={fileList}
          deleteFile={deleteFile}
          fileDelete={fileDelete}
          sessionToggle={sessionToggle}
          // toggleSession={toggleSession}
          singleChatSessions={singleChatSessions}
          multipleChatSessions={multipleChatSessions}
          setSessionToggle={setSessionToggle}
          history={history}
          sessionId={sessionId}
          cancellationStreamingRef={cancellationStreamingRef}
          generatingResponse={generatingResponse}
          setStreamingChatID={setStreamingChatID}
          setGeneratingResponse={setGeneratingResponse}
          setUserMessage={setUserMessage}
          createNewSessionRef={createNewSessionRef}
          sessionLoading={sessionLoading}
          scrollSingleSesion={scrollSingleSesion}
          renameSession={renameSession}
          cancelApiCall={cancelApiCall}
          mode={mode}
          clientId={clientId}
        />
        <div className={Style.mainContainer} style={{ transform: !inDesktop && isNavOpen ? "translateX(320px)" : "translateX(0)" }}>
          <ConditionalRender shouldRender={chatWindowLoading}>
            <LoadingSpinner className={Style.sessionLoader} centered={true} />
          </ConditionalRender>
          <ConditionalRender shouldRender={!chatWindowLoading}>
            <ConditionalRender shouldRender={mode === "socialInsights"}>
              <div
                className={Style.questionTabsContainer + `${socialInsightsExpanded ? " expanded" : ""}`}
                aria-expanded={socialInsightsExpanded}
              >
                <QuestionTabs />
                <button
                  className={Style.questionTabExpandBtn + `${socialInsightsExpanded ? " expanded" : ""}`}
                  onClick={() => {
                    setSocialInsightExpanded(!socialInsightsExpanded);
                  }}
                >
                  <CaretIconGenerator size={24} color="currentColor" />
                </button>
              </div>
            </ConditionalRender>
            <div
              className={Style.chatContainer}
              onClick={() => {
                if (window.innerWidth < 700) {
                  setIsNavOpen(false);
                }
              }}
            >
              <div
                className={Style.messageContainer + `${mode === "socialInsights" ? ` ${Style.socialInsightsChatContainer}` : ""}`}
                id={"masterChat"}
                onScroll={(event) => {
                  if (event.target.scrollTop === 0) {
                    loadNextPage();
                  }
                }}
              >
                <ConditionalRender shouldRender={!!!session.length && !generatingResponse}>{renderNoMessageState()}</ConditionalRender>
                <ConditionalRender shouldRender={nextPageLoading}>
                  <div className={Style.sessionLoadingLoader} style={{ marginBottom: '0.5rem' }}>
                    <img alt="ai loader img" height={30} width={30} src={Assets.getImage("AiLoader")} />
                  </div>
                </ConditionalRender>
                {session?.map((item, idx) => {
                  return (
                    <div
                    ref={(el) => (scrollRef.current[item?.id] = el)} key={`session-${item?.id}`}
                    >
                      <Conversation
                      key={`conversation-${idx}`}
                      data={data}
                      item={item}
                      idx={idx}
                      latestConversation={idx === session.length - 1}
                      feedbackReaction={feedbackReaction}
                      openFeedBackFormModal={openFeedBackFormModal}
                      feedbackLoading={feedbackLoading}
                    />
                    </div>
                  );
                })}
                <ConditionalRender shouldRender={generatingResponse && !!userMessage}>
                  <StreamingResponse
                    data={data}
                    userMessage={userMessage}
                    streamingData={streamingData}
                    llmOutput={llmOutput}
                    streamingUserData={streamingUserData}
                  />
                </ConditionalRender>
              </div>
              <InputContainer
                setUserMessage={setUserMessage}
                getResponse={getResponse}
                generatingResponse={generatingResponse}
                userMessage={userMessage}
                data={data}
                setFormUploadModal={setFormUploadModal}
                filePreview={filePreview}
                terminateChatProcessing={terminateChatProcessing}
                streamingChatID={streamingChatID}
                terminatingStream={terminatingStream}
                sessionId={sessionId}
                createSession={createSession}
              />
              <Footer transparent={true} relativePosition={true} />
            </div>
          </ConditionalRender>
          <Modal
            maskClosable={true}
            destroyOnClose
            open={feedbackFormModal}
            onCancel={closeFeedBackmodal}
            footer={null}
          >
            <FeedbackForm
              feedbackData={feedbackData}
              submitFeedback={submitFeedback}
              data={data}
            />
          </Modal>
          <Modal
            maskClosable={true}
            destroyOnClose
            open={formUploadModal}
            onCancel={() => setFormUploadModal(false)}
            footer={null}
          >
            <FileUploadForm uploadFile={uploadFile} createSesionWithFile={createSesionWithFile} sessionId={sessionId} />
          </Modal>
        </div>
      </ConditionalRender>
    </div>
  );
}
