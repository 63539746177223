import { notification } from 'antd';
import { useState, useEffect, useContext } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CustomGptApiServices from '../services/customGptApiServices';
import AppContext from 'src/context/appContext';

export default function useUserAndPermissionHook({ id }) {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [actionLoading, setActionLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const appContext = useContext(AppContext.Context)
  const email = appContext?.metaData?.email

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await CustomGptApiServices.getUsersList(id);
      const appOwner = res.data.find((ele) => ele.is_app_owner);
      const userData = res.data.find((ele) => ele.email === email);
      const formatUserData = res.data.filter((ele) => ele.email !== email && !ele.is_app_owner);
      if (appOwner) { 
        formatUserData.unshift(appOwner);
      }
      if (userData && !userData.is_app_owner) {
        formatUserData?.unshift(userData);
      }
      setUserData(formatUserData || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }

  const roleChange = async (item, role) => {
    try {
      setActionLoading(true);
      const payload = {
        email: item?.email,
        action: "CHANGE_ROLE",
        role: role
      }
      const res = await CustomGptApiServices.changeRole(id, payload);
      notification.success({
        duration: 5,
        message: "Role changed successfully",
        placement: "bottomRight"
      })
      setUserData(res.data);
      setActionLoading(false)
    } catch (error) {
      setActionLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }

  const removeUser = async (item) => {
    try {
      setActionLoading(true);
      const payload = {
        email: item?.email,
        action: "REMOVE",
      }
      const res = await CustomGptApiServices.changeRole(id, payload);
      notification.success({
        duration: 5,
        message: "User removed successfully",
        placement: "bottomRight"
      })
      setUserData(res.data);
      setActionLoading(false);
    } catch (error) {
      setActionLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }

  const addUser = async (payload) => {
    try {
      setActionLoading(true);
      const res = await CustomGptApiServices.changeRole(id, payload);
      notification.success({
        duration: 5,
        message: "User added successfully",
        placement: "bottomRight"
      })
      setUserData(res.data);
      setActionLoading(false);
      setAddUserModal(false);
    } catch (error) {
      setActionLoading(false);
      const {msg} = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }

  useEffect(() => {
    getUsers();
  }, [])
  return {
    loading,
    userData,
    roleChange,
    removeUser,
    actionLoading,
    addUserModal,
    setAddUserModal,
    addUser
  }
}