import { Tag } from 'antd'
import React from 'react'
import { TAG_BACKGROUND_COLOR_MAP, TAG_TXT_COLOR_MAP, TAG_BORDER_COLOR_MAP } from './globalTagConstant'

function GlobalTag({color, children}) {
  return (
    <Tag color={TAG_BACKGROUND_COLOR_MAP[color]} style={{
      color:TAG_TXT_COLOR_MAP[color], 
      border:`1px solid ${TAG_BORDER_COLOR_MAP[color]}`,
      borderRadius:32,
      lineHeight:0,
      fontSize:11,
      fontWeight:700,
      margin:0,
      padding: '11px',
    }}>{children}</Tag>
  )
}

export default GlobalTag