import { getData } from "country-list";
import moment from "moment";
import { defaultOperators, toFullOption } from "react-querybuilder";

export const ListingScreenContent = {
  CREATE: "Create",
};

export const QueryBuilderScreenContent = {
  RULE: "RULE",
  GROUP: "GROUP",
};

export const statusColors = {
  "In Progress": "blue",
  Ready: "green",
  Draft: "yellow",
  Confused: "red",
};

export const rangePresets = [
  {
    label: "Last 7 Days",
    value: [moment().subtract(7, "days"), moment()],
  },
  {
    label: "Last 14 Days",
    value: [moment().subtract(14, "days"), moment()],
  },
  {
    label: "Last 30 Days",
    value: [moment().subtract(30, "days"), moment()],
  },
  {
    label: "Last 90 Days",
    value: [moment().subtract(90, "days"), moment()],
  },
];

export const defaultPlatformsSelected = [
  "news",
  "twitter",
  "facebook",
  "tiktok",
  "instagram",
  "threads",
];

export const analysisFilterObject = [
  {
    text: "News",
    value: "news",
  },
  {
    text: "Facebook",
    value: "facebook",
  },
  {
    text: "Twitter",
    value: "twitter",
  },
  {
    text: "Tiktok",
    value: "tiktok",
  },
  {
    text: "Instagram",
    value: "instagram",
  },
  {
    text: "Threads",
    value: "threads",
  },
];

export const sentimentFilterObject = [
  {
    text: "Positive",
    value: "positive",
  },
  {
    text: "Negative",
    value: "negative",
  },
  {
    text: "Neutral",
    value: "neutral",
  },
];

const musicalInstruments = [
  {
    label: "Percussion instruments",
    instruments: [
      "Clapstick",
      "Cowbell",
      "Cymbal",
      "Gong",
      "Maraca",
      "Marimba",
      "More cowbell",
      "Spoon",
      "Steelpan",
      "Tambourine",
      "Triangle",
      "Vibraphone",
      "Washboard",
      "Wood block",
      "Wooden fish",
      "Xylophone",
    ],
  },
  {
    label: "Membranophones",
    instruments: [
      "Barrel drum",
      "Bass drum",
      "Bongo drums",
      "Conga",
      "Drum",
      "Drum kit",
      "Jew's harp",
      "Octaban",
      "Samphor",
      "Snare drum",
      "Timpani",
      "Tom-tom",
    ],
  },
  {
    label: "Wind instruments",
    instruments: [
      "Accordion",
      "Air horn",
      "Bagpipe",
      "Baritone horn",
      "Bassoon",
      "Bazooka",
      "Beatboxing",
      "Blown bottle",
      "Bugle",
      "Clarinet",
      "Conch",
      "Cornet",
      "Didgeridoo",
      "Double bell euphonium",
      "Doulophone",
      "English horn",
      "Euphonium",
      "Flugelhorn",
      "Flute",
      "French horn",
      "Harmonica",
      "Irish flute",
      "Jug",
      "Kazoo",
      "Melodeon",
      "Mezzo-soprano",
      "Oboe",
      "Ocarina",
      "Pan flute",
      "Piccolo",
      "Pipe organ",
      "Recorder",
      "Saxophone",
      "Slide whistle",
      "Sousaphone",
      "Trombone",
      "Trumpet",
      "Tuba",
      "Whistle",
    ],
  },
  {
    label: "Stringed instruments",
    instruments: [
      "Aeolian harp",
      "Bandolin",
      "Banjo ukulele",
      "Cello",
      "Chapman stick",
      "Clavichord",
      "Clavinet",
      "Double bass",
      "Dulcimer",
      "Fiddle",
      "Guitar",
      "Hammered dulcimer",
      "Harp",
      "Harpsichord",
      "Lute",
      "Lyre",
      "Maguhu",
      "Mandola",
      "Mandolin",
      "Octobass",
      "Piano",
      "Sitar",
      "Ukulele",
      "Viol",
      "Violin",
      "Washtub bass",
    ],
  },
  {
    label: "Electronic instruments",
    instruments: [
      "AlphaSphere",
      "Audiocubes",
      "Bass pedals",
      "Continuum Fingerboard",
      "Croix Sonore",
      "Denis d'or",
      "Dubreq stylophone",
      "Drum machine",
      "Eigenharp",
      "Electric guitar",
      "Electronic keyboard",
      "Electronic organ",
      "EWI",
      "Fingerboard synthesizer",
      "Hammond organ",
      "Keyboard",
      "Keytar",
      "Kraakdoos",
      "Laser harp",
      "Mellotron",
      "MIDI keyboard",
      "Omnichord",
      "Ondes Martenot",
      "Otamatone",
      "Sampler",
      "Seaboard music instrument",
      "Skoog",
      "Synclavier",
      "Synthesizer",
      "Teleharmonium",
      "Tenori-on",
      "Theremin",
      "Trautonium",
      "Turntablism",
      "Turntable",
    ],
  },
].map(({ label, instruments }) => ({
  label,
  options: instruments.map((s) => ({ name: s, label: s })),
}));

const validator = (r) => !!r.value;
const countries = getData().map((country) => ({
  value: country.code,
  label: `${country.name} (${country.code})`,
}));

const fields = {
  news: [
    {
      name: "keyword",
      label: "keyword",
      placeholder: "Enter Keyword",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "news_publication",
      label: "news_publication",
      placeholder: "Enter Publisher",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "news_categories",
      label: "news_categories",
      placeholder: "Enter News Category",
      operators: defaultOperators.filter((op) => op.name === "in"), // editorType:'multiselect',
      valueEditorType: "multiselect",
      values: [
        { label: "Electronics", value: "electronics" },
        { label: "Books", value: "books" },
        { label: "Clothing", value: "clothing" },
      ],
    },
  ],
  facebook: [
    {
      name: "keyword",
      label: "keyword",
      placeholder: "Enter Keyword",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "handle",
      label: "handle",
      placeholder: "Enter Handle",
      operators: [{ name: "=", label: "=" }],
    },
  ],
  twitter: [
    {
      name: "keyword",
      label: "keyword",
      placeholder: "Enter Keyword",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "hashtag",
      label: "hashtag",
      placeholder: "Enter Hashtag",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "handle",
      label: "handle",
      placeholder: "Enter Handle",
      operators: [{ name: "=", label: "=" }],
    },
  ],
  threads: [
    {
      name: "keyword",
      label: "keyword",
      placeholder: "Enter Keyword",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "hashtag",
      label: "hashtag",
      placeholder: "Enter Hashtag",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "handle",
      label: "handle",
      placeholder: "Enter Handle",
      operators: [{ name: "=", label: "=" }],
    },
  ],
  tiktok: [
    {
      name: "keyword",
      label: "keyword",
      placeholder: "Enter Keyword",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "hashtag",
      label: "hashtag",
      placeholder: "Enter Hashtag",
      operators: [{ name: "=", label: "=" }],
    },
    {
      name: "handle",
      label: "handle",
      placeholder: "Enter Handle",
      operators: [{ name: "=", label: "=" }],
    },
  ],
};

export { musicalInstruments, validator, fields };

export const scheduleIntervals = [
  {
    label: "Every 3 hours",
    value: 3,
  },
  {
    label: "Every 5 hours",
    value: 5,
  },
  {
    label: "Every 6 hours",
    value: 6,
  },
  {
    label: "Every 12 hours",
    value: 12,
  },
  {
    label: "Daily",
    value: 24,
  },
];
