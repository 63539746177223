import React, { useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { styled } from '@linaria/react';
import { Select as AntSelect } from 'antd';
import LoadingSpinner from '../loadingSpinner';

function DebounceSelect({ fetchOptions, debounceTimeout = 700, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (search) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(search).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout])
  
  return (
    <AntSelect
      labelInValue
      filterOption={false}
      notFoundContent={fetching ? <LoadingSpinner /> : null}
      options={options}
      onSearch={debounceFetcher}
      onFocus={() => debounceFetcher('')}
      {...props}
    />
  );
}

/** @type {any} Primary button which has primary color(blue) as background and white text */
const MarkeyDebounceSelect = styled(DebounceSelect)`
  --label: ${(props) => `'${props.label || ''}'`};
  .ant-select-selector {
    height: 40px !important;
    align-items: center;
    &::before {
      content: var(--label);
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      color: #C4C4C4;
    }

    .ant-select-selection-placeholder {
      text-align: right;
    }
  }
`;

MarkeyDebounceSelect.Option = AntSelect.Option;
MarkeyDebounceSelect.OptGroup = AntSelect.OptGroup;

export default MarkeyDebounceSelect;
