import React from "react";
import useCorrelationChart from "./useCorrelationChart";
import LoadingSpinner from "src/components/loadingSpinner";
import ReactECharts from "echarts-for-react";

const CorrelationChart = ({ getFieldValue, clusterConfigId }) => {
  // Add your code here
  const { data, loading, chartOption } = useCorrelationChart({ getFieldValue, clusterConfigId });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <p>
        The correlation matrix shows the strength and direction of relationship between the selected features. Values closer to 1 suggest
        very high correlation between the variables on both axis and values closer to 0 suggest very less or no correlation. Likewise,
        positive value suggest a positive relationship and negative values suggest an inverse relationship.
      </p>
      <ReactECharts option={chartOption} style={{ height: "650px", width: "100%" }} />
    </div>
  );
};

export default CorrelationChart;
