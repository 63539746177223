import { css } from "@linaria/core";

const tableContainer = css`
  width: 100%; 
  overflow: auto;
  margin-top: 1rem;
`;

const table = css`
  border-radius: 8px;

  .ant-table-thead > tr > th {
    background: black;
    color: white;
    font-weight: 600;
  }
  .ant-table-tbody > tr > td {
    background: #eeeeee;
    color: black;
    font-weight: 400;
  }
  .ant-table-tbody tr.ant-table-row:hover td,
  .ant-table-tbody tr td.ant-table-cell- row-hover {
    background: #eeeeee;
  }
`;

export default {
  tableContainer,
  table
};
