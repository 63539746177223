import { CopyOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./googleDriveCapability.style";

export default function GoogleDriveLinks({ getGoogleDriveList, initialValues, configData, configureLoading, copyToClipboard }) {
  const newRef = React.useRef();
  const urlRegex = /^(?:https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:(?!-)[-a-z0-9]{1,63}(?<!-)\.)+(?:[a-z]{2,})(?::\d{2,5})?(?:\/\S*)?$/i;
  return (
    <div>
      <Form
        ref={newRef}
        requiredMark={false}
        initialValues={initialValues}
        onFinish={(val) => {
          getGoogleDriveList(val);
        }}
      >
        <div>
          <div className={Style.googleDriveHeader}>Description</div>
          <Form.Item
            name="google_drive_unstructured_description"
            rules={CustomGptService.validations(
              { required: true, max_length_string: true },
              "Description",
              configData?.config?.google_drive_unstructured_description
            )}
          >
            <TextArea />
          </Form.Item>
        </div>

        <Form.List name="google_drive_folder_urls">
          {(fields, { add, remove }) => (
            <>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  return (
                    <>
                      <div className={Style.googleDriveHeader}>
                        <b>Note:</b> Ensure{" "}
                        <CopyOutlined
                          onClick={() => {
                            copyToClipboard();
                          }}
                        />{" "}
                        <span style={{ padding: "4px 10px", background: "var(--color-charcoal-surface-1)", borderRadius: "0px" }}>
                          <i style={{ color: "var(--color-gale-blue)" }}>alchemyai@alchemyai-418009.iam.gserviceaccount.com</i>
                        </span>{" "}
                        has viewer access to the file
                      </div>
                      <br />
                      <div className={Style.googleDriveHeader}>Google drive folder links</div>
                      {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                        return (
                          <div style={{ marginLeft: "1rem" }}>
                            <div className={Style.googleDriveUrlContainer}>
                              <div style={{ flexGrow: 1 }}>
                                <Form.Item
                                  label={`Link ${idx + 1}*`}
                                  name={[name, "url"]}
                                  fieldKey={[fieldKey, "url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Link is required",
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (value) {
                                          if (!!!value.trim().length) {
                                            return Promise.reject(new Error("Invalid Url."));
                                          } else {
                                            if (urlRegex.test(value)) {
                                              return Promise.resolve();
                                            } else {
                                              return Promise.reject(new Error("Invalid Url"));
                                            }
                                          }
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div>
                                <DeleteOutlined className="delete-btn" style={{ marginTop: "8px" }} onClick={() => remove(name)} />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div className={Style.addRowContainer} onClick={() => add()}>
                        <PlusCircleOutlined />
                        &nbsp;<span>Add</span>
                      </div>
                    </>
                  );
                }}
              </Form.Item>
            </>
          )}
        </Form.List>
        <Row justify="end" style={{ marginTop: "1rem" }}>
          <SecondaryButton loading={configureLoading} onClick={() => newRef.current.submit()}>
            Sync
          </SecondaryButton>
        </Row>
      </Form>
    </div>
  );
}
