import { message, notification } from 'antd';
import { useRef, useState } from 'react';
import { getErrorMessageFromResponse, saveToClipboard } from 'src/lib/utils';
import CustomGptApiServices from 'src/modules/CustomGpt/services/customGptApiServices';

export default function useAddSchemaHook({ add, id, setSchemaModal, files}) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const getSchema = async ( data ) => {
    try {
      setLoading(true)
      const res = await CustomGptApiServices.getSchema(data, id)
      let tempRes = res.data;
      tempRes.is_active = true;
      let structuredDataColumn = tempRes?.structured_data?.columns?.map((item) => {
        return {
          ...item,
          is_active: true
        }
      }) 
      tempRes.structured_data.columns = structuredDataColumn
      add(tempRes);
      setSchemaModal(false);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }

  const checkDuplicate =  (val) => {
    let flag = false;
    for( let i = 0; i < files?.files?.length; i += 1) {
      if (files?.files[i]?.google_sheet_url === val) {
        flag = true;
        break
      }
    }
    return flag
  }

  const copyToClipboard = () => {
    try {
      let text = "alchemyai@alchemyai-418009.iam.gserviceaccount.com";
      saveToClipboard(text)
    } catch (error) {}
    message.success({
      type: "success",
      content: "Copied to clipboard",
      duration: 3,
    });
  }

  return {
    loading,
    getSchema,
    formRef,
    checkDuplicate,
    copyToClipboard
  }
}