import { Col, Form, InputNumber, Row, Select } from "antd";
import React from "react";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";

export default function SqlAgentConfig({ configData }) {
  return (
    <>
      <Row gutter={[30, 0]}>
        <Col xl={12} xs={24}>
          <Form.Item 
            name={["sql_assistant_agent_config", "agent_type"]} 
            label="Agent type*"
            rules={CustomGptService.validations(
              { required: true },
              "Agent type",
            )}
          >
            <Select>
              {configData?.config?.sql_assistant_agent_config?.agent_type?.choices?.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item 
            name={["sql_assistant_agent_config", "max_iterations"]} 
            label="Max iterations*"
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Max iterations",
              configData?.config?.sql_assistant_agent_config?.max_iterations
            )}
          >
            <InputNumber style={{width:'100%'}}/>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item 
            name={["sql_assistant_agent_config", "chat_history_window"]} 
            label="Chat history window*"
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Chat history window",
              configData?.config?.sql_assistant_agent_config?.chat_history_window
            )}
          >
            <InputNumber style={{width:'100%'}}/>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["sql_assistant_agent_config", "intermediate_steps_chat_history_window"]}
            label="Number of previous analysis in chat history*"
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Number of previous analysis in chat history",
              configData?.config?.sql_assistant_agent_config?.intermediate_steps_chat_history_window
            )}
          >
            <InputNumber style={{width:'100%'}}/>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
