import { DownloadOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import CenteredAiLoader from "src/components/centeredAiLoader";
import { transformData } from "../../constants/constants";
import Charts from "./Charts";
import "./DashboardMP.less"; // Create a separate CSS file for styling
import OutputTable from "./OutputTable";
import { useDashboardMP } from "./useDashboardMP";
const { Content } = Layout;

function removeKeyProperty(data) {
  return data.map(({ key, ...rest }) => rest);
}

const DashboardMP = () => {
  const { mediaPlanId } = useParams();

  const { loading, data } = useDashboardMP({ id: mediaPlanId });
  const transformedData = transformData(data);

  const newData = removeKeyProperty(transformedData);

  return (
    <Layout className="layout">
      <Content className="page-container">
        {loading ? (
          <CenteredAiLoader
            text={
              "Hang tight! We're generating your media plan. This may take a few minutes."
            }
          ></CenteredAiLoader>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "32px",
              }}
            >
              {data && (
                <div>
                  <h2 style={{ marginBottom: 0 }}>{data?.name} </h2>
                  <h4> {data?.objective} </h4>
                </div>
              )}
              {data && data.length !== 0 && (
                <Button type="primary" icon={<DownloadOutlined />}>
                  <CSVLink
                    style={{ color: "#fff", marginLeft: "8px" }}
                    data={newData}
                    filename={"media_plan.csv"}
                  >
                    Download CSV
                  </CSVLink>
                </Button>
              )}
            </div>
            {data && <Charts data={data} />}
            <OutputTable data={data} />
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default DashboardMP;
