import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppUrls from "src/constants/appUrls";
import ClientSpace from "./components/clientSpace/ClientSpace";
import ConditionalRender from "src/components/conditionalRender";
import AiAppContainer from "../aiApps/components/AiAppContainer";
import CustomGpt from "../CustomGpt/components/CustomGpt";
import useClientSpaceContainerHook from "./useClientSpaceContainerHook";
import NewsAndCultureLayout from "../newsAndCulture/NewsAndCultureLayout";
import LoadingSpinner from "src/components/loadingSpinner";
import { PageLayoutContext } from "src/context/pageLayoutContext";
import AppContext from 'src/context/appContext';
import AudienceToolLayout from "../audienceTool/audienceToolLayout/AudienceToolLayout";
import ClusterToolLayout from "../audienceTool/clusterTool/components/clusterToolLayout/ClusterToolConfigLayout";
import MediaPlanLayout from "../audienceTool/audienceManager/components/mediaPlan/MediaPlanLayout";
import DataLabelerLayout from '../labeler/Layout';
import AddEditClientSpace from "../addNewClientSpace/components/AddEditClientSpace";

export default function ClientSpaceContainer({ history, match, allClients, addUpdateClient }) {
  const clientId = match?.params?.clientId;
  const appContext = React.useContext(AppContext.Context);
  const { loading } = useClientSpaceContainerHook();
  const { setInSingleAppMode } = useContext(PageLayoutContext);
  const [singleAppChecked, setSingleAppChecked] = useState(false);

  const checkIfSingleApp = (clientData) => {
    const aiAppCount = clientData?.ai_apps.length;
    const microAppCount = clientData?.micro_apps.length;
    const totalAppCount = aiAppCount + microAppCount;
    return totalAppCount === 1 && aiAppCount === 1;
  }

  useEffect(() => {
    if (appContext?.clientSpaceData != null) {
      const clientData = appContext?.clientSpaceData;
      if (allClients && allClients.length === 1 && checkIfSingleApp(clientData) && !appContext.metaData?.email?.includes("galepartners.com")) {
        setInSingleAppMode(true);
        history.push(AppUrls.APP_PAGE(clientId, clientData?.ai_apps[0]?.id));
      } else if (allClients && !checkIfSingleApp(clientData)) {
        setInSingleAppMode(false);
      }
      if (!loading) {
        setSingleAppChecked(true);
      }
    }
  }, [loading, appContext?.clientSpaceData])


  return (
    <>
      <ConditionalRender shouldRender={!singleAppChecked}>
        <LoadingSpinner centered={true} ></LoadingSpinner>
      </ConditionalRender>
      <ConditionalRender shouldRender={singleAppChecked}>
        <Switch>
          <Route exact path={AppUrls.CLIENT_HOME(":clientId")} component={ClientSpace} />
          <Route exact path={AppUrls.APP_PAGE(":clientId", ":id")} component={AiAppContainer} />
          <Route
            exact
            path={[
              AppUrls.CREATE_GPT(":clientId"),
              AppUrls.EDIT_CUSTOM_GPT(":clientId", ":id", ":mode"),
            ]}
            component={CustomGpt}
          />
          <Route path={AppUrls.EDIT_CLIENT_SPACE(":clientId")} children={<AddEditClientSpace history={history} addUpdateClient={addUpdateClient}/>} allClients={allClients}  />
          <Route path={AppUrls.AUDIENCE_TOOL(":clientId")} component={AudienceToolLayout} />
          <Route path={AppUrls.NEWS_CULTURE(":clientId")} component={NewsAndCultureLayout} />
          <Route path={AppUrls.CLUSTER_TOOL(":clientId")} component={ClusterToolLayout} />
          <Route path={AppUrls.MEDIA_PLAN_LISTING_LAYOUT(":clientId")} component={MediaPlanLayout} />
          <Route path={AppUrls.LABELER(":clientId")} component={DataLabelerLayout} />
          <Redirect from="*" to={AppUrls.CLIENT_HOME(clientId)} />
        </Switch>
      </ConditionalRender>
    </>
  );
}
