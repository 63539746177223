import { css } from '@linaria/core';

const header = css`
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
`;

const container = css`
  
`;

const connectionContainer = css`
  margin-bottom: 1rem;
  padding-right:20px;
`;

const label = css`
  margin-right: 1rem;
  font-size: 14px;
  color: gray;
  font-weight: 500;
`;

const fileprocessedContainer = css`
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  width:100%;
  justify-content:flex-end;
  gap:8px;
  color: var(--color-gale-gray);
  padding-right:20px;
`;


export default {
  header,
  container,
  connectionContainer,
  label,
  fileprocessedContainer,
}