import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { formatBreadcrumb } from '../utils';

const isId = (segment) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(segment);
};

const NcBreadCrumb = ({ className, name, onClick, screen }) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((item) => item);

  // Check if the last segment is an ID
  const lastIsId = isId(pathnames[pathnames.length - 1]);
  // Get the segments to be displayed in the breadcrumb
  const breadcrumbSegments = lastIsId ? pathnames.slice(-3, -1) : pathnames.slice(-2);

  const breadcrumbItems = breadcrumbSegments.map((value, index) => {
    // Construct URL by slicing the pathnames accordingly
    const url = lastIsId
      ? `/${pathnames.slice(0, pathnames.length - breadcrumbSegments.length + index).join('/')}`
      : `/${pathnames.slice(0, pathnames.length - breadcrumbSegments.length + index + 1).join('/')}`;

    const isLast = index === breadcrumbSegments.length - 1;

    return isLast ? (
      <Breadcrumb.Item key={url}>{formatBreadcrumb(value, name, onClick, screen)}</Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{formatBreadcrumb(value)}</Link>
      </Breadcrumb.Item>
    );
  });

  return <Breadcrumb className={className}>{breadcrumbItems}</Breadcrumb>;
};

export default NcBreadCrumb;
