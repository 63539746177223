import { useEffect, useMemo, useState } from 'react';
import NcApiServices from '../services/NcApiServices';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import appUrls from 'src/constants/appUrls';

const useListingScreen = () => {
  const [analysisList, setAnalysisList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [canCreate, setCanCreate] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const history = useHistory();
  const { clientId } = useParams();
  const options = useMemo(() => [
    { value: 'IN_PROGRESS', label: 'In Progress' },
    { value: 'IN_QUEUE', label: 'In Queue' },
    { value: 'DONE', label: 'Done' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'ERROR', label: 'Error' },
  ], []);

  const pageSize = 20;

  const getAnalysisList = async (id, page = 1, search = '', status = '', dates = []) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getAnalysisList(id, page, search, status, dates, pageSize);
      const analysisData = res?.data?.data || [];
      setTotalPages(res?.data?.total);
      setAnalysisList(analysisData);
      setFilteredData(analysisData);
      if (res?.data?.can_create_analysis) {
        setCanCreate(true);
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnalysisList(clientId, currentPage, debouncedSearchTerm, statusFilter, dateRange);
  }, [clientId, currentPage, debouncedSearchTerm, statusFilter, dateRange]);

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on filter
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
    setCurrentPage(1); // Reset to first page on filter
  };

  const handleDateFilter = (dates) => {
    setDateRange(dates);
    setCurrentPage(1); // Reset to first page on filter
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    filterData(debouncedSearchTerm, statusFilter, dateRange);
  }, [analysisList, debouncedSearchTerm, statusFilter, dateRange]);

  const filterData = (search, status, dates) => {
    let filtered = analysisList;
    if (search) {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (status) {
      filtered = filtered.filter((item) => item.job_state === status);
    }
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      filtered = filtered.filter(
        (item) => new Date(item.created_on) >= start && new Date(item.created_on) <= end
      );
    }
    setFilteredData(filtered);
  };

  const handleCardClick = (item) => {
    if (item?.can_view_analysis) {
      history.push(appUrls.ANALYSIS_OVERVIEW_SCREEN(clientId, item?.id));
    } else if (item?.job_state === 'DRAFT' || item?.job_state === null) {
      history.push(appUrls.UPDATE_LISTING_SCREEN(clientId, item?.id), { mode: 'Edit' });
    }
  };

  return {
    filteredData,
    currentPage,
    pageSize,
    searchTerm,
    statusFilter,
    dateRange,
    setCurrentPage,
    handleSearch,
    handleStatusFilter,
    handleDateFilter,
    analysisList,
    loading,
    totalPages,
    clientId,
    history,
    handleCardClick,
    options,
    canCreate,
  };
};

export default useListingScreen;
