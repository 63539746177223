import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Collapse, Form, Input, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { PrimaryButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useFineTuningToolHook from "src/modules/CustomGpt/hooks/useFineTuningToolHook";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import FineTuningAdvancedSetting from "./FineTuningAdvancedSetting";
import Style from "./fineTuningTool.style";

export default function FineTuningTool({ id, aiAppData, metaData, updateCustomApp, backToChatAppHandler }) {
  const { formRef, loading, initialValues, configdata, saveFineTuneTool, configureLoading } = useFineTuningToolHook({
    id,
    aiAppData,
    metaData,
    updateCustomApp,
  });
  return (
    <div className={Style.container}>
    
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <Form initialValues={initialValues} layout="vertical" ref={formRef} requiredMark={false} onFinish={(val) => saveFineTuneTool(val)}>
          <Row justify='end' style={{ margin: '24px 0', justifyContent:'space-between' }}>
            <div style={{ color: "white", fontSize: "1.1rem", marginBottom: "1rem" }}>Workflow Tuning Capability</div>
            <ConditionalRender shouldRender={id}>
              <Row className={Style.btnsContainer}>
                <PrimaryButton
                  className="small"
                  onClick={backToChatAppHandler}>
                  Go to assistant
                </PrimaryButton>
                <SecondaryButton
                  className="small"
                  loading={configureLoading}
                  onClick={() => formRef.current.submit()}
                >
                  Save settings
                </SecondaryButton>
              </Row>
            </ConditionalRender>
          </Row>
          <div className={Style.formContainer}>
            <Form.List name="strategies" >
              {(fields, { add, remove }) => {
                return (
                  <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional} noStyle>
                    {({ getFieldsValue }) => {
                      return (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                            const strategy = getFieldsValue(["strategies"]);
                            const strategyName = strategy?.strategies?.[idx]?.strategy_name;
                            return (
                              <div style={{ display: "flex", gap: "1rem" }}>
                                <div style={{ flexGrow: "1" }}>
                                  <Collapse className={'regularCollapse'}>
                                    <Collapse.Panel
                                      forceRender
                                      header={`${strategyName ? strategyName : `New Strategy ${idx + 1}`}`}
                                    >
                                      <Row gutter={[30, 0]}>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy name"
                                            name={[name, "strategy_name"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Strategy name",
                                              configdata?.config?.strategies?.fields?.strategy_name
                                            )}
                                          >
                                            <Input placeholder="Enter strategy name" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy scenario"
                                            name={[name, "strategy_scenario"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Strategy scenario",
                                              configdata?.config?.strategies?.fields?.strategy_scenario
                                            )}
                                          >
                                            <TextArea placeholder="Describe briefly the scenario" autoSize={{ minRows: 2, maxRows: 5 }} />
                                          </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy steps"
                                            name={[name, "strategy_steps"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Strategy steps",
                                              configdata?.config?.strategies?.fields?.strategy_steps
                                            )}
                                          >
                                            <TextArea placeholder="Strategy steps to be performed" autoSize={{ minRows: 4, maxRows: 7 }} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Collapse.Panel>
                                  </Collapse>
                                </div>
                                <div>
                                <DeleteOutlined className="delete-btn" style={{ marginTop: "12px"  }} onClick={() => remove(name)} />
                                </div>
                              </div>
                            );
                          })}
                          <div className={Style.addRowContainer} onClick={() => add({ columns: [undefined] })}>
                            <PlusCircleOutlined />
                            &nbsp;<span>Add Strategy</span>
                          </div>
                        </>
                      );
                    }}
                  </Form.Item>
                );
              }}
            </Form.List>
            <FineTuningAdvancedSetting configData={configdata} />
          </div>
          {/* <Row justify="end" style={{ marginTop: "1.5rem" }}>
            <RoundedPrimaryButton loading={configureLoading} onClick={() => formRef.current.submit()} className={Style.saveButton}>
              Save
            </RoundedPrimaryButton>
          </Row> */}
        </Form>
      </ConditionalRender>
    </div>
  );
}
