import { notification } from 'antd';
import { useState, useEffect } from 'react';
import DatasetServices from '../services/datasetServices';

export default function useEvaluationDetailHook({ evaluationId, appId }) {
    const [loading, setLoading] = useState(false);
    const [evaluationDetail, setEvaluationData] = useState(null);


    const getDataSet = async () => {
      setLoading(true);
      try {
        const res = await DatasetServices.getEvaluationDetail(evaluationId);
        setEvaluationData(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error({
          duration: 5,
          message: "Failed to Load Evaluation Data",
          placement: "bottomRight"
        });
      }
  };

  useEffect(() => {
    getDataSet();
  }, [])

  return {
        loading,
        evaluationDetail,
    }
};
