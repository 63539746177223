import {
  APPEND_TASKS,
  SET_CURRENT_PAGE,
  SET_LOADING,
  SET_LOADING_MORE,
  SET_SEARCH_TERM,
  SET_SORT_BY,
  SET_TASKS,
  SET_TOTAL_PAGES,
  UPDATE_TASKS,
} from "./actionTypes";

export const initialState = {
  tasks: [],
  loading: true,
  loadingMore: false,
  searchTerm: "",
  sortBy: "newest",
  currentPage: 1,
  totalPages: 0,
};

export function reducer(state, action) {
  switch (action.type) {
    case SET_TASKS:
      return { ...state, tasks: action.payload };
    case APPEND_TASKS:
      return { ...state, tasks: [...state.tasks, ...action.payload] };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_LOADING_MORE:
      return { ...state, loadingMore: action.payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    case SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_TOTAL_PAGES:
      return { ...state, totalPages: action.payload };
    case UPDATE_TASKS:
      const updatedTasks = action.payload;
      const taskMap = new Map(state.tasks.map((task) => [task.id, task]));
      let hasChanges = false;
      updatedTasks.forEach((updatedTask) => {
        const existingTask = taskMap.get(updatedTask.id);
        if (
          existingTask &&
          JSON.stringify(existingTask) !== JSON.stringify(updatedTask)
        ) {
          hasChanges = true;
          taskMap.set(updatedTask.id, {
            ...existingTask,
            ...updatedTask,
          });
        }
      });
      if (hasChanges) {
        return { ...state, tasks: Array.from(taskMap.values()) };
      } else {
        return state;
      }
    default:
      return state;
  }
}
