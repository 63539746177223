import { DeleteOutlined } from "@ant-design/icons";
import { Checkbox, Col, List, Row, Select } from "antd";
import React, { useContext } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import Style from "./clientSpaceSelectApp.style";
import { formatString } from "src/lib/utils";
import ConditionalRender from "src/components/conditionalRender";
import AppContext from "src/context/appContext";

const ClientSpaceAppSettings = ({
  data,
  metaData,
  elem,
  updateData,
  setAddUserModal,
  setAppConfigTab,
  configureLoading,
  addUpdateUserToMicroApp,
  updateApps,
  deleteUserFromMicroApp,
}) => {
  const appContext = useContext(AppContext.Context);
  const userEmail = appContext?.metaData?.email;

  let userMapping = {};
  [...metaData?.internal_users]?.forEach((item) => {
    userMapping[item?.email] = item;
  });

  return (
    <div className={Style.container}>
      <Row justify="space-between" style={{ marginBottom: "2rem" }}>
        <Col>
          <Checkbox
            disabled={configureLoading}
            checked={data[elem]?.assign_to_all_users || false}
            onChange={(e) => updateApps(elem, "assignAll", e.target.checked)}
          >
            Enable for All Users in Client Space
          </Checkbox>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>
            <Col>
              <SecondaryButton
                className="small"
                onClick={() => {
                  setAppConfigTab({
                    visible: true,
                    data: {
                      appType: elem,
                      jsonData: data[elem]?.app_config,
                      updateData: updateData,
                    },
                  });
                }}
              >
                App Configuration
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton
                className="small"
                onClick={() => {
                  setAddUserModal({
                    visible: true,
                    data: {
                      data: data[elem],
                      appType: elem,
                    },
                  });
                }}
              >
                Add User
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <List
        className={Style.list}
        loading={configureLoading}
        dataSource={data?.[elem]?.access || []}
        header={
          <div className={Style.listHeader}>
            <div style={{ width: "40%" }}>Email</div>
            <div style={{ width: "40%" }}>Name</div>
            <div style={{ width: "26%" }}>Role</div>
          </div>
        }
        renderItem={(item, idx) => {
          let name = "";
          if (userMapping[item?.email]?.first_name) {
            name = name + userMapping[item?.email]?.first_name;
          }
          if (userMapping[item?.email]?.last_name) {
            name = name + " " + userMapping[item?.email]?.last_name;
          }
          return (
            <div className={Style.listItem}>
              <div style={{ width: "37%" }}>{item?.email}</div>
              <div style={{ width: "37%" }}>{name}</div>
              <div style={{ width: "26%" }} className={Style.deleteContainer}>
                {metaData?.micro_apps_roles?.[elem] ? (
                  <>
                    <Select
                      onChange={(value) => {
                        addUpdateUserToMicroApp(elem, data[elem]?.id, { ...item, role: value }, "update");
                      }}
                      value={item?.role}
                      style={{ width: "80%" }}
                      className="small"
                    >
                      {metaData?.micro_apps_roles?.[elem]?.map((item) => {
                        return (
                          <Select.Option key={item?.id} value={item?.id}>
                            {formatString(item?.role)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </>
                ) : (
                  ""
                )}
                <div>{item?.email === userEmail ? "" : <DeleteOutlined onClick={() => deleteUserFromMicroApp(item?.id, elem)} />}</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ClientSpaceAppSettings;
