import React, { useEffect } from 'react';
import { Drawer, Layout, Row, Tabs, Tooltip } from 'antd';
import Style from '../NewsAndCulture.style';
import 'echarts-wordcloud';
import { OverviewContent } from '../components/OverviewContent';
import { AnalysisViewContent } from '../components/AnalysisViewContent';
import NcBreadCrumb from '../components/NcBreadCrumb';
import RawDataContent from '../components/RawDataContent';
import { InfoCircleOutlined, ExportOutlined } from "@ant-design/icons";
import { useAnalysisOverviewHook } from '../hooks/useAnalysisOverviewHook';
import JobTriggersDrawer from '../components/JobTriggersDrawer';
import moment from 'moment';
import { PrimaryButton } from 'src/components/basic';
import QuadrantChartComponent from '../components/analysisQuadrants/analysisQuadrants.component';

const AnalysisOverviewScreen = () => {
  const {
    analysisOverview,
    loading,
    jobDrawer,
    setJobDrawer,
    handleClickForName,
    latestJobTimeStamp,
    onChatButtonClick,
  } = useAnalysisOverviewHook();


  return (
    <div className={Style.newsAndCultureContainer}>
      <Row justify={"space-between"}>
        <NcBreadCrumb
          className={Style.breadCrumbCreateScreen}
          name={analysisOverview?.name}
          onClick={handleClickForName}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {latestJobTimeStamp ? (
            <div>Last refreshed on: {moment(latestJobTimeStamp?.job_triggered_at).format("LLL")}</div>
          ) : null}
          <Tooltip title="Data pulls" placement="bottom">
            <InfoCircleOutlined
              onClick={() => {
                setJobDrawer(true);
              }}
              className={Style.infoIcon}
            />
          </Tooltip>
        </div>
      </Row>
      <div className={Style.analysisParent}>
        <Tabs defaultActiveKey="1" className={Style.customOverviewTabs}>
          <Tabs.TabPane tab="Analysis View" key="1">
            {analysisOverview && <AnalysisViewContent analysisOverview={analysisOverview} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Source" key="2">
            <RawDataContent />
          </Tabs.TabPane>
          <Tabs.TabPane tab="2 x 2" key="3">
            <QuadrantChartComponent analysisOverview={analysisOverview} />
          </Tabs.TabPane>
        </Tabs>
        {analysisOverview?.advanced_settings?.ai_app_id && (
          <PrimaryButton className={Style.chatWithMeButton} onClick={onChatButtonClick}>
            Chat
            <ExportOutlined />
          </PrimaryButton>
        )}
      </div>
      <Drawer
        open={jobDrawer}
        onClose={() => setJobDrawer(false)}
        title="Data Pulls"
        width="50%"
        placement="right"
        maskClosable={true}
      >
        <JobTriggersDrawer analysisOverview={analysisOverview} />
      </Drawer>
    </div>
  );
};

export default AnalysisOverviewScreen;
