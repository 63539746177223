import React from 'react';
import Assets from 'src/assets';

function CenteredAiLoader({
    text
}) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 146px)' }}>
            <span key="loading" style={{ display: 'flex', alignItems: 'center' }}>
                {text}
                <img src={Assets.getImage("AiLoader")} alt="AI" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
            </span>
        </div>
    );
};

export default CenteredAiLoader;