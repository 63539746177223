export const CLUSTERING_TYPE = ["Acquisition", "CRM"];

export const CLUSTER_COLORS = [
  "#5470C6",
  "#91CC75",
  "#FAC858",
  "#EE6666",
  "#73C0DE",
  "#3BA272",
  "#FC8452",
  "#9A60B4",
  "#EA7CCC",
  "#3EAF7C",
  "#E57373",
  "#81C784",
  "#64B5F6",
  "#FFB74D",
  "#4DB6AC",
  "#BA68C8",
  "#7986CB",
  "#F06292",
  "#FFD54F",
  "#4FC3F7",
];

export const BLUE_SHADES = [
  "#CCE5FF", // Light Blue
  "#B3D8FF",
  "#99CCFF",
  "#80BFFF",
  "#66B3FF",
  "#4DA6FF",
  "#3399FF",
  "#1A8CFF",
  "#007FFF",
  "#0072E5",
  "#0066CC",
  "#0059B3",
  "#004D99",
  "#004080",
  "#003366",
  "#00264D",
  "#001A33",
  "#000D1A",
  "#000814",
  "#000311", // Dark Blue
];
export const FOGO_ACQUISTION =
  "We are developing customer-centric clusters for Fogo De Chao, a Brazilian Steakhouse, with the goal of identifying high-value customers. These clusters should capture distinct groups of customers based on their dining habits, spending patterns, demographics, and any other relevant behaviours. Once these high-value customer clusters are identified, we aim to find similar 'look-alike' audiences within the broader US population. The final output should include detailed cluster descriptions and personas that highlight the unique characteristics, preferences, and motivations of these customers, along with strategies for targeting these look-alike groups.";

export const FOGO_CRM =
  "We are creating customer-centric clusters for Fogo De Chao, a Brazilian Steakhouse, with the goal of identifying and profiling both high-value customers and those with the potential to become high-value customers. The objective is to retain high-value customers and develop strategies to nurture and elevate customers who show potential to increase their value. Analyze the clusters, focusing on their frequency of visits, spending patterns, and any other relevant behavioural or demographic patterns. Provide detailed descriptions and personas that capture the unique characteristics, preferences, and motivations of these two customer segments. Additionally, offer insights on actionable strategies to effectively engage, retain, and grow these groups.";

export const CHILIS_ACQUISTION =
  "We are developing customer-centric clusters for Chili's, an American casual dining restaurant, with the goal of identifying high-value customers. These clusters should capture distinct groups of customers based on their dining habits, spending patterns, demographics, and any other relevant behaviours. Once these high-value customer clusters are identified, we aim to find similar 'look-alike' audiences within the broader US population. The final output should include detailed cluster descriptions and personas that highlight the unique characteristics, preferences, and motivations of these customers, along with strategies for targeting these look-alike groups.";

export const CHILIS_CRM =
  "We are creating customer-centric clusters for Chili's, an American casual dining restaurant, with the goal of identifying and profiling both high-value customers and those with the potential to become high-value customers. The objective is to retain high-value customers and develop strategies to nurture and elevate customers who show potential to increase their value. Analyze the clusters, focusing on their frequency of visits, spending patterns, and any other relevant behavioural or demographic patterns. Provide detailed descriptions and personas that capture the unique characteristics, preferences, and motivations of these two customer segments. Additionally, offer insights on actionable strategies to effectively engage, retain, and grow these groups.";
