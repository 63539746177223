import { css } from "@linaria/core";

export const container = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const featuresGrid = css`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-top: 28px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const featureCard = css`
  background-color: var(--color-90-black);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-80-black);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  width: 280px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover:not(.disabledCard) {
    border-color: var(--color-link);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 280px;
  }
`;

export const selectedCard = css`
  border-color: var(--color-link) !important;
  background-color: rgba(220, 90, 75, 0.1) !important;
`;

export const disabledCard = css`
  opacity: 0.6;
  cursor: not-allowed;

  &:hover {
    transform: none;
    border-color: var(--color-80-black);
  }
`;

export const featureContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`;

export const featureIcon = css`
  font-size: 32px;
  color: var(--color-link);
  margin-bottom: 16px;
`;

export const featureTitle = css`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 8px;
`;

export const featureDescription = css`
  font-size: 14px;
  color: var(--color-60-gray);
  line-height: 1.5;
`;

export const soonBadge = css`
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: var(--color-80-black);
  color: var(--color-white);
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const inputContainer = css`
  width: 100%;
  margin-top: 24px;
  background-color: var(--color-90-black);
  padding: 24px;
  border-radius: 8px;

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-form-item-label > label {
    color: var(--color-white);
  }

  .ant-input {
    background-color: var(--color-80-black);
    border: 1px solid var(--color-70-black);
    color: var(--color-white);
    width: 100%;

    &:hover,
    &:focus {
      border-color: var(--color-link);
    }

    &::placeholder {
      color: var(--color-60-gray);
    }
  }
`;

export const infoBox = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--color-80-black);
  padding: 12px 16px;
  border-radius: 4px;
  width: 100%;
`;

export const infoText = css`
  color: var(--color-60-gray);
  font-size: 14px;
`;

export const serviceAccountContainer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const serviceAccount = css`
  color: var(--color-link);
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const copyIcon = css`
  color: var(--color-60-gray);
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;
  flex-shrink: 0;

  &:hover {
    color: var(--color-link);
  }
`;
