import { css } from "@linaria/core";

const listHeader = css`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  border: none;
  padding: 0.6rem 1rem;
  color:var(--color-gale-gray);
`;

const listItem = css`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  color: white;
  gap:8px;
`;

const columnContainer = css`
  display: flex;
  gap: 1rem ;
`;

const unsavedChanges = css`
  color: red;
  font-size: 14px;
`;

export default {
  listHeader,
  listItem,
  columnContainer,
  unsavedChanges
};
