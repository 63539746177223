import { css } from "@linaria/core";

const container = css`
  width: 100%;
  max-width: 380px;
  margin-top: 4rem;
  @media screen and (min-width: 768px) {
    max-width: 550px;
  }
  @media (max-height: 500px) {
    margin-top: 20px
  }
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const header = css`
  color: white;
  margin-bottom: 1rem;
  text-align: center;
`;

const content = css`
  width:100%;
  margin-bottom: 1rem;
`;

const button = css`
  width:100%;
  background: #000000 !important;
  color: white !important;
  font-size: 1rem;
  font-weight: 600 !important;
  border: 1px solid white !important;
  border-radius: 0px !important;
  padding: 16px 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.3s;

  &:hover {
    border-color:#DC5A4B !important;
  }

  &:focus {
    border-color:#666666 !important;
  }

  &:disabled {
    border-color:#666666 !important;
    color:#666666 !important;
  }
  @media screen and (min-width: 768px) {
    width: fit-content;
  }
`;

const greetingMsg = css `
  color:#DCDCDC;
  font-size: 1.375rem;
  margin-bottom:32px;
  @media (max-height: 500px) {
    margin-bottom: 8px
  }
`;

const emailContainer = css`
  overflow: hidden;
  transition: all 0.5s ease-in;
`;

export default {
  container,
  header,
  contentContainer,
  content,
  button,
  greetingMsg,
  emailContainer,
};
