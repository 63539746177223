import React from "react";
import Style from "./assistantAppIcon.style";
import Assets from "src/assets";
import IconGenerator from "src/components/IconGenerator";

const AssistantAppIcon = ({ data, size = 50, showBackground = true, theme = "dark" }) => {

  const Images = {
    GaleRFP: {
      icon: Assets.getImage('MicroappBradIcon'),
    },
    BackboneAssistant: {
      icon: Assets.getImage('MicroappBackboneIcon'),
    }
  }; 
  function examBardOrBackbone(name) {
    return (name === 'GaleRFP' || name === 'BackboneAssistant');
  }

  const getAppIcons = (className, iconName, fallbackName)=>{
    if (examBardOrBackbone(className)) {
      if (showBackground) {
        return (
          <div className={Style.appIconContainer} style={{minWidth:size, height:size, filter: theme ==='dark' ? 'invert(0%)' : 'invert(100%)'}}>
            <img
                alt="app icon"
                src={Images[className]?.icon}

              />
          </div>
        );
      } else {
        return (
          <img
            alt="app icon"
            src={Images[className]?.icon}
            style={{width:size, height:size}}
          />
        )
      }
    } else if(iconName && iconName.length > 0) {
      if (showBackground) {
        return <div className={Style.appIconContainer} style={{minWidth:size, height:size, background: theme ==='dark' ? 'black' : 'white', color:theme ==='dark'? 'white': 'black'}}>
          <span className="material-symbols-outlined" style={{fontSize:size*0.6}}>{iconName}</span>
        </div>
      } else {
        return <span className="material-symbols-outlined" style={{fontSize:size}}>{iconName}</span>
      }
      
    } else if(Assets.getImage(`${className}`)) {
      return (
        <img src={Assets.getImage(`${className}`)} style={{width:size, height:size}} alt="app icon"/>
      )
    }
    return (
      <div>
        <IconGenerator
          height={size}
          width={size}
          backgroundColor='transparent'
          initial={fallbackName}
        />
      </div>
    )
  }

  return (
    <>
      {getAppIcons(data?.class_name, data?.app_icon_name, data?.assistant_name?.[0]?.toUpperCase())}
    </>
  );
};
export default AssistantAppIcon;
