
import React from "react";

export default function CircleCheckIcon({ size=18, color='white', rotate=0 }) {
  const iconStyle = {
    width: `${size}px`,
    height: `${size}px`,
    display:'block',
    color: color,
    transform: `rotate(${rotate}deg)`
  }
  return (
    <div style={iconStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 17 18"
      >
        <circle cx="8.5" cy="9" r="8.5" fill="#9BBD14"></circle>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13 6.5L7.5 12 5 9.5"
        ></path>
      </svg>
    </div>
  );
}
