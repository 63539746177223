import { Col, Form, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton } from "src/components/basic";
import useSqlSettingsHook from "./useSqlSettingsHook";
import CommonStyle from "../newSqlTool.style";
import Style from "./sqlSettings.style";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";

export default function SqlSettings({ selectedConnection, sqlData, mAiApp }) {
  const { formRef, loading, initialValues, saveConnectionLevelSetting } = useSqlSettingsHook({ selectedConnection, sqlData, mAiApp });
  return (
    <div className={Style.container}>
      <div style={{ flexGrow: "1" }}>
        <Form
          initialValues={initialValues}
          ref={formRef}
          layout="vertical"
          requiredMark={false}
          onFinish={(val) => {
            saveConnectionLevelSetting(val);
          }}
        >
          <Row gutter={[30, 0]}>
            <Col xs={24} md={12}>
              <Form.Item 
                name="max_limit_per_query" 
                label="Max results per query*"
                rules={CustomGptService.validations(
                  { required: true },
                  "Max results per query",
                )}
              >
                <InputNumber style={{width:'100%'}}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                name="query_timeout" 
                label="Query timeout"
                rules={CustomGptService.validations(
                  { required: true, max_length_number: true },
                  "Query timeout",
                  {min_value: 100, max_value: 1000}
                )}
              >
                <InputNumber style={{width:'100%'}}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                name="max_column_unique_values_query_limit" 
                label="Max column unique values query limit"
                rules={CustomGptService.validations(
                  { required: true, max_length_number: true },
                  "Max column unique values query limit",
                  {min_value: 1, max_value: 10000}
                )}
              >
                <InputNumber style={{width:'100%'}}/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item 
                name="max_column_unique_values_fetch_limit" 
                label="Max column unique values fetch limit"
                rules={CustomGptService.validations(
                  { required: true, max_length_number: true },
                  "Max column unique values fetch limit",
                  {min_value: 1, max_value: 10000}
                )}
              >
                <InputNumber style={{width:'100%'}}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row justify="end" style={{ marginTop: "1rem" }}>
        <RoundedOutlinedButton loading={loading} onClick={() => formRef.current.submit()}>
          Save
        </RoundedOutlinedButton>
      </Row>
    </div>
  );
}
