import { AutoComplete, Form, Row, Select } from "antd";
import { set } from "dot-object";
import React, { useRef, useState } from "react";
import { SecondaryButton } from "src/components/basic";
import { formatString } from "src/lib/utils";

const AddUserForm = ({ setUnsavedChanges, setSelectedValues, selectedValues, metaData, setAddUserModal, updateUserAccess }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const options = [...metaData?.internal_users, ...metaData?.external_users]?.map((item) => {
    return { value: item.email, label: item.email };
  });

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const [filteredOptions, setFilteredOptions] = useState(options);

  const onFilterOptions = (val) => {
    if (val) {
      const filtered = options.filter((item) => {
        if (item.value.includes(val)) {
          return item;
        }
      });
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  const checkAlreadySelected = (email) => {
    let emails = selectedValues?.users?.map((item) => item.email);
    if (emails.includes(email)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <Form
          requiredMark={false}
          ref={formRef}
          onFinish={async (val) => {
            setLoading(true);
            await updateUserAccess("addNewUser", { email: val.email, role: val.role });
            setAddUserModal(false);
            setLoading(false);
          }}
          labelAlign="left"
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label="Email*"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (!!!value.trim().length) {
                      return Promise.reject(new Error("Email is required."));
                    } else {
                      const test = validateEmail(value);
                      if (test) {
                        if (!checkAlreadySelected(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error("User already added"));
                        }
                      } else {
                        return Promise.reject(new Error("Invalid Email"));
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <AutoComplete
              placeholder="Enter email"
              options={filteredOptions}
              onSearch={(val) => {
                onFilterOptions(val);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Role is required",
              },
            ]}
          >
            <Select placeholder="Enter Role">
              {metaData?.client_roles?.map((item) => {
                if (item?.name !== "CLIENT_OWNER") {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {formatString(item?.name)}
                    </Select.Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Row justify="end">
        <SecondaryButton loading={loading} onClick={() => formRef.current.submit()}>
          Done
        </SecondaryButton>
      </Row>
    </div>
  );
};

export default AddUserForm;
