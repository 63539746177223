import React from "react";
import Style from "./customGpt.style";
import AppUrls from "src/constants/appUrls";
import useCustomGptHook from "../hooks/useCustomGptHook";
import ConditionalRender from "src/components/conditionalRender";
import { Route, Switch } from "react-router-dom";
import CustomGptTabs from "./CustomGptTabs";
import LoadingSpinner from "src/components/loadingSpinner";

export default function CustomGpt({ history, match }) {
  const id = match?.params?.id;
  const mode = match?.params?.mode || "configure";
  const clientId = match?.params?.clientId;
  const { loading, formRef, initialValues, metaData, createGpt, tabs, setTabs, createGptLoading, updateCustomApp } = useCustomGptHook({
    id,
    history,
    mode,
    clientId,
  });

  return (
    <div className={Style.container}>
      <div className={Style.appContainer}>
        <ConditionalRender shouldRender={loading}>
          <LoadingSpinner centered />
        </ConditionalRender>
        <ConditionalRender shouldRender={!loading}>
          <Switch>
            <Route
              path={[AppUrls.CREATE_GPT(":clientId"), AppUrls.EDIT_CUSTOM_GPT(":clientId", ":id", ":mode")]}
              render={() => (
                <CustomGptTabs
                  id={id}
                  history={history}
                  tabs={tabs}
                  clientId={clientId}
                  setTabs={setTabs}
                  initialValues={initialValues}
                  formRef={formRef}
                  metaData={metaData}
                  createGpt={createGpt}
                  createGptLoading={createGptLoading}
                  updateCustomApp={updateCustomApp}
                />
              )}
            />
          </Switch>
        </ConditionalRender>
      </div>
    </div>
  );
}
