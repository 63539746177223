import React, { useState } from "react";
import useAddEditClientSpaceHook from "./useAddEditClientSpaceHook";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Button, Form, Spin, Steps, Tabs } from "antd";
import Style from "./addEditClientSpace.style";
import { AppstoreAddOutlined, CheckCircleOutlined, UserOutlined } from "@ant-design/icons";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import LoadingSpinner from "src/components/loadingSpinner";
import ClientSpaceBasicInfo from "./ClientSpaceBasicInfo";
import ClientSpaceUserAndPermissions from "./ClientSpaceUserAndPermissions";
import ClientSpaceSelectApps from "./ClientSpaceSelectApps";

const AddEditClientSpace = ({ history, addUpdateClient }) => {
  const { clientId } = useParams();
  const { tab, setTab, loading, metaData } = useAddEditClientSpaceHook();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className={Style.container}>
      <Tabs
        activeKey={tab}
        onChange={(val) => {
          setTab(val);
        }}
        destroyInactiveTabPane={true}
      >
        <Tabs.TabPane tab="Basic Info" key="configure">
          <ClientSpaceBasicInfo metaData={metaData} history={history} clientId={clientId} addUpdateClient={addUpdateClient}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Assigned Users" key="users_and_permissions" disabled={!!!clientId}>
          <ClientSpaceUserAndPermissions metaData={metaData} clientId={clientId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Configure Apps" key="configure_apps" disabled={!!!clientId}>
          <ClientSpaceSelectApps metaData={metaData} clientId={clientId} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AddEditClientSpace;
