import { css } from "@linaria/core";

const footer = css`
  color: #DCDCDC;
  justify-content: center;
  font-size: 0.714 rem;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: var(--color-full-black);
  z-index: 1000;
  align-items: center;
  position: fixed;
  bottom: 0;
  &.transparent-bg {
    background-color: transparent;
  }
  &.relative {
    position: relative;
  }

  @media (max-height: 800px) {
    position:relative;
  }
`;

export default {
  footer
};