import { notification } from "antd";
import { useRef, useState } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { AiAppApiServices } from "src/modules/aiApps/services/aiAppApiServices";

export default function useSessionRenameHook({ renameData, renameSession, data, closeRenameModal }) {
  const mAiApp = data?.id;
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const initialVlaues = {
    name: renameData?.data?.name,
  };

  const changeName = async (val) => {
    try {
      setLoading(true);
      const res = await AiAppApiServices.renameSession(mAiApp, renameData?.data?.id, val);
      renameSession(res?.data, renameData?.idx);
      setLoading(false);
      closeRenameModal();
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg || "Failed to delete the conversation",
        duration: 6,
        placement: "bottomRight"
      });
    }
  };

  return {
    formRef,
    loading,
    initialVlaues,
    changeName,
  };
}
