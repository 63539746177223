import { Modal, notification } from 'antd';
import  { useState, useEffect, useRef } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { ClusterToolApiServices } from 'src/modules/audienceTool/clusterTool/services/ClusterToolApiServices';

export default function useClusterToolListingHook({ id }) {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const filterRef = useRef({
    page: 1,
    searchQuery: ""
  });
  const [data, setData] = useState({
    total_pages: 0,
    data: []
  });
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const timeRef = useRef();
  const debouncedSearch = useRef();

  const getClustersData = async (pageNumber, queryParam="") => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getClusterListing(id, pageNumber, queryParam);
      setData(res?.data);
      setLoading(false)
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  }

  const refreshData = async () => {
    try {
      const pageNumber = filterRef.current.page;
      const queryParam = filterRef.current.searchQuery;
      const res = await ClusterToolApiServices.getClusterListing(id, pageNumber, queryParam);
      setData(res?.data);
    } catch (error) {  

    }
  }

  const changeSearchQuery =(query) => {
    setPage(1);
    filterRef.current = {
      searchQuery: query,
      page: 1
    };
    getClustersData(1, query);
  }

  const setPageNumber = (pageNumber) => {
    setPage(pageNumber);
    filterRef.current.page = pageNumber;
    getClustersData(pageNumber, searchQuery);
  }

  const deleteCluster = async (clusterId) => { 
    Modal.warn({
      title: "Are you sure you want to delete cluster?",
      okText: "Okay",
      cancelText: "Cancel",
      okCancel: true,
      onOk: async () => {
        try {
          const res = await ClusterToolApiServices.deleteClusterConfig(clusterId);
          await refreshData();
          // await getClustersData();
          notification.success({
            message: "Cluster Deleted Successfully!",
            duration: 6,
            placement: "bottomRight",
          });
        } catch (error) {
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || "Failed to delete the cluster",
            duration: 6,
            placement: "bottomRight",
          });
        }
      },
    });
  }

  useEffect(() => {
    getClustersData(1);
    timeRef.current = window.setInterval(async () => {
      await refreshData()
     }, 10000);
     return () => {
      if (timeRef.current) {
        window.clearInterval(timeRef.current);
      }
    };
  }, [])

  return {
    loading,
    filters,
    data,
    deleteCluster,
    page,
    searchQuery,
    setPageNumber,
    setSearchQuery,
    changeSearchQuery,
    debouncedSearch
  }
}