import { css } from "@linaria/core";

const divider = css`
  display: flex;
  align-items: center;
  margin-bottom: 2.286rem;
`;

const dividerBorder = css`
  flex: 1;
  border: none;
  border-top: 2px solid #27272a;
`;


const dividerText = css`
  margin: 0 1rem 0 0;
  color: white;
  font-size: 1rem;
  font-weight: 700;
`;

export default {
  divider,
  dividerBorder,
  dividerText
};
