import { css } from "@linaria/core";

const clientSpaceContainer = css`
  position: relative;
  height: inherit;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 1rem 16px;
  flex-grow: 1;
  z-index:1;
  max-width:1440px;
  @media screen and (min-width: 768px) {
    padding: 0 80px;
  }
`;

const aiAppsContainer = css`
  display:flex;
  margin-top: 56px;
  width: 100%;
  flex-grow: 1;
  flex-direction:column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 70px;
  @media screen and (min-width:768px) {
    flex-direction:row;
    align-items: flex-start;
  }
`;
const createButton = css`
  background: #db5a4b !important;
  color: white !important;
  font-weight: 600 !important;
  border: 0 !important;
  height: 2.2rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  span {
    margin-left: 0.3rem !important;
  }
  .anticon {
    margin-left: 0 !important;
  }
`;

const lefLogoContainer = css`
  display:flex;
  justify-content: center;
  width:60%;
  max-width:162px;
  @media screen and (min-width:768px) {
    width:30%;
  }
`;

const clientLogo = css`
  width:100%;
  height:100%;
  max-width: 124px;
  object-fit: cover;
`;

const rightMenuContainer = css`
  width:100%;
  display:flex;
  flex-direction: column;
`;

const appSection = css`
  margin: 0 0 3.429rem;
`;

const decorativeBackgroundImage = css`
  position: fixed;
  right:0;
  top:0;
  z-index:0;
  img {
    object-fit:cover;
    width: 100%;
    transition: all 0.2s;
  }
  @media screen and (min-width:786px) {
    img {
      width: 100%;
    }
  }
`;

export default {
  clientSpaceContainer,
  aiAppsContainer,
  createButton,
  lefLogoContainer,
  clientLogo,
  rightMenuContainer,
  appSection,
  decorativeBackgroundImage
}