import ConditionalRender from "src/components/conditionalRender";
import useListDatasetHooks from "../hooks/listDatasetHook";
import { Button, Space, Table, Drawer, } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    PlayCircleOutlined,
  } from "@ant-design/icons";
import { Skeleton } from 'antd';
import React from "react";
import Dataset from "./dataset";
import Style from "./style";
import { TAG_COLOR_NAME } from "src/components/globalTag/globalTagConstant";
import GlobalTag from "src/components/globalTag/GlobalTag";

export default function ListDataset({ appId, refreshEvaluations }) {
    const { loading, pageNumber, setPageNumber, datasets, getDatasets, triggerEvaluation } = useListDatasetHooks(
        { appId, refreshEvaluations }
    );

    const [datasetFormModal, setDatasetFormModal] = React.useState(false);
    const [datsetId, setDatasetId] = React.useState(null);
    const openDataSetForm = (datasetId) => {
        setDatasetFormModal(true);
        setDatasetId(datasetId);
    };

    const onClose = () => {
        if(!datsetId) {
            // Reset page number to 1 when creating new dataset
            setPageNumber(1);
            getDatasets(1);
        } else {
            getDatasets();
        }
        setDatasetFormModal(false);
    };
    const columns = [
        {
            title: 'Dataset Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Dataset Type',
            dataIndex: 'is_conversation_based',
            key: 'is_conversation_based',
            render: is_conversation_based => (
                <GlobalTag color={is_conversation_based ? TAG_COLOR_NAME.BLUE : TAG_COLOR_NAME.ORANGE}>
                    {is_conversation_based ? 'Conversational' : 'Non-Conversational'}
                </GlobalTag>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on',
            render: created_on => (
                <span>{new Date(created_on).toLocaleString()}</span>
            ),
        },
        {
            title: 'Action',
            key: 'created_on',
            render: (_, record) => (
                <Space size="middle">
                <EditOutlined onClick={() => openDataSetForm(record.id)} />
                <PlayCircleOutlined onClick={() => triggerEvaluation(record.id)} />
                </Space>
              ),
        },
    ];
    return (
        <div>
            <ConditionalRender shouldRender={loading}>
                <Skeleton active />
            </ConditionalRender>
            <ConditionalRender shouldRender={!loading}>
                <Button
                    type="primary"
                    style={{ marginBottom: 16, float: 'right' }}
                    onClick={() => openDataSetForm(null)}
                >
                    Add Dataset
                </Button>
                <Drawer
                    title={datsetId ? "Edit Dataset": "Create Dataset"}
                    maskClosable={true}
                    placement="right"
                    onClose={onClose}
                    open={datasetFormModal}
                    destroyOnClose
                    size="large"
                    width={"50%"}
                >
                    <Dataset datasetId={datsetId} appId={appId} onClose={onClose} />
                </Drawer>

                <div>
                    <Table
                        className='dark-table'
                        columns={columns}
                        dataSource={datasets?.data || []} 
                        onChange={(pagination) => {
                            setPageNumber(pagination.current);
                        }}
                        pagination={{
                            current: pageNumber,
                            total: datasets?.total_size,
                        }}
                    />
                </div>
            </ConditionalRender>
        </div>
    );
}