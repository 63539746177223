import React, { useMemo } from "react";
import {
  Empty,
  Card,
  Spin,
  Select,
  Row,
  Col,
  DatePicker,
  Drawer,
  Slider,
  Collapse,
  Switch,
  Tooltip,
  Modal,
  Form,
  Input,
  Popover,
  InputNumber,
} from "antd";
import { SaveFilled, SettingFilled, CloseOutlined, CloseCircleOutlined, InfoCircleOutlined, } from "@ant-design/icons";
import Style from "../NewsAndCulture.style";
import KnowledgeGraphComponent from "src/modules/newsAndCulture/components/knowledgeGraph/knowledgeGraph.component";
import useNewsAndCulture from "../hooks/useNewsAndCultureLayout";
import ArticlesTableDrawer from "./ArticlesTableDrawer";
import moment from "moment";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import KnowledgeGraphSummary from "./KnowledgeGraphSummary";
import KnowledgeGraphComponentStats from "./knowledgeGraphStats/knowledgeGraphStats.component";
import ConditionalRender from "src/components/conditionalRender";
import { DateSlider } from "./DateSlider";
import IconGenerator from "src/components/IconGenerator";
import FloatLabel from "./floatLabel/floatLabel.component";
const { RangePicker } = DatePicker;

export const AnalysisViewContent = ({ analysisOverview }) => {
  const {
    loading,
    drawerVisible,
    selectedWord,
    selectedLink,
    analysisId,
    handleNodeClick,
    setDrawerVisible,
    viewAnalysis,
    handleLinkClick,
    nodeClick,
    linkClick,
    knowledgeGraphFilters,
    onChangeKnowledgeGraphFilters,
    knowledgeGraph,
    nodes,
    knowledgeGraphLoading,
    summaryDrawer,
    dates,
    setSummaryDrawer,
    setColorBy,
    colorBy,
    applyFiltersKnowledgeGraph,
    knowledgeGraphStats,
    knowledgeGraphStatsLoading,
    handleSliderChange,
    selectedDate,
    handleNodePerformanceNodeClick,
    datesForDatePicker,
    handleNodesFilter,
    handleCommunitiesFilter,
    knowledgeGraphCommunities,
    handlePopupScroll,
    handleSearch,
    handleDropdownVisibleChange,
    loadingNodesOptions,
    containerRef,
    knowledgeGraphChanged,
    knowledgeGraphSavedFilters,
    knowledgeGraphAPIFilter,
    maxJobTriggeredAt,
    showSaveFilterModal,
    setShowSaveFilterModal,
    saveKnowledgeGraphSavedFilters,
    formRef,
    applySavedFilters,
    selectedFilter,
    showMyFilters,
    setShowMyFilters,
    userData,
    useAISearch,
    constructKnowledgeGraphFilters,
    showMoreOptions,
    setShowMoreOptions,
    handles,
    loadingHandlesOptions,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    analysisListData,
    appliedDates,
  } = useNewsAndCulture({ analysisOverview });

  const memoizedKnowledgeGraph = useMemo(() => {
    const COLOR_BY_MAPPER = {};
    if (knowledgeGraph && knowledgeGraph.platform_categories) {
      COLOR_BY_MAPPER.platform = {
        category_key: "platform_category",
        category_legend_key: "platform_categories",
        name: "Platform",
      };
    }
    if (knowledgeGraph?.analysis_categories) {
      COLOR_BY_MAPPER.analysis = {
        category_key: "analysis_category",
        category_legend_key: "analysis_categories",
        name: "Analysis",
      };
    }
    if (knowledgeGraph?.community_categories) {
      COLOR_BY_MAPPER.community = {
        category_key: "community_category",
        category_legend_key: "community_categories",
        name: "Community",
      };
    }
    if (knowledgeGraph?.custom_categories) {
      Object.keys(knowledgeGraph.custom_categories).map((categoryName) => {
        COLOR_BY_MAPPER[categoryName] = {
          category_key: `custom_${categoryName}`,
          category_legend_key: `custom_${categoryName}`,
          name: categoryName,
        };
        knowledgeGraph[`custom_${categoryName}`] = Object.values(
          knowledgeGraph.custom_categories[categoryName]
        ).map((category) => ({
          id: category.id,
          name: category.name,
          description: category.description,
        }));
      });
    }

    return knowledgeGraph?.nodes
      ? {
        nodes: knowledgeGraph.nodes.map((element) => {
          element.symbolSize =
            10 + (element.mentions * 50) / element.max_mentions;
          element.label = {
            show: element.symbolSize > 15,
            textStyle: {
              color: "#ffffff",
            },
          };
          element.category = element[COLOR_BY_MAPPER[colorBy]?.category_key];
          element.emphasis = {
            label: {
              show: true,
            }
          }
          return element;
        }).filter((element) => element.category !== undefined),
        links: knowledgeGraph.links,
        categories:
          knowledgeGraph[COLOR_BY_MAPPER[colorBy]?.category_legend_key],
        color_by_mapper: COLOR_BY_MAPPER,
      }
      : null;
  }, [knowledgeGraph, knowledgeGraphStats, colorBy, selectedDate]);

  return (
    <div className={Style.tabContent} ref={containerRef}>
      <Card className={Style.analysisCardView}>
        {loading ? (
          <div style={{ textAlign: "center", padding: "50px" }}>
            <Spin size="medium" />
          </div>
        ) : (
          <>
            <>
              <Row gutter={[10, 10]}>
                <Col span={4}>
                  <Row gutter={[10, 10]} className={Style.knowledgeGraphFilters}>
                    <Col span={24}>
                      <FloatLabel
                        label="Saved Filter"
                        style={{ backgroundColor: "var(--color-charcoal-surface-2)" }}
                        value={selectedFilter?.id}
                        helpText="Select one the options below to apply the saved filters onto the knowledge graph."
                      >
                        <Select
                          showSearch
                          className={Style.savedFilterSelect}
                          style={{ width: "100%" }}
                          showArrow
                          // placeholder="Saved Filters"
                          value={selectedFilter?.id}
                          onChange={(value) =>
                            applySavedFilters(knowledgeGraphSavedFilters?.find((elem) => elem.id === value))
                          }
                          // onClear={() => clearSavedFilters()}
                          popupClassName={Style.savedFilterPopup}
                          allowClear
                          optionLabelProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          dropdownRender={(menu) => (
                            <div>
                              <div className={Style.customDropdownHeader}>
                                <span style={{ paddingRight: "10px" }}>Show only my filters: </span>
                                <Switch
                                  style={{ width: "10%" }}
                                  checked={showMyFilters}
                                  onChange={() => setShowMyFilters(!showMyFilters)}
                                />
                              </div>
                              {menu}
                            </div>
                          )}
                        >
                          {knowledgeGraphSavedFilters
                            ?.filter((elem) => {
                              if (showMyFilters) {
                                return elem.filter_owner === userData?.id;
                              }
                              return true;
                            })
                            .map((elem, idx) => {
                              return (
                                <Select.Option key={idx} value={elem.id} label={elem.filter_name}>
                                  <Popover
                                    placement="right"
                                    overlayClassName={Style.savedFilterPopover}
                                    zIndex={9999}
                                    mouseEnterDelay={0.5}
                                    content={
                                      <div className={Style.popoverContent}>
                                        <div>
                                          <strong>Filter Name:</strong> {elem.filter_name}
                                        </div>
                                        {elem.filter_description && (
                                          <div>
                                            <strong>Filter Description:</strong> {elem.filter_description}
                                          </div>
                                        )}
                                        <div>
                                          <strong>Created by:</strong>{" "}
                                          {elem.filter_owner_data?.first_name
                                            ? `${elem.filter_owner_data.first_name} ${elem.filter_owner_data.last_name}`
                                            : elem.filter_owner_data?.email}
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div className={Style.savedFilterOptions}>
                                      <div className={Style.userIcon}>
                                        <IconGenerator
                                          height={24}
                                          width={24}
                                          initial={
                                            elem.filter_owner_data?.first_name
                                              ? elem.filter_owner_data?.first_name.charAt(0) +
                                                elem.filter_owner_data?.last_name.charAt(0)
                                              : elem.filter_owner_data?.email.slice(0, 2)
                                          }
                                          backgroundColor={"var(--color-white)"}
                                          color="var(--color-full-black)"
                                        />
                                      </div>
                                      <div className={Style.filterContent}>
                                        <div className={Style.filterName}>{elem.filter_name}</div>
                                        <div className={Style.filterDate}>
                                          {moment(elem.max_job_triggered_at).format("LLL")}
                                        </div>
                                      </div>
                                    </div>
                                  </Popover>
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </FloatLabel>
                      {selectedFilter?.id && (
                        <div className={Style.dataSnapshotMessage}>
                          <Tooltip
                            overlayClassName={Style.tooltipDegreeOfSeparation}
                            title={"Remove saved filter above to view the latest data."}
                            placement="bottom"
                          >
                            <div>Browsing data as of:</div>
                            <b>{moment(selectedFilter.max_job_triggered_at).format("LLL")}</b>
                          </Tooltip>
                        </div>
                      )}
                    </Col>
                    <div
                      className={
                        showMoreOptions
                          ? Style.knowledgeGraphFiltersBoxDynamicExpanded
                          : Style.knowledgeGraphFiltersBoxDynamic
                      }
                    >
                      <div className={Style.knowledgeGraphFiltersWrapper}>
                        {/* <div className={Style.knowledgeGraphFiltersWrapper}> */}
                        <Col span={"50%"} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                height: "2rem",
                              }}
                            >
                              <h3 style={{ fontWeight: "bold" }}>Filter by: </h3>
                              <div>
                                {!showMoreOptions && <SettingFilled onClick={() => setShowMoreOptions(true)} />}
                              </div>
                            </div>
                          </Col>
                          {/* {!useAISearch ? (
                            <> */}
                          <Col span={24}>
                            <FloatLabel
                              label="Platform"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.analysisType}
                              helpText="Source platform of the data in the knowledge graph."
                            >
                              <Select
                                onChange={(value) => onChangeKnowledgeGraphFilters("analysisType", value)}
                                style={{ width: "100%" }}
                                value={knowledgeGraphFilters.analysisType}
                                allowClear
                                mode="multiple"
                                maxTagCount={2}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "analysisType",
                                                  knowledgeGraphFilters.analysisType.filter(
                                                    (elem) => elem !== label.toLowerCase(),
                                                  ),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                showArrow
                              >
                                <Select.Option value={"news"}>News</Select.Option>
                                <Select.Option value={"twitter"}>Twitter</Select.Option>
                                <Select.Option value={"facebook"}>Facebook</Select.Option>
                                <Select.Option value={"instagram"}>Instagram</Select.Option>
                                <Select.Option value={"threads"}>Threads</Select.Option>
                                <Select.Option value={"tiktok"}>Tiktok</Select.Option>
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Nodes"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={
                                knowledgeGraphFilters.return_community_graph === true
                                  ? undefined
                                  : knowledgeGraphFilters.nodes
                              }
                              helpText={
                                "Nodes are distinct items or concepts that can be clearly identified and described. One or more nodes are assigned by AI for each post."
                              }
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showArrow
                                onPopupScroll={handlePopupScroll}
                                value={
                                  knowledgeGraphFilters.return_community_graph === true
                                    ? undefined
                                    : knowledgeGraphFilters.nodes
                                }
                                onChange={(value) => onChangeKnowledgeGraphFilters("nodes", value)}
                                onSearch={handleSearch}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "nodes",
                                                  knowledgeGraphFilters.nodes.filter((elem) => elem !== label),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading || knowledgeGraphFilters.return_community_graph}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {nodes.length === 0 && !loadingNodesOptions ? (
                                        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingNodesOptions && (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                padding: "8px",
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {nodes?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Communities"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.communities || undefined}
                              helpText="A group of nodes that are highly connected, showing a conversation happening between them."
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showArrow
                                value={knowledgeGraphFilters.communities || undefined}
                                onChange={(value) => onChangeKnowledgeGraphFilters("communities", value)}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "Communities",
                                                  knowledgeGraphFilters.communities.filter((elem) => elem !== label),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                              >
                                {knowledgeGraphCommunities?.map((elem, idx) => {
                                  return (
                                    <Select.Option key={idx} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Date Range"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={[moment(dates?.start_date), moment(dates?.end_date)]}
                            >
                              <RangePicker
                                onChange={(value) => onChangeKnowledgeGraphFilters("date_range", value)}
                                value={[moment(dates?.start_date), moment(dates?.end_date)]}
                                style={{ width: "100%" }}
                                allowClear={false}
                                disabled={knowledgeGraphLoading}
                                disabledDate={(val) => {
                                  if (val?.isBefore(moment(datesForDatePicker?.start_date).startOf("d"))) {
                                    return true;
                                  }
                                  if (val?.isAfter(moment().endOf("d"))) {
                                    return true;
                                  }
                                  return false;
                                }}
                              />
                            </FloatLabel>
                          </Col>
                          <Col span={24} style={{ paddingTop: "10px" }}>
                            Top Communities: {knowledgeGraphFilters.topCommunitiesPercentage}%
                            <Slider
                              className={Style.sliderCommunities} // Adjust the class name if needed
                              min={1} // Set the minimum value
                              max={100} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.topCommunitiesPercentage}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters("topCommunitiesPercentage", value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                1: "1%",
                                50: "50%",
                                100: "100%",
                              }}
                              tooltip={{
                                formatter: (value) => `${value}%`,
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <Tooltip
                              className={`${Style.tooltipDegreeOfSeparation} ${
                                knowledgeGraphFilters?.nodes?.length === 0 ||
                                knowledgeGraphFilters?.return_community_graph
                                  ? "disabled"
                                  : ""
                              }`}
                              overlayClassName={Style.tooltipDegreeOfSeparation}
                              title={
                                knowledgeGraphFilters?.nodes?.length === 0 ||
                                knowledgeGraphFilters?.return_community_graph
                                  ? "Applicable only when filtering on Nodes."
                                  : ""
                              }
                              placement="top"
                            >
                              Degree of separation: {knowledgeGraphFilters.degreeOfSeparation}
                              <Slider
                                min={1} // Set the minimum value
                                max={10} // Set the maximum value, adjust as needed
                                value={knowledgeGraphFilters.degreeOfSeparation}
                                onChange={(value) => {
                                  onChangeKnowledgeGraphFilters("degreeOfSeparation", value);
                                }}
                                step={1} // Set the step value
                                marks={{
                                  1: "1",
                                  10: "10",
                                }}
                                disabled={
                                  knowledgeGraphFilters?.nodes?.length === 0 ||
                                  knowledgeGraphFilters?.return_community_graph
                                }
                              />
                            </Tooltip>
                          </Col>
                          <Col span={24}>
                            <div>
                              Group by Community:&nbsp;&nbsp;
                              <Switch
                                style={{ width: "10%" }}
                                checked={knowledgeGraphFilters.return_community_graph || false}
                                onChange={(value) => onChangeKnowledgeGraphFilters("return_community_graph", value)}
                              />
                            </div>
                          </Col>
                          {/* <Col span={24}>
                                <Collapse className="analysisCollapse">
                                  <Collapse.Panel header="Advanced Settings" key="1">
                                    
                                  </Collapse.Panel>
                                </Collapse>
                              </Col> */}
                          {/* </>
                          ) : (
                            <Input
                              // placeholder="Search"
                              onChange={(e) => onChangeKnowledgeGraphFilters("custom_search_query", e.target.value)}
                              value={knowledgeGraphFilters.custom_search_query}
                            />
                          )} */}
                        </Col>
                        <Col span={"50%"} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                height: "2rem",
                              }}
                            >
                              <div>
                                {/* // <Switch
                                //   style={{ width: "10%" }}
                                //   checked={useAISearch}
                                //   onChange={() => {
                                //     setUseAISearch(!useAISearch);
                                //     onChangeKnowledgeGraphFilters("custom_search_query", null);
                                //   }}
                                // /> */}
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Search Connections"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.custom_search_query}
                              helpTextPopoverStyle={{ maxWidth: "300px", fontSize: "11px" }}
                              helpText={
                                <div>
                                  Filter connections based on the search query, which can be a word or short phrase.
                                  This is not an exact match and may return related topics. This can take upto a minute
                                  to return data. Reduce date range to improve performance.
                                  <br />
                                  <strong>Example:</strong>{" "}
                                  <div style={{ padding: "0px 10px" }}>
                                    <div
                                      style={{
                                        color: "green",
                                        padding: "10px 0px",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      "Music Concerts"
                                    </div>
                                    <div>
                                      Search for "Music Concerts" to see connections talking about music concerts and
                                      adjacent topics.
                                    </div>
                                    <hr style={{ opacity: "0.3" }} />
                                    <div
                                      style={{
                                        color: "red",
                                        padding: "10px 0px",
                                        width: "100%",
                                        textAlign: "center",
                                      }}
                                    >
                                      "I want to see music concerts that are trending"
                                    </div>
                                    <div>
                                      Trending / likes / comments etc are not currently supported for filtering out the
                                      connections.
                                    </div>
                                  </div>
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) => onChangeKnowledgeGraphFilters("custom_search_query", e.target.value)}
                                value={knowledgeGraphFilters.custom_search_query}
                              />
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Exclude Nodes"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={
                                knowledgeGraphFilters.return_community_graph === true
                                  ? undefined
                                  : knowledgeGraphFilters.exclude_nodes
                              }
                              helpText="Exclude nodes from the graph"
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showArrow
                                onPopupScroll={handlePopupScroll}
                                value={
                                  knowledgeGraphFilters.return_community_graph === true
                                    ? undefined
                                    : knowledgeGraphFilters.exclude_nodes
                                }
                                onChange={(value) => onChangeKnowledgeGraphFilters("exclude_nodes", value)}
                                onSearch={handleSearch}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "exclude_nodes",
                                                  knowledgeGraphFilters.exclude_nodes.filter((elem) => elem !== label),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading || knowledgeGraphFilters.return_community_graph}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {nodes.length === 0 && !loadingNodesOptions ? (
                                        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingNodesOptions && (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                padding: "8px",
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {nodes?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          {/* <Col span={24}>
                            <FloatLabel
                              label="Section"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.analysisQueries || undefined}
                            >
                              <Select
                                mode="multiple"
                                showArrow
                                onChange={(value) => onChangeKnowledgeGraphFilters("analysisQueries", value)}
                                style={{ width: "100%" }}
                                value={knowledgeGraphFilters.analysisQueries || undefined}
                                allowClear
                              >
                                {analysisOverview?.section_data?.map((section) => (
                                  <Select.Option key={section?.section_id} value={section?.section_id}>
                                    {section?.section_name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </FloatLabel>
                          </Col> */}
                          <Col span={24}>
                            <FloatLabel
                              label="Handles"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.handles}
                              helpText="Include only posts from these handles"
                            >
                              <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                showArrow
                                onPopupScroll={handlePopupScrollHandle}
                                value={knowledgeGraphFilters.handles}
                                onChange={(value) => onChangeKnowledgeGraphFilters("handles", value)}
                                onSearch={handleSearchHandle}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "handles",
                                                  knowledgeGraphFilters.handles.filter((elem) => elem !== label),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {handles.length === 0 && !loadingHandlesOptions ? (
                                        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingHandlesOptions && (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                padding: "8px",
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {handles?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Exclude Handles"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.exclude_handles}
                              helpText="Exclude posts from these handles"
                            >
                              <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                showArrow
                                onPopupScroll={handlePopupScrollHandle}
                                value={knowledgeGraphFilters.exclude_handles}
                                onChange={(value) => onChangeKnowledgeGraphFilters("exclude_handles", value)}
                                onSearch={handleSearchHandle}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "exclude_handles",
                                                  knowledgeGraphFilters.exclude_handles.filter(
                                                    (elem) => elem !== label,
                                                  ),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {handles.length === 0 && !loadingHandlesOptions ? (
                                        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingHandlesOptions && (
                                            <div
                                              style={{
                                                textAlign: "center",
                                                padding: "8px",
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {handles?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            Relevance Score:
                            <Popover
                              content={
                                "Higher relevance score can be used to filter out connections deemed as not significant for the client by the AI"
                              }
                              overlayStyle={{ maxWidth: "250px", fontSize: "11px" }}
                            >
                              &nbsp;
                              <InfoCircleOutlined style={{ fontSize: "12px" }} />
                            </Popover>
                            <Slider
                              className={Style.slider} // Adjust the class name if needed
                              min={0} // Set the minimum value
                              max={10} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.importance_score || 0}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters("importance_score", value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                0: "0",
                                10: "10",
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            Opportunity Score:
                            <Popover
                              content={
                                "Higher opportunity score can be used to filter out connections that are not deemed as good opportunities for the client by the AI"
                              }
                              overlayStyle={{ maxWidth: "250px", fontSize: "11px" }}
                            >
                              &nbsp;
                              <InfoCircleOutlined style={{ fontSize: "12px" }} />
                            </Popover>
                            <Slider
                              className={Style.slider} // Adjust the class name if needed
                              min={0} // Set the minimum value
                              max={10} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.opportunity_score || 0}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters("opportunity_score", value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                0: "0",
                                10: "10",
                              }}
                            />
                          </Col>
                        </Col>
                        <Col span={"50%"} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                height: "2rem",
                              }}
                            >
                              <div>
                                {showMoreOptions && <CloseCircleOutlined onClick={() => setShowMoreOptions(false)} />}
                                {/* // <Switch
                                //   style={{ width: "10%" }}
                                //   checked={useAISearch}
                                //   onChange={() => {
                                //     setUseAISearch(!useAISearch);
                                //     onChangeKnowledgeGraphFilters("custom_search_query", null);
                                //   }}
                                // /> */}
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Data Type"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.post_types}
                              helpText="Type of data to show in Knowledge graph."
                            >
                              <Select
                                onChange={(value) => onChangeKnowledgeGraphFilters("post_types", value)}
                                style={{ width: "100%" }}
                                value={knowledgeGraphFilters.post_types}
                                allowClear
                                mode="multiple"
                                maxTagCount={2}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "post_types",
                                                  knowledgeGraphFilters.post_types?.filter(
                                                    (elem) => elem !== label.toLowerCase(),
                                                  ),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                showArrow
                              >
                                <Select.Option value={"POST"}>Post</Select.Option>
                                <Select.Option value={"COMMENT"}>Comment</Select.Option>
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Author Overlap"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              value={knowledgeGraphFilters.author_overlap_analysis_ids || undefined}
                              helpText="Filter posts by authors that overlap between current analysis and chosen analysis."
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                showArrow
                                value={knowledgeGraphFilters.author_overlap_analysis_ids || undefined}
                                onChange={(value) =>
                                  onChangeKnowledgeGraphFilters("author_overlap_analysis_ids", value)
                                }
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label, value }) => (
                                          <div className={Style.tagText}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                onChangeKnowledgeGraphFilters(
                                                  "author_overlap_analysis_ids",
                                                  knowledgeGraphFilters.author_overlap_analysis_ids.filter(
                                                    (elem) => elem !== value,
                                                  ),
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                optionFilterProp="children"
                              >
                                {analysisListData?.map((elem, idx) => {
                                  return (
                                    <Select.Option key={idx} value={elem.id}>
                                      {elem.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <Tooltip
                              className={`${Style.tooltipDegreeOfSeparation} ${
                                knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0 ? "disabled" : ""
                              }`}
                              overlayClassName={Style.tooltipDegreeOfSeparation}
                              title={
                                knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0
                                  ? "Applicable only when filtering on Author Overlap."
                                  : ""
                              }
                              placement="top"
                            >
                              <div>
                                Show analysis{" "}
                                <Popover
                                  content={
                                    "Show data from the overlapping authors between the current analysis and the chosen analysis."
                                  }
                                  overlayStyle={{ maxWidth: "250px", fontSize: "11px", zIndex: 9999 }}
                                >
                                  &nbsp;
                                  <InfoCircleOutlined style={{ fontSize: "12px" }} />
                                </Popover>
                                :&nbsp;&nbsp;
                                <Switch
                                  style={{ width: "10%" }}
                                  disabled={knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0}
                                  checked={knowledgeGraphFilters.include_author_overlap_analysis_data || false}
                                  onChange={(value) =>
                                    onChangeKnowledgeGraphFilters("include_author_overlap_analysis_data", value)
                                  }
                                />
                              </div>
                            </Tooltip>
                          </Col>
                          {/* <Col span={24}>
                            <FloatLabel
                              label="Likes Range"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              helpText="Filter posts by number of likes."
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("minLikes", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minLikes}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("maxLikes", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxLikes}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Comments Range"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              helpText="Filter posts by number of comments."
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("minComments", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minComments}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("maxComments", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxComments}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Views Range"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              helpText="Filter posts by number of views."
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("minViews", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minViews}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("maxViews", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxViews}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Followers Range"
                              style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                              helpText="Filter posts by number of followers of the author."
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("minFollowers", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minFollowers}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => onChangeKnowledgeGraphFilters("maxFollowers", value)}
                                  style={{ width: "50%" }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxFollowers}
                                />
                              </div>
                            </FloatLabel>
                          </Col> */}
                        </Col>
                        {/* </div> */}
                      </div>
                    </div>
                    <Col span={24}>
                      <PrimaryButton
                        className={Style.applyButton}
                        loading={knowledgeGraphLoading}
                        style={{ marginTop: "1rem", width: "100%" }}
                        onClick={() => applyFiltersKnowledgeGraph()}
                      >
                        Apply filters
                      </PrimaryButton>
                    </Col>
                    <Col span={24}>
                      <PrimaryButton
                        className={Style.applyButton}
                        style={{ marginTop: "1rem", width: "100%" }}
                        onClick={() => setShowSaveFilterModal(true)}
                      >
                        Save Filters
                      </PrimaryButton>
                    </Col>
                  </Row>
                </Col>
                {memoizedKnowledgeGraph && memoizedKnowledgeGraph.nodes.length > 0 ? (
                  <Col span={20}>
                    <Row>
                      <Col span={24}>
                        <ConditionalRender
                          shouldRender={!!(memoizedKnowledgeGraph && memoizedKnowledgeGraph.nodes.length > 0)}
                        >
                          <div className={Style.summarizeButton}>
                            <DateSlider
                              minDate={dates?.start_date}
                              maxDate={dates?.end_date}
                              handleSliderChange={handleSliderChange}
                              selectedDate={moment.utc(selectedDate)}
                            />
                            <FloatLabel
                              label="Color by"
                              style={{ backgroundColor: "var(--color-charcoal-surface-2)" }}
                              value={colorBy}
                              helpText="Color nodes by the selected category."
                            >
                              <Select
                                style={{ width: "130px" }}
                                // placeholder="Color by"
                                onChange={(val) => setColorBy(val)}
                                value={colorBy}
                              >
                                {" "}
                                {memoizedKnowledgeGraph?.color_by_mapper &&
                                  Object.keys(memoizedKnowledgeGraph?.color_by_mapper)?.map((key) => (
                                    <Select.Option className={Style.colorByOption} key={key} value={key}>
                                      {memoizedKnowledgeGraph?.color_by_mapper[key].name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </FloatLabel>
                            <PrimaryButton onClick={() => setSummaryDrawer(true)}>Analyze Trends</PrimaryButton>
                          </div>
                        </ConditionalRender>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <KnowledgeGraphComponent
                          nodes={memoizedKnowledgeGraph.nodes}
                          links={memoizedKnowledgeGraph.links}
                          categories={memoizedKnowledgeGraph.categories}
                          onNodeClick={handleNodeClick}
                          onLinkClick={handleLinkClick}
                          knowledgeGraphChanged={knowledgeGraphChanged}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={20}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "50px",
                        width: "100%",
                        height: "700px",
                      }}
                    >
                      <Empty description="No Data" />
                    </div>
                  </Col>
                )}
              </Row>
            </>
            {knowledgeGraphStats && Object.values(knowledgeGraphStats).length !== 0 && (
              <KnowledgeGraphComponentStats
                knowledgeGraphStats={knowledgeGraphStats}
                handleNodePerformanceNodeClick={handleNodePerformanceNodeClick}
                setNodesFilter={handleNodesFilter}
                setCommunitiesFilter={handleCommunitiesFilter}
                jobTriggers={analysisOverview?.job_triggers}
                analysisId={analysisId}
                containerRef={containerRef}
                analysisOverview={analysisOverview}
                analysisListData={analysisListData}
              />
            )}
          </>
        )}
        <ArticlesTableDrawer
          selectedWord={selectedWord}
          selectedLink={selectedLink}
          visible={drawerVisible}
          analysisId={analysisId}
          onClose={() => setDrawerVisible(false)}
          nodeClick={nodeClick}
          linkClick={linkClick}
          postTypes={knowledgeGraphFilters?.post_types}
          secondary_analysis_ids={knowledgeGraphFilters?.author_overlap_analysis_ids}
          start_date={appliedDates?.start_date}
          end_date={appliedDates?.end_date}
        />
        <Modal
          width={600}
          open={showSaveFilterModal}
          destroyOnClose
          footer={null}
          onCancel={() => setShowSaveFilterModal(false)}
          maskClosable={true}
        >
          <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
            <SaveFilled style={{ fontSize: "1.25rem" }} /> Save Applied Filter
          </div>
          <Form
            ref={formRef}
            layout="vertical"
            onFinish={(val) => saveKnowledgeGraphSavedFilters(analysisId, val.filter_name, val.filter_description)}
          >
            <Form.Item
              name="filter_name"
              label="Filter Name"
              rules={[{ required: true, message: "Please enter a filter name" }]}
            >
              <Input placeholder="Enter filter name" />
            </Form.Item>
            <Form.Item name="filter_description" label="Filter Description">
              <Input.TextArea placeholder="Enter filter description" />
            </Form.Item>
          </Form>
          <span>
            The filter will be saved with the data snapshot as of <b>{moment(maxJobTriggeredAt).format("LLL")}</b>.
          </span>
          <br />
          <Row justify="end">
            <SecondaryButton className="small" loading={loading} onClick={() => formRef.current.submit()}>
              Save Filter
            </SecondaryButton>
          </Row>
        </Modal>
      </Card>
      <Drawer
        open={summaryDrawer}
        onClose={() => setSummaryDrawer(false)}
        title="Trend Summary"
        placement="right"
        width="80%"
        destroyOnClose
        maskClosable={true}
      >
        <KnowledgeGraphSummary
          knowledgeGraphFilters={knowledgeGraphFilters}
          analysisId={analysisId}
          constructKnowledgeGraphFilters={constructKnowledgeGraphFilters}
        />
      </Drawer>
    </div>
  );
};

export default AnalysisViewContent;
