import { css } from "@linaria/core";

const knowledgeGraphStatsContainer = css`
  margin-top: 20px;
  .ant-typography {
    color: white;
  }
  .ant-list-item {
    color: white;
  }
`;

const darkTable = css`
  table {
    border-color: white;
  }
  table > thead > tr > th {
    background-color: #171717;
    color: white;
  }
  table > tbody > tr > td {
    background-color: #222222 !important;
    color: white;
    border-bottom: none;
  }
  .ant-empty-description {
    color: white;
  }

  .ant-pagination {
    li {
      border: 0px;
      background-color: #222222;
      color: white;
      button {
        background-color: #222222;
        color: white;
        border: none;
        :hover {
          background-color: #222222;
          color: white;
        }
      }
      a {
        border: 0px;
        background-color: #222222;
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid white !important;
  }
`;

const alignRightSearch = css`
  width: 30% !important;
`;

const alignRightSearchWrapper = css`
  display: flex;
  justify-content: end;
`;

const communitiesCard = css`
  color: white;
  margin-bottom: 8px;
  padding: 5px;
  /* border: 1px solid white; */
  border-radius: 5px;
  margin: 5px;
  background-color: #3c3b3b;
`;

const paginationStyle = css`
  text-align: "center";
  margin-top: 16;
`;

const cardContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const opportunitiesCard = css`
  &.ant-card {
    min-width: 32.5%;
    max-width: 32.5%;
    flex: 1 1 calc(33.333% - 16px);
    margin-bottom: 16px;
    position: relative;
    background: var(--color-charcoal-surface-2);
  }
`;

const setNodesButton = css`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
`;

const opportunityNodes = css`
  font-size: 12px;
  color: white;
  padding-top: 12px;
`;

const opportunitiesList = css`
  .ant-list-item.ant-list-item-no-flex {
    border: None;
  }
`;


const nodesCard = css`
  color: white;
  margin-bottom: 8px;
  padding: 5px;
  /* border: 1px solid white; */
  border-radius: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 10px;
  background-color: #3c3b3b;
`;


const tagWrapper = css`
  font-size: 12px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: fit-content;
  max-height: 200px;
  max-width: 268px;
  overflow-y: auto;
`;

const tagText = css`
  color: black;
  background-color: white;
  border-radius: 3px;
  margin: 5px;
  border: solid 0.5px #c6c6c685;
  padding: 3px;
  padding-top: 4px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
`;

const tagPopover = css`
  .ant-popover-inner-content {
    padding: 5px;
    box-shadow: 0px 0px 1px 0px #ffffff8a;
    // display: flex;
    // align-content: flex-end;
    // justify-content: start;
    // align-items: flex-end;
  }
  .ant-popover-arrow > .ant-popover-arrow-content {
    box-shadow: 0px 0px 1px 0px #ffffff8a;
  }
`;


export default {
  knowledgeGraphStatsContainer,
  darkTable,
  alignRightSearch,
  alignRightSearchWrapper,
  communitiesCard,
  paginationStyle,
  cardContainer,
  opportunitiesCard,
  setNodesButton,
  opportunityNodes,
  opportunitiesList,
  nodesCard,
  tagWrapper,
  tagText,
  tagPopover,
};
