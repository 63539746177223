import React from 'react';
import ConditionalRender from 'src/components/conditionalRender';
import Assets from 'src/assets';
import IconGenerator from 'src/components/IconGenerator';
import Style from '../../clientSpaceCard.style';
import { Link } from 'react-router-dom';

export const AssistantCard = ({ data,url, internalApp, showOwner }) => {
  function displayArrow() {
    return (
      <svg
          className={Style.cardArrow + ' arrow'}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 25 26"
        >
          <path
            fill="currentColor"
            d="M17.854 13.649a.5.5 0 000-.708L14.672 9.76a.5.5 0 10-.708.707l2.829 2.829-2.829 2.828a.5.5 0 00.708.707l3.182-3.181zM5.5 13.795h12v-1h-12v1z"
          ></path>
        </svg>
    )
  }

  const Images = {
    GaleRFP: {
      icon: Assets.getImage('MicroappBradIcon'),
    },
    BackboneAssistant: {
      icon: Assets.getImage('MicroappBackboneIcon'),
    }
  };

  function examBardOrBackbone(name) {
    return (name === 'GaleRFP' || name === 'BackboneAssistant');
  }

  return (
    <Link to={url} className={Style.appCardContainer}>
      <ConditionalRender shouldRender={data?.app_icon_name}>
        <span className='material-symbols-outlined icon'>{data?.app_icon_name}</span>
      </ConditionalRender>
      <ConditionalRender shouldRender={!data?.app_icon_name}>
        {examBardOrBackbone(data?.class_name) ? (
          <div className={Style.imageContainer + ' icon'}>
            <img
              alt={data?.class_name}
              src={Images[data?.class_name]?.icon}
            />
          </div>
        ) : (
          <div className="icon">
            <IconGenerator
              height='40'
              width='40'
              backgroundColor='transparent'
              initial={data?.name?.[0]?.toUpperCase()}
            />
          </div>
        )}
      </ConditionalRender>
      <div className={Style.appCardTitle}>
        {data?.name}
        {displayArrow()}
      </div>
    </Link>
  );
};
