import { css } from "@linaria/core";

const container = css`
  padding: 0 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    padding: 0px 60px;
  }
`;

const stepsStyle = css`
  .ant-steps-item-wait .ant-steps-icon svg {
    fill: var(--color-60-gray); /* Inactive step icon color */
  }
  .ant-steps-item-process .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: var(--color-link); /* Active step title color */
  }

  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: var(--color-link); /* Completed step title color */
  }
`;

const buttonContainer = css`
  display: flex;
  justify-content: space-between; /* Space buttons evenly */
  margin-top: 36px;
`;

const stepContent = css`
  padding: 0 1rem;
`;

export default {
  stepsStyle,
  buttonContainer,
  stepContent,
  container,
};
