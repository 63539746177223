import { Card, Col, Collapse, Divider, Form, Input, InputNumber, Row, Select, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import useCreateClusterHook from "./useCreateClusterHook";
import Style from "./createClusters.style";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import { COLUMNS_TYPE } from "src/modules/CustomGpt/constants/constants";
import { PrimaryButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import { CHILIS_ACQUISTION, CHILIS_CRM, CLUSTERING_TYPE, FOGO_ACQUISTION, FOGO_CRM } from "../../constants/constants";
import { numberWithCommas } from "src/lib/utils";
import appUrls from "src/constants/appUrls";

export default function CreateCluster({ history, match }) {
  const {
    loading,
    formRef,
    createClusterConfig,
    configureLoading,
    table,
    getAudienceSize,
    audienceCount,
    audienceSizeLoading,
    initialValues,
    clientSpaceName,
    editDescription,
    setEditDescription,
    clusteringConfig,
    editMode,
    viewMode,
    nextButton,
    id,
    clusterId,
    savedChanges,
    setSavedChanges,
  } = useCreateClusterHook({ match, history });
  if (loading) {
    return <LoadingSpinner centered={true} />;
  }
  return (
    <>
      <NcBreadCrumb className={Style.breadCrumb} />
      <Row justify="space-between">
        <Col>
          <h1>Share some details with us!</h1>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>
            <ConditionalRender shouldRender={!!clusterId}>
              <Col>
                <PrimaryButton
                  className="small"
                  loading={configureLoading}
                  onClick={() => {
                    if (viewMode) {
                      history.push(appUrls.SELECT_FEATURES(id, clusterId));
                    } else if(savedChanges) {
                      formRef.current.submit()
                    } else {
                      history.push(appUrls.SELECT_FEATURES(id, clusterId));
                    }
                    
                  }}
                >
                  Next
                </PrimaryButton>
              </Col>
            </ConditionalRender>
            <ConditionalRender shouldRender={!!!clusterId}>
              <Col>
                <PrimaryButton loading={configureLoading} className="small" onClick={() => formRef.current.submit()}>
                  Save and continue
                </PrimaryButton>
              </Col>
            </ConditionalRender>
          </Row>
        </Col>
      </Row>
      <h3>Hello there, data explorer! ⭐</h3>
      <h3>
        Ready to dive into the world of clustering? Our magical app will help you create clusters automatically, just like that! Buckle up,
        and let's get started on this exciting journey. 🚀
      </h3>
      <div className={Style.formContainer} style={{ flexGrow: "1" }}>
        <Form
          initialValues={initialValues}
          ref={formRef}
          labelAlign="left"
          requiredMark={false}
          layout="vertical"
          scrollToFirstError
          onFinish={(val) => {
            createClusterConfig(val);
          }}
          onValuesChange={(changedValues, allValues) => {
            if (!savedChanges) {
              setSavedChanges(true);
            }
            if (changedValues?.description) {
              setEditDescription(true);
            }
            if (changedValues?.input_table) {
              getAudienceSize(changedValues?.input_table, allValues?.clusters_type);
            }
            if (changedValues?.clusters_type) {
              if (!editDescription) {
                if (clusteringConfig) {
                  formRef.current.setFieldsValue({
                    description: clusteringConfig?.[changedValues?.clusters_type],
                  });
                }
              }
              if (allValues?.input_table) {
                getAudienceSize(allValues?.input_table, changedValues?.clusters_type);
              }
            }
          }}
        >
          <Row gutter={[30, 0]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Give your clusters a crafty name!"
                rules={[
                  {
                    validator: (_, value) => {
                      if (value) {
                        if (value.trim().length === 0) {
                          return Promise.reject(new Error("Cluster name is required"));
                        } else {
                          const regex = /^[a-zA-Z0-9 ]+$/;
                          if (!regex.test(value)) {
                            return Promise.reject(new Error("Cluster name should only contain alphanumeric characters and spaces"));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                  { required: true, message: "Cluster Name is required" },
                ]}
              >
                <Input placeholder="Enter name" disabled={editMode || viewMode} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="clusters_type"
                label="What approach would you like to take here? Acquisition will identify audiences outside of your customer base that look like your current customers, while CRM will identify audiences within your first party customer data."
                rules={CustomGptService.validations({ required: true }, "Clusters Type")}
              >
                <Select placeholder="Select Cluster Type" disabled={editMode || viewMode}>
                  {CLUSTERING_TYPE.map((item, idx) => {
                    return (
                      <Select.Option value={item} key={`option-${idx}`}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Alright, visionary! We need a bit of content before we start. 📝 Please provide some context and objectives for the
                clustering. This will help us (and AI) tailor the clusters perfectly for you. 🎯"
                rules={CustomGptService.validations({ required: true }, "Description")}
              >
                <TextArea autoSize={{ maxRows: 6, minRows: 3 }} placeholder="Enter description" disabled={viewMode} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="input_table"
                label="This is the data table that will do the magic. Only change if you have a customized table created for you"
                rules={CustomGptService.validations({ required: true }, "Table")}
              >
                <Select placeholder="Select Table" showSearch loading={audienceSizeLoading} disabled={editMode || viewMode}>
                  {table.map((item, idx) => {
                    return (
                      <Select.Option value={item} key={`option-${idx}`}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <h4 className={Style.audienceSize}>
                <i>
                  Audience size:&nbsp;{audienceSizeLoading ? <Spin /> : audienceCount ? <b>{numberWithCommas(audienceCount)}</b> : "N/A"}
                </i>
              </h4>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
