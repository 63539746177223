import { css } from "@linaria/core";

const floatLabel = css`
  position: relative;
  // margin-bottom: 12px;
  background: inherit;
`;

const label = css`
  font-size: 12px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 12px;
  transition: 0.2s ease all;
`;


const labelFloat = css`
  top: -0.5rem;
  font-size: 10px;
  background: inherit;
  padding: 0px 5px;
  .anticon-info-circle {
    pointer-events: all;
    font-size: 8px !important;
  }
}
`;

const helpIcon = css`
  &.anticon-info-circle {
    pointer-events: all;
    font-size: 10px;
    transition: 0.2s ease all;
  }
`;

export default {
  floatLabel,
  label,
  labelFloat,
  helpIcon,
};

