import React from "react";
import useCloneFormHook from "./useCloneFormHook";
import { Col, Form, Input, Row } from "antd";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import LoadingSpinner from "src/components/loadingSpinner";

export default function CloneForm({ setCloneModal, history, match }) {
  const { loading, name, formRef, cloneCluster, configureLoading } = useCloneFormHook({ history, match });
  if (loading) {
    return (
      <div style={{ height: "200px" }} >
        <LoadingSpinner  />
      </div>
    );
  }
  return (
    <div>
      <Form 
        initialValues={{ clone_clusters_config_name: name }} 
        ref={formRef}
        onFinish={cloneCluster}
      >
        <Form.Item
          name="clone_clusters_config_name"
          label="Cluster Name"
          rules={[
            {
              required: true,
              message: "Please enter name",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Row justify="space-between" style={{ marginTop: "5rem" }}>
          <Col>
            <SecondaryButton disabled={configureLoading} onClick={() => setCloneModal(false)} className="small">
              Cancel
            </SecondaryButton>
          </Col>

          <Col>
            <PrimaryButton loading={configureLoading} className="small" onClick={() => formRef.current.submit()}>
              Done
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
