import { List, Modal, Row, Select, Skeleton } from "antd";
import React, { useContext } from "react";
import { PrimaryButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useUserAndPermissionHook from "../hooks/useUserAndPermissionHook";
import AddUserForm from "./AddUserForm";
import UserListItem from "./UserListItem";
import Style from "./usersAndPermissions.style";
import { PageLayoutContext } from "src/context/pageLayoutContext";

export default function UsersAndPermissions({ id , backToChatAppHandler}) {
  const { loading, userData, roleChange, removeUser, actionLoading, addUserModal, setAddUserModal, addUser } = useUserAndPermissionHook({
    id,
  });
  const {inDesktop} = useContext(PageLayoutContext);
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 10 }} />
      </ConditionalRender>
      {!loading && (
        <>
          <Row justify="end" className={Style.btnsContainer}>
            <PrimaryButton
                className="small"
                onClick={backToChatAppHandler}>
                Go to assistant
            </PrimaryButton>
            <SecondaryButton className="small" onClick={() => setAddUserModal(true)} >
              Add user
            </SecondaryButton>
          </Row>
          <List
            className={Style.list}
            dataSource={userData}
            header={
              <ConditionalRender shouldRender={inDesktop}>
                <div className={Style.listHeader}>
                  <div style={{ width: "36%" }}>Email</div>
                  <div style={{ width: "30%" }}>Name</div>
                  <div style={{ width: "18%" }}>Role</div>
                  <div style={{ width: "16%" }}>Action</div>
                </div>
              </ConditionalRender>
            }
            renderItem={(item, idx) => {
              let name = "";
              if (item?.first_name) {
                name = name + item?.first_name;
              }
              if (item?.last_name) {
                name = name + " " + item?.last_name;
              }
              return <UserListItem key={`user-list-item-${idx}`} item={item} name={name} roleChange={roleChange} removeUser={removeUser} actionLoading={actionLoading} />;
            }}
          />
          <Modal
            maskClosable={true}
            destroyOnClose
            open={addUserModal}
            onCancel={() => setAddUserModal(false)}
            footer={null}
          >
            <AddUserForm addUser={addUser} />
          </Modal>
        </>
      )}
    </div>
  );
}
