import React from 'react';
import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;

const DateRangePickerWithPresets = ({
  presets,
  onRangeChange,
  onBlur,
  disabled,
  className,
  format,
  value
}) => (
  <Space direction='vertical' size={12}>
    <RangePicker
      ranges={presets?.reduce((acc, preset) => {
        acc[preset.label] = preset.value;
        return acc;
      }, {})}
      format={format}
      className={className}
      onChange={onRangeChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
    />
  </Space>
);

export default DateRangePickerWithPresets;
