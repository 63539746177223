import { Col, Modal, notification, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { PrimaryButton, SecondaryButton } from "src/components/basic";


const AppConfigModal = ({ appConfig, updateApps, configureLoading }) => {
  const [jsonData, setJsonData] = useState(appConfig?.data?.jsonData || {});
  const [addJsonModal, setAddJsonModal] = useState(false)
  const handleEdit = (edit) => {
    setJsonData(edit.updated_src);
    appConfig.data.updateData(appConfig.data.appType, "config", edit.updated_src,);
  };
  const [json, setJson] = useState("");
 
  return (
    <div style={{ height:'450px', overflowY: 'auto' }}>
      <Row justify="end" gutter={[20, 0]}>
        <Col>
          <SecondaryButton className="small" onClick={() => {
            setJson("");
            setAddJsonModal(true)
            }}>
            Import JSON
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton loading={configureLoading} className="small" comfy  onClick={() => updateApps(appConfig?.data?.appType, "config", jsonData)}> 
            Save
          </PrimaryButton>
        </Col>
      </Row>
      <ReactJson
        src={jsonData}
        onEdit={handleEdit}
        onAdd={handleEdit}
        onDelete={handleEdit}
        theme="monokai"
        style={{ padding: "20px" }}
        displayDataTypes={false}
        displayObjectSize={true}
        enableClipboard={true}
      />
      <Modal
        title="Import JSON"
        maskClosable={false}
        destroyOnClose
        open={addJsonModal}
        onCancel={() => setAddJsonModal(false)}
        footer={null}
        width="40%"
      >
        <TextArea 
          autoSize={{ minRows: 6, maxRows: 9 }}
          placeholder="Paste your JSON here"
          value={json}
          onChange={(val) => {
            setJson(val.target.value);
          }}
        />

        <Row justify="end" style={{marginTop: '1rem'}}>
          <Col>
          <PrimaryButton onClick={() => {
            try {
              const parsedJson = JSON.parse(json);
              setJsonData(parsedJson);
              setAddJsonModal(false);
            } catch (error) {
              notification.error({
                duration: 5,
                placement: "bottomRight",
                message: "Invalid JSON",
              })
            }
          }}>
            Import
          </PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AppConfigModal;
