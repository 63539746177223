import { Table } from 'antd';
import React from 'react';
import { formatString } from 'src/lib/utils';
import Style from './csvTable.style';

export default function CsvTable({ data }) {
  const columns = Object.keys(data[0]);
  const tableHeaders = [];
  columns?.forEach((item) => {
    tableHeaders.push({
      title: item,
      dataIndex: item,
      key: item,
      render: ele => ele,
    })
  })
  const dataSource = [];
  for(let i = 0; i < data.length && i < 10; i += 1) {
    dataSource.push(data[i]);
  }

  return (
    <div className={Style.tableContainer} >
      <Table className={Style.table} dataSource={dataSource} columns={tableHeaders} pagination={false}/>
    </div>
  )
}