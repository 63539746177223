import React, { useState } from 'react';
import { Row, Col, Card, Button, Input } from 'antd';
import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import useAudienceRecommendationHook from './useAudienceRecommendationHook';
import LoadingSpinner from 'src/components/loadingSpinner';
import { RULE_BASED } from '../../constants/constants';

const RecommendedAudiencesComponent = ({
    match,
    setShowRecommendation,
    setAudienceName,
    setAudienceDescription,
    setCurrentStep,
    setSelectedAudienceType
}) => {
    const clientId = match.params.clientId;
    const {
        recommendationLoading,
        audienceRecommendations,
        fetchRecommendation,
        refreshPrompt,
        setRefreshPrompt,
    } = useAudienceRecommendationHook(clientId);

    const handleCreate = (audience) => {
        setSelectedAudienceType(RULE_BASED);
        setAudienceName(audience.audience_name);
        setAudienceDescription(audience.audience_description);
        setCurrentStep(2);
    };

    if (recommendationLoading) {
        return <LoadingSpinner centered />;
    }

    return (
        <div style={{ padding: '20px', borderRadius: '8px', position: 'relative', width: '100%' }}>
            <Row style={{ marginTop: '20px', alignItems: 'center', position: 'relative', marginBottom: '20px' }}>
                <Col span={2}>
                    <Button
                        type="link"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => setShowRecommendation(false)}
                        style={{ padding: '0' }}
                    />
                </Col>
                <Col span={20}>
                    <Input
                        placeholder="Enter prompt"
                        value={refreshPrompt}
                        onChange={(e) => setRefreshPrompt(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={2}>
                    <Button
                        type="link"
                        icon={<ReloadOutlined />}
                        onClick={() => fetchRecommendation(false, refreshPrompt)}
                        style={{ padding: '0' }}
                    />
                </Col>
            </Row>
            {audienceRecommendations.length === 0 ? (
                <h2 style={{ color: '#ff4d4f', textAlign: 'center', marginBottom: '20px' }}>
                    No Recommendations Found
                </h2>
            ) : (
                <>
                    <h2 style={{ color: 'var(--color-primary)', textAlign: 'center', marginBottom: '20px' }}>
                        Recommended Audiences by AI
                    </h2>
                    <Row gutter={[16, 16]}>
                        {audienceRecommendations.map(audience => (
                            <Col span={8} key={audience.id}>
                                <Card
                                    title={audience.audience_name}
                                    bordered={false}
                                    style={{ backgroundColor: '#333', color: '#fff', borderRadius: '8px' }}
                                    headStyle={{ color: '#40a9ff' }}
                                    bodyStyle={{ padding: '20px' }}
                                >
                                    <p style={{ color: '#fff', marginBottom: '20px' }}>
                                        {audience.audience_description}
                                    </p>
                                    <p style={{ color: '#fff', marginBottom: '20px' }}>
                                        <b>Reasoning:</b> {audience.reasoning}
                                    </p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            type="primary"
                                            size="small"
                                            style={{ marginRight: '10px' }}
                                            onClick={() => handleCreate(audience)}
                                        >
                                            Proceed
                                        </Button>
                                        <a href="#" style={{ color: 'var(--color-cyan)' }}>
                                            View More Details
                                        </a>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </div>
    );
};

export default RecommendedAudiencesComponent;