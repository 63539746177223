import { css } from "@linaria/core";

const container = css`
  background: black;
  display: flex;
  flex-direction: column;
`;

const homePageContainer = css`
  position: relative;
  height: inherit;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 1.25rem;
  margin-bottom: 120px;
  flex-grow: 1;
  z-index: 1;
  @media screen and (min-width: 768px) {
    padding: 0 80px;
  }
`;

const skeletonCardContainer = css`
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  min-width: 163px;
  min-height: 163px;
  border: 1px solid var(--color-60-gray);
  background: var(--color-full-black);
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
  &:hover {
    border-color: var(--color-white);
    background: var(--color-charcoal-black) !important;
  }
  &:focus {
    border-color: var(--color-primary);
    background: var(--color-full-black) !important;
  }
  @media screen and (min-width: 768px) {
    max-width: 183px;
    max-height: 183px;
  }
`;

const homepageTitle = css`
  color: #dcdcdc;
  text-align: center;
  margin: 80px auto 60px;
  font-size: 1.268rem;
  @media screen and (min-width: 768px) {
    font-size: 1.714rem;
  }
`;

const homeCardContainer = css`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 586px) {
    max-width: 529px;
  }
  @media screen and (min-width: 786px) {
    max-width: 716px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1198px;
  }
`;

const decorativeBackgroundImage = css`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
  img {
    object-fit: cover;
    width: 160%;
    max-width: 1440px;
    transform: rotate(220deg) translate(-20%, 20%);
    transition: all 0.2s;
  }
  @media screen and (min-width: 786px) {
    img {
      width: 100%;
      transform: rotate(197deg) translate(-20%, 55%);
    }
  }
`;

const addClientCard = css`
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  min-width: 163px;
  min-height: 163px;
  border: 1px solid var(--color-60-gray);
  background: var(--color-full-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
    border-color: var(--color-white);
    background: var(--color-charcoal-surface-1) !important;
  }
  &:focus {
    opacity: 1;
    border-color: var(--color-primary);
    background: var(--color-charcoal-surface-2) !important;
  }
  @media screen and (min-width: 768px) {
    max-width: 183px;
    max-height: 183px;
  }
`;

const cardArrow = css`
  width: 45px;
  height: 45px;
  color: white;
  font-size: 1.7rem;
  transition: all 0.2s;
`;

export default {
  container,
  homePageContainer,
  skeletonCardContainer,
  homepageTitle,
  homeCardContainer,
  decorativeBackgroundImage,
  addClientCard,
  cardArrow
};
