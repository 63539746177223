import { css } from '@linaria/core';

const spinnerContainer = css`
  display:flex;
  width:100%;
  height:calc(100vh - 146px);
  background: transparent;
`;

const inlineDisplay = css`
  display: inline-flex;
`;

const blockDisplay = css`
  display: flex;
  width: 100%;
  text-align: center;
`;

const centered = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default {
  spinnerContainer,
  blockDisplay,
  centered,
  inlineDisplay,
};
