import { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Row, Tag, Tooltip, notification } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import NcApiServices from "../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { generatePayloadForNodeDetails } from "../utils";
import debounce from "lodash.debounce";
import Style from "../NewsAndCulture.style";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { analysisFilterObject, sentimentFilterObject } from "../constants";
import GlobalTag from "src/components/globalTag/GlobalTag";
import { TAG_COLOR_NAME } from "src/components/globalTag/globalTagConstant";
import { CustomCheckboxFilterDropdown } from "../components/CustomCheckboxFilterDropdown.jsx";
import moment from "moment";

export const useRawDataTable = () => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [filters, setFilters] = useState({
    headline: [],
    excerpt: [],
    interaction_score: [],
    ai_themes: [],
    ai_topics: [],
    ai_entities: [],
    ai_keywords: [],
    analysis_platform: [],
    ai_sentiment: [],
    ai_knowledge_graph: [],
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [detailDrawerVisible, setDetailDrawerVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [analysisConfigRawData, setAnalysisConfigRawData] = useState([]);
  const [drawerLoader, setDrawerLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });
  const { analysisId } = useParams();

  // Ensure search term triggers an API call
  const handleSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value);
    }, 800),
    []
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  useEffect(() => {
    if (analysisId) {
      getAnalysisConfigRawData(
        generatePayloadForNodeDetails({
          analysis_id: analysisId,
          nodes: filteredInfo.ai_knowledge_graphs || [],
          themes: filteredInfo.ai_themes || [],
          topics: filteredInfo.ai_topics || [],
          entities: filteredInfo.ai_entities || [],
          keywords: filteredInfo.ai_keywords || [],
          search_term: debouncedSearchTerm || null,
          analysis_type: filteredInfo.analysis_platform || null,
          sentiment: filteredInfo.ai_sentiment || null,
        }),
        pagination.current,
        pagination.pageSize
      );
    }
  }, [analysisId, pagination.current, debouncedSearchTerm, filteredInfo]);

  const getAnalysisConfigRawData = async (payload, page, pageSize) => {
    try {
      setDrawerLoader(true);
      const res = await NcApiServices.getAnalysisConfigRawData(
        payload,
        page,
        pageSize
      );
      if (res?.success) {
        setAnalysisConfigRawData(res?.data?.data);
        setTotalPages(res?.data?.total);
        setFilteredData(res?.data?.data); // Ensure data is set here
        setFilters({
          headline: generateFilters("headline", res?.data?.data),
          excerpt: generateFilters("excerpt", res?.data?.data),
          ai_themes: generateFilters("ai_themes", res?.data?.data, true),
          ai_topics: generateFilters("ai_topics", res?.data?.data, true),
          ai_entities: generateFilters("ai_entities", res?.data?.data, true),
          ai_keywords: generateFilters("ai_keywords", res?.data?.data, true),
          analysis_platform: analysisFilterObject,
          ai_sentiment: sentimentFilterObject,
          ai_knowledge_graphs: getNodeForFilters(res?.data?.data),
        });
      }
      setDrawerLoader(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setDrawerLoader(false);
    }
  };

  const getNodeForFilters = (data) => {
    if (data && data.length > 0) {
      const allNodes = data.flatMap((iter) =>
        iter?.processed_data?.ai_knowledge_graphs?.flatMap((i) => [
          i?.head_node,
          i?.tail_node,
        ])
      );
      const uniqueNodesSet = new Set(allNodes);
      const uniqueNodes = Array.from(uniqueNodesSet).map((value) => ({
        text: value,
        value,
      }));
      return uniqueNodes;
    }
    return [];
  };

  const handleTableChange = (pagination, filters) => {
    setFilteredInfo(filters);
    setPagination(pagination);
  };

  const generateFilters = (key, data, isArray = false) => {
    const uniqueValues = isArray
      ? [...new Set(data.flatMap((item) => item.processed_data[key]))]
      : [...new Set(data.map((item) => item.processed_data[key]))];

    return uniqueValues
      .filter((value) => value !== undefined && value !== null)
      .map((value) => ({
        text: truncateText(value, 100),
        value,
      }));
  };

  const truncateText = (text, length) => {
    if (typeof text !== "string") return "";
    return text.length > length ? `${text.slice(0, length)}...` : text;
  };

  const columns = useMemo(
    () => [
      {
        title: "Published Date",
        dataIndex: ["processed_data", "publish_date"],
        key: "publish_date",
        render: (text) =>
          text ? moment(text).format("LLL",) : <span>---</span>,
        width: "150px",
      },
      {
        title: "Headline/Excerpt",
        dataIndex: ["processed_data"],
        key: "headline",
        render: (processed_data) => {
          const { headline, excerpt } = processed_data;
          return headline ? (
            <Tooltip title={headline}>{`${headline.slice(0, 100)}...`}</Tooltip>
          ) : excerpt ? (
            <Tooltip title={excerpt}>{`${excerpt.slice(0, 100)}...`}</Tooltip>
          ) : (
            <span>---</span>
          );
        },
        width: "300px",
      },
      {
        title: "Platform Details",
        dataIndex: "analysis_platform",
        key: "analysis_platform",
        filters: filters.analysis_platform,
        filteredValue: filteredInfo.analysis_platform || null,
        onFilter: (value, record) =>
          record.analysis_platform === null
            ? record.analysis_type.includes(value)
            : record.analysis_platform.includes(value),
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.analysis_platform}
          />
        ),
        render: (_, record) => {
          const { link, publisher } = record.processed_data;
          const { analysis_platform } = record;
          return (
            <div>
              <p>
                <b>Platform Name:</b>{" "}
                {analysis_platform !== null ? analysis_platform : "news"}
              </p>
              <p>
                <b>Publisher: </b>
                {publisher ? publisher : "---"}
              </p>
              <p>
                <b>View Article:</b>
                <Button
                  type="link"
                  icon={<LinkOutlined />}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: "none" }}
                />
              </p>
            </div>
          );
        },
        width: "200px",
      },
      {
        title: "Themes",
        dataIndex: ["processed_data", "ai_themes"],
        key: "ai_themes",
        filters: filters.ai_themes,
        filteredValue: filteredInfo.ai_themes || null,
        onFilter: (value, record) =>
          record.processed_data.ai_themes.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_themes}
          />
        ),
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.BLUE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: "200px",
      },
      {
        title: "Topics",
        dataIndex: ["processed_data", "ai_topics"],
        key: "ai_topics",
        filters: filters.ai_topics,
        filteredValue: filteredInfo.ai_topics || null,
        onFilter: (value, record) =>
          record.processed_data.ai_topics.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_topics}
          />
        ),
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.GREEN} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: "200px",
      },
      {
        title: "Entities",
        dataIndex: ["processed_data", "ai_entities"],
        key: "ai_entities",
        filters: filters.ai_entities,
        filteredValue: filteredInfo.ai_entities || null,
        onFilter: (value, record) =>
          record.processed_data.ai_entities.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_entities}
          />
        ),
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.ORANGE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: "200px",
      },
      {
        title: "Keywords",
        dataIndex: ["processed_data", "ai_keywords"],
        key: "ai_keywords",
        filters: filters.ai_keywords,
        filteredValue: filteredInfo.ai_keywords || null,
        onFilter: (value, record) =>
          record.processed_data.ai_keywords.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_keywords}
          />
        ),
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.PURPLE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: "200px",
      },
      {
        title: "Nodes",
        dataIndex: ["processed_data", "ai_knowledge_graphs"],
        key: "ai_knowledge_graphs",
        filters: filters.ai_knowledge_graphs,
        filteredValue: filteredInfo.ai_knowledge_graphs || null,
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_knowledge_graphs}
          />
        ),
        onFilter: (value, record) =>
          record.processed_data.ai_knowledge_graphs.includes(value),
        filterSearch: true,
        render: (_, record) => {
          const { ai_knowledge_graphs } = record.processed_data;
          const nodes = Array.from(
            new Set(
              ai_knowledge_graphs?.flatMap((iter, idx) => [
                iter?.head_node,
                iter?.tail_node,
              ])
            )
          );
          return (
            <div className={Style.tagsContainer}>
              {nodes.map((tag, index) => (
                <GlobalTag color={TAG_COLOR_NAME.CYAN} key={index}>
                  {tag}
                </GlobalTag>
              ))}
            </div>
          );
        },
        width: "200px",
      },
      {
        title: "Sentiment",
        dataIndex: ["processed_data", "ai_sentiment"],
        key: "ai_sentiment",
        filters: filters.ai_sentiment,
        filteredValue: filteredInfo.ai_sentiment || null,
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown
            {...props}
            filters={filters.ai_sentiment}
          />
        ),
        onFilter: (value, record) =>
          record.processed_data.ai_sentiment === null
            ? false
            : record.processed_data.ai_sentiment.includes(value),
        filterDropdownStyle: { width: 300 },
        render: (text) =>
          text ? (
            <span style={{ textTransform: "capitalize" }}>{text}</span>
          ) : (
            <span>---</span>
          ),
        width: "200px",
      },
      {
        title: "Social Interaction",
        key: "social_interaction",
        render: (_, record) => {
          const { likes, shares, views } = record.processed_data;
          const { interaction_score } = record;
          return (
            <div>
              <p>
                <b>Likes:</b> {likes !== null ? likes : "---"}
              </p>
              <p>
                <b>Shares:</b> {shares !== null ? shares : "---"}
              </p>
              <p>
                <b>Views:</b> {views !== null ? views : "---"}
              </p>
              <p>
                <b>Score:</b>{" "}
                {interaction_score !== null ? interaction_score : "---"}
              </p>
            </div>
          );
        },
        width: "200px",
      },
      {
        title: "",
        key: "view",
        render: (_, record) => (
          <div className={Style.buttonLayout}>
            <Button
              type="link"
              style={{ border: "none" }}
              onClick={() => {
                setSelectedArticle(record);
                setDetailDrawerVisible(true);
              }}
            >
              View Detail
            </Button>
          </div>
        ),
        width: "200px",
      },
    ],
    [filteredInfo, filters]
  );

  return {
    columns,
    filteredData,
    globalSearch: searchTerm,
    setGlobalSearch: setSearchTerm,
    handleTableChange,
    detailDrawerVisible,
    setDetailDrawerVisible,
    selectedArticle,
    setSelectedArticle,
    drawerLoader,
    pagination,
    totalPages,
  };
};
