import { useState } from "react";
import AppUrls from "src/constants/appUrls";
import audienceListService from "../../services/audienceListService";

export default function useMediaPlanHook(id, history) {
  const [loading, setLoading] = useState(false);
  const [mediaPlanId, setMediaPlanId] = useState("");

  const createMediaplanRecommendations = async (paylod) => {
    try {
      setLoading(true);
      const res = await audienceListService.createMediaplanRecommendation(
        paylod
      );
      setMediaPlanId(res.data);
      history.push(AppUrls.MEDIA_PLAN_DASH(id, res.data.response_id));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return {
    loading,
    createMediaplanRecommendations,
  };
}
