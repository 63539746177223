import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { notification } from "antd";
import moment from "moment";
import { debounce } from "lodash";
import { defaultPlatformsSelected } from "../../constants";
import NcApiServices from "../../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";

const useQuadrantChart = (analysisOverview) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [highlightedPost, setHighlightedPost] = useState(null);
  const [handles, setHandles] = useState([]);
  const [pageHandle, setPageHandle] = useState(1);
  const [hasMoreHandles, setHasMoreHandles] = useState(true);
  const [searchTextHandle, setSearchTextHandle] = useState("");
  const [loadingHandlesOptions, setLoadingHandlesOptions] = useState(false);
  const [filteredHandles, setFilteredHandles] = useState([]);
  const [volumeAxis, setVolumeAxis] = useState(null);
  const [efficiencyAxis, setEfficiencyAxis] = useState(null);
  const [handlesTotalData, setHandlesTotalData] = useState({
    total: null,
    total_pages: null,
  });
  const [colorBy, setColorBy] = useState("platform");
  const [sizeBy, setSizeBy] = useState("comments");
  const [filterData, setFilterData] = useState({
    volume_metric: "likes",
    efficiency_metric: "comments",
    platforms: defaultPlatformsSelected,
  });
  const [filterApplyTimestamp, setFilterApplyTimestamp] = useState(null);
  const echartsInstance = useRef(null);

  const minStartDate = useMemo(() => {
    return moment.min(analysisOverview?.section_data.map((item) => moment(item.start_date)));
  }, [analysisOverview]);

  const numberFormatter = new Intl.NumberFormat("en-US");

  const handleFilterChange = (entity, value) => {
    switch (entity) {
      case "date_range":
        value = [moment(moment(value[0]).startOf("day")), moment(moment(value[1]).endOf("day"))];
        setFilterData((prev) => ({
          ...prev,
          start_date: moment(moment(value[0]).startOf("day")),
          end_date: moment(moment(value[1]).endOf("day")),
        }));
        break;
      default:
        setFilterData((prev) => ({
          ...prev,
          [entity]: value,
        }));
    }
  };

  const handleApply = async () => {
    if (!analysisOverview) {
      return;
    }
    setFilterApplyTimestamp(new Date());
    if (!filterData.volume_metric || !filterData.efficiency_metric) {
      notification.warning({
        message: "Please select both Volume and Efficiency",
        placement: "bottomRight",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await NcApiServices.getQuadrantData(analysisOverview.id, filterData);

      if (response.success) {
        if (filterData?.handles?.length !== 0) {
          setFilteredHandles(filterData.handles);
        } else {
          setFilteredHandles([]);
          if (colorBy === "handles") {
            setColorBy("platform");
          }
        };
        setChartData(response.data.posts);
        setVolumeAxis(response.data.volume_cutoff);
        setEfficiencyAxis(response.data.mean_efficiency);
        if (!filterData.start_date || !filterData.end_date) {
          setFilterData((prev) => ({
            ...prev,
            start_date: response.data.start_date,
            end_date: response.data.end_date,
          }));
        }
      } else {
        notification.error({
          message: "Failed to fetch data",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error fetching data",
        description: error.message,
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    handleApply();
  }, [analysisOverview]);

  const options = useMemo(() => {
    const maxVolume = Math.max(...chartData.map((item) => item.volume)) * 1.2; // Add some padding
    const maxEfficiency = Math.max(...chartData.map((item) => item.efficiency)) * 1.2; // Add some padding
    const categories = {
      section: {
        categories: analysisOverview?.section_data.map((item) => item.section_name),
        key: "section_name",
      },
      post_sentiment: {
        categories: ["Positive", "Neutral", "Negative", "Unknown"],
        key: "sentiment",
      },
      comment_sentiment: {
        categories: ["Positive", "Neutral", "Negative", "Unknown"],
        key: "comment_sentiment",
      },
      platform: {
        categories: defaultPlatformsSelected,
        key: "platform",
      },
    };
    if (filteredHandles?.length !== 0) {
      categories.handles = {
        categories: filteredHandles,
        key: "handle",
      };
    }
    const sizeByOptions = {
      comments: {
        key: "comments",
      },
      comments_normalized: {
        key: "comments",
        is_normalized: true,
        normalized_by_key: "volume",
      },
    };
    var symbolSize = (data) => 10;
    if (sizeByOptions[sizeBy]) {
      const maxValue = Math.max(
        ...chartData.map((item) =>
          sizeByOptions[sizeBy].is_normalized
            ? item[sizeByOptions[sizeBy].normalized_by_key]
              ? item[sizeByOptions[sizeBy].key] / item[sizeByOptions[sizeBy].normalized_by_key]
              : 0
            : item[sizeByOptions[sizeBy].key],
        ),
      );
      const minValue = Math.min(
        ...chartData.map((item) =>
          sizeByOptions[sizeBy].is_normalized
            ? item[sizeByOptions[sizeBy].normalized_by_key]
              ? item[sizeByOptions[sizeBy].key] / item[sizeByOptions[sizeBy].normalized_by_key]
              : 0
            : item[sizeByOptions[sizeBy].key],
        ),
      );
      symbolSize = (data) => {
        let value = data[2][sizeByOptions[sizeBy].key];
        if (sizeByOptions[sizeBy].is_normalized) {
          const normalizedByValue = data[2][sizeByOptions[sizeBy].normalized_by_key];
          value = value / normalizedByValue;
        }
        return 10 + ((value - minValue) / (maxValue - minValue)) * 50; // Adjust the multiplier as needed for appropriate sizing
      };
    }
    var legend = {};
    var sections = [
      {
        type: "scatter",
        markArea: {
          silent: true,
          data: [
            [
              {
                name: "Q1 - Expand",
                xAxis: volumeAxis,
                yAxis: efficiencyAxis,
                itemStyle: {
                  color: "rgba(0, 255, 0, 0.07)",
                },
                label: {
                  show: true,
                  position: "insideTopRight",
                  formatter: "Q1 - Expand",
                  color: "#fff",
                },
              },
              {
                xAxis: maxVolume,
                yAxis: maxEfficiency,
              },
            ],
            [
              {
                name: "Q2 - Reduce",
                xAxis: volumeAxis,
                yAxis: -1000000,
                itemStyle: {
                  color: "rgba(255, 255, 0, 0.07)",
                },
                label: {
                  show: true,
                  position: "insideBottomRight",
                  formatter: "Q2 - Reduce",
                  color: "#fff",
                },
              },
              {
                xAxis: maxVolume,
                yAxis: efficiencyAxis,
              },
            ],
            [
              {
                name: "Q3 - Eliminate",
                xAxis: -1000000,
                yAxis: -1000000,
                itemStyle: {
                  color: "rgba(255, 0, 0, 0.07)",
                },
                label: {
                  show: true,
                  position: "insideBottomLeft",
                  formatter: "Q3 - Eliminate",
                  color: "#fff",
                },
              },
              {
                xAxis: volumeAxis,
                yAxis: efficiencyAxis,
              },
            ],
            [
              {
                name: "Q4 - Boost",
                xAxis: -1000000,
                yAxis: efficiencyAxis,
                itemStyle: {
                  color: "rgba(255, 255, 0, 0.07)",
                },
                label: {
                  show: true,
                  position: "insideTopLeft",
                  formatter: "Q4 - Boost",
                  color: "#fff",
                },
              },
              {
                xAxis: volumeAxis,
                yAxis: maxEfficiency,
              },
            ],
          ],
        },
      },
    ];
    if (categories[colorBy]) {
      legend = {
        data: categories[colorBy].categories.map((category, index) => ({
          name: category,
          itemStyle: {
            ...(categories[colorBy].colors ? { color: categories[colorBy].colors[index] } : {}),
          },
        })),
        type: "scroll",
        // backgroundColor: "#262626",
        textStyle: {
          color: "#fff",
        },
        pageIconColor: "#fff",
        pageTextStyle: {
          color: "#fff",
        },
      };
      categories[colorBy].categories.forEach((category, index) => {
        sections.push({
          name: category,
          zlevel: 2,
          type: "scatter",
          data: chartData
            .filter((item) => item[categories[colorBy].key]?.toLowerCase() === category.toLowerCase())
            .map((item) => [item.volume, item.efficiency, item]),
          symbolSize: symbolSize,
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "rgba(255, 255, 255, 0.7)",
          },
        });
      });
    } else {
      sections = [
        {
          type: "scatter",
          zlevel: 2,
          data: chartData.map((item) => [item.volume, item.efficiency, item]),
          symbolSize: (data) => {
            const commentsNormalized = data[2].comments_normalized; // Access comments_normalized from the data array
            return 5 + commentsNormalized * 50; // Adjust the multiplier as needed for appropriate sizing
          },
          label: {
            show: false,
            formatter: (params) => params.data[2], // Show content as label
          },
          itemStyle: {
            borderColor: "rgba(255, 255, 255, 0.7)",
          },
        },
      ];
    }

    return {
      color: [
        "#dd4444",
        "#289361",
        "#fec42c",
        "#5470C6",
        "#e88484",
        "#ea7ccc",
        "#d86736",
        "#9A60B4",
        "#8cd6b5",
        "#f59f00",
        "#f7f7f7",
      ],
      backgroundColor: "#262626",
      legend: legend,
      tooltip: {
        enterable: true,
        renderType: "html",
        backgroundColor: "transparent",
        borderColor: "none",
        padding: 0,
        formatter: function (param) {
          var value = param.value;
          if (!value?.[2]?.url) {
            return "";
          }
          // prettier-ignore
          return `
            <div style="
              max-width: 350px;
              word-wrap: break-word;
              overflow-wrap: break-word;
              white-space: normal;
              max-height: 300px;
              overflow-y: auto;
              font-size: 13px;
              user-select: text;
              padding: 0;
              background-color: #1e1e1e;
              box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
              font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, sans-serif;
              color: #ffffff;
            ">
              <div style="padding: 16px;">
                <a href="${value[2].url}" 
                  target="_blank" 
                  style="
                    color: #60a5fa;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1.4;
                    display: block;
                    margin-bottom: 16px;
                  ">${value[2].content}</a>

                <div style="
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 12px;
                  margin-bottom: 16px;
                ">
                  <div style="
                    background: #2d2d2d;
                    padding: 12px;
                    border-radius: 8px;
                  ">
                    <div style="
                      color: #a0a0a0;
                      font-size: 10px;
                      margin-bottom: 4px;
                      text-transform: capitalize;
                    ">Volume: ${filterData?.volume_metric}</div>
                    <div style="font-weight: 500;">${numberFormatter.format(value[0])}</div>
                  </div>

                  <div style="
                    background: #2d2d2d;
                    padding: 12px;
                    border-radius: 8px;
                  ">
                    <div style="
                      color: #a0a0a0;
                      font-size: 10px;
                      margin-bottom: 4px;
                      text-transform: capitalize;
                    ">Efficiency: ${filterData?.efficiency_metric}</div>
                    <div style="font-weight: 500;">${numberFormatter.format(value[1])}</div>
                  </div>
                </div>
              </div>
            </div>
          `;
        },
      },
      toolbox: {
        right: 20,
        feature: {
          dataZoom: { show: true },
          saveAsImage: { show: true },
          dataView: { show: true },
        },
      },
      xAxis: {
        type: "value",
        name: "Volume",
        axisLine: {
          onZero: true,
          lineStyle: {
            width: 0.1,
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 0.1,
          },
        },
        min: 0,
        max: maxVolume,
      },
      yAxis: {
        type: "value",
        name: "Efficiency",
        axisLine: {
          onZero: true,
          lineStyle: {
            width: 0.1,
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 0.1,
          },
        },
        min: 0,
        //max: maxEfficiency,
        // round it to nearest 10 power
      },
      series: [
        ...sections,
        {
          type: "line",
          markLine: {
            animation: false,
            data: [
              {
                name: "Line 1",
                xAxis: `${volumeAxis}`,
                lineStyle: {
                  color: "#ffffff92",
                  width: 3,
                  type: "solid",
                },
                label: {
                  formatter: `${numberFormatter.format(volumeAxis)}`,
                },
                emphasis: {
                  disabled: true,
                },
              },
            ],
          },
        },
        {
          type: "line",
          markLine: {
            animation: false,
            data: [
              {
                name: "Line 2",
                yAxis: `${efficiencyAxis}`,
                lineStyle: {
                  color: "#ffffff92",
                  width: 3,
                  type: "solid",
                },
                label: {
                  formatter: `${numberFormatter.format(efficiencyAxis)}`,
                },
                symbolOffset: [0, 0],
              },
            ],
          },
        },
      ],
    };
  }, [chartData, colorBy, sizeBy, volumeAxis, efficiencyAxis, filteredHandles]);

  const chartEvents = useMemo(
    () => ({
      click: (params) => {
        if (params.value?.length) {
          setHighlightedPost(params.value[2].id);
        } else {
          setHighlightedPost(null);
        }
      },
    }),
    [setHighlightedPost],
  );

  const handleScrollToEndHandle = useCallback(() => {
    if (hasMoreHandles && !loadingHandlesOptions && pageHandle < handlesTotalData?.total_pages) {
      setPageHandle((prevPage) => prevPage + 1);
    }
  }, [loadingHandlesOptions, hasMoreHandles, pageHandle, handlesTotalData]);

  const handlePopupScrollHandle = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      handleScrollToEndHandle();
    }
  };

  const handleSearchHandle = useCallback(
    debounce((value) => {
      console.log("search");
      setPageHandle(1);
      setSearchTextHandle(value);
    }, 800),
    [],
  );

  const handleDropdownVisibleChangeHandle = () => {
    setPageHandle(1);
    setSearchTextHandle("");
  };

  const fetchHandlesOptions = useCallback(async () => {
    if (!analysisOverview) {
      return;
    }
    try {
      setLoadingHandlesOptions(true);
      const res = await NcApiServices.getNodesOptions({
        analysis_id: analysisOverview?.id,
        page_size: 10,
        page: pageHandle,
        search_text: searchTextHandle,
        start_date: filterData?.start_date,
        end_date: filterData?.end_date,
        platforms: filterData?.platforms.join(","),
        column_name: "handles",
      });
      const newOptions = res.data.data;
      setHandlesTotalData({
        total: res.data.total,
        total_pages: res.data.total_pages,
      });
      if (searchTextHandle || pageHandle === 1) {
        setHandles(newOptions);
      } else {
        setHandles((prevOptions) => [...prevOptions, ...newOptions.filter((handle) => !prevOptions.includes(handle))]);
      }
      setLoadingHandlesOptions(false);
      setHasMoreHandles(newOptions.length > 0);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      if (msg?.request?.status === 404 && pageHandle !== 1) {
        setPageHandle(1);
        setLoadingHandlesOptions(false);
        return;
      }
      console.log(msg);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoadingHandlesOptions(false);
    } finally {
      setLoadingHandlesOptions(false);
    }
  }, [analysisOverview, pageHandle, searchTextHandle, filterData]);

  useEffect(() => {
    fetchHandlesOptions();
  }, [analysisOverview, filterData, searchTextHandle, pageHandle]);

  useEffect(() => {
    if (echartsInstance.current) {
      echartsInstance.current.getEchartsInstance().setOption(options, true);
    }
  }, [options]);

  return {
    chartData,
    filterData,
    handleFilterChange,
    handleApply,
    loading,
    chartEvents,
    highlightedPost,
    setHighlightedPost,
    options,
    minStartDate,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    fetchHandlesOptions,
    handles,
    loadingHandlesOptions,
    colorBy,
    setColorBy,
    echartsInstance,
    sizeBy,
    setSizeBy,
    filteredHandles,
    filterApplyTimestamp,
  };
};

export default useQuadrantChart;
