import { css } from '@linaria/core';

const filters = css`
  margin: 1rem 0;
  .ant-input {
    width: 100%;
    padding: 0.5rem;
  }
`;

const listContainer = css`
  .ant-list-header{
    border-bottom:none !important;
  }
`;

const listHeader = css`
  display: none;
  flex-direction: row;
  font-weight: 600;
  border: none;
  padding: 0.6rem 1rem;
  color: var(--color-white);
  @media screen and (min-width:768px) {
    display: flex;
  }
`;

const listItem = css`
  width:100%;
  margin-top: 1rem;
  padding: 0.6rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  align-items: center;
  border-radius: 0px;
  min-height: 60px;
  font-size: 14px;
  background: var(--color-charcoal-surface-1);
  box-shadow: none;
  border:none;
  text-align:left;
  color: var(--color-white);
  cursor:pointer;
  gap: 8px;

  .name,
  .description {
    width: 100%;
  }
  .status,
  .date {
    width: 50%;
  }

  &:hover {
    background: var(--color-charcoal-surface-1);
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media screen and (min-width:768px) {
    flex-wrap:nowrap;
    .name {
      width:20%;
    }
    .description {
      width:20%;
    }
    .type {
      width: 15%;
    }
    .status {
      width: 15%;
    }
    .date {
      width: 25%;
    }
  }
`;

const listItemContainer = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const deleteContainer = css`
  margin-top: 1.1rem;
  .anticon {
    color: red;
    cursor: pointer;
  }
`;

export default {
  filters,
  listContainer,
  listHeader,
  listItem,
  listItemContainer,
  deleteContainer
}