import { Spin, Typography } from 'antd';
import { cx } from 'linaria';
import * as React from 'react';
import Style from './index.style';

function LoadingSpinner({
  block,
  centered,
  showText = false,
  text,
  className,
  size = 'default',
  indicator,
}) {

  return (
    <div className={Style.spinnerContainer}>
      <Typography.Text
        className={cx('loading-text',
          block ? Style.blockDisplay : Style.inlineDisplay,
          centered && Style.centered,
          className,
        )}
      >
        <Spin
          size={size}
          style={{ lineHeight: 1, marginRight: '0.5rem'}}
          indicator={indicator}
        />
        {showText && (text || 'loading')}
      </Typography.Text>
    </div>
  );
}

export default LoadingSpinner;
