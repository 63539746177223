import { css } from "@linaria/core";

const container = css`
  min-width: 0;
  overflow: auto;
`;

const contentContainer = css`
  background: var(--color-full-black);
  z-index: 1;
  width: 100%;
  overflow: auto;
  justify-content: center;
  @media (max-width: 992px) {
    display: flex;
  }
`;

const leftContainer = css`
  position:relative;
  background: #000000;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const leftLogoContainer = css`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mainLoginContainer = css`
  display: flex; 
  flex-grow: 1;
  align-items: center; 
  flex-direction: column;
  width: 100%;
  padding-top:20vh;
  position: relative;
  z-index:1;
  @media (max-height: 500px) {
    padding-top:32px;
  }
`;

const rightContainer = css`
  border-radius: 10px;
  box-shadow: -3px 4px 8px rgb(41 29 74 / 15%);
  position: relative;
  width: 50%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  float: left;
  overflow: auto;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const redirectingTextContainer = css`
  position: absolute;
  text-align: center;
  bottom: 10%
  color: white;
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const mainLogoContainer = css`
  width: 450px;
  @media screen and (max-width: 768px) {
    width: 250px;
  }
  @media (max-height: 500px) {
    width: 250px;
  }
`;

const form =css`
  width:100%;
  display: flex;
  justify-content: center;
`;

const footer = css`
  color: white;
  background-color:black;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
`;
const decorativeBackgroundImage = css`
  pointer-events:none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: fit-content;
    
  }
  @media screen and (min-width: 768px) {
    img{
      width: 100%;
      object-fit: contain;
    }
  }
`;
export default {
  container,
  contentContainer,
  leftContainer,
  mainLoginContainer,
  rightContainer,
  leftLogoContainer,
  redirectingTextContainer,
  mainLogoContainer,
  form,
  footer,
  decorativeBackgroundImage
};
