import { Tabs } from "antd";
import React from "react";
import Style from "./customGpt.style";
import AppUrls from "src/constants/appUrls";
import CreateForm from "./CreateForm";
import Capabilities from "./Capabilities";
import FineTuningTool from "./capabilityTools/fineTuningTool/FineTuningTool";
import UsersAndPermissions from "./UsersAndPermissions";
import Conversations from "./capabilityTools/conversations/Conversations";
import Evaluation from "src/modules/evaluations/components/evaluation";

export default function CustomGptTabs({
  id,
  history,
  tabs,
  setTabs,
  initialValues,
  formRef,
  metaData,
  createGpt,
  createGptLoading,
  updateCustomApp,
  clientId,
  inCreateMode
}) {

  const backToChatApp = () =>{
    history.push(AppUrls.APP_PAGE(clientId, id))
  }

  return (
    <div className={Style.tabContainer}>
      <Tabs
        activeKey={tabs}
        onChange={(val) => {
          history.push(AppUrls.EDIT_CUSTOM_GPT(clientId, id, val));
          setTabs(val);
        }}
      >
        {initialValues?.version !== 'v1' && (
          <>
            <Tabs.TabPane tab="Configure" key='configure'>
              <CreateForm
                formRef={formRef}
                initialValues={initialValues}
                metaData={metaData}
                createGpt={createGpt}
                createGptLoading={createGptLoading}
                id={id}
                backToChatAppHandler={backToChatApp}
                clientId={clientId}
                history={history}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Capabilities" key='capability' disabled={!!!id}>
              <Capabilities
                metaData={metaData}
                initialValues={initialValues}
                id={id}
                updateCustomApp={updateCustomApp}
                history={history}
                clientId={clientId}
                backToChatAppHandler={backToChatApp}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Fine tuning" key='fine_tuning' disabled={!!!id}>
              <FineTuningTool
                id={id}
                aiAppData={initialValues}
                metaData={metaData}
                updateCustomApp={updateCustomApp}
                backToChatAppHandler={backToChatApp}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Evaluation" key='evaluate' disabled={!!!id}>
                <Evaluation 
                  appId={id}
                  backToChatAppHandler={backToChatApp}
                />
            </Tabs.TabPane>
          </>
        )}
        <Tabs.TabPane tab="Users" key='users' disabled={!!!id}>
          <UsersAndPermissions id={id} backToChatAppHandler={backToChatApp}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Feedback" key='feedback' disabled={!!!id}>
          <Conversations id={id} backToChatAppHandler={backToChatApp}/>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
