import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { PrimaryButton } from 'src/components/basic';

const Uploader = ({ onChange, initialImageUrl }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (initialImageUrl) {
      // Setup the initial file list with the image from URL
      setFileList([
        {
          uid: "-1", // a temporary id
          name: "initialImage.png", // a placeholder name
          status: "done",
          url: initialImageUrl,
          thumbUrl: initialImageUrl, // use thumbUrl to handle external URLs for previews
        },
      ]);
    }
  }, [initialImageUrl]);

  if (typeof onChange !== "function") {
    console.error("Expected onChange to be a function, but got:", typeof onChange);
    return null; // Prevents the component from rendering if onChange is not a function
  }

  const handleBeforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setFileList([
        {
          uid: file.uid,
          name: file.name,
          status: "done",
          url: e.target.result,
          thumbUrl: e.target.result, // preview from base64 data
        },
      ]);
      onChange(e.target.result); // sending base64 to the parent component
    };
    reader.readAsDataURL(file); // convert to base64
    return false; // prevent automatic upload
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === "removed") {
      onChange(null);
      setFileList([]);
    } else {
      // Update the fileList for previews
      setFileList(
        fileList.map((f) => ({
          ...f,
          thumbUrl: f.url || (f.originFileObj && URL.createObjectURL(f.originFileObj)),
        }))
      );
    }
  };

  return (
    <Upload
      listType="picture"
      fileList={fileList}
      maxCount={1}
      beforeUpload={handleBeforeUpload}
      onChange={handleChange}
      onRemove={() => setFileList([])}
      accept="image/*"
    >
      {fileList.length >= 1 ? null : <PrimaryButton comfy className="small" icon={<UploadOutlined />}>Upload</PrimaryButton>}
    </Upload>
  );
};

export default Uploader;