import { useEffect, useMemo, useState, useCallback } from "react";
import { notification } from "antd";
import NcApiServices from "../../services/NcApiServices";

const TrendLineChartHooks = ({ analysisId, filterData, filterApplyTimestamp }) => {
  const [trendData, setTrendData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!analysisId) return;
    try {
      setLoading(true);
      const response = await NcApiServices.getTrendData(analysisId, filterData);
      if (response?.success) {
        setTrendData(response.data.trend);
        setLoading(false);
      } else {
        notification.warning({
          message: "Failed to fetch trend data",
          placement: "bottomRight",
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error fetching trend data",
        description: error.message,
        placement: "bottomRight",
      });
    }
  }, [analysisId, filterData]);

  useEffect(() => {
    fetchData();
  }, [analysisId, filterApplyTimestamp]);

  const option = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        right: 20,
        feature: {
          dataZoom: { show: true },
          saveAsImage: { show: true },
          dataView: { show: true },
        },
      },
      legend: {
        data: ["Volume", "Efficiency", "Ratio"],
        textStyle: {
          color: "#fff",
        },
        pageTextStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        type: "category",
        data: trendData?.map((item) => item.metric_updated_on__date),
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Volume",
          type: "line",
          data: trendData?.map((item) => item.avg_volume),
          label: { color: "#fff" },
        },
        {
          name: "Efficiency",
          type: "line",
          data: trendData?.map((item) => item.avg_efficiency),
          label: { color: "#fff" },
        },
        {
          name: "Ratio",
          type: "line",
          data: trendData?.map((item) => item.avg_normalized_efficiency),
          label: { color: "#fff" },
        },
      ],
    }),
    [trendData],
  );

  return { loading, option, trendData };
};

export default TrendLineChartHooks;
