const AssistantIconChoices = [
  {
    name: 'Charts icons',
    icons: [
      'area_chart',
      'bid_landscape',
      'chart_data',
      'data_thresholding',
      'broken_image',
      'table_chart_view',
      'trending_up',
      'planner_review',
      'legend_toggle',
      'monitoring',
      'multiline_chart',
      'query_stats',
      'show_chart',
      'stacked_line_chart',
      'timeline',
      'network_ping',
      'troubleshoot',
      'data_exploration',
      'elevation',
      'add_chart',
      'analytics',
      'stacked_bar_chart',
      'signal_cellular_alt',
      'bar_chart',
      'bar_chart_4_bars',
      'finance',
      'full_stacked_bar_chart',
      'grouped_bar_chart',
      'leaderboard',
      'search_insights',
      'pie_chart',
      'donut_small',
      'donut_large',
      'data_usage',
      'perm_data_setting',
      'graphic_eq',
      'pivot_table_chart',
      'airline_stops',
      'blood_pressure',
      'insert_chart'
    ]
  },
  {
    name: 'Connection / abstract icons',
    icons: [
      'mitre',
      'family_history',
      'account_tree',
      'schema',
      'network_node',
      'linked_services',
      'activity_zone',
      'tenancy',
      'all_inclusive',
      'page_info',
      'alt_route',
      'api',
      'animation',
      'communities',
      'all_out',
      'center_focus_strong',
      'radar',
      'apps',
      'app_registration',
      'apps_outage',
      'select_all',
      'cyclone',
      'arrow_and_edge',
      'calendar_view_month',
      'online_prediction',
      'emoji_objects',
      'offline_bolt',
      'heat_pump',
      'hvac',
      'diamond',
      'lightbulb',
      'lightbulb_circle',
      'crisis_alert',
      'adjust',
      'highlight_mouse_cursor',
      'target',
      'track_changes',
      'point_scan',
      'hub',
      'lan',
      'conversion_path',
      'stream',
      'spoke',
      'batch_prediction',
      'switch_access',
      'draw_abstract',
      'ads_click',
      'select',
      'arrow_or_edge',
      'cadence',
      'contactless',
      'arrow_split',
      'keyboard_capslock_badge',
      'border_all',
      'ifl',
      'call_merge',
      'border_outer',
      'atr',
      'location_searching',
      'hexagon',
      'category',
      'extension',
      'drag_indicator',
      'shape_line',
      'shapes',
      'interests',
      'stars',
      'star_rate_half',
      'hotel_class',
      'star',
      'app_badging',
      'award_star',
      'azm',
      'blur_circular',
      'blur_linear',
      'bolt',
      'blur_on'
    ]
  },
  {
    name: 'User types icons',
    icons: [
      'deployed_code_account',
      'interactive_space',
      'person_book',
      'recent_actors',
      'sensor_occupied',
      'data_loss_prevention',
      'engineering',
      'familiar_face_and_zone',
      'cognition',
      'network_intelligence_history',
      'neurology',
      'groups_3',
      'diversity_2',
      'partner_exchange',
      'group',
      'diversity_3',
      'groups',
      'groups_2',
      'conditions',
      'connect_without_contact',
      'account_balance',
      'school',
      'support_agent',
      'for_you',
      'psychology',
      'handshake'
    ]
  },
  {
    name: 'Document / tech / utility  icons',
    icons: [
      'clinical_notes',
      'other_admission',
      'text_snippet',
      'dataset_linked',
      'demography',
      'description',
      'export_notes',
      'diagnosis',
      'contact_page',
      'document_scanner',
      'draft',
      'find_in_page',
      'note_add',
      'note_stack',
      'post_add',
      'article',
      'assignment',
      'assignment_add',
      'quick_reference',
      'assignment_ind',
      'two_pager',
      'lab_profile',
      'picture_in_picture_large',
      'stack_star',
      'bookmark_manager',
      'dictionary',
      'bento',
      'featured_play_list',
      'id_card',
      'badge',
      'switch_account',
      'heap_snapshot_thumbnail',
      'folder_shared',
      'folder_supervised',
      'folder_data',
      'folder',
      'snippet_folder',
      'folder_open',
      'folder_copy',
      'folder_special',
      'domain_verification',
      'toolbar',
      'table_chart',
      'dataset',
      'auto_awesome_mosaic',
      'thumbnail_bar',
      'dns',
      'dynamic_form',
      'storage',
      'database',
      'package_2',
      'team_dashboard',
      'style',
      'book_3',
      'grading',
      'breaking_news',
      'instant_mix',
      'display_settings',
      'assistant_on_hub',
      'smart_card_reader',
      'data_check',
      'user_attributes',
      'data_alert',
      'key_visualizer',
      'table',
      'rubric',
      'newspaper',
      'domain',
      'chat_info',
      'help_clinic',
      'precision_manufacturing',
      'architecture',
      'scanner',
      'robot_2',
      'robot',
      'smart_toy',
      'tv_with_assistant',
      'magnification_small',
      'format_align_justify',
      'space_dashboard',
      'laptop_chromebook',
      'book_4',
      'request_page',
      'breaking_news_alt_1',
      'tune',
      'background_dot_small',
      'background_grid_small',
      'cloud_circle',
      'cloud',
      'airplay',
      'online_prediction',
      'smb_share',
      'flowsheet',
      'notes',
      'segment',
      'subject',
      'tv_options_edit_channels',
      'vertical_split',
      'checklist_rtl',
      'checklist',
      'edit_note',
      'format_list_bulleted',
      'format_list_bulleted_add',
      'format_list_numbered',
      'format_list_numbered_rtl',
      'mist',
      'note_stack_add',
      'post',
      'scan',
      'bookmark_heart',
      'developer_guide',
      'book_5',
      'backup_table',
      'browse_activity',
      'dashboard_customize',
      'sheets_rtl',
      'sticky_note_2',
      'summarize',
      'text_snippet',
      'news',
      'note_alt',
      'stacks',
      'overview_key',
      'topic',
      'ad_group',
      'tab_group',
      'table_view',
      'manage_search',
      'mystery',
      'saved_search',
      'screen_search_desktop',
      'travel_explore',
      'token',
      'empty_dashboard',
      'beenhere',
      'bookmark_flag',
      'bookmark_star',
      'bookmarks',
      'military_tech',
      'social_leaderboard',
      'license',
      'workspace_premium',
      'checkbook',
      'ballot',
      'headset_mic',
      'forum',
      'quick_phrases',
      'speech_to_text',
      'outbox_alt',
      'business_messages',
      'flag',
      'eco'
    ]
  }
];

export default AssistantIconChoices