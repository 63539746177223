import React, { useContext, useEffect, useState } from "react";
import Style from "./clientSpace.style";
import CardStyle from "../../clientSpaceCard.style";
import AppContext from "src/context/appContext";
import { Col, Row, Skeleton } from "antd";
import Assets from "src/assets";
import ConditionalRender from "src/components/conditionalRender";
import AppUrls from "src/constants/appUrls";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import MicroAppCard from "../microAppCard/MicroAppCard";
import HorizontolLabelDivider from "../horizontalLabelDivider/HorizontalLabelDivider";
import { AssistantCard } from "../assistantCard/AssistantCard";
import useClientSpaceContainerHook from "../../useClientSpaceContainerHook";
import LoadingSpinner from "src/components/loadingSpinner";
import { PageLayoutContext } from "src/context/pageLayoutContext";
import { PrimaryButton } from "src/components/basic";

export default function ClientSpace({ history }) {
  const { clientId } = useParams();
  const { inDesktop } = useContext(PageLayoutContext);
  const [microApps, setMicroApps] = useState([]);
  const [clients, setClients] = useState([]);
  const [customUnApprovedApps, setCustomUnApprovedApps] = useState([]);
  const [clientName, setClientName] = useState("");
  const appContext = React.useContext(AppContext.Context);
  const [userEmail] = useState(appContext.metaData?.email);
  const [clientIcon, setClientIcon] = useState("");
  const { getClientData } = useClientSpaceContainerHook(false);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    setClientData(appContext?.clientSpaceData);
    if (!appContext?.clientSpaceData) {
      getClientData(clientId);
    }
  }, [appContext?.clientSpaceData]);

  useEffect(() => {
    if (clientData) {
      setClientIcon(clientData?.client_space?.black_white_icon);
      let name = clientData?.client_space?.name ?? "";
      name = name.replace("&", "");
      name = name.replace(" ", "");
      setClientName(name);
      let apps = [];
      clientData?.micro_apps?.forEach((item) => {
        apps.push(item);
      });
      setMicroApps(apps);

      let c = [];
      let unapproved = [];
      clientData?.ai_apps?.forEach((item) => {
        if (item.is_approved) {
          c.push(item);
        } else {
          unapproved.push(item);
        }
      });
      setClients(c);
      setCustomUnApprovedApps(unapproved);
    }
  }, [clientData, userEmail, clientId]);

  // Skeleton for the card grid
  const renderSkeletonCards = (count) => (
    <div style={{ margin: "4rem 0 2rem" }}>
      {/* <HorizontolDivider text='' /> */}
      <Row gutter={[20, 20]}>
        {Array.from({ length: count }, (_, index) => (
          <Col xs={12} lg={4} key={`client-card-${index}`}>
            <div className={Style.appCardContainer}>
              <Skeleton.Avatar style={{ width: 100, height: 100, marginBottom: "16px" }} />
              <Skeleton active title={false} paragraph={{ rows: 2 }} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );

  return (
    <>
      <div className={Style.container}>
        {!clientData ? (
          <LoadingSpinner centered={true}></LoadingSpinner>
        ) : (
          <div className={Style.clientSpaceContainer}>
            <div className={Style.aiAppsContainer}>
              <div className={Style.lefLogoContainer}>
                <ConditionalRender shouldRender={clientIcon?.length > 0}>
                  <img className={Style.clientLogo} alt="Client Icon" src={clientIcon} />
                </ConditionalRender>
                <ConditionalRender shouldRender={!clientIcon}>
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      marginBottom: "0px",
                    }}
                  >
                    {clientData?.client_space?.name || ""}
                  </p>
                </ConditionalRender>
              </div>
              <div className={Style.rightMenuContainer}>
                <ConditionalRender shouldRender={clientData?.client_space?.can_edit}>
                  <Row justify="end" style={{ marginBottom: "1rem" }}>
                    <PrimaryButton className="small" onClick={() => history.push(AppUrls.EDIT_CLIENT_SPACE(clientId))}>
                      Edit Client Space
                    </PrimaryButton>
                  </Row>
                </ConditionalRender>
                <ConditionalRender shouldRender={clients.length > 0 || microApps.length > 0}>
                  <HorizontolLabelDivider text="ALCHEMY APPS" />
                  <div className={Style.appSection}>
                    <Row gutter={[20, 20]}>
                      {clients?.map((item) => {
                        return (
                          <Col key={"assistant-car-" + item.id}>
                            <AssistantCard data={item} url={AppUrls.APP_PAGE(clientId, item.id)} internalApp={true} showOwner={false} />
                          </Col>
                        );
                      })}
                      {microApps?.map((item) => {
                        return (
                          <Col key={"app-card-" + item.id}>
                            <MicroAppCard data={item} clientId={clientData?.client_space?.id} />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </ConditionalRender>
                {/* below is for custom card */}
                <ConditionalRender shouldRender={customUnApprovedApps?.length > 0 || userEmail?.includes("galepartners.com")}>
                  <div className={CardStyle.customAppHeader}>
                    <HorizontolLabelDivider text="CUSTOM APPS" width={inDesktop ? "84%" : "100%"} noMarginBottom={true} />
                    <ConditionalRender shouldRender={userEmail?.includes("galepartners.com")}>
                      <button className={CardStyle.createAppButton} onClick={() => history.push(AppUrls.CREATE_GPT(clientId))}>
                        Add a new assistant
                        <img alt="plus icon" src={Assets.getImage("CirclePlusIcon")} className={CardStyle.cardArrow + " plus-btn"} />
                      </button>
                    </ConditionalRender>
                  </div>
                  <div className={Style.appSection}>
                    <Row gutter={[20, 20]}>
                      {customUnApprovedApps?.map((item) => {
                        return (
                          <Col key={"unapproved-app-card-" + item.id}>
                            <AssistantCard data={item} url={AppUrls.APP_PAGE(clientId, item.id)} internalApp={true} showOwner={true} />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </ConditionalRender>
              </div>
            </div>
          </div>
        )}
        <div className={Style.decorativeBackgroundImage}>
          <img className="select-disable" alt="decorative element" src={Assets.getImage("ClientSpaceWave")} />
        </div>
      </div>
    </>
  );
}
