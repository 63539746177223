import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Assets from "src/assets";
import ConditionalRender from "src/components/conditionalRender";
import AppUrls from "src/constants/appUrls";
import AppContext from "src/context/appContext";
import { PageLayoutContext } from "src/context/pageLayoutContext";
import Style from "./fixedTopBar.style";
import useFixedTopBarHook from "./useFixedTopBarHook";

export default function FixedTopBar({ history, match }) {
  const appContext = React.useContext(AppContext.Context);
  const { data, isSvg, userEmail, name, logOut, metaData } = useFixedTopBarHook({ appContext });
  const {
    isNavOpen,
    setIsNavOpen,
    inDesktop,
    inClientSpaceMode,
    setInClientSpaceMode,
    inChatMode,
    setInChatMode,
    inSingleAppMode,
    doneEditing,
    setDoneEditing,
    isAudienceMode,
    setIsAudienceMode
  } = useContext(PageLayoutContext);
  const [addOnClass, setAddOnClass] = useState('');

  const popOverContent = () => {
    return (
      <>
        <div className={Style.userContainer}>
          <UserOutlined className={Style.userControlIcon} />
          <span>
            {name}
          </span>
        </div>
        <div className={Style.userContainer} style={{ marginBottom: "0" }}>
          <LogoutOutlined className={Style.userControlIcon} />
          <span style={{ cursor: "pointer" }} onClick={() => logOut()}>
            Logout
          </span>
        </div>
      </>
    );
  };

  useEffect(() => {
    let hasClient = match.url.includes('/client');
    let subDirectories = match.url.split('/');
    let inChat = match.url.includes('/chat/');
    let inAudience = match.url.includes('/audience');
    setIsAudienceMode(inAudience);
    setInChatMode(inChat);
    setInClientSpaceMode(hasClient && subDirectories.length >= 4);
  }, [match])

  useEffect(() => {
    if (inChatMode === true) {
      let newClassName = Style.topNavContainer + ' chatbot-bar sticky transition-all';
      setAddOnClass(newClassName);
    } else {
      setAddOnClass(Style.topNavContainer + ' transition-all');
    }
  }, [inChatMode])

  return (
    <div className={addOnClass} style={{ transform: !inDesktop && inClientSpaceMode && isNavOpen ? 'translateX(320px)' : 'translateX(0)' }}>
      <ConditionalRender shouldRender={inClientSpaceMode && inChatMode && !inDesktop}>
        <button className={Style.menuIcon} onClick={() => {
          setIsNavOpen(!isNavOpen);
        }}>
          <img src={Assets.getImage('HamburgerWhite')} />
        </button>
      </ConditionalRender>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: inChatMode ? 'center' : 'flex-start',
          alignItems: 'center',
          width: inClientSpaceMode ? isAudienceMode ? '220px' : '284px' : 'fit-content'
        }}
      >

        <button
          className={Style.aclhemyAiLogoContainer}
          onClick={() => {
            if (!inSingleAppMode) {
              appContext?.updateClientSpaceData(null);
              history.push(AppUrls.HOMEPAGE);
            }
          }}
        >
          <img className={Style.logo + `${inClientSpaceMode ? ' chat-mode' : ''}`} src={Assets.getImage('AlchemyLogo')} alt='App Icon' />
        </button>
        <ConditionalRender shouldRender={inClientSpaceMode && !inSingleAppMode}>
          <div className={Style.clientIcon}>
            <ConditionalRender shouldRender={!!data?.client_space?.icon}>
              <span
                style={{
                  color: 'white'
                }}
              >
                <img src={Assets.getImage('BreadcrumbArrowIcon')} alt="breadcrumb icon" />
              </span>
              <button className={Style.clientIconButton} onClick={() => {
                if (doneEditing) {
                  appContext?.updateClientSpaceData(null);
                  setDoneEditing(false);
                }
                history.push(AppUrls.CLIENT_HOME(data?.client_space?.id));
              }}>
                <img
                  alt='Client App Icon'
                  src={data?.client_space?.black_white_icon || data?.client_space?.icon}
                />
              </button>
            </ConditionalRender>
          </div>
          <ConditionalRender shouldRender={!data?.client_space?.icon}>
            <div className={Style.clientIcon}>
            <span
                style={{
                  color: 'white'
                }}
              >
                <img src={Assets.getImage('BreadcrumbArrowIcon')} alt="breadcrumb icon" />
              </span>
            <p
              style={{
                fontWeight: 'bold',
                color: 'white',
                marginBottom: '0px',
                cursor: 'pointer',
              }}
              onClick={() =>
                history.push(AppUrls.CLIENT_HOME(data?.client_space?.id))
              }
            >
              {data?.client_space?.name || ''}
            </p>
            </div>
            
          </ConditionalRender>
        </ConditionalRender>

      </div>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <div style={{ cursor: 'pointer' }}>
          <Popover
            overlayClassName={Style.popOverContainer}
            placement='bottomRight'
            content={popOverContent}
            trigger={['click']}
          >
            <button className={Style.userIconButton}>
              <img src={Assets.getImage('UserProfileIcon')} alt="user profil icon" height={30} />
            </button>
          </Popover>
        </div>
      </div>
    </div>
  );
}
