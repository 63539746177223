import { Pagination } from 'antd';
import React from 'react'
import './pagination.less';

const CustomPagination = ({ currentPage, data, onPaginationChange, pageSize, showSizeChanger, className }) => {
  return (
    <div className='custom-pagination-parent'>
      <Pagination
        className={`custom-pagination ${className}`}
        current={currentPage}
        total={data?.total}
        onChange={onPaginationChange}
        pageSize={pageSize}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger={showSizeChanger}
      />
    </div>
  );
}

export default CustomPagination;
