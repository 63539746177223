import { useState, useEffect, useRef } from "react";
import appUrls from "src/constants/appUrls";

export default function useSideNavBarHook({
  history,
  multipleChatSessions,
  setSessionToggle,
  data,
  setStreamingChatID,
  setGeneratingResponse,
  cancellationStreamingRef,
  setUserMessage,
  generatingResponse,
  createNewSessionRef,
  scrollSingleSesion,
  sessionLoading,
  singleChatSessions,
  cancelApiCall,
  mode,
  clientId
}) {
  const mAiApp = data?.id;
  const [renameModal, setRenameModal] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [renameData, setRenameData] = useState(null);
  const containerRef = useRef(null);

  const closeRenameModal = () => {
    setRenameModal(false);
  }
  const openRenameModal = (item, idx) => {
    setRenameData({
      data: item,
      idx: idx
    })
    setRenameModal(true);
  }

  const toggleSession = (val) => {
    if (val === "MY_CHAT") {
      setSessionToggle("MY_CHAT");
      createNewSessionRef.current.created = false;
      if (singleChatSessions?.length > 0) {
        cancelApiCall();
        history.push(appUrls.APP_PAGE(clientId, mAiApp, singleChatSessions[0]?.id, mode));
      } else {
        cancelApiCall();
        history.push(appUrls.APP_PAGE(clientId, mAiApp, undefined, mode));
      }
      setUserMessage("");
      if (generatingResponse) {
        cancellationStreamingRef.current.terminated = true;
      }
      setGeneratingResponse(false);
      setStreamingChatID(null);
    } else {
      cancelApiCall();
      const groupChatId = multipleChatSessions?.[0]?.id;
      setSessionToggle("MULTI_CHAT");
      createNewSessionRef.current.created = false;
      history.push(appUrls.APP_PAGE(clientId, mAiApp, groupChatId, mode));
      setUserMessage("");
      if (generatingResponse) {
        cancellationStreamingRef.current.terminated = true;
      }
      setGeneratingResponse(false);
      setStreamingChatID(null);
    }
  };

  const toggleSingleChat = (id) => {
    cancelApiCall();
    history.push(appUrls.APP_PAGE(clientId, mAiApp, id, mode));
    createNewSessionRef.current.created = false;
    setUserMessage("");
    if (generatingResponse) {
      cancellationStreamingRef.current.terminated = true;
    }
    setGeneratingResponse(false);
    setStreamingChatID(null);
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight && !sessionLoading) {
      scrollSingleSesion();
    }
  };

  return {
    toggleSession,
    toggleSingleChat,
    containerRef,
    handleScroll,
    renameModal,
    closeRenameModal,
    openRenameModal,
    renameData,
    disclaimerModal,
    setDisclaimerModal
  };
}
