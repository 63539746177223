import React from "react";
import Style from "./sqlQuery.style";
import { DatabaseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import useSqlQueryHook from "./useSqlQueryHook";
import { Drawer, Form, Row } from "antd";
import Editor from "@monaco-editor/react";
import { RoundedOutlinedButton, SecondaryButton } from "src/components/basic";
import { format } from "sql-formatter";
import CommonStyle from "../newSqlTool.style";

export default function SqlQuery({
  tableName,
  value = "",
  onChange,
  mAiApp,
  selectedConnection,
  setFieldValue,
  tables,
  fieldName,
  getFieldsValue,
  setUnsavedChanges,
}) {
  const { loading, drawer, setDrawer, formRef, validateVirtualTable, setLoading } = useSqlQueryHook({
    mAiApp,
    selectedConnection,
    tableName,
    onChange,
    setFieldValue,
    tables,
    fieldName,
    getFieldsValue,
    setUnsavedChanges,
  });
  return (
    <>
      <button className={Style.viewQueryButton} onClick={() => setDrawer(true)}>
        View
      </button>
      <Drawer
        title={`Add sql query ${tableName ? `for ${tableName}` : "new table"}`}
        maskClosable={true}
        placement="right"
        onClose={() => {
          setDrawer(false);
          setLoading(false);
        }}
        open={drawer}
        destroyOnClose
        size="large"
        width={"80%"}
      >
        <Form
          ref={formRef}
          initialValues={{ virtual_sql_query: format(value, { language: "snowflake" }) }}
          onFinish={(val) => validateVirtualTable(val)}
        >
          <Form.Item shouldUpdate noStyle>
            {({ setFieldValue, getFieldValue }) => {
              return (
                <div>
                  <div className={Style.editorTopBar}>
                    <span
                      onClick={() => {
                        const sql = getFieldValue("virtual_sql_query");
                        const res = format(sql, { language: "snowflake" });
                        setFieldValue("virtual_sql_query", res);
                      }}
                      className={Style.formatter}
                    >
                      <LeftOutlined />/<RightOutlined />
                    </span>
                  </div>
                  <Form.Item name="virtual_sql_query" rules={[{ required: true, message: "Please enter a valid sql query" }]}>
                    <Editor
                      height="calc(100vh - 250px)"
                      className={Style.editor}
                      defaultLanguage="sql"
                      theme="vs-light"
                      options={{
                        minimap: { enabled: false },
                        lineNumbers: "on",
                        automaticLayout: true,
                      }}
                    />
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
          <Row className={CommonStyle.topSaveBar} justify="end">
            <SecondaryButton loading={loading} htmlType="submit">
              Validate Query
            </SecondaryButton>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
