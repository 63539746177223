export const QUESTIONS = ["Influencer Analysis", "Content Analysis", "Brand Engagement"];

export const FORMATTED_QUESTION_ANSWER = {
  // "Data Overview": {
  //   uiQuestion: "Give me an overview of the data",
  //   question: "Summarize in 2 -3 lines the data available for analysis and based on channels and media types, what kind of content seems to be working best in non-technical terms",
  //   answer: "The data available for analysis includes detailed information on social media posts by influencers, such as audience reactions, engagement levels, and the type of content shared. Video content, especially when combined with images or links, tends to perform best, engaging audiences more effectively across social media channels.",
  //   link: "https://dsa.galedash-testing.g43labs.net/dash/dashboard?brand=1&page=134&tab=216"
  // },
  "Influencer Analysis": {
    uiQuestion: "Give me a summary of Influencer Performance",
    question: "Summarise in 3-4 sentences, based on engagement rates and tracks which influencers performed the best",
    answer: `Based on the available engagement rate data, the top-performing influencers are Gabrielle Union-Wade with an engagement rate of 796.61, Kristy Baumann, Running Dietitian with an engagement rate of 659.70, and Carolina Gelen with an engagement rate of 593.43. While the specific tracks for Gabrielle Union-Wade and Kristy Baumann could not be determined due to data retrieval issues, Carolina Gelen is associated with the ""Recipe (Always On)"" track.
    `,
    link: "https://milkpep.gale.technology/dash/dashboard?brand=2&page=68&tab=70",
  },
  "Content Analysis": {
    uiQuestion: "Give me a summary of Content performance",
    question: `Basis engagement rate, give a summary in 3-4 sentences of the qualitative attributes(content theme, tone, number of people, setting, etc.) that are driving high engagement`,
    answer:
      "High engagement is driven by content themes like Events, Running, and Recipes, with tones of Entertainment, Promotional, and Emotional. Posts typically feature one person, set outside or in the kitchen, indicating a preference for personal and relatable content.",
    link: "https://milkpep.gale.technology/dash/dashboard?brand=2&page=68&tab=68",
  },
  "Brand Engagement": {
    uiQuestion: "What does the Brand Engagement look like?",
    question:
      "Summarize in 2-3 lines how milk sponsored posts performed over the duration of data available and list the top 3 in terms of engagement rate",
    answer: `The best-performing milk sponsored posts were those with the highest engagement rates, indicating that they were the most effective at capturing the audience's attention and encouraging interaction. The top 3 posts in terms of engagement rate are:
    https://www.instagram.com/reel/C3bL0tTPGti/ with an engagement rate of 151.82
    https://www.instagram.com/reel/C4jYXJDpvHr/ with an engagement rate of 76.87
    https://www.instagram.com/reel/C3GWBmfv0rv/ with an engagement rate of 39.65`,
    link: "https://milkpep.gale.technology/dash/dashboard?brand=2&page=68&tab=69",
  },
};
