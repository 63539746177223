import { css } from "@linaria/core";

const toolCardContainer = css`
  display: flex;
  background: var(--color-charcoal-surface-1);;
  color: white;
  font-weight: 600;
  padding: 24px;
  border-radius: 0px;
  height: 100%;
  flex-direction:row;
  flex-wrap:nowrap;
  gap: 8px;
  align-items:center;
`;

const toolCardName = css`
  flex-grow: 1;
  display: flex;
  gap: 1rem;
  font-size:1rem;
  font-weight: 700;
`;

const toolCardButton = css`
  cursor: pointer;
  background: transparent;
  font-weight: 400 !important;
  border: 0 !important;
  font-size:1rem;
  display:flex;
  transition: all 0.2s ease-in;
  &:hover {
    color: var(--color-primary);
  }
  svg {
    margin-left:8px;
  }
`;

export default {
  toolCardContainer,
  toolCardName,
  toolCardButton,
};
