import { useEffect, useState, useCallback, useContext, useRef } from "react";
import { notification } from "antd";
import NcApiServices from "../../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import debounce from "lodash.debounce";
import AppContext from "src/context/appContext";
import moment from "moment";

export const useOpportunitiesStatHooks = ({
  containerRef,
  analysisID,
  jobTriggers,
  analysisOverview,
}) => {
  const [drawerData, setDrawerData] = useState();
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(null);
  const [total, setTotal] = useState(null);
  const [opportunities, setOpportunities] = useState(null);
  const [sources, setSources] = useState(null);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [jobLoader, setJobLoader] = useState(false);
  const [sourceLoader, setSourceLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [annotatedOpportunitiesData, setAnnotatedOpportunitiesData] = useState(null);
  const firstDoneJobTrigger = jobTriggers?.filter((jobTrigger) => jobTrigger.state === 'DONE')?.[0];
  const [opportunitiesFilters, setOpportunitiesFilters] = useState({
    analysis_id: analysisID,
    analysis_job_trigger_id: firstDoneJobTrigger ? firstDoneJobTrigger.id : null,
    source: null,
    search_text: null,
    page: 1,
    page_size: 100,
    feedback_type: null,
  });
  const [modalData, setModalData] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [customOpportunitiesData, setCustomOpportunitiesData] = useState(null);
  const [customOpportunitiesFilters, setCustomOpportunitiesFilters] = useState({
    analysis_id: analysisID,
    search_text: null,
    page: 1,
    page_size: 100,
  });
  const [userRuns, setUserRuns] = useState(null);
  const [customPageSize, setCustomPageSize] = useState(null);
  const [customTotal, setCustomTotal] = useState(null);
  const [customAnnotatedOpportunitiesData, setCustomAnnotatedOpportunitiesData] = useState(null);
  const [showOnlyMyRuns, setShowOnlyMyRuns] = useState(true);
  const [customOpportunityCreationLoading, setCustomOpportunityCreationLoading] = useState(false);
  const [csvExport, setCsvExport] = useState(null);
  const [createOpportunityModal, setCreateOpportunityModal] = useState(false);
  const appContext = useContext(AppContext.Context);
  const formRef = useRef(null);
  const userData = appContext?.metaData;

  const getOpportunities = async (filters) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getOpportunities(filters);
      if (res?.success) {
        setOpportunities(res?.data?.data);
        setSources(res?.data?.opportunity_sources);
        setAnnotatedOpportunitiesData(annotateOpportunities(res?.data?.data));
        setTotal(res?.data?.total);
        setPageSize(res?.data?.per_page);
      } else {
        notification.warning({
          duration: 5,
          message: "No data available.",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const getCustomOpportunities = async (filters) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getCustomOpportunities(filters);
      if (res?.success) {
        setCustomOpportunitiesData(res?.data?.data);
        setCustomAnnotatedOpportunitiesData(annotateOpportunities(res?.data?.data));
        setCustomTotal(res?.data?.total);
        setCustomPageSize(res?.data?.per_page);
        setUserRuns(res?.data?.opportunity_runs);
      } else {
        notification.warning({
          duration: 5,
          message: "No data available.",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const categories = {
    EMERGING_NODES: {
      label: "Emerging Nodes",
      color: "#dc5a4b",
    },
    IMPORTANT_NODES: {
      label: "Important Nodes",
      color: "#0449a4",
    },
    INFLUENTIAL_NODES: {
      label: "Influential Nodes",
      color: "#566b04",
    },
    COMMUNITIES: {
      label: "Communities",
      color: "#006d75",
    },
  };

  const otherColors = [
    "#b55b0d",
    "#8c564b",
    "#954d80",
    "#653b8d",
    "#7f7f7f",
    "#818117",
    "#107682",
    "#185e90",
    "#8a2be2",
  ]
  
  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };


  const annotateOpportunities = (opportunitiesData) => {
    const newAnnotatedOpportunitiesData = opportunitiesData.map((item) => {
      if (categories[item.source || item.analysis_user_opportunity_run_data?.run_name] === undefined) {
        categories[item.source || item.analysis_user_opportunity_run_data?.run_name] = {
          label: item.source || item.analysis_user_opportunity_run_data?.run_name,
          // use hashing to ensure same source gets same color
          color:
            otherColors[
              Math.abs(hashCode(item.source || item.analysis_user_opportunity_run_data?.run_name)) % otherColors.length
            ],
        };
      }
      item.type = categories[item.source || item.analysis_user_opportunity_run_data?.run_name]?.label;
      item.color = categories[item.source || item.analysis_user_opportunity_run_data?.run_name]?.color;
      return item;
    });
    return newAnnotatedOpportunitiesData;
    // setAnnotatedOpportunitiesData(newAnnotatedOpportunitiesData);
  }

  useEffect(() => {
    getOpportunities(opportunitiesFilters);
    getCustomOpportunities(customOpportunitiesFilters);
  }, []);

  const handlePageChange = useCallback(async (value) => {
    const updatedOpportunitiesFilters = {
      ...opportunitiesFilters,
      page: value,
    };
    setOpportunitiesFilters(updatedOpportunitiesFilters);
    setPageLoader(true);
    await getOpportunities(updatedOpportunitiesFilters);
    setPageLoader(false);
  }, [opportunitiesFilters]);

  const handleJobTriggerChange = useCallback(async (value) => {
    const updatedOpportunitiesFilters = {
      ...opportunitiesFilters,
      page: 1,
      analysis_job_trigger_id: value,
    };
    setOpportunitiesFilters(updatedOpportunitiesFilters);
    setJobLoader(true);
    await getOpportunities(updatedOpportunitiesFilters);
    setJobLoader(false);
  }, [opportunitiesFilters]);

  const handleFeedbackTypeChange = useCallback(async (value) => {
    const updatedOpportunitiesFilters = {
      ...opportunitiesFilters,
      page: 1,
      feedback_type: value,
    };
    setOpportunitiesFilters(updatedOpportunitiesFilters);
    setFeedbackLoader(true);
    await getOpportunities(updatedOpportunitiesFilters);
    setFeedbackLoader(false);
  }, [opportunitiesFilters]);

  const handleSearch = useCallback(
    debounce(async (value) => {
      const updatedOpportunitiesFilters = {
        ...opportunitiesFilters,
        page: 1,
        search_text: value,
      };
      setSearchLoader(true);
      setOpportunitiesFilters(updatedOpportunitiesFilters);
      await getOpportunities(updatedOpportunitiesFilters);
      setSearchLoader(false);
    }, 800),
    [opportunitiesFilters],
  );

  const handleSourceChange = useCallback(async (value) => {
    const updatedOpportunitiesFilters = {
      ...opportunitiesFilters,
      page: 1,
      source: value,
    };
    setSourceLoader(true);
    setOpportunitiesFilters(updatedOpportunitiesFilters);
    await getOpportunities(updatedOpportunitiesFilters);
    setSourceLoader(false);
  }, [opportunitiesFilters]);

  const handleScrollTop = () => {
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleOpportunityFeedback = async (opportunity, feedbackType) => {
    const existingFeedbackData = opportunity?.feedbacks?.[0] ? {...opportunity.feedbacks[0]} : {};
    if (existingFeedbackData.feedback_type !== feedbackType) {
      existingFeedbackData.id = null;
      existingFeedbackData.feedback_text = null;
    }
    existingFeedbackData.feedback_type = feedbackType;
    setModalData({
      opportunity,
      feedbackData: existingFeedbackData,
    });
  }

  const pinOpportunity = async ({
    opportunityID,
    isPinned,
    existingOpportunities,
    updateExistingOpportunities,
    updateAnnotatedOpportunities,
  }) => {
    try {
      setLoading(true);
      const res = await NcApiServices.pinOpportunity({
        opportunityID,
        isPinned,
      });
      if (res?.success) {
        const updatedOpportunities = existingOpportunities?.map((opportunity) => {
          if (opportunity.id !== opportunityID) {
            return opportunity;
          }
          return res?.data;
        });
        updateExistingOpportunities(updatedOpportunities);
        updateAnnotatedOpportunities(annotateOpportunities(updatedOpportunities));
        notification.success({
          duration: 5,
          message: isPinned ? "Pinned Opportunity" : "Unpinned Opportunity",
          placement: "bottomRight",
        });
      } else {
        notification.warning({
          duration: 5,
          message: "Could not perform operation.",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const handlePin = useCallback(async ({opportunityID, isPinned}) => {
    const existingOpportunities = opportunities;
    const updateExistingOpportunities = setOpportunities;
    const updateAnnotatedOpportunities = setAnnotatedOpportunitiesData;
    pinOpportunity({
      opportunityID,
      isPinned,
      existingOpportunities,
      updateExistingOpportunities,
      updateAnnotatedOpportunities,
    });
  }, [opportunities]);

  const handleCustomPin = useCallback(async ({opportunityID, isPinned}) => {
    const existingOpportunities = customOpportunitiesData;
    const updateExistingOpportunities = setCustomOpportunitiesData;
    const updateAnnotatedOpportunities = setCustomAnnotatedOpportunitiesData;
    pinOpportunity({
      opportunityID,
      isPinned,
      existingOpportunities,
      updateExistingOpportunities,
      updateAnnotatedOpportunities,
    });
  }, [customOpportunitiesData]);

  
  const handleFeedbackSubmitBase = async ({
    opportunity_id,
    feedback_text,
    feedback_type,
    existingOpportunities,
    updateExistingOpportunities,
    updateAnnotatedOpportunities,
  }) => {
    try {
      const res = await NcApiServices.submitOpportunityFeedback({
        opportunity_id,
        feedback_text,
        feedback_type,
      });
      if (res?.success) {
        const updatedOpportunities = existingOpportunities?.map(
          (opportunity) => {
            if (opportunity.id !== opportunity_id) {
              return opportunity
            }
            return res?.data;
          }
        );
        updateExistingOpportunities(updatedOpportunities);
        updateAnnotatedOpportunities(annotateOpportunities(updatedOpportunities));
        notification.success({
          duration: 5,
          message: feedback_type ? "Feedback submitted successfully" : "Feedback removed successfully",
          placement: "bottomRight",
        });
      } else {
        notification.warning({
          duration: 5,
          message: feedback_type ? "Could not submit feedback": "Could not remove feedback",
          placement: "bottomRight",
        });
      }
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  }

  const handleFeedbackSubmit = useCallback(async ({
    opportunity_id,
    feedback_text,
    feedback_type,
  }) => {
    const existingOpportunities = opportunities;
    const updateExistingOpportunities = setOpportunities;
    const updateAnnotatedOpportunities = setAnnotatedOpportunitiesData;
    handleFeedbackSubmitBase({
      opportunity_id,
      feedback_text,
      feedback_type,
      existingOpportunities,
      updateExistingOpportunities,
      updateAnnotatedOpportunities,
    });
  }, [opportunities]);

  const handleCustomFeedbackSubmit = useCallback(async ({
    opportunity_id,
    feedback_text,
    feedback_type,
  }) => {
    const existingOpportunities = customOpportunitiesData;
    const updateExistingOpportunities = setCustomOpportunitiesData;
    const updateAnnotatedOpportunities = setCustomAnnotatedOpportunitiesData;
    handleFeedbackSubmitBase({
      opportunity_id,
      feedback_text,
      feedback_type,
      existingOpportunities,
      updateExistingOpportunities,
      updateAnnotatedOpportunities,
    });
  }, [customOpportunitiesData]);

  const handleDeleteBase = async (
    opportunity_id,
    getOpportunitiesFunc
  ) => {
    try {
      const res = await NcApiServices.deleteOpportunity({
        analysis_opportunity_id: opportunity_id,
        analysis_id: analysisID,
      });
      setDeleteModalData(null);
      if (res?.success) {
        notification.success({
          duration: 5,
          message: "Deleted opportunity",
          placement: "bottomRight",
        });
        await getOpportunitiesFunc(opportunitiesFilters);
      } else {
        notification.warning({
          duration: 5,
          message: "Could not delete opportunity",
          placement: "bottomRight",
        });
      }
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };
  
  const handleDelete = useCallback(async (opportunity_id) => {
    handleDeleteBase(opportunity_id, getOpportunities);
  }, [opportunitiesFilters]);

  const handleCustomDelete = useCallback(async (opportunity_id) => {
    handleDeleteBase(opportunity_id, getCustomOpportunities);
  }, [customOpportunitiesFilters]);

  const handleCustomSearch = useCallback(
    debounce(async (value) => {
      const updatedOpportunitiesFilters = {
        ...customOpportunitiesFilters,
        page: 1,
        search_text: value,
      };
      // setSearchLoader(true);
      setCustomOpportunitiesFilters(updatedOpportunitiesFilters);
      await getCustomOpportunities(updatedOpportunitiesFilters);
      // setSearchLoader(false);
    }, 800),
    [customOpportunitiesFilters],
  );

  const handleCustomPageChange = useCallback(
    async (value) => {
      const updatedOpportunitiesFilters = {
        ...customOpportunitiesFilters,
        page: value,
      };
      setCustomOpportunitiesFilters(updatedOpportunitiesFilters);
      // setPageLoader(true);
      await getCustomOpportunities(updatedOpportunitiesFilters);
      // setPageLoader(false);
    },
    [customOpportunitiesFilters],
  );

  const handleUserRunChange = useCallback(
    async (value) => {
      const updatedOpportunitiesFilters = {
        ...customOpportunitiesFilters,
        analysis_user_opportunity_run_id: value,
        page: 1,
      };
      setCustomOpportunitiesFilters(updatedOpportunitiesFilters);
      // setPageLoader(true);
      await getCustomOpportunities(updatedOpportunitiesFilters);
      // setPageLoader(false);
    },
    [customOpportunitiesFilters],
  );

  const createCustomOpportunity = async (
    runName,
    userQuery,
    client_info,
    problem_statement,
    analysis_section_config_ids,
  ) => {
    try {
      setCustomOpportunityCreationLoading(true);
      const res = await NcApiServices.createCustomOpportunity({
        analysis_id: analysisID,
        run_name: runName,
        user_query: userQuery,
        client_info,
        problem_statement,
        analysis_section_config_ids,
      });
      setCustomOpportunityCreationLoading(false);
      if (res?.success) {
        if (res?.data?.opportunities?.length === 0) {
          notification.warning({
            duration: 5,
            message: "No opportunities found for this query.",
            placement: "bottomRight",
          });
        } else {
          notification.success({
            duration: 5,
            message: "Created Opportunities",
            placement: "bottomRight",
          });
          setCreateOpportunityModal(false);
          await handleUserRunChange(res?.data?.opportunity_run?.id);
        }
      } else {
        notification.warning({
          duration: 5,
          message: "Could not create opportunities",
          placement: "bottomRight",
        });
      }
    } catch (error) {
      setCustomOpportunityCreationLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };
  
  const exportOpportunitiesToCSV = async (fetchFunction, filters, fileName) => {
    let allData = [];
    let currentPage = 1;
    let totalPages = 1;

    while (totalPages >= currentPage) {
      const response = await fetchFunction({ ...filters, page: currentPage, page_size: 1000 });
      if (response.success && response.data.data.length > 0) {
        allData = allData.concat(response.data.data);
        totalPages = response.data.total_pages;
        currentPage++;
      } else {
        break;
      }
    }
    if (allData.length === 0) {
      throw new Error("No data available to export");
    }
    const escapeCSVValue = (value) => {
      if (value == null) return "";
      const stringValue = value.toString();
      if (stringValue.includes('"') || stringValue.includes(",") || stringValue.includes("\n")) {
        return `"${stringValue.replace(/"/g, '""')}"`;
      }
      return stringValue;
    };


    const csvContent = [
      [
        "opportunity",
        "reason",
        "opportunity_source",
        "actionable_steps",
        "nodes",
        "communities",
        "opportunity_score",
        "urgency_score",
        "created_on",
        "feedback_type",
        "feedback_text",
      ],
      ...allData.map((item) => [
        escapeCSVValue(item.opportunity),
        escapeCSVValue(item.reason),
        escapeCSVValue(item.source || item.analysis_user_opportunity_run_data?.run_name),
        // actionable steps is a list of strings
        escapeCSVValue(item.actionable_steps?.join(" -")),
        escapeCSVValue(item.metadata?.nodes?.join(", ")),
        escapeCSVValue(item.metadata?.communities?.join(", ")),
        item.opportunity_score,
        item.urgency_score,
        escapeCSVValue(item.created_on),
        escapeCSVValue(item.feedbacks?.[0]?.feedback_type || ""),
        escapeCSVValue(item.feedbacks?.[0]?.feedback_text || ""),
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  const handleExportOpportunities = async () => {
    try {
      setCsvExport('opportunities');
      await exportOpportunitiesToCSV(
        NcApiServices.getOpportunities,
        opportunitiesFilters,
        `${analysisOverview?.name}_opportunities_${moment().format("MMM D, YYYY h:mm A").replace(/:/g, "-")}.csv`,
      );
      notification.success({
        duration: 5,
        placement: "bottomRight",
        message: "Opportunities have been exported to CSV",
      });
      setCsvExport(null);
    } catch (error) {
      setCsvExport(null);
      notification.error({
        duration: 5,
        placement: "bottomRight",
        message: `Export Failed. ${error}`,
      });
    }
  };

  const handleExportCustomOpportunities = async () => {
    try {
      setCsvExport("customOpportunities");
      await exportOpportunitiesToCSV(
        NcApiServices.getCustomOpportunities,
        customOpportunitiesFilters,
        // include timestamp in filename to avoid overwriting
        `${analysisOverview?.name}_custom_opportunities_${moment()
          .format("MMM D, YYYY h:mm A")
          .replace(/:/g, "-")}.csv`,
      );
      notification.success({
        duration: 5,
        placement: "bottomRight",
        message: "Custom opportunities have been exported to CSV.",
      });
      setCsvExport(null);
    } catch (error) {
      setCsvExport(null);
      notification.error({
        duration: 5,
        placement: "bottomRight",
        message: `Export Failed. Error: ${error}`,
      });
    }
  };

  return {
    drawerData,
    setDrawerData,
    showMore,
    setShowMore,
    handleScrollTop,
    handleJobTriggerChange,
    handleSearch,
    handleSourceChange,
    opportunitiesFilters,
    pinOpportunity,
    annotatedOpportunitiesData,
    total,
    pageSize,
    handlePageChange,
    jobLoader,
    searchLoader,
    loading,
    pageLoader,
    sources,
    sourceLoader,
    categories,
    modalData,
    handleOpportunityFeedback,
    setModalData,
    handleFeedbackSubmit,
    feedbackLoader,
    handleFeedbackTypeChange,
    deleteModalData,
    setDeleteModalData,
    handleDelete,
    customOpportunitiesData,
    customOpportunitiesFilters,
    userRuns,
    handleCustomSearch,
    handleCustomPageChange,
    handleUserRunChange,
    customPageSize,
    customTotal,
    customAnnotatedOpportunitiesData,
    userData,
    showOnlyMyRuns,
    setShowOnlyMyRuns,
    handleCustomFeedbackSubmit,
    handleCustomDelete,
    createCustomOpportunity,
    customOpportunityCreationLoading,
    createOpportunityModal,
    setCreateOpportunityModal,
    formRef,
    handlePin,
    handleCustomPin,
    handleExportOpportunities,
    handleExportCustomOpportunities,
    csvExport,
  };
};

export default useOpportunitiesStatHooks;
