import { SyncOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { formatNumberWithPercentageAndCeil, justFormatNumber } from 'src/lib/utils';

// Component to display audience size
const AudienceSize = ({ audienceSize, baseSize, audienceSizeLoading, getAudienceSize }) => (
    <div className="audience-size">
        <span className="audience-size-label">Audience Size:</span>
        {audienceSizeLoading ? (
            <Spin />
        ) : (
            <span className="audience-size-value">
                {audienceSize > 0 ? `${justFormatNumber(audienceSize)} (${formatNumberWithPercentageAndCeil((audienceSize / baseSize) * 100)} of total population)` : 0}
            </span>
        )}
        {!audienceSizeLoading && (
            <SyncOutlined className="refresh-icon" onClick={getAudienceSize} />
        )}
    </div>
);

export default AudienceSize;