import { Checkbox, Col, Form, Input, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useBackboneCapabiltyHook from "src/modules/CustomGpt/hooks/useBackboneCapabiltyHook";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./backboneCapability.style";
import BackboneToolAdvancedSettings from "./BackboneToolAdvancedSettings";
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style";

export default function BackboneCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { formRef, loading, configureCapability, initialValues, configureLoading } = useBackboneCapabiltyHook({
    id,
    aiAppData,
    configData,
    updateCustomApp,
    onClose,
    toolData,
    reload,
  });
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form}>
          <Form
            onFinish={(val) => {
              configureCapability(val);
            }}
            requiredMark={false}
            ref={formRef}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="name"
                  label="Name*"
                  rules={CustomGptService.validations({ required: true, max_length_string: true }, "Name", configData?.config?.name)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label="Description*"
                  rules={CustomGptService.validations({ required: true, max_length_string: true }, "Description", configData?.config?.description)}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="assistant_description_for_system_prompt"
                  label="Assistant description for system prompt*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    "Assistant description for system prompt",
                    configData?.config?.assistant_description_for_system_prompt
                  )}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <BackboneToolAdvancedSettings configData={configData} />
              </Col>
              
            </Row>
            <Row justify="end" className={CommonStyle.globalSave}>
              <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                Save
              </SecondaryButton>
            </Row>
          </Form>
        </div>
      </ConditionalRender>
    </div>
  );
}
