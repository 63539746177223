import { Alert, Slider, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { justFormatNumber } from 'src/lib/utils';
import './behaviourProbability.style.less';

const { Title, Text } = Typography;

const BehaviourProbabilityComponent = ({ behaviourProbabilityData, setBehaviourSegmentCount }) => {
    const [adjustedAudienceData, setAdjustedAudienceData] = useState([]);
    const [sliderValue, setSliderValue] = useState(1);

    useEffect(() => {
        if (Object.keys(behaviourProbabilityData).length === 0) {
            return;
        }

        if (behaviourProbabilityData.behavioural_probability === null) {
            return;
        }

        const maxSegments = behaviourProbabilityData.behavioural_probability.length;
        const initialValue = maxSegments > 10 ? 10 : maxSegments;
        setSliderValue(initialValue);
        handleSliderChange(initialValue);
    }, [behaviourProbabilityData]);

    useEffect(() => {
        setBehaviourSegmentCount(sliderValue);
    }, [sliderValue]);

    if (Object.keys(behaviourProbabilityData).length === 0) {
        return (
            <div className="behaviour-probability-container">
                <Title level={3} className="behaviour-probability-title">Audience Size and Precision Adjustment</Title>
                <Text className="behaviour-probability-text">
                    Sorry, that is a very niche audience you're trying to find! Can you broaden your definition so we can find you some relevant people in our Backbone?
                </Text>
            </div>
        );
    }

    if (behaviourProbabilityData.behavioural_probability === null) {
        return (
            <div className="behaviour-probability-container">
                <Title level={3} className="behaviour-probability-title">Audience Size and Precision Adjustment</Title>
                <Text className="behaviour-probability-text">
                    No E1 features were selected during Audience creation.
                </Text>
            </div>
        );
    }

    const totalPopulation = behaviourProbabilityData.base_size;
    const initialAudienceSize = behaviourProbabilityData.audience_size;
    const initialPercentage = ((initialAudienceSize / totalPopulation) * 100).toFixed(2);

    const calculateAverageProbability = (data) => {
        const totalProbability = data.reduce((sum, segment) => sum + segment.probability, 0);
        return totalProbability / data.length;
    };

    const initialAverageProbability = calculateAverageProbability(behaviourProbabilityData.behavioural_probability);
    const totalAverageProbability = behaviourProbabilityData.average_behavioural_probability;

    const maxSegments = behaviourProbabilityData.behavioural_probability.length;
    const sliderMax = maxSegments;

    const handleSliderChange = (value) => {
        setBehaviourSegmentCount(value);
        setSliderValue(value);
        const segments = behaviourProbabilityData.behavioural_probability;
        let adjustedSegments = segments.slice(0, value);
        setAdjustedAudienceData(adjustedSegments);
    };

    const adjustedAudienceSize = adjustedAudienceData.reduce((sum, segment) => sum + segment.segment_count, 0);
    const adjustedPercentage = ((adjustedAudienceSize / totalPopulation) * 100).toFixed(2);
    const adjustedAverageProbability = calculateAverageProbability(adjustedAudienceData);
    const likelihood = (adjustedAverageProbability / totalAverageProbability).toFixed(2);
    const selectedSegments = adjustedAudienceData.map(segment => segment.e1_segment).join(', ');

    const lastSegmentProbability = adjustedAudienceData.length > 0 ? adjustedAudienceData[adjustedAudienceData.length - 1].probability : null;
    const showLowPrecisionMessage = lastSegmentProbability !== null && lastSegmentProbability <= 0.2;

    return (
        <div className="behaviour-probability-container">
            <Title level={3} className="behaviour-probability-title">Audience Probability and size adjustment</Title>
            <Text className="behaviour-probability-text">
                The audience size is: {justFormatNumber(adjustedAudienceSize)}, which is {adjustedPercentage}% of the total population.
            </Text>
            <div className="behaviour-probability-slider">
                <Slider
                    min={1}
                    max={sliderMax}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    marks={{
                        1: 'Make audience smaller and more precise',
                        [sliderMax]: 'Make audience bigger but less precise',
                    }}
                />
            </div>
            <Text className="behaviour-probability-text">
                This audience is {likelihood} times as likely on average as the total population to satisfy this query.
            </Text>
            {showLowPrecisionMessage && (
                <Alert
                    message="Low Precision Warning"
                    description="The precision of your audience is low, strongly consider reducing the audience size. Alternatively, consider broadening your audience definition so we can find some relevant people in our Backbone"
                    type="warning"
                    showIcon
                    className="low-precision-alert"
                />
            )}
            <div className="selected-segments">
                <Title level={4} className="selected-segments-title">Selected E1 Segments</Title>
                <Text className="selected-segments-text">{selectedSegments}</Text>
            </div>
        </div>
    );
};

export default BehaviourProbabilityComponent;