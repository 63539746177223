import React from "react";
import { Form, Input, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Style from "./createForm.style";
import { SecondaryButton } from "src/components/basic";

const ConversationStartersConfig = () => {
  return (
    <>
      <div className={Style.advancedSettingHeader}>Conversation starters</div>
      <div style={{ margin: '0 21px' }}>
        <Form.List name="conversation_starters">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} className={Style.conversationStarterContainer}>
                  <div style={{ flexGrow: "1" }}>
                    <Form.Item
                      {...field}
                      name={[field.name]}
                      fieldKey={[field.fieldKey, "conversation_starters"]}
                      rules={[{ required: true, message: "Input is required." }]}
                    >
                      <Input placeholder="Enter Conversation Starter" />
                    </Form.Item>
                  </div>
                  <DeleteOutlined onClick={() => remove(field.name)}>Delete</DeleteOutlined>
                </div>
              ))}
              {fields.length < 4 && (
                <SecondaryButton className="small" onClick={() => add()}>
                  Add
                </SecondaryButton>
              )}
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

export default ConversationStartersConfig;
