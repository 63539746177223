import React from "react";
import { useKnowledgeGraphSummaryHook } from "../hooks/useKnowledgeGraphSummaryHook";
import LoadingSpinner from "src/components/loadingSpinner";
import { cleanText } from "src/lib/utils";
import ReactMarkdown from "react-markdown";
import Style from 'src/modules/newsAndCulture/NewsAndCulture.style';

export default function KnowledgeGraphSummary({
  knowledgeGraphFilters,
  analysisId,
  constructKnowledgeGraphFilters,
}) {
  const { loading, data } = useKnowledgeGraphSummaryHook({
    knowledgeGraphFilters,
    analysisId,
    constructKnowledgeGraphFilters,
  });
  if (loading) {
    return (
      <>
        <div className={Style.loadingContainerAnalyzeTrends}>
          <LoadingSpinner centered />
        </div>
        <div className={Style.loadingTextAnalyzeTreands}>This will take a few seconds...</div>
      </>
    );
  }
  return (
    <>
      <ReactMarkdown className={Style.markDownColour} children={cleanText(data?.trend_summary)} />
    </>
  );
}
