import { css } from "@linaria/core";

const container = css`
  background: black;
  display: flex;
  overflow-y : auto;
`;

const appContainer = css`
  padding: 0 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width:1440px;
  margin:0 auto;
  @media screen and (min-width: 768px) {
    padding: 0px 60px;
  }
`;

const banner = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 0.8rem;
`;

const accountIcon = css`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const userContainer = css`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const popOverContainer = css`
  .ant-popover-inner {
    width: fit-content;
    border-radius: 5px;
  }
`;

const tabContainer = css`
  padding-bottom: 24px;
`;

const appButton = css`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const footer = css`
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 500;
`;

const createButton = css`
  background: #db5a4b !important;
  color: white !important;
  font-weight: 600 !important;
  border: 0 !important;
  height: 2.2rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
`;

const editButton = css`
  &.ant-btn-default {
    border-radius: 8px;
    border: 0px !important;
    background: #db5a4b;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    height: 2.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white !important;
    :hover {
      border: 0px;
      background: #db5a4b;
    }
  }
`;

export default {
  container,
  appContainer,
  banner,
  accountIcon,
  userContainer,
  popOverContainer,
  tabContainer,
  footer,
  createButton,
  appButton,
  editButton,
}