import { css } from '@linaria/core';

const editor = css`
  border-radius: 5px;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .monaco-editor, .monaco-diff-editor {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 5px;
  }
  .monaco-editor .overflow-guard {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 5px;
  }
`;

const editorTopBar = css`
  background: #222222;
  border-radius: 5px;
  padding: 6px 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: end;
`;

const formatter = css`
  cursor: pointer; 
  font-weight: 800;
  font-size: 0.8rem;
  color: white;
`;

const viewQueryButton = css`
  border:none;
  background:transparent;
  color:var(--color-white);
  cursor:pointer;
`;


export default {
  editor,
  editorTopBar,
  formatter,
  viewQueryButton
}