import { css } from "@linaria/core";

const communityGraphContainer = css`
  position: relative;
  height: inherit;
  margin: 0 auto;
  flex-grow: 1;
`;

const communityDetails = css`
  margin: 10px
`;

const communityName = css`
  // width: 80%;
  font-weight: 800;
  font-size: 20px;
`;

const communityStatsTable = css`
  width: 40rem;
`;


const darkTable = css`
  table {
    border-color: white;
  }
  table > thead > tr > th {
    background-color: #171717;
    color: white;
  }
  table > tbody > tr > td {
    background-color: #222222 !important;
    color: white;
    border-bottom: none;
  }
  .ant-empty-description {
    color: white;
  }

  .ant-pagination {
    li {
      border: 0px;
      background-color: #222222;
      color: white;
      button {
        background-color: #222222;
        color: white;
        border: none;
        :hover {
          background-color: #222222;
          color: white;
        }
      }
      a {
        border: 0px;
        background-color: #222222;
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid white !important;
  }
`;




export default {
  communityGraphContainer,
  darkTable,
  communityDetails,
  communityName,
  communityStatsTable,
};
