import axios from "src/http/index";
import ApiUrls from "src/constants/apiUrls";

export class ClusterToolApiServices {
  static async getClusterListing(id, page, search) {
    const res = await axios.get(ApiUrls.GET_CLUSTER_LISTING(id, page, search))
    return res.data;
  }

  static async getConnections(id) {
    const res = await axios.get(ApiUrls.GET_SQL_CONNECTIONS(id));
    return res.data;
  }

  static async getSchema(id, connectionId) {
    const res = await axios.get(ApiUrls.GET_SQL_SCHEMA(id, connectionId));
    return res.data;
  }
  static async getClusterConfig(id) {
    const res = await axios.get(ApiUrls.GET_CLUSTER_CONFIG(id));
    return res.data;
  }
  static async createClusterConfig(formattedPayload) {
    const res = await axios.put(ApiUrls.CREATE_CLUSTER_CONFIG, formattedPayload);
    return res.data;
  }
  static async generateCluster(payload) {
    const res = await axios.put(ApiUrls.GENERATE_CLUSTER_CONFIG, payload);
    return res.data;
  }
  static async getActivationGroups(configId) {
    const res = await axios.get(ApiUrls.GET_ACTIVATION_GROUPS(configId));
    return res.data;
   }

   static async createAndEditActivationGroup(payload) {
    const res = await axios.put(ApiUrls.CREATE_EDIT_ACTIVATION_GROUP, payload);
    return res.data;
   }

   static async getSqlTable(clientId) { 
    const res = await axios.get(ApiUrls.GET_SQL_TABLE(clientId));
    return res.data;
   }

   static async getAudienceSize(clientId, tableName, clusterType) {
    const res = await axios.get(ApiUrls.GET_AUDIENCE_SIZE(clientId, tableName, clusterType));
    return res.data;
   }

   static async deleteClusterConfig(clusterId) { 
    const res  = await axios.get(ApiUrls.DELETE_CLUSTER_CONFIG(clusterId));
    return res.data;
   }

   static async getClusterFeature( payload, pageNum ) {
    const res = await axios.post(ApiUrls.GET_CLUSTER_FEATURES(pageNum), payload);
    return res.data;
   }

   static async getClusterFeatureMeanInfo (payload) {
    const res = await axios.post(ApiUrls.GET_CLUSTER_MEAN_INFO, payload);
    return res.data;
   }

   static async getClusterFeatureParentMeanInfo (payload) {
    const res = await axios.post(ApiUrls.GET_CLUSTER_FEATURE_MEAN_PARENT, payload);
    return res.data;
   }

   static async dowloadReports(payload) {
    const res = await axios.post(ApiUrls.DOWNLOAD_REPORTS, payload);
    return res.data;
   }

   static async clusterFeatureCorrelation(payload) {
    const res = await axios.post(ApiUrls.CLUSTER_FEATURE_CORRELATION, payload);
    return res.data;
   }

   static async getClusterCloneName(clusterId) {
    const res = await axios.get(ApiUrls.GET_CLUSTER_CLONE_NAME(clusterId));
    return res.data;
   }

    static async cloneCluster(name, clusterId) {
      const res = await axios.get(ApiUrls.CLONE_CLUSTER(name, clusterId));
      return res.data;
    }

    static async getClusterCoreFeatures(clusterId) {
      const res = await axios.get(ApiUrls.GET_CLUSTER_CORE_FEATURES(clusterId));
      return res.data;
    }
}