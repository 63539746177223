import { css } from '@linaria/core';

const container = css`
  padding: 0 1rem;
`;

const tabs = css`
  padding: 0.5rem 0.8rem;
  border: 0.8px solid var(--color-white);
  cursor: pointer;
  background: transparent;
  transition: all 0.1s ease;
`;

const activeTab = css`
  border: 1px solid var(--color-primary);
`;

export default {
  container,
  tabs,
  activeTab
}