import React from "react";
import Style from "./clusterPersonas.style";
import { Col, Row, Tabs } from "antd";
import { PrimaryButton } from "src/components/basic";
import useClusterPersonas from "./useClusterPersonas";
import ClusterDetails from "./ClusterDetails";
import ConditionalRender from "src/components/conditionalRender";

const ClusterPersonas = ({ data, clusterData }) => {
  const clusterType = clusterData?.clusters_type;
  const { onTabSwitch, tab } = useClusterPersonas();
  return (
    <div className={Style.container}>
      <Row gutter={[3, 3]} style={{ marginBottom: "1rem" }}>
        <Col>
          <div className={`${Style.tabs} ${tab === "demographic" && Style.activeTab}`} onClick={() => onTabSwitch("demographic")}>
            Demographic
          </div>
        </Col>
        <ConditionalRender shouldRender={clusterType === "CRM"}>
          <Col>
            <div className={`${Style.tabs} ${tab === "behavioural" && Style.activeTab}`} onClick={() => onTabSwitch("behavioural")}>
              Behavioural
            </div>
          </Col>
        </ConditionalRender>
        <Col>
          <div className={`${Style.tabs} ${tab === "activation" && Style.activeTab}`} onClick={() => onTabSwitch("activation")}>
            Activation
          </div>
        </Col>
        <Col>
          <div className={`${Style.tabs} ${tab === "brand" && Style.activeTab}`} onClick={() => onTabSwitch("brand")}>
            Brand Engagement
          </div>
        </Col>
      </Row>
      <ClusterDetails data={data} tab={tab} clusterData={clusterData} />
    </div>
  );
};

export default ClusterPersonas;
