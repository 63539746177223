import { format, parseISO } from "date-fns";
import Cookie from "src/lib/cookie";

export const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "MMM d, yyyy h:mm a");
};

export const createWebSocketConnection = (
  url,
  onMessage,
  onError,
  maxRetries = 5,
  retryInterval = 1000
) => {
  let retries = 0;
  let socket;
  let reconnectTimeout;

  const connect = () => {
    if (
      socket &&
      (socket.readyState === WebSocket.OPEN ||
        socket.readyState === WebSocket.CONNECTING)
    ) {
      console.log(
        "WebSocket is already open or connecting. Skipping new connection."
      );
      return; // Prevent opening a new WebSocket if one is already open or connecting.
    }

    // Get the token from cookies
    const token = Cookie.get("token");

    // Append the token as a query parameter to the WebSocket URL
    const wsUrl = `${url}?token=${encodeURIComponent(token)}`;

    socket = new WebSocket(wsUrl);

    socket.onopen = () => {
      console.log("WebSocket connection opened.");
      retries = 0; // Reset retries when the connection is successful
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (onMessage) onMessage(data);
    };

    socket.onerror = (err) => {
      console.error("WebSocket error:", err);
      if (onError) onError(err);
      reconnect();
    };

    socket.onclose = (event) => {
      console.log("WebSocket closed!");
      reconnect();
    };
  };

  const reconnect = () => {
    if (retries < maxRetries) {
      const retryTime = retryInterval * Math.pow(2, retries); // Exponential backoff
      console.log(`Reconnecting in ${retryTime / 1000} seconds...`);

      // Clear any previous reconnect attempt
      clearTimeout(reconnectTimeout);

      // Attempt to reconnect after a delay
      reconnectTimeout = setTimeout(() => {
        retries += 1;
        connect();
      }, retryTime);
    } else {
      console.error("Max retries reached, WebSocket connection failed.");
    }
  };

  const closeConnection = () => {
    if (socket) {
      console.log("Closing WebSocket connection.");
      socket.close();
    }

    // Clear any pending reconnect timeouts
    clearTimeout(reconnectTimeout);
  };

  // Initialize WebSocket connection
  connect();

  // Return a cleanup function to close the connection
  return closeConnection;
};
