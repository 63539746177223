import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuUnfoldOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { useEffect, useRef } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AppUrls from "src/constants/appUrls";
import "./ActionMenu.less";

const ActionMenu = ({
  isOpen,
  onToggle,
  closeMenu,
  mediaPlanId,
  mediaData,
}) => {
  const menuRef = useRef(null);
  const history = useHistory();
  const { clientId } = useParams();

  // Effect to detect clicks outside of the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu(); // Close the menu if clicked outside
      }
    };

    // Add event listener when the menu is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, closeMenu]);

  return (
    <div className="action-menu-container" ref={menuRef}>
      {isOpen ? (
        <div className="action-items">
          {/* Clone Media Plan */}
          {mediaData?.status === "DRAFT" && (
            <Tooltip title="Clone Media Plan">
              <Button icon={<CopyOutlined />} />
            </Tooltip>
          )}

          {/* Edit Media Plan shown if the status is "DRAFT" */}
          <Tooltip title="Edit Media Plan">
            <Button icon={<EditOutlined />} />
          </Tooltip>

          {/* Delete Media Plan */}
          <Tooltip title="Delete Media Plan">
            <Button icon={<DeleteOutlined />} />
          </Tooltip>
          <Tooltip title="View Dashboard">
            <Button
              icon={<WindowsOutlined />}
              onClick={() =>
                history.push(
                  AppUrls.MEDIA_PLAN_DASH(clientId, mediaData?.response_id)
                )
              }
            />
          </Tooltip>
        </div>
      ) : (
        <Button icon={<MenuUnfoldOutlined />} onClick={onToggle} />
      )}
    </div>
  );
};

export default ActionMenu;
