import { css } from "@linaria/core";

const container = css`
  .ant-checkbox-disabled + span {
    color: var(--color-white);
  }
`;

const microCardContainer = css`
  cursor: pointer;
  aspect-ratio: 1;
  border: 1px solid #666;
  display: flex;
  flex-direction: column;
  background: var(--color-full-black) !important;
  width: 160px;
  height: 160px;
  transition: all 0.2s;
  padding: 24px 16px;
  justify-content: space-between;
  outline: none;
  text-align: left;
  color: var(--color-gale-gray);

  .icon {
    color: white;
    opacity: 0.8;
    transition: all 0.2s ease-in;
  }

  &.align-bottom {
    justify-content: flex-end;
  }
  @media screen and (min-width: 768px) {
    width: 193px;
    height: 193px;
  }
  &:hover {
    border-color: var(--color-white);
    background: var(--color-charcoal-surface-1) !important;
    .arrow {
      transform: translateX(4px);
    }
    .icon {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    border-color: var(--color-primary);
    background: var(--color-charcoal-surface-2) !important;

    .arrow {
      color: var(--color-primary);
      transform: translateX(4px);
    }
    .plus-btn {
      color: var(--color-primary);
    }
  }
`;

const microAppLabelContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const usersContainer = css`
  display: flex;
  flex-grow: 1;
  gap: 1rem;
`;

const userSetingsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const usersAndPermissionsCollapse = css`
  max-height: 140px;
  overflow-y: auto;
`;

const userItemContainer = css`
  background: var(--color-charcoal-surface-1);
  padding: 9px 10px;
  border-radius: 5px;
`;

const userItemContainerWithoutRole = css`
  background: var(--color-charcoal-surface-1);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
`;

const configContainer = css`
  display: flex;
  gap: 1rem;
`;

const disabledContainer = css`
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
`;

const list = css`
  .ant-list-header {
    border-bottom: none !important;
    padding-bottom: 0 !important;
  }
  margin-bottom: 1rem;
`;

const listHeader = css`
  display: flex;
  flex-direction: row;
  color: white;
  border: none;
  padding: 24px;
`;

const listItem = css`
  margin-top: 1rem;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  font-size: 14px;
  background: var(--color-charcoal-surface-1);
  color: var(--color-white);
  gap: 8px;
  @media screen and (min-width: 768px) {
    padding: 24px;
    flex-direction: row;
  }
`;

const listItemInner = css`
  .list-item-label {
    color: var(--color-40-black);
    margin-bottom: 0;
  }
  .list-item-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
`;

const deleteContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export default {
  microCardContainer,
  microAppLabelContainer,
  container,
  usersContainer,
  userSetingsContainer,
  usersAndPermissionsCollapse,
  userItemContainer,
  userItemContainerWithoutRole,
  configContainer,
  disabledContainer,
  list,
  listHeader,
  listItem,
  listItemInner,
  deleteContainer,
};
