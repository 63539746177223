import { DislikeFilled, LikeFilled } from "@ant-design/icons";
import { Checkbox, Form, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import { formatString } from "src/lib/utils";
import { FEEDBACK_ENUMS } from "./constants";
import useFeedbackFormHook from "./hooks/useFeedbackFormHook";

export default function FeedbackForm({ feedbackData, submitFeedback, data }) {
  const { loading, formRef, initialValues, setLoading } = useFeedbackFormHook({ feedbackData });

  return (
    <div>
      <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
        {feedbackData?.reaction === "LIKED" ? (
          <LikeFilled style={{ fontSize: "1.25rem" }} />
        ) : (
          <DislikeFilled style={{ fontSize: "1.25rem" }} />
        )}
        &nbsp;&nbsp;Feedback
      </div>
      <ConditionalRender shouldRender={data.is_private}>
        <div style={{ marginBottom: '0.5rem' }}>
        <b>Note:</b> <i style={{color: 'gray'}}>Admin will be able to view your message to resolve the feedback.</i>
          </div>
      </ConditionalRender>
      <div>

      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Form
          ref={formRef}
          initialValues={initialValues}
          onFinish={async (val) => {
            setLoading(true);
            let payload = {
              chat_id: feedbackData?.data?.id,
              feedback: val?.feedback ? val?.feedback : null,
              user_reaction: feedbackData?.reaction,
            };
            if (val?.category) {
              payload['category'] = val?.category
            }
            await submitFeedback(feedbackData, payload, feedbackData?.idx);
            setLoading(false);
          }}
        >
          <ConditionalRender shouldRender={feedbackData?.reaction !== "LIKED"}>
            <Form.Item
              name="category"
            >
              <Checkbox.Group>
              {FEEDBACK_ENUMS.map((ele) => {
                return (
                  <div><Checkbox value={ele}>{formatString(ele)}</Checkbox></div>
                )
              })}
              </Checkbox.Group>
            </Form.Item>
          </ConditionalRender>

          <Form.Item 
            name="feedback"
            rules={[
              {
                required: feedbackData?.reaction === "LIKED" ? false : true,
                message: 'Feedback is required'
              }
            ]}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 7 }}
              style={{ borderRadius: "5px" }}
              placeholder={
                feedbackData?.data?.user_reaction === "LIKED"
                  ? "What do you find good about the response?"
                  : "What was the problem with the response?"
              }
            />
          </Form.Item>
        </Form>
      </div>
      <Row justify="end">
        <SecondaryButton loading={loading} onClick={() => formRef.current.submit()}>
          Done
        </SecondaryButton>
      </Row>
    </div>
  );
}
