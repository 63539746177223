import { Divider, Card, Row, Col, Collapse } from "antd";

import { Skeleton } from "antd";
import React from "react";
import useEvaluationDetailHook from "../hooks/evaluationDetailHook";
import MarkdownRender from "src/modules/ConversationContainer/MarkdownRender";
import ConversationResponse from "src/modules/ConversationContainer/conversations/components/ConversationResponse";
import ScoreConfigToolTip from "./scoreConfig";
import ConditionalRender from "src/components/conditionalRender";
import Style from "./style";
import { TAG_COLOR_NAME } from "src/components/globalTag/globalTagConstant";
import GlobalTag from "src/components/globalTag/GlobalTag";

export default function EvaluationDetail({ evaluationId, appId }) {
  const { loading, evaluationDetail } = useEvaluationDetailHook({ evaluationId, appId });
  if (loading) {
    return <Skeleton active />;
  }
  // Same height for both columns
  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between">
        {evaluationDetail &&
          evaluationDetail?.responses?.map((each, idx) => {
            let scoreColor = TAG_COLOR_NAME.GREEN;
            if (each.score < 5) {
              scoreColor = TAG_COLOR_NAME.RED;
            } else if (each.score < 7) {
              scoreColor = TAG_COLOR_NAME.YELLOW;
            } else {
              scoreColor = TAG_COLOR_NAME.GREEN;
            }

            return (
              <>
                <Col span={24} >
                  <div className={Style.cardContainer}>
                    <Card title={`Question: ${idx + 1}`} size="small">
                      <span style={{ float: "right" }}>
                        <ScoreConfigToolTip isIntermediateStep={each.expected_intermediate_step} />
                        &nbsp;
                        <GlobalTag color={scoreColor}>
                          <b>Score: {each.score}</b>
                        </GlobalTag>
                      </span>

                      <div>
                        <b>Question</b>
                        <MarkdownRender children={each.question} />
                      </div>
                      <div>
                        <b>Expected Answer</b>
                        <MarkdownRender children={each.expected_answer} />
                      </div>
                      <ConditionalRender shouldRender={each.expected_intermediate_step}>
                        <div>
                          <b>Expected Intermediate Steps</b>
                          <MarkdownRender children={each.expected_intermediate_step} />
                        </div>
                      </ConditionalRender>
                      <div>
                        <b>A.I Evaluation</b>&nbsp;
                        <MarkdownRender children={each.reasoning} />
                      </div>
                      <Collapse defaultActiveKey={["1"]} className={'drawerCollapse'}>
                        <Collapse.Panel forceRender={true} key="0" header="Actual Response">
                          <ConversationResponse
                            key={each.message?.id}
                            data={each.message?.ai_output}
                            analysisData={each.message?.intermediate_steps}
                            mAiApp={appId}
                            chatId={each.message?.id}
                            hasAnalysis={each.message?.has_analysis}
                            feedbackScreen={false}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    </Card>
                  </div>
                </Col>
                <Divider />
              </>
            );
          })}
      </Row>
    </div>
  );
}
