import { Col, Drawer, List, Modal, Progress, Row, Select, Skeleton, Table } from "antd";
import React from "react";
import Style from "./newSqlTool.style";
import useNewSqlToolHook from "./useNewSqlToolHook";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import SqlToolTabs from "./sqlToolTabs/SqlToolTabs";
import SqlGlobalSettings from "./sqlGlobalSettings/SqlGlobalSettings";
import ExploreDatabase from "./exploreDatabase.jsx/ExploreDatabase";
import LoadingSpinner from "src/components/loadingSpinner";
import moment from "moment";

export default function NewSqlTool({ id, clientId, metaData, aiAppData, updateCustomApp }) {
  const {
    loading,
    connectionString,
    globalSettingsDrawer,
    setGlobalSettingsModal,
    selectedConnection,
    changeConnection,
    schema,
    sqlData,
    onChangeConnectionLoad,
    syncColumns,
    setSqlData,
    syncColumnLoading,
  } = useNewSqlToolHook({ id, clientId });
  return (
    <>
      <ConditionalRender shouldRender={loading}>
        <LoadingSpinner centered />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <ConditionalRender shouldRender={connectionString?.length === 0}>
          <i style={{ color: "gray", fontWeight: "600" }}>
            No datasource connection exists for this app. Please connect with Alchemy AI Admin Team to add the connection.
          </i>
        </ConditionalRender>
        <ConditionalRender shouldRender={connectionString?.length > 0}>
          <ConditionalRender shouldRender={!!sqlData?.sync_column_index_data?.status}>
            <ConditionalRender shouldRender={sqlData?.sync_column_index_data?.status === "IN_PROGRESS"}>
              <div className={Style.fileprocessedContainer}>
                <div>Indexing data</div>
                <Progress
                  style={{ width: "139px" }}
                  strokeColor={{
                    from: "#108ee9",
                    to: "#87d068",
                  }}
                  showInfo={true}
                  percent={(sqlData?.sync_column_index_data?.columns_parsed / sqlData?.sync_column_index_data?.total_columns) * 100}
                  status={sqlData?.sync_column_index_data?.status === "IN_PROGRESS" ? "active" : "normal"}
                />
              </div>
            </ConditionalRender>
            <ConditionalRender shouldRender={sqlData?.sync_column_index_data?.status === "DONE"}>
              <div className={Style.fileprocessedContainer}>
                <span>Last Indexed at:</span>
                {moment(sqlData?.sync_column_index_data?.completed_processing_at).format("MMMM Do YYYY, h:mm A")}
              </div>
            </ConditionalRender>
          </ConditionalRender>
          <div className={Style.container}>
            <div className={Style.connectionContainer}>
              <Row justify="space-between">
                <Col xs={24} md={8}>
                  <span className={Style.label}>Datasource:</span>
                  <Select
                    onChange={(val) => changeConnection(val)}
                    value={selectedConnection}
                    style={{ width: "100%" }}
                    placeholder="Connections"
                    disabled={onChangeConnectionLoad}
                  >
                    {connectionString?.map((item) => {
                      return <Select.Option value={item?.connection_id}>{item?.connection_name}</Select.Option>;
                    })}
                  </Select>
                </Col>
                <Col xs={24} md={16} style={{display:'flex', alignItems:'flex-end', marginTop:16}}>
                  <Row justify={'end'} style={{width:'100%'}}>
                      <SecondaryButton
                        loading={sqlData?.sync_column_index_data?.status === "IN_PROGRESS" || syncColumnLoading}
                        onClick={() => syncColumns()}
                      >
                        Index data
                      </SecondaryButton>
                      &nbsp;&nbsp;

                    <SecondaryButton onClick={() => setGlobalSettingsModal(true)} className={Style.button}>
                      Settings
                    </SecondaryButton>
                  </Row>
                </Col>
              </Row>
            </div>
            <ConditionalRender shouldRender={onChangeConnectionLoad}>
              <Row style={{ height: "calc(100vh - 290px)" }} align="middle">
                <LoadingSpinner centered />
              </Row>
            </ConditionalRender>
            <ConditionalRender shouldRender={!onChangeConnectionLoad}>
              <SqlToolTabs
                selectedConnection={selectedConnection}
                connectionString={connectionString}
                clientId={clientId}
                sqlData={sqlData}
                mAiApp={id}
                setSqlData={setSqlData}
              />
            </ConditionalRender>
          </div>
        </ConditionalRender>
      </ConditionalRender>
      <Drawer
        title="Settings"
        maskClosable={true}
        placement="right"
        onClose={() => setGlobalSettingsModal(false)}
        open={globalSettingsDrawer}
        destroyOnClose
        size="large"
        width={"85%"}
      >
        <SqlGlobalSettings metaData={metaData} aiAppData={aiAppData} mAiApp={id} updateCustomApp={updateCustomApp} />
      </Drawer>
    </>
  );
}
