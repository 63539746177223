import { notification } from "antd";
import { useState, useEffect, useRef, useContext } from "react";
import AppUrls from "src/constants/appUrls";
import CustomGptApiServices from "../services/customGptApiServices";
import { CustomGptService } from "../services/customGptServices";
import AppContext from 'src/context/appContext';
import { getErrorMessageFromResponse } from "src/lib/utils";

export default function useCustomGptHook({ id, history, mode, clientId }) {
  const appContext = useContext(AppContext.Context)
  const [loading, setIsLoading] = useState(true);
  const [createGptLoading, setCreateGptLoading] = useState(false);
  const [tabs, setTabs] = useState(mode);
  const [metaData, setMetaData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const formRef = useRef(null);

  const getMetaData = async () => {
    try {
      setIsLoading(true);
      const res = await CustomGptApiServices.getMetaData();
      setMetaData(res.data);
      if (id) {
        const appData = await CustomGptApiServices.getCustomAppData(id);
        if (appData?.data?.version === 'v1') {
          setTabs('users')
        }
        setInitialValues(appData.data);
      } else {
        const formattedInitialValues = CustomGptService.formatInitialValues(res.data);
        formattedInitialValues['client_name'] = appContext?.clientSpaceData?.client_space?.name;
        setInitialValues(formattedInitialValues);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        duration: 5,
        message: "Failed to load",
        placement: "bottomRight"
      });
    }
  };

  const createGpt = async (data) => {
    try {
      setCreateGptLoading(true);
      let formattedPayload = CustomGptService.formatCreateGptPayload(data);
      if (id) {
        const res = await CustomGptApiServices.editCustomGpt(formattedPayload, id);
        // await appContext?.updateMetaData();
        notification.success({
          duration: 5,
          message: "App edited successfully.",
          placement: "bottomRight"
        });
      } else {
        const res = await CustomGptApiServices.createCustomGpt(formattedPayload);
        setInitialValues(res.data);
        // await appContext?.updateMetaData();
        history.push(AppUrls.EDIT_CUSTOM_GPT(clientId, res.data.id));
        setTabs("capability");
        notification.success({
          duration: 5,
          message: "App created successfully.",
          placement: "bottomRight"
        });
      }
      setCreateGptLoading(false);
    } catch (error) {
      setCreateGptLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const updateCustomApp = async (val) => {
    try {
      setInitialValues(val);
    } catch (error) {}
  };

  useEffect(() => {
    setTabs(mode);
  }, [history.location.pathname])

  useEffect(() => {
    getMetaData();
  }, []);

  return {
    loading,
    formRef,
    metaData,
    initialValues,
    createGpt,
    tabs,
    setTabs,
    createGptLoading,
    updateCustomApp,
  };
}
