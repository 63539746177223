import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import audienceListService from "../../services/audienceListService";

export const useDashboardMP = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const timeoutRef = useRef(null); // To hold reference to the timeout

  const fetchRecommendedMediaPlan = async (id) => {
    try {
      setLoading(true);
      // Fetch recommended media plan data
      const res = await audienceListService.getRecommendedMediaPlan(id);
      if (res?.success) {
        setData(res?.data?.output);
        if (res?.data?.output === null) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        if (timeoutRef.current && res?.data?.output !== null) {
          clearTimeout(timeoutRef.current); // Clear the timeout once data is fetched
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    if (id === undefined) return;
    const debounceFetch = () => {
      fetchRecommendedMediaPlan(id);
      timeoutRef.current = setTimeout(debounceFetch, 5000); // Repeat every 5 seconds if needed
    };

    debounceFetch(); // Call the function initially

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear the timeout on component unmount or id change
      }
    };
  }, [id]);

  return {
    loading,
    data,
  };
};
