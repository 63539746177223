import {
    Button,
    Form,
    Input,
    Checkbox,
    Divider,
    Card,
  } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Skeleton } from 'antd';
import React from "react";
import useDatasetHooks from "../hooks/datasetHook";
import Style from "./style";
import { PrimaryButton } from 'src/components/basic';



export default function Dataset({ datasetId, appId, onClose }) {
    const { loading, datasetData, createOrEditDataSet } = useDatasetHooks({ datasetId, appId, onClose });
    const [form] = Form.useForm();
    if (loading) {
        return <Skeleton active />
    }
    return(
        <div>
            <Form
                onFinish={async val => {
                    createOrEditDataSet(val)
                }}
                initialValues={datasetData}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                variant="filled"
                style={{
                maxWidth: 700,
                }}
                form={form}
            >
                <Form.Item
                    label="Dataset Name"
                    name="name"
                    rules={[{
                        required: true,
                        message: 'Enter Dataset Name',
                        min: 2,
                    }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Is Conversational"
                    name="is_conversation_based"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>

                <Form.List
                    name="data"
                >
                    {(fields, { add, remove }) => (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                rowGap: 16,
                                flexDirection: 'column',
                            }}
                            className={Style.cardContainer}
                        >
                            {fields.map((field, idx) => (
                            <Card
                                size="small"
                                title={`Test Case ${field.name + 1}`}
                                key={field.key}
                                extra={
                                <CloseOutlined
                                    style={{color: 'gray'}}
                                    onClick={() => {
                                    remove(field.name);
                                    }}
                                />
                                }
                            >
                                <Form.Item
                                    label="Question"
                                    name={[field.name, 'question']}
                                    rules={[{
                                        required: true,
                                        message: 'Enter Question',
                                        min: 2,
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Expected Steps"
                                    name={[field.name, 'expected_intermediate_step']}
                                    rules={[{
                                        required: false,
                                        message: 'Enter expected_intermediate_step',
                                        min: 2,
                                    }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Expected Answer"
                                    name={[field.name, 'expected_answer']}
                                    rules={[{
                                        required: true,
                                        message: 'Enter Expected Answer',
                                        min: 2,
                                    }]}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                            
                            </Card>
                            ))}

                        </div>
                        <PrimaryButton type="small" type="dashed" onClick={() => add()} block>
                        + Add Test Case
                        </PrimaryButton>
                    </>
                    
                    )}
                </Form.List>
                <Divider />
                <Form.Item
                    style={{float: 'right'}}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
};