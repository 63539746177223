import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import formatMediaData from '../../constants/constants';
import MediaPlanListService from '../../services/mediaPlanListService';

export default function useMediaPlanListing() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [mediaData, setMediaData] = useState({
    data: [],
    current_page: 1,
    total: 0,
    per_page: 10,
    total_pages: 0,
  });
  const history = useHistory();

  const { clientId } = useParams();

  // Fetch media plans based on updated payload
  const getMediaPlan = async (page) => {
    try {
      setLoading(true);
      const res = await MediaPlanListService.getMediaPlanList(clientId, page);
      const formattedData = formatMediaData(res);
      setMediaData({
        data: formattedData,
        current_page: res.data.current_page,
        total: res.data.total,
        per_page: res.data.per_page,
        total_pages: res.data.total_pages,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching media plans:', error);
    }
  };

  useEffect(() => {
    getMediaPlan(page);
  }, [page]); // Only run this effect once when the component mounts

  // Handle pagination changes
  const onPaginationChange = (page) => {
    setPage(page);
  };

  return {
    mediaData,
    page,
    loading,
    onPaginationChange,
    history,
    clientId,
  };
}
