/**
 * Renders the children only if the condition passed in shouldRender is true
 * @param {Object} props
 * @param {JSX.Element|String|Array<JSX.Element|String>|Function|JSX.Element[]} props.children
 * @param {Boolean} props.shouldRender
 * @return {null|JSX.Element}
 */
 function ConditionalRender({
    shouldRender,
    children,
  }) {
    if (shouldRender) {
      return (children instanceof Function) ? children() : children;
    }
  
    return null;
  }
  
  export default ConditionalRender;
  