import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";

const useKnowledgeGraphHooks = ({
  nodes,
  links,
  categories,
  onNodeClick,
  onLinkClick,
  knowledgeGraphChanged,
  tooltipDelay = 300,
}) => {
  const [option, setOption] = useState({});
  const chartRef = useRef(null);
  const [highlightedNode, setHighlightedNode] = useState(null);
  const [mouseOutState, setMouseOutState] = useState(null);
  const [friction, setFriction] = useState(0.5);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const prevHighlightedNode = useRef(null); // Add useRef to keep track of the previous highlighted node

  const tooltipFormatter = useCallback((params) => {
    if (params.dataType === "node") {
      var tooltipString = `<div><strong>${params?.data?.name}</strong><br/>Community: ${params?.data?.community}</div>`;
      if (!params?.data?.community) {
        tooltipString = `<div><strong>${params?.data?.name}</strong></div>`;
      }
      if (params?.data?.description) {
        tooltipString = `<div style="max-width: 400px; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;"><strong>${params?.data?.name}</strong><br/> ${params?.data?.description}</div>`;
      }
      return tooltipString;
    } else if (params.dataType === "edge") {
      return `<div style="max-width: 400px; word-wrap: break-word; overflow-wrap: break-word; white-space: normal;">Connection: <strong>${params.data.source_node} - ${params.data.target_node}</strong><br/>${params.data.connection}</div>`;
    }
  }, []);

  useEffect(() => {
    if (nodes && links && categories) {
      const clonedNodes = cloneDeep(nodes);
      const clonedLinks = cloneDeep(links);
      const clonedCategories = cloneDeep(categories);
      const showNodeLabels = {};
      const showEdgeLabels = {};
      clonedLinks.forEach((link) => {
        if (highlightedNode && (link.source === highlightedNode.id || link.target === highlightedNode.id)) {
          showEdgeLabels[link.id] = true;
          showNodeLabels[link.source] = true;
          showNodeLabels[link.target] = true;
          // link.emphasis = { disabled: true };
        } else if (highlightedNode) {
          link.lineStyle = { opacity: 0.1 };
          link.blur = { lineStyle: { opacity: 0.1 } };
          link.emphasis = { disabled: true };
        }
      });
      clonedNodes.map((node) => {
        if (showNodeLabels[node.id]) {
          node.label.show = true;
          node.emphasis.disabled = true;
        } else if (highlightedNode) {
          node.emphasis.disabled = true;
        }
      });

      const newOptions = {
        tooltip: {
          trigger: "item",
          formatter: tooltipFormatter,
          showDelay: tooltipDelay,
          show: true,
        },
        legend: {
          silent: true,
          emphasis: {
            selectorLabel: {
              show: false,
            },
          },
          backgroundColor: "#262626",
          selector: [
            {
              type: "all",
              title: "All",
            },
            {
              type: "inverse",
              title: "Inv",
            },
          ],
          selectorLabel: {
            color: "#fff",
          },
          data: clonedCategories.map((category) => ({
            name: category.name,
            textStyle: { color: "#fff" },
          })),
          type: "scroll",
          textStyle: {
            color: "#fff",
          },
          pageIconColor: "#fff",
          pageTextStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "graph",
            layout: clonedNodes[0]?.x !== undefined ? "none" : "force",
            data: clonedNodes,
            links: clonedLinks,
            categories: clonedCategories,
            legendHoverLink: false,
            roam: true,
            lineStyle: {
              color: "source",
              curveness: 0.3,
            },
            scaleLimit: {
              min: 0.05,
              // max: 2,
            },
            force: {
              repulsion: 100,
              friction: friction,
              // edgeLength: 100,
            },
            emphasis: {
              focus: "adjacency",
              lineStyle: {
                width: 10,
              },
              label: {
                show: true,
              },
            },
            labelLayout: {
              hideOverlap: true,
            },
          },
        ],
      };
      if (links && (links.length < 20)) {
        newOptions.series[0].force.edgeLength = 100;
      }
      setOption(newOptions);
    }
  }, [nodes, links, categories, highlightedNode, friction]);

  useEffect(() => {
    setFriction(0.6);
  }, [nodes, links, categories]);

  useEffect(() => {
    setFriction(0.1);
  }, [highlightedNode]);

  const onChartEvents = useMemo(
    () => ({
      click: (params) => {
        if (params.dataType === "node") {
          onNodeClick && onNodeClick(params.data);
        } else if (params.dataType === "edge") {
          onLinkClick && onLinkClick(params.data);
        } else {
          // Reset highlight when clicking on empty space
          setHighlightedNode(null);
        }
      },
      contextmenu: (params) => {
        params.event.event.preventDefault();
        if (params?.dataType === "node") {
          setHighlightedNode(params.data);
        } else {
          setHighlightedNode(null);
        }
      },
      mouseout: (params) => {
        setMouseOutState(Math.random());
      },
      highlight: (params) => {
        if (params.seriesIndex === undefined) {
          setMouseOutState(Math.random());
        }
      },
      downplay: (params) => {
        if (params.seriesIndex === undefined) {
          setMouseOutState(Math.random());
        }
      },
    }),
    [onNodeClick, onLinkClick],
  );

  const resetZoom = () => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance();
      if (chartInstance) {
        chartInstance.setOption(option, true);
        const zRender = chartInstance.getZr();
        if (zRender) {
          zRender.on("click", (event) => {
            if (!event.target) {
              setHighlightedNode(null);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    setHighlightedNode(null);
    resetZoom();
  }, [knowledgeGraphChanged]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance();
      if (chartInstance) {
        if (highlightedNode) {
          chartInstance.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: nodes.findIndex((node) => node.id === highlightedNode.id),
          });
        } else if (prevHighlightedNode.current) {
          // Check if the previous highlighted node was not null
          chartInstance.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
          });
        }
      }
    }
    prevHighlightedNode.current = highlightedNode; // Update the previous highlighted node
  }, [highlightedNode, nodes, mouseOutState]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape" && isFullScreen) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullScreen, setIsFullScreen]);

  return { option, onChartEvents, chartRef, setHighlightedNode, highlightedNode, isFullScreen, setIsFullScreen };
};

export default useKnowledgeGraphHooks;
