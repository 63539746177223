import React, { useEffect, useState } from 'react';
import ConditionalRender from 'src/components/conditionalRender';
import useNewLoginHook from 'src/modules/authentication/hooks/useNewLoginHook';
import LoginForm from './LoginForm';
import Style from './login.style';
// import Email from './Email';
// import Password from './Password';
import ResetPassword from './ResetPassword';

export default function Login() {
  const { data, tab, navigateToPassword, navigateToEmail, doLogin, loading, resetPassword } = useNewLoginHook();
  const [formData, setFormData] = useState(data);

  useEffect(()=>{
    setFormData(data);
  }, [data])

  return (
    <div className={Style.container + ' transition-all'}>
      <ConditionalRender shouldRender={tab === 'email' || tab === 'password'}>
        <LoginForm data={formData} navigateToPassword={navigateToPassword} displayPassword={tab==='password'} navigateToEmail={navigateToEmail} doLogin={doLogin} loading={loading}/>
      </ConditionalRender>
      {/* below is the seperate steps for email and password */}
      {/* <ConditionalRender shouldRender={tab === 'email'}>
        <Email data={data} navigateToPassword={navigateToPassword} />
      </ConditionalRender>
      <ConditionalRender shouldRender={tab === 'password'}>
        <Password data={data} navigateToEmail={navigateToEmail} doLogin={doLogin} loading={loading}/>
      </ConditionalRender> */}
      <ConditionalRender shouldRender={tab === 'resetPassword'}>
        <ResetPassword data={data} loading={loading} resetPassword={resetPassword}/>
      </ConditionalRender>
    </div>
  )
}