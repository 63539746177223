import React from 'react';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom';
import appUrls from 'src/constants/appUrls';
import AnalysisOverviewScreen from './pages/AnalysisOverviewScreen';
import CreateListingScreen from './pages/CreateListingScreen';
import ListingScreen from './pages/ListingScreen';

const NewsAndCultureLayout = () => {
  return (
    <Switch>
      <Route
        exact
        path={appUrls.NEWS_CULTURE(':clientId')}
        component={ListingScreen}
      />
      <Route
        exact
        path={appUrls.CREATE_LISTING_SCREEN(':clientId', ':analysisId?')}
        component={CreateListingScreen}
      />
      {/* Test Query Builder here */}
      {/* <Route
        exact
        path={appUrls.CREATE_ANALYSIS_QUERY(':clientId', ':analysisId?')}
        component={CreateAnalysisQuery}
      /> */}
      <Route
        exact
        path={appUrls.UPDATE_LISTING_SCREEN(':clientId', ':analysisId')}
        component={CreateListingScreen}
      />
      <Route
        exact
        path={appUrls.ANALYSIS_OVERVIEW_SCREEN(':clientId', ':analysisId')}
        component={AnalysisOverviewScreen}
      />
    </Switch>
  );
};

export default NewsAndCultureLayout;
