import { css } from "@linaria/core";

const homeCardContainer = css`
  cursor: pointer;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  min-width: 163px;
  min-height: 163px;
  border: 1px solid var(--color-60-gray);
  background: var(--color-full-black);
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
    border-color: var(--color-white);
    background: var(--color-charcoal-surface-1) !important;
  }
  &:focus {
    opacity: 1;
    border-color: var(--color-primary);
    background: var(--color-charcoal-surface-2) !important;
  }
  @media screen and (min-width: 768px) {
    max-width:183px;
    max-height:183px;
  }
`;

const imageContainer = css`
  color:var(--color-white);
  height: 9rem;
  width: 100%;
  height: 100%;
  background: transparent !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
`;

const clientLogo = css`
  max-width:108px;
  pointer-events: none;
  object-fit: cover;
`;

export default {
  homeCardContainer,
  imageContainer,
  clientLogo
};
