import { css } from "@linaria/core";

export const searchFilterContainer = css`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 1440px;
`;

export const searchInputWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 250px;
`;

export const searchInput = css`
  .ant-input-group > .ant-input:first-child {
    border: none !important;
  }
`;

export const selectFilter = css`
  width: 180px;
`;

export const validationMessage = css`
  color: var(--color-tag-red-txt);
  font-size: 12px;
  margin-top: 2px;
`;
