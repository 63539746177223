
import React from "react";

export default function ConfigurationIcon({ size=18 }) {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${size}px`}
        height={`${size}px`}
        fill="none"
        viewBox="0 0 33 32"
      >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.113 10.666h16m0 0a4 4 0 108 0 4 4 0 00-8 0zm-8 10.667h16m-16 0a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
    </svg>
  );
}
