import React, { useEffect, useState } from 'react';
import Style from './footer.style';

const Footer = ({transparent, relativePosition = false}) => {
  const[ addOnClass, setAddOnClass] = useState('');
  const [currentYear, setCurrentYear] = useState(2024);

  useEffect(()=>{
    let classes = '';
    if (transparent) {
      classes += ' transparent-bg';
    }
    if (relativePosition) {
      classes += ' relative';
    }
    setAddOnClass(classes);
    const currentTime = new Date().getFullYear();
    setCurrentYear(currentTime);
  }, [transparent, relativePosition])

  
  return (
    <footer className={Style.footer + `${addOnClass}`} >{`©${currentYear} GALE | All rights reserved`}</footer>
  );
};

export default Footer;
