import { Button } from 'antd';
import './button.less';

export const OutlinedButton = ({ name, icon, ...rest }) => {
  return (
    <Button className="button-with-icon" icon={icon} {...rest}>
      {name}
    </Button>
  );
};

export const FilledButton = ({ name, icon, ...rest }) => {
  return (
    <Button className="button-with-icon filled-button" icon={icon} {...rest}>
      {name}
    </Button>
  );
};
