import { Select } from "antd";
import ReactECharts from "echarts-for-react";
import React, { useState } from "react";

const { Option } = Select;

const GanttChart = ({ response }) => {
  const [filterType, setFilterType] = useState("channel");

  const getSeriesData = () => {
    const seriesDataMap = new Map();

    response.media_plan.forEach((plan) => {
      plan.weekly_plan.forEach((week) => {
        const key = `${plan[filterType]}_${new Date(
          week.week_start_date
        ).getTime()}`;
        if (seriesDataMap.has(key)) {
          const existingEntry = seriesDataMap.get(key);
          existingEntry.value[3] += week.budget; // Sum the budget
        } else {
          seriesDataMap.set(key, {
            name: plan[filterType],
            value: [
              plan[filterType],
              new Date(week.week_start_date).getTime(),
              new Date(week.week_end_date).getTime(),
              week.budget,
            ],
          });
        }
      });
    });

    // Convert the map to an array
    const seriesData = Array.from(seriesDataMap.values());

    // Sort series data by the filter type and start date
    seriesData.sort((a, b) => {
      if (a.value[0] < b.value[0]) return -1;
      if (a.value[0] > b.value[0]) return 1;
      return a.value[1] - b.value[1];
    });

    return seriesData;
  };

  const getYAxisData = () => {
    const yAxisData = [];
    response.media_plan.forEach((plan) => {
      if (!yAxisData.includes(plan[filterType])) {
        yAxisData.push(plan[filterType]);
      }
    });

    // Sort yAxisData alphabetically
    yAxisData.sort();

    return yAxisData;
  };

  const getColor = (value) => {
    if (value === 0) {
      return "rgba(255, 255, 255, 0)"; // Fully transparent for budget 0
    }

    const maxBudget = Math.max(
      ...response.media_plan.flatMap((plan) =>
        plan.weekly_plan.map((week) => week.budget)
      )
    );
    const minBudget = Math.min(
      ...response.media_plan.flatMap((plan) =>
        plan.weekly_plan.map((week) => week.budget)
      )
    );

    const colors = [
      "#ffffd9",
      "#edf8b1",
      "#c7e9b4",
      "#7fcdbb",
      "#41b6c4",
      "#1d91c0",
      "#225ea8",
    ];

    const colorIndex = Math.floor(
      ((value - minBudget) / (maxBudget - minBudget)) * (colors.length - 1)
    );

    return colors[colorIndex];
  };

  const getItemPaint = (budget, width) => {
    const color = getColor(budget);
    const text = width > 30 ? `$${budget}` : ""; // Only show text if there is enough space
    return { color, text };
  };

  const getLegendData = () => {
    const maxBudget = Math.max(
      ...response.media_plan.flatMap((plan) =>
        plan.weekly_plan.map((week) => week.budget)
      )
    );
    const minBudget = Math.min(
      ...response.media_plan.flatMap((plan) =>
        plan.weekly_plan.map((week) => week.budget)
      )
    );

    const colors = [
      "#ffffd9",
      "#edf8b1",
      "#c7e9b4",
      "#7fcdbb",
      "#41b6c4",
      "#1d91c0",
      "#225ea8",
    ];

    const legendData = colors.map((color, index) => {
      const rangeMin =
        minBudget + (index / colors.length) * (maxBudget - minBudget);
      const rangeMax =
        minBudget + ((index + 1) / colors.length) * (maxBudget - minBudget);
      return {
        name: `$${rangeMin.toFixed(2)} - $${rangeMax.toFixed(2)}`,
        icon: "rect",
        itemStyle: {
          color: color,
        },
      };
    });

    return legendData.map((item) => item.name);
  };

  const option = {
    title: {
      text: `Channel Gantt Chart`,
      left: "center",
      top: 20,
      textStyle: {
        color: "#fff",
      },
    },
    tooltip: {
      formatter: function (params) {
        return `${params.marker} ${params.name}<br/>Start: ${new Date(
          params.value[1]
        ).toLocaleDateString()}<br/>End: ${new Date(
          params.value[2]
        ).toLocaleDateString()}<br/>Budget: $${params.value[3]}`;
      },
    },
    legend: {
      data: getLegendData(),
      textStyle: {
        color: "#fff", // Set legend text color to white
      },
      itemWidth: 20,
      itemHeight: 14,
      formatter: function (name) {
        return name;
      },
    },
    xAxis: {
      type: "time",
      min: new Date(
        Math.min(
          ...response.media_plan.flatMap((plan) =>
            plan.weekly_plan.map((week) =>
              new Date(week.week_start_date).getTime()
            )
          )
        )
      ),
      max: new Date(
        Math.max(
          ...response.media_plan.flatMap((plan) =>
            plan.weekly_plan.map((week) =>
              new Date(week.week_end_date).getTime()
            )
          )
        )
      ),
      axisLabel: {
        color: "#fff", // Set x-axis label color to white
        hideOverlap: true,
        formatter: function (value) {
          return new Date(value).toLocaleDateString();
        },
      },
    },
    yAxis: {
      type: "category",
      data: getYAxisData(),
      axisLabel: {
        hideOverlap: true,
        color: "#fff", // Set y-axis label color to white
      },
    },
    series: [
      {
        type: "custom",
        renderItem: function (params, api) {
          const categoryIndex = api.value(0);
          const start = api.coord([api.value(1), categoryIndex]);
          const end = api.coord([api.value(2), categoryIndex]);
          const height = api.size([0, 1])[1] * 0.6;
          const budget = api.value(3);

          // Calculate the width of the rectangle
          const width = end[0] - start[0];

          // Get the color and text for the item
          const { color, text } = getItemPaint(budget, width);

          // Adjust text position to avoid overlap
          const textPosition = width > 30 ? "inside" : "top";

          return {
            type: "rect",
            shape: {
              x: start[0],
              y: start[1] - height / 2,
              width: width,
              height: height,
            },
            style: {
              fill: color,
              text: text,
              textFill: "#262626", // Set label color to white
              textPosition: textPosition,
            },
          };
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: getSeriesData(),
      },
    ],
  };

  return (
    <div>
      {/* <Select
        placeholder="Select Filter Type"
        style={{ width: 200, marginBottom: 20 }}
        value={filterType}
        onChange={(value) => {
          setFilterType(value);
        }}
      >
        {CHART_FILTER_OPTIONS.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select> */}
      <ReactECharts
        option={option}
        style={{ height: "600px", width: "100%" }}
        notMerge={true}
      />
    </div>
  );
};

export default GanttChart;
