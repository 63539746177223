import { css } from "@linaria/core";

const hoverComponent = css`
  z-index: 0;
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: -7px 0px 15px 0px rgb(0 0 0 / 10%);
  height: 30rem;
  width: 100%;
`;

const nodeContent = css`
  max-height: 24rem;
  overflow-y: auto;
`;

const node = css`
  display: flex;
  justify-content: space-between;
  .ant-btn.ant-btn-default,
  .ant-btn-background-ghost.ant-btn-primary {
    border: none;
    display: contents;
  }
`;

const nodeHeader = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const nodeActions = css`
  display: flex;
  justify-content: space-between;
  width: 42px;
  position: absolute;
  right: 10px;
  top: 23px;
  z-index: 2;
  .ant-btn.ant-btn-default,
  .ant-btn-background-ghost.ant-btn-primary {
    color: black !important;
    border: none;
    display: contents;
  }
`;


const tabs = css`
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
  height: 100%;
  // width: 60%;
`;


const hoverStreamingContainer = css`
  max-height: 100%;
`;


export default {
  hoverComponent,
  nodeContent,
  nodeHeader,
  nodeActions,
  tabs,
  hoverStreamingContainer,
};
