import { notification } from "antd";
import { duration } from "moment";
import { useState, useRef, useEffect } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { SqlToolApiServices } from "../sqlToolApiServices";

export default function useSqlStrategyHook({ selectedConnection, mAiApp, clientId, connectionString }) {
  const formRef = useRef();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [configureLoading, setConfigureLoading] = useState(false)

  const connectionObj = connectionString?.find((ele) => ele.connection_id === selectedConnection);

  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        capability_name: "StrategyCapability",
        filters: {
          level: "capability",
          capability: "SqlCapabilityV2",
          connection_name: connectionObj?.connection_name,
        },
      };
      const res = await SqlToolApiServices.getConnectionLevelStrategy(payload, mAiApp);
      setInitialValues({
        strategies: res.data.strategies,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight'
      })
    }
  };

  const saveStrategies = async (val) => {
    try {
      setConfigureLoading(true);
      const payload = {
        capability_name: "StrategyCapability",
        fields_config: {
          ...val,
          filters: {
            level: "capability",
            capability: "SqlCapabilityV2",
            connection_name: connectionObj?.connection_name,
          },
        },
      };
      const res = await SqlToolApiServices.postCapibility(mAiApp, payload);
      setConfigureLoading(false);
      notification.success({
        message: "Sql strategies added successfully",
        duration: 5,
        placement: 'bottomRight'
      })
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight'
      })
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    formRef,
    loading,
    initialValues,
    configureLoading,
    saveStrategies
  };
}
