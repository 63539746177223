import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, List } from 'antd';
import React, { useCallback, useState } from 'react';
import './browseBehaviourFeatures.style.less';

const { Panel } = Collapse;

const BrowseBehaviourFeaturesComponent = ({
    fieldDirectory,
    browsedSelectedItems,
    setBrowsedSelectedItems
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const handleCheckboxChange = useCallback(
        (checked, key) => {
            setBrowsedSelectedItems(prevSelected => {
                if (checked) {
                    return [...prevSelected, key];
                } else {
                    return prevSelected.filter(item => item !== key);
                }
            });
        },
        [setBrowsedSelectedItems]
    );

    const handleRemoveItem = useCallback(
        item => {
            setBrowsedSelectedItems(prevSelected =>
                prevSelected.filter(selectedItem => selectedItem !== item)
            );
        },
        [setBrowsedSelectedItems]
    );

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const filterTree = (node, query) => {
        return Object.keys(node).reduce((acc, key) => {
            const isLeaf = Object.keys(node[key]).length === 0;

            if (key.toLowerCase().includes(query.toLowerCase())) {
                acc[key] = node[key];
            } else if (!isLeaf) {
                const filteredChild = filterTree(node[key], query);
                if (Object.keys(filteredChild).length > 0) {
                    acc[key] = filteredChild;
                }
            }

            return acc;
        }, {});
    };

    const renderTree = (node, path = '') => {
        return Object.keys(node).map(key => {
            const currentPath = path ? `${path} > ${key}` : key;
            const isLeaf = Object.keys(node[key]).length === 0;

            return (
                <div key={currentPath} className="tree-node">
                    {isLeaf ? (
                        <Checkbox
                            checked={browsedSelectedItems.includes(key)}
                            onClick={e => e.stopPropagation()} // Prevent checkbox click from triggering Collapse
                            onChange={e => handleCheckboxChange(e.target.checked, key)}
                        >
                            {key}
                        </Checkbox>
                    ) : (
                        <Collapse >
                            <Panel
                                key={currentPath}
                                header={
                                    <>
                                        {key}
                                    </>
                                }
                            >
                                {renderTree(node[key], currentPath)}
                            </Panel>
                        </Collapse>
                    )}
                </div>
            );
        });
    };

    const filteredTree = filterTree(fieldDirectory, searchQuery);

    return (
        <>
            <Input
                className="dark-theme-input"
                placeholder="Search..."
                prefix={<SearchOutlined />}
                value={searchQuery}
                onChange={handleSearchChange}
                style={{ marginBottom: '16px' }}
            />
            <div className="drawer-content">
                <div className="tree-container">{renderTree(filteredTree)}</div>
                <div className="selected-items">
                    <h3>Selected Items</h3>
                    <List
                        dataSource={browsedSelectedItems}
                        renderItem={item => (
                            <List.Item
                                key={item}
                                actions={[
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => handleRemoveItem(item)}
                                    />
                                ]}
                            >
                                {item}
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export default BrowseBehaviourFeaturesComponent;