import React from "react";
import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import AppContext from "src/context/appContext";

import { Button as AntButton, Popover } from "antd";
import { isAllowedWithPermissions } from "src/lib/uiHelper";
import { useTranslation } from "react-i18next";

const ComfyButtonStyles = css`
  ${"" /* padding: 0.5rem 2.857rem; */}
  min-width: 9.7143rem;
`;

const StrongFontStyles = css`
  &.ant-btn {
    font-weight: 600;
  }
`;

const UnderlineStyle = css`
  span {
    text-decoration: underline;
  }
`;

const UppercaseStyle = css`
  span {
    text-transform: uppercase;
  }
`;

const ModernStyle = css`
  &.ant-btn {
    box-shadow: 0px 4px 8px rgba(41, 29, 74, 0.15);
    border-radius: 5px;
  }
`;

const ModernPrimaryStyle = css`
  &.ant-btn {
    background: #0055C3;
    border-color: #0055C3;
    &:hover,
    &:focus,
    &:active {
      background: #1b79f1;
      border-color: #1b79f1;
    }
  }
`;

const ModernTextStyle = css`
  &.ant-btn {
    color: #0055C3;
    &:hover,
    &:focus,
    &:active {
      color: #1b79f1;
    }
  }
`;

const ModernOutlineStyle = css`
  &.ant-btn {
    color: #0055C3;
    border-color: #0055C3;
    &:hover,
    &:focus,
    &:active {
      color: #1b79f1;
      border-color: #1b79f1;
    }
  }
`;

function _PrimaryButton({
  comfy,
  uppercase,
  strong,
  className,
  modern,
  underline,
  message,
  permissions = "",
  disabled,
  ...rest
}) {
  const isAllowed =
    permissions.length === 0 ||
    isAllowedWithPermissions(permissions, AppContext);
  const classNames = cx(
    className,
    uppercase && UppercaseStyle,
    comfy && ComfyButtonStyles,
    strong && StrongFontStyles,
    underline && UnderlineStyle,
    modern && ModernStyle,
    modern && ModernPrimaryStyle
  );
  const { t } = useTranslation();

  return isAllowed ? (
    <AntButton {...rest} className={classNames} type="primary" disabled={disabled}/>
  ) : (
    <Popover
      content={
        message || t('not_authorized')
      }
    >
      <span>
        <AntButton {...rest} className={classNames} type="primary" disabled />
      </span>
    </Popover>
  );
}

function _SecondaryButton({
  comfy,
  uppercase,
  strong,
  className,
  modern,
  underline,
  message,
  permissions = "",
  disabled,
  ...rest
}) {
  const isAllowed =
    permissions.length === 0 ||
    isAllowedWithPermissions(permissions, AppContext);
  const classNames = cx(
    className,
    uppercase && UppercaseStyle,
    comfy && ComfyButtonStyles,
    strong && StrongFontStyles,
    underline && UnderlineStyle,
    modern && ModernStyle,
    modern && ModernPrimaryStyle
  );
  const { t } = useTranslation();

  return isAllowed ? (
    <AntButton {...rest} className={classNames} disabled={disabled}/>
  ) : (
    <Popover
      content={
        message || t('not_authorized')
      }
    >
      <span>
        <AntButton {...rest} className={classNames} disabled />
      </span>
    </Popover>
  );
}

function _OutlinedButton({
  comfy,
  uppercase,
  strong,
  className,
  modern,
  underline,
  ...props
}) {
  return (
    <AntButton
      {...props}
      className={cx(
        className,
        uppercase && UppercaseStyle,
        comfy && ComfyButtonStyles,
        strong && StrongFontStyles,
        underline && UnderlineStyle,
        modern && ModernStyle,
        modern && ModernOutlineStyle
      )}
    />
  );
}

function _TextButton({
  comfy,
  uppercase,
  strong,
  className,
  underline,
  modern,
  ...props
}) {
  return (
    <AntButton
      {...props}
      className={cx(
        className,
        uppercase && UppercaseStyle,
        comfy && ComfyButtonStyles,
        strong && StrongFontStyles,
        underline && UnderlineStyle,
        modern && ModernStyle,
        modern && ModernTextStyle
      )}
      type="text"
    />
  );
}

function _LinkButton({
  comfy,
  uppercase,
  strong,
  underline,
  className,
  modern,
  ...props
}) {
  return (
    <AntButton
      {...props}
      className={cx(
        className,
        uppercase && UppercaseStyle,
        comfy && ComfyButtonStyles,
        strong && StrongFontStyles,
        underline && UnderlineStyle,
        modern && ModernTextStyle
      )}
      type="link"
    />
  );
}

function _ShadowButton({
  comfy,
  uppercase,
  strong,
  children,
  className,
  ...props
}) {
  return (
    <AntButton
      {...props}
      className={`${className} ${comfy ? ComfyButtonStyles : ""} ${uppercase ? UppercaseStyle : ""
        } ${strong ? StrongFontStyles : ""}`}
      type="text"
    >
      {children}
    </AntButton>
  );
}

function _IconButton({ icon, width = 15, height, ...props }) {
  return (
    <AntButton {...props} type="text">
      {icon && (
        <img alt="icon" src={icon} style={{ width, height: height || width }} />
      )}
    </AntButton>
  );
}

/** @type {any} re-export of ant button. */
export const Button = AntButton;

/** @type {any} text as button. No border, no background, Text uses primary color(blue) */
export const TextButton = styled(_TextButton)`
  &.ant-btn {
    background-color: transparent;
    padding: 0;

    &:active,
    &:hover,
    &:focus,
    &:active,
    &:focus {
      background: none;
    }

    &[disabled] {
      border: 0;
    }
  }
`;

/** @type {any} Primary button which has primary color(blue) as background and white text */
export const PrimaryButton = styled(_PrimaryButton)``;
export const SecondaryButton = styled(_SecondaryButton)``
export const LinkButton = styled(_LinkButton)`
  &.ant-btn {
    padding: 0;
    height: auto;
  }
`;

/** @type {any} button which has primary color(blue) as background and white text with rounded corners */
export const RoundedPrimaryButton = styled(_PrimaryButton)`
  &.ant-btn-primary {
    border-radius: 5px;
    background: #0055c3;
  }
`;

/** @type {any} button which has primary light color(blue) as background and white text with rounded corners */
export const RoundedSecondaryButton = styled(_PrimaryButton)`
  &.ant-btn-primary {
    border-radius: 15px;
    background-color: #fafafc;
    border-color: #fafafc;
    color: var(--color-almost-black);

    &:active,
    &:focus,
    &:hover {
      background-color: #70a7c8;
      border-color: #70a7c8;
      color: var(--color-almost-black);
    }
  }
`;

/** @type {any} button with Icon which has primary color(blue) as background and white text with rounded corners */
export const RoundedPrimaryButtonWithIcon = styled(_PrimaryButton)`
  &.ant-btn-primary {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

/** @type {any} button which has primary color(blue) as border and primary color(blue) text */
export const OutlinedButton = styled(_OutlinedButton)`
  &.ant-btn {
    border-color: var(--color-outline-button-color);
    color: var(--color-outlined-button);
    background-color: var(--color-card-background);
    &:active,
    &:focus,
    &:hover {
    }
  }
`;

/** @type {any} button which has primary color(blue) as border and primary color(blue) text */
export const RoundedOutlinedButton = styled(_OutlinedButton)`
  &.ant-btn {
    border-radius: 5px;
  }
`;

export const RoundedOutlinedButtonWithIcon  = styled(_OutlinedButton)`
&.ant-btn {
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
`;

export function OutlinedButtonPrimary(props) {
  return <OutlinedButton {...props} type="primary" ghost />;
}
export function RoundedOutlinedButtonPrimary(props) {
  return <RoundedOutlinedButton {...props} type="primary" ghost />;
}

/** @type {any} button with icon and has box shadow */
export const ShadowButton = styled(_ShadowButton)`
  &.ant-btn {
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
    padding: 1.2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-card-background);

    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 20%);
    }
  }
`;

/** @type {any} only an icon as button. No borders, no text */
export const IconButton = styled(_IconButton)`
  &.ant-btn {
    ${"" /* background-color: var(--color-card-background); */}
    background-color: transparent;
    padding: 0.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MarkeyButton = props => {
  const { variant = "primary", rounded = false, withIcon = false } = props;
  switch (variant) {
    case "primary": {
      if (rounded) {
        if (withIcon) {
          return <RoundedPrimaryButtonWithIcon {...props} />;
        }
        return <RoundedPrimaryButton {...props} />;
      }
      return <PrimaryButton {...props} />;
    }

    case "secondary": {
      return <SecondaryButton {...props} />;
    }

    case "outlined": {
      if (rounded) {
        if (withIcon) {
          return <RoundedOutlinedButtonWithIcon {...props} />;
        }
        return <RoundedOutlinedButton {...props} />;
      }
      return <OutlinedButton {...props} />;
    }

    case "link": {
      return <LinkButton {...props} />;
    }

    case "shadow": {
      return <ShadowButton {...props} />;
    }

    case "iconOnly": {
      return <IconButton {...props} />;
    }

    default: {
      if (rounded) {
        return <RoundedPrimaryButton {...props} />;
      }
      return <PrimaryButton {...props} />
    }
  }
};

const popOverStyle = {
  backgroundColor: 'var(--color-white)',
  borderRadius: '0.7rem',
  marginTop: '0.7rem',
  boxShadow: 'var(--box-shadow-popover)',
}

export function MarkeyButtonWithPermission({
  permissions,
  message,
  ...buttonProps
}) {

  const appContext = React.useContext(AppContext.Context);
  let isEmailVerified = appContext?.userData?.is_email_verified;
  const isAllowed = !isEmailVerified ? isEmailVerified : isAllowedWithPermissions(permissions, AppContext);
  const { t } = useTranslation();

  if (isAllowed) {
    return (
      <MarkeyButton
        {...buttonProps}
      />
    );
  }

  return (
    <Popover
      placement="bottomRight" overlayInnerStyle={popOverStyle}
      content={message || t('not_authorized')}
    >
      <span>
        <MarkeyButton
          {...buttonProps}
          disabled
        />
      </span>
    </Popover>
  );
}

