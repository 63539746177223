import { useState, useEffect, useRef, useContext } from "react";
import ClientSpace from "src/modules/clientSpace/components/clientSpace/ClientSpace";
import CreateClientApiServices from "../services/createClientSpaceApiServices";
import { set } from "dot-object";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { Modal, notification } from "antd";
import AppContext from "src/context/appContext";
const useClientSpaceAndPermissionsHook = ({ metaData, clientId }) => {
  const [addUserModal, setAddUserModal] = useState(false);
  const [laoding, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const formRef = useRef(null);
  const appContext = useContext(AppContext.Context);
  const userEmail = appContext?.metaData?.email;
  const [canMakeOwner, setCanMakeOwner] = useState(false);

  let ownerRoleId = metaData?.client_roles?.find((item) => item?.name === "CLIENT_OWNER")?.id;

  let userMapping = {};
  [...metaData?.internal_users, ...metaData?.external_users]?.forEach((item) => {
    userMapping[item?.email] = item;
  });

  const [selectedValues, setSelectedValues] = useState({
    users: [],
    assign_to_all_users: false,
  });

  const getClientUser = async (notLoad) => {
    try {
      if (!notLoad) {
        setLoading(true);
      }
      const res = await CreateClientApiServices.getClientAccessDetails(clientId);
      let clientDetails = res.data.access.find((item) => item.email === userEmail);
      if (clientDetails?.role_name === "CLIENT_OWNER") {
        setCanMakeOwner(true);
      }
      let assignedToAll = res.data.assign_to_all_users;
      setSelectedValues({
        users: res.data.access,
        assign_to_all_users: assignedToAll,
      });
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: "bottomRight",
        message: msg,
      });
    }
  };

  const updateUserAccess = async (type, value) => {
    try {
      setConfigureLoading(true);
      let payload = {
        client_space_id: clientId,
      };
      if (type === "assignAll") {
        payload = {
          ...payload,
          assign_to_all_users: value,
        };
        setSelectedValues({
          ...selectedValues,
          assign_to_all_users: value,
        });
      }
      if (type === "addNewUser") {
        payload = {
          ...payload,
          assign_to_all_users: selectedValues.assign_to_all_users,
          access: [value],
        };
      }
      if (type === "changeRole") {
        payload = {
          ...payload,
          assign_to_all_users: selectedValues.assign_to_all_users,
          access: [value],
        }
      }
      await CreateClientApiServices.updateClientUserAccess(payload);
      if (type === "addNewUser" || type === "changeRole")  {
        await getClientUser(true);
      }
      notification.success({
        duration: 5,
        placement: "bottomRight",
        message: "Client access updated successfully",
      });
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: "bottomRight",
        message: msg,
      });
    }
  };

  const deleteUser = async (id) => {
    Modal.warn({
      title: "Are you sure you want to remove this user?",
      okText: "Okay",
      cancelText: "Cancel",
      okCancel: true,
      onOk: async () => {
        try {
          setConfigureLoading(true);
          const res = await CreateClientApiServices.deleteClientUserAccess(id);
          await getClientUser(true);
          setConfigureLoading(false);
        } catch (error) {
          setConfigureLoading(false);
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || "Failed to remove the user",
            duration: 6,
            placement: "bottomRight",
          });
        }
      },
    });
  };

  useEffect(() => {
    if (clientId) {
      getClientUser(false);
    } else {
      setLoading(false);
    }
  }, []);

  return {
    laoding,
    formRef,
    selectedValues,
    setSelectedValues,
    userMapping,
    updateUserAccess,
    configureLoading,
    addUserModal,
    setAddUserModal,
    ownerRoleId,
    userEmail,
    canMakeOwner,
    deleteUser,
  };
};

export default useClientSpaceAndPermissionsHook;
