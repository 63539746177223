import { Table } from "antd";
import React from "react";
import { OUTPUT_TABLE_COLUMNS, transformData } from "../../constants/constants";
import "./OutputTable.less";

const OutputTable = ({ loading, data }) => {
  const paginationConfig = {
    position: ["bottomCenter"],
    pageSize: 10,
    showSizeChanger: false,
    showQuickJumper: false,
  };

  const transformedData = transformData(data);

  return (
    <div className="data-table-parent">
      {transformedData && transformedData.length > 0 && (
        <Table
          columns={OUTPUT_TABLE_COLUMNS}
          dataSource={transformedData}
          pagination={paginationConfig}
          scroll={{ x: "max-content" }}
        />
      )}
    </div>
  );
};

export default OutputTable;
