import { css } from '@linaria/core';

const container = css`
  height : calc(100vh - 215px);
  display : flex;
  flex-direction : column;
`;

const body = css`
  flex-grow : 1;
`;

export default {
  container,
  body
}