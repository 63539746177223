import { css } from '@linaria/core';

const columnContainer = css`
  display: flex;
  gap: 1rem;
`;

const featureContainer = css`
  height: calc(100vh - 427px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
`;

const breadCrumb = css`
  margin-bottom:16px !important;
`;

const categoryContainer = css`
 .ant-checkbox-group {
  width : 100%;
 }
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export default {
  columnContainer,
  featureContainer,
  breadCrumb,
  categoryContainer
}