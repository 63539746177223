import React from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, Typography } from 'antd';
import {
    audienceDetailsContainer,
    audienceDescriptionStyle,
    audienceSection,
    audienceSectionHeader,
    audienceSectionList,
    audienceSectionListItem,
    clusterStyle,
    clusterHeader,
    clusterDescription
} from './audienceDetails.style';
import Assets from 'src/assets';

const { Title, Text } = Typography;

const AudiencePreviewComponent = ({ open, onClose, audienceData, audienceName, audienceDescription, handleCreatePersona, personaLoading }) => {
    return (
        <Drawer
            title={<Title level={2} style={{ color: "#fff" }}>{audienceName}</Title>}
            visible={open}
            onClose={onClose}
            width="80%"
            bodyStyle={audienceDetailsContainer}
            footer={
                personaLoading ? (
                    <span key="loading" style={{ alignItems: "center" }}>
                        Creating Persona...
                        <img src={Assets.getImage("AiLoader")} alt="AI" style={{ width: '20px', height: '20px', marginLeft: '10px' }} />
                    </span>
                ) : (
                    <Button type="primary" onClick={handleCreatePersona} style={{ marginLeft: '10px' }}>
                        Chat with Persona
                    </Button>
                )
            }
            footerStyle={{ textAlign: 'center' }}
        >
            <Text style={audienceDescriptionStyle}>{audienceDescription}</Text>

            {audienceData.selectedCategories && audienceData.selectedCategories.length > 0 && (
                <section style={{ ...audienceSection, backgroundColor: '#444', padding: '10px', borderRadius: '8px', marginTop: '20px' }}>
                    <Title level={3} style={audienceSectionHeader}>Selected Categories</Title>
                    <ul style={audienceSectionList}>
                        {audienceData.selectedCategories.map(category => (
                            <li key={category.categoryID} style={audienceSectionListItem}>
                                <Text>{category.categoryName}</Text>
                            </li>
                        ))}
                    </ul>
                </section>
            )}

            {audienceData.rules && audienceData.rules.rules && audienceData.rules.rules.length > 0 && (
                <section style={{ ...audienceSection, backgroundColor: '#444', padding: '10px', borderRadius: '8px', marginTop: '20px' }}>
                    <Title level={3} style={audienceSectionHeader}>Audience Rules</Title>
                    <ul style={audienceSectionList}>
                        {audienceData.rules.rules.map((rule, index) => (
                            <li key={index} style={audienceSectionListItem}>
                                {rule.combinator ? (
                                    <ul style={audienceSectionList}>
                                        {rule.rules.map((subRule, subIndex) => (
                                            <li key={subIndex} style={audienceSectionListItem}>
                                                <Text>
                                                    {subRule.field_readable_name} {subRule.operator}{" "}
                                                    {Array.isArray(subRule.value)
                                                        ? subRule.value.map((item, i) => (
                                                            <span key={i}>
                                                                {item.value_description}
                                                                {i < subRule.value.length - 1 ? ", " : ""}
                                                            </span>
                                                        ))
                                                        : typeof subRule.value === 'object'
                                                            ? subRule.value.value_description
                                                            : subRule.value}{" "}
                                                    ({subRule.reasoning})
                                                </Text>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Text>
                                        {rule.field_readable_name} {rule.operator}{" "}
                                        {Array.isArray(rule.value)
                                            ? rule.value.map((item, i) => (
                                                <span key={i}>
                                                    {item.value_description}
                                                    {i < rule.value.length - 1 ? ", " : ""}
                                                </span>
                                            ))
                                            : typeof rule.value === 'object'
                                                ? rule.value.value_description
                                                : rule.value}{" "}
                                        ({rule.reasoning})
                                    </Text>
                                )}
                            </li>
                        ))}
                    </ul>
                </section>
            )}

            {audienceData.selectedClusters && audienceData.selectedClusters.length > 0 && (
                <section style={{ ...audienceSection, backgroundColor: '#444', padding: '10px', borderRadius: '8px', marginTop: '20px' }}>
                    <Title level={3} style={audienceSectionHeader}>Selected Clusters</Title>
                    {audienceData.selectedClusters.map((cluster, index) => (
                        <div key={index} style={clusterStyle}>
                            <Title level={4} style={clusterHeader}>{cluster.cluster_name}</Title>
                            <Text style={clusterDescription}>{cluster.cluster_description}</Text>
                        </div>
                    ))}
                </section>
            )}
        </Drawer>
    );
};

AudiencePreviewComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    audienceData: PropTypes.object.isRequired,
    audienceName: PropTypes.string.isRequired,
    audienceDescription: PropTypes.string.isRequired,
    handleCreatePersona: PropTypes.func.isRequired,
    personaLoading: PropTypes.bool.isRequired,
};

export default AudiencePreviewComponent;