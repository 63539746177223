import { notification } from "antd";
import { set } from "dot-object";
import { useState, useEffect } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { ClusterToolApiServices } from "src/modules/audienceTool/clusterTool/services/ClusterToolApiServices";

const useDownloadReports = ({ clusterData }) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState('')
  const downloadFiles = async (reportType) => {
    try {
      setLoading(true);
      setProgress(reportType)
      const payload = {
        clusters_config_id: clusterData?.id,
        report_type: reportType,
      };
      const res = await ClusterToolApiServices.dowloadReports(payload);
      const anchor = document.createElement("a");
      anchor.href = res.data; 
      anchor.style.display = "none"; 
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setLoading(false);
      setProgress('')
    } catch (error) {
      setLoading(false);
      setProgress('')
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  return {
    downloadFiles,
    loading,
    progress
  };
};

export default useDownloadReports;
