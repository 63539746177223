import { css } from '@linaria/core';

const contentContainer = css`
  position: relative;
  @media screen and (max-width: 768px) {
    padding-bottom: 5px;
  }
`;

const hamburgerContainer = css`
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  background-color: #ededed;
  z-index: 1000;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
  borderBottom: 0.5px solid rgba(0,0,0,.15);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const contentContainerWithoutNavBar = css`
  padding-top: 14px;
  margin-left: 0px;
`;

const footer = css`
  color: white;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: black;
  z-index: 1000;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

const content = css`
  height:fit-content;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  min-height:calc(100vh - 144px);
`;

export default {
  contentContainer,
  hamburgerContainer,
  contentContainerWithoutNavBar,
  footer,
  content
};
