import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 215px);
`;

export default {
  container
}