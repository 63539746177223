import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import NcApiServices from "../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { notification } from "antd";
import appUrls from "src/constants/appUrls";
import { get } from "lodash";
import { set } from "dot-object";
import moment from "moment";
import { debounce } from "lodash";
import { defaultPlatformsSelected } from "../constants";
import AppContext from "src/context/appContext";

const useNewsAndCulture = ({ analysisOverview }) => {
  const [loading, setLoading] = useState(true);
  const [viewAnalysis, setViewAnalysis] = useState(null);
  const history = useHistory();
  const { clientId, analysisId } = useParams();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [nodeClick, setNodeClick] = useState(false);
  const [linkClick, setLinkClick] = useState(false);
  const [knowledgeGraph, setKnowledgeGraph] = useState(null);
  const [knowledgeGraphStats, setKnowledgeGraphStats] = useState(null);
  const [knowledgeGraphCommunities, setKnowledgeGraphCommunities] =
    useState(null);
  const [knowledgeGraphSavedFilters, setKnowledgeGraphSavedFilters] = useState([])
  const [knowledgeGraphAPIFilter, setKnowledgeGraphAPIFilter] = useState(null);
  const [maxJobTriggeredAt, setMaxJobTriggeredAt] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [knowledgeGraphFilters, setKnowledgeGraphFilters] = useState({
    nodes: [],
    date_range: null,
    analysisQueries: undefined,
    analysisType: defaultPlatformsSelected,
    importance_score: 0,
    opportunity_score: 0,
    degreeOfSeparation: 3,
    topCommunitiesPercentage: 10,
    post_types: ['POST'],
  });
  const [nodes, setNodes] = useState([]);
  const [handles, setHandles] = useState([]);
  const [knowledgeGraphChanged, setKnowledgeGraphChanged] = useState(false);
  const [knowledgeGraphLoading, setKnowledgeGraphLoading] = useState(false);
  const [knowledgeGraphStatsLoading, setKnowledgeGraphStatsLoading] =
    useState(false);
  const timeRef = useRef(null);
  const containerRef = useRef(null);
  const [summaryDrawer, setSummaryDrawer] = useState(false);
  const [dates, setDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [appliedDates, setAppliedDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [datesForDatePicker, setDatesForDatePicker] = useState({
    start_date: null,
    end_date: null,
  });
  const [colorBy, setColorBy] = useState("platform");
  const [selectedDate, setSelectedDate] = useState(null);
  const [initialLinks, setInitialLinks] = useState(null);
  const [showSaveFilterModal, setShowSaveFilterModal] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pageHandle, setPageHandle] = useState(1);
  const [hasMoreHandles, setHasMoreHandles] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchTextHandle, setSearchTextHandle] = useState("");
  const [showMyFilters, setShowMyFilters] = useState(true);
  const [loadingNodesOptions, setLoadingNodesOptions] = useState(false);
  const [loadingHandlesOptions, setLoadingHandlesOptions] = useState(false);
  const [useAISearch, setUseAISearch] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [fetchNodesState, setFetchNodesState] = useState(false);
  const [analysisListData, setAnalysisListData] = useState([]);
  const [totalData, setTotalData] = useState({
    total: null,
    total_pages: null,
  });
  const [handlesTotalData, setHandlesTotalData] = useState({
    total: null,
    total_pages: null,
  });
  const formRef = useRef(null);
  const appContext = useContext(AppContext.Context);
  const userData = appContext?.metaData;

  const debouncedFetchNodesOptions = useCallback(
    debounce(() => fetchNodesOptions(knowledgeGraphFilters), 500),
    [knowledgeGraphFilters, dates],
  );

  useEffect(() => {
    debouncedFetchNodesOptions();
  }, [fetchNodesState, dates]);

  useEffect(() => {
    fetchNodesOptions(knowledgeGraphFilters);
  }, [page, searchText]);

  const debouncedFetchHandlesOptions = useCallback(
    debounce(() => fetchHandlesOptions(knowledgeGraphFilters), 300),
    [knowledgeGraphFilters, dates],
  );

  useEffect(() => {
    debouncedFetchHandlesOptions();
  }, [fetchNodesState, dates]);

  useEffect(() => {
    fetchHandlesOptions(knowledgeGraphFilters);
  }, [pageHandle, searchTextHandle]);

  const handleScrollToEnd = useCallback(() => {
    if (hasMore && !loading && page < totalData.total_pages) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, hasMore, page, totalData]);

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      handleScrollToEnd();
    }
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchText(value);
    }, 800),
    []
  );
  const handleDropdownVisibleChange = () => {
    setSearchText("");
    setPage(1);
  };

  const handleScrollToEndHandle = useCallback(() => {
    if (hasMoreHandles && !loadingHandlesOptions && pageHandle < handlesTotalData?.total_pages) {
      setPageHandle((prevPage) => prevPage + 1);
    }
  }, [loadingHandlesOptions, hasMoreHandles, pageHandle, handlesTotalData]);

  const handlePopupScrollHandle = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10) {
      handleScrollToEndHandle();
    }
  };

  const handleSearchHandle = useCallback(
    debounce((value) => {
      setPageHandle(1);
      setSearchTextHandle(value);
    }, 800),
    []
  );

  const handleDropdownVisibleChangeHandle = () => {
    setPageHandle(1);
    setSearchTextHandle("");
  };

  const handleSliderChange = (newValue) => {
    const filteredLinks = initialLinks?.filter((link) => {
      const linkDate = moment(link.timestamp);
      return linkDate.isSameOrBefore(moment(newValue).endOf("day"));
    });

    setKnowledgeGraph({ ...knowledgeGraph, links: filteredLinks });
    setSelectedDate(newValue);
  };

  const getAnalysis = async () => {
    if (analysisId) {
      await getKnowledgeGraph(analysisId, knowledgeGraphFilters, true, true);
      await getKnowledgeGraphStats(analysisId);
      await getAnalysisList();
      await getKnowledgeGraphSavedFilters(analysisId);
      // await getAnalysisViewStats(analysisId);
    }
  };

  const getAnalysisList = async () => {
    try {
      const res = await NcApiServices.getAnalysisList(null, 1, '', '', [], 1000);
      const analysisListData = res?.data?.data || [];
      setAnalysisListData(analysisListData.filter((item) => item?.id !== analysisId));
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      console.error(msg);
    }
  }

  const getAnalysisViewStats = async (analysisId, params = {}) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getAnalysisStats(analysisId, params);
      if (res?.success) {
        setViewAnalysis(res?.data);
      } else {
        setViewAnalysis({});
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const fetchNodesOptions = useCallback(async (updatedKnowledgeGraphFilters) => {
    try {
      setLoadingNodesOptions(true);
      const res = await NcApiServices.getNodesOptions({
        analysis_id: analysisId,
        page_size: 10,
        page: page,
        search_text: searchText,
        start_date: updatedKnowledgeGraphFilters?.date_range?.[0]?.toISOString() || dates?.start_date,
        end_date: updatedKnowledgeGraphFilters?.date_range?.[1]?.toISOString() || dates?.end_date,
        author_overlap_analysis_ids: updatedKnowledgeGraphFilters?.author_overlap_analysis_ids
          ? updatedKnowledgeGraphFilters?.author_overlap_analysis_ids.join(",")
          : null,
        column_name: "nodes",
      });
      const newOptions = res.data.data;
      setTotalData({
        total: res.data.total,
        total_pages: res.data.total_pages,
      });
      if (searchText || page === 1) {
        setNodes(newOptions);
      } else {
        setNodes((prevOptions) => [...prevOptions, ...newOptions.filter((option) => !prevOptions.includes(option))]);
      }
      setLoadingNodesOptions(false);
      setHasMore(newOptions.length > 0);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      if (msg?.request?.status === 404 && pageHandle !== 1) {
        setPage(1);
        setLoadingNodesOptions(false);
        return;
      }
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoadingNodesOptions(false);
    } finally {
      setLoadingNodesOptions(false);
    }
  }, [analysisId, page, searchText, dates]);

  const fetchHandlesOptions = useCallback(
    async (updatedKnowledgeGraphFilters) => {
      try {
        setLoadingHandlesOptions(true);
        const res = await NcApiServices.getNodesOptions({
          analysis_id: analysisId,
          page_size: 10,
          page: pageHandle,
          search_text: searchTextHandle,
          start_date: updatedKnowledgeGraphFilters?.date_range?.[0]?.toISOString() || dates?.start_date,
          end_date: updatedKnowledgeGraphFilters?.date_range?.[1]?.toISOString() || dates?.end_date,
          author_overlap_analysis_ids: updatedKnowledgeGraphFilters?.author_overlap_analysis_ids
            ? updatedKnowledgeGraphFilters?.author_overlap_analysis_ids.join(",")
            : null,
          column_name: "handles",
        });
        const newOptions = res.data.data;
        setHandlesTotalData({
          total: res.data.total,
          total_pages: res.data.total_pages,
        });
        if (searchTextHandle || pageHandle === 1) {
          setHandles(newOptions);
        } else {
          setHandles((prevOptions) => [
            ...prevOptions,
            ...newOptions.filter((handle) => !prevOptions.includes(handle)),
          ]);
        }
        setLoadingHandlesOptions(false);
        setHasMoreHandles(newOptions.length > 0);
      } catch (error) {
        const { msg } = getErrorMessageFromResponse(error);
        if (msg?.request?.status === 404 && pageHandle !== 1) {
          setPageHandle(1);
          setLoadingHandlesOptions(false);
          return;
        }
        console.log(msg)
        notification.error({
          duration: 5,
          message: msg,
          placement: "bottomRight",
        });
        setLoadingHandlesOptions(false);
      } finally {
        setLoadingHandlesOptions(false);
      }
    },
    [analysisId, pageHandle, searchTextHandle, dates],
  );

  const getKnowledgeGraph = async (
    analysisId,
    updatedFilters = {},
    saveNodes = false,
    saveDates = false
  ) => {
    try {
      setKnowledgeGraphLoading(true);
      const payload = constructKnowledgeGraphFilters(analysisId, updatedFilters);
      setKnowledgeGraphAPIFilter(payload);
      const res = await NcApiServices.getKnowledgeGraph(payload);
      setKnowledgeGraphChanged(!knowledgeGraphChanged);
      setInitialLinks(res?.data?.knowledge_graph_config?.links);
      setKnowledgeGraph(res?.data?.knowledge_graph_config);
      setKnowledgeGraphCommunities(res?.data?.communities);
      if (updatedFilters?.return_community_graph) {
        setColorBy("community");
      }
      if (saveDates) {
        const sectionData = get(analysisOverview, "section_data", []);
        let startDate = res?.data?.start_date;
        sectionData.forEach((section) => {
          let date1 = moment(section?.start_date);
          let date2 = moment(startDate);
          if (date1.isBefore(date2)) {
            startDate = section?.start_date;
          }
        });
        setDates({
          start_date: res?.data?.start_date,
          end_date: res?.data?.end_date,
        });
        setAppliedDates({
          start_date: res?.data?.start_date,
          end_date: res?.data?.end_date,
        });
        setDatesForDatePicker({
          start_date: startDate,
          end_date: res?.data?.end_date,
        });
        setSelectedDate(moment(res?.data?.end_date)?.valueOf());
        setMaxJobTriggeredAt(res?.data?.max_job_triggered_at);
      }
      setKnowledgeGraphLoading(false);
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setKnowledgeGraphLoading(false);
      setLoading(false);
    }
  };

  const constructKnowledgeGraphFilters = (analysisId, updatedFilters) => {
    return {
      analysis_id: analysisId,
      start_date: updatedFilters?.date_range ? updatedFilters?.date_range[0].toISOString() : null,
      end_date: updatedFilters?.date_range ? updatedFilters?.date_range[1].toISOString() : null,
      nodes: updatedFilters?.nodes && updatedFilters?.return_community_graph !== true ? updatedFilters?.nodes : null,
      exclude_nodes:
        updatedFilters?.exclude_nodes && updatedFilters?.return_community_graph !== true
          ? updatedFilters?.exclude_nodes
          : null,
      communities: updatedFilters?.communities ? updatedFilters?.communities : null,
      handles: updatedFilters?.handles ? updatedFilters?.handles : null,
      exclude_handles: updatedFilters?.exclude_handles ? updatedFilters?.exclude_handles : null,
      analysis_type: updatedFilters?.analysisType ? updatedFilters?.analysisType : null,
      analysis_section_config_ids: updatedFilters?.analysisQueries ? updatedFilters?.analysisQueries : null,
      importance_score: updatedFilters?.importance_score ? updatedFilters?.importance_score : null,
      opportunity_score: updatedFilters?.opportunity_score ? updatedFilters?.opportunity_score : null,
      degree_of_separation: updatedFilters?.degreeOfSeparation ? updatedFilters?.degreeOfSeparation : null,
      return_community_graph: updatedFilters?.return_community_graph ? updatedFilters?.return_community_graph : null,
      top_communities_percentage: updatedFilters?.topCommunitiesPercentage
        ? updatedFilters?.topCommunitiesPercentage
        : null,
      max_job_triggered_at: updatedFilters?.max_job_triggered_at || null,
      custom_search_query: updatedFilters?.custom_search_query || null,
      post_types: updatedFilters?.post_types || null,
      author_overlap_analysis_ids: updatedFilters?.author_overlap_analysis_ids || null,
      include_author_overlap_analysis_data: updatedFilters?.include_author_overlap_analysis_data || null,
      likes_counts:
        ![undefined, null].includes(updatedFilters?.minLikes) || ![undefined, null].includes(updatedFilters?.maxLikes)
          ? {
              min: updatedFilters?.minLikes,
              max: updatedFilters?.maxLikes,
            }
          : null,
      comments_counts:
        ![undefined, null].includes(updatedFilters?.minComments) ||
        ![undefined, null].includes(updatedFilters?.maxComments)
          ? {
              min: updatedFilters?.minComments,
              max: updatedFilters?.maxComments,
            }
          : null,
      views_counts:
        ![undefined, null].includes(updatedFilters?.minViews) || ![undefined, null].includes(updatedFilters?.maxViews)
          ? {
              min: updatedFilters?.minViews,
              max: updatedFilters?.maxViews,
            }
          : null,
      author_followers_counts:
        ![undefined, null].includes(updatedFilters?.minFollowers) ||
        ![undefined, null].includes(updatedFilters?.maxFollowers)
          ? {
              min: updatedFilters?.minFollowers,
              max: updatedFilters?.maxFollowers,
            }
          : null,
    };
  };

  const getKnowledgeGraphStats = async (analysisId) => {
    try {
      setKnowledgeGraphStatsLoading(true);
      const payload = {
        analysis_id: analysisId,
      };
      const res = await NcApiServices.getKnowledgeGraphStats(payload);
      setKnowledgeGraphStats(res?.data);
      setKnowledgeGraphStatsLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setKnowledgeGraphStatsLoading(false);
    }
  };

  const getKnowledgeGraphSavedFilters = async (analysisId) => {
    try {
      const payload = {
        analysis_id: analysisId,
      };
      const res = await NcApiServices.getKnowledgeGraphSavedFilters(payload);
      setKnowledgeGraphSavedFilters(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg || "Something went wrong",
        placement: "bottomRight",
      });
    }
  };

  const saveKnowledgeGraphSavedFilters = useCallback(
    async (analysisId, filter_name, filter_description) => {
      try {
        const payload = {
          analysis_id: analysisId,
          filter_name: filter_name,
          filter_description: filter_description || null,
          filters: knowledgeGraphAPIFilter,
          max_job_triggered_at: maxJobTriggeredAt,
        };
        const res = await NcApiServices.saveKnowledgeGraphSavedFilters(payload);
        const updatedKnowledgeGraphSavedFilters = [res?.data, ...knowledgeGraphSavedFilters];
        setKnowledgeGraphSavedFilters(updatedKnowledgeGraphSavedFilters);
        setShowSaveFilterModal(false);
        notification.success({
          duration: 5,
          message: "Filters saved successfully",
          placement: "bottomRight",
        });
      } catch (error) {
        const { msg } = getErrorMessageFromResponse(error);
        notification.error({
          duration: 5,
          message: msg,
          placement: "bottomRight",
        });
      }
    },
    [maxJobTriggeredAt, knowledgeGraphSavedFilters, knowledgeGraphAPIFilter],
  );

  const onChangeKnowledgeGraphFilters = (type, value) => {

    switch (type) {
      case "date_range":
        value = [
          moment(
            moment(value[0]).startOf("day")
          ),
          moment(
            moment(value[1]).endOf("day")
          ),
        ];
        break;
      default:
        break;
    }

    const updatedFilters = {
      ...knowledgeGraphFilters,
      [type]: value,
    };

    if (type === "date_range") {
      setDates({
        start_date: value[0],
        end_date: value[1],
      });
    }

    setKnowledgeGraphFilters(updatedFilters);
    if (type === "date_range" || type === 'author_overlap_analysis_ids') {
      setFetchNodesState((prevState) => !prevState);
    }
  };

  const applyFiltersKnowledgeGraph = () => {
    setShowMoreOptions(false);
    getKnowledgeGraph(analysisId, knowledgeGraphFilters, false, true);
  };

  const applySavedFilters = (selectedFilterData) => {
    if (selectedFilterData){
      
      const uiFilters = getKnowledgeGraphFiltersFromSavedFilters(selectedFilterData?.filters);
      uiFilters.max_job_triggered_at = selectedFilterData?.max_job_triggered_at;
      setSelectedFilter(selectedFilterData)
      setKnowledgeGraphFilters(uiFilters);
      getKnowledgeGraph(analysisId, uiFilters, false, true);
    } else  {
      clearSavedFilters();
    }
  };

  const clearSavedFilters = useCallback(() => {
    const uiFilters = getKnowledgeGraphFiltersFromSavedFilters(knowledgeGraphAPIFilter);
    uiFilters.max_job_triggered_at = null;
    setSelectedFilter(null)
    setKnowledgeGraphFilters(uiFilters);
    getKnowledgeGraph(analysisId, uiFilters, false, true);
  }, [knowledgeGraphAPIFilter]);

  const getKnowledgeGraphFiltersFromSavedFilters = (savedFilters) => {
    // reverse of constructKnowledgeGraphFilters
    const filter = {
      nodes: savedFilters?.nodes ? savedFilters?.nodes : undefined,
      exclude_nodes: savedFilters?.exclude_nodes ? savedFilters?.exclude_nodes : undefined,
      handles: savedFilters?.handles ? savedFilters?.handles : undefined,
      exclude_handles: savedFilters?.exclude_handles ? savedFilters?.exclude_handles : undefined,
      communities: savedFilters?.communities ? savedFilters?.communities : undefined,
      analysisType: savedFilters?.analysis_type ? savedFilters?.analysis_type : undefined,
      analysisQueries: savedFilters?.analysis_section_config_ids
        ? savedFilters?.analysis_section_config_ids
        : undefined,
      importance_score: savedFilters?.importance_score ? savedFilters?.importance_score : null,
      opportunity_score: savedFilters?.opportunity_score ? savedFilters?.opportunity_score : null,
      degreeOfSeparation: savedFilters?.degree_of_separation ? savedFilters?.degree_of_separation : null,
      return_community_graph: savedFilters?.return_community_graph ? savedFilters?.return_community_graph : null,
      custom_search_query: savedFilters?.custom_search_query ? savedFilters?.custom_search_query : null,
      post_types: savedFilters?.post_types ? savedFilters?.post_types : null,
      author_overlap_analysis_ids: savedFilters?.author_overlap_analysis_ids
        ? savedFilters?.author_overlap_analysis_ids
        : null,
      include_author_overlap_analysis_data: savedFilters?.include_author_overlap_analysis_data
        ? savedFilters?.include_author_overlap_analysis_data
        : null,
      minLikes: ![undefined, null].includes(savedFilters?.likes_counts?.min) ? savedFilters?.likes_counts?.min : null,
      maxLikes: ![undefined, null].includes(savedFilters?.likes_counts?.max) ? savedFilters?.likes_counts?.max : null,
      minComments: ![undefined, null].includes(savedFilters?.comments_counts?.min)
        ? savedFilters?.comments_counts?.min
        : null,
      maxComments: ![undefined, null].includes(savedFilters?.comments_counts?.max)
        ? savedFilters?.comments_counts?.max
        : null,
      minViews: ![undefined, null].includes(savedFilters?.views_counts?.min) ? savedFilters?.views_counts?.min : null,
      maxViews: ![undefined, null].includes(savedFilters?.views_counts?.max) ? savedFilters?.views_counts?.max : null,
      minFollowers: ![undefined, null].includes(savedFilters?.author_followers_counts?.min)
        ? savedFilters?.author_followers_counts?.min
        : null,
      maxFollowers: ![undefined, null].includes(savedFilters?.author_followers_counts?.max)
        ? savedFilters?.author_followers_counts?.max
        : null,
      topCommunitiesPercentage: savedFilters?.top_communities_percentage
        ? savedFilters?.top_communities_percentage
        : null,
    };
    filter.date_range = [moment(savedFilters?.start_date), moment(savedFilters?.end_date)];
    return filter;
  };

  useEffect(() => {
    getAnalysis();
  }, [analysisId]);

  const handleNodeClick = useCallback(
    (nodeData) => {
      if ([null, undefined].includes(nodeData?.platform_category)) {
        return;
      }
      setDrawerVisible(true);
      setSelectedWord(nodeData?.name);
      setNodeClick(true);
      setLinkClick(false);
    },
    [analysisId]
  );

  const handleNodePerformanceNodeClick = useCallback(
    (nodeData) => {
      const updatedFilters = {
        ...knowledgeGraphFilters,
        nodes: [nodeData.name],
        degreeOfSeparation: 2,
        // date_range: [
        //   moment(
        //     moment(dates?.start_date)
        //       .startOf("day")
        //       .format("YYYY-MM-DDTHH:mm:ss[Z]")
        //   ),
        //   moment(
        //     moment(dates?.end_date)
        //       .endOf("day")
        //       .format("YYYY-MM-DDTHH:mm:ss[Z]")
        //   ),
        // ],
      };
      setKnowledgeGraphFilters(updatedFilters);
      getKnowledgeGraph(analysisId, updatedFilters, true);
    },
    [analysisId]
  );

  const handleLinkClick = useCallback(
    (nodeData) => {
      setDrawerVisible(true);
      setSelectedLink(nodeData?.connection);
      setLinkClick(true);
      setNodeClick(false);
    },
    [analysisId]
  );

  const handleNodesFilter = (nodes) => {
    const updatedFilters = {
      ...knowledgeGraphFilters,
      nodes: nodes,
      degreeOfSeparation: 2,
      return_community_graph: false,
      topCommunitiesPercentage: 100,
      communities: undefined,
      handles: undefined,
      exclude_handles: undefined,
      exclude_nodes: undefined,
      analysisQueries: undefined,
      importance_score: 0,
      opportunity_score: 0,
      custom_search_query: undefined,
      post_types: ['POST'],
    };
    setKnowledgeGraphFilters(updatedFilters);
    getKnowledgeGraph(analysisId, updatedFilters, false, true);
  };

  const handleCommunitiesFilter = (communities) => {
    const updatedFilters = {
      ...knowledgeGraphFilters,
      communities: communities,
      nodes: undefined,
      return_community_graph: false,
      topCommunitiesPercentage: 100,
      handles: undefined,
      exclude_handles: undefined,
      exclude_nodes: undefined,
      analysisQueries: undefined,
      importance_score: 0,
      opportunity_score: 0,
      custom_search_query: undefined,
      post_types: ['POST'],
    };
    setKnowledgeGraphFilters(updatedFilters);
    getKnowledgeGraph(analysisId, updatedFilters, false, true);
  };

  return {
    loading,
    drawerVisible,
    selectedWord,
    analysisId,
    handleNodeClick,
    setDrawerVisible,
    viewAnalysis,
    setSelectedWord,
    history,
    clientId,
    handleLinkClick,
    selectedLink,
    linkClick,
    nodeClick,
    knowledgeGraphFilters,
    onChangeKnowledgeGraphFilters,
    knowledgeGraph,
    nodes,
    knowledgeGraphLoading,
    summaryDrawer,
    setSummaryDrawer,
    dates,
    colorBy,
    setColorBy,
    applyFiltersKnowledgeGraph,
    knowledgeGraphStats,
    knowledgeGraphStatsLoading,
    getKnowledgeGraphStats,
    handleSliderChange,
    selectedDate,
    handleNodePerformanceNodeClick,
    datesForDatePicker,
    handleNodesFilter,
    knowledgeGraphCommunities,
    handleCommunitiesFilter,
    handlePopupScroll,
    handleSearch,
    handleDropdownVisibleChange,
    loadingNodesOptions,
    containerRef,
    knowledgeGraphChanged,
    knowledgeGraphSavedFilters,
    knowledgeGraphAPIFilter,
    maxJobTriggeredAt,
    showSaveFilterModal,
    saveKnowledgeGraphSavedFilters,
    setShowSaveFilterModal,
    formRef,
    applySavedFilters,
    clearSavedFilters,
    selectedFilter,
    setSelectedFilter,
    showMyFilters,
    setShowMyFilters,
    userData,
    useAISearch,
    setUseAISearch,
    constructKnowledgeGraphFilters,
    showMoreOptions,
    setShowMoreOptions,
    handles,
    hasMoreHandles,
    loadingHandlesOptions,
    handlesTotalData,
    fetchHandlesOptions,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    analysisListData,
    appliedDates,
  };
};

export default useNewsAndCulture;
