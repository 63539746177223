import axios from 'src/http/index';
import ApiUrls from 'src/constants/apiUrls';
import Cookie from "src/lib/cookie";

export default class HomePageApiServices {
  static getClients = async () => {
    const res = await axios.get(ApiUrls.CLIENT_LIST_HOME);
    return res?.data;
  };
  static getClientList = async (id) => {
    const res = await axios.get(ApiUrls.CLIENT_LIST(id));
    return res?.data;
  };
}
