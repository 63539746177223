import { useState, useEffect } from 'react';
import axios from 'axios';
import { AppstoreAddOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import ClientSpaceBasicInfo from './ClientSpaceBasicInfo';
import ClientSpaceSelectApps from './ClientSpaceSelectApps';
import ClientSpaceUserAndPermissions from './ClientSpaceUserAndPermissions';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';
import CreateClientApiServices from '../services/createClientSpaceApiServices';

const useAddEditClientSpaceHook = () => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('configure');
  const [metaData, setMetaData] = useState(null);

  const getMetaData = async () => {
    try {
      setLoading(true);
      const res = await CreateClientApiServices.getClientSpaceMetaData();
      setMetaData(res.data);
      setLoading(false);
    } catch (error) {
      const {msg}  = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg
      })
    }
  }


  useEffect(() => {
    getMetaData();
  }, [])
 
  return {
    loading,
    tab,
    setTab,
    metaData
  };
};

export default useAddEditClientSpaceHook;