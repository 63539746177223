import { css } from '@linaria/core';

const container = css`
  
`;

const addRowContainer = css`
  margin: 1rem 0;
  font-size: 16px;
  color: #DB5A4B;
  cursor: pointer;
  display: inline-block;
`;

const saveButton = css`
  background: #DB5A4B !important;
  color: white !important;
  font-weight: 600 !important;
  border: 0 !important;
`;

const btnsContainer = css`
  gap:8px;
  width:100%;
  justify-content: flex-end;
  flex-wrap:nowrap !important;

  > button {
    width:50%;
  }
  @media screen and (min-width:768px) {
    > button {
      width:auto;
    }
    width:50%;
  }
`

const formContainer = css`
  max-width:900px;
  padding-top:40px;
`

export default {
  container,
  btnsContainer,
  addRowContainer,
  saveButton,
  formContainer
}