import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AppContext from "src/context/appContext";
import LoadingSpinner from "./components/loadingSpinner";
import AppUrls from "./constants/appUrls";
import AuthenticationLayout from "./modules/authentication/authenticationLayout";
import LoggedInLayout from "./modules/loggedInLayout";
import { Security } from "@okta/okta-react";
import { oktaAuthInstance } from "./services/okta-services";
import OktaLogin from "./modules/authentication/components/login/okta/OktaLogin";

/**
 * Root router for Media Planning Optimizer. The application starts its life here 👶👶.
 * @return {JSX.Element}
 */

function getLoggedOutRoutes() {
  return (
    <Switch>
      <Route path={AppUrls.AUTH_LOGIN} component={AuthenticationLayout} />
      <Route path={AppUrls.OKTA_LOGIN} component={OktaLogin} />
      <Redirect from="*" to={AppUrls.AUTH_LOGIN} />
    </Switch>
  );
}

function getLoggedInRoutes() {
  return (
    <Switch>
      <Route path="*" component={LoggedInLayout} />
    </Switch>
  )
}

export default function AppRouter({ history }) {
  const appContext = React.useContext(AppContext.Context);
  const oktaAuth = oktaAuthInstance;
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.push((window.location.origin));
   };
  if (appContext.isLoading) {
    return <LoadingSpinner centered />;
  }
  return (
    <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      <Router>{appContext.isLoggedIn ? getLoggedInRoutes() : getLoggedOutRoutes()}</Router>
    </Security>
  );
}
