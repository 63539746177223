import React, { useState } from 'react';

import {
    Modal,
    List,
  } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';
import ConditionalRender from 'src/components/conditionalRender';

export default function ScoreConfigToolTip({ isIntermediateStep }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
        <>
            <InfoCircleOutlined onClick={showModal} />
            <Modal
                title="Score Details"
                open={isModalOpen}
                onCancel={handleCancel}
                width="40%"
                footer={null}
            >
              
              <ConditionalRender shouldRender={isIntermediateStep}>
                <List>
                  <List.Item><b>Score 0:</b> The answer is completely unrelated to the reference.</List.Item>
                  <List.Item><b>Score 1:</b> The answer has minor relevance but does not align with the reference at all.</List.Item>
                  <List.Item><b>Score 2:</b> The answer has minor relevance but does not align well with the reference.</List.Item>
                  <List.Item><b>Score 3:</b> The answer has minor relevance and is somewhat misaligned with the reference.</List.Item>
                  <List.Item><b>Score 4:</b> The answer has moderate relevance but contains significant inaccuracies.</List.Item>
                  <List.Item><b>Score 5:</b> The answer has moderate relevance but contains some inaccuracies.</List.Item>
                  <List.Item><b>Score 6:</b> The answer aligns with the reference but has noticeable errors or omissions, including intermediate steps.</List.Item>
                  <List.Item><b>Score 7:</b> The answer aligns with the reference but has minor errors or omissions, including intermediate steps.</List.Item>
                  <List.Item><b>Score 8:</b> The answer aligns well with the reference with minimal errors or omissions, including intermediate steps.</List.Item>
                  <List.Item><b>Score 9:</b> The answer is very accurate and aligns almost perfectly with the reference. Intermediate steps may have minor errors, but the final answer is correct.</List.Item>
                  <List.Item><b>Score 10:</b> The answer is completely accurate and aligns perfectly with the reference, including all intermediate steps.</List.Item>
                </List>
              </ConditionalRender>
              <ConditionalRender shouldRender={!isIntermediateStep}>
                  <List>
                      <List.Item><b>Score 0: </b>The answer is completely unrelated to the reference</List.Item>
                      <List.Item><b>Score 1: </b>The answer has minor relevance but does not align with the reference</List.Item>
                      <List.Item><b>Score 2: </b>The answer has minor relevance but does not align well with the reference</List.Item>
                      <List.Item><b>Score 3: </b>The answer has minor relevance and is somewhat misaligned with the reference</List.Item>
                      <List.Item><b>Score 4: </b>The answer has moderate relevance but contains significant inaccuracies</List.Item>
                      <List.Item><b>Score 5: </b>The answer has moderate relevance but contains some inaccuracies</List.Item>
                      <List.Item><b>Score 6: </b>The answer aligns with the reference but has noticeable errors or omissions</List.Item>
                      <List.Item><b>Score 7: </b>The answer aligns with the reference but has minor errors or omissions</List.Item>
                      <List.Item><b>Score 8: </b>The answer aligns well with the reference with minimal errors or omissions</List.Item>
                      <List.Item><b>Score 9: </b>The answer is very accurate and aligns almost perfectly with the reference</List.Item>
                      <List.Item><b>Score 10: </b>The answer is completely accurate and aligns perfectly with the reference</List.Item>
                  </List>
                </ConditionalRender>
            </Modal>
        </>
        
    )
}