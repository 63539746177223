import { css } from "@linaria/core";

const appCardContainer = css`
  cursor: pointer;
  aspect-ratio: 1;
  border: 1px solid #666;
  display: flex;
  flex-direction: column;
  background: var(--color-full-black) !important;
  width:160px;
  height:160px;
  transition: all 0.2s;
  padding: 24px 16px;
  justify-content: space-between;
  outline:none;
  text-align: left;
  color: var(--color-gale-gray);

  .icon {
    color:white;
    opacity: 0.8;
    transition: all 0.2s ease-in;
  }
  
  &.align-bottom {
    justify-content: flex-end;
  }
  @media screen and (min-width:768px){
    width: 193px;
    height: 193px;
  }
  &:hover {
    border-color: var(--color-white);
    background: var(--color-charcoal-surface-1) !important;
    .arrow {
      transform: translateX(4px);
    }
    .icon {
      opacity: 1;
    }
  }
  &:focus {
    outline:none;
    border-color: var(--color-primary);
    background: var(--color-charcoal-surface-2) !important;

    .arrow {
      color: var(--color-primary);
      transform: translateX(4px);
    }
    .plus-btn {
      color: var(--color-primary);
    }
  }
`;

const imageContainer = css`
  height: 9rem;
  width: 40px
  height: 40px;
  background: transparent;
  display: flex;
  img {
    width:100%;
    height:100%;
    object-fit: contain;
  }
`;

const appTextContainer = css`
  border: 1px solid #19202b;
  border-top: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  height: auto;
  flex-grow: 1;
  margin: 0 1px;
`;

const appCardTitle = css`
  font-size: 14px;
  font-weight: 400;
  color: #DCDCDC;
  text-transform: capitalize;
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
`;

const cardArrow = css`
  width: 25px;
  height: 25px;
  color: white;
  font-size: 1.7rem;
  transition: all 0.2s;
  margin-left:8px;
`;

const customAppHeader = css`
  display:flex;
  flex-direction: colummn;
  flex-wrap:wrap;
  gap: 32px;
  margin-bottom: 32px;
  align-items:center;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap:nowrap;
  }
`;

const createAppButton = css`
  cursor:pointer;
  background: var(--color-full-black);
  border:1px solid var(--color-white);
  padding: 6px 24px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-white);
  width:100%;
  min-width: 208px;
  transition: all 0.2s ease-in;
  
  @media screen and (min-width: 768px) {
    width:fit-content;
  }
  img {
    width:20px;
    height:20px;
  }
  &:hover {
    border-color: var(--color-white);
    background: var(--color-charcoal-surface-1) !important;
  }
  &:focus {
    outline:none;
    border-color: var(--color-primary);
    background: var(--color-charcoal-surface-2) !important;
  }
`;

export default {
  appCardContainer,
  imageContainer,
  appTextContainer,
  appCardTitle,
  cardArrow,
  customAppHeader,
  createAppButton
}