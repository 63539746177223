import { Form, Input, Row } from 'antd';
import React from 'react';
import { PrimaryButton } from 'src/components/basic';
import Style from './login.style';

export default function ResetPassword({ data, loading, resetPassword }) {
  const newRef = React.useRef();
  return (
    <div className={Style.contentContainer}>
      <div className={Style.header}>
        <div style={{ fontSize: "1.2rem" }}>Reset password</div>
      </div>
      <div className={Style.content}>
        <Form 
          initialValues={data} 
          ref={newRef}
          onFinish={(val) => {
            resetPassword(val);
          }} 
        >
          <Form.Item
            name="new_password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input.Password placeholder="New password"/>
          </Form.Item>
        </Form>
      </div>
      <div>
        <Row justify="end">
          <PrimaryButton loading={loading} onClick={() => newRef.current.submit()}>
            Reset
          </PrimaryButton>
        </Row>
      </div>
    </div>
  )
}