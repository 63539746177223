import { css } from "@linaria/core";

const treeChart = css`
  position: relative;
  width: 100%;
  height: 400px;
  background-size: 20px, 20px;
  background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0.17) 1px,
      rgba(0, 0, 0, 0) 1px
    )
    0% 0% / 20px 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  .link__to-solved {
    stroke: #00ad00;
    stroke-width: 3px;
  }
`;


const nodeDetails = css`
  position: absolute;
  width: 22%;
  vertical-align: center;
  top: 7%;
  display: flex;
  align-items: baseline;
  right: 10px;
  height: 48%;
`;

const nodeModal = css`
  width: 47% !important;
  height: 80%;
  .ant-modal-content {
    background-color: transparent;
    height: 100%;
    .ant-modal-body {
      height: 100%;
      padding: 0;
      .hover-component-modal-container {
        height: 100%;
        .hover-component-modal {
          max-height: 100%;
        }
      }
    }
  }
`;

export default {
  treeChart,
  nodeDetails,
  nodeModal,
};
