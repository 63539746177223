import { useState, useEffect, useRef } from "react";
import { SqlToolApiServices } from "../sqlToolApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { message, notification } from "antd";

export default function useSqlQueryHook({
  mAiApp,
  selectedConnection,
  tableName,
  onChange,
  setFieldValue,
  tables,
  fieldName,
  getFieldsValue,
  setUnsavedChanges,
}) {
  const [loading, setLoading] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const formRef = useRef(null);

  const validateVirtualTable = async (val) => {
    try {
      const updatedTables = getFieldsValue(["virtual_tables"]);
      let newtableName = updatedTables?.virtual_tables?.[fieldName]?.table_name;
      setLoading(true);
      formRef.current.setFields([
        {
          name: "virtual_sql_query",
          errors: [""],
        },
      ]);
      const payload = {
        table_name: newtableName,
        ...val,
      };
      const res = await SqlToolApiServices.validateVirtualTable(mAiApp, selectedConnection, payload);
      const virtualTables = updatedTables?.virtual_tables;
      const selectedTable = updatedTables?.virtual_tables[fieldName];
      selectedTable.columns = res.data.columns;
      virtualTables[fieldName] = selectedTable;
      setFieldValue({ virtual_tables: virtualTables });
      onChange(val.virtual_sql_query);
      setUnsavedChanges(true);
      setDrawer(false);
      message.success("Query validated successfully");
      setLoading(false);
    } catch (error) {
      const { msg, details } = getErrorMessageFromResponse(error);
      const errorArr = details?.errors;
      setLoading(false);
      if (errorArr?.length > 0) {
        const errorFields = [];
        errorArr.forEach((err) => {
          errorFields.push(err.error);
        });
        formRef.current.setFields([
          {
            name: "virtual_sql_query",
            errors: [errorFields.join(", ")],
          },
        ]);
      } else {
        notification.error({
          message: msg,
          duration: 5,
          placement: "bottomRight",
        });
      }
    }
  };

  return {
    loading,
    drawer,
    setDrawer,
    formRef,
    validateVirtualTable,
    setLoading
  };
}
