import { useState, useMemo, useEffect, useCallback } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { notification } from "antd";
import NcApiServices from "../../services/NcApiServices";

export const useKnowledgeGraphStatHooks = ({
  analysisId,
  handleNodePerformanceNodeClick,
}) => {
  const [overlapType, setOverlapType] = useState("section");
  const [overlapAnalysisIDs, setOverlapAnalysisIDs] = useState([]);
  const [overlappingRegionsData, setOverlappingRegionsData] = useState(null);
  const [overlappingRegionsColumnName, setOverlappingRegionsColumnName] = useState('topics');
  const [overlappingRegionsSearchText, setOverlappingRegionsSearchText] = useState(null);
  const [overlappingRegionsDataLoading, setOverlappingRegionsDataLoading] = useState(false);

  const onChartEvents = useMemo(
    () => ({
      click: (params) => {
        if (params.name) {
          handleNodePerformanceNodeClick && handleNodePerformanceNodeClick(params);
        }
      },
    }),
    [handleNodePerformanceNodeClick],
  );

  const applyFilters = useCallback(() => {
    getOverlappingRegionsData(
      analysisId,
      overlappingRegionsColumnName,
      overlappingRegionsSearchText,
      overlapAnalysisIDs,
      overlapType,
    );
  }, [analysisId, overlappingRegionsColumnName, overlappingRegionsSearchText, overlapAnalysisIDs, overlapType]);

  const getOverlappingRegionsData = async (
    analysisId,
    columnName = "topics",
    searchText = null,
    overlapAnalysisIDs = [],
    overlapType = "sections",
  ) => {
    try {
      setOverlappingRegionsDataLoading(true);
      setOverlappingRegionsColumnName(columnName);
      const res = await NcApiServices.getOverlappingRegionsData(
        analysisId,
        columnName,
        searchText,
        overlapAnalysisIDs,
        overlapType,
      );
      setOverlappingRegionsData(res?.data);
      setOverlappingRegionsDataLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setOverlappingRegionsDataLoading(false);
    }
  };

  useEffect(() => {
    getOverlappingRegionsData(analysisId);
  }, [analysisId]);

  return {
    onChartEvents,
    overlapType,
    setOverlapType,
    overlapAnalysisIDs,
    setOverlapAnalysisIDs,
    overlappingRegionsDataLoading,
    overlappingRegionsData,
    overlappingRegionsColumnName,
    overlappingRegionsSearchText,
    setOverlappingRegionsSearchText,
    setOverlappingRegionsColumnName,
    applyFilters,
  };
};

export default useKnowledgeGraphStatHooks;
