import axios from "src/http/index";
import ApiUrls from "src/constants/apiUrls";

export default class NcApiServices {
  static getAnalysisList = async (clientId, page = 1, search = "", status = "", dates = [], page_size = 12) => {
    const params = {
      page,
      page_size,
      search,
      status,
      startDate: dates[0] ? dates[0].toISOString() : "",
      endDate: dates[1] ? dates[1].toISOString() : "",
      client_space_id: clientId,
    };
    const res = await axios.get(ApiUrls.LIST_NEWS_ANALYSIS, {
      params,
    });
    return res?.data;
  };

  static getAnalysisOverview = async (analysisId) => {
    const res = await axios.get(ApiUrls.GET_ANALYSIS_CONFIG(analysisId));
    return res?.data;
  };

  static getAnalysisCategories = async () => {
    const res = await axios.get(ApiUrls.GET_ANALYSIS_CATEGORIES);
    return res?.data;
  };

  static getAnalysisStats = async (analysisId, params = {}) => {
    const res = await axios.get(ApiUrls.GET_ANALYSIS_CONFIG_STATS(analysisId), {
      params,
    });
    return res?.data;
  };

  static createAnalysis = async (payload) => {
    const res = await axios.put(ApiUrls.CREATE_ANALYSIS, payload);
    return res?.data;
  };

  static getOverlappingRegionsData = async (analysisId, columnName, searchText, overlapAnalysisIDs, overlapType) => {
    const params = {
      overlap_type: overlapType,
    };

    if (searchText) {
      params.search_text = searchText;
    }
    if (overlapAnalysisIDs) {
      params.overlap_analysis_ids = overlapAnalysisIDs.join(",");
    }

    const res = await axios.get(ApiUrls.GET_ANALYSIS_CONFIG_OVERLAPPING_REGIONS(analysisId, columnName), {
      params: params,
    });
    return res?.data;
  };

  static generateAnalysis = async (payload) => {
    const res = await axios.put(ApiUrls.GENERATE_ANALYSIS, payload);
    return res?.data;
  };

  static updateAnalysis = async (payload) => {
    const res = await axios.put(ApiUrls.UPDATE_ANALYSIS, payload);
    return res?.data;
  };

  static addQuery = async (payload) => {
    const res = await axios.post(ApiUrls.ADD_QUERY, payload);
    return res?.data;
  };

  static getAnalysisConfigRawData = async (payload, page = 1, pageSize = 5) => {
    const params = {
      page,
      page_size: pageSize,
    };
    const res = await axios.post(ApiUrls.GET_ANALYSIS_CONFIG_RAW_DATA, payload, { params });
    return res?.data;
  };

  static getKnowledgeGraph = async (payload) => {
    const res = await axios.post(ApiUrls.GET_KNOWLEDGE_GRAPH, payload);
    return res.data;
  };

  static getKnowledgeGraphStats = async (payload) => {
    const res = await axios.post(ApiUrls.GET_KNOWLEDGE_GRAPH_STATS, payload);
    return res.data;
  };

  static getKnowledgeGraphSummary = async (payload) => {
    const res = await axios.post(ApiUrls.GET_KNOWLEDGE_GRAPH_SUMMARY, payload);
    return res.data;
  };

  static getNodesOptions = async (params) => {
    const res = await axios.get(ApiUrls.GET_NODES_OPTIONS, { params });
    return res.data;
  };

  static getOpportunities = async (params) => {
    const res = await axios.get(ApiUrls.GET_OPPORTUNITIES, { params });
    return res.data;
  };

  static getCustomOpportunities = async (params) => {
    const res = await axios.get(ApiUrls.GET_CUSTOM_OPPORTUNITIES, { params });
    return res.data;
  };

  static pinOpportunity = async ({ opportunityID, isPinned }) => {
    const res = await axios.get(ApiUrls.PIN_UNPIN_OPPORTUNITIES, {
      params: {
        analysis_opportunity_id: opportunityID,
        is_pinned: isPinned,
      },
    });
    return res.data;
  };

  static submitOpportunityFeedback = async ({ opportunity_id, feedback_text, feedback_type }) => {
    const res = await axios.post(ApiUrls.FEEDBACK_OPPORTUNITIES, {
      analysis_opportunity_id: opportunity_id,
      feedback_text: feedback_text,
      feedback_type: feedback_type,
    });
    return res.data;
  };

  static getNodePerformanceData = async (payload) => {
    const res = await axios.post(ApiUrls.NODE_TRENDS, payload);
    return res.data;
  };

  static getKnowledgeGraphSavedFilters = async (params) => {
    const res = await axios.get(ApiUrls.LIST_FILTERS, { params });
    return res.data;
  };

  static saveKnowledgeGraphSavedFilters = async (payload) => {
    const res = await axios.post(ApiUrls.SAVE_DELETE_FILTER, payload);
    return res.data;
  };

  static deleteOpportunity = async (params) => {
    const res = await axios.delete(ApiUrls.OPPORTUNITY_OPERATIONS, { params });
    return res.data;
  };

  static createCustomOpportunity = async (payload) => {
    const res = await axios.post(ApiUrls.CREATE_CUSTOM_OPPORTUNITIES, payload);
    return res?.data;
  };

  static getQuadrantData = async (analysisId, filterData) => {
    const payload = {
      analysis_id: analysisId,
      ...filterData
    };
    const res = await axios.post(ApiUrls.POST_QUADRANTS, payload);
    return res?.data;
  };

  static getTrendData = async (analysisId, filterData) => {
    const payload = {
      analysis_id: analysisId,
      ...filterData
    };
    const res = await axios.post(ApiUrls.TREND_QUADRANTS, payload);
    return res?.data;
  };
}
