import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, containerRef, cssClassName, anchorNodeRef, offsetPx }) => {
  const portalRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && portalRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        portalRef.current.style.top = `${rect.top - offsetPx}px`;
        // portalRef.current.style.left = `${rect.left}px`;
      }
    };

    // Sync initial position
    handleScroll();

    // Add scroll listener
    window.addEventListener("scroll", handleScroll, true);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [containerRef]);

  return ReactDOM.createPortal(
    <div
      ref={portalRef}
      style={{ position: "absolute" }}
      className={cssClassName}
    >
      {children}
    </div>,
    anchorNodeRef,
  );
};

export default Portal;
