import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, Typography, Button } from 'antd';
import useClusterTabHook from './useClusterTabHook';
import LoadingSpinner from 'src/components/loadingSpinner';
import {
  containerStyle,
  subClusterContainerStyle,
  subClusterTitleStyle,
  subClusterDescriptionStyle,
  subClusterSizeStyle,
} from './clusterTabLayout.style';
import ClusterRecommendationModal from './ClusterRecommendationModal';
import { ASK_AI } from '../../constants/constants';
import CenteredAiLoader from 'src/components/centeredAiLoader';

const { Text, Title } = Typography;

const ClusterTabLayout = ({ clientId, audienceName, audienceDescription, selectedAudienceData, setSelectedAudienceData }) => {
  const {
    clusterLoading,
    clusters,
    getAiRecommendedClusters,
    clusterRecommendations,
    setClusterRecommendations,
    clusterRecommendationLoading,
    setClusterRecommendationLoading,
  } = useClusterTabHook(clientId);

  const [selectedClusters, setSelectedClusters] = useState([]);
  const [selectedClusterIds, setSelectedClusterIds] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [aiPrompt, setAiPrompt] = useState(audienceDescription);
  const [applyRecommendations, setApplyRecommendations] = useState(false);

  const getSelectedClusters = () => {
    return clusters.flatMap(cluster =>
      cluster.clusters
        .filter(subCluster => selectedClusters.includes(subCluster.id))
        .map(subCluster => ({
          cluster_name: subCluster.cluster_name,
          cluster_description: subCluster.cluster_description
        }))
    );
  };

  useEffect(() => {
    if (Object.keys(clusterRecommendations).length > 0) {
      const subClusterIds = clusterRecommendations.cluster_groups.flatMap(group => group.clusters.map(subCluster => subCluster.id));
      setSelectedClusterIds(subClusterIds);
      setClusterRecommendationLoading(false);
      setApplyRecommendations(true);
      if (!isModalVisible) {
        setIsModalVisible(true);
      }
    }
  }, [clusterRecommendations]);

  useEffect(() => {
    setAiPrompt(audienceDescription);
    if (audienceDescription.length > 0 && clusters?.length > 0) {
      getAiRecommendedClusters(audienceDescription);
    }
  }, [audienceDescription, clusters]);

  useEffect(() => {
    setSelectedAudienceData({
      ...selectedAudienceData,
      selectedClusters: getSelectedClusters()
    });
  }, [selectedClusters]);

  const handleCheckboxChange = (clusterId) => {
    setSelectedClusterIds(prevSelectedIds =>
      prevSelectedIds.includes(clusterId)
        ? prevSelectedIds.filter(id => id !== clusterId)
        : [...prevSelectedIds, clusterId]
    );
  };

  const handleClusterSelection = (checkedValues) => {
    setSelectedClusters(checkedValues);
    setApplyRecommendations(false);
  };

  const handleAskAiClick = () => {
    setIsModalVisible(true);
    setClusterRecommendations({});
  };

  const handleAskRecommendation = () => {
    getAiRecommendedClusters(aiPrompt);
  };

  const handleAiPromptChange = (e) => {
    setAiPrompt(e.target.value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedClusterIds([]);
  };

  const handleApplyRecommendations = () => {
    setSelectedClusters(selectedClusterIds);
    setIsModalVisible(false);
    setApplyRecommendations(true);
  };

  const sortedClusters = useMemo(() => {
    if (applyRecommendations) {
      return clusters.flatMap(cluster => cluster.clusters).sort((a, b) => {
        const aSelected = selectedClusters.includes(a.id);
        const bSelected = selectedClusters.includes(b.id);
        return bSelected - aSelected;
      });
    }
    return clusters.flatMap(cluster => cluster.clusters);
  }, [clusters, selectedClusters, applyRecommendations]);

  if (clusterLoading) return <LoadingSpinner centered />;

  if (clusterRecommendationLoading && !isModalVisible) {
    return <CenteredAiLoader text="Please wait while AI finds the best audiences for you..." />;
  }

  return (
    <>
      <div>
        <Button type="primary" onClick={handleAskAiClick} style={{ marginBottom: "20px" }} disabled={clusters.length === 0}>
          {ASK_AI}
        </Button>
      </div>
      <div style={containerStyle}>
        <div>
          <Title level={3}>{clusters?.length === 0 ? "No Clusters found" : "Select Clusters"}</Title>
        </div>
        <Checkbox.Group
          value={selectedClusters}
          onChange={handleClusterSelection}
          style={{ width: '100%' }}
        >
          {sortedClusters.map(subCluster => (
            <div
              key={subCluster.id}
              style={subClusterContainerStyle}
            >
              <Checkbox value={subCluster.id}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Title level={5} style={subClusterTitleStyle}>{subCluster.cluster_name}</Title>
                  <Text style={subClusterDescriptionStyle}>{subCluster.cluster_description}</Text>
                  <Text style={subClusterSizeStyle}>Cluster Size: {subCluster.cluster_size}</Text>
                </div>
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      </div>
      <ClusterRecommendationModal
        isModalVisible={isModalVisible}
        loading={clusterRecommendationLoading}
        aiPrompt={aiPrompt}
        clusterRecommendations={clusterRecommendations}
        selectedClusterIds={selectedClusterIds}
        handleAskRecommendation={handleAskRecommendation}
        handleApplyRecommendations={handleApplyRecommendations}
        handleAiPromptChange={handleAiPromptChange}
        handleCheckboxChange={handleCheckboxChange}
        handleCancel={handleCancel}
      />

    </>
  );
};

export default ClusterTabLayout;
