import React from "react";
import { Row, Col, Typography, Select, Empty } from "antd";
import ReactECharts from "echarts-for-react";
import useNodePerformanceHooks from "./nodePerformance.hooks";
import Style from "./nodePerformance.style";
import LoadingSpinner from "src/components/loadingSpinner";

const { Title } = Typography;

const NodePerformanceComponent = ({ analysisID, onChartEvents }) => {
  const {
    risingNodesData,
    fallingNodesData,
    loading,
    getBarChartOption,
    getLineChartOption,
    fetchNodePerformanceData,
    trendsRisingNodesData,
    trendsFallingNodesData,
  } = useNodePerformanceHooks({ analysisID });

  if (loading && risingNodesData?.length === 0 && fallingNodesData?.length === 0) {
    return <LoadingSpinner centered />;
  }

  return (
    <div className={Style.nodePerformanceContainer}>
      <Row gutter={16} className={Style.platformDropdownFilter}>
        <Col span={6}>
          <Select
            onChange={(value) => fetchNodePerformanceData(value)}
            placeholder="Platform"
            style={{ width: "100%" }}
            defaultValue={null}
            loading={loading}
          >
            <Select.Option value={null}>Across all platforms</Select.Option>
            <Select.Option value={"news"}>News</Select.Option>
            <Select.Option value={"facebook"}>Facebook</Select.Option>
            <Select.Option value={"twitter"}>Twitter</Select.Option>
            <Select.Option value={"instagram"}>Instagram</Select.Option>
            <Select.Option value={"threads"}>Threads</Select.Option>
            <Select.Option value={"tiktok"}>Tiktok</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>Emerging Nodes</Title>
          {risingNodesData?.length !== 0 ? (
            <ReactECharts
              option={getBarChartOption(risingNodesData, "#52c41a", "Node", "Delta no. of mentions / Delta time")}
              style={{ height: "300px" }}
              onEvents={onChartEvents}
            />
          ) : (
            <Empty description="No Data"  style={{height: "300px"}} />
          )}
        </Col>
        <Col span={12}>
          <Title level={5}>Decaying Nodes</Title>
          {fallingNodesData?.length !== 0 ? (
            <ReactECharts
              option={getBarChartOption(fallingNodesData, "#f5222d", "Node", "Delta no. of mentions / Delta time")}
              style={{ height: "300px" }}
              onEvents={onChartEvents}
            />
          ) : (
            <Empty description="No Data"  style={{height: "300px"}} />
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>Emerging Nodes Trends</Title>
          {trendsRisingNodesData?.length !== 0 ? (
            <ReactECharts
              option={getLineChartOption(trendsRisingNodesData, "Date", "Delta mentions.")}
              style={{ height: "400px" }}
              notMerge={true}
            />
          ) : (
            <Empty description="No Data"  style={{height: "400px"}} />
          )}
        </Col>
        <Col span={12}>
          <Title level={5}>Decaying Nodes Trends</Title>
          {trendsRisingNodesData?.length !== 0 ? (
            <ReactECharts
              option={getLineChartOption(trendsFallingNodesData, "Date", "Delta mentions.")}
              style={{ height: "400px" }}
              notMerge={true}
            />
          ) : (
            <Empty description="No Data"  style={{height: "400px"}} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NodePerformanceComponent;
