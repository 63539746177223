import React from 'react';
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Button,
  Empty,
  Pagination,
  Skeleton,
  Tooltip,
} from 'antd';
import { LeftOutlined, PlusOutlined, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import Style from '../NewsAndCulture.style';
import useListingScreen from '../hooks/useListingScreen';
import appUrls from 'src/constants/appUrls';
import { ListingScreenContent } from '../constants';
import { formatStatus } from '../utils';
import { PrimaryButton } from 'src/components/basic';
import { USE_LISTING_STATUS_COLOR } from 'src/components/globalTag/globalTagConstant';
import GlobalTag from 'src/components/globalTag/GlobalTag';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ListingScreen = () => {
  const {
    filteredData,
    currentPage,
    pageSize,
    searchTerm,
    statusFilter,
    dateRange,
    setCurrentPage,
    handleSearch,
    handleStatusFilter,
    handleDateFilter,
    loading,
    totalPages,
    clientId,
    history,
    handleCardClick,
    options,
    canCreate,
  } = useListingScreen();

  return (
    <div className={Style.cardListingContainer}>
      <div className={Style.contentParent}>
        <div className={Style.content}>
          <div className={Style.filterContainerParent}>
            <div className={Style.filterContainer}>
              <Search
                placeholder="Search Analysis"
                onSearch={handleSearch}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className={Style.searchInputGroup}
              />
              {/* disabled as per Dhruvas suggestion */}
              {/* <Select
                placeholder='Filter by Status'
                onChange={handleStatusFilter}
                value={statusFilter}
                allowClear
                style={{ width: 200 }}
              >
                {options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select> */}
              <RangePicker onChange={handleDateFilter} value={dateRange} className={Style.rangePicker} />
            </div>
            <PrimaryButton
              className={Style.createButton}
              onClick={() => history.push(appUrls.CREATE_LISTING_SCREEN(clientId))}
              // View only access for time being
              disabled={canCreate ? false : true}
            >
              {ListingScreenContent.CREATE}
              <PlusOutlined />
            </PrimaryButton>
          </div>
          <Row gutter={[16, 16]} className={Style.cardRow}>
            {loading ? (
              Array.from({ length: 12 }).map((_, index) => (
                <Col key={index} className={Style.cardCol} xs={24} sm={12} md={8} lg={6}>
                  <Card className={Style.analysisCard}>
                    <Skeleton active />
                  </Card>
                </Col>
              ))
            ) : filteredData?.length > 0 ? (
              filteredData?.map((item, index) => (
                <Col key={index} className={Style.cardCol} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    title={item.name}
                    className={Style.analysisCard}
                    onClick={() => handleCardClick(item)}
                    style={{
                      cursor: item?.can_view_analysis ? "pointer" : "default",
                    }}
                    hoverable={item?.can_view_analysis}
                    extra={
                      item?.can_view_analysis && (
                        <Tooltip title="View Query" placement="top">
                          <InfoCircleOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(appUrls.UPDATE_LISTING_SCREEN(clientId, item?.id), { mode: "Done" });
                            }}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    <p>
                      Status:{" "}
                      <GlobalTag color={USE_LISTING_STATUS_COLOR[item?.can_view_analysis ? "DONE" : item?.job_state]}>
                        {item?.can_view_analysis
                          ? (item?.is_scheduled && moment(item?.schedule_end_date) > moment())
                            ? "Live"
                            : "Done"
                          : formatStatus(item?.job_state)}
                      </GlobalTag>
                    </p>
                    <p>Created On: {moment(item?.created_on).format("MM-DD-YYYY")}</p>
                  </Card>
                </Col>
              ))
            ) : (
              <div className={Style.emptyContainer}>
                <Empty description="No data found" />
              </div>
            )}
          </Row>
        </div>
        {totalPages > 1 && (
          <div className={Style.paginationContainer}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalPages}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
              itemRender={(page, type, originalElement) => {
                if (type === "prev") {
                  return <Button type="text" icon={<LeftOutlined />} />;
                }
                if (type === "next") {
                  return <Button type="text" icon={<RightOutlined />} />;
                }
                if (type === "page") {
                  return (
                    <Button
                      type="text"
                      style={{
                        fontWeight: page === currentPage ? "bold" : "normal",
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
                return originalElement;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingScreen;
