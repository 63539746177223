import React, { useState } from 'react';
import { Input, InputNumber, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './Input.less'; // Optional: Add any custom styles

const { Search } = Input;

export const InputSearch = ({ placeholder, onSearch, className, ...props }) => {
  const [loading, setLoading] = useState(false);

  // Function to handle search behavior
  const handleSearch = async (value) => {
    if (!value) return;

    setLoading(true); // Show loading spinner
    try {
      await onSearch(value); // Perform the search function
    } finally {
      setLoading(false); // Stop loading once search is done
    }
  };

  return (
    <Search
      className={`custom-input-search ${className ? className : ''}`} // Optional: Add custom class}
      placeholder={placeholder}
      enterButton={loading ? <Spin size='small' /> : <SearchOutlined />}
      onSearch={handleSearch}
      disabled={loading} // Disable input while loading
      allowClear
      {...props}
    />
  );
};

export const InputText = ({ placeholder, onChange, value, className, ...props }) => {
  return (
    <Input
      className={`custom-input ${className ? className : ''}`} // Optional: Add custom class}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      allowClear
      {...props}
    />
  );
};

export const InputNo = ({ placeholder, onChange, value, className, ...props }) => {
  return (
    <InputNumber
      className={`custom-input ${className ? className : ''}`} // Optional: Add custom class}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      allowClear
      {...props}
    />
  );
}