import { css } from "@linaria/core";

const layoutContainer = css`
  position: relative;
`;

const title = css`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.1rem;
  color: black;
`;

const hamburgerContainer = css`
  padding: 0.8rem;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  z-index: 1000;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
`;

// const chatContainer = css`
//   display: flex;
//   padding-bottom: 1rem;
//   flex-grow: 1;
//   flex-direction: column;
//   height: calc(100vh - 102px);
//   width: calc(100% - 250px);
//   margin-left: 250px;
//   background-color:var(--color-white);
//   @media screen and (max-width: 768px) {
//     width: 100%;
//     margin-left: 0;
//   }
// `;

const socialInsightsChatContainer = css`
  height: calc(100vh - 400px)
`

const messageContainer = css`
  flex: 1;
  overflow-y: auto;
  padding: 1rem 15%;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    padding: 1rem;
    padding-left: 1.5rem;
  }
`;

const mainContainer = css`
  display: flex;
  flex-direction: column;
  width:100vw;
  height: calc(100vh - 100px);
`;

const helpImage = css`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const helpContainer = css`
  margin-top: 7vh;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;


const chatLabel = css`
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.3rem;
`;

const chatDesc = css`
  color: var(--color-white);
  padding-left: 32px;
  font-size: 1.143rem !important;
  font-weight: 400;
  width: 100%;
  margin-bottom: 0.5rem;
  display:flex;
  flex-direction: column;
  &.right-align{
    align-items:flex-end;
  }
`;

const chatMessage = css`
  margin-bottom:16px;
`;

const inputLoader = css`
  right: 16%;
  position: absolute;
  top: 30%;
  padding: 4px 7px;
  border-radius: 0.5rem;
  background: black;
  @media screen and (max-width: 768px) {
    right: 7%;
  }
`;

const stopIcon = css`
  right: 16%;
  position: absolute;
  top: 30%;
  padding: 4px 7px;
  border-radius: 0.5rem;
  color: #171717;
  @media screen and (max-width: 768px) {
    right: 7%;
  }
`;

const fileUploadIcon = css`
  position: absolute;
  cursor: pointer;
  top: 25%;
  padding: 4px 7px;
  @media screen and (max-width: 768px) {
  }
`;

const inputLoaderImage = css`
  right: 16%;
  position: absolute;
  top: 30%;
  padding: 4px 7px;
  @media screen and (max-width: 768px) {
    right: 7%;
  }
`;

const accountIcon = css`
  padding: 6px 9px;
  background: #788f9c;
  border-radius: 26px;
`;

const noMessageContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const conversationStarterContainer = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
`;

const chatMessageContainer = css`
  display: flex;
  flex-direction:column;
  gap: 0.3rem;
`;

const messageLabel = css`
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 0.2rem;
  font-size: 16px;
  display:flex;
  gap:6px;
  justify-content:flex-end;
`;

const reactionContainer = css`
  margin-top: 0.2rem;
  font-size: 1rem;
  display:flex;
  & > * {
    opacity: 0.7;
    transition: transform 0.2s;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  &.right-align {
    justify-content:flex-end;
  }
`;

const reactionIcon = css`
  cursor: pointer;
  padding-right: 3px;
`;

const copiedIcon = css`
  padding-right: 3px;
`;

const markdown = css`
  display: flex;
  max-width:99%;
  flex-direction: column;
  color: var(--color-gale-gray);
  font-weight: 400;
  font-size: 1.143rem !important;
  
  & > * {
    color: var(--color-gale-gray);
  }

  &.light {
    color: var(--color-70-black);
    & > * {
      color: var(--color-70-black);
    }
  }

  &.disliked {
    color: var(--color-primary);
    & > * {
      color: var(--color-primary);
    }
  }

  table {
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
  }
  th {
    padding: 6px 13px;
    background: black;
    color: white;
    font-weight: 600;
  }
  td {
    background: #eeeeee;
    padding: 6px 13px;
    color: black;
    font-weight: 400;
  }
  p {
    font-size: 1.143rem !important;
    margin-bottom: 0.3rem;
  }
`;

const disliked = css`
  color: var(--feedback-dislike);
  font-weight: 500;
`

const liked = css`
  color: var(--feedback-like);
  font-weight: 500;
`

const dislikedParent = css`
  // background-color: #FA5F55;
`

const likedParent = css`
  // background-color: #478778;
`

const markDownParent = css`
  overflow-x: auto;
`

const reactionContainerDisabled = css`
  opacity: 0.75;
  pointer-events: none;
`;

const clearChatButton = css`
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const streamingResponseAccordianContainer = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 6px;
`;

const streamingResponseAccordianActionText = css`
  font-size: 1rem;
  font-weight: 600;
  color: gray;
  overflow: auto;
`;

const actionParserContainer = css`
  font-weight: 400;
  font-size:1rem;
  white-space: pre;
  overflow: auto;
  max-height: 350px;
  .react-json-view {
    background: transparent !important;
  }
`;

const outputLabel = css`
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 4px;
`;

const inputLabel = css`
  font-weight: 600;
`;

const actionContainer = css`
  background: var(--color-charcoal-surface-1);
  padding: 5px 10px;
  border-radius: 5px;
  color:var(--color-white);
  // margin-left: 1rem;
  // margin-top: 1rem;
`;

const userContainer = css`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const aclhemyAiLogoContainer = css`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  margin-right: 12px;
  span {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const footer = css`
  color: white;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: black;
  z-index: 1000;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

const sideNav = css`
  position: fixed;
  left: 0;
  top: 94px;
  padding: 67px 0;
  width: 250px;
  color: black;
  z-index: 2;
  transition: width 2s;
`;

const sideNavTabs = css`
  width: calc(100% - 250px);
  z-index: 100;
  height: 290px;
  margin-left: 250px;
  .ant-card-bordered {
    border-radius: 8px;
    box-shadow: 5px 5px 5px lightgray;
    max-height: 240px;
  }
  .ant-tabs-tab:hover{
    color: black;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: bold;
    color: black;
    .ant-tabs-tab-btn {
      color: black;
    }
  }
  .ant-tabs{
    font-size: 13px;
    font-weight: 400;
  }

  .ant-tabs-ink-bar {
    background-color: black;
  }
`;

const openNavBarIcon = css`
  position: absolute;
  top: 50%;
`;

const inputFileUploadContainer = css`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

const navBarContainer = css`
  display: flex;
  flex-direction: column;
`;

const navBarContainerWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
`;

const fileListHeader = css`
  font-size: 16px;
  // color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const fileContainer = css`
  padding-bottom: 0.3rem;
  display: flex;
`;

const file = css`
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  color: white;
  background: #484545;
`;

const previewFileLoader = css`
  top: 64%;
`;

const previewFileUploader = css`
  top: 61%;
`;

const streamingResponseContainer = css`
  width: 100%;
  max-height: 30rem;
  overflow-y: scroll;
  display:flex;
  flex-direction:column;
  gap:4px;
`;

const loadingDeleteContainer = css`
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
`;

const editButton = css`
  background: #db5a4b !important;
  color: white !important;
  border: 0 !important;
  font-weight: 600 !important;
  height: 2.2rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 8px !important;
`;

const hiddenReactions = css`
  & > * {
    visibility: hidden;
  }
`;

const timestampTime = css`
  float: right;
  visibility: unset;
  color: var(--color-30-gray);
  font-size: 0.7rem;
  font-weight:700;
`;

const responseInfoIcon = css`
  color: var(--color-30-gray) !important;
`;

const botLogoNameContainer = css`
  display:flex;
  gap: 8px;
`;

const messageLabelBot = css`
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 0.2rem;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const userLabel = css`
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 0.2rem;
  font-size: 16px;
`

const responseFootnote = css`
  display:flex;
  gap:6px;
  align-items:center;
  margin-right:30px;
`;

const timeAndInfoParent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  gap:6px;
`;

const timeTooltip = css`
  border-radius: 0.5rem;
  padding-bottom: 0.5rem !important;
  .ant-tooltip-content {
    border-radius: 0.5rem;
    font-size: 10px;
    .ant-tooltip-inner {
      border-radius: 0.5rem;
      margin: 5px;
      display: flex;
      align-items: center;
    }
  }
`;

const skeleton = css`
  .ant-skeleton-title {
    display: none;
  }
  .ant-skeleton-paragraph {
    margin-top: 0;
  }
  li {
    height: 10px !important;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 1rem;
  }
`;

const groupChatContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;


const chatLoader = css`
  width: 70%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-self: center;
  .ant-skeleton {
    padding: 11px;
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 20% !important;
      }
    }
  }
`;

const sessionLoader = css`
  height: 90%;
`;

const disclaimer = css`
  font-size: 1rem;
  padding: 0px 10px;
`;

const toggleButton = css`
  &.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #171717 !important;
  }
  .ant-radio-button-wrapper {
    border: none;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background: none;
  }
  &.ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background-color: none !important;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

const navBarLogo = css`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 10px; 
`;

const chatListItem = css`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px
  cursor: pointer;
  .anticon svg {
    display: inline-block;
  }
  div {
    .anticon svg {
      display: inline-block;
    }
  }
  &:hover {
    .anticon svg {
      display: inline-block;
    }
    background: #e0e0e0;
  }
`;

const activeListItem = css`
  background: #e0e0e0;
`;

const sessionLoadingLoader = css`
  display: flex;
  justify-content: center;
`;

const popOverContainer = css`
  .ant-popover-inner {
    width: fit-content;
    border-radius: 5px;
  }
`;

const clientIcon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  #hoverImage {
    display: none;
  }
  :hover {
    #normalImage {
      display: none;
    }
    #hoverImage {
      display: inline-block;
    }
  }
`;

const editButtonParent = css`
  display: flex;
  flex-direction: row;
  justify-content: end;
  z-index: 1;
`

const questionContainer = css`
  width:fit-content;
  float:right;
  max-width:80%;
  margin-bottom:8px;
  border-radius: 16px;
  background:var(--color-charcoal-surface-3);
  padding: 12px 16px;
  color: var(--color-gale-gray);
  margin-right:30px;
`;


export default {
  title,
  // chatContainer,
  hamburgerContainer,
  messageContainer,
  helpContainer,
  helpImage,
  chatLabel,
  chatDesc,
  chatMessage,
  inputLoader,
  accountIcon,
  disclaimer,
  noMessageContainer,
  conversationStarterContainer,
  chatMessageContainer,
  messageLabel,
  reactionContainer,
  reactionIcon,
  copiedIcon,
  markdown,
  reactionContainerDisabled,
  inputLoaderImage,
  clearChatButton,
  streamingResponseAccordianContainer,
  streamingResponseAccordianActionText,
  actionParserContainer,
  outputLabel,
  inputLabel,
  chatLoader,
  actionContainer,
  userContainer,
  mainContainer,
  aclhemyAiLogoContainer,
  footer,
  sideNav,
  openNavBarIcon,
  layoutContainer,
  inputFileUploadContainer,
  navBarContainer,
  fileListHeader,
  fileContainer,
  file,
  previewFileLoader,
  streamingResponseContainer,
  fileUploadIcon,
  previewFileUploader,
  loadingDeleteContainer,
  editButton,
  stopIcon,
  hiddenReactions,
  timestampTime,
  responseInfoIcon,
  timeTooltip,
  skeleton,
  groupChatContainer,
  sessionLoader,
  toggleButton,
  navBarContainerWrapper,
  navBarLogo,
  chatListItem,
  activeListItem,
  sessionLoadingLoader,
  popOverContainer,
  clientIcon,
  sideNavTabs,
  socialInsightsChatContainer,
  disliked,
  liked,
  markDownParent,
  dislikedParent,
  likedParent,
  editButtonParent,
  botLogoNameContainer,
  messageLabelBot,
  userLabel,
  responseFootnote,
  timeAndInfoParent,
  questionContainer
};
