import axios from "src/http/index";
import Axios from "axios";
import ApiUrls from "src/constants/apiUrls";
import Cookie from "src/lib/cookie";

export class AiAppApiServices {
  static async getsessionList(mAiApp, type, page) {
    const res = await axios.get(ApiUrls.GET_SESSION_LIST(type, page), {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async getChatList(mAiApp, sessionId, cancelToken, pageNum) {
    const res = await axios.get(ApiUrls.GET_CHAT_LIST(pageNum), {
      headers: {
        mAiApp: mAiApp,
        'session-id': sessionId 
      },
      cancelToken: cancelToken
    }, );
    return res.data;
  }

  static async postResponse(data, mAiApp, testMode, sessionId) {
    const url = ApiUrls.GET_CHAT_RESPONSE(testMode);
    const token = Cookie.get("token");

    let headers = {
      "Content-Type": "application/json",
      mAiApp: mAiApp,
      Authorization: token,
    };
    if (sessionId) {
      headers['session-id'] = sessionId
    }
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    return response;
  }

  static async streamInProgressResponse(chatID, mAiApp, sessionId) {
    const url = ApiUrls.STREAM_IN_PROGRESS_CHAT_RESPONSE(chatID);
    const token = Cookie.get("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mAiApp: mAiApp,
        Authorization: token,
        "session-id": sessionId
      },
    });

    return response;
  }

  static async terminateInProgressChat(chatID, mAiApp, sessionId) {
    const url = ApiUrls.TERMINATE_IN_PROGRESS_CHAT(chatID);
    const token = Cookie.get("token");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        mAiApp: mAiApp,
        Authorization: token,
        'session-id': sessionId
      },
    });

    return response;
  }

  static async getStarterConversation(mAiApp, sessionId) {
    const res = await axios.get(ApiUrls.GET_CONVERSATION_STARTERS, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }
  static async postFeedback(mAiApp, data, sessionId) {
    const res = await axios.post(ApiUrls.POST_FEEDBACK, data, {
      headers: {
        mAiApp: mAiApp,
        "session-id": sessionId
      },
    });
    return res.data;
  }

  static async deleteChatHistory(mAiApp, sessionId) {
    const res = await axios.get(ApiUrls.DELETE_CHAT_HISTORY, {
      headers: {
        mAiApp: mAiApp,
        "session-id": sessionId
      },
    });
    return res.data;
  }

  static async getAnalysis(mAiApp, chatId) {
    const res = await axios.get(ApiUrls.GET_ANALYSIS(chatId), {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async fileUpload(mAiApp, formData, sessionId) {
    const res = await axios.post(ApiUrls.POST_FILE, formData, {
      headers: {
        mAiApp: mAiApp,
        "Content-Type": "multipart/form-data",
        "session-id": sessionId
      },
    });
    return res.data;
  }
  static async getFileList(mAiApp, sessionId) {
    const res = await axios.get(ApiUrls.GET_FILE_LIST, {
      headers: {
        mAiApp: mAiApp,
        'session-id': sessionId
      },
    });
    return res.data;
  }

  static async archiveFile(mAiApp, fileId, sessionId) {
    const res = await axios.get(ApiUrls.ARCHIVE_FILE(fileId), {
      headers: {
        mAiApp: mAiApp,
        'session-id': sessionId
      },
    });
    return res.data;
  }

  static async createSession(mAiApp, payload) {
    const res = await axios.post(ApiUrls.CREATE_SESSION, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    })
    return res.data;
  }

  static async renameSession(mAiApp, sessionId, payload) {
    const res = await axios.put(ApiUrls.EDIT_SESSION, payload, {
      headers: {
        mAiApp: mAiApp,
        'session-id': sessionId
      },
    })
    return res.data;
  }
}
