import { Timeline } from "antd";
import React from "react";
import { formatStatus } from "../utils";
import moment from "moment";
import GlobalTag from "src/components/globalTag/GlobalTag";
import { JOB_TRIGGER_STATUS_COLOR } from "src/components/globalTag/globalTagConstant";

export default function JobTriggersDrawer({ analysisOverview }) {
  const data = analysisOverview?.job_triggers;
  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = (seconds % 60).toFixed(2);
    return `${minutes} minutes and ${remainingSeconds} seconds`;
}

  return (
    <>
      <Timeline style={{color:"white"}}>
        {data?.map((item) => {
          return (
            <Timeline.Item>
              {moment(item?.job_started_at || item?.job_triggered_at).format(
                "LLL",
              )}{" "}
              &nbsp;&nbsp;
              <GlobalTag
                color={
                  JOB_TRIGGER_STATUS_COLOR[item?.state] ||
                  JOB_TRIGGER_STATUS_COLOR.IN_PROGRESS
                }
              >
                {formatStatus(item?.state)}
              </GlobalTag>
              &nbsp;({formatTime(item?.time_taken)})
            </Timeline.Item>
          );
        })}
      </Timeline>
    </>
  );
}
