import { css } from "@linaria/core";
const queryBuilder = css`
  .queryBuilder {
    background-color: var(--color-charcoal-surface-1);
    padding: 16px;
    border-radius: 5px;
    width: 100%;
    overflow: auto;
    .ruleGroup {
      .ruleGroup-header {
        .ruleGroup-combinators,
        .ruleGroup-addRule,
        .ruleGroup-addGroup,
        .ruleGroup-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          font-size: 1.143rem;
          line-height: 120%;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          padding: 0px 48px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          text-transform: uppercase;
          transition: all 0.3s;
          height: 40px !important;
        }
        .ruleGroup-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          width: 56px !important;
          height: 40px !important;
          padding-bottom: 10px !important;
          font-size: 32px;
          line-height: 32px;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }
    .ruleGroup-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .rule {
        width: 100%;

        display: flex;
        gap: 1rem;
        .rule-fields,
        .rule-operators,
        .ant-select,
        .ant-select-selector {
          background-color: var(--color-charcoal-surface-1) !important;
          align-items: center;
          padding: 10px;
        }
        .rule-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          font-size: 32px;
          line-height: 32px;
          width: 56px !important;
          height: 40px !important;
          padding-bottom: 10px !important;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
        .rule-value {
          background-color: var(--color-charcoal-surface-1) !important;
          color: white;
          display: flex;
          gap: 1rem;
          width: 100%;
          min-width: 120px;
          border: 1px solid #6f6f6f;
          padding: 10px;
        }
      }
      .ruleGroup {
        width: 100% !important;
        border: 1px solid gray;
        padding: 16px;
        border-radius: 5px;
      }
    }
  }
`;

const articleNumContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  color: white;
`;

const articleNumLabel = css`
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding-right: 10px;
`;

const queryBuilderFieldName = css`
  font-size: 16px;
  color: white;
  padding-right: 10px;
`;

const fieldsContainer = css`
  display: flex;
  align-items: center;
  width: 90%;
  padding: 16px;
  font-size: 14px;
  color: white;
`;

const articleNumInput = css`
  height: 40px;
  width: 180px !important;
  fontsize: 14px;
  color: white;
`;

const queryBuilderContainer = css`
  display: flex;
  gap: 20px;
  align-item: center;
`;

export default {
  queryBuilder,
  articleNumContainer,
  articleNumLabel,
  articleNumInput,
  queryBuilderContainer,
  fieldsContainer,
  queryBuilderFieldName,
};
