import React from 'react';
import { Modal, Button, Checkbox, Input } from 'antd';
import Assets from 'src/assets';
import { ASK_AI } from '../../constants/constants';

const { TextArea } = Input;

const ClusterRecommendationModal = ({
    isModalVisible,
    loading,
    aiPrompt,
    clusterRecommendations,
    selectedClusterIds,
    handleAskRecommendation,
    handleApplyRecommendations,
    handleAiPromptChange,
    handleCheckboxChange,
    handleCancel
}) => (
    <Modal
        title={ASK_AI}
        visible={isModalVisible}
        onOk={handleAskRecommendation}
        footer={[
            loading ? (
                <span key="loading" style={{ alignItems: "center" }}>Analyzing... <img src={Assets.getImage("AiLoader")} alt="AI" style={{ width: '20px', height: '20px', marginLeft: '10px' }} /> </span>
            ) : (
                <Button key="submit" type="primary" onClick={Object.keys(clusterRecommendations).length === 0 ? handleAskRecommendation : handleApplyRecommendations}>
                    {Object.keys(clusterRecommendations).length === 0 ? "Recommend" : "Apply"}
                </Button>
            )
        ]}
        onCancel={handleCancel}
        width={600}
        centered
    >
        {Object.keys(clusterRecommendations).length > 0 ? (
            <div>
                <h3 style={{ color: "#ffff" }}>Recommended Clusters:</h3>
                <p>{clusterRecommendations.reasoning}</p>
                {clusterRecommendations.cluster_groups.map((group) => {
                    return (
                        <div key={group.id} style={{ marginBottom: '20px' }}>
                            <h4 style={{ display: 'inline', marginLeft: '8px', color: "#ffff", fontWeight: "bold" }}>{group.name}</h4>
                            <p>{group.reasoning}</p>
                            <ul>
                                {group.clusters.map((cluster) => (
                                    <li key={cluster.id}>
                                        <Checkbox
                                            checked={selectedClusterIds.includes(cluster.id)}
                                            onChange={() => handleCheckboxChange(cluster.id)}
                                        >
                                            <strong>{cluster.cluster_name}</strong>: {cluster.reasoning}
                                        </Checkbox>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        ) : (
            <TextArea
                rows={4}
                value={aiPrompt}
                onChange={handleAiPromptChange}
                placeholder="Enter your prompt for AI"
                style={{ marginBottom: '20px' }}
            />
        )}
    </Modal>
);

export default ClusterRecommendationModal;