import { useState, useEffect } from "react";
import { notification } from "antd";
import NcApiServices from "../../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";

const useNodePerformanceHooks = ({ analysisID }) => {
  const [risingNodesData, setRisingNodesData] = useState([]);
  const [fallingNodesData, setFallingNodesData] = useState([]);
  const [trendsRisingNodesData, setTrendsRisingNodesData] = useState([]);
  const [trendsFallingNodesData, setTrendsFallingNodesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatNodeData = (nodes) => {
    if (nodes) {
      return Object.entries(nodes).map(([name, value]) => ({ name, value }));
    }
    return [];
  };

  const formatNodeTrendData = (data) => {
    return Object.entries(data).map(([nodeName, nodeData]) => ({
      name: nodeName,
      data: nodeData.data.map((item) => ({
        name: item.name,
        value: item.value,
      })),
    }));
  };

  const getBarChartOption = (data, color, xAxisLabel, yAxisLabel) => ({
    xAxis: {
      type: "category",
      data: data?.map((item) => item.name),
      name: xAxisLabel,
      nameLocation: "middle", // Align label to the middle of the axis
      nameGap: 50,
      axisLabel: {
        rotate: 45, // Rotate xAxis labels by 45 degrees
      },
    },
    yAxis: {
      type: "value",
      name: yAxisLabel,
      nameRotate: 90,
      nameLocation: "middle", // Align label to the middle of the axis
      nameGap: 50,
    },
    series: [
      {
        data: data?.map((item) => item.value),
        type: "bar",
        itemStyle: {
          color: color,
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  });

  const getLineChartOption = (data, xAxisLabel, yAxisLabel) => {
    // Extract all unique x-axis labels and sort them
    const allXAxisLabels = Array.from(new Set(data?.flatMap((node) => node.data.map((item) => item.name)))).sort();

    // Preprocess the data to ensure each series has a value (or null) for every x-axis label
    const processedData = data?.map((node) => {
      const dataMap = new Map(node.data.map((item) => [item.name, item.value]));
      const completeData = allXAxisLabels.map((label) => dataMap.get(label) ?? 0);
      return {
        name: node.name,
        data: completeData,
      };
    });

    return {
      xAxis: {
        type: "category",
        data: allXAxisLabels,
        name: xAxisLabel,
        nameLocation: "middle", // Align label to the middle of the axis
        nameGap: 50,
        axisLabel: {
          rotate: 45, // Rotate xAxis labels by 45 degrees
        },
      },
      yAxis: {
        type: "value",
        name: yAxisLabel,
        nameRotate: 90,
        nameLocation: "middle", // Align label to the middle of the axis
        nameGap: 50,
      },
      series: processedData.map((node) => ({
        name: node.name,
        type: "line",
        data: node.data,
      })),
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: processedData.map((node) => node.name),
        textStyle: {
          color: "#fff",
        },
      },
      textStyle: {
        color: "#fff",
      },
      grid: {
        bottom: 120,
      },
      dataZoom: [
        {
          type: "slider",
          xAxisIndex: 0,
          start: 0,
          end: 100,
        },
      ],
    };
  };

  const fetchNodePerformanceData = async (platform) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getNodePerformanceData({ analysis_id: analysisID, platform: platform });
      if (res?.success) {
        setTrendsRisingNodesData(formatNodeTrendData(res?.data?.rising_node_trend_data));
        setTrendsFallingNodesData(formatNodeTrendData(res?.data?.falling_node_trend_data));
        setRisingNodesData(formatNodeData(res?.data?.rising_nodes_data));
        setFallingNodesData(formatNodeData(res?.data?.falling_nodes_data));
      } else {
        notification.warning({
          duration: 5,
          message: "No data available.",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNodePerformanceData();
  }, [analysisID]);

  return {
    risingNodesData,
    fallingNodesData,
    loading,
    getBarChartOption,
    fetchNodePerformanceData,
    getLineChartOption,
    trendsRisingNodesData,
    trendsFallingNodesData,
  };
};

export default useNodePerformanceHooks;
