import { css } from '@linaria/core';

const conversationBox = css`
  border: 1px solid var(--color-60-gray);
  border-radius: 8px;
  color:var(--color-gale-gray);
  padding: 16px;
  font-weight: 400;
  height: 100%;
  display: flex;
  cursor: pointer;
  transition: all 0.2s ease-in;
  &:hover {
    border-color:var(--color-white);
    color: var(--color-white);
    background: var(--color-hover-black);
  }
  @media screen and (max-width: 768px) {
    padding: 8px 16px;
  }

`;

export default {
  conversationBox
}