import { Card, Checkbox, Col, Drawer, Form, Input, InputNumber, Progress, Row, Skeleton, Slider, Tooltip } from "antd";
import React from "react";
import { PrimaryButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./clusterFeature.style";
import useClusterFeatureHook from "./useClusterFeatureHook";
import { numberWithCommas, shortenStr } from "src/lib/utils";
import NcBreadCrumb from "src/modules/newsAndCulture/components/NcBreadCrumb";
import LoadingSpinner from "src/components/loadingSpinner";
import SalientFeatureChart from "./SalientFeatureChart";
import CorrelationChart from "./CorrelationChart";
import appUrls from "src/constants/appUrls";

export default function ClusterFeature({ history, match }) {
  const {
    loading,
    formRef,
    configureLoading,
    data,
    initialValues,
    generateCluster,
    selectAllFeatures,
    goBack,
    recommendedCount,
    corelationDrawer,
    setCorelationDrawer,
    clusterConfigId,
    autoSelection,
    clientSpaceId,
    setAutoSelection,
    viewMode,
  } = useClusterFeatureHook({
    history,
    match,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <ConditionalRender shouldRender={!loading}>
      <div style={{ marginBottom: "1rem" }}>
        <NcBreadCrumb className={Style.breadCrumbCreateScreen} />
      </div>
      <Row justify="space-between">
        <Col>
          <h1>Feature Importance</h1>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>
            <Col>
              <SecondaryButton className="small" onClick={() => history.push(appUrls.SELECT_FEATURES(clientSpaceId, clusterConfigId))}>
                Back
              </SecondaryButton>
            </Col>
            <Col>
              <PrimaryButton disabled={viewMode} loading={configureLoading} className="small" onClick={() => formRef.current.submit()}>
                Save and continue
              </PrimaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form
        initialValues={{ ...initialValues }}
        ref={formRef}
        requiredMark={false}
        onFinish={(val) => {
          let totalFeatureSelected = 0;
          val.salient_features.forEach((feature) => {
            if (feature.is_user_selected) {
              totalFeatureSelected++;
            }
          });
          if (totalFeatureSelected < 5 && !val.auto_select_salient_features) {
            formRef.current.setFields([
              {
                name: "feature_parent",
                errors: ["Select Atleast 5 Features"],
              },
            ]);
          } else {
            generateCluster(val);
          }
        }}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues?.salient_features) {
            formRef.current.setFields([
              {
                name: "feature_parent",
                errors: [],
              },
            ]);
          }
          if (changedValues?.auto_select_salient_features) {
            setAutoSelection(changedValues?.auto_select_salient_features);
          } else if (changedValues?.auto_select_salient_features === false) {
            setAutoSelection(changedValues?.auto_select_salient_features);
          }
        }}
      >
        <div className={Style.clusterNumberContainer}>
          <h3>Specify the number of clusters you want to create</h3>
          <Row gutter={[20, 0]}>
            <Col>
              <Form.Item name="number_of_clusters" rules={CustomGptService.validations({ required: true }, "Number of Clusters")}>
                <Slider style={{ width: 300, maxWidth: "300px" }} marks={{ 2: 2, 20: 20 }} min={2} max={20}></Slider>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="number_of_clusters">
                <InputNumber min={2} max={20} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Row justify="space-between">
          <Col>
            <h4>While unvelling some cool trends, I also took the liberty to identify the most relevant behavioral attributes.</h4>
            <h4>You can click on Auto Select and let the tool decide it for you.</h4>
            <Form.Item name="auto_select_salient_features" valuePropName="checked" noStyle>
              <Checkbox style={{ marginBottom: "0.8rem" }}>Auto Select</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <PrimaryButton disabled={autoSelection} onClick={() => setCorelationDrawer(true)} className="small">
              View Correlation
            </PrimaryButton>
          </Col>
        </Row>
        <h4>Or</h4>
        <h4>
          We have preselected top <b>{recommendedCount}</b> features for you, but you have the liberty to add many more!
        </h4>
        <Form.Item name="feature_parent">
          <Form.List name="salient_features">
            {(fields, { add, remove }) => {
              return (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue, setFieldsValue }) => {
                    return (
                      <div className={`${autoSelection && Style.disabledFeatureContainer}`}>
                        <Row style={{ marginTop: "1rem" }}>
                          <Col span={10}>
                            <Checkbox onChange={(e) => selectAllFeatures(getFieldValue, setFieldsValue, e.target.checked)}>
                              {" "}
                              Select All
                            </Checkbox>
                          </Col>
                          <Col span={4}>
                            <b>Strength</b>
                          </Col>
                          <Col span={10}>
                          <Row justify="space-between">
                            <Col><b>Importance</b></Col>
                            <Col>
                              <span className={Style.progressBar}>
                                <span className={Style.polarityLegend}></span>&nbsp; Positive&nbsp;&nbsp;
                                <span style={{ background: "#d97979" }} className={Style.polarityLegend}></span>&nbsp; Negative
                              </span>
                            </Col>
                          </Row>
                          </Col>
                          
                        </Row>
                        <div className={`${Style.featureContainer} ${autoSelection && Style.disabledFeatureContainer}`}>
                          {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                            let item = getFieldValue(["salient_features", idx]);
                            let featureRedableName = item?.feature_readable_name;
                            let featureScore = item?.score_normalized;
                            let polarity = item?.polarity;
                            let strength = item?.correlation_level_with_target;

                            return (
                              <>
                                <Row>
                                  <Col span={10}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "is_user_selected"]}
                                      fieldKey={[fieldKey, "is_user_selected"]}
                                      valuePropName="checked"
                                    >
                                      <Checkbox>{featureRedableName}</Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col span={4}>{strength ? strength : "N/A"}</Col>
                                  <Col span={10}>
                                    <Form.Item>
                                      <div className={Style.progressBar}>
                                        <Progress
                                          percent={featureScore}
                                          format={(percent) => percent}
                                          strokeColor={polarity === "negative" ? "#d97979" : "#91dd91"}
                                        />
                                      </div>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form.Item>
        <ConditionalRender shouldRender={!autoSelection}>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const salientFeature = getFieldValue("salient_features");
              let count = 0;
              salientFeature?.forEach((item) => {
                if (item?.is_user_selected) {
                  count++;
                }
              });
              return (
                <h3 style={{ marginTop: "1rem" }}>
                  <i>
                    You have selected <b>{count}</b> features for clustering
                  </i>
                </h3>
              );
            }}
          </Form.Item>
        </ConditionalRender>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            return (
              <Drawer
                open={corelationDrawer}
                onClose={() => setCorelationDrawer(false)}
                title="Correlation Matrix"
                width="75%"
                destroyOnClose
              >
                <CorrelationChart getFieldValue={getFieldValue} clusterConfigId={clusterConfigId} />
              </Drawer>
            );
          }}
        </Form.Item>
      </Form>
    </ConditionalRender>
  );
}
