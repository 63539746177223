import React from "react";
import useCrossFeatureView from "./useCrossFeatureView";
import LoadingSpinner from "src/components/loadingSpinner";
import { Col, Empty, Row, Select, Spin } from "antd";
import ReactECharts from "echarts-for-react";
import { PrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import { DownloadOutlined } from "@ant-design/icons";

const CrossFeatureView = ({ clusterData }) => {
  const {
    loading,
    handlePopupScroll,
    handleSearch,
    loadingFeature1,
    feature1Data,
    loadingFeature2,
    handleSearchFeature2,
    handlePopupScrollFeature2,
    feature2Data,
    selectedData,
    setSelectedData,
    getFeatureMeanInfo,
    chartOptions,
    chartLoading,
    chartref,
    downloadChart,
    keyFeature,
    setKeyFeature,
    keyFeaturesData,
  } = useCrossFeatureView({ clusterData });
  if (loading) return <LoadingSpinner centered />;
  return (
    <div>
      <h3>Cross Feature View</h3>

      <h4>
        {clusterData?.clusters_type === "CRM"
          ? "Compare cluster means by selecting features for the X and Y axes, with bubble size representing a third feature"
          : "Compare cluster means by selecting features for the X and Y axes. Bubble size represents the cluster size"}
      </h4>
      <h4>Select Features for X & Y Axis.</h4>
      <Row gutter={[20, 20]}>
        <Col>
          <Select
            style={{ width: "265px" }}
            showSearch
            showArrow
            placeholder="Select Feature"
            autoClearSearchValue={false}
            onPopupScroll={handlePopupScroll}
            notFoundContent={<></>}
            value={selectedData?.feature1}
            onChange={(value) => {
              setSelectedData({ ...selectedData, feature1: value });
              getFeatureMeanInfo(value, selectedData?.feature2, keyFeature);
            }}
            onSearch={handleSearch}
            dropdownRender={(menu) => {
              return (
                <>
                  {feature1Data?.data?.length === 0 && !loadingFeature1 ? (
                    <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                  ) : (
                    <>
                      {menu}
                      {loadingFeature1 && (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            }}
          >
            {feature1Data?.data?.map((elem, index) => {
              return (
                <Select.Option key={index} value={elem}>
                  {elem}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <Select
            style={{ width: "265px" }}
            showSearch
            showArrow
            placeholder="Select Feature"
            autoClearSearchValue={false}
            onPopupScroll={handlePopupScrollFeature2}
            notFoundContent={<></>}
            onSearch={handleSearchFeature2}
            value={selectedData?.feature2}
            onChange={(value) => {
              setSelectedData({ ...selectedData, feature2: value });
              getFeatureMeanInfo(selectedData?.feature1, value, keyFeature);
            }}
            dropdownRender={(menu) => {
              return (
                <>
                  {feature2Data?.data?.length === 0 && !loadingFeature2 ? (
                    <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                  ) : (
                    <>
                      {menu}
                      {loadingFeature2 && (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            }}
          >
            {feature2Data?.data?.map((elem, index) => {
              return (
                <Select.Option key={index} value={elem}>
                  {elem}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <ConditionalRender shouldRender={clusterData?.clusters_type === "CRM"}>
          <Col>
            <Select
              style={{ width: "265px" }}
              showSearch
              showArrow
              allowClear
              placeholder="Select Core Feature"
              autoClearSearchValue={false}
              value={keyFeature}
              onChange={(value) => {
                setKeyFeature(value);
                getFeatureMeanInfo(selectedData?.feature1, selectedData?.feature2, value);
              }}
            >
              {keyFeaturesData?.map((elem, index) => {
                return (
                  <Select.Option key={`${elem}-${index}`} value={elem}>
                    {elem}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </ConditionalRender>
      </Row>
      <ConditionalRender shouldRender={!!chartOptions}>
        <ConditionalRender shouldRender={!chartLoading}>
          <Row justify="end" style={{ marginRight: "4.5rem" }}>
            <Col onClick={downloadChart} style={{ cursor: "pointer" }}>
              <DownloadOutlined />
              &nbsp;Download Chart
            </Col>
          </Row>
          <ReactECharts ref={chartref} option={chartOptions} style={{ height: 550, marginTop: "1rem" }} />
        </ConditionalRender>
        <ConditionalRender shouldRender={chartLoading}>
          <LoadingSpinner centered />
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender shouldRender={!!!chartOptions}>
        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
      </ConditionalRender>
    </div>
  );
};

export default CrossFeatureView;
