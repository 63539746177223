import { css } from "@linaria/core";

export const formContainer = css`
  width: 100%;
`;

export const formHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const formTitle = css`
  font-size: 18px;
  color: var(--color-white);
  margin: 0;
`;

export const emptyState = css`
  text-align: center;
  padding: 48px 24px;
  background-color: var(--color-90-black);
  border-radius: 8px;
  color: var(--color-60-gray);
`;

export const columnHeaders = css`
  display: flex;
  gap: 16px;
  padding: 0 12px 8px;
  border-bottom: 1px solid var(--color-80-black);
  margin-bottom: 16px;
`;

export const headerColumn = css`
  color: var(--color-60-gray);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;

  &:nth-child(1) {
    width: 200px;
    flex-shrink: 0;
  }

  &:nth-child(2) {
    flex: 1;
  }

  &:nth-child(3) {
    width: 120px;
    flex-shrink: 0;
  }
`;

export const headerAction = css`
  width: 32px;
`;

export const scrollableContainer = css`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 4px;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-90-black);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-70-black);
    border-radius: 3px;
  }
`;

export const columnsContainer = css`
  background-color: var(--color-90-black);
  border-radius: 8px;
  padding: 16px;
  /* Add min-height to prevent container collapse */
  min-height: 200px;
`;

export const inputColumnRow = css`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  transition: background-color 0.2s ease;
  margin-bottom: 10px;

  .ant-form-item {
    margin-bottom: 0;
    position: relative;
  }

  .ant-form-item:nth-child(1) {
    width: 200px;
    flex-shrink: 0;
  }

  .ant-form-item:nth-child(2) {
    flex: 1;
    min-width: 0;
  }

  .ant-form-item:nth-child(3) {
    width: 120px;
    flex-shrink: 0;
  }

  .ant-form-item-explain {
    position: absolute;
    top: calc(100% + 4px); /* Adjust positioning */
    left: 0;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1; /* Ensure validation shows above other elements */
  }

  .ant-select,
  .ant-input {
    background-color: var(--color-80-black);
    color: var(--color-white);
    transition: border-color 0.2s ease;

    &:hover {
      border-color: var(--color-link);
    }
  }

  .ant-select-focused .ant-select-selector,
  .ant-input:focus {
    border-color: var(--color-link) !important;
    box-shadow: 0 0 0 2px rgba(220, 90, 75, 0.2) !important;
  }
`;

export const columnSelect = css`
  width: 100% !important;
`;

export const columnDescription = css`
  width: 100%;
`;

export const columnTypeSelect = css`
  min-width: 130px !important;
`;

export const deleteIcon = css`
  color: var(--color-tag-red-txt);
  cursor: pointer;
  font-size: 16px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  height: 32px;
  transition: color 0.2s ease;
  margin-top: 10px;

  &:hover {
    color: var(--color-tag-red-border);
  }
`;

export const deleteIconVisible = css`
  opacity: 1;
`;

export const addColumnButton = css`
  background-color: var(--color-link);
  border: none;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--color-primary-tint-90);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const errorList = css`
  margin-top: 16px;
  color: var(--color-tag-red-txt);
  padding: 8px 12px;
  background-color: var(--color-80-black);
  border-radius: 4px;
  border: 1px solid var(--color-tag-red-txt);
`;

export const titleContainer = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const infoIcon = css`
  color: var(--color-60-gray);
  font-size: 14px;
  cursor: help;
  transition: color 0.2s ease;

  &:hover {
    color: var(--color-link);
  }
`;
