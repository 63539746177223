import { useState, useEffect } from 'react';

const useClusterPersonas = () => {
  const [tab, setTab] = useState('demographic');

  const onTabSwitch = (tab) => {
    setTab(tab);
  }

  return {
    tab,
    onTabSwitch
  }
};

export default useClusterPersonas;