/* eslint-disable */
const DOMAIN = `${APP_PREFIX}.${APP_SUFFIX}/api/v1`;
const BASE_URL = `https://${DOMAIN}`;

const LOGIN = `${BASE_URL}/login`;
const PASSWORD_RESET = `${BASE_URL}/password/reset`;
const LOGOUT = `${BASE_URL}/logout`;
const OKTA_LOGIN = `${BASE_URL}/okta/authorization-code/callback`;

const META_DATA = `${BASE_URL}/metadata`;

const GET_CHAT_LIST = (pageNum = 1, pageSize = 10) =>
  `${BASE_URL}/ai_app/chat/list?page_size=${pageSize}&page=${pageNum}`;

const GET_CHAT_RESPONSE = (mode) =>
  `${BASE_URL}/ai_app/chat/stream?test_mode=${mode}`;

const STREAM_IN_PROGRESS_CHAT_RESPONSE = (chatID) =>
  `${BASE_URL}/ai_app/chat/stream-in-progress?chat_id=${chatID}`;

const TERMINATE_IN_PROGRESS_CHAT = (chatID) =>
  `${BASE_URL}/ai_app/chat/stream?chat_id=${chatID}`;

const GET_CONVERSATION_STARTERS = `${BASE_URL}/ai_app`;

const POST_FEEDBACK = `${BASE_URL}/ai_app/chat/feedback`;

const DELETE_CHAT_HISTORY = `${BASE_URL}/ai_app/chat/clear`;

const GET_ANALYSIS = (chatId) =>
  `${BASE_URL}/ai_app/chat/detail?chat_id=${chatId}`;

const POST_FILE = `${BASE_URL}/ai_app/chat/file/upload`;

const ARCHIVE_FILE = (id) => `${BASE_URL}/ai_app/file/archive?file_id=${id}`;

const GET_FILE_LIST = `${BASE_URL}/ai_app/file/upload/list`;

const GET_CUSTOM_APP_META_DATA = `${BASE_URL}/custom_app/metadata`;

const CREATE_CUSTOM_APP = `${BASE_URL}/custom_app`;

const EDIT_CUSTOM_GPT = `${BASE_URL}/custom_app/edit`;

const SQL_CONNECTION_STRING = (clientSpaceId) =>
  `${BASE_URL}/sql/connections?client_space_id=${clientSpaceId}`;

const CONFIGURE_CAPABILITIES = `${BASE_URL}/custom_app/capability`;

const CAPABILITY_ACTIVATION = `${BASE_URL}/custom_app/capability/activation`;

const GET_LIST_USERS = `${BASE_URL}/ai_app/users`;

const CHANGE_ROLE = `${BASE_URL}/ai_app/user`;

const GOOGLE_DRIVE_LIST = `${BASE_URL}/gdrive/validate-folders`;

const FEEDBACK_LIST = (pageNo, page_size = 50) =>
  `${BASE_URL}/ai_app/interactions/feedbacks/list?page=${pageNo}&page_size=${page_size}`;

const RESOLVE_FEEDBACK = `${BASE_URL}/ai_app/feedback/resolve`;

const GET_SESSION_LIST = (type, page, pageSize = "10") => {
  const params = new URLSearchParams();
  if (type) {
    params.set("session_type", type);
  }
  if (page) {
    params.set("page", page);
  }
  if (pageSize) {
    params.set("page_size", pageSize);
  }
  return `${BASE_URL}/ai_app/session/list?${params}`;
};

const GET_GOOGLE_SHEET_SCHEMA = `${BASE_URL}/gdrive/gsheet-schema`;

const CREATE_SESSION = `${BASE_URL}/ai_app/session`;

const EDIT_SESSION = `${CREATE_SESSION}/edit`;

const GET_FEEDBACK_SESSION = (chatId, prev, next) =>
  `${BASE_URL}/ai_app/chat/list/surrounding-messages?chat_message_id=${chatId}&previous_n_messages=${prev}&next_n_messages=${next}`;

const CLIENT_LIST_HOME = `${BASE_URL}/client-space/list`;

const CLIENT_LIST = (id) =>
  `${BASE_URL}/client-space/apps/list?client_space_id=${id}`;

const GET_CLUSTER_LISTING = (
  id,
  pageNum,
  name,
  pageSize = 10,
  clusterState
) => {
  const params = new URLSearchParams();
  if (id) {
    params.set("client_space_id", id);
  }
  if (name) {
    params.set("clusters_search_term", name);
  }
  if (pageNum) {
    params.set("page", pageNum);
  }
  if (pageSize) {
    params.set("page_size", pageSize);
  }
  if (clusterState) {
    params.set("cluster_state", clusterState);
  }
  return `${BASE_URL}/clusters-config/list?${params}`;
};

const GET_SQL_CONNECTIONS = (id) =>
  `${BASE_URL}/sql/connections?client_space_id=${id}`;

const GET_SQL_SCHEMA = (id, connectionId) => {
  const params = new URLSearchParams();
  if (id) {
    params.set("client_space_id", id);
  }
  if (connectionId) {
    params.set("sql_connection_id", connectionId);
  }
  return `${BASE_URL}/sql/connections/meta?${params}`;
};

const GET_SQL_TABLE = (id) =>
  `${BASE_URL}/clusters-config/tables?client_space_id=${id}`;

const GET_CLUSTER_CONFIG = (id) =>
  `${BASE_URL}/clusters-config?cluster_config_id=${id}`;

const GET_AUDIENCE_SIZE = (id, tableName, clusterType) =>
  `${BASE_URL}/clusters-config/table/count?client_space_id=${id}&input_table=${tableName}&clusters_type=${clusterType}`;

const CREATE_CLUSTER_CONFIG = `${BASE_URL}/clusters-config`;

const GENERATE_CLUSTER_CONFIG = `${CREATE_CLUSTER_CONFIG}/generate`;

const DELETE_CLUSTER_CONFIG = (id) =>
  `${BASE_URL}/clusters-config/archive?cluster_config_id=${id}`;

const GET_CONNECTION_LEVEL_DATA = (connectionId) =>
  `${BASE_URL}/ai_app/capability/sql/connection?connection_id=${connectionId}`;

const GET_STRATEGY_CAPABILITY = `${BASE_URL}/custom_app/capability/get`;

const POST_CONNECTION_LEVEL_DATA = `${BASE_URL}/ai_app/capability/sql/connection`;

const LIST_NEWS_ANALYSIS = `${BASE_URL}/news-analysis-config/list?page_size=12`;

const CREATE_ANALYSIS = `${BASE_URL}/news-analysis-config`;

const UPDATE_ANALYSIS = `${BASE_URL}/news-analysis-config`;

const ADD_QUERY = `${BASE_URL}/news-analysis-config/lucene-query`;

const GENERATE_ANALYSIS = `${BASE_URL}/news-analysis-config/generate`;

const GET_ANALYSIS_CONFIG = (id) =>
  `${BASE_URL}/news-analysis-config?analysis_id=${id}`;

const GET_ANALYSIS_CATEGORIES = `${BASE_URL}/news-analysis-config/categories`;

const GET_ANALYSIS_CONFIG_STATS = (id) =>
  `${BASE_URL}/news-analysis-config/stats?analysis_id=${id}`;

const GET_ANALYSIS_CONFIG_OVERLAPPING_REGIONS = (id, column_name) =>
  `${BASE_URL}/news-analysis-config/overlapping-regions?analysis_id=${id}&column_name=${column_name}`;

const SYNC_COLUMNS = (connectionId) =>
  `${BASE_URL}/ai_app/capability/sql/connection/sync-column-index?connection_id=${connectionId}`;

const VALIDATE_VIRTUAL_TABLE = (connectionId) =>
  `${BASE_URL}/ai_app/capability/sql/connection/validate-virtual-table?connection_id=${connectionId}`;

const GET_ACTIVATION_GROUPS = (configId) =>
  `${BASE_URL}/clusters-config/activation-group/list?cluster_config_id=${configId}`;

const CREATE_EDIT_ACTIVATION_GROUP = `${BASE_URL}/clusters-config/activation-group`;

const GET_ANALYSIS_CONFIG_RAW_DATA = `${BASE_URL}/news-analysis-config/details`;

const EVALUATION_LIST_DATASET = (appId, pageNum, pageSize) =>
  `${BASE_URL}/evaluation/dataset?ai_app_id=${appId}&page=${pageNum}&page_size=${pageSize}`;

const EVALUATION_DATASET = (appId) =>
  `${BASE_URL}/evaluation/dataset?ai_app_id=${appId}`;

const GET_EVALUATION_DATASET = (datasetId) =>
  `${BASE_URL}/evaluation/dataset/detail?dataset_id=${datasetId}`;

const EVALUATION_LIST = (appId, pageNum, pageSize) =>
  `${BASE_URL}/evaluation?ai_app_id=${appId}&page=${pageNum}&page_size=${pageSize}`;

const EVALUATION_TRIGGER = (datasetId) =>
  `${BASE_URL}/evaluation?dataset_id=${datasetId}`;

const GET_EVALUATION = (evaluationId) =>
  `${BASE_URL}/evaluation/detail?evaluation_id=${evaluationId}`;

const GET_DASH_SSO = (clientSpaceId) =>
  `${BASE_URL}/dash/sso?client_space_id=${clientSpaceId}`;

const GET_MPT_SSO = (clientSpaceId) =>
  `${BASE_URL}/mediaplan/sso?client_space_id=${clientSpaceId}`;

const GET_KNOWLEDGE_GRAPH = `${BASE_URL}/news-analysis-config/knowledge-graph`;

const GET_KNOWLEDGE_GRAPH_STATS = `${BASE_URL}/news-analysis-config/knowledge-graph-stats`;

const GET_KNOWLEDGE_GRAPH_SUMMARY = `${BASE_URL}/news-analysis-config/knowledge-graph-summary`;

const GET_OPPORTUNITIES = `${BASE_URL}/news-analysis-config/opportunity/list`;

const GET_CUSTOM_OPPORTUNITIES = `${BASE_URL}/news-analysis-config/opportunity/custom-opportunity/list`;

const CREATE_CUSTOM_OPPORTUNITIES = `${BASE_URL}/news-analysis-config/opportunity/custom-opportunity`;

const OPPORTUNITY_OPERATIONS = `${BASE_URL}/news-analysis-config/opportunity`;

const PIN_UNPIN_OPPORTUNITIES = `${BASE_URL}/news-analysis-config/opportunity/pin`;

const FEEDBACK_OPPORTUNITIES = `${BASE_URL}/news-analysis-config/opportunity/feedback`;

const NODE_TRENDS = `${BASE_URL}/news-analysis-config/nodes/trends`;

const POST_QUADRANTS = `${BASE_URL}/news-analysis-config/quadrants`;

const TREND_QUADRANTS = `${BASE_URL}/news-analysis-config/quadrants/trend`;

const ARCHIVE_APP = `${BASE_URL}/ai_app/archive`;

const GET_NODES_OPTIONS = `${BASE_URL}/news-analysis-config/filters`;

const LIST_FILTERS = `${BASE_URL}/news-analysis-config/filter/list`;

const SAVE_DELETE_FILTER = `${BASE_URL}/news-analysis-config/filter`;

const GET_CLUSTER_FEATURES = (pageNum, pageSize = 30) =>
  `${BASE_URL}/clusters-config/cluster-info/feature-mean/search?page=${pageNum}&page_size=${pageSize}`;

const GET_CLUSTER_MEAN_INFO = `${BASE_URL}/clusters-config/cluster-info/feature-mean`;

const GET_CLUSTER_FEATURE_MEAN_PARENT = `${BASE_URL}/clusters-config/cluster-info/feature-mean/parent`;

const DOWNLOAD_REPORTS = `${BASE_URL}/clusters-config/download-report`;

const CLUSTER_FEATURE_CORRELATION = `${BASE_URL}/clusters-config/feature-correlation`;

const GET_CLUSTER_CLONE_NAME = (clusterId) =>
  `${BASE_URL}/clusters-config/clone-name?cluster_config_id=${clusterId}`;

const CLONE_CLUSTER = (name, clusterId) =>
  `${BASE_URL}/clusters-config/clone?cluster_config_id=${clusterId}&clone_clusters_config_name=${name}`;

const GET_CLUSTER_CORE_FEATURES = (clusterId) => `${BASE_URL}/clusters-config/cluster-info/core-features?cluster_config_id=${clusterId}`;

// Api to return audience recommendations
const GET_AUDIENCE_RECOMMENDATIONS = `${BASE_URL}/audience/recommendation`;

// Api to return meta data for Audience
const GET_AUDIENCE_META_DATA = (clientSpaceId, tableName) =>
  `${BASE_URL}/audience/features-metadata?client_space_id=${clientSpaceId}&cached=true`;

// Api to return rules recommendations for Audiemce
const GET_AUDIENCE_RULE_RECOMMENDATIONS = `${BASE_URL}/audience/recommend-rules`;

// Api to create Audience
const CREATE_AUDIENCE = `${BASE_URL}/audience`;

// Api to get list of Audience
const GET_AUDIENCE_LIST = (
  clientSpaceId,
  pageNum = 1,
  pageSize = 10,
  searchName,
  filterType,
  sortBy,
  ascending = true,
  statusFilter
) => {
  const params = new URLSearchParams();

  params.set("client_space_id", clientSpaceId);
  params.set("page", pageNum);
  params.set("page_size", pageSize);
  if (searchName.length > 0) {
    params.set("search", searchName);
  }
  if (filterType.length > 0) {
    params.set("audience_type", filterType);
  }
  if (sortBy.length > 0) {
    params.set("order_by", sortBy);
  }
  if (statusFilter.length > 0) {
    params.set("audience_state", statusFilter);
  }
  params.set("ascending", ascending);

  return `${BASE_URL}/audience/list?${params}`;
};

// Api to get Audience
const GET_AUDIENCE_BY_ID = (audienceId) =>
  `${BASE_URL}/audience?audience_id=${audienceId}`;

// Api to get feature profiles of Audience
const GET_AUDIENCE_FEATURE_PROFILES = (audienceId, orderBy, featureName, currentPage = 1, featureType, groupByParent, pageSize) => {
  const params = new URLSearchParams();
  params.set("page", currentPage);
  params.set("audience_id", audienceId);
  if (orderBy && orderBy.length > 0) {
    params.set("order_by", orderBy);
  }
  if (featureName && featureName.length > 0) {
    params.set("feature_name", featureName);
  }
  if (groupByParent) {
    params.set("group_by_parent", groupByParent);
  }
  if (featureType && featureType.length > 0) {
    params.set("profile_type", featureType);
  }
  if (pageSize) {
    params.set("page_size", pageSize);
  }

  return `${BASE_URL}/audience/feature-profiles/list?${params}`;
};

// Api to replace Behaviour feature in audience profile
const REPLACE_BEHAVIOUR_FEATURE = () =>
  `${BASE_URL}/audience/behavioural-profile/replace`;

const GET_AUDIENCE_SEARCH_FEATURE = (clientId, searchKey) =>
  `${BASE_URL}/audience/search-features?client_space_id=${clientId}&search_key=${searchKey}`;

// Api to get audience recommendation
const GET_RECOMMENDED_MEDIA_PLAN = (id) =>
  `${BASE_URL}/mediaplan/response/get?response_id=${id}`;
// Api to Request Mediaplan Reccomendation
const CREATE_MEDIAPLAN_RECOMMENDATION = `${BASE_URL}/mediaplan`;

// Api to get Media Plans listing
const GET_MEDIA_PLAN = (clientId, page) =>
  `${BASE_URL}/mediaplan/list?client_space_id=${clientId}&page=${page}&page_size=10`;

const GET_CLIENT_AUDIENCES_RECOMMENDATIONS = (
  clientSpaceId,
  useCache,
  refreshPrompt
) =>
  `${BASE_URL}/audience/recommend-audience?client_space_id=${clientSpaceId}&use_cache=${useCache}${refreshPrompt.length > 0 ? `&user_prompt=${refreshPrompt}` : ""
  }`;

//Api to get Audience Size
const GET_CREATE_AUDIENCE_SIZE = (clientId) =>
  `${BASE_URL}/audience/audience-size?client_space_id=${clientId}`;

// Api to archive Audience
const ARCHIVE_AUDIENCE = (audienceId, action) =>
  `${BASE_URL}/audience/archive?audience_id=${audienceId}&archive=${action}`;

// Api to get audience behaviour probability
const GET_AUDIENCE_BEHAVIOUR_PROBABILITY = (audienceId) =>
  `${BASE_URL}/audience/behavioural-probability?audience_id=${audienceId}`;

// Api to get audience behaviour directory
const GET_AUDIENCE_BEHAVIOURAL_DIRECTORY = `${BASE_URL}/audience/behavioural-directory`;

// Labeler URLs
const LABELING_TASKS = (clientSpaceId) =>
  `${BASE_URL}/client-spaces/${clientSpaceId}/labeling-tasks/`;

const LABELING_TASK_WS_URL = (clientSpaceId) => {
  // Check if the current page is served over HTTPS
  const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
  // Create the WebSocket URL dynamically based on the protocol
  return `${wsProtocol}://${DOMAIN}/ws/labeling-tasks/${clientSpaceId}/`;
};

const CLIENT_SPACE_META_DATA = `${BASE_URL}/client-space/metadata`;

const CREATE_CLIENT_SPACE = `${BASE_URL}/client-space/create-update`;

const CLIENT_SPACE_POST_ACCESS = `${BASE_URL}/client-space/access/create-update`;

const CLIENT_SPACE_POST_APPS = `${BASE_URL}/client-space/micro-apps/create-update`;

const GET_CLIENT_DETAILS = (id) => `${BASE_URL}/client-space/details?client_space_id=${id}`;

const GET_CLIENT_APP_DETAILS = (id) => `${BASE_URL}/client-space/micro-apps/list?client_space_id=${id}`;

const GET_CLIENT_ACCESS_DETAILS = (id) => `${BASE_URL}/client-space/access/list?client_space_id=${id}`;

const DELETE_CLIENT_ACCESS_USER = (id) => `${BASE_URL}/client-space/access/delete?access_id=${id}`;

const ADD_UPDATE_USER_MICRO_APP = `${BASE_URL}/client-space/micro-apps/roles/create-update`;;

const DELETE_USER_MICRO_APP = (id) => `${BASE_URL}/client-space/micro-apps/roles/delete?access_id=${id}`;

export default {
  LOGIN,
  PASSWORD_RESET,
  OKTA_LOGIN,
  META_DATA,
  GET_CHAT_LIST,
  GET_CHAT_RESPONSE,
  GET_CONVERSATION_STARTERS,
  POST_FEEDBACK,
  DELETE_CHAT_HISTORY,
  GET_ANALYSIS,
  LOGOUT,
  POST_FILE,
  ARCHIVE_FILE,
  GET_FILE_LIST,
  GET_CUSTOM_APP_META_DATA,
  CREATE_CUSTOM_APP,
  EDIT_CUSTOM_GPT,
  SQL_CONNECTION_STRING,
  CONFIGURE_CAPABILITIES,
  CAPABILITY_ACTIVATION,
  GET_LIST_USERS,
  CHANGE_ROLE,
  GOOGLE_DRIVE_LIST,
  STREAM_IN_PROGRESS_CHAT_RESPONSE,
  TERMINATE_IN_PROGRESS_CHAT,
  GET_SESSION_LIST,
  GET_GOOGLE_SHEET_SCHEMA,
  CREATE_SESSION,
  EDIT_SESSION,
  FEEDBACK_LIST,
  RESOLVE_FEEDBACK,
  GET_FEEDBACK_SESSION,
  CLIENT_LIST_HOME,
  CLIENT_LIST,
  GET_CLUSTER_LISTING,
  GET_SQL_CONNECTIONS,
  GET_SQL_SCHEMA,
  GET_SQL_TABLE,
  GET_CLUSTER_CONFIG,
  GET_AUDIENCE_SIZE,
  DELETE_CLUSTER_CONFIG,
  CREATE_CLUSTER_CONFIG,
  GENERATE_CLUSTER_CONFIG,
  GET_CLUSTER_CLONE_NAME,
  CLONE_CLUSTER,
  GET_CLUSTER_CORE_FEATURES,
  GET_CONNECTION_LEVEL_DATA,
  GET_STRATEGY_CAPABILITY,
  POST_CONNECTION_LEVEL_DATA,
  LIST_NEWS_ANALYSIS,
  CREATE_ANALYSIS,
  UPDATE_ANALYSIS,
  GENERATE_ANALYSIS,
  GET_ANALYSIS_CONFIG,
  GET_ANALYSIS_CATEGORIES,
  SYNC_COLUMNS,
  GET_ANALYSIS_CONFIG_STATS,
  VALIDATE_VIRTUAL_TABLE,
  GET_ACTIVATION_GROUPS,
  CREATE_EDIT_ACTIVATION_GROUP,
  GET_ANALYSIS_CONFIG_RAW_DATA,
  EVALUATION_LIST_DATASET,
  EVALUATION_DATASET,
  GET_EVALUATION_DATASET,
  EVALUATION_LIST,
  EVALUATION_TRIGGER,
  GET_EVALUATION,
  GET_DASH_SSO,
  GET_KNOWLEDGE_GRAPH,
  GET_KNOWLEDGE_GRAPH_SUMMARY,
  GET_MPT_SSO,
  GET_KNOWLEDGE_GRAPH_STATS,
  ARCHIVE_APP,
  GET_ANALYSIS_CONFIG_OVERLAPPING_REGIONS,
  ADD_QUERY,
  GET_NODES_OPTIONS,
  GET_OPPORTUNITIES,
  GET_CUSTOM_OPPORTUNITIES,
  PIN_UNPIN_OPPORTUNITIES,
  NODE_TRENDS,
  GET_CLUSTER_FEATURES,
  GET_CLUSTER_MEAN_INFO,
  GET_CLUSTER_FEATURE_MEAN_PARENT,
  DOWNLOAD_REPORTS,
  FEEDBACK_OPPORTUNITIES,
  LIST_FILTERS,
  SAVE_DELETE_FILTER,
  OPPORTUNITY_OPERATIONS,
  CLUSTER_FEATURE_CORRELATION,
  GET_AUDIENCE_RECOMMENDATIONS,
  CREATE_CUSTOM_OPPORTUNITIES,
  GET_AUDIENCE_META_DATA,
  GET_AUDIENCE_RULE_RECOMMENDATIONS,
  CREATE_AUDIENCE,
  GET_AUDIENCE_LIST,
  GET_AUDIENCE_BY_ID,
  GET_AUDIENCE_FEATURE_PROFILES,
  REPLACE_BEHAVIOUR_FEATURE,
  GET_CLIENT_AUDIENCES_RECOMMENDATIONS,
  GET_CREATE_AUDIENCE_SIZE,
  ARCHIVE_AUDIENCE,
  GET_AUDIENCE_BEHAVIOUR_PROBABILITY,
  GET_AUDIENCE_BEHAVIOURAL_DIRECTORY,
  GET_RECOMMENDED_MEDIA_PLAN,
  CREATE_MEDIAPLAN_RECOMMENDATION,
  GET_MEDIA_PLAN,
  LABELING_TASKS,
  LABELING_TASK_WS_URL,
  GET_AUDIENCE_SEARCH_FEATURE,
  CLIENT_SPACE_META_DATA,
  CREATE_CLIENT_SPACE,
  CLIENT_SPACE_POST_ACCESS,
  CLIENT_SPACE_POST_APPS,
  GET_CLIENT_DETAILS,
  GET_CLIENT_APP_DETAILS,
  GET_CLIENT_ACCESS_DETAILS,
  DELETE_CLIENT_ACCESS_USER,
  ADD_UPDATE_USER_MICRO_APP,
  DELETE_USER_MICRO_APP,
  POST_QUADRANTS,
  TREND_QUADRANTS,
};
