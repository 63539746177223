import React from "react";
import Style from "./clusterComparison.style";
import { Col, Row, Select } from "antd";
import { use } from "echarts";
import useClusterComparison from "./useClusterComparison";
import ConditionalRender from "src/components/conditionalRender";
import CrossFeatureView from "./CrossFeatureView";
import ValueByCluster from "./ValueByCluster";
import FeatureDistribution from "./FeatureDistribution";

export default function ClusterComparison({ clusterData }) {
  const { tab, onTabSwitch } = useClusterComparison();
  return (
    <>
      <Row gutter={[3,3]} style={{marginBottom: '1rem'}}>
        <Col>
          <div className={`${Style.tabs} ${tab === "cross_feature" && Style.activeTab}`} onClick={() => onTabSwitch("cross_feature")}>
            Cross Feature
          </div>
        </Col>
        <Col>
          <div className={`${Style.tabs} ${tab === "value_by_cluster" && Style.activeTab}`} onClick={() => onTabSwitch("value_by_cluster")}>
            Value by Cluster
          </div>
        </Col>
        <Col>
          <div className={`${Style.tabs} ${tab === "feature_distribution" && Style.activeTab}`} onClick={() => onTabSwitch("feature_distribution")}>
            Feature Distribution
          </div>
        </Col>
      </Row>
      <ConditionalRender shouldRender={tab === 'cross_feature'}>
        <CrossFeatureView  clusterData={clusterData} />
      </ConditionalRender>
      <ConditionalRender shouldRender={tab === 'value_by_cluster'}>
        <ValueByCluster clusterData={clusterData}/>
      </ConditionalRender>
      <ConditionalRender shouldRender={tab === 'feature_distribution'}>
        <FeatureDistribution clusterData={clusterData}/>
      </ConditionalRender>
    </>
  );
}
