export class ClusterToolServices {
  static formatCreateClusterConfigPayload(data, clientId) {
    const payload = {
      client_space_id: clientId,
      ...data,
    };
    return payload;
  }

  static formatInitialValuesFeatures(data) {
    let feature = [];
    let nonFeature = [];
    let nonActive = [];
    data.forEach((item) => {
      let obj = item;
      if (item?.is_invalid) {
        nonActive.push(obj);
      } else {
        if (item?.is_feature) {
          feature.push(obj);
        } else {
          nonFeature.push(obj);
        }
      }
    })
    return [...feature, ...nonFeature, ...nonActive];
  }
}
