import axios from "src/http/index";
import ApiUrls from "src/constants/apiUrls";

export class SqlToolApiServices {
  static async getSchema(id, connectionId) {
    const res = await axios.get(ApiUrls.GET_SQL_SCHEMA(id, connectionId));
    return res.data;
  }

  static async getSqlConnection(mAiApp, clientSpaceId) {
    const res = await axios.get(ApiUrls.SQL_CONNECTION_STRING(clientSpaceId), {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data
  }

  static async getConnectionLevelData(mAiApp, connectionId) {
    const res = await axios.get(ApiUrls.GET_CONNECTION_LEVEL_DATA(connectionId), {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }

  static async postCapibility(mAiApp, payload) {
    const res = await axios.post(ApiUrls.CONFIGURE_CAPABILITIES, payload, {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }

  static async postSqlConnectionLevelData(mAiApp, payload) {
    const res = await axios.put(ApiUrls.POST_CONNECTION_LEVEL_DATA, payload, {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }

  static async getConnectionLevelStrategy(payload, mAiApp) {
    const res = await axios.post(ApiUrls.GET_STRATEGY_CAPABILITY, payload, {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }

  static async syncColumns(mAiApp, connectionId) {
    const res = await axios.get(ApiUrls.SYNC_COLUMNS(connectionId), {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }

  static async validateVirtualTable(mAiApp, connectionId, payload) {
    const res = await axios.post(ApiUrls.VALIDATE_VIRTUAL_TABLE(connectionId), payload, {
      headers: {
        mAiApp: mAiApp
      }
    })
    return res.data;
  }
} 