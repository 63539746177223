import React, { useState, useEffect } from 'react';
import { Input, Modal } from 'antd';

const FeedbackModal = ({ modalOpen, closeModal, handleResolve, data }) => {

  const [feedbackComment, setFeedbackComment] = useState('');

  const handleOk = () => {
    handleResolve(data?.id, feedbackComment);
    closeModal();
    setFeedbackComment(''); // Clear the text area
  };

  const handleCancel = () => {
    closeModal();
    setFeedbackComment(''); // Clear the text area
  };

  const inputValue = (e) => {
    setFeedbackComment(e.target.value);
  };

  useEffect(() => {
    if (!modalOpen) {
      setFeedbackComment(''); // Clear the text area when modal is closed
    }
  }, [modalOpen]);

  return (
    <>
      <Modal
        title='Resolve Feedback'
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        mask={false}
      >
        {data && Object.keys(data).length ? (
          <div>
            <p><b>Question: </b>{data.question}</p>
            <p><b>Categories: </b>{data.category}</p>
            <p><b>Feedback: </b>{data.feedback}</p>
          </div>
        ) : (
          <p>No Data Available</p>
        )}
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 7 }}
          style={{ borderRadius: '5px' }}
          placeholder={'Please add your comment'}
          value={feedbackComment}
          onChange={inputValue}
        />
      </Modal>
    </>
  );
};

export default FeedbackModal;
