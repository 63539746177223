import { css } from '@linaria/core';

const card = css`
  &.ant-card-bordered {
    border-radius: 10px;
    .ant-card-body {
      padding: 24px 14px;
      max-height: calc(100vh - 238px);
      overflow-y: auto;
    }
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const columnLabel = css`
  color: gray;
  margin-bottom: 8px;
`;

const dataSourceLoadingSpinner = css`
  position: absolute;
  height:auto;
  top: 40px;
  right: 20px;
`;

const dataSourceContainer = css`
  position: relative;
`;

const breadCrumb = css`
  margin-bottom:16px !important;
`;

const audienceSize = css`
  margin-top: -10px;
  margin-bottom: 24px;
`;

export default {
  columnLabel,
  card,
  dataSourceContainer,
  dataSourceLoadingSpinner,
  breadCrumb,
  audienceSize
}