import { Form, Input, Typography } from "antd";
import React from "react";
import * as Styles from "./styles";

const { Text } = Typography;

const BasicInfoForm = ({ form }) => {
  return (
    <div className={Styles.formContainer}>
      {/* Guide section */}
      <div className={Styles.guideSection}>
        <h2 className={Styles.title}>Let's set up your Labeling analysis</h2>
        <Text type="secondary" className={Styles.subtitle}>
          Name and describe your analysis to help you identify it later.
        </Text>
      </div>

      {/* Form section */}
      <div className={Styles.formSection}>
        <Form.Item
          label="Analysis Name"
          name="name"
          required
          tooltip="Give your analysis a clear, descriptive name"
          rules={[
            { required: true, message: "Please enter the analysis name" },
          ]}
        >
          <Input
            placeholder="e.g. Product Image Classification, URL Content Analysis"
            className={Styles.input}
          />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          tooltip="Add details about what this analysis will analyze and produce"
        >
          <Input.TextArea
            rows={4}
            placeholder="Describe what this analysis will do, what kind of data it will process, and what results you expect..."
            className={Styles.textarea}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default BasicInfoForm;
