import { css } from "@linaria/core";

export const darkFormWrapper = css`
  .ant-picker,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .ant-input-number,
  .ant-input-affix-wrapper {
    background-color: #1f1f1f !important;
    color: white !important;
    border-color: var(--color-80-black) !important;
  }

  .ant-picker-input > input,
  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-input::placeholder,
  .ant-input-number-input::placeholder {
    color: rgba(255, 255, 255, 0.65) !important;
  }

  .ant-picker:hover,
  .ant-picker-focused,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-input:hover,
  .ant-input:focus,
  .ant-input-number:hover,
  .ant-input-number-focused,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper-focused {
    border-color: var(--color-70-black) !important;
  }

  .ant-select-arrow,
  .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-form-item-label > label {
    color: rgba(255, 255, 255, 0.85) !important;
  }

  .ant-form-item {
    margin-bottom: 24px;
  }

  .ant-form-item-has-error .ant-picker,
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled)
    .ant-select-selector,
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-input-affix-wrapper {
    background-color: #1f1f1f !important;
    border-color: #ff4d4f !important;
  }

  .ant-form-item-explain-error {
    color: #ff4d4f !important;
  }

  .ant-form-item-has-error .ant-picker:hover,
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):hover
    .ant-select-selector,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-number:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #ff7875 !important;
  }

  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-select-focused .ant-select-selector,
  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border-color: #ff7875 !important;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2) !important;
  }

  .ant-select .ant-select-arrow {
    color: var(--color-60-black) !important;
  }
`;
