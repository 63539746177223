import React from "react";
import useGoogleDriveCapapbilityHook from "src/modules/CustomGpt/hooks/useGoogleDriveCapapbilityHook";
import GoogleDriveLinks from "./GoogleDriveLinks";
import Style from "./googleDriveCapability.style";
import ConditionalRender from "src/components/conditionalRender";
import { Progress, Skeleton } from "antd";

export default function GoogleDriveCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { getGoogleDriveList, initialValues, loading, configureLoading, fileProgressData, copyToClipboard } = useGoogleDriveCapapbilityHook(
    {
      id,
      aiAppData,
      configData,
      updateCustomApp,
      onClose,
      toolData,
      reload,
    }
  );
  return (
    <>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <ConditionalRender shouldRender={fileProgressData}>
          <ConditionalRender shouldRender={fileProgressData?.status === "IN_PROGRESS" || fileProgressData?.status === "FETCHING_FILES"}>
            <div className={Style.fileprocessedContainer}>
              {fileProgressData?.status === "IN_PROGRESS" ? (
                <div>
                  File Processed{" "}
                  <span className={Style.fileProcessedCount}>{`${fileProgressData?.files_parsed}/${fileProgressData?.total_files}`}</span>
                </div>
              ) : (
                "Fetching Files"
              )}

              <Progress
                style={{ width: "139px" }}
                strokeColor={{
                  from: "#108ee9",
                  to: "#87d068",
                }}
                showInfo={false}
                percent={(fileProgressData?.files_parsed / fileProgressData?.total_files) * 100}
                status={fileProgressData?.status === "IN_PROGRESS" || fileProgressData?.status === "FETCHING_FILES" ? "active" : "normal"}
              />
            </div>
          </ConditionalRender>
          <ConditionalRender shouldRender={fileProgressData?.status === "DONE"}>
            <div className={Style.fileProcessedCompleted}>{`Synced all ${fileProgressData?.total_files} files.`}</div>
          </ConditionalRender>
        </ConditionalRender>
        <div className={Style.form}>
          <GoogleDriveLinks
            getGoogleDriveList={getGoogleDriveList}
            initialValues={initialValues}
            configData={configData}
            configureLoading={configureLoading}
            copyToClipboard={copyToClipboard}
          />
        </div>
      </ConditionalRender>
    </>
  );
}
