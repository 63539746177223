import React from "react";
import AreaChart from "./components/charts/AreaChart";
import FunnelChart from "./components/charts/FunnelChart";
import GanttChart from "./components/charts/GanttChart";
import PieChart from "./components/charts/PieChart";

const Charts = ({ data }) => {
  if (data.length === 0) {
    return false;
  }

  return (
    <>
      <div
        style={{
          border: "1px dashed #8d8d8d",

          marginBottom: "24px",
        }}
      >
        <AreaChart response={data} />
      </div>
      <div style={{ display: "flex", marginBottom: "24px" }}>
        <div
          style={{
            width: "50%",
            border: "1px dashed #8d8d8d",
          }}
        >
          <PieChart response={data} defaultSelection={"platform"} />
        </div>
        <div
          style={{
            width: "50%",
            border: "1px dashed #8d8d8d",

            marginLeft: "16px",
          }}
        >
          {/* <PieChart response={data} defaultSelection={"audience_name"} /> */}
          <FunnelChart response={data} />
        </div>
      </div>
      {/* <div style={{ display: "flex", marginBottom: "24px" }}>
        <div
          style={{
            width: "50%",
            border: "1px dashed #8d8d8d",
            
            paddingBottom: "16px",
          }}
        >
        </div>
       
      </div> */}
      <div style={{ display: "flex", marginBottom: "24px" }}>
        <div
          style={{
            width: "100%",
            border: "1px dashed #8d8d8d",

            paddingBottom: "16px",
          }}
        >
          <GanttChart response={data} />
        </div>
      </div>
    </>
  );
};

export default Charts;
