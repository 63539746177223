import { Button, DatePicker, Form, Select, TimePicker } from "antd";
import React from "react";
import * as Styles from "./styles";

const { Option } = Select;

const ScheduleTaskForm = ({ onScheduleSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onScheduleSubmit(values);
  };

  return (
    <div className={Styles.darkFormWrapper}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="startDate"
          label="Start Date"
          rules={[{ required: true, message: "Please select a start date" }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="startTime"
          label="Start Time"
          rules={[{ required: true, message: "Please select a start time" }]}
        >
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item
          name="repeatFrequency"
          label="Repeat Frequency"
          rules={[
            { required: true, message: "Please select a repeat frequency" },
          ]}
        >
          <Select>
            <Option value="NONE">Do not repeat</Option>
            <Option value="HOURLY">Hourly</Option>
            <Option value="DAILY">Daily</Option>
            <Option value="WEEKLY">Weekly</Option>
            <Option value="MONTHLY">Monthly</Option>
          </Select>
        </Form.Item>
        <Form.Item name="endDate" label="End Date (Optional)">
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            SCHEDULE TASK
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScheduleTaskForm;
