import { useEffect, useState } from "react";
import audienceListService from "../../services/audienceListService";

export default function useAudienceRecommendationHook(clientId) {
    const [recommendationLoading, setRecommendationLoading] = useState(false);
    const [audienceRecommendations, setAudienceRecommendations] = useState([]);
    const [refreshPrompt, setRefreshPrompt] = useState('');

    const fetchRecommendation = async (cache, refreshPrompt) => {
        try {
          setRecommendationLoading(true);
          const recommendations_res = await audienceListService.getAudienceRecommendations(clientId, cache, refreshPrompt);
          setAudienceRecommendations(recommendations_res.data);
          setRecommendationLoading(false);
        } catch (error) {
          setRecommendationLoading(false);
          console.error(error);
        }
    };

    useEffect(() => {
        fetchRecommendation(true,refreshPrompt);
    }, [clientId]);

    return {
        recommendationLoading,
        audienceRecommendations,
        fetchRecommendation,
        refreshPrompt,
        setRefreshPrompt,
    };

}