import { Checkbox, Col, Collapse, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { CustomGptService } from 'src/modules/CustomGpt/services/customGptServices';
import Style from './fineTuningTool.style';

export default function FineTuningAdvancedSetting({ configData }) {
  return (
    <div style={{marginTop:32}}>
      <Collapse className={'regularCollapse'}>
        <Collapse.Panel forceRender header="Advanced settings">
          <Row gutter={[30, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Name*"
                name="name"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Name",
                  configData?.config?.name
                )}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
              <Form.Item 
                valuePropName="checked"
                name="allow_system_prompt_injection"
              >
                <Checkbox style={{marginRight:8}}/>{'Allow system prompt injection'}
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="description"
                label="Description*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Description",
                  configData?.config?.description
                )}
              >
                <TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="strategy_summarizer_llm_prompt"
                label="Summarizer*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Summarizer",
                  configData?.config?.strategy_summarizer_llm_prompt
                )}
              >
                <TextArea autoSize={{ minRows: 6, maxRows: 8 }} />
              </Form.Item>
            </Col>
            
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}