import { message, notification } from 'antd';
import { useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { AiAppApiServices } from 'src/modules/aiApps/services/aiAppApiServices';

export default function useConversationResponseHook({mAiApp, chatId}) {
  const [viewAnalysis, setViewAnalysis] = useState(null);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const getAnalysis = async () => {
    try {
      setAnalysisLoading(true);
      const res = await AiAppApiServices.getAnalysis(mAiApp, chatId);
      setViewAnalysis(res?.data?.intermediate_steps);
      setAnalysisLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 4,
        placement: "bottomRight"
      })
    }
  }

  const openAnalysisAccordian = async (val) => {
    if (val.includes('analysis')) {
      if (!viewAnalysis) {
        await getAnalysis();
      }
    }
  }

  return {
    analysisLoading,
    viewAnalysis,
    openAnalysisAccordian,
  }
}