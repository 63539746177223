import { notification } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CustomGptApiServices from 'src/modules/CustomGpt/services/customGptApiServices';

export default function useGoogleSheetCapabilityHook({id, aiAppData, configData, updateCustomApp, onClose, toolData, reload}) {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false)
  const [schemaModal, setSchemaModal] = useState(false);

  const getInitialValues = () => {
    try {
      setLoading(true);
      const capabilityData = aiAppData?.capabilities?.find((ele) => ele.capability_name === "GoogleSheetCapability");
      if (capabilityData) {
        setInitialValues(capabilityData.fields_config);
      } else {
        setInitialValues({
          google_drive_structured_name: configData?.config?.google_drive_structured_name?.default_value,
          google_drive_structured_description: configData?.config?.google_drive_structured_description?.default_value,
          google_drive_structured_allow_system_prompt_injection: configData?.config?.google_drive_structured_allow_system_prompt_injection?.default_value,
          google_drive_get_file_info_name: configData?.config?.google_drive_get_file_info_name?.default_value,
          google_drive_get_file_info_description: configData?.config?.google_drive_structured_name?.default_value,
          google_drive_get_file_info_allow_system_prompt_injection: configData?.config?.google_drive_get_file_info_allow_system_prompt_injection?.default_value,
          set_schema_in_instruction_prompt: configData?.config?.set_schema_in_instruction_prompt?.default_value,
          max_results_per_query: configData?.config?.max_results_per_query?.default_value,
          max_characters_returned: configData?.config?.max_characters_returned?.default_value
        })
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const configureCapability = async (payload) => {
    try {
      setConfigureLoading(true);
      const formattedPayload = {
        fields_config: payload, capability_name: 'GoogleSheetCapability',
      }
      const res = await CustomGptApiServices.configureCapabilities(id, formattedPayload);
      updateCustomApp(res.data);
      notification.success({
        duration: 5,
        message: 'Google sheet capability configured successfully',
        placement: "bottomRight"
      })
      setConfigureLoading(false);
      reload();
    } catch (error) {
      setConfigureLoading(false);
      const {msg} = getErrorMessageFromResponse(error)
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }
  

  useEffect(() => {
    getInitialValues();
  }, [])
  return {
    formRef,
    initialValues,
    loading,
    configureCapability,
    configureLoading,
    schemaModal,
    setSchemaModal
  }
}