import React, { useContext, useEffect, useState } from 'react';
import Style from './loggedInLayout.style';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppUrls from 'src/constants/appUrls';
import HomePage from './homePage/components/homepage/HomePage';
import FixedTopBar from './fixedTopBar/FixedTopBar';
import ClientSpaceContainer from './clientSpace/ClientSpaceContainer';
import Cookie from 'src/lib/cookie';
import Disclaimer from './disclaimer/Disclaimer';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import Footer from './footer/Footer';
import ConditionalRender from 'src/components/conditionalRender';
import AppContext from "src/context/appContext";
import { useHomePage } from './homePage/hooks/useHomePage';
import AddEditClientSpace from './addNewClientSpace/components/AddEditClientSpace';

function LoggedInLayout({ history , match}) {
  const appContext = React.useContext(AppContext.Context);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const {inChatMode, inClientSpaceMode } = useContext(PageLayoutContext);
  const { loading, allClients, addUpdateClient } = useHomePage({ appContext });

  useEffect(()=>{
    const cookies = Cookie.get('Disclaimer');
    if (cookies) {
      setOpenDisclaimer(false);
    } else {
      const d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      Cookie.set('Disclaimer', 'true', d);
      setOpenDisclaimer(true);
    }
  }, []);

  useEffect(()=>{
    if (!loading && allClients && allClients.length === 1 && !appContext.metaData?.email.includes("galepartners.com")) {
      history.push(AppUrls.CLIENT_HOME(allClients[0]?.id));
    }
  }, [loading, allClients]);


  const handleClose = () => {
    setOpenDisclaimer(false);
  };
  return (
    <>
      <div className={`${Style.contentContainer}`}>
        <FixedTopBar history={history} match={match}/>
        <div className={Style.content}>
          <Switch>
            <Route path={AppUrls.HOMEPAGE} children={<HomePage history={history} loading={loading} allClients={allClients}/>} /> 
            <Route path={AppUrls.CREATE_CLIENT} children={<AddEditClientSpace history={history} addUpdateClient={addUpdateClient}/>} allClients={allClients} />
            <Route
              path={AppUrls.CLIENT_HOME(':clientId')}
              children={<ClientSpaceContainer history={history} match={match} allClients={allClients} addUpdateClient={addUpdateClient}/>}
            />
            <Redirect from='*' to={AppUrls.HOMEPAGE} />
          </Switch>
        </div>
        <ConditionalRender shouldRender={!inChatMode}>
          <Footer transparent={false} relativePosition={inClientSpaceMode}/>      
        </ConditionalRender>
      </div>
        <Disclaimer modalOpen={openDisclaimer} closeModal={handleClose} />
    </>
  );
}

export default LoggedInLayout;
