import { useEffect, useState } from 'react';
import HomePageApiServices from '../services/homePageApiService';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';

export const useHomePage = ({ appContext }) => {
  const [loading, setLoading] = useState(true);
  const [allClients, setAllClients] = useState([]);

  const addUpdateClient = (data) => {
    const clientPresent = allClients.find((client) => client.id === data.id);
    if (clientPresent) {
      const index = allClients.indexOf(clientPresent);
      const tempArr = [...allClients];
      tempArr[index] = data;
      setAllClients(tempArr);
    } else {
      setAllClients([...allClients, data]);
    }
  }

  const getAllClients = async () => {
    try {
      appContext?.updateClientSpaceData(null);
      const res = await HomePageApiServices.getClients();
      if (res?.success) {
        setAllClients(res?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  useEffect(() => {
    if (allClients.length === 0 ) {
      getAllClients();
    }
  }, []);

  return {
    loading,
    allClients,
    addUpdateClient
  };
};
