import { notification } from 'antd';
import { useState, useEffect } from 'react';
import DatasetServices from '../services/datasetServices';

export default function useListEvaluationHooks({ appId }) {
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [evaluations, setEvaluations] = useState([]);
    
  const getEvaluations = async () => {
    setLoading(true);
    try {
      const res = await DatasetServices.listEvaluations(appId, pageNumber - 1);
      setEvaluations(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        duration: 5,
        message: "Failed to load Evaluations",
        placement: "bottomRight"
      });
    }
  };

  useEffect(() => {
    getEvaluations();
  }, [pageNumber]);

  return {
    loading,
    pageNumber,
    setPageNumber,
    evaluations,
    getEvaluations,
  }
}
