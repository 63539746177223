import { notification } from "antd";
import axios from "axios";
import { useState, useEffect, useRef, useContext } from "react";
import appUrls from "src/constants/appUrls";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { ClusterToolApiServices } from "src/modules/audienceTool/clusterTool/services/ClusterToolApiServices";
import { ClusterToolServices } from "../../services/ClusterToolServices";
import { CHILIS_ACQUISTION, CHILIS_CRM, CLUSTERING_TYPE, FOGO_ACQUISTION, FOGO_CRM } from "../../constants/constants";
import AppContext from "src/context/appContext";
import { set } from "dot-object";

export default function useCreateClusterHook({ match, history }) {
  const id = match?.params?.clientId;
  const clusterId = match?.params?.clusterId;
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [audienceCount, setAudienceCount] = useState(null);
  const [audienceSizeLoading, setAudienceSizeLoading] = useState(false);
  const [table, setTable] = useState([]);
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const appContext = useContext(AppContext.Context);
  const clientSpaceName = appContext?.clientSpaceData?.client_space?.name;
  const clusteringApp = appContext?.clientSpaceData?.micro_apps?.find(app => app.app_type === "CLUSTERING_APP");
  const clusteringConfig = clusteringApp?.app_config;
  const [editDescription, setEditDescription] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [nextButton, setNextButton] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);

  const clientSpaceDescription = {
    Chilis: {
      Acquisition: CHILIS_ACQUISTION,
      CRM: CHILIS_CRM,
    },
    Fogo: {
      Acquisition: FOGO_ACQUISTION,
      CRM: FOGO_CRM,
    },
  };

  const createClusterConfig = async (val) => {
    try {
      setConfigureLoading(true);
      let formattedPayload = ClusterToolServices.formatCreateClusterConfigPayload(val, id);
      if (clusterId) {
        formattedPayload = {
          ...formattedPayload,
          cluster_config_id: clusterId,
        }
      } 
      const res = await ClusterToolApiServices.createClusterConfig(formattedPayload);
      history.push(appUrls.SELECT_FEATURES(id, res.data.id));
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const getAudienceSize = async (tableName, clusterType) => {
    try {
      setAudienceSizeLoading(true);
      const res = await ClusterToolApiServices.getAudienceSize(id, tableName, clusterType);
      setAudienceCount(res.data.count);
      setAudienceSizeLoading(false);
    } catch (error) {
      setAudienceSizeLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  const getSqlTable = async (clientId) => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getSqlTable(clientId);
      let tempObj = {}
      if (clusteringConfig){
        tempObj = {
          description: clusteringConfig?.[CLUSTERING_TYPE[0]]
        }
        setInitialValues(tempObj);
      }
      setTable(res.data);  
      if (clusterId) {
        const clusterConfig = await ClusterToolApiServices.getClusterConfig(clusterId);
        setNextButton(true);
        if (clusterConfig?.data?.state === "IDENTIFYING_SALIENT_FEATURES" || clusterConfig?.data?.state === "CREATING_SAMPLE_CLUSTERS") {
          setViewMode(true);
        }
        if (clusterConfig.data.state === "DRAFT") {
          setEditMode(false);
        } else {
          setEditMode(true);
        }
        let clusterData = {
          input_table: clusterConfig.data.input_table,
          clusters_type: clusterConfig.data.clusters_type,
          name: clusterConfig.data.name,
          description: clusterConfig.data.description,
        };
        setInitialValues(clusterData);
        setAudienceCount(clusterConfig.data.input_table_count);
      } else {
        if (res.data.includes("customer_master_clustering_input")) {
          tempObj = {...tempObj, input_table: "customer_master_clustering_input", clusters_type: CLUSTERING_TYPE[0] };
          setInitialValues(tempObj);
          await getAudienceSize("customer_master_clustering_input", CLUSTERING_TYPE[0]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getSqlTable(id);
  }, []);

  return {
    loading,
    formRef,
    createClusterConfig,
    configureLoading,
    table,
    getAudienceSize,
    audienceCount,
    audienceSizeLoading,
    initialValues,
    clientSpaceName,
    editDescription,
    setEditDescription,
    clusteringConfig,
    editMode,
    viewMode,
    nextButton,
    id,
    clusterId,
    savedChanges,
    setSavedChanges
  }
}
