import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const PageLayoutContext = createContext({});

export const PageLayoutWrapper = ({ children }) => {
  const [inDesktop, seInDesktopn] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [inClientSpaceMode, setInClientSpaceMode] = useState(false);
  const [inChatMode, setInChatMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [inSingleAppMode, setInSingleAppMode] = useState(false);
  const [doneEditing, setDoneEditing] = useState(false);
  const [isAudienceMode, setIsAudienceMode] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      let isDesktopView = window.innerWidth >= 768;
      seInDesktopn(isDesktopView);
      setIsNavOpen(isDesktopView);
      setWindowWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PageLayoutContext.Provider
      value={{
        isNavOpen,
        setIsNavOpen,
        inDesktop, 
        seInDesktopn,
        windowWidth, 
        inClientSpaceMode, 
        setInClientSpaceMode,
        inChatMode,
        setInChatMode, 
        inSingleAppMode,
        setInSingleAppMode,
        doneEditing,
        setDoneEditing,
        isAudienceMode,
        setIsAudienceMode
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};

PageLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
