import { notification } from "antd";
import { debounce } from "lodash";
import { useState, useEffect, useCallback, useRef } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { BLUE_SHADES, CLUSTER_COLORS } from "src/modules/audienceTool/clusterTool/constants/constants";
import { ClusterToolApiServices } from "src/modules/audienceTool/clusterTool/services/ClusterToolApiServices";

const useFeatureDistribution = ({ clusterData }) => {
  const [loading, setLoading] = useState(true);
  const [feature1Page, setFeature1Page] = useState(1);
  const [loadingFeature1, setLoadingFeature1] = useState(false);
  const [queryFeature1, setQueryFeature1] = useState("");
  const [hasMore1, setHasMore1] = useState(true);
  const [chartLoading, setChartLoading] = useState(false);
  const [feature1Data, setfeature1Data] = useState({
    data: [],
    total: null,
    total_pages: null,
  });
  const [selectedData, setSelectedData] = useState({
    feature: "Education Level",
    cluster: clusterData?.clusters?.[0]?.id,
  });
  const [chartOptions, setChartOptions] = useState(null);
  const chartref = useRef();

  useEffect(() => {
    defaultLoad();
  }, []);

  const defaultLoad = async () => {
    try {
      setLoading(true);
      await getFeature1Data(1, clusterData?.clusters?.[0]?.id, "");
      await getParentFeatureMeanInfo(selectedData?.feature, clusterData?.clusters?.[0]?.id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleScrollToEnd = () => {
    if (hasMore1 && !loading && feature1Page < feature1Data.total_pages) {
      setFeature1Page((prevPage) => prevPage + 1);
      getFeature1Data(feature1Page + 1, selectedData?.cluster, queryFeature1);
    }
  };

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      handleScrollToEnd();
    }
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      setFeature1Page(1);
      setQueryFeature1(value);
      getFeature1Data(1, selectedData?.cluster, value);
    }, 800),
    []
  );

  const getFeature1Data = async (page, parentFeature, searchText = "") => {
    try {
      setLoadingFeature1(true);
      const payload = {
        clusters_config_id: clusterData?.id,
        search_column: "parent_feature_name",
        search_value: searchText,
        cluster_info_id: parentFeature,
      };
      const res = await ClusterToolApiServices.getClusterFeature(payload, page);
      const data = res.data;
      if (page === 1) {
        setfeature1Data({
          data: data.data,
          total: data.total,
          total_pages: data.total_pages,
        });
      } else {
        setfeature1Data((prevData) => ({
          data: [...prevData.data, ...data.data],
          total: data.total,
          total_pages: data.total_pages,
        }));
      }
      setLoadingFeature1(false);
      setHasMore1(data?.data?.length > 0);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoadingFeature1(false);
    } finally {
      setLoadingFeature1(false);
    }
  };

  const getParentFeatureMeanInfo = async (feature1, cluster) => {
    try {
      setChartLoading(true);
      const payload = {
        cluster_info_id: cluster,
        parent_feature_name: feature1,
      };
      const res = await ClusterToolApiServices.getClusterFeatureParentMeanInfo(payload);
      const formatOptions = formatChartData(res.data, feature1);
      setChartLoading(false);
    } catch (error) {}
  };

  const formatChartData = (data, feature1) => {
    if (data?.length > 0) {
      const feature2Category = data[0]?.feature_data_type === "categorical";
      let option = {
        xAxis: [
          {
            type: "category",
            data: data?.map((feature) => feature?.feature_name),
            axisLabel: {
              interval: 0,
              rotate: 30,
              hideOverlap: true, // hide this to show the overlap value
              formatter: (value) => {
                const len = value.length;
                const ellipsis = len > 17 ? "..." : "";
                return value.slice(0, 4) + ellipsis + value.slice(-13);
              },
              textStyle: {
                color: "#ffffff",
              },
            },
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `<b>${params?.name}</b><br/>${feature1}: ${params.value.toFixed(2)}`;
          },
        },
        yAxis: [
          {
            name: `${feature2Category ? "% of " : ""}${feature1.length > 40 ? feature1.substring(0, 60) + "..." : feature1}`,
            nameLocation: "middle",
            nameTextStyle: {
              fontSize: 14,
              padding: [0, 0, 30, 0],
              color: "#ffffff",
            },
            splitLine: {
              lineStyle: {
                color: "gray",
              },
            },
            type: "value",
            axisLabel: {
              textStyle: {
                color: "#ffffff",
              },
            },
          },
        ],

        color: ["#99CCFF"],
        series: data.map((item, idx) => {
          return {
            name: item?.feature_name,
            type: "bar",
            stack: "stack",
            data: data.map((item, index) => {
              if (index === idx) {
                return item?.mean_value;
              } else {
                return null;
              }
            }),
          };
        }),
      };

      setChartOptions(option);
    } else {
      setChartOptions(null);
    }
  };

  const downloadChart = () => {
    const echartsInstance = chartref.current.getEchartsInstance(); // Get the ECharts instance
    const imgURL = echartsInstance.getDataURL({
      type: "png", // You can specify 'jpeg' or 'svg' as well
      pixelRatio: 2, // Increase pixel ratio for better resolution
      backgroundColor: "black",
    });

    // Create a link element and trigger download
    const link = document.createElement("a");
    link.href = imgURL;
    link.download = "chart.png"; // Filename for the downloaded image
    link.click();
  };

  return {
    loading,
    handlePopupScroll,
    handleSearch,
    loadingFeature1,
    feature1Data,
    selectedData,
    setSelectedData,
    getParentFeatureMeanInfo,
    chartOptions,
    setChartOptions,
    getFeature1Data,
    setFeature1Page,
    chartLoading,
    downloadChart,
    chartref,
  };
};

export default useFeatureDistribution;
