import React, { useEffect } from "react";
import BackboneCapability from "./capabilityTools/backboneCapability/BackboneCapability";
import ChartingCapability from "./capabilityTools/chartingTool/ChartingCapability";
import DashboardCapability from "./capabilityTools/dashboardCapability/DashboardCapability";
import GoogleDriveCapability from "./capabilityTools/googleDriveCapability/GoogleDriveCapability";
import InternetCapability from "./capabilityTools/internetTool/InternetCapability";
import SqlCapability from "./capabilityTools/sqlTool/SqlCapability";
import CodeInterpreterTool from "./capabilityTools/codeInterpreterTool/components/CodeInterpreterTool";
import GoogleSheetCapability from "./capabilityTools/googleSheetCapability/components/GoogleSheetCapability";
import NewSqlTool from "./capabilityTools/sqlToolv2/NewSqlTool";
import ImageGenerationTool from "./capabilityTools/imageGenerationTool/components/ImageGenerationTool";
import CsvGenerationTool from "./capabilityTools/csvGenerationTool/components/CsvGenerationTool";


export default function CapabilityDrawer({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload, clientId }) {
  if (configData?.capability_name === "SqlCapability") {
    return (
      <SqlCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "InternetCapability") {
    return (
      <InternetCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "ChartingCapability") {
    return (
      <ChartingCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "GoogleDriveCapability") {
    return (
      <GoogleDriveCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "BackboneCapability") {
    return (
      <BackboneCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "DashCapability") {
    return (
      <DashboardCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "GoogleSheetCapability") {
    return (
      <GoogleSheetCapability
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "CodeInterpreterCapability") {
    return (
      <CodeInterpreterTool
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "SqlCapabilityV2") {
    return <NewSqlTool id={id} clientId={clientId} metaData={configData} aiAppData={aiAppData} updateCustomApp={updateCustomApp} />;
  } else if (configData?.capability_name === "ImageGenerationCapability") {
    return (
      <ImageGenerationTool
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  } else if (configData?.capability_name === "CsvGenerationCapability") {
    return (
      <CsvGenerationTool
        toolData={toolData}
        configData={configData}
        id={id}
        aiAppData={aiAppData}
        updateCustomApp={updateCustomApp}
        onClose={onClose}
        reload={reload}
      />
    );
  }

  return <div>no tools found</div>;
}
