import { Tabs } from "antd";
import React from "react";
import useSqlToolTabsHook from "./usSqlToolTabsHook";
import SqlStrategy from "../sqlStrategy/SqlStrategy";
import SqlToolTables from "../sqlToolTables/SqlToolTables";
import Style from "./sqlToolTabs.style";
import SqlSettings from "../sqlSettings/SqlSettings";
import Relationships from "../relationships/Relationships";
import SqlVirtualTable from "../sqlVirtualTable/SqlVirtualTable";

export default function SqlToolTabs({ selectedConnection, clientId, sqlData, mAiApp, connectionString, setSqlData }) {
  const { tabs, setTabs } = useSqlToolTabsHook();
  
  return (
    <>
      <div className={Style.tabContainer}>
        <Tabs
          activeKey={tabs}
          onChange={(val) => {
            setTabs(val);
          }}
        >
          <Tabs.TabPane tab="Tables" key="tables">
            <SqlToolTables selectedConnection={selectedConnection} clientId={clientId} sqlData={sqlData} mAiApp={mAiApp} setSqlData={setSqlData} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Virtual Tables" key="virtual_tables">
            <SqlToolTables selectedConnection={selectedConnection} clientId={clientId} sqlData={sqlData} mAiApp={mAiApp} setSqlData={setSqlData} virtualTable={true} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Relationships" key="relationships">
            <Relationships selectedConnection={selectedConnection} clientId={clientId} sqlData={sqlData} mAiApp={mAiApp}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Strategies" key="strategies">
            <SqlStrategy selectedConnection={selectedConnection} clientId={clientId} mAiApp={mAiApp} connectionString={connectionString}/>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Advanced settings" key="parameters">
            <SqlSettings selectedConnection={selectedConnection} sqlData={sqlData} mAiApp={mAiApp}/>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
}
