import { css } from "@linaria/core";

export const welcomeContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px 24px;
`;

export const welcomeHeader = css`
  text-align: center;
  margin-bottom: 48px;
`;

export const welcomeTitle = css`
  font-size: 32px;
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 16px;
`;

export const welcomeDescription = css`
  font-size: 16px;
  color: var(--color-60-gray);
  max-width: 600px;
  margin: 0 auto;
`;

export const featuresGrid = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 48px;
`;

export const featureCard = css`
  background-color: var(--color-90-black);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-80-black);
  transition: border-color 0.3s ease;

  &:hover {
    border-color: var(--color-link);
  }
`;

export const featureIcon = css`
  font-size: 24px;
  color: var(--color-link);
  margin-bottom: 16px;
`;

export const featureTitle = css`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 8px;
`;

export const featureDescription = css`
  font-size: 14px;
  color: var(--color-60-gray);
  line-height: 1.5;
`;

export const createButton = css`
  height: 40px;
  padding: 0 24px;
  font-size: 16px;
`;
