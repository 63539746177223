import { css } from "@linaria/core";

const controls = css`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const select = css`
  width: 100%;
`;


const analysisCardView = css`
  &.ant-card {
    background-color: var(--color-charcoal-surface-2);
    border: none;
    box-shadow: none;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px !important;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;
  }
`;


const tabContent = css`
  display: flex;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;


const knowledgeGraphFilters = css`
  .ant-select-selection-placeholder {
    font-size: 0.9rem !important;
  }
  .ant-select.ant-select.ant-select {
    padding: 0 !important;
    &:hover {
      padding: 0 !important;
    }
    .ant-select-selector.ant-select-selector.ant-select-selector {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      &:hover {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
      }
      .ant-select-selection-overflow {
        padding: 0;
        .ant-select-selection-item-content {
          font-size: 0.8rem;
        }
      }
    }
  }
  .ant-input.ant-input,
  .ant-picker-input.ant-picker-input.ant-picker-input {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    font-size: 1.1rem !important;
  }
  .ant-picker.ant-picker-range .ant-picker-input input {
    font-size: 0.8rem !important;
    text-align: center;
  }
`;


const knowledgeGraphFiltersWrapper = css`
  flex-direction: column;
  display: flex;
  z-index: 3;
  width: 100%;
  background-color: var(--color-charcoal-surface-1);
  border-radius: 10px;
  padding: 10px 0px;
  .ant-col {
    padding: 5px 10px !important;
    &.col-wrapper {
      padding: 5px 0px !important;
      width: 49%;
    }
  }
`;

const tagWrapper = css`
  font-size: 12px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: fit-content;
  max-height: 200px;
  max-width: 268px;
  overflow-y: auto;
`;

const tagText = css`
  color: black;
  background-color: white;
  border-radius: 3px;
  margin: 5px;
  border: solid 0.5px #c6c6c685;
  padding: 3px;
  padding-top: 4px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
`;

const tagPopover = css`
  .ant-popover-inner-content {
    padding: 5px;
    box-shadow: 0px 0px 1px 0px #ffffff8a;
    // display: flex;
    // align-content: flex-end;
    // justify-content: start;
    // align-items: flex-end;
  }
  .ant-popover-arrow > .ant-popover-arrow-content {
    box-shadow: 0px 0px 1px 0px #ffffff8a;
  }
`;

const selectDropdown = css`
  .ant-select-item-option-content {
    font-size: 0.8rem;
    word-wrap: normal;
    text-overflow: unset;
  }
`;

const summarizeButton = css`
  display: flex;
  gap: 8px;
  align-items: center;
  color: white;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  height: 58px;
  padding-bottom: 13px;
  .ant-btn-primary {
    padding: 10px !important;
    font-size: 1rem;
  }
  .ant-select {
    .ant-select-selector.ant-select-selector {
      padding: 4px 8px !important;
      &:hover {
        padding: 4px 8px !important;
      }
    }
    .ant-select-selection-item.ant-select-selection-item.ant-select-selection-item {
      font-size: 0.8rem !important;
      &:hover {
        font-size: 0.8rem;
      }
    }
  }
`;

export default {
  controls,
  select,
  analysisCardView,
  tabContent,
  knowledgeGraphFilters,
  knowledgeGraphFiltersWrapper,
  tagWrapper,
  tagText,
  tagPopover,
  summarizeButton,
  selectDropdown,
};
