import { css } from "@linaria/core";

const VennDiagramWrapper = css`
  position: relative;
  width: 100%;
  height: 400px;

//   .highcharts-container {
//     width: 100% !important;
//     height: 100% !important;
//   }
`;


const HoverTooltip = css`
  position: absolute;
  top: 0;
  right: 10px;
  background-color: var(--color-90-black);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
`;



const overlapDrawer = css`
  .ant-drawer-content-wrapper {
    width: 50% !important;
  }
`;


const drawerList = css`
  color: black;
`;

const fullScreenDrawer = css`
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
`;

const listItem = css`
  color: black;
`;


const darkTable = css`
  table {
    border-color: white;
  }
  table > thead > tr > th {
    background-color: #171717;
    color: white;
  }
  table > tbody > tr > td {
    background-color: #222222 !important;
    color: white;
    border-bottom: none;
  }
  .ant-empty-description {
    color: white;
  }

  .ant-pagination {
    li {
      border: 0px;
      background-color: #222222;
      color: white;
      button {
        background-color: #222222;
        color: white;
        border: none;
        :hover {
          background-color: #222222;
          color: white;
        }
      }
      a {
        border: 0px;
        background-color: #222222;
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid white !important;
  }
`;



export default {
  VennDiagramWrapper,
  HoverTooltip,
  drawerList,
  listItem,
  darkTable,
  overlapDrawer,
  fullScreenDrawer,
};
