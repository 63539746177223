import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Form, Input, message, Row, Select, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import Uploader from "./Uploader";
import useClientSpaceAndBasicInfoHook from "./useClientSpaceAndBasicInfoHook";
import LoadingSpinner from "src/components/loadingSpinner";

const ClientSpaceBasicInfo = ({ metaData, history, clientId, addUpdateClient }) => {
  const { formRef, createClisentSpace, basicInfo, loading, configureLoading } = useClientSpaceAndBasicInfoHook({
    metaData,
    history,
    clientId,
    addUpdateClient,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <Row justify="end">
        <PrimaryButton
          loading={configureLoading}
          className="small"
          onClick={() => {
            formRef.current.submit();
          }}
          comfy
        >
          {clientId ? "Edit" : "Create"}
        </PrimaryButton>
      </Row>
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={basicInfo}
        requiredMark={false}
        onFinish={(values) => {
          createClisentSpace(values);
        }}
      >
        <Row gutter={[30, 0]}>
          <Col span={24}>
            <Form.Item label="Client Space Name" name="name" rules={[{ required: true, message: "Please enter a client space name" }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Website URL" name="website_url">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Industry" name="industry">
              <Select>
                {metaData?.industries?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Tell us about them"
              name="description"
              rules={[{ required: true, message: "Please enter a client space description" }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Client Space Icon" name="black_white_icon">
              <Uploader onChange={(base64String) => {}} initialImageUrl={basicInfo?.black_white_icon || ""} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ClientSpaceBasicInfo;
