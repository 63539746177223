import React, { useEffect, useState } from "react";
import remarkGfm from "remark-gfm";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeMathjax from 'rehype-mathjax';
import MarkdownCodeViewer from "./MarkdownCodeViewer";
import Style from './aiApp.style';

const MarkdownRender = ({ children, feedbackClass, theme="dark" }) => {
  const regex = /\\\[.*?\]|\\\[.*?\\\]/g; // find all LaTex math equations
  const matches = children?.match(regex); // find all matches
  const [markDownClassName, setMarkDownClassName] = useState('');
  
  if (matches) {
    matches.forEach((match) => {
      children = children?.replace(
        match,
        match?.replaceAll(/\[|\\\[/g, "\n```math\n")?.replaceAll(/\]|\\\]/g, "\n```\n")
      ); // replace [ and ] with $
    });
  }

  useEffect(()=>{
    let className = `${Style.markdown}`
    if (theme === 'light') {
      className += `${theme}`;
    }
    if (feedbackClass) {
      if (feedbackClass === 'disliked' ){
        className += ` ${Style.disliked}`;
      } else {
        className += ` ${Style.liked}`;
      } 
    }
    
    setMarkDownClassName(className);
    
  }, [feedbackClass, theme])

  return (
    <Markdown
      className={markDownClassName}
      remarkPlugins={[[remarkMath, { singleDollarTextMath: false }], remarkGfm]}
      rehypePlugins={[rehypeMathjax]}
      components={{
        code(props) {
          const { children, className, ...rest } = props;
          const match = /language-(\w+)/.exec(className || "");
          return match ? (
            // <MarkdownCodeViewer rest={rest} preTag="div" language={match[1]} className={className}>
            //   {children}
            // </MarkdownCodeViewer>
            <code {...rest} className={className}>
              {children}
            </code>
          ) : (
            <code {...rest} className={className}>
              {children}
            </code>
          );
        }
      }}>
      {children}
    </Markdown>
  );
};

MarkdownRender.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default MarkdownRender;