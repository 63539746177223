import { Select } from "antd";
import EChartsReact from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { CHART_FILTER_OPTIONS } from "src/modules/audienceTool/audienceManager/constants/constants";

const { Option } = Select;

export default function AreaChart({ response }) {
  const [filterType, setFilterType] = useState("channel");
  const [chartOption, setChartOption] = useState({});

  // JS function to convert this into e charts data format
  function getAreaGraphData(data) {
    let seriesMap = new Map();
    let xAxis = [];
    let legend = new Set();

    // Get the xAxis data from the first object's weekly plan
    if (data.length > 0 && data[0].weekly_plan.length > 0) {
      data[0].weekly_plan.forEach((item) => {
        xAxis.push(item.week_start_date);
      });
    }

    data.forEach((item) => {
      if (!seriesMap.has(item[filterType])) {
        seriesMap.set(item[filterType], new Array(xAxis.length).fill(0));
      }
      let seriesValue = seriesMap.get(item[filterType]);
      item.weekly_plan.forEach((week, index) => {
        seriesValue[index] += week.budget;
      });
      legend.add(item[filterType]);
    });

    let series = [];
    seriesMap.forEach((value, key) => {
      series.push({
        name: key,
        type: "line",
        data: value,
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
      });
    });

    return {
      xAxis: xAxis,
      series: series,
      legend: Array.from(legend),
    };
  }

  // Filter the data based on selected filter type
  const filteredData = filterType
    ? response.media_plan.filter((item) => item[filterType])
    : response.media_plan;

  useEffect(() => {
    const op = getAreaGraphData(filteredData);

    const option = {
      title: {
        text: `Weekly Spend ${
          filterType
            ? `- ${
                CHART_FILTER_OPTIONS.filter(
                  (item) => item.value === filterType
                )[0].label
              }`
            : ""
        }`,
        left: "center",
        top: 20,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: {
        name: "Week Start Date",
        type: "category",
        data: op?.xAxis,
        splitLine: {
          show: false, // Remove grid lines on the x-axis
        },
        axisLine: {
          lineStyle: {
            color: "#fff", // Set x-axis line color to white
          },
        },
      },
      yAxis: {
        name: "Spend",
        type: "value",
        splitLine: {
          show: false, // Remove grid lines on the y-axis
        },
        axisLabel: {
          formatter: (value) => `$${value.toLocaleString()}`, // Format y-axis labels as USD
          textStyle: {
            color: "#fff",
          },
        },
        axisLine: {
          // Add labels to the y-axis
          lineStyle: {
            color: "#fff", // Set x-axis line color to white
          },
        },
      },
      series: op?.series,
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let result = `${params[0].name}<br/>`;
          params.forEach((item) => {
            result += `${item.marker} ${
              item.seriesName
            }: $${item.value.toLocaleString()}<br/>`;
          });
          return result;
        },
      },
      legend: {
        data: op?.legend,
        orient: "vertical", // Display legends vertically
        right: 10, // Position the legend to the right
        top: "middle", // Align the legend in the middle vertically
        textStyle: {
          color: "#fff",
        },
      },
    };

    setChartOption(option);
  }, [filterType]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "1rem",
        }}
      >
        <Select
          placeholder="Select Filter Type"
          style={{ width: 200 }}
          value={filterType}
          onChange={(value) => {
            setFilterType(value);
          }}
        >
          {CHART_FILTER_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <EChartsReact
        option={chartOption}
        notMerge={true}
        style={{ height: "460px", width: "100%" }}
      />
    </div>
  );
}
