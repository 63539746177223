import { useState, useEffect } from 'react';

const useClusterComparison = () => {
  const [tab, setTab] = useState('cross_feature');

  const onTabSwitch = (tab) => {
    setTab(tab);
  }

  return {
    tab,
    onTabSwitch
  }

  
};

export default useClusterComparison;