import { DislikeFilled, LikeFilled, DeleteFilled } from "@ant-design/icons";
import { Form, Row, Link, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { SecondaryButton } from "src/components/basic";
import useOpportunityFeedbackFormHooks from "./opportunitiesFeedbackForm.hooks";
import Style from "./opportunitiesFeedbackForm.style";

export default function OpportunityFeedbackForm({ opportunity, feedbackData, onSubmitFeedback, onDone }) {
  const { loading, formRef, submitFeedback, removeFeedback } = useOpportunityFeedbackFormHooks({
    opportunity,
    feedbackData,
    onSubmitFeedback,
    onDone,
  });

  return (
    <div>
      <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
        {feedbackData?.feedback_type === "LIKED" ? (
          <LikeFilled style={{ fontSize: "1.25rem" }} />
        ) : (
          <DislikeFilled style={{ fontSize: "1.25rem" }} />
        )}
        &nbsp;&nbsp;Feedback
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Form ref={formRef} initialValues={feedbackData} onFinish={(val) => submitFeedback(val)}>
          <Form.Item
            name="feedback_text"
            rules={[
              {
                required: true,
                message: "Feedback is required",
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 7 }}
              style={{ borderRadius: "5px" }}
              placeholder={
                feedbackData?.feedback_type === "LIKED"
                  ? "What do you like about the opportunity?"
                  : "What was the problem with the opportunity?"
              }
            />
          </Form.Item>
        </Form>
      </div>
      <Row justify="space-between">
        {feedbackData?.id ? (
          <SecondaryButton className={`small ${Style.removeFeedback}`} onClick={() => removeFeedback()}>
            <DeleteFilled />
            Remove Feedback
          </SecondaryButton>
        ) : <div/>}
        <SecondaryButton className="small" loading={loading} onClick={() => formRef.current.submit()}>
          Done
        </SecondaryButton>
      </Row>
    </div>
  );
}
