import { useEffect, useState } from "react";
import createAudienceService from "../../services/createAudienceService";
import { CLUSTER } from "../../constants/constants";

export default function useClusterTabHook(id) {

    const [clusterLoading, setClusterLoading] = useState(false);
    const [clusters, setClusters] = useState([]);
    const [clusterRecommendations, setClusterRecommendations] = useState({});
    const [clusterRecommendationLoading, setClusterRecommendationLoading] = useState(false);

    const getClusterData = async () => {
        setClusterLoading(true);
        try {
            const res = await createAudienceService.getClusterList(id);
            setClusters(res.data?.data);
            setClusterLoading(false);
        } catch (error) {
            console.error(error);
            setClusterLoading(false);
        }
    };

    const getAiRecommendedClusters = async (aiPrompt) => {
        try {
          const clientId = id;
          setClusterRecommendationLoading(true);
          const recommendation = await createAudienceService.getAudienceRecommendations(clientId, aiPrompt, CLUSTER);
          setClusterRecommendations(recommendation.data);
        } catch (e) {
          console.error(e);
          setClusterRecommendationLoading(false);
        }
      }

    useEffect(() => {
        getClusterData();
        }, []);
    
    return { 
        clusterLoading,
        clusters,
        getAiRecommendedClusters,
        clusterRecommendations,
        setClusterRecommendations,
        clusterRecommendationLoading,
        setClusterRecommendationLoading
    };
}