import { message } from 'antd';
import { useState, useContext, useEffect } from 'react';
import AppContext from 'src/context/appContext';
import { consistentHashing } from 'src/lib/utils';

export default function useAiAppConversationHook({item}) {
  const appContext = useContext(AppContext.Context);
  const [elementCopiedToClipBoard, setElementCopiedToClipBoard] = useState(null);
  const [showReactions, setShowReactions] = useState(false);
  const [showInputCopy, setShowInputCopy] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  let userIndex = 0;

  const n = 6;
  
  let code = consistentHashing(item?.email, n);
  if (code) {
    userIndex = code
  }

  let name = "";
  if(item?.first_name) {
    name += item?.first_name; 
  }
  if(item?.last_name) {
    name += ` ${item?.last_name}`; 
  }

  const totalFeedbacks = item?.feedbacks;
  
  const userFeedback = totalFeedbacks?.find((ele) => ele.email === appContext?.metaData?.email);

  const copyToClipBoard = ({answerType, text}) => {
    setElementCopiedToClipBoard(answerType);
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text.replace(/\*\*(.*?)\*\*/g, "$1")?.replace(/\n/g, "\n");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    } catch (error) {}
    message.success({
      type: "success",
      content: "Copied to clipboard",
      duration: 3,
    });
    window.setTimeout(() => {
      setElementCopiedToClipBoard(null);
    }, 2000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {
    copyToClipBoard,
    isMobile,
    showReactions,
    setShowReactions,
    showInputCopy,
    setShowInputCopy,
    elementCopiedToClipBoard,
    appContext,
    userIndex,
    name,
    userFeedback
  }
}