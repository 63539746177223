import React from "react";
import useCrossFeatureView from "./useCrossFeatureView";
import LoadingSpinner from "src/components/loadingSpinner";
import { Col, Empty, Row, Select, Spin } from "antd";
import ReactECharts from "echarts-for-react";
import { PrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useValueByCluster from "./useValueByCluster";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";

const ValueByCluster = ({ clusterData }) => {
  const {
    loading,
    handlePopupScroll,
    handleSearch,
    loadingFeature1,
    feature1Data,
    getFeatureMeanInfo,
    chartOptions,
    selectedData,
    setSelectedData,
    chartLoading,
    chartref,
    downloadChart,
  } = useValueByCluster({ clusterData });

  if (loading) return <LoadingSpinner centered />;
  return (
    <div>
      <h3>Feature Value by Cluster</h3>
      <h4>Compare Mean Values of Feature by Clusters.</h4>
      <h4>Select Features for Comparison</h4>
      <Row gutter={[20, 20]}>
        <Col>
          <Select
            style={{ width: "265px" }}
            showSearch
            showArrow
            placeholder="Select Feature"
            onPopupScroll={handlePopupScroll}
            autoClearSearchValue={false}
            notFoundContent={<></>}
            value={selectedData}
            onChange={(value) => {
              setSelectedData(value);
              getFeatureMeanInfo(value);
            }}
            onSearch={handleSearch}
            dropdownRender={(menu) => {
              return (
                <>
                  {feature1Data?.data?.length === 0 && !loadingFeature1 ? (
                    <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                  ) : (
                    <>
                      {menu}
                      {loadingFeature1 && (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "8px",
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            }}
          >
            {feature1Data?.data?.map((elem, index) => {
              return (
                <Select.Option key={index} value={elem}>
                  {elem}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <ConditionalRender shouldRender={!!chartOptions}>
        <ConditionalRender shouldRender={!chartLoading}>
          <Row justify="end" style={{ marginRight: "4.5rem" }}>
            <Col onClick={downloadChart} style={{ cursor: "pointer" }}>
              <DownloadOutlined />
              &nbsp;Download Chart
            </Col>
          </Row>
          <ReactECharts ref={chartref} option={chartOptions} style={{ height: 550, marginTop: "1rem" }} />
        </ConditionalRender>
        <ConditionalRender shouldRender={chartLoading}>
          <LoadingSpinner centered />
        </ConditionalRender>
      </ConditionalRender>
      <ConditionalRender shouldRender={!!!chartOptions}>
        <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
      </ConditionalRender>
    </div>
  );
};

export default ValueByCluster;
