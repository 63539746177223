import { css } from "@linaria/core";

const listHeader = css`
  display: flex;
  flex-direction: row;
  color: var(--color-40-black);
  font-weight: 600;
  border: none;
  padding: 1rem;
  gap:8px;
`;

const listItem = css`
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  color: var(--color-gale-gray);
  gap:8px;
`;

const form = css`
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 90px);
`;

const deleteContainer = css`
  display: flex;
  justify-content: space-between;
`;

const body = css`
  flex-grow: 1;
`;

export default {
  listHeader,
  listItem,
  form,
  deleteContainer,
  body
};
