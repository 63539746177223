import { DatabaseOutlined, FileOutlined } from "@ant-design/icons";
import React from "react";

export const DATA_SOURCE_TYPE = {
  GOOGLE_SHEETS: "GOOGLE_SHEETS",
  DATABASE: "DATABASE",
};

export const DATA_SOURCES = [
  {
    title: "Google Sheets",
    type: DATA_SOURCE_TYPE.GOOGLE_SHEETS,
    disabled: false,
    icon: <FileOutlined />,
  },
  {
    title: "Database",
    type: DATA_SOURCE_TYPE.DATABASE,
    disabled: true,
    icon: <DatabaseOutlined />,
  },
];

export const SERVICE_ACCOUNT_EMAIL =
  "alchemyai@alchemyai-418009.iam.gserviceaccount.com";
