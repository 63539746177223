import { css } from "@linaria/core";

const container = css`
  display: flex;
  flex-direction: column;
  padding-right:20px;
`;

export default {
  container,
};
