import { notification } from "antd";
import { useState, useEffect } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import NcApiServices from "../services/NcApiServices";

export const useKnowledgeGraphSummaryHook = ({
  knowledgeGraphFilters,
  analysisId,
  constructKnowledgeGraphFilters,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getData = async () => {
    try {
      setLoading(true);
      const payload = constructKnowledgeGraphFilters(analysisId, knowledgeGraphFilters);
      const res = await NcApiServices.getKnowledgeGraphSummary(payload);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    data,
  };
};
