import React from "react";
import Assets from "src/assets";

export default function AiLoader({ width=24, height=24, color='white', rotate=0 }) {
  const loaderStyle = {
    width: `${width}px`,
    height: `${height}px`,
    display:'inline-block',
    color: color,
    transform: `rotate(${rotate}deg)`
  }
  return (
    <img alt="AI loading animation"
      style={loaderStyle}
      src={Assets.getImage("AiLoader")}
    />
  );
}
