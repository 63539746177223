import { css } from "@linaria/core";

const container = css`
  display: flex;
  flex-direction: column;
`;

const addRowContainer = css`
  margin-top: 1rem;
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
`;

export default {
  addRowContainer,
  container,
};
