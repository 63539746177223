import React, { useState, useEffect, useContext, useCallback, useRef, useLayoutEffect } from 'react';
import IconGenerator from 'src/components/IconGenerator';
import { PROFILECOLORS } from 'src/modules/aiApps/constants';
import Style from '../conversation.style';
import { Button, Col, Row, Skeleton, Tooltip } from 'antd';
import moment from 'moment';
import ConditionalRender from 'src/components/conditionalRender';
import AppContext from 'src/context/appContext';
import ConversationResponse from 'src/modules/ConversationContainer/conversations/components/ConversationResponse';
import { InfoCircleOutlined } from '@ant-design/icons';
import FeedbackModal from './FeedbackModal';

const ChatView = ({ dataPresent, feedbackData, isFeedbackChatLoading, appId, handleResolve, isResolved, openFeedBackFormModal, closeFeedBackmodal, feedbackFormData, feedbackFormModal }) => {
  const appContext = useContext(AppContext.Context);
  const [prevMessages, setPrevMessages] = useState([]);
  const [nextMessages, setNextMessages] = useState([]);
  const [prevPage, setPrevPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const pointerMessageRef = useRef(null);

  useEffect(() => {
    if (feedbackData) {
      setPrevMessages(feedbackData?.data?.previous_n_messages_data?.slice(0, prevPage * 10));
      setNextMessages(feedbackData?.data?.next_n_messages_data?.slice(0, nextPage * 10));
    }
  }, [feedbackData, prevPage, nextPage]);

  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    if (feedbackData?.data?.pointer_message_data) {
      setShouldScroll(true);
    }
  }, [feedbackData?.data?.pointer_message_data]);

  useEffect(() => {
    if (shouldScroll && pointerMessageRef.current) {
      pointerMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setShouldScroll(false); // Reset the flag
    }
  }, [shouldScroll, pointerMessageRef]);

  const loadMorePrevMessages = useCallback(() => {
    setPrevPage((prevPage) => prevPage + 5);
  }, []);

  const loadMoreNextMessages = useCallback(() => {
    setNextPage((nextPage) => nextPage + 5);
  }, []);

  const handleScroll = useCallback(
    (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      const top = e.target.scrollTop === 0;
      if (bottom) {
        loadMoreNextMessages();
      } else if (top) {
        loadMorePrevMessages();
      }
    },
    [loadMoreNextMessages, loadMorePrevMessages]
  );

  const renderTimestamp = (timestamp) => {
    const today = moment().startOf("day");
    return (
      <Tooltip overlayClassName={Style.timeTooltip} title={<div>{moment(timestamp)?.format("DD MMM YYYY, hh:mm A")}</div>}>
        <div className={`${Style.reactionContainer} ${Style.timestampTime}`}>{moment(timestamp).startOf("day") < today ? moment(timestamp)?.format("DD MMM YYYY, hh:mm A") : moment(timestamp)?.format("hh:mm A")}</div>
      </Tooltip>
    );
  };

  const renderUserQuestion = (message, index)=> {
    return <div className={Style.chatMessageContainer}>
      <div className={Style.chatDesc}>
        <div className={Style.messageLabel}>
          {message?.email === appContext?.metaData?.email
            ? 'You'
            : message?.first_name
            ? message.first_name
            : message?.email}
          &nbsp;&nbsp;
          <IconGenerator
            height={24}
            width={24}
            initial={message?.first_name ? message?.first_name.charAt(0) : 'U'}
            backgroundColor={'var(--color-white)'}
            color="var(--color-full-black)"
          />
        </div>
        <div className={Style.questionContainer}>
          <div className={Style.questionLabel}>{message?.user_input}</div>
          {renderTimestamp(message?.input_at)}
        </div>
      </div>
    </div>
  }

  const renderAILabel = (message) =>{
    return <div className={Style.messageLabelBot}>
            <IconGenerator
              height={24}
              width={24}
              initial='AI'
              backgroundColor='var(--color-full-black)'
              color='var(--color-white)'
              withBorder={false}
            />
            <span>Chat Assistant</span>
          </div>
          
  }

  const renderAITimeStamp = (message)=>{
    return <div className={Style.timeAndInfoParent}>
    <Tooltip
      overlayClassName={Style.timeTooltip}
      title={
        <div>
          <div>
            <span>Runtime: </span>
            <span>
              {message?.completion_time?.toFixed(2).concat('s')}
            </span>
          </div>
          <div>
            <span>Cost: </span>
            <span>{message?.cost?.toFixed(2).concat('$')}</span>
          </div>
        </div>
      }
    >
      <InfoCircleOutlined />
    </Tooltip>
    {renderTimestamp(message?.input_at)}
  </div>
  }

  const renderMessage = (message, index) => (
    <>
      {renderUserQuestion(message, index)}
      <div
        key={`${index}-pointer-ai`}
        className={`${Style.chatMessageContainer} ${Style.feedbackContainer}`}
      >
        <div className={Style.chatDesc}>
          {renderAILabel(message)}
          <div>
            <ConversationResponse
              data={message?.ai_output}
              analysisData={message?.generated_steps}
              mAiApp={appId}
              chatId={message?.id}
              hasAnalysis={message?.has_analysis}
              feedbackScreen={true}
              theme='light'
            />
          </div>
          {renderAITimeStamp(message)}
        </div>
      </div>
    </>
  );

 

  const renderPointerMessage = () => {
    const message = feedbackData?.data?.pointer_message_data;
    const hasDislikedFeedback = message?.feedbacks?.some(feedback => feedback.user_reaction === 'DISLIKED');
    return (
      <>
        {renderUserQuestion(message)}
        <div
          key='pointer-ai'
          className={`${Style.chatMessageContainer} ${Style.feedbackContainer}`}
          ref={pointerMessageRef}
        >
          <div className={Style.chatDesc}>
            {renderAILabel(message)}
            <div>
              <ConversationResponse
                key={message?.id}
                data={message?.ai_output}
                analysisData={message?.generated_steps}
                mAiApp={appId}
                chatId={message?.id}
                hasAnalysis={message?.has_analysis}
                feedbackClass={hasDislikedFeedback ? 'disliked' : 'liked'}
                feedbackScreen={true}
                theme='light'
              />
            </div>
            {renderAITimeStamp(message)}
          </div>
        </div>
        <FeedbackModal
          modalOpen={feedbackFormModal}
          closeModal={closeFeedBackmodal}
          handleResolve={handleResolve}
          data={feedbackFormData}
        />
      </>
    );
  };

  const SkeletonLoader = ({ count }) => (
    <div className={Style.chatLoader}>
      {Array.from({ length: count }).map((_, index) => (
        <Skeleton key={'skeleton-'+index} avatar paragraph={{ rows: 2 }} active />
      ))}
    </div>
  );

  return (
    <div onScroll={handleScroll}>
      <ConditionalRender shouldRender={isFeedbackChatLoading}>
        <div className={Style.chatContainer}>
          <SkeletonLoader count={5} />
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!feedbackData || Object.keys(feedbackData).length === 0 || dataPresent}>
        <div className={Style.noMessageContainer}>
          <div className={Style.helpContainer}>
            <div className={Style.helpImage}>
              <IconGenerator
                height={50}
                backgroundColor="#222222"
                width={50}
                initial={appContext?.metaData?.email?.[0]?.toUpperCase()}
              />
            </div>
            <div className={Style.helpImage}>
              You have no feedback.
            </div>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={Object.keys(feedbackData).length > 0 && !dataPresent}>
        <div>
          {prevMessages?.map((message, index) => renderMessage(message, index))}
          {renderPointerMessage()}
          {nextMessages?.map((message, index) => renderMessage(message, index))}
        </div>
      </ConditionalRender>
    </div>
  );
};

export default ChatView;
