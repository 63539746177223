export const audienceDetailsContainer = {
    backgroundColor: '#1e1e1e',
    color: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
};

export const audienceName = {
    fontSize: '16px',
    marginBottom: '10px',
};

export const audienceDescriptionStyle = {
    fontSize: '1.2em',
    marginBottom: '20px',
};

export const audienceSection = {
    marginBottom: '20px',
};

export const audienceSectionHeader = {
    fontSize: '1.5em',
    borderBottom: '2px solid #ffffff',
    paddingBottom: '5px',
    marginBottom: '10px',
    color: '#ffffff'
};

export const audienceSectionList = {
    listStyleType: 'none',
    paddingLeft: '0',
};

export const audienceSectionListItem = {
    marginBottom: '5px',
};

export const clusterStyle = {
    marginBottom: '15px',
};

export const clusterHeader = {
    fontSize: '1.3em',
    marginBottom: '5px',
    color: '#ffffff'
};

export const clusterDescription = {
    fontSize: '1em',
    marginLeft: '10px',
};