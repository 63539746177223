import { useState, useEffect, useRef } from 'react';
import { SqlToolApiServices } from '../sqlToolApiServices';
import { getErrorMessageFromResponse, truncateString } from 'src/lib/utils';
import { notification } from 'antd';

export default function useRelationshipsHook({ selectedConnection, mAiApp, sqlData }) { 
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  let tables = [];
  let initialValues = {relationships: [undefined]};

  if (sqlData?.relationships) {
    initialValues = {relationships: sqlData.relationships}
  }

  sqlData?.tables?.forEach((table) => {
    tables.push(table.table_name);
  })

  sqlData?.virtual_tables?.forEach((table) => {
    tables.push(table.table_name);
  });

  const configureRelationships = async (val) => {
    try {
      setLoading(true);
      let payload = {
        connection_id: selectedConnection,
        ...val
      }
      const res = await SqlToolApiServices.postSqlConnectionLevelData(mAiApp, payload);
      setLoading(false);
      notification.success({
        message: "Relationships configured successfully",
        duration: 5,
        placement: "bottomRight"
      })
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: truncateString(msg, 200),
        duration: 5,
        placement: 'bottomRight'
      })
    }
  }
  return {
    loading,
    tables,
    formRef,
    configureRelationships,
    initialValues
  }
}