import { DislikeFilled, DislikeOutlined, LikeFilled, LikeOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Tag } from "antd";
import React, { useEffect } from "react";
import Style from "../conversation.style";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import IconGenerator from "src/components/IconGenerator";
import FeedbackModal from "./FeedbackModal";
import ConditionalRender from "src/components/conditionalRender";
import GlobalTag from "src/components/globalTag/GlobalTag";
import { TAG_COLOR_NAME } from "src/components/globalTag/globalTagConstant";

const ConversationCard = ({
  name,
  question,
  feedback,
  category,
  timeStamp,
  isResolved,
  handleResolve,
  onClick,
  categories,
  initial,
  uId,
  openFeedBackFormModal,
  closeFeedBackmodal,
  feedbackFormData,
  feedbackFormModal,
  cardId,
  isFirstCard,
  feedbackLoader,
  reaction,
  resolutionComment,
  resolvedBy,
  resolvedOn,
  jira_ticket_key,
  jira_ticket_status,
  jira_ticket_url,
}) => {
  useEffect(() => {
    if (isFirstCard) {
      handleClick();
    }
  }, [isFirstCard]);

  const FeedbackIcon = ({ type }) => {
    if (type === "LIKED") {
      return <LikeOutlined style={{ color: "var(--feedback-like)" }} />;
    } else {
      return <DislikeOutlined style={{ color: "var(--feedback-dislike)" }} />;
    }
  };

  const handleClick = (e) => {
    if (feedbackLoader) {
      feedbackLoader(true);
    }
    onClick();
  };

  return (
    <>
      <Card bordered={false} className={Style.cardMain} size="small" onClick={handleClick}>
        <div className={Style.cardDivFirst}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconGenerator height={24} width={24} initial={initial} color="var(--color-full-black)" backgroundColor={"var(--color-white)"} />
            <div className={Style.cardDivSecond}>
              <span className={Style.userInfo}>{name}</span>
              <p className={Style.cardTime}>{CustomGptService.dateConverter(timeStamp)}</p>
            </div>
          </div>
          <div>
            <FeedbackIcon type={reaction} />
          </div>
        </div>
        <div className={Style.cardBodyFirst}>
          <div className={Style.cardBodyHeader}>
            <p className={Style.cardBodyHeaderText}>
              <b>{isResolved === true ? 'Comment: ' : 'Question: '}</b>
              {isResolved === true ? resolutionComment : question}
            </p>
          </div>
        </div>
        <Row style={{padding: '0 1rem'}} align="middle" justify="space-between">
          <Col span={22}>
            <div className={Style.cardBody2}>
              <p className={Style.cardCategories}>
                <b>{isResolved === true ? 'Resolved By: ' : 'Categories: '}</b>
                {isResolved === true ? resolvedBy : CustomGptService.formatCategories(categories)}
              </p>
              <p className={Style.cardCategory}>
                <b>{isResolved === true ? 'Resolved On: ' : 'Feedback: '}</b>
                {isResolved === true ? CustomGptService.dateConverter(resolvedOn) : feedback}
              </p>
            </div>
            <ConditionalRender shouldRender={!!jira_ticket_url}>
              <a
                  href={jira_ticket_url}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkOutlined />
                  {jira_ticket_key}
                </a>
                &nbsp;
              <GlobalTag
                color={jira_ticket_status == "done"? TAG_COLOR_NAME.GREEN: TAG_COLOR_NAME.GRAY}
              >
                {jira_ticket_status && jira_ticket_status.toUpperCase().replace("_", " ") }
              </GlobalTag>
            </ConditionalRender>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ConversationCard;
