import { Input } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as Styles from "./styles";

const AutocompleteTextarea = ({ value, onChange, columns, placeholder }) => {
  const [cursorPosition, setCursorPosition] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsPosition, setSuggestionsPosition] = useState({
    top: 0,
    left: 0,
  });
  const [searchText, setSearchText] = useState("");
  const textareaRef = useRef(null);
  const suggestionsRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (
      suggestionsRef.current &&
      !suggestionsRef.current.contains(event.target)
    ) {
      setShowSuggestions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (cursorPosition !== null && textareaRef.current) {
      const textarea = textareaRef.current.resizableTextArea.textArea;
      textarea.focus();
      textarea.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  const getFilteredColumns = () => {
    const search = searchText.toLowerCase();
    return columns.filter((col) => col.name.toLowerCase().includes(search));
  };

  const calculateCaretPosition = (textarea, position) => {
    const computed = window.getComputedStyle(textarea);
    const div = document.createElement("div");
    const span = document.createElement("span");

    // Copy the style
    div.style.position = "absolute";
    div.style.visibility = "hidden";
    div.style.whiteSpace = "pre-wrap";
    div.style.wordWrap = "break-word";
    const textareaRect = textarea.getBoundingClientRect();
    div.style.top = textareaRect.top + window.scrollY + "px";
    div.style.left = textareaRect.left + window.scrollX + "px";
    div.style.width = textarea.offsetWidth + "px";
    div.style.height = textarea.offsetHeight + "px";
    div.style.fontFamily = computed.fontFamily;
    div.style.fontSize = computed.fontSize;
    div.style.lineHeight = computed.lineHeight;
    div.style.padding = computed.padding;
    div.style.border = computed.border;
    div.style.boxSizing = computed.boxSizing;

    // Mirror the textarea content up to the caret position
    const textContent = textarea.value.substring(0, position);
    const text = textContent
      .replace(/\n$/, "\n\n")
      .replace(/\n/g, "<br/>")
      .replace(/ /g, "&nbsp;");

    div.innerHTML = text;
    div.appendChild(span);
    document.body.appendChild(div);

    const rect = span.getBoundingClientRect();

    const caretLeft = rect.left;
    const caretTop = rect.bottom;

    document.body.removeChild(div);

    return {
      top: caretTop,
      left: caretLeft,
    };
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    const cursorPos = e.target.selectionStart;
    onChange(newValue);

    // Find the last @ before cursor
    const textBeforeCursor = newValue.slice(0, cursorPos);
    const lastAtIndex = textBeforeCursor.lastIndexOf("@");

    if (lastAtIndex !== -1 && cursorPos - lastAtIndex <= 20) {
      const searchTerm = textBeforeCursor.slice(lastAtIndex + 1);
      setSearchText(searchTerm);
      setShowSuggestions(true);

      const textarea = textareaRef.current.resizableTextArea.textArea;
      const { top, left } = calculateCaretPosition(textarea, cursorPos);

      setSuggestionsPosition({ top, left });
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (columnName) => {
    const textarea = textareaRef.current.resizableTextArea.textArea;
    const cursorPos = textarea.selectionStart;
    const textBeforeCursor = value.slice(0, cursorPos);
    const lastAtIndex = textBeforeCursor.lastIndexOf("@");

    const beforeAt = value.slice(0, lastAtIndex);
    const afterCursor = value.slice(cursorPos);

    const formattedColumnName = columnName.includes(" ")
      ? "`" + columnName + "`"
      : columnName;
    const newValue = `${beforeAt}@${formattedColumnName} ${afterCursor}`;

    onChange(newValue);
    const newCursorPosition = lastAtIndex + formattedColumnName.length + 2;
    setCursorPosition(newCursorPosition);
    setShowSuggestions(false);
  };

  return (
    <div className={Styles.textareaContainer}>
      <Input.TextArea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        className={Styles.textareaInput}
        rows={3}
        placeholder={placeholder}
      />
      {showSuggestions &&
        getFilteredColumns().length > 0 &&
        createPortal(
          <div
            className={`${Styles.suggestionsContainer} ${
              showSuggestions ? "visible" : ""
            }`}
            style={{
              top: suggestionsPosition.top,
              left: suggestionsPosition.left,
            }}
            ref={suggestionsRef}
          >
            {getFilteredColumns().map((col) => (
              <div
                key={col.name}
                className={Styles.suggestionItem}
                onClick={() => handleSuggestionClick(col.name)}
              >
                @{col.name}
              </div>
            ))}
          </div>,
          document.body
        )}
    </div>
  );
};

export default AutocompleteTextarea;
