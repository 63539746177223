import { notification } from 'antd';
import { duration } from 'moment';
import { useState, useRef } from 'react';
import { getErrorMessageFromResponse, shortenStr, truncateString } from 'src/lib/utils';
import { SqlToolApiServices } from '../sqlToolApiServices';

export default function useSqlToolTablesHook({ mAiApp, selectedConnection, setSqlData, virtualTable }) {
  const [exploreDataBaseDrawer, setExploreDataBaseDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const formRef = useRef();

  const addConnectionlevelTable = async (val) => {
    try {
      setLoading(true);
      const payload = {
        connection_id: selectedConnection,
        ...val
      }
      const res = await SqlToolApiServices.postSqlConnectionLevelData(mAiApp, payload);
      setUnsavedChanges(false);
      notification.success({
        message: `${virtualTable ? 'Virtual table' : 'Table'}Table added successfully`,
        duration: 5,
        placement: "bottomRight"
      })
      setSqlData(res.data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: truncateString(msg, 200),
        duration: 5,
        placement: 'bottomRight'
      })
    }
  }

  return {
    exploreDataBaseDrawer,
    setExploreDataBaseDrawer,
    formRef,
    addConnectionlevelTable,
    loading,
    unsavedChanges,
    setUnsavedChanges
  }
}