import { css } from "@linaria/core";

const list = css`
  .ant-list-header {
    border-bottom: none !important;
    padding-bottom:0 !important;
  }
`

const listHeader = css`
  display: flex;
  flex-direction: row;
  color: white;
  border: none;
  padding: 24px;
`;

const listItem = css`
  margin-top: 1rem;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  font-size: 14px;
  background: var(--color-charcoal-surface-1);
  color: var(--color-white);
  gap:8px;
  @media screen and (min-width:768px) {
    padding: 24px;
    flex-direction: row;
  }
`;

const listItemInner = css`
  .list-item-label {
    color:var(--color-40-black);
    margin-bottom:0;
  }
  .list-item-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
  }
`

const listItemRemoveUser = css`
  border:none;
  background:transparent;
  font-weight: 700;
  cursor: pointer;
`;

const addUserButton = css`
  background: #DB5A4B !important;
  color: white !important;
  font-weight: 600 !important;
  border: 0 !important;
`;

const btnsContainer = css`
  gap:8px;
  width:100%;
  justify-content: flex-end;
  flex-wrap:nowrap !important;
  margin: 24px 0;

  > button {
    width:50%;
  }
  @media screen and (min-width:768px) {
    > button {
      width:auto;
    }
  }
`

export default {
  list,
  listHeader,
  listItem,
  listItemInner,
  listItemRemoveUser,
  addUserButton,
  btnsContainer
};
