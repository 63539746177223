export default function useFixedTopBarHook({ appContext }) {
  const userEmail = appContext.metaData?.email;
  const data = appContext?.clientSpaceData || {};
  const metaData = appContext?.metaData || {};
  const isSvg = data?.client_space?.icon?.startsWith("data:image/svg+xml;base64,");

  const logOut = () => {
    appContext.logout();
  };

  let name = appContext?.metaData?.email;
  if (appContext?.metaData?.first_name) {
    name = appContext?.metaData?.first_name + " " + appContext?.metaData?.last_name;
  }
  return {
    data,
    isSvg,
    name,
    userEmail,
    logOut,
    metaData,
  }
}