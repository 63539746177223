import { css } from '@linaria/core';

const container = css`
  .ant-checkbox-disabled + span {
    color: var(--color-white);
  }
`;

const list = css`
  .ant-list-header {
    border-bottom: none !important;
    padding-bottom:0 !important;
  }
  margin-bottom: 1rem;
`

const listHeader = css`
  display: flex;
  flex-direction: row;
  color: white;
  border: none;
  padding: 24px;
`;

const listItem = css`
  margin-top: 1rem;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  font-size: 14px;
  background: var(--color-charcoal-surface-1);
  color: var(--color-white);
  gap:8px;
  @media screen and (min-width:768px) {
    padding: 24px;
    flex-direction: row;
  }
`;

const listItemInner = css`
  .list-item-label {
    color:var(--color-40-black);
    margin-bottom:0;
  }
  .list-item-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom:0;
  }
`;

const deleteContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;


export default {
  list,
  listHeader,
  listItem,
  listItemInner,
  container,
  deleteContainer
}