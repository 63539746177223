import { PlusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Row, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButtonPrimary, RoundedPrimaryButton } from "src/components/basic";
import Style from "./fileUpload.style";

export default function FileUploadForm({ uploadFile, sessionId, createSesionWithFile }) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  return (
    <div>
      <div className={Style.headingText}>Upload file</div>
      <Form
        ref={formRef}
        className={Style.form}
        requiredMark={false}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 18,
        }}
        onFinish={async (val) => {
          setLoading(true);
          if (sessionId) {
            await uploadFile(val);
          } else { 
            await createSesionWithFile(val);
          }
          setLoading(false);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          labelAlign="left"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  if (!!!value.trim().length) {
                    return Promise.reject(new Error("Name is required."));
                  }
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            },
            {
              required: false,
              message: "Name is required.",
            },
          ]}
        >
          <Input placeholder="Enter name of the file" style={{ borderRadius: "5px" }} maxLength={15} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          labelAlign="left"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  if (!!!value.trim().length) {
                    return Promise.reject(new Error("Description is required."));
                  }
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            },
            {
              required: false,
              message: "Description is required.",
            },
          ]}
        >
          <TextArea
            placeholder="Enter description about what is there in the file"
            showCount
            style={{ borderRadius: "5px" }}
            maxLength={60}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item
          name="file"
          labelAlign="left"
          label="File"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  if (value.status === "removed") {
                    return Promise.reject(new Error("File is required."));
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error("File is required."));
                }
              },
            },
          ]}
        >
          <Uploader />
        </Form.Item>
        <Row justify="end">
          <RoundedPrimaryButton htmlType="submit" loading={loading}>
            Done
          </RoundedPrimaryButton>
        </Row>
      </Form>
    </div>
  );
}

function Uploader({ onChange }) {
  return (
    <Upload
      accept=".xlsx, .csv, .pdf, .docx, .png, .jpeg, .jpg, .gif"
      showUploadList={true}
      maxCount={1}
      beforeUpload={(file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        return false;
      }}
      onChange={(event) => {
        const file = event.file;
        // const formData = new FormData();
        // formData.append("file", file);
        onChange(file);
      }}
    >
      <RoundedOutlinedButtonPrimary>
        <PlusCircleOutlined />
        &nbsp;Upload
      </RoundedOutlinedButtonPrimary>
    </Upload>
  );
}
