import { CopyOutlined } from "@ant-design/icons";
import { Form, Input, Tooltip } from "antd";
import React, { useState } from "react";
import {
  DATA_SOURCES,
  DATA_SOURCE_TYPE,
  SERVICE_ACCOUNT_EMAIL,
} from "../../constants";
import * as Styles from "./styles";

const DataSourceSelection = ({ form }) => {
  const [selectedSource, setSelectedSource] = useState(null);
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const handleSelect = (type) => {
    const selectedSource = DATA_SOURCES.find((source) => source.type === type);
    if (selectedSource && !selectedSource.disabled) {
      setSelectedSource(type);
      form.setFieldsValue({ dataSourceType: type });
      form.resetFields(["googleSheetUrl", "databaseConnection"]);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(SERVICE_ACCOUNT_EMAIL).then(() => {
      setTooltipText("Copied!");
      setTimeout(() => setTooltipText("Copy to clipboard"), 2000);
    });
  };

  return (
    <div className={Styles.container}>
      <Form.Item
        name="dataSourceType"
        rules={[{ required: true, message: "Please select a data source" }]}
      >
        <div className={Styles.featuresGrid}>
          {DATA_SOURCES.map((source) => (
            <div
              key={source.type}
              className={`${Styles.featureCard} ${
                selectedSource === source.type ? Styles.selectedCard : ""
              } ${source.disabled ? Styles.disabledCard : ""}`}
              onClick={() => handleSelect(source.type)}
            >
              <div className={Styles.featureContent}>
                <div className={Styles.featureIcon}>{source.icon}</div>
                <h3 className={Styles.featureTitle}>{source.title}</h3>
                <p className={Styles.featureDescription}>
                  {source.disabled ? "Coming soon" : "Connect and analyze data"}
                </p>
                {source.disabled && (
                  <span className={Styles.soonBadge}>Soon</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </Form.Item>

      {selectedSource === DATA_SOURCE_TYPE.GOOGLE_SHEETS && (
        <div className={Styles.inputContainer}>
          <Form.Item
            label="Google Sheet URL"
            name="googleSheetUrl"
            rules={[
              { required: true, message: "Please enter Google Sheet URL" },
              { type: "url", message: "Please enter a valid URL" },
            ]}
          >
            <Input placeholder="Paste your Google Sheet URL here" />
          </Form.Item>

          <div className={Styles.infoBox}>
            <span className={Styles.infoText}>
              Important: Share your sheet with our service account
            </span>
            <div className={Styles.serviceAccountContainer}>
              <span className={Styles.serviceAccount}>
                {SERVICE_ACCOUNT_EMAIL}
              </span>
              <Tooltip title={tooltipText}>
                <CopyOutlined
                  className={Styles.copyIcon}
                  onClick={handleCopy}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataSourceSelection;
