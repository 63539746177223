import {
  Col,
  Input,
  Row,
  Space,
  DatePicker,
  Skeleton,
  Radio,
  Button,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Style from './conversation.style';
import ConversationCard from './common/ConversationCard';
import { useConversations } from 'src/modules/CustomGpt/hooks/useConversations';
import ConversationSelect from './common/ConversationSelect';
import {
  FEEDBACK_CATEGORY,
  FEEDBACK_TYPE,
  JIRA_STATUS,
  VIEW_BY_CONVO,
} from 'src/modules/CustomGpt/constants/constants';
import ChatView from './common/ChatView';
import FeedbackModal from './common/FeedbackModal';
import ConditionalRender from 'src/components/conditionalRender';
import { PrimaryButton } from 'src/components/basic';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import ConfigurationIcon from 'src/components/ConfigurationIcon';
import { CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const Conversations = ({ id, backToChatAppHandler }) => {
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [inVertical, setInVertical] = useState(false);
  const {
    isLoading,
    feedbackData,
    users,
    filterHandler,
    handleInput,
    handleDatePicker,
    handleFilterOnBlur,
    totalItems,
    currentPage,
    handlePageChange,
    handleClick,
    handleResolve,
    feedbackSessionData,
    viewBy,
    handleViewByChange,
    isFeedbackChatLoading,
    appId,
    openFeedBackFormModal,
    closeFeedBackmodal,
    feedbackFormModal,
    feedbackFormData,
    firstCardIndex,
    setIsFeedbackChatLoading,
    defaultJiraStatus,
    showClear,
    resetFilter,
  } = useConversations({ id });

  const {inDesktop, windowWidth} = useContext(PageLayoutContext);

  useEffect(()=>{
    let largeSize = windowWidth >= 992;
    if (largeSize){
      setOpenMobileFilter(false);
    }
    setInVertical(largeSize)
  }, [windowWidth]);

  return (
    <>
      <div className={Style.viewByParent}>
        <Radio.Group
          value={viewBy}
          onChange={handleViewByChange}
          className={Style.radioStyle}
          buttonStyle='solid'
        >
          {VIEW_BY_CONVO.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <Row justify={inVertical ? 'end': 'space-between'} style={{ margin: '24px 0', gap:'8px' }}>
          <ConditionalRender shouldRender={!inVertical}>
            <button className={Style.filterMenuBtn} onClick={()=>{
              setOpenMobileFilter(true);
            }}>
              <ConfigurationIcon size={32} />
            </button>
          </ConditionalRender>
          <ConditionalRender shouldRender={id}>
            <PrimaryButton
              className="small"
              style={{width:'auto'}}
              onClick={backToChatAppHandler}>
              Go to assistant
            </PrimaryButton>
          </ConditionalRender>
      </Row>
      <div className={Style.filterDrawer + `${openMobileFilter ? ' expand':''}`}>
        <div className={Style.filterParent + `${openMobileFilter ? ' expand':''}`}>
          <div className={Style.filterContainer + `${openMobileFilter ? ' expand':''}`}>
              <ConditionalRender shouldRender={openMobileFilter}>
                <Row justify={'end'}>
                  <button className={Style.filterMenuBtn} onClick={()=>{setOpenMobileFilter(false)}}><CloseOutlined /></button>
                </Row>
                <p style={{fontWeight:500}}>Filter feedback</p>
              </ConditionalRender>
              <ConversationSelect
                placeholder='User'
                options={users.length && users}
                isSearchable={true}
                mode='multiple'
                tagCount={1}
                onChange={(value, options) =>
                  filterHandler(value, options, 'User')
                }
                onBlur={handleFilterOnBlur}
              />
              <ConversationSelect
                placeholder='Feedback Category'
                options={FEEDBACK_CATEGORY}
                mode='multiple'
                tagCount={1}
                showSearch={false}
                onChange={(value, options) =>
                  filterHandler(value, options, 'Category')
                }
                onBlur={handleFilterOnBlur}
              />
              <ConversationSelect
                placeholder='Feedback Type'
                options={FEEDBACK_TYPE}
                defaultValue={'DISLIKED'}
                onChange={(value, options) =>
                  filterHandler(value, options, 'Type')
                }
              />
              <ConversationSelect
                placeholder='Status'
                options={JIRA_STATUS}
                mode='multiple'
                tagCount={1}
                defaultValue={defaultJiraStatus}
                onChange={(value, options) =>
                  filterHandler(value, options, 'Status')
                }
              />
              <Input
                placeholder='Search'
                className={Style.inputStyle}
                onBlur={handleInput}
              />
              <RangePicker
                className={Style.datePickerStyle}
                onChange={handleDatePicker}
              />
              <Button
                onClick={resetFilter}
                type="link"
                style={
                  !showClear
                    ? { display: 'none' }
                    : {
                        background: 'transparent',
                        height: '32px',
                        color: '#fff',
                      }
                }
              >
                Reset
              </Button>
          </div>
        </div>
      </div>
      <Row className={Style.fullHeight}>
        <Col xs={24} lg={7} className={Style.scrollableLeft}>
          <div className={Style.content}>
            <Space
              direction={inVertical ? 'vertical' : 'horizontal'}
              size='middle'
              align='stretch'
              style={{ display: 'flex', gap:8 }}
            >
              {isLoading ? (
                // Show skeleton while loading
                <Skeleton active avatar paragraph={{ rows: 4 }} />
              ) : (
                feedbackData?.data?.map((iter, idx) => {
                  const isResolved =
                    iter.resolution_status === 'UNRESOLVED' ? false : true;
                  const categories = iter?.category?.map((val) => val);
                  const initial = iter?.email?.[0]?.toUpperCase();
                  return (
                    <ConversationCard
                      initial={initial}
                      uId={idx}
                      key={iter.id}
                      question={iter.input_text}
                      reaction={iter.user_reaction}
                      feedback={iter.feedback}
                      category={iter.category}
                      timeStamp={iter.input_at}
                      name={
                        iter.first_name
                          ? iter.first_name.concat(' ').concat(iter.last_name)
                          : iter.email
                      }
                      isResolved={isResolved}
                      onClick={() => handleClick(iter.chat_id)}
                      handleResolve={handleResolve}
                      categories={categories}
                      openFeedBackFormModal={openFeedBackFormModal}
                      closeFeedBackmodal={closeFeedBackmodal}
                      feedbackFormData={feedbackFormData}
                      feedbackFormModal={feedbackFormModal}
                      cardId={iter.id}
                      isFirstCard={idx === firstCardIndex}
                      feedbackLoader={setIsFeedbackChatLoading}
                      resolutionComment={iter.resolution_comment}
                      resolvedBy={
                        iter.resolved_by_first_name
                          ? iter.resolved_by_first_name
                              .concat(' ')
                              .concat(iter.resolved_by_last_name)
                          : iter.resolved_by_email
                      }
                      resolvedOn={iter.resolved_on}
                      jira_ticket_key={iter.jira_ticket_key}
                      jira_ticket_status={iter.jira_ticket_status}
                      jira_ticket_url={iter.jira_ticket_url}
                    />
                  );
                })
              )}
            </Space>
          </div>
        </Col>
        <Col span={1}></Col>
        <Col xs={24} lg={16} className={Style.scrollableRight}>
          <ChatView
            feedbackData={feedbackSessionData}
            isFeedbackChatLoading={isFeedbackChatLoading}
            appId={appId}
            openFeedBackFormModal={openFeedBackFormModal}
            closeFeedBackmodal={closeFeedBackmodal}
            feedbackFormData={feedbackFormData}
            feedbackFormModal={feedbackFormModal}
            handleResolve={handleResolve}
            dataPresent={feedbackData?.data?.length === 0 || false}
          />
        </Col>
      </Row>
      <FeedbackModal
        modalOpen={feedbackFormModal}
        closeModal={closeFeedBackmodal}
        handleResolve={handleResolve}
        data={feedbackFormData}
      />
    </>
  );
};

export default Conversations;
