import React from 'react';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import {
  Select as AntSelect,
} from 'antd';

const myStyle = css`
`;

function _Select({
  className = '',
  label,
  children,
  ...props
}) {
  return (
    <AntSelect
      {...props}
      className={cx(myStyle, className)}
      label={label}
    >
      {children}
    </AntSelect>
  );
}

/** @type {any} Primary button which has primary color(blue) as background and white text */
const MarkeySelect = styled(_Select)`
  --label: ${(props) => `'${props.label || ''}'`};

  .ant-select-selector {
    height: 40px !important;
    align-items: center;
    &::before {
      content: var(--label);
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      color: #C4C4C4;
    }

    .ant-select-selection-placeholder {
      text-align: right;
    }
  }
`;

MarkeySelect.Option = AntSelect.Option;
MarkeySelect.OptGroup = AntSelect.OptGroup;

export default MarkeySelect;
