import { Card, Tabs, Button, Skeleton } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import {
  FORMATTED_QUESTION_ANSWER,
  QUESTIONS,
} from 'src/modules/socialInsights/constants/constants';
import { useEffect, useState } from 'react';
import Style from './questionAnswerSideBar.style';

const { TabPane } = Tabs;

function SkeletonLoader() {
  return (
    <Card bordered={true} style={{ width: '100%' }}>
      <Tabs defaultActiveKey='0'>
        {[...Array(QUESTIONS.length)].map((_, index) => (
          <TabPane
            tab={<Skeleton.Input style={{ width: 150 }} active />}
            key={index}
          >
            <Skeleton paragraph={{ rows: 3 }} active />
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
}

function QuestionTabs() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const getMoreInfoIcon = () => {
    return (
      <svg
        className='more-info-icon'
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        fill="none"
        viewBox="0 0 16 17"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M14 6.5v-4m0 0h-4m4 0l-6 6m-1.333-6H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C2 4.02 2 4.58 2 5.7v5.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874c.428.218.988.218 2.108.218h5.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C14 12.98 14 12.42 14 11.3V9.833"
        ></path>
      </svg>
    )
  }
  
  return loading ? (
    <SkeletonLoader />
  ) : (
    <Card bordered={true} style={{ width: '100%' }}>
      <Tabs defaultActiveKey='0'>
        {QUESTIONS.map((question, index) => (
          <TabPane tab={question} key={index}>
            <h3>{FORMATTED_QUESTION_ANSWER[question].uiQuestion}</h3>
            <p style={{ fontSize: '13px', fontWeight: '400' }}>
              {FORMATTED_QUESTION_ANSWER[question].answer}
            </p>{' '}
            <Button
              type='link'
              icon={<>{getMoreInfoIcon()}</>}
              onClick={() =>
                window.open(FORMATTED_QUESTION_ANSWER[question].link, '_blank')
              }
            >
              More Info
            </Button>
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
}

export default QuestionTabs;
