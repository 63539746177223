import React from 'react';
import ListingTable from './components/ListingTable';
import { OutlinedButton } from './components/Button';
import { PlusOutlined } from '@ant-design/icons';
import AppUrls from 'src/constants/appUrls';
import AppContext from 'src/context/appContext';
import {
  column_media_listing,
  MEDIA_PLAN_PAGE,
} from '../../constants/constants';
import ActionMenu from './ActionMenu';
import useMediaPlanListing from './useMediaPlanListing';
import './MediaPlansListing.less';

const MediaPlansListing = () => {
  const appContext = React.useContext(AppContext.Context);
  const { loading, page, onPaginationChange, mediaData, history, clientId } =
    useMediaPlanListing();

  return (
    <div className='media-listing-parent'>
      <div className='page-header-layout'>
        <div>
          <h3>
            {MEDIA_PLAN_PAGE.MEDIA_HEADER} :{' '}
            {appContext?.clientSpaceData?.client_space?.name}
          </h3>
          <p className='media-description'>
            {MEDIA_PLAN_PAGE.MEDIA_DESCRIPTION}
          </p>
        </div>
        <div>
          <OutlinedButton
            name={MEDIA_PLAN_PAGE.CREATE_MEDIA_PLAN}
            icon={<PlusOutlined />}
            onClick={() =>
              history.push({
                pathname: AppUrls.CREATE_MEDIA_PLAN(clientId)
              })
            }
          />
          <p className='media-btn-description'>
            {MEDIA_PLAN_PAGE.MEDIA_BTN_DESCRIPTION}
          </p>
        </div>
      </div>
      <div>
        <div style={{ marginTop: 32 }}>
          <ListingTable
            data={mediaData}
            columns={column_media_listing}
            ActionMenu={ActionMenu}
            currentPage={page}
            onPaginationChange={onPaginationChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaPlansListing;
