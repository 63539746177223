import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ConditionalRender from "src/components/conditionalRender";
import { shortenStr, truncateString } from "src/lib/utils";
import Style from "../../aiApp.style";
import ReactJson from 'react-json-view';
import { format } from "sql-formatter";

export default function ActionParser({ data, actionName='' }) {
  let isJson = false;
  let parsedData = {};

  try {
    parsedData = JSON.parse(data);
    if (parsedData?.sql) {
      parsedData['sql'] = format(parsedData?.sql, { language: "snowflake" })
    }
    isJson = true;
  } catch (error) {}
  return (
    <>
      <ConditionalRender shouldRender={isJson}>
        <div className={Style.actionParserContainer}>
          <ReactJson src={parsedData} name={false} collapsed={1} displayDataTypes={false} theme="bright"/>
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!isJson}>
        <div className={Style.actionParserContainer}>
          <ReactMarkdown className={Style.markdown} remarkPlugins={[remarkGfm]}>
            {data}
          </ReactMarkdown>
        </div>
      </ConditionalRender>
    </>
  );
}
