import { notification } from "antd";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { getErrorMessageFromResponse } from "src/lib/utils";
import NcApiServices from "../services/NcApiServices";
import appUrls from "src/constants/appUrls";

export const useAnalysisOverviewHook = () => {
  const { clientId, analysisId } = useParams();
  const [loading, setLoading] = useState(false);
  const [analysisOverview, setAnalysisOverview] = useState(null);
  const history = useHistory();
  const [jobDrawer, setJobDrawer] = useState(false);
  const [latestJobTimeStamp, setLatestJobTimeStamp] = useState();

  const getAnalysisOverview = async (analysisId) => {
    try {
      setLoading(true);
      const res = await NcApiServices.getAnalysisOverview(analysisId);
      if (res?.success) {
        setAnalysisOverview(res?.data);
        findLatestDoneJob(res?.data?.job_triggers);
      } else {
        setAnalysisOverview(null);
        notification.warning({
          duration: 5,
          message: "No data available for the selected analysis.",
          placement: "bottomRight",
        });
      }
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoading(false);
    }
  };

  const handleClickForName = () => {
    history.push(appUrls.CREATE_LISTING_SCREEN(clientId, analysisId), {
      mode: "Done",
    });
  };

  useEffect(() => {
    if (analysisId) {
      getAnalysisOverview(analysisId);
    }
  }, [analysisId]);

  function findLatestDoneJob(jobEntries) {
    let latestJob = null;

    jobEntries.forEach((job) => {
      if (job.state === "DONE") {
        if (
          !latestJob ||
          new Date(job.job_triggered_at) > new Date(latestJob.job_triggered_at)
        ) {
          latestJob = job;
        }
      }
    });

    setLatestJobTimeStamp(latestJob);
  }

  const onChatButtonClick = () => {
    const url = appUrls.APP_PAGE(clientId, analysisOverview?.advanced_settings?.ai_app_id);
    window.open(url, "_blank");
  }
  
  return {
    analysisOverview,
    loading,
    analysisId,
    history,
    clientId,
    jobDrawer,
    setJobDrawer,
    handleClickForName,
    onChatButtonClick,
    latestJobTimeStamp,
  };
};
