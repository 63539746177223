import { message, notification } from "antd";
import { useState, useEffect, useRef } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import CustomGptApiServices from "../services/customGptApiServices";

export default function useFineTuningToolHook({ id, aiAppData, metaData, updateCustomApp }) {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({ strategies: [undefined] });
  const [configdata, setConfigData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [getCapabilityData, setCapabilityData] = useState({});

  const getInitialValues = async () => {
    try {
      setLoading(true);
      const payload = {
        capability_name: "StrategyCapability",
        filters: {
          level: "global",
        },
      };
      const res = await CustomGptApiServices.postStrategyCapability(id, payload);
      setInitialValues(res.data);
      setLoading(false);
    } catch (error) {
      const fineTuningToolConfig = metaData?.capability_config?.find((ele) => ele.capability_name === "StrategyCapability");
      const obj = {
        allow_system_prompt_injection: fineTuningToolConfig?.config?.allow_system_prompt_injection?.default_value,
        description: fineTuningToolConfig?.config?.description?.default_value,
        name: fineTuningToolConfig?.config?.name?.default_value,
        strategy_summarizer_llm_prompt: fineTuningToolConfig?.config?.strategy_summarizer_llm_prompt?.default_value,
      };
      setInitialValues({ ...initialValues, ...obj });
      setLoading(false);
    }
  };

  const saveFineTuneTool = async (val) => {
    try {
      setConfigureLoading(true);
      const payload = {
        capability_name: "StrategyCapability",
        fields_config: {
          ...val,
          filters: {
            level: "global",
          },
        },
      };
      const res = await CustomGptApiServices.configureCapabilities(id, payload);
      updateCustomApp(res.data);
      setInitialValues(val);
      notification.success({
        duration: 5,
        message: "Fine tuning configured successfully",
        placement: "bottomRight",
      });
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  return {
    formRef,
    initialValues,
    loading,
    configdata,
    saveFineTuneTool,
    configureLoading,
  };
}
