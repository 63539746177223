import {
  CheckCircleOutlined,
  ColumnWidthOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  LeftOutlined,
  ScheduleOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, Form, message, Modal, Spin, Steps } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import BasicInfoForm from "../components/BasicInfoForm";
import InputColumnsForm from "../components/Columns/InputColumnsForm";
import OutputColumnsForm from "../components/Columns/OutputColumnsForm";
import DataSourceSelection from "../components/DataSourceSelection";
import ReviewForm from "../components/ReviewForm";
import ScheduleTaskForm from "../components/ScheduleTaskForm";
import { DATA_SOURCE_TYPE } from "../constants";
import LabelerApiService from "../services/api";
import * as Styles from "./CreateLabelingTask.styles";

const CreateTask = ({ history, match }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [columns, setColumns] = useState([]);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const clientSpaceId = match.params.clientId;

  const fetchGoogleSheetColumns = async (googleSheetUrl) => {
    setIsLoading(true);
    try {
      const response = await LabelerApiService.getGoogleSheetSchema({
        file_url: googleSheetUrl,
      });
      const data = response.data;
      const fetchedColumns = data.structured_data.columns.map((col) => ({
        name: col.name,
      }));
      setColumns(fetchedColumns);
      return true; // Indicate success
    } catch (error) {
      message.error("Failed to fetch Google Sheet columns");
      return false; // Indicate failure
    } finally {
      setIsLoading(false);
    }
  };

  const formatLabelingTaskData = (values, scheduleData = null) => {
    const formattedData = {
      name: values.name,
      description: values.description,
      data_source: {
        type: values.dataSourceType,
        ...(values.dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS
          ? { sheet_url: values.googleSheetUrl }
          : { sql_connection: parseInt(values.databaseConnection) }),
      },
      input_columns: values.inputColumns,
      output_columns: values.outputColumns,
    };

    if (scheduleData) {
      formattedData.start_date = dayjs(scheduleData.startDate).format(
        "YYYY-MM-DD"
      );
      formattedData.start_time = dayjs(scheduleData.startTime).format(
        "HH:mm:ss"
      );
      formattedData.repeat_frequency =
        scheduleData.repeatFrequency.toUpperCase();
      formattedData.end_date = scheduleData.endDate
        ? dayjs(scheduleData.endDate).format("YYYY-MM-DD")
        : null;
    }

    return formattedData;
  };

  const createLabelingTask = async (formattedData) => {
    try {
      setIsLoading(true);
      const response = await LabelerApiService.createLabelingTask(
        clientSpaceId,
        formattedData
      );
      if (response.success) {
        message.success("Task created successfully!");
        history.push(`/client/${clientSpaceId}/labeler`);
      } else {
        message.error("Failed to create task. Please try again.");
      }
    } catch (error) {
      console.error("Error creating task:", error);
      message.error("An error occurred while creating the task.");
    } finally {
      setIsLoading(false);
    }
  };

  const next = async () => {
    try {
      await form.validateFields();
      const currentValues = form.getFieldsValue();

      if (
        currentStep === 1 &&
        currentValues.dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS
      ) {
        const googleSheetUrl = currentValues.googleSheetUrl;
        const success = await fetchGoogleSheetColumns(googleSheetUrl);
        if (!success) {
          // Do not proceed to the next step if fetching columns failed
          return;
        }
      }

      setCurrentStep(currentStep + 1);
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const showTaskOptions = () => {
    form
      .validateFields()
      .then(() => {
        Modal.confirm({
          title: "Task Options",
          content: "Do you want to run this task now or schedule it for later?",
          okText: "Run Now",
          cancelText: "Schedule",
          icon: <ScheduleOutlined />,
          onOk() {
            handleFinish(form.getFieldsValue(true));
          },
          onCancel() {
            setIsScheduleModalVisible(true);
          },
        });
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleFinish = async (values) => {
    const formattedData = formatLabelingTaskData(values);
    await createLabelingTask(formattedData);
  };

  const handleScheduleSubmit = async (scheduleData) => {
    const formData = form.getFieldsValue(true);
    const formattedData = formatLabelingTaskData(formData, scheduleData);
    await createLabelingTask(formattedData);
    setIsScheduleModalVisible(false);
  };

  const getSelectedInputColumns = () => {
    const values = form.getFieldValue("inputColumns") || [];
    return values.map((column) => column.name);
  };

  const steps = [
    {
      title: "Basic Info",
      icon: <CheckCircleOutlined />,
      content: <BasicInfoForm form={form} />,
    },
    {
      title: "Data Source",
      icon: <DatabaseOutlined />,
      content: <DataSourceSelection form={form} />,
    },
    {
      title: "Input Columns",
      icon: <ColumnWidthOutlined />,
      content: <InputColumnsForm columns={columns} />,
    },
    {
      title: "Output Columns",
      icon: <FileDoneOutlined />,
      content: (
        <OutputColumnsForm
          form={form}
          columns={columns}
          selectedInputColumns={getSelectedInputColumns()}
        />
      ),
    },
    {
      title: "Review",
      icon: <SmileOutlined />,
      content: (
        <ReviewForm
          formData={form.getFieldsValue(true)}
          clientSpaceId={clientSpaceId}
        />
      ),
    },
  ];

  return (
    <div className={Styles.createTaskContainer}>
      <div className={Styles.contentParent}>
        <div className={Styles.backButton} onClick={() => history.goBack()}>
          <LeftOutlined className={Styles.arrowIcon} /> Back
        </div>

        <Steps
          size="small"
          current={currentStep}
          className={Styles.stepsStyle}
          items={steps.map((step) => ({
            title: step.title,
            icon: step.icon,
          }))}
        />

        <Form form={form} layout="vertical">
          <Spin spinning={isLoading}>
            <div className={Styles.stepContent}>
              {steps[currentStep].content}
            </div>
          </Spin>

          <div className={Styles.buttonContainer}>
            {currentStep > 0 && (
              <Button type="default" onClick={prev} disabled={isLoading}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button type="primary" onClick={next} disabled={isLoading}>
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={showTaskOptions}
                disabled={isLoading}
              >
                Create Task
              </Button>
            )}
          </div>
        </Form>
      </div>

      <Modal
        title="Schedule Task"
        open={isScheduleModalVisible}
        onCancel={() => setIsScheduleModalVisible(false)}
        footer={null}
      >
        <ScheduleTaskForm onScheduleSubmit={handleScheduleSubmit} />
      </Modal>
    </div>
  );
};

export default withRouter(CreateTask);
