import { css } from "@linaria/core";

const cardContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;

const opportunitiesPageWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const opportunitiesCell = css`
  padding: 0 !important;
`;

const setNodesButton = css`
  position: absolute;
  top: -11px;
  right: -11px;
  font-size: 18px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
`;

const opportunityNodes = css`
  font-size: 12px;
  color: white;
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const opportunitiesList = css`
  .ant-list-item.ant-list-item-no-flex {
    border: None;
  }
`;

const nodesCard = css`
  color: white;
  margin-bottom: 8px;
  padding: 5px;
  /* border: 1px solid white; */
  border-radius: 5px;
  margin: 5px;
  margin-left: 0;
  margin-right: 10px;
  background-color: #3c3b3b;
`;

const ribbon = css`
  &.ant-ribbon.ant-ribbon-placement-start {
    top: -29px;
    left: -34px !important;
    // background: #dc5a4b;
    // color: white;
  }
`;

const sectionHeader = css`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
`;

const viewDetails = css`
  justify-content: right;
  position: absolute !important;
  bottom: 24px !important;
  left: 24px !important;
  font-size: 18px;
  &.ant-btn.ant-btn-default {
    padding: 5px 10px !important;
  }
`;

const opportunitiesCardTitle = css`
  // width: 700px;
  padding: 0px 16px;
`;

const likeReactionIcon = css`
  font-size: 16px;
  &.anticon.anticon:hover {
    color: green;
  }
`;

const dislikeReactionIcon = css`
  font-size: 16px;
  &.anticon.anticon:hover {
    color: red;
  }
`;

const pinIcon = css`
  // position: absolute;
  // bottom: 24px !important;
  // right: 24px !important;
  font-size: 20px;
  cursor: pointer;

  .anticon:hover {
    color: #40a9ff;
  }
`;

const filtersContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // margin-bottom: 20px;
`;

const filtersContainerWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const jobTriggerSelect = css`
  height: 100%;
  width: 230px;
`;

const feedbackSelect = css`
  height: 100%;
  width: 210px;
  margin-right: 20px !important;
`;

const searchTextBox = css`
  .ant-input-wrapper.ant-input-group {
    height: 100%;
    .ant-input {
      height: 100%;
    }
  }
  height: 52px;
  margin-right: 20px !important;
  margin-bottom: 0 !important;
  width: 270px;
`;

const sourceDropdown = css`
  height: 100%;
  width: 255px;
  margin-right: 20px !important;
`;

const opportunityTypeCell = css`
  // width: 300px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: inset #ffffff33 0px 0 8px -2px;
  font-size: 12px;
`;

const darkTable = css`
  width: 100%;
  table {
    border-color: white;
  }
  .ant-table-thead {
    background-color: #171717;
  }
  .ant-table-body {
    background-color: #222222;
  }
  table > thead > tr > th {
    background-color: #171717;
    color: white;
    &.ant-table-cell-scrollbar {
      box-shadow: none;
    }
  }
  table > tbody > tr > td {
    background-color: #222222 !important;
    color: white;
    border-bottom: none;
  }
  .ant-empty-description {
    color: white;
  }

  .ant-pagination {
    li {
      border: 0px;
      background-color: #222222;
      color: white;
      button {
        background-color: #222222;
        color: white;
        border: none;
        :hover {
          background-color: #222222;
          color: white;
        }
      }
      a {
        border: 0px;
        background-color: #222222;
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    border: 1px solid white !important;
  }
`;

const actionsContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const actionsContainerWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  justify-content: center;
`;


const deleteOpportunityIcon = css`
  padding-left: 10px;
`;


const savedFilterPopup = css`
  .ant-select-item.ant-select-item-option {
    padding-left: 0;
    padding-right: 0;
  }
`;

const customDropdownHeader = css`
  display: flex;
  padding: 3px 0;
  background: inherit;
  font-size: 13px;
  align-items: center;
  width: 100%;
  background-color: var(--color-charcoal-surface-3);
  justify-content: center;
`;


const savedFilterPopover = css`
  .ant-popover-inner-content.ant-popover-inner-content {
    max-width: 300px !important;
    max-height: 200px !important;
    overflow-y: auto !important;
  }
`;


const savedFilterOptions = css`
  display: flex;
  flex-direction: row;
`;

const filterContent = css`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const filterName = css`
  text-overflow: ellipsis;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
`;

const filterDate = css`
  font-size: 10px;
`;

const userIcon = css`
  padding-left: 10px;
  align-self: center;
`;


const createOpportunityButton = css`
  &.ant-btn-primary {
    height: 100%;
  }
`;


const exportOpportunities = css`
  &.ant-btn-primary {
    height: 100% ;
  }
`;

const popOverTable = css`
  background-color: #222222;
  .ant-table-row {
    border: solid 1px black;
  }
  .ant-table-cell {
    padding: 1px 8px;
    padding-right: 0;
    color: black;
    border: none !important;
    font-size: 10px;
  }
`;

const popoverContent = css`
  padding: 0;
  .ant-popover-inner-content.ant-popover-inner-content {
    max-width: 300px !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    padding: 0;
  }
`;

const popOverValue = css`
  max-width: 190px;
  max-height: 3rem;
  overflow-y: auto;
`;

export default {
  cardContainer,
  opportunitiesCell,
  setNodesButton,
  opportunityNodes,
  opportunitiesList,
  nodesCard,
  ribbon,
  sectionHeader,
  viewDetails,
  opportunitiesCardTitle,
  opportunitiesPageWrapper,
  pinIcon,
  filtersContainer,
  filtersContainerWrapper,
  jobTriggerSelect,
  searchTextBox,
  sourceDropdown,
  likeReactionIcon,
  dislikeReactionIcon,
  feedbackSelect,
  darkTable,
  popOverTable,
  popoverContent,
  popOverValue,
  opportunityTypeCell,
  actionsContainer,
  actionsContainerWrapper,
  deleteOpportunityIcon,
  savedFilterPopup,
  customDropdownHeader,
  savedFilterPopover,
  savedFilterOptions,
  filterContent,
  filterName,
  filterDate,
  userIcon,
  createOpportunityButton,
  exportOpportunities,
};
