import { css } from '@linaria/core';

const form = css`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }
  
`;

const headingText = css`
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: 600;
`;

export default {
  form,
  headingText
}