import { Checkbox, Col, Drawer, message, notification, Row, Skeleton } from "antd";
import React from "react";
import { PrimaryButton, RoundedOutlinedButton, RoundedPrimaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import { AVAILABLE_CAPABILITY, NAVIGATOR_CLIENT_ID } from "../constants/constants";
import CustomGptApiServices from "../services/customGptApiServices";
import Style from "./capabilities.style";
import CapabilityDrawer from "./CapabilityDrawer";
import AppUrls from "src/constants/appUrls";
import Assets from "src/assets";
import ConfigurationIcon from "src/components/ConfigurationIcon";

export default function Capabilities({ metaData, initialValues, id, updateCustomApp, history, clientId, backToChatAppHandler }) {
  const [toolModal, setToolModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [toolData, setToolData] = React.useState(null);
  const [configData, setConfigData] = React.useState(null);
  const onClose = () => {
    setToolModal(false);
    setToolData(null);
    setConfigData(null);
  };

  const reload = () => {
    setLoading(true);
    setLoading(false);
  };
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <Row justify='end' style={{ margin: '24px 0', gap:'8px' }}>
          <ConditionalRender shouldRender={id}>
            <PrimaryButton
              className="small"
              onClick={backToChatAppHandler}>
              Go to assistant
            </PrimaryButton>
          </ConditionalRender>
        </Row>
        <Row gutter={[8, 8]}>
          {metaData?.capability_config?.map((item, index) => {
            if (AVAILABLE_CAPABILITY.includes(item?.capability_name)) {
              const initialCapabilityValues = initialValues?.capabilities?.find((ele) => ele.capability_name === item.capability_name);
              if (item.capability_name === "BackboneCapability" && clientId === NAVIGATOR_CLIENT_ID) {
                return null;
              }
              return (
                <Col xs={24} md={12} xl={8} key={`capability-config-${index}`}>
                  <ToolCard
                    setConfigData={setConfigData}
                    item={item}
                    setToolModal={setToolModal}
                    initialCapabilityValues={initialCapabilityValues}
                    metaData={metaData}
                    setToolData={setToolData}
                    id={id}
                    history={history}
                    clientId={clientId}
                  />
                </Col>
              );
            }
          })}
        </Row>
        <Drawer
          title={configData?.capability_display_name}
          maskClosable={true}
          placement="right"
          onClose={onClose}
          open={toolModal}
          destroyOnClose
          size="large"
          width={"88%"}
        >
          <CapabilityDrawer
            toolData={toolData}
            configData={configData}
            id={id}
            aiAppData={initialValues}
            updateCustomApp={updateCustomApp}
            onClose={onClose}
            reload={reload}
            clientId={clientId}
          />
        </Drawer>
      </ConditionalRender>
    </div>
  );
}

function ToolCard({
  id,
  item,
  setToolModal,
  metaData,
  setToolData,
  initialCapabilityValues,
  setConfigData,
  sqlV2 = false,
  history,
  clientId,
}) {
  const [isActive, setIsActive] = React.useState(initialCapabilityValues?.is_active || false);
  const [loading, setIsLoading] = React.useState(false);
  const capabilityConfig = metaData?.capability_config?.find((ele) => ele.capability_name === item?.capability_name);

  const connection = async (connectionFlag) => {
    try {
      setIsLoading(true);
      const payload = {
        capability_name: item?.capability_name,
        is_active: connectionFlag,
      };
      const res = await CustomGptApiServices.capabilityConnection(id, payload);
      notification.success({
        duration: 5,
        message: `${item.capability_display_name} ${connectionFlag ? "connected" : "disconnected"} successfully.`,
        placement: "bottomRight",
      });
      setIsActive(connectionFlag);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className={Style.toolCardContainer}>
      <div className={Style.toolCardName}>
          <Checkbox
            checked={isActive}
            disabled={loading}
            onChange={(val) => {
              if (!initialCapabilityValues) {
                setToolData(initialCapabilityValues);
                setConfigData(capabilityConfig);
                setToolModal(true);
              } else {
                connection(val.target.checked);
              }
            }}
          />
        <div>{item.capability_display_name}</div>
      </div>
      <Row justify="end">
        <button
          onClick={() => {
            setToolData(initialCapabilityValues);
            setConfigData(capabilityConfig);
            setToolModal(true);
          }}
          className={Style.toolCardButton}
        >
          Configure
          <ConfigurationIcon size={24}/>
        </button>
      </Row>
    </div>
  );
}
