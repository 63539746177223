import React from "react";

export default function CaretIconGenerator({ size=24, color='white', rotate=0 }) {
  const iconStyle = {
    width: `${size}px`,
    height: `${size}px`,
    display:'inline-block',
    color: color,
    transform: `rotate(${rotate}deg)`
  }
  return (
    <div style={iconStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M6 10l6 6 6-6"
        ></path>
      </svg>
    </div>
  );
}
