import React from "react";

export default function IconGenerator({ height, width, color='white', backgroundColor="#788f9c", withBorder=true, initial }) {
  const iconStyle = {
    backgroundColor: backgroundColor,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color,
    border: withBorder?'1px solid white': 'none',
    fontWeight: 'bold',
    fontSize: `${Math.min(width, height) * 0.5}px`, 
  }
  return (
    <div
      style={iconStyle}
    >
      {initial || 'A'}
    </div>
  );
}
