import { notification } from "antd";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import AppContext from 'src/context/appContext';
import { getErrorMessageFromResponse } from "src/lib/utils";
import HomePageApiServices from "../homePage/services/homePageApiService";

export default function useClientSpaceContainerHook(autoFetch = true) {
  const { clientId, id } = useParams();
  const appContext = useContext(AppContext.Context);
  const [loading, setLoading] = useState(true);

  const getClientData = async (id) => {
    try {
      const res = await HomePageApiServices.getClientList(id);
      if (res?.success) {
        appContext?.updateClientSpaceData(res?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  useEffect(() => {
    if (autoFetch) {
      getClientData(clientId);
    }
  }, []);
  return {
    loading,
    getClientData
  }
}