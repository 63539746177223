import React from 'react';
import { fields } from '../constants';
import Style from '../NewsAndCulture.style';
import QueryBuilder from 'react-querybuilder';
import { Drawer, Input, message, Tooltip } from 'antd';
import { CopyOutlined, SnippetsOutlined } from '@ant-design/icons';
import { SecondaryButton } from 'src/components/basic';
import useQueryBuilderHook from '../hooks/useQueryBuilderHook';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import InstagramQueryBuilder from './instagramQueryBuilder/InstagramQueryBuilder';

const CreateAnalysisQuery = ({
  drawerVisible,
  setDrawerVisible,
  queryName,
  queryData,
  addUpdateQuery,
  addUpdateQueryInstagram,
  setInstaFormData,
  instaFormData,
  UpdatedAnalysisCategories,
}) => {
  const { handleQueryChange, query, loading, numberOfPosts, setNumberOfPosts, handleCopy, handlePaste } =
    useQueryBuilderHook({
      queryData,
    });

  const queryFieldHeader = queryData.queryType.replace(/Queries$/, "");
  const queryTitle = `${queryFieldHeader.charAt(0).toUpperCase() + queryFieldHeader.substring(1).toLowerCase()} Query ${
    queryData?.query?.key + 1
  }`;

  let updatedFields = [];
  if (queryFieldHeader === "news") {
    updatedFields = fields.news.map((item) => {
      if (item.name === "news_categories") {
        return {
          ...item,
          values: UpdatedAnalysisCategories,
        };
      } else {
        return item;
      }
    });
  } else {
    updatedFields = fields[queryFieldHeader] || [];
  }

  return queryData ? (
    <Drawer
      title={
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>Query Builder</span>
          <div>
            <Tooltip title="Copy Query" zIndex={10000000}>
              <CopyOutlined onClick={handleCopy} style={{ marginRight: "10px", cursor: "pointer", fontSize: "18px" }} />
            </Tooltip>
            <Tooltip title="Paste Query" zIndex={10000000}>
              <SnippetsOutlined onClick={handlePaste} style={{ cursor: "pointer", fontSize: "18px" }} />
            </Tooltip>
          </div>
        </div>
      }
      width="90%"
      placement="right"
      onClose={() => {
        setDrawerVisible(false);
      }}
      open={drawerVisible}
      closable={true}
      className={Style.createAnalysisQueryContainer}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <ConditionalRender shouldRender={loading}>
        <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
          <LoadingSpinner centered={true} />
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.createAnalysisQueryContainer}></div>
        <h3 style={{ color: "white", fontWeight: "bold" }}>{queryTitle}</h3>

        <div style={{ overflowY: "auto", maxHeight: "calc(100vh - 160px)", scrollbarWidth: "none" }}>
          {queryFieldHeader !== "instagram" ? (
            <div className={Style.queryBuilder}>
              <div className={Style.articleNumContainer}>
                <label className={Style.articleNumLabel}>No. of Posts: </label>
                <Input
                  placeholder="Enter No. of Posts"
                  className={Style.articleNumInput}
                  onChange={(e) => setNumberOfPosts(e.target.value)}
                  value={numberOfPosts || ""}
                />
              </div>
              {query.rules.length > 0 ? (
                <QueryBuilder
                  defaultQuery={query}
                  query={query}
                  fields={updatedFields}
                  onQueryChange={(val) => {
                    handleQueryChange(val);
                  }}
                />
              ) : (
                <QueryBuilder
                  defaultQuery={queryData.query.json_query}
                  fields={updatedFields}
                  onQueryChange={(val) => {
                    handleQueryChange(val);
                  }}
                />
              )}
            </div>
          ) : (
            <InstagramQueryBuilder
              instaFormData={instaFormData}
              setInstaFormData={setInstaFormData}
              queryData={queryData}
            />
          )}
        </div>
        <div className={Style.queryFooterBtns}>
          <SecondaryButton
            className={"small"}
            onClick={() => {
              queryFieldHeader === "instagram"
                ? addUpdateQueryInstagram(instaFormData, queryData)
                : addUpdateQuery(query, queryData, numberOfPosts);
            }}
          >
            Validate
          </SecondaryButton>
          <SecondaryButton className={"small"} onClick={() => setDrawerVisible(false)}>
            Cancel
          </SecondaryButton>
        </div>
      </ConditionalRender>
    </Drawer>
  ) : null;
};

export default CreateAnalysisQuery;