import React from "react";
import Style from "./homepage.style";
import { Col, Drawer, Row, Skeleton } from "antd";
import ConditionalRender from "src/components/conditionalRender";
import AppUrls from "src/constants/appUrls";
import HomeClientCard from "../homeClientCard/HomeClientCard";
import Assets from "src/assets/index";
import AppContext from "src/context/appContext";

export default function HomePage({ history, loading, allClients }) {
  const appContext = React.useContext(AppContext.Context);
  const isSuperAdmin = appContext?.metaData?.super_admin;

  // Skeleton for the card grid
  const renderSkeletonCards = (count) => (
    <div className={Style.homeCardContainer}>
      <div className={Style.apps}>
        <Row gutter={[20, 20]} justify="center">
          {Array.from({ length: count }, (_, index) => (
            <Col xl={4} key={index}>
              <div className={Style.skeletonCardContainer} style={{ padding: "16px", justifyContent: "center", alignItems: "center" }}>
                <Skeleton active title={false} paragraph={{ rows: 1 }} />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );

  const AddClientCard = () => {
    return (
      <div className={Style.addClientCard} onClick={() => history.push(AppUrls.CREATE_CLIENT)}>
        <img alt="plus icon" src={Assets.getImage('CirclePlusIcon')} className={Style.cardArrow}/>
      </div>
    )
  }

  return (
    <>
      <div className={Style.container}>
        {loading ? (
          <div className={Style.homePageContainer}>
            <Skeleton active title={false} paragraph={{ rows: 2 }} className={Style.homepageTitle} style={{ maxWidth: "40%" }} />
            {renderSkeletonCards(12)}
          </div>
        ) : (
          <div className={Style.homePageContainer}>
            <h2 className={Style.homepageTitle}>
              Welcome to Alchemy AI by GALE.
              <br />
              Choose a brand below to get started.
            </h2>
            <ConditionalRender shouldRender={allClients.length > 0}>
              <div className={Style.homeCardContainer}>
                <Row gutter={[20, 20]} justify={{ xs: "center", md: "center", lg: "start" }}>
                  {allClients?.map((item) => {
                    return (
                      <Col xl={allClients.length <= 6 ? 24 / allClients.length : 4} key={`home-client-card-${item.id}`}>
                        <HomeClientCard data={item} linkUrl={AppUrls.CLIENT_HOME(item.id)} internalApp={true} showOwner={false} />
                      </Col>
                    );
                  })}
                  {
                    isSuperAdmin && (
                      <Col xl={allClients.length <= 6 ? 24 / allClients.length : 4}>
                        <AddClientCard />
                      </Col>
                    )
                  }
                </Row>
              </div>
            </ConditionalRender>
          </div>
        )}
        <div className={Style.decorativeBackgroundImage}>
          <img className="select-disable" alt="decorative element" src={Assets.getImage("PageGridWave")} />
        </div>
      </div>
    </>
  );
}
