import { Col, Collapse, Form, Input, Row, Select, Popconfirm, notification, Modal } from "antd";
import React, { useContext, useState } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import Style from "./createForm.style";
import LlmConfig from "./LlmConfig";
import AgentConfig from "./AgentConfig";
import { CustomGptService } from "../services/customGptServices";
import CustomGptApiServices from "../services/customGptApiServices";
import { CREATE_FORM } from "../constants/constants";
import AssistantIconPicker from "src/modules/assistantAppIcon/assistantIconPicker/AssistantIconPicker";
import ConditionalRender from "src/components/conditionalRender";
import appUrls from "src/constants/appUrls";
import AppContext from "src/context/appContext";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { PageLayoutContext } from "src/context/pageLayoutContext";
import ConversationStartersConfig from "./ConversationStartersConfig";

export default function CreateForm({
  formRef,
  initialValues,
  metaData,
  createGpt,
  createGptLoading,
  id,
  backToChatAppHandler,
  clientId,
  history,
}) {
  const [appIconName, setAppIconName] = useState(initialValues.app_icon_name ?? "");
  const [chosenLLM, setChosenLLM] = useState(initialValues);
  const appContext = useContext(AppContext.Context);
  const {setDoneEditing} = useContext(PageLayoutContext);

  const archiveAssistant = async () => {
    Modal.warn({
      title: "Are you sure you want to archive?",
      okText: "Okay",
      cancelText: "Cancel",
      okCancel: true,
      onOk: async () => {
        try {
          const data = await CustomGptApiServices.archive(id);
          history.push(appUrls.HOMEPAGE);
          notification.success({
            message: "App Archived Successfully",
            duration: 6,
            placement: "bottomRight",
          });
        } catch (error) {
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || "Failed to delete the conversation",
            duration: 6,
            placement: "bottomRight",
          });
        }
      },
    });
  };
  return (
    <div className={Style.container}>
      <Form
        requiredMark={false}
        initialValues={initialValues}
        ref={formRef}
        layout="vertical"
        onFinish={(val) => {
          val.app_icon_name = appIconName;
          setDoneEditing(true);
          createGpt(val);
        }}
      >
        <Row justify="end" className={Style.btnsContainer}>
          <PrimaryButton className="small" onClick={backToChatAppHandler} disabled={!!!id}>
            Go to assistant
          </PrimaryButton>
          <SecondaryButton className="small" loading={createGptLoading} onClick={() => formRef.current.submit()}>
            {id ? "Save settings" : "Save and continue"}
          </SecondaryButton>
          <ConditionalRender shouldRender={!!id && appContext.metaData.email == initialValues.app_owner_email}>
            <PrimaryButton className="small" disabled={!!!id} onClick={() => archiveAssistant()}>
              Archive assistant
            </PrimaryButton>
          </ConditionalRender>
        </Row>
        <Row gutter={[30, 0]} className={Style.formContainer}>
          <Col xl={12} xs={24}>
            <Form.Item
              name="assistant_display_name"
              label="Name*"
              rules={CustomGptService.validations({ bot_name: true, required: true }, "Name")}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, "Client name")}
              name="client_name"
              label="Client*"
            >
              <Select disabled={true}>
                {metaData?.client_config?.available_clients?.choices?.map((item, idx) => {
                  return (
                    <Select.Option value={item} key={`option-${idx}`}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, "Description")}
              name="description"
              label={CREATE_FORM.ASSISTANT_DESC}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 5 }}
                placeholder={CREATE_FORM.ASSISTANT_DESC_PLACEHOLDER}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, "Instruction prompt")}
              name="system_prompt"
              label={CREATE_FORM.INSTRUCTION_ASSISTANT}
            >
              <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 12 }}
                placeholder={CREATE_FORM.INSTRUCTION_ASSISTANT_PLACEHOLDER}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item name="app_icon_name" initialValue={appIconName} className={Style.assistantIconInvisibleInput}>
              <Input tabIndex={-1} aria-disabled={true} />
            </Form.Item>
            <AssistantIconPicker
              originalIconName={appIconName}
              updateIconNameHandler={(iconName) => {
                setAppIconName(iconName);
              }}
            />
          </Col>
        </Row>
        <Row className={Style.formContainer} gutter={[30, 0]}>
          <Col span={24}>
            <Collapse className={"regularCollapse"}>
              <Collapse.Panel header={"Advanced settings"} forceRender={true}>
                <Form.Item noStyle name={["llm_config"]}>
                  <LlmConfig metaData={metaData} setChosenLLM={setChosenLLM} />
                </Form.Item>
                <Form.Item noStyle name={["agent_config"]}>
                  <AgentConfig metaData={metaData} chosenLLM={chosenLLM} formRef={formRef} />
                </Form.Item>
                <ConversationStartersConfig />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
