import ApiUrls from "src/constants/apiUrls";
import axios from "src/http/index";
export default class audienceListService {
  static async getAudienceList(clientId, currentPage, searchName, filterType, sortBy, isAscending, statusFilter) {
    const url = ApiUrls.GET_AUDIENCE_LIST(clientId, currentPage, 10, searchName, filterType, sortBy, isAscending, statusFilter);
    const response = await axios.get(url);
    return response.data;
  }

  static async getRecommendedMediaPlan(recommendationId) {
    const url = ApiUrls.GET_RECOMMENDED_MEDIA_PLAN(recommendationId);
    const response = await axios.get(url);
    return response.data;
  }

  static async createMediaplanRecommendation(payload) {
    const url = ApiUrls.CREATE_MEDIAPLAN_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getAudienceByid(audienceId) {
    const url = ApiUrls.GET_AUDIENCE_BY_ID(audienceId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceRecommendations(clientId, useCache, refreshPrompt) {
    const url = ApiUrls.GET_CLIENT_AUDIENCES_RECOMMENDATIONS(
      clientId,
      useCache,
      refreshPrompt
    );
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceFeatureProfiles(audienceId, orderBy, featureName, currentPage, featureType, groupByParent, pageSize) {
    const url = ApiUrls.GET_AUDIENCE_FEATURE_PROFILES(audienceId, orderBy, featureName, currentPage, featureType, groupByParent, pageSize);
    const response = await axios.get(url);
    return response.data;
  }

  static async archiveAudience(audienceId, action) {
    const url = ApiUrls.ARCHIVE_AUDIENCE(audienceId, action);
    const response = await axios.get(url);
    return response.data;
  }

  static async replaceAudienceBehaviouralFeature(payload) {
    const url = ApiUrls.REPLACE_BEHAVIOUR_FEATURE();
    const response = await axios.put(url, payload);
    return response.data;
  }
}
