import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Style from './assistantIconPicker.style';
import { PrimaryButton } from 'src/components/basic';
import Assets from 'src/assets';
import AssistantIconsChoices from '../constant/assistantIconsChoices';

const AssistantIconPicker = ({originalIconName, updateIconNameHandler}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchMinLength] = useState(2);
  const [selectedIconName, setSelectedIconName] = useState(originalIconName);

  const closeModal = ()=>{
    setModalOpen(false);
  }

  useEffect(()=>{
    if (searchKey.length >= searchMinLength) {
      let filteredIcon = []
      AssistantIconsChoices.forEach((group, index)=>{
        let results = group.icons.filter((name, index)=>{
          return name.includes(searchKey);
        });
        filteredIcon.push(results);
        if (index === AssistantIconsChoices.length -1) {
          setSearchResult(filteredIcon.flat());
        }
      })
    } else {
      setSearchResult([]);
    }
  }, [searchKey, searchMinLength])

  const selectIconHandler = (iconName)=>{
    setSelectedIconName(iconName);
  }

  const renderIconButton = (iconName, index) => {
    return <button key={`assistant-icon-choice-button-${index}`} id={iconName} className={Style.iconChoiceBtn + ` material-symbols-outlined ${selectedIconName === iconName ? ' selected': ''}`} onClick={(e)=>{
      selectIconHandler(e.target.id);
    }}>{iconName}</button>
  }

  return (
    <>
      <div className={Style.assistantPickerButton} onClick={()=>{
        setModalOpen(true);
      }}>
        Choose an icon for your Assistant
        <img className={Style.plusIcon} alt="plus icon" src={Assets.getImage('CirclePlusIcon')} />
      </div>
      <Modal
      maskClosable={true}
      destroyOnClose
      open={modalOpen}
      onCancel={closeModal}
      footer={null}
      className={Style.assistantIconPickerModal}
    >
      <Input placeholder='Search Icons' onChange={(e)=>{
        setSearchKey(e.target.value);
      }}></Input>
      <div className={Style.assistantIconPickerContent}>
        {(searchKey.length >= searchMinLength && searchResult.length === 0) &&
          <p>No results</p>
        }
        {searchKey.length < searchMinLength ? 
          <>
            {AssistantIconsChoices?.map((group, index)=>{
              return (
                <div className={Style.iconGroupContainer} key={`assistant-icon-choice-group-${index}`}>
                  <p>{group?.name}</p>
                  <div className={Style.iconContainer}>
                    {group?.icons?.map((iconName, index)=>{
                      return renderIconButton(iconName, index);
                    })}
                  </div>
                </div>
              )
            })}
          </>
          :
          <div className={Style.iconContainer}>{searchResult.map((iconName, index)=>{
            return renderIconButton(iconName, index);
          })}</div>
        } 
      </div>
      <PrimaryButton onClick={()=>{
        if (updateIconNameHandler) {
          updateIconNameHandler(selectedIconName);
        }
        closeModal();
      }}>Save Icon</PrimaryButton>
    </Modal>
  </>
  )
}

export default AssistantIconPicker