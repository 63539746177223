import React from 'react';
import { Drawer, Table, Skeleton, Empty, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import DataSourceContent from './DataSourceContent';
import { useArticleTable } from '../hooks/useArticleTable';
import Style from '../NewsAndCulture.style';
const { Search } = Input;

const ArticleTableDrawer = ({
  selectedWord,
  selectedLink,
  analysisId,
  visible,
  onClose,
  nodeClick,
  linkClick,
  columnName = "",
  drawerContent,
  postTypes,
  secondary_analysis_ids,
  selectedPostID,
  start_date = null,
  end_date = null,
}) => {
  const {
    columns,
    filteredData,
    globalSearch,
    setGlobalSearch,
    handleTableChange,
    detailDrawerVisible,
    setDetailDrawerVisible,
    selectedArticle,
    drawerLoader,
    pagination,
    totalPages,
  } = useArticleTable(
    selectedWord,
    selectedLink,
    analysisId,
    visible,
    nodeClick,
    linkClick,
    columnName,
    drawerContent,
    postTypes,
    secondary_analysis_ids,
    selectedPostID,
    start_date,
    end_date,
  );

  return (
    <>
      <Drawer
        title="Articles"
        width="90%"
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={true}
        className={Style.articleTableDrawerContainer}
      >
        <Search
          placeholder="Search"
          value={globalSearch}
          onChange={(e) => setGlobalSearch(e.target.value)}
          style={{ marginBottom: 16, width: "300px", display: "flex" }}
        />
        <Table
          columns={columns}
          dataSource={drawerLoader ? [] : filteredData}
          locale={{
            emptyText: drawerLoader ? (
              <Skeleton active={true} paragraph={{ rows: 15 }} />
            ) : (
              <Empty description="No Data" />
            ),
          }}
          rowKey="id"
          scroll={{ x: 1700 }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: totalPages,
            showSizeChanger: false,
            position: ["bottomCenter"],
          }}
          loading={drawerLoader}
          onChange={handleTableChange}
          className={Style.articleTableDrawer}
        />
      </Drawer>

      {selectedArticle && (
        <DataSourceContent
          data={[selectedArticle]}
          visible={detailDrawerVisible}
          onClose={() => setDetailDrawerVisible(false)}
          loading={drawerLoader}
          nodeName="Article Details"
        />
      )}
    </>
  );
};

export default ArticleTableDrawer;
