import axios from "src/http/index";
import ApiUrls from "src/constants/apiUrls";
import Cookie from "src/lib/cookie";

export default class DatasetServices {
  static async listDataSet(appId, pageNum) {
    const res = await axios.get(ApiUrls.EVALUATION_LIST_DATASET(appId, pageNum, 10));
    return res.data;
  }

  static async createDataset(appId, payload) {
    const res = await axios.post(ApiUrls.EVALUATION_DATASET(appId), payload);
    return res.data;
  }

  static async editDataset(appId, payload) {
    const res = await axios.put(ApiUrls.EVALUATION_DATASET(appId), payload);
    return res.data;
  }

  static async getDataset(datasetId) {
    const res = await axios.get(ApiUrls.GET_EVALUATION_DATASET(datasetId));
    return res.data;
  }

  static async listEvaluations(appId, pageNum) {
    const res = await axios.get(ApiUrls.EVALUATION_LIST(appId, pageNum, 10));
    return res.data;
  }

  static async getEvaluationDetail(evaluationId) {
    const res = await axios.get(ApiUrls.GET_EVALUATION(evaluationId));
    return res.data;
  }

  static async triggerEvaluation(datasetId) {
    const res = await axios.post(ApiUrls.EVALUATION_TRIGGER(datasetId));
    return res.data;
  }

};
