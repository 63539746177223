import { useState, useRef, useContext } from 'react';
import AppContext from 'src/context/appContext';

export default function useFeedbackFormHook({ feedbackData }) {
  const formRef = useRef();
  const appContext = useContext(AppContext.Context);
  const [loading, setLoading] = useState(false);

  const totalFeedbacks = feedbackData?.data?.feedbacks;
  
  const userFeedback = totalFeedbacks?.find((ele) => ele.email === appContext?.metaData?.email);

  let initialValues = {
    feedback: userFeedback?.feedback,
    category: userFeedback?.category
  };

  return {
    loading,
    formRef,
    userFeedback,
    initialValues,
    setLoading
  }
}