import { useState, useEffect, useMemo, useCallback } from "react";

export const useCommunityGraphHooks = ({
  communities,
  setCommunitiesFilter
//   selectedMetric,
}) => {
  const [option, setOption] = useState({});
  const [selectedMetric, setSelectedMetric] = useState("comments");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const numberFormatter = new Intl.NumberFormat("en-US");

  const dataSource = [
    {
      key: "1",
      metric: "Likes",
      value: selectedCommunity?.likes && numberFormatter.format(selectedCommunity?.likes),
    },
    {
      key: "2",
      metric: "Views",
      value: selectedCommunity?.views && numberFormatter.format(selectedCommunity?.views),
    },
    {
      key: "3",
      metric: "Shares",
      value: selectedCommunity?.shares && numberFormatter.format(selectedCommunity?.shares),
    },
    {
      key: "4",
      metric: "Comments",
      value: selectedCommunity?.comments && numberFormatter.format(selectedCommunity?.comments),
    },
    {
      key: "5",
      metric: "Number of Nodes",
      value: selectedCommunity?.node_count && numberFormatter.format(selectedCommunity?.node_count),
    },
    {
      key: "6",
      metric: "Number of Posts",
      value: selectedCommunity?.post_count && numberFormatter.format(selectedCommunity?.post_count),
    },
  ];

  const columns = [
    {
      title: "Metric",
      dataIndex: "metric",
      key: "metric",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const DROPDOWN_METRICS = {
    comments: {
      label: "Comments",
      value: "comments",
    },
    likes: {
      label: "Likes",
      value: "likes",
    },
    views: {
      label: "Views",
      value: "views",
    },
    shares: {
      label: "Shares",
      value: "shares",
    },
    post_count: {
      label: "Number of Posts",
      value: "post_count",
    },
    node_count: {
      label: "Number of Nodes",
      value: "node_count",
    },
  };

  const onBarHover = (community) => {
    setSelectedCommunity(community);
    setDrawerVisible(true);
  };


  const tooltipFormatter = useCallback(
    (params) => {
      const formattedValue = numberFormatter.format(params[0]?.value);
      return `<div><strong>${params[0]?.name}</strong><br/>${DROPDOWN_METRICS[selectedMetric].label}: ${formattedValue}</div>`;
    },
    [selectedMetric],
  );

  useEffect(() => {
    const topCommunities = Object.values(communities)
      .sort((a, b) => b[selectedMetric] - a[selectedMetric])
      .slice(0, 10);

    const data = topCommunities.map((community) => ({
      name: community.name,
      value: community[selectedMetric],
      ...community,
    }));

    setOption({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: tooltipFormatter,
      },
      grid: {
        bottom: 180, // Increase the bottom margin to ensure labels are visible
      },
      xAxis: {
        type: "category",
        data: data.map((item) => item.name),
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
        name: DROPDOWN_METRICS[selectedMetric].label,
      },
      series: [
        {
          type: "bar",
          data: data.map((item) => item.value),
          itemStyle: {
            color: "#5470C6",
          },
        },
      ],
    });
  }, [communities, selectedMetric, tooltipFormatter]);

  const onChartEvents = useMemo(
    () => ({
      mouseover: (params) => {
        const community = Object.values(communities).find(
          (c) => c.name === params.name,
        );
        if (community) {
          onBarHover && onBarHover(community);
        }
      },
      click: (params) => {
        const community = Object.values(communities).find(
          (c) => c.name === params.name,
        );
        if (community) {
          setCommunitiesFilter && setCommunitiesFilter([community.name]);
        }
      },
    }),
    [communities],
  );

  return {
    option,
    onChartEvents,
    DROPDOWN_METRICS,
    selectedMetric,
    setSelectedMetric,
    drawerVisible,
    setDrawerVisible,
    dataSource,
    columns,
    selectedCommunity,
  };
};

export default useCommunityGraphHooks;
