import { useState, useEffect, useRef } from 'react';
import appUrls from 'src/constants/appUrls';
import { ClusterToolApiServices } from '../../services/ClusterToolApiServices';
import { notification } from 'antd';
import { getErrorMessageFromResponse } from 'src/lib/utils';


export default function useCloneFormHook({ history, match }) {
  const clusterId = match?.params?.clusterId;
  const clientSpaceId = match?.params?.clientId;

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [configureLoading, setConfigureLoading] = useState(false);
  const formRef = useRef();

  const getName = async () => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getClusterCloneName(clusterId);
      setName(res.data.name);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  }

  const cloneCluster = async (val) => {
    try {
      setConfigureLoading(true);
      const res = await ClusterToolApiServices.cloneCluster(val.clone_clusters_config_name, clusterId);
      history.push(appUrls.CLUSTER_CONFIG_FEATURES(clientSpaceId, res.data.id));
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  }

  useEffect(() => {
    getName();
  }, [])
  return {
    loading,
    name,
    formRef,
    configureLoading,
    cloneCluster
  }
}