import { notification } from 'antd';
import { useState, useEffect } from 'react';
import DatasetServices from '../services/datasetServices';

export default function useListDatasetHooks({ appId, refreshEvaluations }) {
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [datasets, setDatasets] = useState([]);
    
  const getDatasets = async (customPageNumber) => {
    setLoading(true);
    try {
      const res = await DatasetServices.listDataSet(appId, (customPageNumber  || pageNumber) - 1);
      setDatasets(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        duration: 5,
        message: "Failed to load Datasets",
        placement: "bottomRight"
      });
    }
  };
  const triggerEvaluation = async (datasetId) => {
    setLoading(true);
    try {
      await DatasetServices.triggerEvaluation(datasetId);
      setLoading(false);
      notification.success({
        duration: 5,
        message: "Evaluation triggered successfully",
        placement: "bottomRight"
      });
      refreshEvaluations();
    } catch (error) {
      setLoading(false);
      notification.error({
        duration: 5,
        message: "Failed to trigger evaluation",
        placement: "bottomRight"
      });
    }

  };

  useEffect(() => {
    getDatasets();
  }, [pageNumber])
  return {
    loading,
    pageNumber,
    setPageNumber,
    datasets,
    getDatasets,
    triggerEvaluation,
  }
}
