import MicroAppServices from '../services/microAppService';
import { notification } from 'antd';

export const useMicroAppHook = ({ clientId }) => {

  const getDashSso = async (clientSpaceId) => {
    try {
        const res = await MicroAppServices.getDashSso(clientSpaceId);
        if (res?.success) {
            const { url } = res?.data;
            window.open(url, '_blank');
        } else {
            notification.error({
                duration: 5,
                message: "User Doesn't have access to Reporting App for this client",
                placement: "bottomRight"
            });
        }
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'You are not authorized to access this app',
        placement: "bottomRight"
      });
    }
  };

  const getMPTSso = async (clientSpaceId) => {
    try {
        const res = await MicroAppServices.getMptSso(clientSpaceId);
        if (res?.success) {
            const { url } = res?.data;
            window.open(url, '_blank');
        } else {
            notification.error({
                duration: 5,
                message: "User Doesn't have access to Media Planner Tool for this client",
                placement: "bottomRight"
            });
        }
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'You are not authorized to access this app',
        placement: "bottomRight"
      });
    }
  };


  return {
    getDashSso,
    getMPTSso
  };
};
