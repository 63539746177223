import { css } from '@linaria/core';

const navBarContainerWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 1rem;
`;

const question = css`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
`;

const answer = css`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-left: 1.4rem;
  color: gray;
`;

const cardContent = css`
  max-height: 200px; /* Adjust as needed */
  overflow: hidden;
  font-size: 16px; /* Adjust as needed */
`;


const expanded = css`
  max-height: none;
  font-size: 14px; /* Reduced font size when expanded */
`;


export default {
  navBarContainerWrapper,
  question,
  answer,
  cardContent,
  expanded
}