import { css } from "@linaria/core";

const connectionContainer = css`
  
`;

const form = css`
`;

const connectionLabel = css`
  color: gray;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const tableContainer = css`
  overflow: auto;
  padding: 16px 24px;
`;

const table = css`
  width:100%;
`;

const addRowContainer = css`
  margin-top: 1rem;
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
`;

const advancedSettingContainer = css`
  margin-top: 2rem;
`;

const advanceSettinglabel = css`
  color: gray;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;


export default {
  connectionContainer,
  form,
  connectionLabel,
  addRowContainer,
  table,
  tableContainer,
  advancedSettingContainer,
  advanceSettinglabel,

};
