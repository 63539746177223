import { useState, useEffect } from 'react';
import CustomGptApiServices from 'src/modules/CustomGpt/services/customGptApiServices';
import { SqlToolApiServices } from './sqlToolApiServices';

export default function useNewSqlToolHook({ id, clientId }) {
  const [loading, setLoading] = useState(true);
  const [connectionString, setConnectionString] = useState([]);
  const [globalSettingsDrawer, setGlobalSettingsModal] = useState(false);
  const [exploreDataBaseDrawer, setExploreDataBaseDrawer] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState('');
  const [sqlData, setSqlData] = useState({});
  const [onChangeConnectionLoad, setOnChangeConnectionLoad] = useState(false);
  const [syncColumnLoading, setSyncColumnLoading] = useState(false);

  const getInitialData = async () => {
    try {
      setLoading(true);
      const res = await SqlToolApiServices.getSqlConnection(id, clientId);
      if (res?.data?.length > 0) { 
        await getConnectionLevelSqlData(res?.data?.[0]?.connection_id);
        setSelectedConnection(res?.data[0].connection_id);
      }
      setConnectionString(res?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const changeConnection = async (val) => {
    try {
      setOnChangeConnectionLoad(true);
      setSelectedConnection(val);
      await getConnectionLevelSqlData(val);
      setOnChangeConnectionLoad(false);
    } catch (error) {
      setOnChangeConnectionLoad(false);
    }
  }

  const getConnectionLevelSqlData = async (connectionId) => {
    try {
      const res = await SqlToolApiServices.getConnectionLevelData(id, connectionId);
      setSqlData(res.data);
    } catch (error) {
      setSqlData({});
    }
  }

  const syncColumns = async () => {
    try {
      setSyncColumnLoading(true)
      const res = await SqlToolApiServices.syncColumns(id, selectedConnection);
    } catch (error) {
      setSyncColumnLoading(false)
    }
  
  }

  useEffect(() => {
    getInitialData();
  }, [])

  return {
    loading,
    connectionString,
    globalSettingsDrawer,
    setGlobalSettingsModal,
    exploreDataBaseDrawer,
    setExploreDataBaseDrawer,
    selectedConnection,
    changeConnection,
    sqlData,
    onChangeConnectionLoad,
    syncColumns,
    setSqlData,
    syncColumnLoading
  }
}