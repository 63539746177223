import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import AppUrls from "src/constants/appUrls";
import MediaPlansListing from './MediaPlansListing';
import DashboardMP from './DashboardMP';
import CreateMediaPlanForm from './CreateMediaPlanForm';
import { Layout } from 'antd';
import './MediaPlanLayout.less';
import assets from 'src/assets';

const { Content } = Layout;

const MediaPlanLayout = () => {
  return (
    <>
      <Layout className='layout'>
        <Content className='page-container'>
          <Switch>
            <Route
              exact
              path={AppUrls.MEDIA_PLAN_LISTING(':clientId')}
              component={MediaPlansListing}
            />
            <Route
              path={AppUrls.CREATE_MEDIA_PLAN(':clientId')}
              component={CreateMediaPlanForm}
            />
            <Route
              path={AppUrls.MEDIA_PLAN_DASH(':clientId', ':mediaPlanId')}
              component={DashboardMP}
            />
            <Redirect to={AppUrls.MEDIA_PLAN_LISTING(':clientId')} />
          </Switch>
        </Content>
      </Layout>
      <div className={'decorativeBackgroundImage'}>
        <img
          className='select-disable'
          alt='decorative element'
          src={assets.getImage('ClientSpaceWave')}
        />
      </div>
    </>
  );
};

export default MediaPlanLayout;