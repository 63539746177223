import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AppUrls from "src/constants/appUrls";
import ClusterToolListing from "../clusterToolListing/ClusterToolListing";
import CreateCluster from "../createCluster/CreateCluster";
import ShowCluster from "../ShowCluster/ShowCluster";
import ClusterFeature from "../clusterFeature/ClusterFeature";
import SelectFeatures from "../selectFeatures/SelectFeatures";

export default function ClusterToolLayout() {
  return (
    <div style={{ padding: "0 54px", }}>
      <Switch>
        <Route
          exact
          path={[AppUrls.CREATE_CLUSTER(":clientId"), AppUrls.EDIT_CLUSTER(":clientId", ":clusterId")]}
          component={CreateCluster}
        />
        <Route exact path={AppUrls.SELECT_FEATURES(":clientId", ":clusterId")} component={SelectFeatures} />
        <Route exact path={AppUrls.SHOW_CLUSTER(":clientId", ":clusterId")} component={ShowCluster} />
        <Route exact path={AppUrls.CLUSTER_CONFIG_FEATURES(":clientId", ":clusterId")} component={ClusterFeature} />
        <Route exact path={AppUrls.CLUSTER_TOOL(":clientId")} component={ClusterToolListing} />
        <Route redirect to={AppUrls.CLUSTER_TOOL(":clientId")} />
      </Switch>
    </div>
  );
}
