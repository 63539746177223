import { message, notification } from "antd";
import { useState, useEffect, useRef } from "react";
import { getErrorMessageFromResponse, saveToClipboard } from "src/lib/utils";
import CustomGptApiServices from "../services/customGptApiServices";
import { CustomGptService } from "../services/customGptServices";

export default function useGoogleDriveCapapbilityHook({ id, aiAppData, configData, updateCustomApp, onClose, toolData, reload }) {
  const capability = aiAppData?.capabilities?.find((ele) => ele.capability_name === "GoogleDriveCapability");
  const [initialValues, setInitialValues] = useState({ google_drive_folder_urls: [undefined] });
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [fileProgressData, setFileProgressData] = useState(capability?.fields_config.unstructured_files_indexing_progress || null);
  const timeRef = useRef();

  const getGoogleDriveList = async (val) => {
    try {
      setConfigureLoading(true);
      const folders = {
        folder_urls: [],
      };
      val.google_drive_folder_urls.forEach((ele) => {
        folders.folder_urls.push(ele?.url);
      });
      const res = await CustomGptApiServices.getGDriveList(folders);
      let payload = {
        capability_name: "GoogleDriveCapability",
        fields_config: {
          ...val,
          google_drive_folder_urls: folders.folder_urls,
          files: [],
        },
      };
      const newRes = await CustomGptApiServices.configureCapabilities(id, payload);
      updateCustomApp(newRes.data);
      notification.success({
        duration: 5,
        message: "Unstructured tool configured successfully",
        placement: "bottomRight"
      });
      setConfigureLoading(false);
      reload();
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const getInitialValues = () => {
    setLoading(true);
    const capabilityData = aiAppData?.capabilities?.find((ele) => ele.capability_name === "GoogleDriveCapability");
    if (capabilityData) {
      let folders = [];
      capabilityData?.fields_config?.google_drive_folder_urls?.forEach((ele) => {
        folders.push({
          url: ele,
        });
      });
      let obj = {
        google_drive_unstructured_description: capabilityData?.fields_config?.google_drive_unstructured_description,
        google_drive_folder_urls: folders,
      };
      setInitialValues(obj);
    } else {
      setInitialValues({
        ...initialValues,
        google_drive_unstructured_description: configData?.config?.google_drive_unstructured_description?.default_value,
      });
    }
    setLoading(false);
  };

  const getFileProcessingData = () => {
    timeRef.current = window.setInterval(async () => {
      try {
        const res = await CustomGptApiServices.getCustomAppData(id);
        const capabilityData = res?.data?.capabilities?.find((ele) => ele.capability_name === "GoogleDriveCapability");
        setFileProgressData(capabilityData?.fields_config.unstructured_files_indexing_progress);
        updateCustomApp(res.data);
        if (capabilityData?.fields_config.unstructured_files_indexing_progress?.status === "DONE") {
          window.clearInterval(timeRef.current);
          notification.success({
            duration: 5,
            message: `Synced all ${capabilityData?.fields_config.unstructured_files_indexing_progress?.total_files} files.`,
            placement: "bottomRight"
          });
        }
      } catch (error) {
        const {msg} = getErrorMessageFromResponse(error);
        notification.error({
          duration: 5,
          message: msg,
          placement: "bottomRight"
        });
      }
    }, 5000);
  };

  const copyToClipboard = () => {
    try {
      let text = "alchemyai@alchemyai-418009.iam.gserviceaccount.com";
      saveToClipboard(text)
    } catch (error) {}
    message.success({
      type: "success",
      content: "Copied to clipboard",
      duration: 3,
    });
  }

  useEffect(() => {
    getInitialValues();
  }, []);

  useEffect(() => {
    if (fileProgressData?.status === "IN_PROGRESS" || fileProgressData?.status === "FETCHING_FILES") {
      getFileProcessingData();
    }
    return () => {
      if (timeRef.current) {
        window.clearInterval(timeRef.current);
      }
    };
  }, []);

  return {
    getGoogleDriveList,
    initialValues,
    loading,
    configureLoading,
    fileProgressData,
    copyToClipboard
  };
}
