import { css } from '@linaria/core';

const form = css`

`;
const formCheckbox = css`
  width:100%;
  color:var(--color-gale-gray) !important;
  .ant-form-item-control-input-content {
    display:flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
`

export default {
  form,
  formCheckbox
}