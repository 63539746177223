import { notification } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CustomGptApiServices from '../services/customGptApiServices';

export default function useInternetCapabiltyHook({id, aiAppData, configData, updateCustomApp, onClose, toolData, reload}) {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false)

  const getInitialValues = () => {
    try {
      setLoading(true);
      if (toolData) {
        setInitialValues(toolData.fields_config);
      } else {
        setInitialValues({
          name: configData?.config?.name?.default_value,
          description: configData?.config?.description?.default_value,
          allow_system_prompt_injection: configData?.config?.allow_system_prompt_injection?.default_value
        })
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const configureCapability = async (payload) => {
    try {
      setConfigureLoading(true);
      const formattedPayload = {
        fields_config: payload, capability_name: 'InternetCapability',
      }
      const res = await CustomGptApiServices.configureCapabilities(id, formattedPayload);
      updateCustomApp(res.data);
      notification.success({
        duration: 5,
        message: 'Internet tool configured successfully',
        placement: "bottomRight"
      })
      setConfigureLoading(false);
      reload();
      onClose();
    } catch (error) {
      setConfigureLoading(false);
      const {msg} = getErrorMessageFromResponse(error)
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      })
    }
  }
  

  useEffect(() => {
    getInitialValues();
  }, [])
  return {
    formRef,
    initialValues,
    loading,
    configureCapability,
    configureLoading
  }
}