/* Authentication related URL */
const AUTH_LOGIN = `/login`;

const OKTA_LOGIN = "/okta-callback";

const HOMEPAGE = "/home";

const CREATE_GPT = (id) => `/client/${id}/create/custom`;

const CLIENT_HOME = (id) => `/client/${id}`;

const APP_PAGE = (clientId, id, sessionId, mode) => {
  const params = new URLSearchParams();
  if (sessionId) {
    params.set("session-id", sessionId);
  }
  if (mode === "socialInsights") {
    params.set("mode", mode);
  }
  return `/client/${clientId}/chat/${id}?${params}`;
};

const EDIT_CUSTOM_GPT = (clientId, id, mode = "configure") =>
  `/client/${clientId}/edit/custom/${id}/${mode}`;

const AUDIENCE_TOOL = (id) => `/client/${id}/audience-tool`;
const AUDIENCE_DETAIL = (clientId, audienceId) =>
  `${AUDIENCE_TOOL(clientId)}/audience/${audienceId}`;
const CLUSTER_TOOL = (id) => `/client/${id}/cluster-tool`;
const AUDIENCE_MANAGER = (id) => `${AUDIENCE_TOOL(id)}/audience-manager`;
const CREATE_AUDIENCE = (id) => `${AUDIENCE_TOOL(id)}/create-audience`;
const EDIT_AUDIENCE = (id, audienceId) => `${AUDIENCE_TOOL(id)}/edit-audience/${audienceId}`;
const CREATE_MEDIA_PLAN_AUDIENCE = (id, audienceId) =>
  `${AUDIENCE_TOOL(id)}/create-media-plan/${audienceId}`;

const MEDIA_PLAN_LISTING_LAYOUT = (id) => `/client/${id}/media-plan`;
const MEDIA_PLAN_LISTING = (id) => `${MEDIA_PLAN_LISTING_LAYOUT(id)}/listing`;
const CREATE_MEDIA_PLAN = (id) => `${MEDIA_PLAN_LISTING_LAYOUT(id)}/create`;
const MEDIA_PLAN_DASH = (id, mediaPlanId) =>
  `${MEDIA_PLAN_LISTING_LAYOUT(id)}/dashboard/${mediaPlanId}`;

const SQL_TOOL = (clientId, id, mode = "capability") =>
  `/client/${clientId}/edit/custom/${id}/${mode}/sql-tool`;

const CREATE_CLUSTER = (id) => `/client/${id}/cluster-tool/create-cluster`;

const EDIT_CLUSTER = (id, clusterId) =>
  `/client/${id}/cluster-tool/edit-cluster/${clusterId}`;

const SELECT_FEATURES = (id, clusterId) =>
  `/client/${id}/cluster-tool/select-features/${clusterId}`;

const SHOW_CLUSTER = (appId, clusterId) =>
  `/client/${appId}/cluster-tool/cluster/${clusterId}`;

const CLUSTER_CONFIG_FEATURES = (id, clusterId) =>
  `/client/${id}/cluster-tool/features/${clusterId}`;

const NEWS_CULTURE = (id) => `/client/${id}/news-culture`;

const ANALYSIS_OVERVIEW_SCREEN = (clientId, analysisId) =>
  `/client/${clientId}/news-culture/analysis-overview/${analysisId}`;

const CREATE_LISTING_SCREEN = (clientId, analysisId = "") => {
  return `/client/${clientId}/news-culture/create-listing${
    analysisId ? `/${analysisId}` : ""
  }`;
};

const UPDATE_LISTING_SCREEN = (id, analysisId) =>
  `/client/${id}/news-culture/update-listing/${analysisId}`;

const CREATE_ANALYSIS_QUERY = (clientId, analysisId) =>
  `/client/${clientId}/news-culture/create-analysis-query/${analysisId}`;

// Labeler
const LABELER = (id) => `/client/${id}/labeler`;

const CREATE_CLIENT = `/create-client-space`;

const EDIT_CLIENT_SPACE = (id) => `/client/${id}/edit`;

export default {
  AUTH_LOGIN,
  OKTA_LOGIN,
  HOMEPAGE,
  CLIENT_HOME,
  CREATE_GPT,
  EDIT_CUSTOM_GPT,
  APP_PAGE,
  CLUSTER_TOOL,
  AUDIENCE_MANAGER,
  CREATE_AUDIENCE,
  EDIT_AUDIENCE,
  EDIT_CLUSTER,
  SELECT_FEATURES,
  CREATE_CLUSTER,
  SHOW_CLUSTER,
  CLUSTER_CONFIG_FEATURES,
  NEWS_CULTURE,
  ANALYSIS_OVERVIEW_SCREEN,
  CREATE_LISTING_SCREEN,
  SQL_TOOL,
  UPDATE_LISTING_SCREEN,
  CREATE_ANALYSIS_QUERY,
  AUDIENCE_TOOL,
  AUDIENCE_DETAIL,
  CREATE_MEDIA_PLAN_AUDIENCE,
  MEDIA_PLAN_DASH,
  MEDIA_PLAN_LISTING_LAYOUT,
  MEDIA_PLAN_LISTING,
  CREATE_MEDIA_PLAN,
  LABELER,
  CREATE_CLIENT,
  EDIT_CLIENT_SPACE
};
