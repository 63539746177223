import { Checkbox, Col, Collapse, Form, Input, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useBackboneCapabiltyHook from "src/modules/CustomGpt/hooks/useBackboneCapabiltyHook";
import useDashboardCapabiltyHook from "src/modules/CustomGpt/hooks/useDashboardCapability";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./dashboardCapability.style";
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style.js";

export default function DashboardCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { formRef, loading, configureCapability, initialValues, configureLoading } = useDashboardCapabiltyHook({
    id,
    aiAppData,
    configData,
    updateCustomApp,
    onClose,
    toolData,
    reload,
  });
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form}>
          <Form
            onFinish={(val) => {
              configureCapability(val);
            }}
            requiredMark={false}
            ref={formRef}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row gutter={[30, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="account_name"
                  label="Account name*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    "Account name",
                    configData?.config?.account_name
                  )}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label="Description*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    "Description",
                    configData?.config?.description
                  )}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="assistant_description_for_system_prompt"
                  label="Assistant description for system prompt*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    "Assistant description for system prompt",
                    configData?.config?.assistant_description_for_system_prompt
                  )}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Collapse className={'drawerCollapse'}>
                  <Collapse.Panel forceRender={true} key="1" header="Advanced settings">
                      <Form.Item
                        name="name"
                        label="Name*"
                        rules={CustomGptService.validations({ required: true, max_length_string: true }, "Name", configData?.config?.name)}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="tool_invocation_text"
                        label="Tool invocation text*"
                        rules={CustomGptService.validations(
                          { required: true, max_length_string: true },
                          "Tool invocation text",
                          configData?.config?.tool_invocation_text
                        )}
                      >
                        <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                      </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
            <Row justify="end" className={CommonStyle.globalSave}>
              <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                Save
              </SecondaryButton>
            </Row>
          </Form>
        </div>
      </ConditionalRender>
    </div>
  );
}
