import React from "react";
import LoadingSpinner from "src/components/loadingSpinner";
import TopFeatureIndexView from "./TopFeatureIndexView";
import useBrandAffinitiesHook from "./useBrandAffinitiesHook";

const BrandAffinities = ({ audienceId, brandAffinityCategories, activeTab }) => {

    const {
        brandAffinitiesLoading,
        brandAffinitiesData,
        replaceBrandAffinities
    } = useBrandAffinitiesHook({ audienceId, activeTab });

    if (brandAffinitiesLoading) {
        return <LoadingSpinner centered />;
    }

    return (
        <>
            <p style={{ textAlign: 'left', fontSize: '16px', marginTop: '20px', marginLeft: '20px', color: 'white' }}>
                Highlighting consumer brand affinities, this overview illustrates how strongly audiences prefer certain brands compared to a baseline. By examining these affinities, you can gain insights into brand loyalty and consumer preferences, helping to understand which brands resonate most with your audience.
            </p>
            <TopFeatureIndexView
                data={brandAffinitiesData}
                behaviourFeaturesCategories={brandAffinityCategories}
                replaceFunction={replaceBrandAffinities} />
        </>
    )
}
export default BrandAffinities;
