import React from 'react';
import { Drawer, Spin, Empty } from 'antd';
import useDataSourceContent from '../hooks/useDataSourceContent';


const DataSourceContent = ({ data, visible, onClose, loading, nodeName }) => {
  const { processedData } = useDataSourceContent(data, loading);

  return (
    <Drawer
      title={nodeName}
      width="50%"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={true}
      style={{ boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)', overlay: '0 0 0 100vh rgba(0, 0, 0, 0.3)', zIndex: 10004 }}
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <Spin size="medium" />
        </div>
      ) : processedData.length > 0 ? (
        processedData
      ) : (
        <Empty description='No Data' />
      )}
    </Drawer>
  );
};

export default DataSourceContent;
