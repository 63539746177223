import { Checkbox, Col, Collapse, Form, Input, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./googleSheetCapability.style";

export default function GoogleSheetAdvancedSettings({ configData }) {
  return (
    <div style={{ marginTop: "2rem" }}>
      <Collapse className={'drawerCollapse'}>
        <Collapse.Panel forceRender={true} key="1" header="Advanced settings">
          <Row gutter={[16, 0]}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="google_drive_structured_name"
                label="Google drive structured name*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Google drive structured name",
                  configData?.config?.google_drive_structured_name
                )}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="google_drive_get_file_info_name"
                label="Google drive get file info name*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Google drive get file info name",
                  configData?.config?.google_drive_get_file_info_name
                )}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="max_results_per_query"
                label="Max results per query*"
                rules={CustomGptService.validations(
                  { required: true,  },
                  "Max results per query",
                  configData?.config?.max_results_per_query
                )}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="max_characters_returned"
                label="Max characters returned*"
                rules={CustomGptService.validations(
                  { required: true,  },
                  "Max characters returned",
                  configData?.config?.max_characters_returned
                )}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="google_drive_structured_allow_system_prompt_injection"
                valuePropName="checked"
              >
                <Checkbox style={{marginRight:8}}/>{"Google drive structured allow system prompt injection"}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="google_drive_get_file_info_allow_system_prompt_injection"
                valuePropName="checked"
              >
                <Checkbox style={{marginRight:8}}/>{"Google drive get file info allow system prompt injection"}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item name="set_schema_in_instruction_prompt" valuePropName="checked">
                <Checkbox style={{marginRight:8}}/>{"Set schema in instruction prompt"}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="google_drive_structured_description"
                label="Google drive structured description*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Google drive structured description",
                  configData?.config?.google_drive_structured_description
                )}
              >
                <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="google_drive_get_file_info_description"
                label="Google drive get file info description*"
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Google drive get file info description",
                  configData?.config?.google_drive_get_file_info_description
                )}
              >
                <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
