import { Modal } from 'antd';
import React from 'react';

const Disclaimer = ({ modalOpen, closeModal }) => {
  const handleCancel = () => {
    closeModal();
  };
  return (
    <Modal
      maskClosable={true}
      destroyOnClose
      open={modalOpen}
      onCancel={handleCancel}
      footer={null}
      className={'disclaimerModal'}
    >
      <div
        style={{ fontSize: '1rem', fontWeight: '600', marginBottom: '1rem' }}
      >
        Disclaimer{' '}
      </div>
      <div>
        Alchemy.AI is based on third-party data and uses some third-party and
        externally trained LLM. As such, the prompt responses could contain
        information containing stereotypes or biases—racial or otherwise.
        Further, the model might return just plain wrong information. If you
        find any of the above, please mark it unacceptable and share the issue
        in detail. It is your responsibility to vet all information generated by
        Alchemy.Ai.
      </div>
    </Modal>
  );
};

export default Disclaimer;
