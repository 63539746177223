import { Checkbox, Col, Form, Input, Row, Skeleton } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import useInternetCapabiltyHook from "src/modules/CustomGpt/hooks/useInternetCapabiltyHook";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./internetCapability.style";
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style";

export default function InternetCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { formRef, loading, configureCapability, initialValues, configureLoading } = useInternetCapabiltyHook({
    id,
    aiAppData,
    configData,
    updateCustomApp,
    onClose,
    toolData,
    reload
  });
  return (
    <>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form}>
          <Form
            onFinish={(val) => {
              configureCapability(val);
            }}
            requiredMark={false}
            ref={formRef}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={14}>
                <Form.Item
                  name="name"
                  label="Name*"
                  rules={CustomGptService.validations({ required: true, max_length_string: true }, "Name", configData?.config?.name)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={10} style={{display:"flex", alignItems:'center'}}>
                <Form.Item 
                  name="allow_system_prompt_injection"
                  valuePropName="checked"
                  className={Style.formCheckbox}
                >
                  <Checkbox/>Allow system prompt injection
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                <Form.Item
                  name="description"
                  label="Description*"
                  rules={CustomGptService.validations({ required: true, max_length_string: true }, "Name", configData?.config?.description)}
                >
                  <TextArea autoSize={{ minRows: 5 ,maxRows: 7 }}/>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                Save
              </SecondaryButton>
            </Row>
          </Form>
        </div>
      </ConditionalRender>
    </>
  );
}
