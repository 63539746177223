import { css } from "@linaria/core";

export const table = css`
  .ant-table {
    background-color: transparent;
    color: var(--color-white);
  }
  .ant-table-thead > tr > th {
    background-color: var(--color-90-black);
    color: var(--color-30-gray);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 16px;
    font-weight: 600;
  }
  .ant-table-tbody > tr:hover > td {
    background-color: var(--color-80-black) !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 12px 16px;
  }
  .ant-table-container {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }

  /* Add these new styles */
  .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background-color: transparent;
  }

  .ant-empty-description {
    color: var(--color-white);
  }

  .ant-empty-img-simple-path {
    fill: var(--color-30-gray);
  }
`;
