const images = {
  Search: new URL("src/assets/images/search.svg", import.meta.url),
  HighPriority: new URL("src/assets/images/high-priority.png", import.meta.url),
  MediumPriority: new URL(
    "src/assets/images/medium-priority.png",
    import.meta.url
  ),
  LowPriority: new URL("src/assets/images/low-priority.png", import.meta.url),
  GaleLogo: new URL("src/assets/images/gale-logo.svg", import.meta.url),
  GaleLogoWhite: new URL(
    "src/assets/images/galeLogoWhite.svg",
    import.meta.url
  ),
  GalePolicyApp: new URL(
    "src/assets/images/GalePolicyApp.svg",
    import.meta.url
  ),
  GalePolicyPGApp: new URL(
    "src/assets/images/GalePolicyPGApp.svg",
    import.meta.url
  ),
  GalePolicyReactApp: new URL(
    "src/assets/images/GalePolicyReactApp.svg",
    import.meta.url
  ),
  Home: new URL("src/assets/images/home-new.svg", import.meta.url),
  ContentGenerating: new URL(
    "src/assets/images/contentGenerating.gif",
    import.meta.url
  ),
  Hamburger: new URL("src/assets/images/hamburger.svg", import.meta.url),
  AiLoader: new URL("src/assets/images/ailoader.gif", import.meta.url),
  GaleRFP: new URL("src/assets/images/GaleRFP.png", import.meta.url),
  AskDash: new URL("src/assets/images/AskDash.png", import.meta.url),
  ToolBuilder: new URL("src/assets/images/ToolBuilder.svg", import.meta.url),
  MediaOptimiser: new URL(
    "src/assets/images/MediaOptimiser.svg",
    import.meta.url
  ),
  HertzAssistant: new URL(
    "src/assets/images/HertzAssistant.png",
    import.meta.url
  ),
  StrategyAssistant: new URL(
    "src/assets/images/StrategyAssistant.svg",
    import.meta.url
  ),
  AskDashBlackAndWhite: new URL(
    "src/assets/images/AskDashBlackAndWhite.svg",
    import.meta.url
  ),
  HertzAssistantBlackAndWhite: new URL(
    "src/assets/images/HertzAssistantBlackAndWhite.png",
    import.meta.url
  ),
  StrategyAssistantBlackAndWhite: new URL(
    "src/assets/images/StrategyAssistantBlackAndWhite.png",
    import.meta.url
  ),
  AlchemyLogo: new URL("src/assets/images/AlchemyLogo.svg", import.meta.url),
  AskDashColor: new URL("src/assets/images/AskDashColor.svg", import.meta.url),
  WellsColor: new URL("src/assets/images/WellsColor.svg", import.meta.url),
  TruthInitiativeColor: new URL(
    "src/assets/images/TruthInitiativeColor.svg",
    import.meta.url
  ),
  MilkpepColor: new URL("src/assets/images/MilkpepColor.svg", import.meta.url),
  OdflColor: new URL("src/assets/images/OdflColor.svg", import.meta.url),
  HertzAssistantColor: new URL(
    "src/assets/images/HertzAssistantColor.svg",
    import.meta.url
  ),
  DropboxColor: new URL("src/assets/images/DropboxColor.svg", import.meta.url),
  AthenaHealthColor: new URL(
    "src/assets/images/AthenaHealthColor.svg",
    import.meta.url
  ),
  BathfitterColor: new URL(
    "src/assets/images/BathfitterColor.svg",
    import.meta.url
  ),
  HbsColor: new URL("src/assets/images/HbsColor.svg", import.meta.url),
  StrategyAssistantColor: new URL(
    "src/assets/images/StrategyAssistantColor.svg",
    import.meta.url
  ),
  PellaColor: new URL("src/assets/images/PellaColor.svg", import.meta.url),
  ToolBuilderColor: new URL(
    "src/assets/images/ToolBuilderColor.svg",
    import.meta.url
  ),
  BackboneAssistantColor: new URL(
    "src/assets/images/BackboneAssistantColor.svg",
    import.meta.url
  ),
  GaleRFPColor: new URL("src/assets/images/GaleRFPColor.svg", import.meta.url),
  MediaOptimiserColor: new URL(
    "src/assets/images/MediaOptimiserColor.svg",
    import.meta.url
  ),
  HertzAssistantFavicon: new URL(
    "src/assets/images/HertzAssistantFavicon.svg",
    import.meta.url
  ),
  AskDashBw: new URL("src/assets/images/AskDashBw.svg", import.meta.url),
  WellsBw: new URL("src/assets/images/WellsBw.svg", import.meta.url),
  TruthInitiativeBw: new URL(
    "src/assets/images/TruthInitiativeBw.svg",
    import.meta.url
  ),
  MilkpepBw: new URL("src/assets/images/MilkpepBw.svg", import.meta.url),
  OdflBw: new URL("src/assets/images/OdflBw.svg", import.meta.url),
  HertzAssistantBw: new URL(
    "src/assets/images/HertzAssistantBw.svg",
    import.meta.url
  ),
  DropboxBw: new URL("src/assets/images/DropboxBw.svg", import.meta.url),
  AthenaHealthBw: new URL(
    "src/assets/images/AthenaHealthBw.svg",
    import.meta.url
  ),
  BathfitterBw: new URL("src/assets/images/BathfitterBw.svg", import.meta.url),
  HbsBw: new URL("src/assets/images/HbsBw.svg", import.meta.url),
  StrategyAssistantBw: new URL(
    "src/assets/images/StrategyAssistantBw.svg",
    import.meta.url
  ),
  PellaBw: new URL("src/assets/images/PellaBw.svg", import.meta.url),
  ToolBuilderBw: new URL(
    "src/assets/images/ToolBuilderBw.svg",
    import.meta.url
  ),
  BackboneAssistantBw: new URL(
    "src/assets/images/BackboneAssistantBw.svg",
    import.meta.url
  ),
  GaleRFPBw: new URL("src/assets/images/GaleRFPBw.svg", import.meta.url),
  MediaOptimiserBw: new URL(
    "src/assets/images/MediaOptimiserBw.svg",
    import.meta.url
  ),
  HamburgerWhite: new URL(
    "src/assets/images/hamburgerWhite.png",
    import.meta.url
  ),
  FileUploadLogo: new URL(
    "src/assets/icons/file-upload-icon.svg",
    import.meta.url
  ),
  DashAssistantColor: new URL(
    "src/assets/images/DashAssistantColor.svg",
    import.meta.url
  ),
  DashAssistantBw: new URL(
    "src/assets/images/DashAssistantBw.svg",
    import.meta.url
  ),
  BarclaysAssistantColor: new URL(
    "src/assets/images/BarclaysAssistantColor.svg",
    import.meta.url
  ),
  BarclaysAssistantBw: new URL(
    "src/assets/images/BarclaysAssistantBw.svg",
    import.meta.url
  ),
  BarclaysAssistant: new URL(
    "src/assets/images/BarclaysAssistant.svg",
    import.meta.url
  ),
  Chilis_1710147539_341366Bw: new URL(
    "src/assets/images/ChillisBw.svg",
    import.meta.url
  ),
  Chilis_1710147539_341366Color: new URL(
    "src/assets/images/ChillisColor.svg",
    import.meta.url
  ),
  NewsIconColor: new URL("src/assets/images/NewsIcon.svg", import.meta.url),
  NewsIconBw: new URL("src/assets/images/NewsIcon.svg", import.meta.url),
  ChatIconBw: new URL("src/assets/images/ChatIcon.svg", import.meta.url),
  ChatIconColor: new URL("src/assets/images/ChatIcon.svg", import.meta.url),
  NorthWesternLogo: new URL(
    "src/assets/images/NorthWesternLogo.svg",
    import.meta.url
  ),
  SocialMediaInsights: new URL(
    "src/assets/images/social-media.svg",
    import.meta.url
  ),
  CluterTool: new URL("src/assets/images/cluster.svg", import.meta.url),
  // below are static decorative images
  LoginGridWave: new URL(
    "src/assets/images/login-page-grid-wave.png",
    import.meta.url
  ),
  PageGridWave: new URL(
    "src/assets/images/alchemy-ai-page-wave.png",
    import.meta.url
  ),
  ClientSpaceWave: new URL(
    "src/assets/images/alchemy-ai-page-wave-2.png",
    import.meta.url
  ),
  // below are custom icons
  UserProfileIcon: new URL("src/assets/icons/user-icon.svg", import.meta.url),
  CirclePlusIcon: new URL(
    "src/assets/icons/circle-plus-icon.svg",
    import.meta.url
  ),
  CircleUploadIcon: new URL(
    "src/assets/icons/circle-upload-icon.svg",
    import.meta.url
  ),
  CircleCloseIcon: new URL(
    "src/assets/icons/circle-close-icon.svg",
    import.meta.url
  ),
  BreadcrumbArrowIcon: new URL(
    "src/assets/icons/breadcrumb-arrow-icon.svg",
    import.meta.url
  ),
  SpeechBubbleIcon: new URL(
    "src/assets/icons/speech-bubble-icon.svg",
    import.meta.url
  ),
  SettingIcon: new URL("src/assets/icons/settings-icon.svg", import.meta.url),
  AILogo: new URL("src/assets/icons/ai-logo.svg", import.meta.url),
  // below are home page client logo
  HomeClientLogoGALE: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-gale.svg",
    import.meta.url
  ),
  HomeClientLogoChilis: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-chillis.svg",
    import.meta.url
  ),
  HomeClientLogoPella: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-pella.svg",
    import.meta.url
  ),
  HomeClientLogoHRBlock: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-hr-block.svg",
    import.meta.url
  ),
  HomeClientLogoDropbox: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-dropbox.svg",
    import.meta.url
  ),
  HomeClientLogoBathFitter: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-bath-fitter.svg",
    import.meta.url
  ),
  HomeClientLogoFogo: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-fogo.svg",
    import.meta.url
  ),
  HomeClientLogoMilkpep: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-milkpep.svg",
    import.meta.url
  ),
  HomeClientLogoNorthwesternMutual: new URL(
    "src/assets/images/homeClientLogos/home-client-logo-northwestern-mutual.svg",
    import.meta.url
  ),
  // below are client space client logo
  ClientSpaceLogoGALE: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-gale.svg",
    import.meta.url
  ),
  ClientSpaceLogoChilis: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-chillis.svg",
    import.meta.url
  ),
  ClientSpaceLogoPella: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-pella.svg",
    import.meta.url
  ),
  ClientSpaceLogoHRBlock: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-hr-block.svg",
    import.meta.url
  ),
  ClientSpaceLogoDropbox: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-dropbox.svg",
    import.meta.url
  ),
  ClientSpaceLogoBathFitter: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-bath-fitter.svg",
    import.meta.url
  ),
  ClientSpaceLogoFogo: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-fogo.svg",
    import.meta.url
  ),
  ClientSpaceLogoMilkpep: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-milkpep.svg",
    import.meta.url
  ),
  ClientSpaceLogoNorthwesternMutual: new URL(
    "src/assets/images/clientSpaceLogos/client-space-logo-northwestern-mutual.svg",
    import.meta.url
  ),
  // below are micro apps icon
  MicroappDashboardIcon: new URL(
    "src/assets/images/microappIcons/app-logo-dashboard.svg",
    import.meta.url
  ),
  MicroappSocialInsightsIcon: new URL(
    "src/assets/images/microappIcons/app-logo-creative-content-coder.svg",
    import.meta.url
  ),
  MicroappMediaPlanIcon: new URL(
    "src/assets/images/microappIcons/app-logo-media-planning-app.svg",
    import.meta.url
  ),
  MicroappClusteringIcon: new URL(
    "src/assets/images/microappIcons/app-logo-clustering-app.svg",
    import.meta.url
  ),
  MicroappNewsCultureIcon: new URL(
    "src/assets/images/microappIcons/app-logo-news-culture-app.svg",
    import.meta.url
  ),
  MicroappBradIcon: new URL(
    "src/assets/images/microappIcons/app-logo-BRAD.svg",
    import.meta.url
  ),
  MicroappInteractivePersonaIcon: new URL(
    "src/assets/images/microappIcons/app-logo-interactive-persona-builder-app.svg",
    import.meta.url
  ),
  MicroappBackboneIcon: new URL(
    "src/assets/images/microappIcons/app-logo-backbone-assistant-app.svg",
    import.meta.url
  ),
  MicroappPrivateWorldDataChatIcon: new URL(
    "src/assets/images/microappIcons/app-logo-private-world-data-chat-app.svg",
    import.meta.url
  ),
  MicroAppAudienceIcon: new URL(
    "src/assets/images/microappIcons/app-logo-audience-app.svg",
    import.meta.url
  ),
  MicroappLabelerIcon: new URL(
    "src/assets/images/microappIcons/app-logo-labeler-app.svg",
    import.meta.url
  ),
};

export const getImage = (name) => {
  return images[name];
};

export default {
  getImage,
};
