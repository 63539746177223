import React, { useRef, useState } from "react";
import {
  Popover,
  List,
  Typography,
  Card,
  Divider,
  Drawer,
  Button,
  Badge,
  Space,
  Select,
  Pagination,
  Input,
  Modal,
  Empty,
  Tooltip,
  Table,
  Row,
  Switch,
  Form,
} from "antd";
import moment from "moment";
import Style from "./opportunities.style";
import {
  DotChartOutlined,
  PushpinOutlined,
  PushpinFilled,
  LikeFilled,
  LikeOutlined,
  DislikeFilled,
  DislikeOutlined,
  FieldTimeOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import useOpportunitiesStatHooks from "./opportunities.hooks";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import IconGenerator from "src/components/IconGenerator";
import LoadingSpinner from "src/components/loadingSpinner";
import OpportunityFeedbackForm from "../opportunitiesFeedback/opportunitiesFeedbackForm.component";

const { Search } = Input;

const OpportunitiesComponent = ({
  analysisID,
  jobTriggers,
  setNodesFilter,
  setCommunitiesFilter,
  containerRef,
  analysisOverview,
}) => {
  const {
    drawerData,
    setDrawerData,
    annotatedOpportunitiesData,
    opportunitiesFilters,
    handleScrollTop,
    total,
    pageSize,
    handlePageChange,
    handleJobTriggerChange,
    handleSearch,
    jobLoader,
    searchLoader,
    loading,
    pageLoader,
    sourceLoader,
    sources,
    handleSourceChange,
    categories,
    modalData,
    setModalData,
    handleOpportunityFeedback,
    handleFeedbackSubmit,
    feedbackLoader,
    handleFeedbackTypeChange,
    deleteModalData,
    setDeleteModalData,
    handleDelete,
    customOpportunitiesData, // New state for custom opportunities
    customOpportunitiesFilters, // New state for custom opportunities filters
    userRuns, // New state for user runs
    handleCustomSearch, // New handler for custom search
    handleCustomPageChange, // New handler for custom page change
    handleUserRunChange, // New handler for user run change
    customPageSize,
    customTotal,
    userData,
    showOnlyMyRuns,
    setShowOnlyMyRuns,
    handleCustomFeedbackSubmit,
    handleCustomDelete,
    createCustomOpportunity,
    customOpportunityCreationLoading,
    createOpportunityModal,
    formRef,
    setCreateOpportunityModal,
    handlePin,
    handleCustomPin,
    handleExportOpportunities,
    handleExportCustomOpportunities,
    csvExport,
  } = useOpportunitiesStatHooks({
    analysisID,
    jobTriggers,
    containerRef,
    analysisOverview,
  });

  const renderOpportunities = (data) => {
    const columns = [
      {
        title: "",
        key: "nodes_link",
        render: (text, opportunity) => (
          <div style={{ width: "10px" }}>
            {opportunity.metadata.nodes && (
              <Popover content="Show Nodes in Knowledge Graph" placement="right">
                <DotChartOutlined
                  // className={Style.setNodesButton}
                  onClick={() => {
                    setNodesFilter(opportunity.metadata.nodes);
                    handleScrollTop();
                  }}
                />
              </Popover>
            )}
            {opportunity.metadata.communities && (
              <Popover content="Show Communities in Knowledge Graph" placement="right">
                <DotChartOutlined
                  // className={Style.setNodesButton}
                  onClick={() => {
                    setCommunitiesFilter(opportunity.metadata.communities);
                    handleScrollTop();
                  }}
                />
              </Popover>
            )}
          </div>
        ),
        width: 20,
      },
      {
        title: () => <div style={{ padding: "16px 16px" }}>Opportunity</div>,
        dataIndex: "opportunity",
        key: "opportunity",
        render: (text, opportunity) => <div className={Style.opportunitiesCardTitle}>{opportunity.opportunity}</div>,
        className: Style.opportunitiesCell,
        width: 420,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Opportunity Type</div>,
        key: "opportunity_type",
        style: { width: "20%" },
        render: (text, opportunity) => (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <div className={Style.opportunityTypeCell} style={{ backgroundColor: opportunity.color }}>
              {opportunity.urgency_score >= 9 ? (
                <>
                  <Tooltip overlayClassName={Style.timeTooltip} title={"Time-sensitive opportunity."}>
                    <FieldTimeOutlined />
                  </Tooltip>{" "}
                  {opportunity.type}
                </>
              ) : (
                opportunity.type
              )}
            </div>
          </div>
        ),
        width: 150,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Created At</div>,
        dataIndex: "created_on",
        key: "created_on",
        render: (text, opportunity) => (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "11.6px" }}>
            {moment(opportunity.created_on).format("MMM D, YYYY h:mm A")}
          </div>
        ),
        width: 100,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Actions</div>,
        key: "actions",
        render: (text, opportunity) => (
          <div className={Style.actionsContainerWrapper}>
            <div className={Style.actionsContainer}>
              <Popover
                content={
                  opportunity?.feedbacks?.[0]?.feedback_type === "LIKED"
                    ? `Liked by ${
                        opportunity?.feedbacks?.[0]?.feedback_user.first_name
                          ? opportunity?.feedbacks?.[0]?.feedback_user.first_name +
                            " " +
                            opportunity?.feedbacks?.[0]?.feedback_user.last_name
                          : opportunity?.feedbacks?.[0]?.feedback_user.email
                      }`
                    : ""
                }
              >
                {opportunity?.feedbacks?.[0]?.feedback_type === "LIKED" ? (
                  <LikeFilled
                    className={Style.likeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "LIKED")}
                  />
                ) : (
                  <LikeOutlined
                    className={Style.likeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "LIKED")}
                  />
                )}
              </Popover>
              &nbsp;&nbsp;
              <Popover
                content={
                  opportunity?.feedbacks?.[0]?.feedback_type === "DISLIKED"
                    ? `Disliked by ${
                        opportunity?.feedbacks?.[0]?.feedback_user.first_name
                          ? opportunity?.feedbacks?.[0]?.feedback_user.first_name +
                            " " +
                            opportunity?.feedbacks?.[0]?.feedback_user.last_name
                          : opportunity?.feedbacks?.[0]?.feedback_user.email
                      }`
                    : ""
                }
              >
                {opportunity?.feedbacks?.[0]?.feedback_type === "DISLIKED" ? (
                  <DislikeFilled
                    className={Style.dislikeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "DISLIKED")}
                  />
                ) : (
                  <DislikeOutlined
                    className={Style.dislikeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "DISLIKED")}
                  />
                )}
              </Popover>
              &nbsp;&nbsp;
              <Popover
                content={
                  opportunity.is_pinned
                    ? `Pinned by ${
                        opportunity.pinned_by_user_data.first_name
                          ? opportunity.pinned_by_user_data.first_name + " " + opportunity.pinned_by_user_data.last_name
                          : opportunity.pinned_by_user_data.email
                      }. Click to unpin`
                    : "Pin this opportunity"
                }
              >
                {opportunity.is_pinned ? (
                  <PushpinFilled
                    className={Style.pinIcon}
                    onClick={() =>
                      handlePin({
                        opportunityID: opportunity.id,
                        isPinned: !opportunity.is_pinned,
                      })
                    }
                  />
                ) : (
                  <PushpinOutlined
                    className={Style.pinIcon}
                    onClick={() =>
                      handlePin({
                        opportunityID: opportunity.id,
                        isPinned: !opportunity.is_pinned,
                      })
                    }
                  />
                )}
              </Popover>
              {/* <Button type="link" onClick={() => handleOpportunityFeedback(opportunity, "DELETE")}>
              Delete
            </Button> */}
            </div>
            {/* <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div> */}
            <Divider type="vertical" />
            <DeleteFilled
              // className={Style.deleteOpportunityIcon}
              onClick={() => setDeleteModalData(opportunity)}
            />
          </div>
        ),
        width: 70,
      },
      {
        // title: () => <div style={{ textAlign: "center" }}>Details</div>,
        key: "details",
        render: (text, opportunity) => (
          <Button type="link" onClick={() => setDrawerData(opportunity)}>
            View details
          </Button>
        ),
        fixed: "right",
        width: 80,
      },
    ];

    return (
      <Table
        pagination={false}
        className={Style.darkTable}
        columns={columns}
        dataSource={data}
        rowKey="id"
        scroll={{ y: 620 }}
      />
    );
  };
  
  const renderCustomOpportunityRun = (opportunityRun) => {
    const dataSource = [
      {
        key: "1",
        label: "Run Name",
        value: opportunityRun?.run_name,
      },
      {
        key: "2",
        label: "Client Information",
        value: opportunityRun?.client_info,
      },
      {
        key: "3",
        label: "Problem Statement",
        value: opportunityRun?.problem_statement,
      },
      {
        key: "4",
        label: "User Query",
        value: opportunityRun?.user_query,
      },
      {
        key: "5",
        label: "Created by",
        value: opportunityRun?.created_by_user_data?.first_name
          ? `${opportunityRun?.created_by_user_data.first_name} ${opportunityRun?.created_by_user_data.last_name}`
          : opportunityRun?.created_by_user_data?.email,
      },
    ].filter((item) => item.value); // Filter out items with no value

    const columns = [
      {
        title: "Label",
        dataIndex: "label",
        key: "label",
        render: (text) => <strong>{text}</strong>,
        width: 97,
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (text) => <div className={Style.popOverValue}>{text}</div>,
      },
    ];

    return (
      <>
        <Table
          className={Style.popOverTable}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          showHeader={false}
        />
      </>
    );
    
  }

  const renderCustomOpportunities = (data) => {
    const columns = [
      {
        title: "",
        key: "nodes_link",
        render: (text, opportunity) => (
          <div style={{ width: "10px" }}>
            {opportunity.metadata.nodes && (
              <Popover content="Show Nodes in Knowledge Graph" placement="right">
                <DotChartOutlined
                  // className={Style.setNodesButton}
                  onClick={() => {
                    setNodesFilter(opportunity.metadata.nodes);
                    handleScrollTop();
                  }}
                />
              </Popover>
            )}
            {opportunity.metadata.communities && (
              <Popover content="Show Communities in Knowledge Graph" placement="right">
                <DotChartOutlined
                  // className={Style.setNodesButton}
                  onClick={() => {
                    setCommunitiesFilter(opportunity.metadata.communities);
                    handleScrollTop();
                  }}
                />
              </Popover>
            )}
          </div>
        ),
        width: 20,
      },
      {
        title: () => <div style={{ padding: "16px 16px" }}>Opportunity</div>,
        dataIndex: "opportunity",
        key: "opportunity",
        render: (text, opportunity) => <div className={Style.opportunitiesCardTitle}>{opportunity.opportunity}</div>,
        className: Style.opportunitiesCell,
        width: 420,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Opportunity Run Name</div>,
        dataIndex: "run_name",
        key: "run_name",
        render: (text, opportunity) => (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", cursor: "default" }}>
            <div className={Style.opportunityTypeCell} style={{ backgroundColor: opportunity.color }}>
              {opportunity.urgency_score >= 9 ? (
                <>
                  <Tooltip overlayClassName={Style.timeTooltip} title={"Time-sensitive opportunity."}>
                    <FieldTimeOutlined />
                  </Tooltip>{" "}
                  <Popover
                    placement="right"
                    overlayClassName={Style.popoverContent}
                    zIndex={9999}
                    content={renderCustomOpportunityRun(opportunity?.analysis_user_opportunity_run_data)}
                  >
                    {opportunity.type}
                  </Popover>
                </>
              ) : (
                <Popover
                  placement="right"
                  overlayClassName={Style.popoverContent}
                  zIndex={9999}
                  content={renderCustomOpportunityRun(opportunity?.analysis_user_opportunity_run_data)}
                >
                  {opportunity.type}
                </Popover>
              )}
            </div>
          </div>
        ),
        width: 150,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Created At</div>,
        dataIndex: "created_on",
        key: "created_on",
        render: (text, opportunity) => (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", fontSize: "11.6px" }}>
            {moment(opportunity.created_on).format("MMM D, YYYY h:mm A")}
          </div>
        ),
        width: 100,
      },
      {
        title: () => <div style={{ textAlign: "center" }}>Actions</div>,
        key: "actions",
        render: (text, opportunity) => (
          <div className={Style.actionsContainerWrapper}>
            <div className={Style.actionsContainer}>
              <Popover
                content={
                  opportunity?.feedbacks?.[0]?.feedback_type === "LIKED"
                    ? `Liked by ${
                        opportunity?.feedbacks?.[0]?.feedback_user.first_name
                          ? opportunity?.feedbacks?.[0]?.feedback_user.first_name +
                            " " +
                            opportunity?.feedbacks?.[0]?.feedback_user.last_name
                          : opportunity?.feedbacks?.[0]?.feedback_user.email
                      }`
                    : ""
                }
              >
                {opportunity?.feedbacks?.[0]?.feedback_type === "LIKED" ? (
                  <LikeFilled
                    className={Style.likeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "LIKED")}
                  />
                ) : (
                  <LikeOutlined
                    className={Style.likeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "LIKED")}
                  />
                )}
              </Popover>
              &nbsp;&nbsp;
              <Popover
                content={
                  opportunity?.feedbacks?.[0]?.feedback_type === "DISLIKED"
                    ? `Disliked by ${
                        opportunity?.feedbacks?.[0]?.feedback_user.first_name
                          ? opportunity?.feedbacks?.[0]?.feedback_user.first_name +
                            " " +
                            opportunity?.feedbacks?.[0]?.feedback_user.last_name
                          : opportunity?.feedbacks?.[0]?.feedback_user.email
                      }`
                    : ""
                }
              >
                {opportunity?.feedbacks?.[0]?.feedback_type === "DISLIKED" ? (
                  <DislikeFilled
                    className={Style.dislikeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "DISLIKED")}
                  />
                ) : (
                  <DislikeOutlined
                    className={Style.dislikeReactionIcon}
                    onClick={() => handleOpportunityFeedback(opportunity, "DISLIKED")}
                  />
                )}
              </Popover>
              &nbsp;&nbsp;
              <Popover
                content={
                  opportunity.is_pinned
                    ? `Pinned by ${
                        opportunity.pinned_by_user_data.first_name
                          ? opportunity.pinned_by_user_data.first_name + " " + opportunity.pinned_by_user_data.last_name
                          : opportunity.pinned_by_user_data.email
                      }. Click to unpin`
                    : "Pin this opportunity"
                }
              >
                {opportunity.is_pinned ? (
                  <PushpinFilled
                    className={Style.pinIcon}
                    onClick={() =>
                      handleCustomPin({
                        opportunityID: opportunity.id,
                        isPinned: !opportunity.is_pinned,
                      })
                    }
                  />
                ) : (
                  <PushpinOutlined
                    className={Style.pinIcon}
                    onClick={() =>
                      handleCustomPin({
                        opportunityID: opportunity.id,
                        isPinned: !opportunity.is_pinned,
                      })
                    }
                  />
                )}
              </Popover>
              <Divider type="vertical" />
              <DeleteFilled onClick={() => setDeleteModalData(opportunity)} />
            </div>
          </div>
        ),
        width: 70,
      },
      {
        key: "details",
        render: (text, opportunity) => (
          <Button type="link" onClick={() => setDrawerData(opportunity)}>
            View details
          </Button>
        ),
        // fixed: "right",
        width: 80,
      },
    ];

    return (
      <Table
        pagination={false}
        className={Style.darkTable}
        columns={columns}
        dataSource={data}
        rowKey="id"
        scroll={{
          y: 620,
        }}
      />
    );
  };

  if (loading && !annotatedOpportunitiesData) {
    return (
      <div>
        <LoadingSpinner centered />
      </div>
    );
  }

  return (
    <>
      <div className={Style.filtersContainerWrapper}>
        <div className={Style.filtersContainer}>
          <Search
            loading={searchLoader}
            className={Style.searchTextBox}
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 16, width: "270px", display: "flex" }}
          />
          {sources && (
            <Select
              loading={sourceLoader}
              allowClear
              className={Style.sourceDropdown}
              placeholder="Source"
              onChange={(value) => handleSourceChange(value)}
            >
              {sources.map((source) => (
                <Select.Option key={source} value={source}>
                  {categories[source]?.label || source}
                </Select.Option>
              ))}
            </Select>
          )}
          <Select
            loading={feedbackLoader}
            allowClear
            defaultValue={null}
            className={Style.feedbackSelect}
            placeholder="Feedback Type"
            onClear={() => handleFeedbackTypeChange(null)}
            onChange={(value) => handleFeedbackTypeChange(value)}
          >
            <Select.Option key={1} value={"LIKED"}>
              Liked
            </Select.Option>
            <Select.Option key={2} value={"DISLIKED"}>
              Disliked
            </Select.Option>
          </Select>
          <Select
            loading={jobLoader}
            allowClear
            defaultValue={jobTriggers?.filter((jobTrigger) => jobTrigger.state === "DONE")[0].id}
            className={Style.jobTriggerSelect}
            placeholder="Job Run Date"
            onChange={(value) => handleJobTriggerChange(value)}
          >
            {jobTriggers
              ?.filter((jobTrigger) => jobTrigger.state === "DONE")
              .map((trigger) => (
                <Select.Option key={trigger.id} value={trigger.id}>
                  {moment(trigger?.job_triggered_at).format("LLL")}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div style={{ paddingLeft: "20px" }}>
          <PrimaryButton
            loading={csvExport === "opportunities"}
            className={`small ${Style.exportOpportunities}`}
            onClick={handleExportOpportunities}
          >
            Export Opportunities
          </PrimaryButton>
        </div>
      </div>
      <div className={Style.cardContainer}>
        {annotatedOpportunitiesData?.length !== 0 ? (
          renderOpportunities(annotatedOpportunitiesData)
        ) : (
          <Empty description="No data found" style={{ padding: "0.75rem 0", width: "100%" }} />
        )}
        <div className={Style.opportunitiesPageWrapper}>
          <Pagination
            showSizeChanger={false}
            current={opportunitiesFilters.page}
            total={total}
            onChange={handlePageChange}
            className={Style.pagination}
            pageSize={pageSize}
          />
        </div>
      </div>
      <Divider />
      <div className={Style.filtersContainerWrapper}>
        <div className={Style.filtersContainer}>
          <Search
            loading={searchLoader}
            className={Style.searchTextBox}
            placeholder="Search Custom Opportunities"
            onChange={(e) => handleCustomSearch(e.target.value)}
            style={{ marginBottom: 16, width: "300px", display: "flex" }}
          />
          <Select
            allowClear
            defaultValue={null}
            value={customOpportunitiesFilters.analysis_user_opportunity_run_id}
            className={Style.jobTriggerSelect}
            placeholder="User Run"
            onChange={(value) => handleUserRunChange(value)}
            optionLabelProp="label"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            dropdownRender={(menu) => (
              <div>
                <div className={Style.customDropdownHeader}>
                  <span style={{ paddingRight: "10px" }}>Show only my queries: </span>
                  <Switch
                    style={{ width: "10%" }}
                    checked={showOnlyMyRuns}
                    onChange={() => setShowOnlyMyRuns(!showOnlyMyRuns)}
                  />
                </div>
                {menu}
              </div>
            )}
          >
            {userRuns
              ?.filter((elem) => {
                if (showOnlyMyRuns) {
                  return elem.created_by === userData?.id;
                }
                return true;
              })
              .map((elem, idx) => {
                return (
                  <Select.Option key={idx} value={elem.id} label={elem.run_name}>
                    <Popover
                      placement="right"
                      overlayClassName={Style.popoverContent}
                      zIndex={9999}
                      mouseEnterDelay={0.5}
                      content={renderCustomOpportunityRun(elem)}
                    >
                      <div className={Style.savedFilterOptions}>
                        <div className={Style.userIcon}>
                          <IconGenerator
                            height={24}
                            width={24}
                            initial={
                              elem.created_by_user_data?.first_name
                                ? elem.created_by_user_data?.first_name.charAt(0) +
                                  elem.created_by_user_data?.last_name.charAt(0)
                                : elem.created_by_user_data?.email.slice(0, 2)
                            }
                            backgroundColor={"var(--color-white)"}
                            color="var(--color-full-black)"
                          />
                        </div>
                        <div className={Style.filterContent}>
                          <div className={Style.filterName}>{elem.run_name}</div>
                          <div className={Style.filterDate}>{moment(elem.created_at).format("LLL")}</div>
                        </div>
                      </div>
                    </Popover>
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ paddingRight: "10px" }}>
            <PrimaryButton
              loading={csvExport === "customOpportunities"}
              className={`small ${Style.createOpportunityButton}`}
              onClick={handleExportCustomOpportunities}
            >
              Export Custom Opportunities
            </PrimaryButton>
          </div>
          <div>
            <PrimaryButton
              className={`small ${Style.createOpportunityButton}`}
              onClick={() => setCreateOpportunityModal(true)}
            >
              Create Custom Opportunity
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={Style.cardContainer}>
        {customOpportunitiesData?.length !== 0 ? (
          renderCustomOpportunities(customOpportunitiesData)
        ) : (
          <Empty description="No custom opportunities found" style={{ padding: "0.75rem 0", width: "100%" }} />
        )}
        <div className={Style.opportunitiesPageWrapper}>
          <Pagination
            showSizeChanger={false}
            current={customOpportunitiesFilters.page}
            total={customTotal}
            onChange={handleCustomPageChange}
            className={Style.pagination}
            pageSize={customPageSize}
          />
        </div>
      </div>
      {drawerData && (
        <Drawer
          title="Opportunity Details"
          placement="right"
          onClose={() => setDrawerData(null)}
          width={"40%"}
          open={!!drawerData}
        >
          <div className={Style.sectionHeader}>{drawerData.opportunity}</div>
          <Divider />
          <div>
            <div className={Style.sectionHeader}>Reason:</div>
            <div>{drawerData.reason}</div>
          </div>
          <Divider />
          <div>
            <div className={Style.sectionHeader}>Actionable Steps:</div>
            <List
              className={Style.opportunitiesList}
              dataSource={drawerData.actionable_steps}
              renderItem={(step) => <List.Item>• {step}</List.Item>}
            />
          </div>
          <Divider />
          {drawerData?.metadata?.nodes ? (
            <>
              <div className={Style.sectionHeader}>Nodes:</div>
              <div className={Style.opportunityNodes}>
                {drawerData?.metadata?.nodes?.map((node) => (
                  <div className={Style.nodesCard}>{node}</div>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className={Style.sectionHeader}>Communities:</div>
              <div className={Style.opportunityNodes}>
                {drawerData?.metadata?.communities?.map((node) => (
                  <div className={Style.nodesCard}>{node}</div>
                ))}
              </div>
            </>
          )}
          {drawerData.opportunity_score && (
            <>
              <Divider />
              <div>
                <div className={Style.sectionHeader}>Opportunity Score: {drawerData.opportunity_score}</div>
              </div>
            </>
          )}
        </Drawer>
      )}
      {modalData && (
        <Modal maskClosable={true} destroyOnClose open={!!modalData} onCancel={() => setModalData(null)} footer={null}>
          <OpportunityFeedbackForm
            opportunity={modalData?.opportunity}
            feedbackData={modalData?.feedbackData}
            onSubmitFeedback={
              modalData?.opportunity?.opportunity_type === "USER_QUERY"
                ? handleCustomFeedbackSubmit
                : handleFeedbackSubmit
            }
            onDone={() => setModalData(null)}
          />
        </Modal>
      )}
      {createOpportunityModal && (
        <Modal
          maskClosable={true}
          destroyOnClose
          centered
          open={!!createOpportunityModal}
          onCancel={() => setCreateOpportunityModal(false)}
          footer={null}
          width={"50%"}
        >
          <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
            Generate Custom Opportunities
          </div>
          <div>
            <Form
              layout="vertical"
              ref={formRef}
              initialValues={{
                client_info: analysisOverview?.client_info,
                problem_statement: analysisOverview?.problem_statement,
              }}
              onFinish={(val) =>
                createCustomOpportunity(
                  val.run_name,
                  val.user_query,
                  val.client_info,
                  val.problem_statement,
                  val.analysis_section_config_ids,
                )
              }
            >
              <Form.Item
                label="Run Name"
                name="run_name"
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                ]}
              >
                <Input placeholder="Enter name for the run" />
              </Form.Item>
              <Form.Item
                label="Client Information:"
                name="client_info"
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: "Client Information is required",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  style={{ borderRadius: "5px" }}
                  placeholder="Some information on the client"
                />
              </Form.Item>
              <Form.Item
                label="Problem Statement:"
                name="problem_statement"
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: "Problem Statement is required",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  style={{ borderRadius: "5px" }}
                  placeholder="Problem statement you are trying to address with this run."
                />
              </Form.Item>
              <Form.Item
                label="Generate Opportunities around:"
                name="user_query"
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: "User Query is required",
                  },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  style={{ borderRadius: "5px" }}
                  placeholder="Ex: Music concerts with DJ"
                />
              </Form.Item>
              <Form.Item label="Section:" name="analysis_section_config_ids" shouldUpdate>
                <Select mode="multiple" showArrow style={{ width: "100%" }} allowClear>
                  {analysisOverview?.section_data?.map((section) => (
                    <Select.Option key={section?.section_id} value={section?.section_id}>
                      {section?.section_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
          <Row justify="space-between">
            <SecondaryButton className="small" onClick={() => setCreateOpportunityModal(false)}>
              Cancel
            </SecondaryButton>
            <SecondaryButton
              loading={customOpportunityCreationLoading}
              className="small"
              onClick={() => formRef.current.submit()}
            >
              Create Opportunities
            </SecondaryButton>
          </Row>
        </Modal>
      )}
      {deleteModalData && (
        <Modal
          maskClosable={true}
          destroyOnClose
          open={!!deleteModalData}
          onCancel={() => setDeleteModalData(null)}
          footer={null}
        >
          <div>
            <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
              Are you sure you want to delete this opportunity?
            </div>
            <div style={{ marginBottom: "1rem", fontStyle: "italic", minHeight: "70px" }}>
              "{deleteModalData.opportunity}"
            </div>
            <Row justify="space-between">
              <SecondaryButton className="small" loading={loading} onClick={() => setDeleteModalData(null)}>
                Cancel
              </SecondaryButton>
              <SecondaryButton
                className="small"
                loading={loading}
                onClick={() =>
                  deleteModalData.opportunity_type === "USER_QUERY"
                    ? handleCustomDelete(deleteModalData.id)
                    : handleDelete(deleteModalData.id)
                }
              >
                Delete
              </SecondaryButton>
            </Row>
          </div>
        </Modal>
      )}
    </>
  );
};

export default OpportunitiesComponent;

