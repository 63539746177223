import React from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { Link as ReactRouterLink } from 'react-router-dom';

const Style = {};

/**
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Boolean} [props.strong] Determines if the link text should be bold
 * @param {String|null} [props.className] extra Classname(s)
 * @param {String} props.to Path the link should travel to
 * @returns
 */
function _Link({
  children, strong, className, ...props
}) {
  return (
    <ReactRouterLink
      {...props}
      className={`${className || ''} ${strong ? Style.Strong : ''}`}
    >
      {children}
    </ReactRouterLink>
  );
}

/**
 *
 * @param {Object} props
 * @param {any} props.children
 * @param {Boolean} [props.strong] Determines if the link text should be bold
 * @param {String|null} [props.className] extra Classname(s)
 * @param {String} props.to Path the link should travel to
 * @returns
 */
function _ExternalLink({
  to, children, strong = false, className = null, datatestId='', ...props
}) {
  return (
    <a
      {...props}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`${className || ''} ${strong ? Style.Strong : ''}`}
      data-testid={datatestId}
    >
      {children}
    </a>
  );
}

export const Link = styled(_Link)`
  color: var(--color-link);
  text-decoration: none;
`;

// TODO: Find an override using tagname
export const ExternalLink = styled(_ExternalLink)`
  color: var(--color-link) !important;
  text-decoration: none !important;
  font-weight: bolder;
`;

Style.Strong = css`
  font-weight: 600;
`;

export default {};
