import { Drawer } from "antd";
import React from "react";
import { RoundedPrimaryButton } from "src/components/basic";
import ColumnDrawer from "./ColumnDrawer";
import useSqlColumnHook from "./useSqlColumnHook";
import { EditOutlined } from "@ant-design/icons";

export default function SqlColumn({ value = [], onChange, tableName, virtualTable, setUnsavedChanges }) {
  const { columnDrawer, setColumnDrawer } = useSqlColumnHook();
  return (
    <>
      <EditOutlined onClick={() => setColumnDrawer(true)} style={{color:'var(--color-gale-gray)'}}/>
      <Drawer
        title={`Add columns ${tableName ? `for ${tableName}` : "new table"}`}
        maskClosable={true}
        placement="right"
        onClose={() => setColumnDrawer(false)}
        open={columnDrawer}
        destroyOnClose
        size="large"
        width={"80%"}
      >
        <ColumnDrawer
          onChange={onChange}
          value={value}
          setColumnDrawer={setColumnDrawer}
          virtualTable={virtualTable}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Drawer>
    </>
  );
}
