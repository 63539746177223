/**
 * Checks if the permission List has all(AND between all permissions passed) the permissions passed.
 * @param {Array<String>} permissionList  Array of all permissions
 * @param {Array<String>} permissionsToCheck Array of all permissions to check against the permissionList
 * @returns {Boolean} True if allowed, false otherwise
 */
export function hasAllPermissions(permissionList, permissionsToCheck) { // eslint-disable-line
  permissionsToCheck.forEach((item) => {
    if (permissionList.includes(item)) {
      return true;
    }
  })
  return false;
}

/**
 * Checks if the permission List has all the permissions passed.
 * @param {Array} permissionList Array of all permissions
 * @param {String} permissionToCheck Permission to check against the permissionList
 * @returns Boolean Result of the permission
 */
function has(permissionList, permissionToCheck) { // eslint-disable-line
  return true;
}

export default {
  has,
  // hasAll,
};
