import { Select } from 'antd';
import React from 'react';
import Style from '../conversation.style';

const ConversationSelect = ({
  placeholder,
  options,
  style,
  mode,
  onChange,
  onBlur,
  defaultValue,
  isSearchable, // New boolean prop to control search functionality
  tagCount
}) => {
  return (
    <Select
      mode={mode}
      allowClear
      showSearch={isSearchable} // Controlled by the boolean prop
      placeholder={placeholder}
      className={Style.selectStyle}
      defaultValue={defaultValue}
      options={options}
      maxTagCount={tagCount}
      style={style}
      onChange={onChange}
      onBlur={onBlur}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default ConversationSelect;
