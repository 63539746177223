import React, { Suspense } from "react";
import Style from "../../aiApp.style";
import { Row, Tooltip } from "antd";
import moment from "moment";
import {
  CheckOutlined,
  CopyOutlined,
  DislikeFilled,
  DislikeOutlined,
  InfoCircleOutlined,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import ConversationResponse from "./ConversationResponse";
import useAiAppConversationHook from "../hooks/useAiAppConversationHook";
import AssistantAppIcon from "src/modules/assistantAppIcon/assistantAppIcon";
import IconGenerator from "src/components/IconGenerator";

export default function Conversation({
  item,
  data,
  idx,
  latestConversation,
  feedbackReaction,
  feedbackLoading,
  openFeedBackFormModal,
}) {
  const {
    copyToClipBoard,
    isMobile,
    showReactions,
    setShowReactions,
    showInputCopy,
    setShowInputCopy,
    elementCopiedToClipBoard,
    appContext,
    userIndex,
    name,
    userFeedback,
  } = useAiAppConversationHook({ item });

  const renderTimestamp = (timestamp) => {
    const today = moment().startOf("day");
    return (
      <Tooltip overlayClassName={Style.timeTooltip} title={<div>{moment(timestamp)?.format("DD MMM YYYY, hh:mm A")}</div>}>
        <div className={`${Style.reactionContainer} ${Style.timestampTime}`}>{moment(timestamp).startOf("day") < today ? moment(timestamp)?.format("DD MMM YYYY, hh:mm A") : moment(timestamp)?.format("hh:mm A")}</div>
      </Tooltip>
    );
  };

  const renderAIResponse = () => {
    return (
      <div
        className={Style.chatMessageContainer}
        onMouseEnter={() => (isMobile ? null : setShowReactions(true))}
        onMouseLeave={() => (isMobile ? null : setShowReactions(false))}
      >
        <div className={Style.botLogoNameContainer}>
          <AssistantAppIcon data={data} showBackground={true} size={24} theme="dark" />
          <div className={Style.messageLabelBot}>
            <span>{data?.assistant_display_name || data?.name}</span>
          </div>
        </div>
        <div className={Style.chatDesc}>
          <ConversationResponse
            data={item?.ai_output}
            analysisData={item?.generated_steps}
            mAiApp={data?.id}
            chatId={item?.id}
            hasAnalysis={item?.has_analysis}
          />
          <div className={Style.responseFootnote}>
            <div className={Style.timeAndInfoParent}>
              <Tooltip
                overlayClassName={Style.timeTooltip}
                title={
                  <div>
                    <div>
                      <span>Runtime: </span>
                      <span>{item?.completion_time?.toFixed(2).concat("s")}</span>
                    </div>
                  </div>
                }
              >
                <InfoCircleOutlined className={Style.responseInfoIcon} />
              </Tooltip>
              {renderTimestamp(item?.output_at)}
            </div>
            <div
              className={`${Style.reactionContainer} ${feedbackLoading && Style.reactionContainerDisabled} ${
                !(showReactions || latestConversation || isMobile) ? Style.hiddenReactions : ""
              }`}
            >
              {elementCopiedToClipBoard === "output-text" ? (
                <CheckOutlined className={Style.copiedIcon} />
              ) : (
                <CopyOutlined
                  className={Style.reactionIcon}
                  onClick={() =>
                    copyToClipBoard({
                      answerType: "output-text",
                      text: item?.ai_output?.final_answer,
                    })
                  }
                />
              )}
              &nbsp;&nbsp;
              {userFeedback?.user_reaction === "LIKED" ? (
                <LikeFilled
                  style={{ fontSize: "1rem" }}
                  className={Style.reactionIcon}
                  onClick={() => feedbackReaction(item, "LIKED", idx, userFeedback)}
                />
              ) : (
                <LikeOutlined
                  style={{ fontSize: "1rem" }}
                  className={Style.reactionIcon}
                  onClick={() => feedbackReaction(item, "LIKED", idx, userFeedback)}
                />
              )}
              &nbsp;&nbsp;
              {userFeedback?.user_reaction === "DISLIKED" ? (
                <DislikeFilled
                  style={{ fontSize: "1rem" }}
                  className={Style.reactionIcon}
                  onClick={() => openFeedBackFormModal(item, "DISLIKED", idx, userFeedback)}
                />
              ) : (
                <DislikeOutlined
                  style={{ fontSize: "1rem" }}
                  className={Style.reactionIcon}
                  onClick={() => openFeedBackFormModal(item, "DISLIKED", idx, userFeedback)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={Style.chatMessage}>
        <div
          className={Style.chatMessageContainer}
          onMouseEnter={() => (isMobile ? null : setShowInputCopy(true))}
          onMouseLeave={() => (isMobile ? null : setShowInputCopy(false))}
        >
          <div className={Style.chatDesc + " right-align"}>
            <Row justify="end" style={{gap:8}}>
              <p className={Style.userLabel}>{item?.first_name ? item?.first_name + ' ' + item?.last_name : item?.email}</p>
              <IconGenerator initial={item?.first_name ? item?.first_name?.[0] : item?.email?.[0]} width={24} height={24} backgroundColor="white" color="black"></IconGenerator>
            </Row>
            <div className={Style.questionContainer} style={{ whiteSpace: "pre-line" }}>
              {item?.user_input}
            </div>
            <div className={Style.responseFootnote}>
              <div className={`${Style.reactionContainer} right-align ${!(showInputCopy || isMobile) ? Style.hiddenReactions : ""}`}>
                {elementCopiedToClipBoard === "input-text" ? (
                  <CheckOutlined className={Style.copiedIcon} />
                ) : (
                  <CopyOutlined
                    className={Style.reactionIcon}
                    onClick={() =>
                      copyToClipBoard({
                        answerType: "input-text",
                        text: item?.user_input,
                      })
                    }
                  />
                )}
              </div>
              {renderTimestamp(item?.input_at)}
            </div>
          </div>
        </div>
        {/* below are the ai response */}
        {renderAIResponse()}
      </div>
    </Suspense>
  );
}
