import React from "react";
import Style from "./authenticationLayout.style";
import Assets from "src/assets/index";
// import { useOktaAuth } from "@okta/okta-react";
import Login from "./components/login/newLogin/Login";
import Footer from "../footer/Footer";

export default function AuthenticationLayout() {
  // const { oktaAuth } = useOktaAuth();
  const [count, setCount] = React.useState(5);

  // async function fetchOktaLink() {
  //   try {
  //     oktaAuth.signInWithRedirect({ originalUri: "/login/" });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // React.useEffect(() => {
  //   window.setTimeout(() => {
  //     fetchOktaLink();
  //   },4000)
  // }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount((prevCount) => prevCount - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [count]);

  return (
    <div className={Style.container}>
      <div className={Style.contentContainer}>
        <div className={Style.leftContainer}>
          <div className={Style.mainLoginContainer}>
            <div className={Style.mainLogoContainer + ' transition-all'}>
              <img width={"100%"} src={Assets.getImage("AlchemyLogo")} alt="logo" />
            </div>
            <div className={Style.form}>
              <Login />
            </div>
          </div>
          <div className={Style.decorativeBackgroundImage}>
            <img className="select-disable" src={Assets.getImage("LoginGridWave")} alt="decorative element"/>
          </div>
          <Footer transparent={true}/>
        </div>
      </div>
    </div>
  );
}
