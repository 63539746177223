import { notification } from "antd";
import { useEffect, useState } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { BEHAVIOURAL } from "../../constants/audienceDetailsConstants";
import audienceListService from "../../services/audienceListService";

const useAttitudinalOverviewHook = ({ audienceId, activeTab }) => {

    const [attitudinalOverviewLoading, setAttitudinalOverviewLoading] = useState(true);
    const [attitudinalOverviewData, setAttitudinalOverviewData] = useState(null);

    const fetchAttitudinalOverviewData = async () => {
        try {
            const audienceData = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "", 1, BEHAVIOURAL, true);
            setAttitudinalOverviewData(audienceData.data);
            setAttitudinalOverviewLoading(false);
        } catch (error) {
            setAttitudinalOverviewLoading(false);
            const { msg } = getErrorMessageFromResponse(error);
            notification.error({
                duration: 5,
                message: msg,
                placement: "bottomRight",
            });
        }
    }

    const getReplaceFeaturePayload = (newFeature, parentFeatureId) => {
        return {
            "audience_id": audienceId,
            "parent_feature_id": parentFeatureId,
            "new_parent_feature_name": newFeature.leaf_node,
            "new_parent_feature_directory_filters": newFeature.directory_path,
            "profile_type": BEHAVIOURAL
        }
    }

    const replaceBehaviouralFeature = async (newFeature, parentfeatureId) => {
        try {
            setAttitudinalOverviewLoading(true);
            const payload = getReplaceFeaturePayload(newFeature, parentfeatureId);
            const response = await audienceListService.replaceAudienceBehaviouralFeature(payload);
            await fetchAttitudinalOverviewData();
        } catch (error) {
            setAttitudinalOverviewLoading(false);
            const { msg } = getErrorMessageFromResponse(error);
            notification.error({
                duration: 5,
                message: msg,
                placement: "bottomRight",
            });
        }
    }

    useEffect(() => {
        if (activeTab === '4' && attitudinalOverviewData !== null) {
            fetchAttitudinalOverviewData();
        } else if (attitudinalOverviewData === null) {
            setAttitudinalOverviewLoading(true);
            fetchAttitudinalOverviewData();
        }
    }, [audienceId, activeTab]);

    return {
        attitudinalOverviewLoading,
        attitudinalOverviewData,
        replaceBehaviouralFeature
    };
};
export default useAttitudinalOverviewHook;
