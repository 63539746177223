import { Col, Row, Select } from "antd";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { formatString } from "src/lib/utils";
import { USER_ROLE } from "../constants/constants";
import Style from "./usersAndPermissions.style";
import AppContext from 'src/context/appContext';
import ConditionalRender from "src/components/conditionalRender";
import { PageLayoutContext } from "src/context/pageLayoutContext";

export default function UserListItem({ item, name, roleChange, removeUser, actionLoading }) {
  const appContext = useContext(AppContext.Context)
  const { inDesktop } = useContext(PageLayoutContext);
  const [email] = useState(appContext?.metaData?.email ?? '');

  return (
    <div className={Style.listItem}>
      <Row style={{ width: inDesktop ? "36%" : '100%', minWidth: inDesktop ? "36%" : '100%' }} className={Style.listItemInner}>
        <ConditionalRender shouldRender={!inDesktop}>
          <Col span={5}>
            <p className="list-item-label">{"Email"}</p>
          </Col>
        </ConditionalRender>
        <Col xs={19} md={24}>
         <p className="list-item-value">{item?.email}</p>
        </Col>
      </Row>
      <Row style={{ width: inDesktop ? "30%" : '100%' }} className={Style.listItemInner}>
        <ConditionalRender shouldRender={!inDesktop}>
          <Col span={5}>
          <p className="list-item-label">{"Name"}</p>
          </Col>
        </ConditionalRender>
        <Col xs={19} md={24}>
          <p className="list-item-value">{name ? name : "NA"}</p>
        </Col>
      </Row>
      <Row style={{ width: inDesktop ? "18%" : '100%' }} className={Style.listItemInner}>
        <ConditionalRender shouldRender={!inDesktop}>
          <Col span={5}>
            <p className="list-item-label">{"Role"}</p>
          </Col>
        </ConditionalRender>
        <Col xs={19} md={24}>
          <ConditionalRender shouldRender={email !== item?.email && !item?.is_app_owner}>
            <Select className="small" value={item?.role} onChange={(val) => roleChange(item, val)} disabled={actionLoading}>
              {USER_ROLE?.map((ele) => {
                return <Select.Option value={ele}>{formatString(ele)}</Select.Option>;
              })}
            </Select>
          </ConditionalRender>
          <ConditionalRender shouldRender={email === item?.email || item?.is_app_owner}>
            <p style={{margin:0}}>{item?.is_app_owner ? 'App owner' : 'Admin'}</p>
          </ConditionalRender>
        </Col>
      </Row>
      <ConditionalRender shouldRender={email !== item?.email && !item?.is_app_owner}>
      <Row style={{ width: inDesktop ? "16%" : '100%' }} className={Style.listItemInner}>
        <ConditionalRender shouldRender={!inDesktop}>
          <Col span={5}>
            <p className="list-item-label">{"Action"}</p>
          </Col>
        </ConditionalRender>
        <Col xs={19} md={24}>
          <button className="utility-btn" onClick={() => removeUser(item)}>
            Remove user
          </button>
        </Col>
      </Row>
      </ConditionalRender>
    </div>
  );
}
