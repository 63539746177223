import {
  Button,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { InputNo, InputText } from "src/components/basic/Input";
import {
  CHANNELS,
  FUNNEL_STAGES,
  PLATFORMS,
  TACTICS,
} from "../../constants/constants";
import useAudienceListingHook from "../audienceListing/useAudienceListingHook";
import "./CreateMediaPlanForm.less";
import useMediaPlanHook from "./useMediaPlanHook";

const { RangePicker } = DatePicker;

const CreateMediaPlanForm = () => {
  const history = useHistory();
  const { clientId, audienceId: selectedAudienceId } = useParams();
  const { state } = useLocation();
  const { loading, createMediaplanRecommendations } = useMediaPlanHook(
    clientId,
    history
  );
  const [form] = Form.useForm();
  const [advancedSettings, setAdvancedSettings] = useState(false);
  const selectedAudience = state?.state?.audiencelist?.find(
    (audience) => audience.id === selectedAudienceId
  );

  const { audienceListData } = useAudienceListingHook(clientId, history);
  const audienceOptions = audienceListData.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  useEffect(() => {
    form.setFieldsValue({
      platform: PLATFORMS.map((platform) => platform),
      tactic: TACTICS.map((tactic) => tactic),
      funnelStage: FUNNEL_STAGES.map((stage) => stage),
      channel: CHANNELS.map((channel) => channel),
    });
  }, [form]);

  const onFinish = (values) => {
    const transformedValues = {
      client_space_id: clientId,
      mediaplan_type: values.generateBy,
      name: values.name,
      objective: values.objective,
      budget: values.budget,
      json_data: {
        audience_ids: values.audience,
        channels: values.channel,
        platforms: values.platform,
        tactics: values.tactic,
        funnel_stages: values.funnelStage,
      },
      start_date: values.dateRange
        ? moment(values.dateRange[0]).format("YYYY-MM-DD")
        : null,
      end_date: values.dateRange
        ? moment(values.dateRange[1]).format("YYYY-MM-DD")
        : null,
    };
    createMediaplanRecommendations(transformedValues);
  };

  return (
    <div>
      <h2 style={{ marginBottom: 24 }}>Create Media Plan</h2>
      <Form
        form={form}
        layout="vertical"
        className="custom-scenario-plan-form"
        requiredMark={false}
        onFinish={onFinish}
        initialValues={{
          audience: selectedAudience ? [selectedAudience.id] : [],
          generateBy: "LLM",
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item
              name="name"
              label="Media Plan Name*"
              rules={[
                { required: true, message: "Media Plan Name is required" },
              ]}
              extra="Enter a unique name for this media plan to easily identify and compare it with others."
            >
              <InputText />
            </Form.Item>

            <Form.Item
              name="objective"
              label="Media Plan Objective*"
              rules={[{ required: true, message: "Objective is required" }]}
              extra="Define the primary goal of this media plan."
            >
              <InputText />
            </Form.Item>

            <Form.Item
              name="budget"
              label="Specify your Budget"
              extra="Set your media plan budget and see auto-calculated suggestions based on your campaign duration."
            >
              <InputNo
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } // Format to USD with commas
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Remove dollar sign and commas for parsing
                min={0}
              />
            </Form.Item>

            <Form.Item
              name="dateRange"
              label="Start & End Date"
              extra="Set the duration for this media plan to evaluate performance over a specific period."
            >
              <RangePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item
              name="audience"
              label="Audience "
              rules={[
                { required: true, message: "Please select the audience" },
              ]}
              extra="Select the target audience for this media plan."
            >
              <Select
                mode="multiple"
                placeholder="Please select the audience"
                options={
                  state?.state?.audiencelist?.map((audience) => ({
                    label: audience.name,
                    value: audience.id,
                  })) || audienceOptions
                }
              />
            </Form.Item>

            <Form.Item
              name="channel"
              label="Channel "
              rules={[{ required: true, message: "Please select the channel" }]}
              extra="Select the channels for this media plan."
            >
              <Select
                mode="multiple"
                placeholder="Please select the channel"
                maxTagCount={5}
                options={CHANNELS.map((channel) => ({
                  label: channel,
                  value: channel,
                }))}
              />
            </Form.Item>

            <Form.Item
              name="generateBy"
              label="Generate By"
              rules={[
                {
                  required: true,
                  message: "Please select the generation method",
                },
              ]}
              extra="Select the method to generate the media plan."
            >
              <Radio.Group>
                <Radio value="Manual" disabled>
                  Manual
                </Radio>
                <Radio value="LLM">AI (LLM)</Radio>
                <Radio value="Response Curve" disabled>
                  Response Curve
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row className="advanced-settings-row">
          <Col xs={24} sm={24} md={12} lg={8}>
            <Form.Item label="Advanced Settings" valuePropName="checked">
              <Switch onChange={(checked) => setAdvancedSettings(checked)} />
            </Form.Item>

            {advancedSettings && (
              <>
                <Form.Item
                  name="platform"
                  label="Platform "
                  rules={[
                    { required: true, message: "Please select the platform" },
                  ]}
                  extra="Select the platforms for this media plan."
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select the platforms"
                    options={PLATFORMS.map((platform) => ({
                      label: platform,
                      value: platform,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  name="tactic"
                  label="Tactic"
                  rules={[
                    { required: true, message: "Please select the tactic" },
                  ]}
                  extra="Select the tactic for this media plan."
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select the tactics"
                    options={TACTICS.map((tactic) => ({
                      label: tactic,
                      value: tactic,
                    }))}
                  />
                </Form.Item>

                <Form.Item
                  name="funnelStage"
                  label="Funnel Stage"
                  rules={[
                    {
                      required: true,
                      message: "Please select the funnel stage",
                    },
                  ]}
                  extra="Select the funnel stage for this media plan."
                >
                  <Select
                    mode="multiple"
                    placeholder="Please select the funnel stage"
                    options={FUNNEL_STAGES.map((stage) => ({
                      label: stage,
                      value: stage,
                    }))}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>

        <Form.Item>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "end",
              display: "flex",
            }}
          >
            <Button onClick={() => history.goBack()} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              onClick={() => form.submit()}
              type="primary"
              loading={loading}
              disabled={loading}
            >
              Generate Media Plan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateMediaPlanForm;
