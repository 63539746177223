import { ArrowUpOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import React from "react";
import ConditionalRender from "src/components/conditionalRender";
import Style from "./inputContainer.style";
import Assets from "src/assets";
import useInputContainerHook from "../hooks/useInputContainerHook";
import AiLoader from "src/components/AiLoader";

export default function InputContainer({
  getResponse,
  generatingResponse,
  setUserMessage,
  userMessage,
  data,
  setFormUploadModal,
  filePreview,
  terminateChatProcessing,
  streamingChatID,
  terminatingStream,
  sessionId,
  createSession,
}) {

  const { formRef, placeholderName } = useInputContainerHook({ data, generatingResponse });

  return (
    <div className={Style.inputContainer}>
      {/* <ConditionalRender shouldRender={!!filePreview}>
        <div className={Style.fileContainer}>
          <span className={Style.file}>
            <div>{filePreview?.name}</div>
            <div>Type: {filePreview?.file?.type}</div>
          </span>
        </div>
      </ConditionalRender> */}
      <div className={Style.inputFileUploadContainer}>
        <div style={{ flexGrow: "1" }}>
          <Form  
            initialValues={{ user_message: userMessage }} 
            ref={formRef}
            onFinish={async (val) => {
              if (val?.user_message?.trim()) {
                if (sessionId) {
                  await getResponse({ message: val?.user_message });
                } else {
                  await createSession(val?.user_message);
                }
                
              }
            }}
          >
            <Form.Item name="user_message" noStyle>
              <Input.TextArea
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (!e.shiftKey) {
                      if (e.target.value.trim()) {
                        if (sessionId) {
                          getResponse({ message: e.target.value, newSessionId: sessionId });
                        } else {
                          e.preventDefault();
                          createSession(e.target.value.trim());
                        }
                      }
                    }
                  }
                }}
                onBlur={(e)=>{
                  setUserMessage(e.target.value);
                }}
                onChange={(e)=>{
                  setUserMessage(e.target.value);
                }}
                autoSize={{ minRows: 1, maxRows: 5 }}
                disabled={generatingResponse && !!userMessage}
                placeholder={`Message ${placeholderName}`}
                className={Style.inputContainerTextArea + `${data?.allow_file_upload ? ' upload-feature' :''}`}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
      <ConditionalRender shouldRender={generatingResponse && !!userMessage && !streamingChatID}>
        <div className={`${Style.inputLoaderImage} ${!!filePreview && Style.previewFileLoader}`}>
          <AiLoader width={30} height={30}/>
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={generatingResponse && !!userMessage && streamingChatID}>
        <button className={`${Style.stopButton} ${!!filePreview && Style.previewFileLoader}`} >
          {!terminatingStream && (
            <img src={Assets.getImage("CircleCloseIcon")} alt="terminate icon" onClick={() => terminateChatProcessing({ chatID: streamingChatID })}/>
          )}
          {terminatingStream && <LoadingOutlined />}
        </button>
      </ConditionalRender>
      <ConditionalRender shouldRender={data?.allow_file_upload}>
        <div className={`${Style.fileUploadIcon} ${!!filePreview && Style.previewFileUploader}`}>
          <img onClick={() => setFormUploadModal(true)} src={Assets.getImage("FileUploadLogo")} height={24} width={24} />
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!generatingResponse}>
        <button className={Style.submitBtn} disabled={userMessage.length === 0} onClick={() => {
            formRef.current.submit()
        }}>
          <img src={Assets.getImage("CircleUploadIcon")} alt="upload icon" />
        </button>
      </ConditionalRender>
    </div>
  );
}
