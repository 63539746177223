import { css } from '@linaria/core';

const form = css`
  
`;

const advancedSettingContainer = css`
  margin-top: 2rem;
`;

export default {
  form,
  advancedSettingContainer
}