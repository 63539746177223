import { Card, Skeleton } from 'antd';
import React from 'react';
import AppUrls from 'src/constants/appUrls';
import Style from "./ShowCluster.style";
import { SecondaryButton } from 'src/components/basic';
import { numberWithCommas } from 'src/lib/utils';

const ShowClusterCards = ({ title, extra, children }) => {
  
  return (
    <Card title={title} extra={extra} bordered={false} className={Style.clusterCardContainer}>
      {children}
    </Card>
  );
};

export default ShowClusterCards;
