import { Col, Collapse, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import Style from "./backboneCapability.style";

export default function BackboneToolAdvancedSettings({ configData }) {
  return (
    <div className={Style.advancedSettingContainer}>
      <Collapse className={'drawerCollapse'}>
        <Collapse.Panel forceRender={true} key="1" header="Advanced settings">
          <Col lg={24}>
            <Form.Item
              name="tool_invocation_text"
              label="Tool invocation text*"
              rules={CustomGptService.validations(
                { required: true, max_length_string: true },
                "Tool invocation text",
                configData?.config?.tool_invocation_text
              )}
            >
              <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
            </Form.Item>
          </Col>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
