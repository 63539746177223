import { css } from "linaria";

/* App.css */
const clusterControlCotnainer = css`
  width:100%;
  gap:8px;
  margin-bottom:24px;
  @media screen and (min-width:768px) {
    width:fit-content;
  }
`;

const clusterContainer = css`
  display: flex;
  flex-direction:column-reverse !important;

  @media screen and (min-width:768px) {
    flex-direction: row !important;
  }
`;

const scrollableLeft = css`
  overflow-y: auto;
  width:fit-content;
  height: 40vh;
  flex: 0 0 auto;
  flex-basis: 25%;
  background:var(--color-charcoal-surface-1) !important;

  @media screen and (min-width:768px) {
    height: calc(100vh - 250px);
  }
`;

const scrollableRight = css`
  overflow-y: auto;
  height: 40vh;
  display:flex !important;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  flex-basis: 75%;

  @media screen and (min-width:768px) {
    height: calc(100vh - 250px);
  }
`;

const verticalDivider = css`
  width: 1px;
  background-color: #ccc;
  height: 100%;
`;

const content = css`
  height: 100%;
  padding: 20px;
  .ant-card-bordered {
    border: solid 1px lightgray;
    border-radius: 8px;
    box-shadow: 5px 5px 5px lightgray;
    margin-bottom: 16px;
  }
`;

const breadCrumb = css`
  margin-bottom:16px !important;
`;

const clusterCardContainer = css`
  border-radius:16px;
  background:var(--color-charcoal-surface-1) !important;
  color:var(--color-white) !important;
  &.ant-card {
    height: 100%;
  }
  .ant-card-head-wrapper {
  align-items: normal;
  }

  .ant-card-body {
    padding-top:8px;
    border-bottom: 1px solid var(--color-60-black);
  }

  &:last-child {
    .ant-card-body {
      border-bottom: none;
    }
  }
  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-title {
    color:var(--color-white) !important;
    padding-bottom:0px;
    font-weight: 700;
  }
`;

const clusterGraph = css`
  width:100%;
  height: inherit;
  min-height:500px;
  max-height:600px;
  display:flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
`;

const clusterBasicDetailsContainer = css`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const headerContainer = css`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export default {
  clusterControlCotnainer,
  clusterContainer,
  scrollableLeft,
  scrollableRight,
  verticalDivider,
  content,
  breadCrumb,
  clusterCardContainer,
  clusterGraph,
  clusterBasicDetailsContainer,
  headerContainer
};
