import { InfoCircleOutlined, ProfileOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Card, Input, Radio, Steps, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import { CLUSTER_BASED, PROPENSITY_TIERS, RULE_BASED } from '../../constants/constants';
import AudiencePreviewComponent from '../audienceListing/AudiencePreviewComponent';
import BehaviourProbabilityComponent from './BehaviourProbabilityComponent';
import ClusterTabLayout from './ClusterTabLayout';
import './createAudience.style.less';
import DataSourceTabLayout from './DataSourceTabLayout';
import RecommendedAudiencesComponent from './RecommendedAudiencesComponent';
import useCreateAudienceHook from './useCreateAudienceHook';

const { Title } = Typography;
const { Step } = Steps;

function CreateAudience({ history, match }) {
    const [audienceAction, setAudienceAction] = useState('target');
    const [advancedSettings, setAdvancedSettings] = useState(false);
    const [showAudienceDetails, setShowAudienceDetails] = useState(false);
    const [showReccomentation, setShowRecommendation] = useState(false);

    const id = match.params.clientId;
    const audienceId = match.params.audienceId;
    // Determine if the operation is "create" or "edit"
    const isEdit = !!audienceId;
    const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);

    const {
        personaLoading,
        createPersona,
        selectedAudienceData,
        setSelectedAudienceData,
        createAudiences,
        createAudienceLoading,
        audienceSize,
        baseSize,
        audienceSizeLoading,
        getAudienceSize,
        audienceName,
        audienceDescription,
        setAudienceName,
        setAudienceDescription,
        selectedAudienceType,
        setSelectedAudienceType,
        audienceLoading,
        behaviourProbabilityData,
        setBehaviourSegmentCount,
        errors,
        setErrors,
        behaviourDirectory
    } = useCreateAudienceHook(id, history, audienceId, isEdit, setCurrentStep, currentStep);

    const handleNext = () => setCurrentStep(currentStep + 1);
    const handleBack = () => setCurrentStep(currentStep - 1);
    const handleSubmit = (profiling) => createAudiences(audienceName, audienceDescription, selectedAudienceData, profiling);
    const handleCreatePersona = () => createPersona(audienceName, audienceDescription, selectedAudienceData);
    const handleShowDetails = () => setShowAudienceDetails(true);
    const handleDetailsClose = () => setShowAudienceDetails(false);

    useEffect(() => {
        if (selectedAudienceType.length > 0) {
            setSelectedAudienceData({
                ...selectedAudienceData,
                audienceType: selectedAudienceType,
                rules: selectedAudienceType === RULE_BASED ? selectedAudienceData.rules : {},
                selectedClusters: selectedAudienceType === CLUSTER_BASED ? selectedAudienceData.selectedClusters : {}
            });
        }
    }, [selectedAudienceType]);

    if (audienceLoading) {
        return <LoadingSpinner centered />;
    }

    return (
        <div className="createAudienceContainer">
            <NcBreadCrumb className='breadCrumb' />
            <Title level={2} className="title"> {isEdit ? 'Edit Audience' : 'Create Audience'} </Title>
            <Steps current={currentStep} direction="horizontal" className="steps">
                <Step title={<span className={currentStep >= 0 ? 'step-title-active' : 'step-title'}>Select Audience Type</span>} icon={<ToolOutlined className={currentStep >= 0 ? 'step-icon-active' : 'step-icon'} />} />
                <Step title={<span className={currentStep >= 1 ? 'step-title-active' : 'step-title'}>Audience Details</span>} icon={<InfoCircleOutlined className={currentStep >= 1 ? 'step-icon-active' : 'step-icon'} />} />
                <Step title={<span className={currentStep >= 2 ? 'step-title-active' : 'step-title'}>{selectedAudienceType === RULE_BASED ? 'Define Rules' : 'Select Clusters'}</span>} icon={<ProfileOutlined className={currentStep >= 2 ? 'step-icon-active' : 'step-icon'} />} />
                {selectedAudienceType === RULE_BASED && (
                    <Step title={<span className={currentStep >= 3 ? 'step-title-active' : 'step-title'}>Adjust Probability & Size</span>} icon={<ProfileOutlined className={currentStep >= 3 ? 'step-icon-active' : 'step-icon'} />} />
                )}
            </Steps>
            <div style={{
                width: '100%',
                minHeight: '650px',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
                <div>
                    {currentStep === 0 && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '40px',
                        }}>
                            {!showReccomentation && (
                                <>
                                    <h2 style={{ color: '#fff', textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                                        How would you like to create the audience?
                                    </h2>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                                        <Card
                                            hoverable
                                            className={`card ${selectedAudienceType === RULE_BASED ? 'card-primary' : 'card-gray'} card-hover`}
                                            onClick={() => setSelectedAudienceType(RULE_BASED)}
                                        >
                                            <Radio
                                                checked={selectedAudienceType === RULE_BASED}
                                                onChange={() => setSelectedAudienceType(RULE_BASED)}
                                                style={{ position: 'absolute', top: '10px', left: '10px' }}
                                            />
                                            <Card.Meta
                                                title={<span className="card-meta-title">Rule Based</span>}
                                                description={<span className="card-meta-description card-meta-description-digital">Create an audience based on rules.</span>}
                                            />
                                        </Card>
                                        <Card
                                            hoverable
                                            className={`card ${selectedAudienceType === CLUSTER_BASED ? 'card-primary' : 'card-gray'} card-hover`}
                                            onClick={() => setSelectedAudienceType(CLUSTER_BASED)}
                                        >
                                            <Radio
                                                checked={selectedAudienceType === CLUSTER_BASED}
                                                onChange={() => setSelectedAudienceType(CLUSTER_BASED)}
                                                style={{ position: 'absolute', top: '10px', left: '10px' }}
                                            />
                                            <Card.Meta
                                                title={<span className="card-meta-title">Cluster</span>}
                                                description={<span className="card-meta-description card-meta-description-digital">Create an audience based on clusters.</span>}
                                            />
                                        </Card>
                                        <Card
                                            className="card card-disabled"
                                        >
                                            <Radio
                                                checked={selectedAudienceType === PROPENSITY_TIERS}
                                                onChange={() => setSelectedAudienceType(PROPENSITY_TIERS)}
                                                style={{ position: 'absolute', top: '10px', left: '10px' }}
                                                disabled
                                            />
                                            <Card.Meta
                                                title={<span className="card-meta-title">Propensity Tiers</span>}
                                                description={<span className="card-meta-description card-meta-description-digital">Coming Soon</span>}
                                            />
                                        </Card>
                                    </div>
                                    <div style={{ marginTop: '80px', textAlign: 'center' }}>
                                        <p style={{ color: '#fff', fontStyle: 'italic' }}>Need Help?</p>
                                        <Button type="primary" onClick={() => setShowRecommendation(true)}>
                                            Show Recommendations
                                        </Button>
                                    </div>
                                </>
                            )}
                            {showReccomentation && (
                                <RecommendedAudiencesComponent
                                    match={match}
                                    setShowRecommendation={setShowRecommendation}
                                    setAudienceName={setAudienceName}
                                    setAudienceDescription={setAudienceDescription}
                                    setCurrentStep={setCurrentStep}
                                    setSelectedAudienceType={setSelectedAudienceType}
                                />
                            )}
                        </div>
                    )}
                    {currentStep === 1 && (
                        <>
                            <div className="formItem">
                                <Input
                                    placeholder="Enter Name of the Audience"
                                    className="input"
                                    value={audienceName}
                                    onChange={(e) => setAudienceName(e.target.value)}
                                />
                            </div>
                            <div className="formItem">
                                <TextArea
                                    placeholder="Describe the Audience"
                                    className="input"
                                    value={audienceDescription}
                                    rows={4}
                                    onChange={(e) => setAudienceDescription(e.target.value)}
                                />
                            </div>
                            <div className="formItem">
                                {advancedSettings && (
                                    <>
                                        <h3 className="advanced-settings-title" style={{ marginTop: "10px" }}>Targeting Option:</h3>
                                        <Radio.Group
                                            defaultValue="target"
                                            className="radio-group"
                                            value={audienceAction}
                                            onChange={(e) => setAudienceAction(e.target.value)}
                                        >
                                            <Radio value="target">Inclusion</Radio>
                                            <Radio value="exclude">Exclusion</Radio>
                                            <Radio value="create-lookalike">Lookalike</Radio>
                                        </Radio.Group>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {currentStep === 2 && (
                        <div className="formItem">
                            {selectedAudienceType === CLUSTER_BASED && (
                                <ClusterTabLayout
                                    clientId={id}
                                    audienceName={audienceName}
                                    audienceDescription={audienceDescription}
                                    selectedAudienceData={selectedAudienceData}
                                    setSelectedAudienceData={setSelectedAudienceData}
                                />
                            )}
                            {selectedAudienceType === RULE_BASED && (
                                <DataSourceTabLayout
                                    clientId={id}
                                    audienceName={audienceName}
                                    audienceDescription={audienceDescription}
                                    selectedAudienceData={selectedAudienceData}
                                    setSelectedAudienceData={setSelectedAudienceData}
                                    audienceSize={audienceSize}
                                    baseSize={baseSize}
                                    audienceSizeLoading={audienceSizeLoading}
                                    getAudienceSize={getAudienceSize}
                                    errors={errors}
                                    setErrors={setErrors}
                                    behaviourDirectory={behaviourDirectory}
                                />
                            )}
                        </div>
                    )}
                    {
                        currentStep === 3 && (
                            <div className="formItem">
                                <BehaviourProbabilityComponent
                                    behaviourProbabilityData={behaviourProbabilityData}
                                    setBehaviourSegmentCount={setBehaviourSegmentCount}
                                />
                            </div>
                        )
                    }
                </div>
                <div className="button-container">
                    {currentStep > 0 && (
                        <Button type="default" onClick={handleBack} style={{ marginRight: '10px' }} size="small" loading={createAudienceLoading} disabled={createAudienceLoading}>
                            Back
                        </Button>
                    )}
                    {currentStep < 2 ? (
                        <Button type="primary" onClick={handleNext} size="small">
                            Next
                        </Button>
                    ) : (
                        <>
                            <Button type="primary" onClick={() => handleSubmit(false)} size="small" loading={createAudienceLoading} disabled={createAudienceLoading}>
                                {selectedAudienceType === CLUSTER_BASED ? 'Save' : currentStep === 2 ?
                                    'Save and Continue' : 'Save as Draft'}
                            </Button>
                            {currentStep === 3 &&
                                <Button type="default" onClick={() => handleSubmit(true)} size="small" loading={createAudienceLoading} disabled={createAudienceLoading} style={{ marginLeft: '10px' }}>
                                    Save And Profile
                                </Button>
                            }
                        </>
                    )}
                </div>
            </div>
            {
                showAudienceDetails && (
                    <AudiencePreviewComponent
                        open={showAudienceDetails}
                        onClose={handleDetailsClose}
                        audienceData={selectedAudienceData}
                        audienceName={audienceName}
                        audienceDescription={audienceDescription}
                        handleCreatePersona={handleCreatePersona}
                        personaLoading={personaLoading}
                    />
                )
            }
        </div >
    );
}

export default CreateAudience;