import { css } from "@linaria/core";

const mainLayoutContainer = css`
  display: flex;
  flex-direction: column;
`;

const listingContainer = css`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 100px 100px 20px;
`;

const tableParent = css`
  width: 100%;
  margin-top: 16px;
`;

const analysisParent = css`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

const newsAndCultureContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-full-black);
  padding: 16px;
  @media screen and (min-width: 768px) {
    padding: 16px 52px;
  }
`;

const breadCrumb = css`
  &.ant-breadcrumb {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const breadCrumbCreateScreen = css`
  &.ant-breadcrumb {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: transparent;
    a {
      background-color: transparent !important;
    }
  }
`;

const pageLoader = css`
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
`;

const tabContent = css`
  display: flex;
  padding: 0;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`;

const summaryCard = css`
  &.ant-card {
    width: 100%;
    overflow-y: auto;
    box-shadow: none;
    border: none;
    padding: 20px;
    background-color: var(--color-charcoal-surface-2);
    color: var(--color-white);
    border-radius: 10px !important;
    max-width: 1440px;
    height: calc(100vh - 274px);
    width: 100%;
    margin: 0 auto;

    .ant-anchor-ink-ball {
      background: var(--color-primary);
      border: none;
    }

    .ant-anchor-link-title {
      color: var(--color-white);
      &:hover {
        color: var(--color-primary);
      }
    }
    .ant-anchor-link-title-active {
      color: var(--color-primary);
    }
  }
`;

const summaryText = css`
  font-size: 14px;
  margin-bottom: 10px;
`;

const summaryTitle = css`
  font-size: 16px;
  font-weight: bold;
`;

const summaryAnchor = css`
  position: sticky;
  top: 20px;
`;

const summaryPoint = css`
  margin-left: 20px;
  font-style: italic;
`;

const iconButton = css`
  &.ant-btn.ant-btn-default {
    border: 0 none;
    color: var(--color-white);
    padding: 16px !important;
    background: transparent;
    .anticon {
      color: var(--color-white);
    }

    &:hover {
      .anticon {
        color: var(--color-primary);
      }
    }
  }
`;

const customOverviewTabs = css`
  &.ant-tabs {
    width: 100%;
    .ant-tabs-content-holder {
      width: 100%;
    }
  }
`;

const wordCloud = css`
  width: 100%;
  height: 300px;
  margin-bottom: 20px;
`;

const rawDataCard = css`
  &.ant-card {
    width: 100%;
    overflow-y: auto;
    box-shadow: none;
    border: none;
    padding: 20px;
    background-color: var(--color-charcoal-surface-2);
    border-radius: 10px !important;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
  }
`;

const rawDataCardIfDrawer = css`
  &.ant-card {
    padding: 0px !important;
    .ant-card-body {
      padding: 5px !important;
    }
  }
`;

// Create Listing form styles
const formContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-charcoal-surface-2);
  overflow-y: auto;
  padding: 16px;
  max-width: 1440px;
  width: 100%;
  border-radius: 10px;
  margin: 0 auto;
  @media screen and (min-width: 768px) {
    padding: 16px 48px;
  }
`;

const formCard = css`
  &.ant-card {
    width: 100%;
    background-color: transparent;
    padding: 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: none;
    border: none;

    .ant-card-body {
      padding: 0;
      color: var(--color-white);
    }
    .ant-form {
      color: var(--color-white);
    }
  }
`;

const querySection = css`
  border: 1px solid #d9d9d9;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const querySectionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const removeQueryIcon = css`
  cursor: pointer;
  color: lightCoral !important;
  margin-left: 10px;
`;

const removeSectionIcon = css`
  cursor: pointer;
  color: lightCoral !important;
`;

const addbtnParent = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
`;

const addButton = css`
  &.ant-btn.ant-btn-default {
    border-radius: 5px;
    border: 0 none;
    padding: 0;
    height: 24px;
  }
`;

const backButton = css`
  &.ant-btn.ant-btn-default {
    border: 0 none;
    padding: 0;
    margin-bottom: 10px;
    // margin-left: 24px;
  }
`;

const staticText = css`
  // margin-left: 24px;
`;

const sectionContainer = css`
  border: none
  padding: 16px 0px;
  margin-bottom: 32px;
  border-radius: 4px;
`;

const sectionSubParent = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const sectionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 8px;
  .ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    .ant-row {
      flex-direction: row;
      align-items: baseline;
      width: 100%;
    }
    .ant-space-vertical {
      width: 100%;
    }
  }
`;

const noQueryParent = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  margin-top: 16px;
  background-color: transparent;
  border: 1px solid #525252;
  p {
    margin-bottom: 0px;
  }
`;

const viewOnly = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px;
  background-color: transparent;
  border: 1px solid #525252;
  p {
    margin-bottom: 0px;
  }
`;

const pageLoaderContainer = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 9999;
`;

const sectionActions = css`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const queryContainerSubParent = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const queryHeaderSubParent = css`
  font-weight: 500;
  width: 100%;
  font-size: 16px;
`;

const queryDivider = css`
  &.ant-divider-horizontal.ant-divider-with-text {
    padding-left: 32px;
    font-size: 14px;
    font-weight: 400;
    border-top-color: var(--color-white);
    color: var(--color-white);
  }
`;

const queryContainer = css`
  border: none;
  padding: 0;
  margin-bottom: 16px;
  border-radius: 4px;
  width: 100%;
`;

const queryHeader = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

const addSectionButton = css`
  &.ant-btn.ant-btn-default {
    border: 0 none;
    background-color: #f9f9f9;
  }
`;

const generateBtn = css`
  &.ant-btn {
    border-radius: 5px;
  }
`;

const filterContainerParent = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const filterContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
  @media screen and (max-width: 568px) {
    flex-wrap: wrap;
    > * {
      width: 100% !important;
    }
  }
`;

const searchInputGroup = css`
  .ant-input-group > .ant-input:first-child {
    border: none !important;
  }
`;

const cardListingContainer = css`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    padding: 3rem 54px;
  }
`;

const contentParent = css`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const emptyContainer = css`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const content = css`
  width: 100%;
`;

const cardRow = css`
  &.ant-row {
    display: flex;
    flex-wrap: wrap;
  }
`;

const cardCol = css`
  flex: 1 0 21%;
  max-width: 21%;
  min-width: 200px;
  display: flex;
`;

const analysisCard = css`
  &.ant-card {
    background-color: var(--color-charcoal-surface-2);
    color: var(--color-white);
    height: 100%;
    overflow-y: auto;
    padding: 16px !important;
    border-radius: 10px !important;
    border: none;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    .ant-card-head {
      color: var(--color-white);
      padding: 0;
    }
    .ant-card-body {
      padding: 16px 0;
    }
    .ant-card-extra {
      color: var(--color-white);
    }
  }
`;

const analysisCardView = css`
  &.ant-card {
    background-color: var(--color-charcoal-surface-2);
    border: none;
    box-shadow: none;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px !important;
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding: 0;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;
  }
`;

const analysisFilters = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
`;

const colorByOption = css`
  &.ant-select-item-option {
    padding: 10px 7px;
    font-size: 1rem;
  }
`;

const analysisArticleCard = css`
  background-color: var(--color-charcoal-surface-2);
  color: var(--color-white);
  height: 100%;
  overflow-y: auto;
  padding: 16px !important;
  margin-top: 10px;

  a {
    color: var(--color-primary);
    transition: all 0.2s;
    opacity: 0.8;

    &:hover {
      color: var(--color-primary);
      opacity: 1;
    }
  }
`;

const carouselContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const carouselButton = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: #fff;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  :first-child {
    left: -40px;
  }
  :last-child {
    right: -40px;
  }
`;

const scheduleContainer = css`
  font-size: 15px;
  display: flex;
  flex-direction: column;
  padding: 24px 12px 24px 24px;
  border: solid 1px #d9d9d9;
`;

const scheduleQs = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: center;
  p {
    margin-bottom: 0;
  }
  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

const scheduleOptionsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  @media screen and (min-width: 768px) {
    width: 60%;
  }
`;

const scheduleFormRow = css`
  width: 50%;
`;

const addSectionContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;
  gap: 8px;
  margin: 40px 0 20px 0;
  button {
    width: 100% !important;
  }
  @media screen and (min-width: 860px) {
    button {
      width: auto !important;
    }
  }
`;

const addSectionButtons = css`
  width: 100%;
  @media screen and (min-width: 860px) {
    width: 60%;
  }
`;

const addSectionButtonRow = css`
  gap: 8px;
  justify-content: flex-end;
  flex-wrap: wrap !important;
  width: 100%;
  button {
    width: 100%;
  }

  @media screen and (min-width: 800px) {
    width: 100%;
    flex-wrap: nowrap !important;
  }
`;

const articleWordCloud = css`
  // width: 100%;
`;

const rangePicker = css`
  // &.ant-picker {
  //   height: 32px;
  // }
`;

const paginationContainer = css`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const summaryContent = css`
  margin-left: 20px;
  overflow-y: auto;
  flex: 1;
  padding-left: 20px;
  border-left: solid 1px #d9d9d9;
`;

const dateRangePicker = css`
  width: 100%;
`;

const articleTable = css`
  .ant-table-column-title {
    white-space: nowrap;
  }
  .ant-table-filter-dropdown {
    max-width: 300px !important;
    width: 100% !important;
  }
  .ant-table {
    max-width: 1440px;
    width: 100%;
    display: block;
    margin: 0 auto;
    background: transparent !important;
    color: var(--color-white);

    .ant-table-thead .ant-table-cell {
      background: transparent !important;
      color: var(--color-white);
    }
    .ant-table-row {
      box-shadow: none;
      background: transparent !important;
      .ant-table-cell-row-hover {
        background: transparent !important;
      }
      &:hover {
        .ant-table-cell {
          background: transparent !important;
        }
      }
    }
  }
  .ant-table-pagination {
    .ant-pagination-item-link {
      border: none;
    }
    .ant-pagination-item {
      border: none;
    }
  }
`;

const articleTableDrawer = css`
  .ant-table-column-title {
    white-space: nowrap;
  }
  .ant-table-filter-dropdown {
    max-width: 300px !important;
    width: 100% !important;
  }
  .ant-table {
    width: 100%;
    display: block;
    background: transparent !important;
    color: var(--color-white);

    .ant-table-thead .ant-table-cell {
      background: transparent !important;
      color: var(--color-white);
    }
    .ant-table-row {
      box-shadow: none;
      background: transparent !important;
      .ant-table-cell-row-hover {
        background: transparent !important;
      }
      &:hover {
        .ant-table-cell {
          background: transparent !important;
        }
      }
    }
  }
  .ant-table-pagination {
    .ant-pagination-item-link {
      border: none;
    }
    .ant-pagination-item {
      border: none;
    }
  }
`;

const buttonLayout = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
`;


const knowledgeGraphFiltersBoxDynamic = css`
  width: 100%;
  overflow: hidden;
  z-index: 3;
  // margin-left: -6px;
  border-radius: 10px;
`;

const knowledgeGraphFiltersBoxDynamicExpanded = css`
  width: 100%;
  // overflow: hidden;
  z-index: 3;
  // margin-left: -6px;
  border-radius: 10px;
`;


const knowledgeGraphFiltersWrapper = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  min-width: 309%;
  z-index: 3;
  background-color: var(--color-charcoal-surface-1);
  border-radius: 10px;
  .ant-col {
    padding: 5px 10px !important;
    &.col-wrapper {
      padding: 5px 0px !important;
      width: 49%;
    }
  }
`;

const knowledgeGraphFilters = css`
  .ant-select-selection-placeholder {
    font-size: 0.9rem !important;
  }
  .ant-select.ant-select.ant-select {
    padding: 0 !important;
    &:hover {
      padding: 0 !important;
    }
    .ant-select-selector.ant-select-selector.ant-select-selector {
      padding-top: 7px !important;
      padding-bottom: 7px !important;
      &:hover {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
      }
      .ant-select-selection-overflow {
        padding: 0;
        .ant-select-selection-item-content {
          font-size: 0.8rem;
        }
      }
    }
  }
  .ant-input.ant-input,
  .ant-picker-input.ant-picker-input.ant-picker-input {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    font-size: 1.1rem !important;
  }
  .ant-picker.ant-picker-range .ant-picker-input input {
    font-size: 0.8rem !important;
    text-align: center;
  }
`;

const jobTriggerTimeline = css`
  margin-top: 4rem;
  max-height: 400px;
  overflow-y: auto;
  text-align: center;
`;

const jobTriggerItem = css`
  font-size: 15px;
  font-weight: 500;
  color: gray;
`;

const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const summarizeButton = css`
  display: flex;
  gap: 8px;
  align-items: center;
  color: white;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  height: 58px;
  padding-bottom: 13px;
  .ant-btn-primary {
    padding: 10px !important;
    font-size: 1rem;
  }
  .ant-select {
    .ant-select-selector.ant-select-selector {
      padding: 4px 8px !important;
      &:hover {
        padding: 4px 8px !important;
      }
    }
  }
`;

const infoIcon = css`
  color: var(--color-white) !important;
  padding-left: 20px;
`;

const markDownColour = css`
  color: var(--color-white);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-white);
  }
`;

const tagsContainer = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
`;

const customTagsContainer = css`
  margin-top: 20px;
  margin-bottom: 20px;
  h3 {
    color: var(--color-white);
    margin-bottom: 16px;
  }
`;

const customTagCard = css`
  &.ant-card {
    background-color: var(--color-charcoal-surface-2);
    color: var(--color-white);
    // border: none;
    box-shadow: none;
    margin-bottom: 16px;
    .ant-card-body {
      padding: 16px;
    }
  }
`;

const categoryCard = css`
  &.ant-card {
    background-color: var(--color-charcoal-surface-2);
    color: var(--color-white);
    // border: none;
    box-shadow: none;
    margin-bottom: 16px;
    .ant-card-body {
      padding: 16px;
    }
  }
`;

const articleTableDrawerContainer = css`
  z-index: 1000 !important;
`;

const LuceneQueryContainer = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
  align-items: center;
`;

const LuceneQueryLabelStyle = css`
  // padding-left: 10%;
  width: 30%;
`;

const LuceneQueryQueryBoxStyle = css`
  border: 1px solid var(--color-60-black) !important;
  padding: 10px;
  width: 50%;
  min-height: 40px;
`;
const maxCountBoxStyle = css`
  border: 1px solid var(--color-60-black) !important;
  padding: 10px;
  width: 10%;
  height: 40px;
`;

const inputNumber = css`
  .ant-input-number-group-addon {
    border: none;
    padding-left: 0;
    background-color: transparent;
  }
`;

const inputNumberRange = css`
  &.ant-input-number {
    padding: 2px 6px !important;
    font-size: 10px;
    border: 1px solid var(--color-charcoal-surface-1);
    background-color: #2f2f2f !important;
    &:hover {
      background-color: #2f2f2f !important;
      border: 1px solid var(--color-charcoal-surface-1);
    }
  }
`;

const slider = css`
  // width: 20rem;
  &.ant-slider.ant-slider-horizontal {
    // margin-left: 0;
    // margin-right: 0;
  }
`;

const sliderCommunities = css`
  padding-top: 10px;
  &.ant-slider {
    width: 91%;
  }
`;

const createAnalysisQueryContainer = css`
  z-index: 9999999 !important;
  padding-bottom: 24px;
  overflow-y: auto;
`;

const queryFooterBtns = css`
  position: fixed;
  bottom: 40px;
  right: 0px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-right: 50px;
  background-color: #262626;
  width: calc(90% - 50px);
  padding-top: 28px;
`;

const queryBuilder = css`
  .queryBuilder {
    background-color: var(--color-charcoal-surface-1);
    padding: 16px;
    border-radius: 5px;
    width: 100%;
    overflow: auto;
    .ruleGroup {
      .ruleGroup-header {
        .ruleGroup-combinators,
        .ruleGroup-addRule,
        .ruleGroup-addGroup,
        .ruleGroup-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          font-size: 1.143rem;
          line-height: 120%;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          padding: 0px 48px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          text-transform: uppercase;
          transition: all 0.3s;
          height: 40px !important;
        }
        .ruleGroup-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          width: 56px !important;
          height: 40px !important;
          padding-bottom: 10px !important;
          font-size: 32px;
          line-height: 32px;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
      }
    }
    .ruleGroup-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .rule {
        width: 100%;

        display: flex;
        gap: 1rem;
        .rule-fields,
        .rule-operators,
        .ant-select,
        .ant-select-selector {
          background-color: var(--color-charcoal-surface-1) !important;
          align-items: center;
          padding: 10px;
        }
        .rule-remove {
          background-color: var(--color-charcoal-surface-1) !important;
          font-size: 32px;
          line-height: 32px;
          width: 56px !important;
          height: 40px !important;
          padding-bottom: 10px !important;
          font-weight: 600 !important;
          border-radius: 0;
          border: 1px solid var(--color-white);
          color: var(--color-white);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
        .rule-value {
          background-color: var(--color-charcoal-surface-1) !important;
          color: white;
          display: flex;
          gap: 1rem;
          width: 100%;
          min-width: 120px;
          border: 1px solid #6f6f6f;
          padding: 10px;
        }
      }
      .ruleGroup {
        width: 100% !important;
        border: 1px solid gray;
        padding: 16px;
        border-radius: 5px;
      }
    }
  }
`;

const articleNumContainer = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  font-size: 14px;
  color: white;
`;

const articleNumLabel = css`
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding-right: 10px;
`;

const articleNumInput = css`
  height: 40px;
  width: 180px !important;
  fontsize: 14px;
  color: white;
`;

const filterDropdownStyle = css`
  background-color: red !important;
  color: red !important;
  width: 300px !important;
  padding: 10px !important;
  background-color: #f9f9f9 !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2) !important;
`;

const searchBoxStyle = css`
  margin: 10px !important;
  width: 180px !important;
  padding: 8px 12px !important;
  color: var(--color-white);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
`;

const checkBoxStyle = css`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 200px;
  max-height: 300px;
  font-size: 14px;
  line-height: 22px;
  .ant-checkbox-wrapper {
    color: var(--color-white);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    padding: 5px 12px;
    margin: 0 !important;
  }
`;

const bottomButtonsStyle = css`
  display: flex;
  justify-content: space-between;
  padding: 7px 7px;
`;

const tooltipDegreeOfSeparation = css`
  &.disabled {
    opacity: 0.6;
  }
  .ant-tooltip-content {
    background-color: var(--color-charcoal-surface-2);
  }
  .ant-slider {
    width: 91%;
  }
`;

const loadingContainerAnalyzeTrends = css`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 20%;
  height: 20px;
`;

const loadingTextAnalyzeTreands = css`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

const dataSnapshotMessage = css`
  font-style: italic;
  font-weight: 500;
  padding: 10px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  width: 100%;
  text-align: center;
  font-size: 11px;
  border: 1px solid #626262;
  background: #000000;
  cursor: default;
`;

const applyButton = css`
  &.ant-btn {
    margin-top: 5px !important;
    // border-radius: 5px;
  }
  // &.ant-btn-primary {
  //   padding: 10px !important;
  //   font-size: 0.9rem;
  // }
`;

const savedFilterOptions = css`
  display: flex;
  flex-direction: row;
`;

const filterContent = css`
  display: flex;
  flex-direction: column;
  padding-left: 10px
`;

const filterName = css`
  text-overflow: ellipsis;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
`;

const filterDate = css`
  font-size: 10px
`;

const userIcon = css`
  padding-left: 10px;
  align-self: center;
`;

const savedFilterPopup = css`
  .ant-select-item.ant-select-item-option{
    padding-left: 0;
    padding-right: 0;
  }
`;

const savedFilterSelect = css`
  .ant-select-selection-item {
    text-overflow: ellipsis;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
  }
`;

const customDropdownHeader = css`
  display: flex;
  padding: 3px 0;
  background: inherit;
  font-size: 13px;
  align-items: center;
  width: 100%;
  background-color: var(--color-charcoal-surface-3);
  justify-content: center;
`;


const savedFilterPopover = css`
// white-space: nowrap;
// text-overflow: none;
// overflow-x: scroll;
.ant-popover-inner-content.ant-popover-inner-content {
    max-width: 300px !important;
    max-height: 200px !important;
    overflow-y: auto !important;
    // background-color: var(--color-charcoal-surface-3);
    // color: var(--color-white) !important;
  }
`;


const tagWrapper = css`
  font-size: 12px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: fit-content;
  max-height: 200px;
  max-width: 268px;
  overflow-y: auto;
`;


const tagText = css`
  color: black;
  background-color: white;
  border-radius: 3px;
  margin: 5px;
  border: solid 0.5px #c6c6c685;
  padding: 3px;
  padding-top: 4px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
`;


const tagPopover = css`
  .ant-popover-inner-content {
    padding: 5px;
    box-shadow: 0px 0px 1px 0px #ffffff8a;
    // display: flex;
    // align-content: flex-end;
    // justify-content: start;
    // align-items: flex-end;
  }
  .ant-popover-arrow > .ant-popover-arrow-content {
    box-shadow: 0px 0px 1px 0px #ffffff8a;
  }
`;

const chatWithMeButton = css`
  &.ant-btn-primary {
    padding: 10px !important;
    font-size: 0.9rem;
    position: absolute;
    top: 147px;
    right: 52px;
  }
`;

const inputNumberRangeWrapper = css`
  display: flex;
  gap: 8px;
  padding: 10.5px;
  border: 1px solid var(--color-60-black) !important;
`;

export default {
  mainLayoutContainer,
  listingContainer,
  tableParent,
  analysisParent,
  newsAndCultureContainer,
  infoIcon,
  breadCrumb,
  formContainer,
  formCard,
  querySection,
  querySectionHeader,
  removeQueryIcon,
  removeSectionIcon,
  cardListingContainer,
  cardRow,
  cardCol,
  analysisCard,
  analysisCardView,
  analysisFilters,
  rangePicker,
  filterContainer,
  searchInputGroup,
  filterContainerParent,
  breadCrumbCreateScreen,
  addbtnParent,
  addButton,
  backButton,
  staticText,
  generateBtn,
  content,
  paginationContainer,
  contentParent,
  tabContent,
  summaryCard,
  summaryText,
  summaryTitle,
  iconButton,
  customOverviewTabs,
  wordCloud,
  addSectionButton,
  sectionContainer,
  sectionSubParent,
  sectionHeader,
  queryContainerSubParent,
  queryHeaderSubParent,
  queryDivider,
  queryContainer,
  queryHeader,
  sectionActions,
  emptyContainer,
  pageLoader,
  overlay,
  analysisArticleCard,
  articleWordCloud,
  pageLoaderContainer,
  summaryAnchor,
  summaryPoint,
  carouselButton,
  carouselContainer,
  summaryContent,
  scheduleContainer,
  scheduleQs,
  scheduleOptionsContainer,
  scheduleFormRow,
  addSectionContainer,
  addSectionButtons,
  addSectionButtonRow,
  dateRangePicker,
  articleTable,
  rawDataCard,
  buttonLayout,
  articleTableDrawer,
  noQueryParent,
  viewOnly,
  knowledgeGraphFilters,
  jobTriggerItem,
  jobTriggerTimeline,
  header,
  summarizeButton,
  markDownColour,
  tagsContainer,
  customTagsContainer,
  customTagCard,
  categoryCard,
  articleTableDrawerContainer,
  LuceneQueryContainer,
  LuceneQueryLabelStyle,
  LuceneQueryQueryBoxStyle,
  inputNumber,
  slider,
  createAnalysisQueryContainer,
  queryFooterBtns,
  queryBuilder,
  articleNumContainer,
  articleNumLabel,
  articleNumInput,
  maxCountBoxStyle,
  filterDropdownStyle,
  searchBoxStyle,
  checkBoxStyle,
  bottomButtonsStyle,
  colorByOption,
  tooltipDegreeOfSeparation,
  sliderCommunities,
  loadingContainerAnalyzeTrends,
  loadingTextAnalyzeTreands,
  rawDataCardIfDrawer,
  dataSnapshotMessage,
  applyButton,
  savedFilterOptions,
  filterContent,
  filterName,
  filterDate,
  userIcon,
  savedFilterPopup,
  savedFilterSelect,
  customDropdownHeader,
  savedFilterPopover,
  knowledgeGraphFiltersWrapper,
  knowledgeGraphFiltersBoxDynamic,
  knowledgeGraphFiltersBoxDynamicExpanded,
  tagText,
  tagWrapper,
  tagPopover,
  chatWithMeButton,
  inputNumberRange,
  inputNumberRangeWrapper,
};
