import { css } from '@linaria/core';

const container = css`
  display: flex;
  flex-direction: column;
`;

const body = css`
  flex-grow: 1;
  margin-bottom:24px;
`;

const tableContainer = css`
  margin-left: 1rem;
`;

const schemaLabel = css `
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0.8rem;
`;

const header = css`
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 600;
`;

const column = css`
  background:var(--color-charcoal-surface-1);
  color:var(--color-gale-gray);
  height: 100%;
  border-radius: 10px;
  padding: 1rem;
  .ant-checkbox-disabled + span {
    color: black;
  }
`;

const columnsContainer = css`
  max-height: 150px; 
  overflow-y: auto;
`;

const columnItem = css`
  color: var(--color-gale-gray);
  font-weight: 500;
`;

export default {
  tableContainer,
  schemaLabel,
  container,
  body,
  header,
  columnsContainer,
  columnItem,
  column,
}