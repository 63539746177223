import React, { useState } from "react";

import Style from "./floatLabel.style";
import {
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Popover,
} from "antd";


/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.label
 * @param {any} props.value
 * @param {string|null} [props.labelClassName]
 * @param {Object} [props.style]
 * @param {string|React.ReactNode|null} [props.helpText]
 * @param {string|null} [props.helpTextPopoverClassName]
 * @param {Object} [props.helpTextPopoverStyle]
 * @param {string|null} [props.helpTextTitle]
 * @param {boolean|undefined} [props.open]
 */
const FloatLabel = ({
  children,
  label,
  value,
  labelClassName = null,
  style = {},
  helpText = '',
  helpTextPopoverClassName = null,
  helpTextPopoverStyle = { maxWidth: "250px", fontSize: "11px" },
  helpTextTitle = null,
  open = undefined,
}) => {
  const [focus, setFocus] = useState(false);

  const labelClass = focus || (value && value.length !== 0) ? `${Style.label} ${Style.labelFloat}` : `${Style.label}`;

  return (
    <div className={`${Style.floatLabel}`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <label className={`${labelClass} ${labelClassName}`} style={style}>
        {label}
        {helpText && (
          <Popover
            title={helpTextTitle}
            content={helpText}
            overlayClassName={`${helpTextPopoverClassName}`}
            overlayStyle={helpTextPopoverStyle}
            open={open}
          >
            &nbsp;
            <InfoCircleOutlined className={Style.helpIcon} />{" "}
          </Popover>
        )}
      </label>
    </div>
  );
};

export default FloatLabel;
