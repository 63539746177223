import { message, notification } from "antd";
import { useState, useEffect } from "react";
import { SqlToolApiServices } from "../sqlToolApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";

export default function useExploreDatabaseHook({ selectedConnection, clientId, formRef, setExploreDataBaseDrawer }) {
  const tables = formRef.current.getFieldValue("tables") || [];
  const [loading, setLoading] = useState(false);
  const [availableSchemaAndTable, setAvailableSchemaAndTable] = useState({});
  const [data, setData] = useState({});
  const [schema , setSchema] = useState({});
 

  const onChangeSchemaTable = (val, schemaName) => {
    setData({
      ...data,
      [schemaName]: val,
    });
  };

  const addTable = () => {
    const tablePayload = [];
    const schemaArray = Object.keys(data);
    schemaArray.forEach((item) => {
      const tables = data[item];
      tables.forEach((ele) => {
        if (!availableSchemaAndTable?.[item]?.includes(ele)) {
          const columns = schema?.[item]?.[ele].map((item) => {
            return {
              ...item,
              is_active: true,
            };
          });
          tablePayload.push({
            schema_name: item,
            table_name: ele,
            columns: columns,
            is_active: true,
          });
        }
      });
    });
    formRef.current.setFieldsValue({
      tables: [...tables, ...tablePayload],
    });
    if (tablePayload.length > 0) {
      message.success("Table added successfully");
    }
    setExploreDataBaseDrawer(false);
  };

  const getSchema = async () => {
    try {
      setLoading(true);
      const res = await SqlToolApiServices.getSchema(clientId, selectedConnection);
      setSchema(res.data);
      let schema = res.data;
      const tempAvailableSchemaAndTable = {};
      tables.forEach((item) => {
        let availableSchema = item?.schema_name;
        if (availableSchema && schema[availableSchema]) {
          if (tempAvailableSchemaAndTable[availableSchema]) {
            tempAvailableSchemaAndTable[availableSchema].push(item?.table_name);
          } else {
            tempAvailableSchemaAndTable[availableSchema] = [item?.table_name];
          }
        }
      });
      setAvailableSchemaAndTable(tempAvailableSchemaAndTable);
      setData(tempAvailableSchemaAndTable);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 7,
        placement: "bottomRight"
      });
    }
  }

  useEffect(() => {
    getSchema();
  }, [])
  return {
    data,
    availableSchemaAndTable,
    onChangeSchemaTable,
    addTable,
    loading,
    schema
  };
}
