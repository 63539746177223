import { useState, useEffect, useRef } from "react";

const useOpportunityFeedbackFormHooks = ({ opportunity, feedbackData, onSubmitFeedback, onDone }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const submitFeedback = async (val) => {
    setLoading(true);
    await onSubmitFeedback({
      opportunity_id: opportunity.id,
      feedback_text: val?.feedback_text ? val?.feedback_text : null,
      feedback_type: feedbackData?.feedback_type,
    });
    setLoading(false);
    onDone();
  };

  const removeFeedback = async () => {
    setLoading(true);
    await onSubmitFeedback({
      opportunity_id: opportunity.id,
      feedback_text: null,
      feedback_type: null,
    });
    setLoading(false);
    onDone();
  };

  return {
    loading,
    setLoading,
    formRef,
    submitFeedback,
    removeFeedback,
  };
};

export default useOpportunityFeedbackFormHooks;
