import { CopyOutlined } from "@ant-design/icons";
import { Form, Input, Row } from "antd";
import React from "react";
import { RoundedPrimaryButton } from "src/components/basic";
import useAddSchemaHook from "../hooks/useAddSchemaHook";
import Style from "./googleSheetCapability.style";

export default function GoogleSheetModal({ add, id, setSchemaModal, files }) {
  const { loading, formRef, getSchema, checkDuplicate, copyToClipboard } = useAddSchemaHook({ add, id, setSchemaModal, files });
  const urlRegex = /^(?:https?|ftp):\/\/(?:\S+(?::\S*)?@)?(?:(?!-)[-a-z0-9]{1,63}(?<!-)\.)+(?:[a-z]{2,})(?::\d{2,5})?(?:\/\S*)?$/i;
  return (
    <div>
      <div className={Style.addSchemaHeader}>Add google sheet</div>
      <div>
        <b>Note:</b> Ensure <CopyOutlined onClick={() => {
          copyToClipboard();
        }}/> <span style={{ padding: '4px', background: '#EEF1F2', borderRadius: '5px'}}><i style={{ color: "blue" }}>alchemyai@alchemyai-418009.iam.gserviceaccount.com</i></span>  has viewer access to the
        file
      </div>
      <br />
      <div className={Style.form}>
        <Form
          ref={formRef}
          onFinish={(val) => {
            getSchema(val);
          }}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            name="file_url"
            label="File Url*"
            rules={[
              {
                required: true,
                message: "File url is required",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (!!!value.trim().length) {
                      return Promise.reject(new Error("Url is required."));
                    } else {
                      if (urlRegex.test(value)) {
                        if (checkDuplicate(value, files)) {
                          return Promise.reject(new Error("Google sheet url already present"));
                        } else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.reject(new Error("Invalid Url"));
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        <Row justify="end">
          <RoundedPrimaryButton loading={loading} onClick={() => formRef.current.submit()}>
            Fetch schema
          </RoundedPrimaryButton>
        </Row>
      </div>
    </div>
  );
}
