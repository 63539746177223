import { notification } from "antd";
import { format } from "date-fns";
import { set } from "dot-object";
import { has } from "lodash";
import { get } from "lodash";
import { debounce } from "lodash";
import { useState, useEffect, useCallback, useRef } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { CLUSTER_COLORS } from "src/modules/audienceTool/clusterTool/constants/constants";
import { ClusterToolApiServices } from "src/modules/audienceTool/clusterTool/services/ClusterToolApiServices";

const useCrossFeatureView = ({ clusterData }) => {
  const [loading, setLoading] = useState(true);
  const [feature1Page, setFeature1Page] = useState(1);
  const [loadingFeature1, setLoadingFeature1] = useState(false);
  const [feature2Page, setFeature2Page] = useState(1);
  const [queryFeature1, setQueryFeature1] = useState("");
  const [queryFeature2, setQueryFeature2] = useState("");
  const [loadingFeature2, setLoadingFeature2] = useState(false);
  const [hasMore1, setHasMore1] = useState(true);
  const [chartLoading, setChartLoading] = useState(false);
  const [hasMore2, setHasMore2] = useState(true);
  const [feature1Data, setfeature1Data] = useState({
    data: [],
    total: null,
    total_pages: null,
  });
  const [feature2Data, setFeature2Data] = useState({
    data: [],
    total: null,
    total_pages: null,
  });
  const [selectedData, setSelectedData] = useState({
    feature1: "Exact Age",
    feature2: "Gender: Female",
  });
  const [chartOptions, setChartOptions] = useState(null);
  const [keyFeature, setKeyFeature] = useState(null);
  const [keyFeaturesData, setKeyFeaturesData] = useState([]);
  const chartref = useRef();

  useEffect(() => {
    defaultLoad();
  }, []);

  const defaultLoad = async () => {
    try {
      setLoading(true);
      await getFeature1Data(1, "");
      await getFeature2Data(1, "");
      if (clusterData?.clusters_type === "CRM") {
        await getKeyFeature();
      }
      await getFeatureMeanInfo(selectedData.feature1, selectedData.feature2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleScrollToEnd = () => {
    if (hasMore1 && !loading && feature1Page < feature1Data.total_pages) {
      setFeature1Page((prevPage) => prevPage + 1);
      getFeature1Data(feature1Page + 1, queryFeature1);
    }
  };

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      handleScrollToEnd();
    }
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      setFeature1Page(1);
      setQueryFeature1(value);
      getFeature1Data(1, value);
    }, 800),
    []
  );

  const handleScrollToEndFeature2 = () => {
    if (hasMore2 && !loading && feature2Page < feature2Data.total_pages) {
      setFeature2Page((prevPage) => prevPage + 1);
      getFeature2Data(feature2Page + 1, queryFeature2);
    }
  };

  const handlePopupScrollFeature2 = (e) => {
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      handleScrollToEndFeature2();
    }
  };

  const handleSearchFeature2 = useCallback(
    debounce(async (value) => {
      setFeature2Page(1);
      setQueryFeature2(value);
      getFeature2Data(1, value);
    }, 800),
    []
  );

  const getFeature1Data = async (page, searchText = "") => {
    try {
      setLoadingFeature1(true);
      const payload = {
        clusters_config_id: clusterData?.id,
        search_column: "feature_name",
        search_value: searchText,
      };
      const res = await ClusterToolApiServices.getClusterFeature(payload, page);
      const data = res.data;
      if (page === 1) {
        setfeature1Data({
          data: data.data,
          total: data.total,
          total_pages: data.total_pages,
        });
      } else {
        setfeature1Data((prevData) => ({
          data: [...prevData.data, ...data.data],
          total: data.total,
          total_pages: data.total_pages,
        }));
      }
      setLoadingFeature1(false);
      setHasMore1(data?.data?.length > 0);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoadingFeature1(false);
    } finally {
      setLoadingFeature1(false);
    }
  };

  const getFeature2Data = async (page, searchText = "") => {
    try {
      setLoadingFeature2(true);
      const payload = {
        clusters_config_id: clusterData?.id,
        search_column: "feature_name",
        search_value: searchText,
      };
      const res = await ClusterToolApiServices.getClusterFeature(payload, page);
      const data = res.data;
      if (page === 1) {
        setFeature2Data({
          data: data.data,
          total: data.total,
          total_pages: data.total_pages,
        });
      } else {
        setFeature2Data((prevData) => ({
          data: [...prevData.data, ...data.data],
          total: data.total,
          total_pages: data.total_pages,
        }));
      }
      setLoadingFeature2(false);
      setHasMore2(data?.data?.length > 0);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
      setLoadingFeature2(false);
    } finally {
      setLoadingFeature2(false);
    }
  };

  const getFeatureMeanInfo = async (feature1, feature2, keyFeature=null) => {
    try {
      setChartLoading(true);
      const payload = {
        clusters_config_id: clusterData?.id,
        feature_names: [feature1, feature2],
      };
      if (keyFeature) {
        payload.core_feature_name = keyFeature
      }
      const res = await ClusterToolApiServices.getClusterFeatureMeanInfo(payload);
      const formatOptions = formatChartData(res.data, feature1, feature2);
      setChartLoading(false);
    } catch (error) {}
  };

  const getKeyFeature = async () => {
    try {
      const res = await ClusterToolApiServices.getClusterCoreFeatures(clusterData?.id);
      setKeyFeaturesData(res.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight",
      });
    }
  }

  const formatChartData = (data, feature1, feature2) => {
    if (data?.length > 0) {
      const feature1Category = data[0]?.features?.[feature1]?.feature_data_type === "categorical";
      const feature2Category = data[0]?.features?.[feature2]?.feature_data_type === "categorical";
      const xValues = data.map((item) => item?.features?.[feature1]?.mean_value);
      const yValues = data.map((item) => item?.features?.[feature2]?.mean_value);

      const xMin = Math.min(...xValues);
      const xMax = Math.max(...xValues);
      const yMin = Math.min(...yValues);
      const yMax = Math.max(...yValues);
      let keyFeatureName = null;
      let totalMeanValue = 0;
      
        data?.forEach((item) => {
          if (item?.core_feature && item?.core_feature?.mean_value >= 0) {
            keyFeatureName = item?.core_feature?.feature_name;
            totalMeanValue += item?.core_feature?.mean_value;
          }
        })
      let option = {
        xAxis: {
          type: "value",
          name: `${feature1Category ? "% of " : ""}${feature1}`,
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 16,
            padding: [20, 0, 0, 0],
          },
          axisLine: {
            lineStyle: {
              color: "#888", // X-axis line color
            },
          },
          axisLabel: {
            color: "white", // X-axis label color
          },
          splitLine: {
            lineStyle: {
              color: "none", // X-axis grid line color
            },
          },
          min: Math.floor(xMin),
          max: Math.ceil(xMax),
        },
        yAxis: {
          type: "value",
          name: `${feature2Category ? "% of " : ""}${feature2}`,
          nameLocation: "middle",
          nameTextStyle: {
            fontSize: 16,
            padding: [0, 0, 30, 0],
          },
          axisLine: {
            lineStyle: {
              color: "#888", // Y-axis line color
            },
          },
          axisLabel: {
            color: "white", // Y-axis label color
          },
          splitLine: {
            lineStyle: {
              color: "none", // Y-axis grid line color
            },
          },
          min: Math.floor(yMin),
          max: Math.ceil(yMax),
        },
        grid: {
          containLabel: true,
          borderColor: "#ccc",
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            return `<b>${params?.seriesName}</b><br/>${feature1Category ? "% of " : ""}${feature1}: ${params.value[0].toFixed(2)}<br/>${
              feature2Category ? "% of " : ""
            }${feature2}: ${params.value[1].toFixed(2)}<br />${keyFeatureName ?  keyFeatureName : 'Cluster Size'}: ${ keyFeatureName ?  params.value[3] : `${params.value[2]?.toFixed(2)}%`}`;
          },
        },
        legend: {
          data: data.map((item) => item.cluster_name),
          textStyle: {
            color: "#ffffff",
          },
        },
        color: CLUSTER_COLORS,
        series: data.map((item, idx) => {
          if (totalMeanValue) { 
            let percentage = item?.core_feature?.mean_value / totalMeanValue * 100;
            return {
              name: item.cluster_name,
              type: "scatter",
              symbolSize: percentage * 5,
              data: [[item?.features?.[feature1]?.mean_value, item?.features?.[feature2]?.mean_value, percentage, item?.core_feature?.mean_value]],
            }
          }
          return {
            name: item.cluster_name,
            type: "scatter",
            symbolSize: item.cluster_decimal_percentage * 5,
            data: [[item?.features?.[feature1]?.mean_value, item?.features?.[feature2]?.mean_value, item.cluster_decimal_percentage]],
          };
        }),
      };
      setChartOptions(option);
    } else {
      setChartOptions(null);
    }
  };

  const downloadChart = () => {
    const echartsInstance = chartref.current.getEchartsInstance(); // Get the ECharts instance
    const imgURL = echartsInstance.getDataURL({
      type: "png", // You can specify 'jpeg' or 'svg' as well
      pixelRatio: 2, // Increase pixel ratio for better resolution
      backgroundColor: "black",
    });

    // Create a link element and trigger download
    const link = document.createElement("a");
    link.href = imgURL;
    link.download = "chart.png"; // Filename for the downloaded image
    link.click();
  };

  return {
    loading,
    handlePopupScroll,
    handleSearch,
    loadingFeature1,
    feature1Data,
    loadingFeature2,
    handleSearchFeature2,
    handlePopupScrollFeature2,
    feature2Data,
    selectedData,
    setSelectedData,
    getFeatureMeanInfo,
    chartOptions,
    chartLoading,
    chartref,
    downloadChart,
    keyFeature,
    setKeyFeature,
    keyFeaturesData
  };
};

export default useCrossFeatureView;
