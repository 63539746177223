import { notification } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { SqlToolApiServices } from '../sqlToolApiServices';

export default function useSqlGlobalSettingsHook({ metaData, aiAppData, mAiApp, updateCustomApp }) {
  const formRef = useRef();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const configData = metaData
  const capabilityData = aiAppData?.capabilities?.find((ele) => ele.capability_name === "SqlCapabilityV2");

  const fetchInitialValues = () => {
    setLoading(true);
    if (capabilityData) {
      setInitialValues(capabilityData?.fields_config);
    } else {
      const config = configData?.config;
      let obj = {
        sql_assistant_name: config?.sql_assistant_name?.default_value,
        sql_assistant_description_for_system_prompt: config?.sql_assistant_description_for_system_prompt?.default_value,
        sql_assistant_tool_description: config?.sql_assistant_tool_description?.default_value,
        sql_assistant_llm_config: {
          model_name: config?.sql_assistant_llm_config?.model_name?.default_value,
          temperature: config?.sql_assistant_llm_config?.temperature?.default_value,
          max_tokens: config?.sql_assistant_llm_config?.max_tokens?.default_value,
        },
        sql_assistant_agent_config: {
          agent_type: config?.sql_assistant_agent_config?.agent_type?.default_value,
          max_iterations: config?.sql_assistant_agent_config?.max_iterations?.default_value,
          chat_history_window: config?.sql_assistant_agent_config?.chat_history_window?.default_value,
          intermediate_steps_chat_history_window: config?.sql_assistant_agent_config?.intermediate_steps_chat_history_window?.default_value,
        },
        master_prompt_injection_type: config?.master_prompt_injection_type?.default_value
      };
      setInitialValues(obj);
    }
    setLoading(false);
  }

  const configureCapability = async (val) => {
    try {
      const payload = {
        capability_name: "SqlCapabilityV2",
        fields_config: val,
      }
      const res = await SqlToolApiServices.postCapibility(mAiApp, payload);
      updateCustomApp(res.data);
      notification.success({
        message: 'Sql tool configured successfully',
        duration: 6,
        placement: 'bottomRight'
      })
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 6,
        placement: 'bottomRight'
      })
    }
  }

  useEffect(() => {
    fetchInitialValues();
  }, [])

  return {
    formRef,
    loading,
    initialValues,
    configData,
    configureCapability
  }
}