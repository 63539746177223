import axios from "src/http/index";
import ApiUrls from "../../../constants/apiUrls";

const login = async (data) => {
  const res = await axios.post(ApiUrls.LOGIN, data);
  return res.data
};

const passwordReset = async (data, token) => {
  const res = await axios.post(ApiUrls.PASSWORD_RESET, data, {
    headers: {
      authorization: token
    }
  })
}

export default {
  login,
  passwordReset
};
