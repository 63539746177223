import React from "react"
import { hasAllPermissions } from "./permissions"

export function useDebouncedEffect(fn, deps = [], time = 700) {
    const dependencies = [...deps, time]
    React.useEffect(() => {
      const timeout = setTimeout(fn, time)
      return () => {
        clearTimeout(timeout)
      }
    }, dependencies)
  }

  export const isAllowedWithPermissions = (permissions, features) => {
    const newPermissions = Array.isArray(permissions) ? permissions : [permissions];
    return hasAllPermissions(features, newPermissions)
  }
