import { Drawer, Skeleton, Table, Space, Row } from "antd";
import React from "react";
import Style from "./style";
import useListEvaluationHooks from "../hooks/listEvaluationHook";
import ListDataset from "./listDataset";
import EvaluationDetail from "./evaluationDetail";
import { PrimaryButton, SecondaryButton } from "src/components/basic";
import GlobalTag from "src/components/globalTag/GlobalTag";
import { EVALUATION_STATUS_COLOR, TAG_COLOR_NAME } from "src/components/globalTag/globalTagConstant";

export default function Evaluation({ appId, backToChatAppHandler }) {
    const { loading, pageNumber, setPageNumber, evaluations, getEvaluations } = useListEvaluationHooks({ appId });

    const [datasetModal, setDatasetModal] = React.useState(false);

    const [viewEvaluationModal, setViewEvaluation] = React.useState(false);
    const [evaluationId, setEvaluationId] = React.useState(null);

    const openDataSet = () => {
        setDatasetModal(true);
    };

    const onCloseDataset = () => {
        setDatasetModal(false);
    };

    const openEvaluationDetail = () => {
        setViewEvaluation(true);
    };

    const onCloseEvaluationDetail = () => {
        setViewEvaluation(false);
    };

    const refreshEvaluations = () => {
        onCloseDataset();
        setPageNumber(1);
        getEvaluations();
    };
    const statusColor = {
        COMPLETED: 'green',
        ERROR: 'red',
        IN_PROGRESS: 'yellow',
        PENDING: 'yellow',
    };
    const columns = [
        {
            title: 'Dataset Name',
            dataIndex: 'evaluation_dataset_name',
            key: 'evaluation_dataset_name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                <GlobalTag color={EVALUATION_STATUS_COLOR[status]}>
                    <b>{status}</b>
                </GlobalTag>
            ),
        },
        {
            title: 'Total Questions',
            dataIndex: 'total_questions_to_processed',
            key: 'total_questions_to_processed',
        },
        {
            title: 'Question Processed',
            dataIndex: 'questions_processed',
            key: 'questions_processed',
        },
        {
            title: 'Score',
            dataIndex: 'overall_score',
            key: 'overall_score',
            render: (_, record) => (
                record.status === 'COMPLETED' ? parseFloat(record.overall_score).toFixed(1) : <i>Computing</i>
              ),
        },
        {
            title: 'Evaluation Type',
            dataIndex: 'is_conversation_based',
            key: 'is_conversation_based',
            render: is_conversation_based => (
                <GlobalTag color={is_conversation_based ? TAG_COLOR_NAME.BLUE : TAG_COLOR_NAME.ORANGE}>
                    <b>{is_conversation_based ? 'Conversational' : 'Non-Conversational'}</b>
                </GlobalTag>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on',
            render: created_on => (
                <span>{new Date(created_on).toLocaleString()}</span>
            ),
        },
        {
            title: 'Action',
            key: 'created_on',
            render: (_, record) => (
                <Space size="middle">
                  <button 
                  className="utility-btn"
                  onClick={() =>{
                        setEvaluationId(record.id);
                        openEvaluationDetail();
                  }}>View</button>
                </Space>
              ),
        },
    ];

    if(loading) return <Skeleton active />;
    return (
        <div>
            <Row justify="end" className={Style.btnsCOntainer}>
                <PrimaryButton className="small" onClick={backToChatAppHandler}>
                    Go to assistant
                </PrimaryButton>
                <SecondaryButton className="small" onClick={openDataSet}>
                    View Datasets
                </SecondaryButton>
                <SecondaryButton className="small" onClick={refreshEvaluations}>
                    Refresh
                </SecondaryButton>
            </Row>
            <Drawer
                key={"dataset"}
                title={"Datasets"}
                maskClosable={true}
                placement="right"
                onClose={onCloseDataset}
                open={datasetModal}
                destroyOnClose
                size="large"
                width={"75%"}
            >
                <ListDataset appId={appId} refreshEvaluations={refreshEvaluations} />
            </Drawer>

            <Drawer
                key={"evaluation_detail"}
                title={"Evaluation Detail"}
                maskClosable={true}
                placement="right"
                onClose={onCloseEvaluationDetail}
                open={viewEvaluationModal}
                destroyOnClose
                size="large"
                width={"50%"}
            >
                <EvaluationDetail evaluationId={evaluationId} appId={appId}  />
            </Drawer>

            <br/>
            <br/>
            <div>
                <Table
                    className='dark-table'
                    columns={columns}
                    dataSource={evaluations?.data || []} 
                    onChange={(pagination) => {
                        setPageNumber(pagination.current);
                    }}
                    pagination={{
                        current: pageNumber,
                        total: evaluations?.total_size,
                    }}
                />
                </div>
        </div>
    );
}