import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Collapse, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import useSqlStrategyHook from "./useSqlStrategyHook";
import Style from "./sqlStrategy.style";
import CommonStyle from "../newSqlTool.style";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";

export default function SqlStrategy({ selectedConnection, mAiApp, clientId, connectionString }) {
  const { loading, formRef, initialValues, saveStrategies, configureLoading } = useSqlStrategyHook({
    selectedConnection,
    mAiApp,
    clientId,
    connectionString,
  });
  return (
    <div className={Style.container}>
      <div style={{ flexGrow: "1" }}>
        <ConditionalRender shouldRender={loading}>
          <LoadingSpinner centered />
        </ConditionalRender>
        <ConditionalRender shouldRender={!loading}>
          <Form
            initialValues={initialValues}
            ref={formRef}
            layout="vertical"
            requiredMark={false}
            onFinish={(val) => {
              saveStrategies(val);
            }}
          >
            <Form.List name="strategies">
              {(fields, { add, remove }) => {
                return (
                  <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional} noStyle>
                    {({ getFieldsValue }) => {
                      return (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                            const strategy = getFieldsValue(["strategies"]);
                            const strategyName = strategy?.strategies?.[idx]?.strategy_name;
                            return (
                              <div style={{ display: "flex", gap: "1rem" }}>
                                <div style={{ flexGrow: "1" }}>
                                  <Collapse className={'drawerCollapse'}>
                                    <Collapse.Panel forceRender header={`${strategyName ? strategyName : `New Strategy ${idx + 1}`}`}>
                                      <Row gutter={[30, 0]}>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy name"
                                            name={[name, "strategy_name"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={[{ required: true, message: "Strategy Name is required" }]}
                                          >
                                            <Input placeholder="Enter strategy name" />
                                          </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy scenario"
                                            name={[name, "strategy_scenario"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={[{ required: true, message: "Strategy scenario is required" }]}
                                          >
                                            <TextArea placeholder="Describe briefly the scenario" autoSize={{ minRows: 2, maxRows: 5 }} />
                                          </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                          <Form.Item
                                            label="Strategy steps"
                                            name={[name, "strategy_steps"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={[{ required: true, message: "Strategy steps is required" }]}
                                          >
                                            <TextArea placeholder="Strategy steps to be performed" autoSize={{ minRows: 4, maxRows: 7 }} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Collapse.Panel>
                                  </Collapse>
                                </div>
                                <div>
                                  <DeleteOutlined className="delete-btn" style={{ marginTop: "12px" }} onClick={() => remove(name)} />
                                </div>
                              </div>
                            );
                          })}
                          <div className={Style.addRowContainer} onClick={() => add()}>
                            <PlusCircleOutlined />
                            &nbsp;<span>Add Strategy</span>
                          </div>
                        </>
                      );
                    }}
                  </Form.Item>
                );
              }}
            </Form.List>
          </Form>
        </ConditionalRender>
      </div>
      <Row justify="end" style={{ paddingBottom: "1rem" }}>
        <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()} className={CommonStyle.button}>
          Save
        </SecondaryButton>
      </Row>
    </div>
  );
}
