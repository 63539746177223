import { DeleteOutlined, DownloadOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Collapse, Divider, Form, Input, Row, Select, Skeleton, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CSVLink } from "react-csv";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import { getErrorMessageFromResponse } from "src/lib/utils";
import useSqlCapabilityHook from "../../../hooks/useSqlCapabilityHook";
import { CustomGptService } from "../../../services/customGptServices";
import CapabilityColumn from "./CapabilityColumn";
import SqlAdvancedSettings from "./SqlAdvancedSettings";
import Style from "./sqlCapabilit.style";
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style.js";

export default function SqlCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const {
    loading,
    connectionString,
    formRef,
    initialValues,
    configureCapability,
    configureLoading,
    csvHeaders,
    validateCSVAndGetRecords,
    csvColumns,
    parseCsv,
    templateData
  } = useSqlCapabilityHook({
    id,
    aiAppData,
    configData,
    updateCustomApp,
    onClose,
    reload,
  }); 
  return (
    <div style={{paddingRight:24, paddingBottom:16}}>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <>
          <Row justify="end" gutter={[20, 0]} style={{ marginBottom: "1rem" }}>
            <Col>
              <Upload
                accept=".csv"
                showUploadList={false}
                maxCount={1}
                beforeUpload={(file) => {
                  const reader = new FileReader();
                  reader.readAsText(file);
                  return false;
                }}
                onChange={async (event) => {
                  const file = event.file;
                  await parseCsv(file);
                }}
              >
                <SecondaryButton className={'small'}>
                  Upload&nbsp;&nbsp;
                  <UploadOutlined />
                </SecondaryButton>
              </Upload>
            </Col>
            <Col>
              <CSVLink data={templateData} headers={csvColumns} filename="sql-capability-template" target="_blank">
                <SecondaryButton className={'small'}>
                  Download&nbsp;&nbsp;
                  <DownloadOutlined />
                </SecondaryButton>
              </CSVLink>
            </Col>
          </Row>
          <div className={Style.form}>
            <Form
              onFinish={(val) => {
                configureCapability(val);
              }}
              requiredMark={false}
              ref={formRef}
              layout="vertical"
              initialValues={initialValues}
            >
              <Form.List name="tables">
                {(fields, { add, remove }) => {
                  return (
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional} noStyle>
                      {({ getFieldsValue }) => {
                        return (
                          <>
                            {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                              const tables = getFieldsValue(["tables"]);
                              const tableName = tables?.tables?.[idx]?.name;
                              return (
                                <div style={{ display: "flex", gap: "8px" }} key={`table-${idx}`}>
                                  <div>
                                    <Form.Item
                                      name={[name, "is_active"]}
                                      fieldKey={[fieldKey, "is_active"]}
                                      {...restField}
                                      valuePropName="checked"
                                    >
                                      <Checkbox />
                                    </Form.Item>
                                  </div>
                                  <div style={{ flexGrow: "1" }}>
                                    <Collapse className={'drawerCollapse'}>
                                      <Collapse.Panel
                                        key="1"
                                        forceRender={true}
                                        header={`${tableName ? tableName : `New table ${idx + 1}`}`}
                                      >
                                        <div className={Style.table}>
                                          <Form.Item
                                            label="Connection*"
                                            name={[name, "connection_id"]}
                                            fieldKey={[fieldKey, "name"]}
                                            {...restField}
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Table",
                                              configData?.config?.tables?.fields?.connection_id
                                            )}
                                          >
                                            <Select>
                                              {connectionString.map((ele) => {
                                                return <Select.Option value={ele?.connection_name}>{ele?.connection_name}</Select.Option>;
                                              })}
                                            </Select>
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "schema"]}
                                            fieldKey={[fieldKey, "name"]}
                                            label="Schema*"
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Schema",
                                              configData?.config?.tables?.fields?.schema
                                            )}
                                          >
                                            <Input />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "name"]}
                                            fieldKey={[fieldKey, "name"]}
                                            label="Table name*"
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Name",
                                              configData?.config?.tables?.fields?.name
                                            )}
                                          >
                                            <Input />
                                          </Form.Item>
                                          <Form.Item
                                            {...restField}
                                            name={[name, "description"]}
                                            fieldKey={[fieldKey, "description"]}
                                            label="Description*"
                                            rules={CustomGptService.validations(
                                              { required: true, max_length_string: true },
                                              "Description",
                                              configData?.config?.tables?.fields?.description
                                            )}
                                          >
                                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                          </Form.Item>
                                          <CapabilityColumn name={name} configData={configData} />
                                        </div>
                                      </Collapse.Panel>
                                    </Collapse>
                                  </div>
                                  <div>
                                    <DeleteOutlined className="delete-btn" style={{ marginTop: "12px" }} onClick={() => remove(name)} />
                                  </div>
                                </div>
                              );
                            })}
                            <div className={Style.addRowContainer} onClick={() => add({ columns: [undefined] })}>
                              <PlusCircleOutlined />
                              &nbsp;<span>Add table</span>
                            </div>
                          </>
                        );
                      }}
                    </Form.Item>
                  );
                }}
              </Form.List>
              <SqlAdvancedSettings configData={configData} />
              <Row justify="end" className={CommonStyle.globalSave}>
                <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                  Save
                </SecondaryButton>
              </Row>
            </Form>
          </div>
        </>
      </ConditionalRender>
    </div>
  );
}
