import { Col, Form, InputNumber, Row, Select } from "antd";
import React from "react";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";

export default function SqlLlmConfig({ configData }) {
  return (
    <>
      <Row gutter={[30, 0]}>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["sql_assistant_llm_config", "model_name"]}
            label="Model name*"
            rules={CustomGptService.validations(
              { required: true },
              "Model name",
            )}
          >
            <Select>
              {configData?.config?.sql_assistant_llm_config?.model_name?.choices?.map((item) => {
                return <Select.Option key={item}>{item}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item 
            name={["sql_assistant_llm_config", "temperature"]} 
            label="Temperature*"
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Temprature",
              configData?.config?.sql_assistant_llm_config?.temperature
            )}
          >
            <InputNumber style={{width:'100%'}}/>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item 
            name={["sql_assistant_llm_config", "max_tokens"]} 
            label="Max tokens*"
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Max tokens",
              configData?.config?.sql_assistant_llm_config?.max_tokens
            )}
          >
            <InputNumber style={{width:'100%'}}/>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
