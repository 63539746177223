import { css } from "@linaria/core";

const form = css`
  color: var(--color-gale-gray);
`;

const googleDriveHeader = css`
  font-size: 16px;
  font-weight: 600;
  color: var(--color-gale-gray);
  margin-bottom: 1rem;
`;

const googleDriveUrlContainer = css`
  display: flex;
  gap: 2rem;
  width: 100%;
`;

const addRowContainer = css`
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
  margin-left: 1rem;
`;

const fileprocessedContainer = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0.46rem;
  right: 1.5rem;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-gale-gray);
`;

const fileProcessedCount = css`
  color: gray;
  font-weight: 400;
`;

const fileProcessedCompleted = css`
  position: absolute;
  top: 1.15rem;
  right: 1.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
`;

export default {
  form,
  googleDriveUrlContainer,
  addRowContainer,
  googleDriveHeader,
  fileprocessedContainer,
  fileProcessedCount,
  fileProcessedCompleted,
};
