import { Form, Input, Row } from "antd";
import React from "react";
import { SecondaryButton } from "src/components/basic";
import useSessionRenameHook from "../hooks/useSessionRenameHook";

export default function SessionRenameModal( { renameData, renameSession, data, closeRenameModal } ) {
  const {loading, formRef, initialVlaues, changeName} = useSessionRenameHook( { renameData, renameSession, data, closeRenameModal } );
  return (
    <>
      <div style={{ fontSize: "1rem", fontWeight: "600", marginBottom: "1rem" }}>
        Rename session
      </div>
      <Form
        initialValues={initialVlaues}
        ref={formRef}
        labelAlign="left"
        requiredMark={false}
        onFinish={(val) => {
          changeName(val);
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Session name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row justify="end">
          <SecondaryButton loading={loading} onClick={() => formRef.current.submit()}>
            Save
          </SecondaryButton>
        </Row>
      </Form>
    </>
  );
}
