import { css } from '@linaria/core';

const userContainer = css`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const topNavContainer = css`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  z-index: 1000;
  align-items: flex-start;
  position:relative;
  left: 0;
  top: 0;
  margin:0 auto;
  @media screen and (min-width: 768px) {
    padding: 32px 52px;
  }
  &.chatbot-bar {
    align-items: center;
    padding: 16px;
  }
  &.sticky {
    position:sticky;
  }
`;

const aclhemyAiLogoContainer = css`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  margin-right: 12px;
  background:transparent;
  border:none;
  span {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const clientIconButton = css`
  background:transparent;
  border:none;
`;

const clientIcon = css`
  display: flex;
  max-height:30px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  img {
   max-width: 60px;
   max-height: 40px;
   objectFit: contain;
   cursor: pointer;
  }
`;

const editButton = css`
  &.ant-btn-primary {
    border-radius: 8px;
    border: 0px;
    background: #db5a4b;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    height: 2.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    :hover {
      border: 0px;
      background: #db5a4b;
    }
  }
`;

const popOverContainer = css`
  .ant-popover-inner {
    width: fit-content;
    border-radius: 5px;
  }
`;

const accountIcon = css`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const logo = css`
  max-width:180px;
  transition: all 0.3s;
  &.chat-mode {
    max-width:100px;
  }
`;

const userIconButton = css`
  cursor: pointer;
  border: none;
  background: transparent;
  padding:0;
  transition: all 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

const userControlIcon = css`
  margin-right:8px;
`;

const menuIcon = css`
  cursor:pointer;
  padding:0;
  width:fit-content;
  border:none;
  background:transparent;
  img {
    max-height:30px;
  }
`;

export default {
  userContainer,
  topNavContainer,
  aclhemyAiLogoContainer,
  clientIconButton,
  clientIcon,
  popOverContainer,
  editButton,
  accountIcon,
  logo,
  userIconButton,
  userControlIcon,
  menuIcon
}