import { css } from "@linaria/core";

const sideNav = css`
  position: fixed;
  left: 0;
  top:0;
  padding: 16px 0 0 0 ;
  width: 320px;
  height:100vh;
  color: black;
  background: var(--color-charcoal-surface-1);
  z-index: 100;
  transition: transform 0.2s ease-in;
  transform: translateX(-100%);
  &.show {
    z-index: 1002;
    transform: translateX(0);
  }
`;

const navBarContainerWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 70px;
  height: calc(100vh - 90px);
  overflow-y: auto;
  position:relative;
  z-index:1;
`;
const navBarContainer = css`
  display: flex;
  flex-direction: column;
`;
const editButtonParent = css`
  color:var(--color-30-gray);
  display: flex;
  flex-direction: row;
  justify-content: end;
  z-index: 1;
  margin-top:12px;
`
const navBarLogo = css`
  color:var(--color-white);
  font-size: 1rem;
  font-weight:700;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 10px; 
`;

const groupChatContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const groupChatContent = css`
  color:var(--color-white);
  margin-top: 36px; 
  padding: 0 16px;
`;

const myChatGroup = css`
  margin-top: 36px; 
`;

const toggleButton = css`
  &.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: var(--color-white) !important;
    color:var(--color-full-black) !important;
    border:1px solid var(--color-white);
    font-size: 1rem;
    font-weight:500;
    border-right-color:var(--color-white);
    &:hover,
    &:focus{
      border-color: var(--color-white);
      outline:none;
      box-shadow:none;
    }
  }
  .ant-radio-button-wrapper {
    border: none;
    padding: 4px 24px;
    height:fit-content;
    border:1px solid var(--color-white);
    background: transparent !important;
    color:var(--color-white) !important;
    border-right-color:var(--color-white) !important;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    display:none;
    background: none;
  }
  &.ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    display:none;
    background-color: none !important;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 0;
    border-color:var(--color-white);
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display:none;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0;
  }
`;

const sideNavCollapse = css`
  &.ant-collapse {
    width:100%;
    border-radius: 10px;
    margin-bottom: 1rem;
    background: transparent;
    color:var(--color-white);
    border: 0;
  }
  &.ant-collapse  .ant-collapse-item  .ant-collapse-header {
    color:var(--color-30-gray);
    padding: 9px 16px !important;
    border-radius: 0px !important;
  }
  &.ant-collapse > .ant-collapse-item {
    border: 0;
  }
  .ant-collapse-content {
    background: transparent;
    color:var(--color-white);
    padding:0;
    border-top: 1px solid var(--color-60-gray);
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px; 
    border-radius:0;
  }
`;

const chatListContainer = css`
  max-height: 201px;
  overflow-y: auto;
  padding:12px 0;
`;

const chatListItem = css`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border-radius: 0px
  cursor: pointer;
  transition: all 0.2s ease-in;
  .anticon svg {
    display: inline-block;
  }
  div {
    .anticon svg {
      display: inline-block;
    }
  }
  &:hover {
    .anticon svg {
      display: inline-block;
    }
    background: var(--color-charcoal-surface-2);
  }
`;
const activeListItem = css`
  background: var(--color-charcoal-surface-2);
`;
const popOverContainer = css`
  .ant-popover-inner {
    width: fit-content;
    border-radius: 5px;
  }
`;


const sessionLoadingLoader = css`
  display: flex;
  justify-content: center;
`;
const disclaimer = css`
  cursor:pointer;
  text-align:center;
  font-size: 1rem;
  padding: 0px 10px;
  text-align: center;
  color: var(--color-gale-gray);
`;

const overlay = css`
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:1001;
  background:var(--color-full-black);
  opacity:0.3;
`;

const appDescription = css`
  color:var(--color-white);
  font-size: 1rem;
  font-weight:700;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 16px; 
  max-width: 80%;
  margin: 16px auto 0;
`;

const deleteGroupChat = css`
  color:var(--color-30-gray);
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-top:12px;
  text-align: center;
`

export default {
  sideNav,
  navBarContainerWrapper,
  navBarContainer,
  editButtonParent,
  navBarLogo,
  groupChatContainer,
  groupChatContent,
  myChatGroup,
  toggleButton,
  sideNavCollapse,
  chatListContainer,
  chatListItem,
  activeListItem,
  popOverContainer,
  sessionLoadingLoader,
  disclaimer,
  overlay,
  appDescription,
  deleteGroupChat
}