import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import assets from 'src/assets';
import ConditionalRender from 'src/components/conditionalRender';
import appUrls from 'src/constants/appUrls';
import Style from '../../clientSpaceCard.style';
import { useMicroAppHook } from '../../hooks/microAppHook';

const MicroAppCard = ({ data, clientId }) => {
  const { getDashSso, getMPTSso } = useMicroAppHook(clientId);
  const history = useHistory();

  const handleCardClick = (appType, url, id) => {
    if (appType === 'REPORTING_APP') {
      getDashSso(clientId);
    } else if (appType === 'SOCIAL_INSIGHTS_APP') {
      const socialInsightsUrl = appUrls.APP_PAGE(
        clientId,
        data?.app_config?.app_id,
        undefined,
        'socialInsights'
      );
      history.push(socialInsightsUrl);
    } else if (appType === 'CLUSTERING_APP') {
      const clusteringUrl = appUrls.CLUSTER_TOOL(clientId);
      history.push(clusteringUrl);
    } else if (appType === 'MEDIA_PLANNER_APP') {
      // media planner url goes here
    } else if (appType === 'NEWS_AND_CULTURE_APP') {
      const newsAndCultureUrl = appUrls.NEWS_CULTURE(clientId);
      history.push(newsAndCultureUrl);
    } else if (appType === 'MEDIA_PLANNER_TOOL') {
      getMPTSso(clientId);
    } else if (appType === 'AUDIENCE_APP') {
      const audienceUrl = appUrls.AUDIENCE_TOOL(clientId);
      history.push(audienceUrl);
    } else if (appType === 'MEDIA_PLAN_GENERATOR') {
      const mpgUrl = appUrls.MEDIA_PLAN_LISTING_LAYOUT(clientId);
      history.push(mpgUrl);
    } else if (appType === 'LABELER') {
      const labelerUrl = appUrls.LABELER(clientId);
      history.push(labelerUrl);
    }
  };

  const CardTitle = {
    REPORTING_APP: 'Dashboards',
    SOCIAL_INSIGHTS_APP: 'Creative Content Coder',
    MEDIA_PLANNER_APP: 'Media Planner',
    CLUSTERING_APP: 'Clustering Tool',
    MEDIA_PLANNER_TOOL: 'Media Planner Tool',
    NEWS_AND_CULTURE_APP: 'Sensing Culture',
    AUDIENCE_APP: 'Audience Tool',
    MEDIA_PLAN_GENERATOR: 'Media Plan Generator',
    LABELER: 'Labeler',
  };

  const Images = {
    REPORTING_APP: {
      icon: assets.getImage('MicroappDashboardIcon'),
    },
    SOCIAL_INSIGHTS_APP: {
      icon: assets.getImage('MicroappSocialInsightsIcon'),
    },
    MEDIA_PLANNER_APP: {
      icon: assets.getImage('MicroappMediaPlanIcon'),
    },
    CLUSTERING_APP: {
      icon: assets.getImage('MicroappClusteringIcon'),
    },
    NEWS_AND_CULTURE_APP: {
      icon: assets.getImage('MicroappNewsCultureIcon')
    },
    INTERACTIVE_PERSONAL_APP: {
      icon: assets.getImage('MicroappInteractivePersonaIcon')
    },
    PRIVATE_WORLD_DATA_CHAT_APP: {
      icon: assets.getImage('MicroappPrivateWorldDataChatIcon')
    },
    MEDIA_PLANNER_TOOL: {
      icon: assets.getImage('MicroappMediaPlanIcon'),
    },
    AUDIENCE_APP: {
      icon: assets.getImage('MicroAppAudienceIcon')
    },
    MEDIA_PLAN_GENERATOR: {
      icon: assets.getImage('MicroappMediaPlanIcon')
    },
    LABELER: {
      icon: assets.getImage('MicroappLabelerIcon'),
    }
  };

  function displayArrow() {
    return (
      <svg
        className={Style.cardArrow + ' arrow'}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 25 26"
      >
        <path
          fill="currentColor"
          d="M17.854 13.649a.5.5 0 000-.708L14.672 9.76a.5.5 0 10-.708.707l2.829 2.829-2.829 2.828a.5.5 0 00.708.707l3.182-3.181zM5.5 13.795h12v-1h-12v1z"
        ></path>
      </svg>
    )
  }

  return (
    <button className={Style.appCardContainer}
      onClick={() =>
        handleCardClick(data?.app_type, data?.app_config?.url, data?.id)
      }
    >
      <div className={Style.imageContainer + ' icon'}>
        <ConditionalRender shouldRender={data?.app_type}>
          <img
            alt={data?.app_type}
            src={Images[data?.app_type]?.icon}
          />
        </ConditionalRender>
      </div>
      <div className={Style.appCardTitle}>
        {data?.app_type && CardTitle[data?.app_type]}
        {displayArrow()}
      </div>
    </button>
  );
};

export default MicroAppCard;
