// create a function nume Relationships using react
import React from "react";
import Style from "./relationships.style";
import { Col, Form, Input, Row, Select } from "antd";
import useRelationshipsHook from "./useRelationshipsHook";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { SecondaryButton } from "src/components/basic";
import CommonStyle from "../newSqlTool.style";
import { initial } from "lodash";

export default function Relationships({ selectedConnection, clientId, sqlData, mAiApp }) {
  const { loading, tables, formRef, configureRelationships, initialValues } = useRelationshipsHook({
    selectedConnection,
    mAiApp,
    sqlData,
  });
  return (
    <div className={Style.formContainer}>
      <div className={Style.body} >
        <Form initialValues={initialValues} ref={formRef} onFinish={(val) => configureRelationships(val)}>
          <Form.List name="relationships">
            {(fields, { add, remove }) => {
              return (
                <div style={{paddingRight:8, overflow:'auto'}}>
                  <Row gutter={[16, 16]} style={{ marginBottom: "1rem", minWidth:768 }}>
                    <Col span={7} className={Style.headers}>
                      Table 1
                    </Col>
                    <Col span={7} className={Style.headers}>
                      Table 2
                    </Col>
                    <Col span={10} className={Style.headers}>
                      Join condition
                    </Col>
                  </Row>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    return (
                      <Row gutter={[16, 0]} style={{minWidth:768}} align={'middle'}>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "table1"]}
                            fieldKey={[fieldKey, "table1"]}
                            rules={[{ required: true, message: "Table is required" }]}
                          >
                            <Select placeholder="Enter table 1" showSearch allowClear>
                              {tables.map((table) => {
                                return <Select.Option value={table}>{table}</Select.Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "table2"]}
                            fieldKey={[fieldKey, "table2"]}
                            rules={[{ required: true, message: "Table is required" }]}
                          >
                            <Select placeholder="Enter table 2" showSearch allowClear>
                              {tables.map((table) => {
                                return <Select.Option value={table}>{table}</Select.Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <div className={Style.columnContainer}>
                            <div style={{ flexGrow: 1 }}>
                              <Form.Item
                                {...restField}
                                name={[name, "join_condition"]}
                                fieldKey={[fieldKey, "join_condition"]}
                                rules={[{ required: true, message: "Join condition is required" }]}
                              >
                                <Input placeholder="Join condition" />
                              </Form.Item>
                            </div>
                            <DeleteOutlined className="delete-btn" style={{marginBottom:24}} onClick={() => remove(name)} />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  <div className={Style.addRowContainer} onClick={() => add()}>
                    <PlusCircleOutlined />
                    &nbsp;<span>Add Relationship</span>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </div>
      <Row justify="end" style={{paddingBottom: '1rem', marginTop:16}}>
        <SecondaryButton className={CommonStyle.button} loading={loading} onClick={() => formRef.current.submit()}>
          Save
        </SecondaryButton>
      </Row>
    </div>
  );
}
