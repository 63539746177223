import { Row, Skeleton } from "antd";
import React from "react";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import useAiAppContainerHook from "../hooks/useAiAppContainerHook";
import AiApp from "./AiApp";

export default function AiAppContainer({ history, match }) {
  const searchQueries = new URLSearchParams(window.location.search);
  const searchQueriesKeys = searchQueries.keys();
  const appId = match.params.id;
  const clientId = match.params.clientId;

  let sessionId = "";
  let mode = "";
  for (const key of searchQueriesKeys) {
    if (key === "session-id") {
      sessionId = searchQueries.get(key);
    }
    if (key === "mode") {
      mode = searchQueries.get(key);
    }
  }
  const {
    loading,
    singleChatSessions,
    multipleChatSessions,
    starterConversation,
    sessionToggle,
    setSessionToggle,
    addNewSession,
    sessionLoading,
    scrollSingleSesion,
    renameSession,
    data,
  } = useAiAppContainerHook({ sessionId, history, appId, mode, clientId });
  return (
    <>
      <ConditionalRender shouldRender={loading}>
        <Row align="middle">
          <LoadingSpinner centered />
        </Row>
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <AiApp
          history={history}
          match={match}
          singleChatSessions={singleChatSessions}
          multipleChatSessions={multipleChatSessions}
          starterConversation={starterConversation}
          data={data}
          sessionToggle={sessionToggle}
          sessionId={sessionId}
          setSessionToggle={setSessionToggle}
          addNewSession={addNewSession}
          sessionLoading={sessionLoading}
          scrollSingleSesion={scrollSingleSesion}
          renameSession={renameSession}
          mode={mode}
          clientId={clientId}
        />
      </ConditionalRender>
    </>
  );
}
