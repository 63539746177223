export const containerStyle = {
    backgroundColor: '#1a1a1a',
    padding: '24px',
    borderRadius: '12px',
  };
  
  export const collapseStyle = {
    backgroundColor: '#1a1a1a',
  };
  
  export const panelStyle = {
    background: '#2a2a2a',
    border: '1px solid #444',
    borderRadius: '10px',
    marginBottom: '16px',
  };
  
  export const headerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };
  
  export const checkboxStyle = {
    color: '#dc5a4b',
  };
  
  export const titleStyle = {
    color: '#dc5a4b',
    margin: 0,
    fontWeight: 'bold',
  };
  
  export const descriptionStyle = {
    fontSize: '14px',
    color: '#fff',
    marginTop: '6px',
    fontWeight: 'bold',
  };
  
  export const subClusterContainerStyle = {
    padding: '10px 0',
    marginLeft: '32px',
    borderBottom: '1px solid #444',
    color: '#fff',
  };
  
  export const subClusterTitleStyle = {
    color: '#dc5a4b',
    margin: 0,
    fontWeight: 'bold',
  };
  
  export const subClusterDescriptionStyle = {
    fontSize: '14px',
    color: '#fff',
    marginTop: '6px',
    fontWeight: 'bold',
  };
  
  export const subClusterSizeStyle = {
    fontSize: '12px',
    color: '#bbb',
    marginTop: '4px',
    fontWeight: 'bolder',
  };