import { notification } from 'antd';
import { useState, useEffect, useRef } from 'react';
import apiUrls from 'src/constants/apiUrls';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse, shortenStr } from 'src/lib/utils';
import { ClusterToolApiServices } from '../../services/ClusterToolApiServices';
import { ClusterToolServices } from '../../services/ClusterToolServices';
import { set } from 'dot-object';

export default function useClusterFeatureHook({ match, history }) {
  const clientSpaceId = match?.params?.clientId;
  const clusterConfigId = match?.params?.clusterId;
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [data, setData] = useState({});
  const [initialValues, setInitialValues] = useState();
  const [recommendedCount, setRecommendedCount] = useState(0);
  const [corelationDrawer, setCorelationDrawer] = useState(false);
  const [autoSelection, setAutoSelection] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const formRef = useRef()

  const getClusterConfig = async () => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getClusterConfig(clusterConfigId);
      const state = res?.data?.state;
      if (state === "CREATING_SAMPLE_CLUSTERS") {
        setViewMode(true);
      }
      let count = 0;
      let salientFeatures = res?.data?.salient_features;
      salientFeatures.forEach((item) => {
        if (item.is_algorithm_recommended) {
          count++;
        }
      });
      if (res?.data?.auto_select_salient_features) {
        setAutoSelection(true);
      }
      let newFeatures;
      if (state === "CREATING_SAMPLE_CLUSTERS" && !res?.data?.auto_select_salient_features) {
        newFeatures = salientFeatures.map((item) => {
          return (
            {
              ...item,
              is_user_selected : item.is_user_selected
            }
          )
        })
      } else {
        newFeatures = salientFeatures.map((item) => {
          return (
            {
              ...item,
              is_user_selected : item.is_algorithm_recommended
            }
          )
        })
      }
  
      setInitialValues({
        salient_features: newFeatures,
        number_of_clusters: 8,
        auto_select_salient_features: res?.data?.auto_select_salient_features
      })
      setRecommendedCount(count);
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      
    }
  }

  const goBack = () => {
    history.push(appUrls.SELECT_FEATURES(clientSpaceId, clusterConfigId));
  }

  const generateCluster = async (val) => {
    try {
      setConfigureLoading(true);
      if (val?.auto_select_salient_features) {
        val.salient_features = [];
      }
      let payload = {
        ...val,
        cluster_config_id: clusterConfigId,
        trigger_sample_cluster_creation: true
      }
      const res = await ClusterToolApiServices.createClusterConfig(payload);
      setConfigureLoading(false);
      history.push(appUrls.CLUSTER_TOOL(clientSpaceId));
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: shortenStr(msg, 100),
        placement: "bottomRight"
      });
    }
  }

  const selectAllFeatures = (getFieldValue, setFieldsValue, checked) => {
    const newFields = getFieldValue("salient_features").map((item) => {
      return {
        ...item,
        is_user_selected: checked
      }
    });
    setFieldsValue({salient_features: newFields});
  }

  useEffect(() => {
    getClusterConfig();
  }, [])

  return {
    loading,
    configureLoading,
    data,
    initialValues,
    formRef,
    generateCluster,
    selectAllFeatures,
    goBack,
    recommendedCount,
    corelationDrawer,
    setCorelationDrawer,
    clusterConfigId,
    autoSelection,
    setAutoSelection,
    clientSpaceId,
    viewMode
  }
}