import { EditOutlined } from '@ant-design/icons';
import { Button, Input, List, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatNumberWithCeil } from 'src/lib/utils';
import './topFeatureIndexView.style.less';

const TopFeatureIndexView = ({ data, behaviourFeaturesCategories, replaceFunction }) => {
  const [categories, setCategories] = useState(data);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setCategories(data);
  }, [data]);

  const handleDelete = (category) => {
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const handleReplace = (newLeafNode) => {
    replaceFunction(newLeafNode, selectedCategory.id)
    setIsModalVisible(false);

  };

  const filteredCategories = behaviourFeaturesCategories.filter(item =>
    item.leaf_node.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="feature-list">
      {categories.sort((a, b) => a.order - b.order) // Sort categories by order
        .map((category, index) => (
          <div key={index} className="category">
            {category.job_state === "DONE" ? (
              <>
                <div className="category-header">
                  <span className="category-title">{category.parent_feature_name}</span>
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => handleDelete(category)}
                    className="button-delete"
                  />
                </div>
                <div className="features">
                  <div className="feature feature-header">
                    <span className="feature-name">Feature</span>
                    <span className="feature-pen">Pen</span>
                    <span className="feature-index">Index</span>
                  </div>
                  {category.features.slice(0, 5).map((feature, idx) => (
                    <div key={idx} className="feature">
                      <span className="feature-name">{feature.feature_readable_name}</span>
                      <span className="feature-pen">{formatNumberWithCeil(feature.audience_feature_percentage)}%</span>
                      <span className="feature-index">{Math.round(feature.index_value)}</span>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="loading-spinner-container">
                <Spin />
                <span>Feature Profiling Under progress</span>
              </div>
            )}
          </div>
        ))}
      <Modal
        title="Select a Category to Replace With"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }}
      >
        <Input
          placeholder="Search categories"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <List
          className="category-list"
          dataSource={filteredCategories}
          renderItem={(item) => (
            <List.Item
              className="category-item"
              onClick={() => handleReplace(item)}
              style={{ borderBottom: 'none' }}
            >
              {item.leaf_node}
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default TopFeatureIndexView;