import React, { useEffect, useState } from "react";
import ConditionalRender from "src/components/conditionalRender";
import Assets from "src/assets";
import Style from "./homeClientCard.style";
import { Link } from "react-router-dom";

const HomeClientCard = ({ data, linkUrl, internalApp, showOwner }) => {
  const [clientAssetName, setClientAssetName] = useState('');
  const [clientName, setClientName] = useState('');

  useEffect(()=>{
    let name = data?.name ?? '';
    name = name.replace('&', '');
    name = name.replace(' ', '');
    setClientAssetName(name);
    setClientName(name);
  }, [data])

  return (
    <Link to={linkUrl} className={Style.homeCardContainer}>
      <div className={Style.imageContainer}>
        <ConditionalRender shouldRender={data?.icon}>
          <img className={Style.clientLogo} alt="Client App Icon" src={data?.black_white_icon || data?.icon} />
        </ConditionalRender>
        <ConditionalRender shouldRender={!data?.icon}>
          {Assets.getImage(`HomeClientLogo${clientAssetName}`) ? (
            <img className={Style.clientLogo} width="100%" height="100%" alt="Client App Icon" src={Assets.getImage(`HomeClientLogo${clientAssetName}`)} />
          ) : (
            <p>
              {clientName}
            </p>
          )}
        </ConditionalRender>
      </div>
    </Link>
  );
};

export default HomeClientCard;