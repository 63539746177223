import { notification } from "antd";
import { useEffect, useState } from "react";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { GEOGRAPHIC } from "../../constants/audienceDetailsConstants";
import audienceListService from "../../services/audienceListService";

const useGeographicHook = ({ audienceId }) => {

    const [geographicLoading, setGeographicLoading] = useState(false);
    const [dmaData, setDmaData] = useState(null);
    const [geographicData, setGeographicData] = useState(null);

    const fetchGeographicData = async () => {
        try {
            const audienceData = await audienceListService.getAudienceFeatureProfiles(audienceId, "", "", 1, GEOGRAPHIC, undefined, 10000);
            setGeographicData(audienceData.data.data);
            setGeographicLoading(false);
        } catch (error) {
            setGeographicLoading(false);
            const { msg } = getErrorMessageFromResponse(error);
            notification.error({
                duration: 5,
                message: msg,
                placement: "bottomRight",
            });
        }
    }

    const getDmaData = async () => {
        try {
            const response = await fetch('/dma.json');
            const data = await response.json();
            setDmaData(data);
        } catch (error) {
            notification.error({
                duration: 5,
                message: 'Failed to fetch DMA data',
                placement: 'bottomRight',
            });
        }
    };

    useEffect(() => {
        setGeographicLoading(true);
        getDmaData();
        fetchGeographicData();
    }, [audienceId]);

    return {
        geographicLoading,
        geographicData,
        dmaData
    }

}
export default useGeographicHook;
