import { css } from '@linaria/core';

const tabContainer = css`
  padding-right:20px;
  .ant-tabs-tab-btn {
    font-weight: 600;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    opacity: 0.6;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
  }
`;

export default {
  tabContainer
}