import { css } from "@linaria/core";

export const textareaContainer = css`
  position: relative;
  width: 100%;
`;

export const textareaInput = css`
  background-color: var(--color-80-black);
  color: var(--color-white);
  padding: 8px;
  border: 1px solid var(--color-box-shadow);
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5715;
  resize: vertical;
`;

export const suggestionsContainer = css`
  position: fixed;
  z-index: 1000;
  background-color: var(--color-90-black);
  border: 1px solid var(--color-70-black);
  border-radius: 6px;
  min-width: 200px;
  max-width: 300px;
  overflow: hidden; /* Hide overflow to remove scrollbar */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transform: translateY(-8px);
  transition: opacity 0.2s ease, transform 0.2s ease;
  margin-top: 0; /* Remove margin-top since positioning is fixed */

  /* Ensure container appears immediately when needed */
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const suggestionItem = css`
  padding: 8px 12px;
  cursor: pointer;
  color: var(--color-white);
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.15s ease;
  user-select: none;

  &:hover {
    background-color: var(--color-70-black);
  }

  &:active {
    background-color: var(--color-60-black);
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
