import React from 'react';
import './audienceOverview.style.less';
import TraitsView from './TraitsView';

const AudienceOverview = ({
    audienceTrait,
    getFeatureData,
    audienceFeatures,
    setSelectedFeature,
    selectedFeature,
    currentPage,
    setCurrentPage,
    setFeatureSortBy,
    featureSortBy,
    featureListTotalCount,
    isFeatureLoading,
    overViewFeatureType,
    setOverViewFeatureType,
    handleSearch,
    searchFeatureLoading
}) => {

    return (
        <TraitsView data={audienceTrait}
            getFeatureData={getFeatureData}
            audienceFeatures={audienceFeatures}
            setSelectedFeature={setSelectedFeature}
            selectedFeature={selectedFeature}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setFeatureSortBy={setFeatureSortBy}
            featureSortBy={featureSortBy}
            featureListTotalCount={featureListTotalCount}
            isFeatureLoading={isFeatureLoading}
            overViewFeatureType={overViewFeatureType}
            setOverViewFeatureType={setOverViewFeatureType}
            handleSearch={handleSearch}
            searchFeatureLoading={searchFeatureLoading}
        />

    );
};

export default AudienceOverview;
