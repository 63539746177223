import { css } from "@linaria/core";

const assistantIconPickerModal = css`
  width:100vw !important;
  height:100vh !important;
  max-width:none !important;
  margin:0 !important;
  top:0 !important;
  padding-bottom:0 !important;

  @media screen and (min-width: 768px) {
    width:auto !important;
    max-width: 590px !important;
    height:fit-content !important;
    max-height:90vh !important;
    margin:0 auto !important;
    top: 5vh !important;
    padding-bottom:5vh !important;
  }
  
  .ant-input {
    background:transparent !important;
    margin-right: 44px !important;
    width:auto;
  }
`;

const assistantIconPickerContent = css`
  width:100%;
  display: flex;
  flex-direction: column;
  overflow-y : auto;
  gap: 40px;
  height: calc(100vh - 200px);
  margin-top:24px;
  color:var(--color-white);
  @media screen and (min-width: 768px) {
    height: calc(90vh - 200px);
  }
`;

const assistantPickerButton = css`
  cursor: pointer;
  border-radius: 20px;
  background: var(--color-charcoal-surface-2);
  color:var(--color-gale-gray);
  padding: 8px 16px;
  margin-bottom: 16px;
  width:fit-content;
  transition: all 0.2s ease-in;

  &:hover {
    background: var(--color-70-black);
  }
`;

const plusIcon = css`
  margin-left: 4px;
`;
const iconGroupContainer = css`
  display:flex;
  flex-direction: column;
  gap:16px;

  &:last-child {
    margin-bottom: 24px;
  }
`;

const iconContainer = css`
  display:flex;
  gap:16px;
  flex-wrap: wrap;
`;

const iconChoiceBtn = css`
  cursor:pointer;
  background: transparent;
  padding:0;
  border-radius:2px;
  border:1px solid transparent;
  transition: all 0.2s ease-in;

  &:hover,
  &:focus,
  &.selected {
    border:1px solid var(--color-primary);
  }
`;


export default {
  assistantPickerButton,
  plusIcon,
  assistantIconPickerModal,
  assistantIconPickerContent,
  iconContainer,
  iconGroupContainer,
  iconChoiceBtn
}