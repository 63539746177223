import { useEffect, useState } from 'react';
import CustomGptApiServices from '../services/customGptApiServices';
import { notification } from 'antd';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { CustomGptService } from '../services/customGptServices';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const useConversations = ({ id }) => {
  const defaultJiraStatus = [
      'backlog',
      'selected_for_development',
      'in_progress',
      'invalidate/_duplicate',
      'blocked',
      'reopened',
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackData, setFeedbackData] = useState({});
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [feedbackCategory, setFeedbackCategory] = useState([]);
  const [feedbackType, setFeedbackType] = useState("DISLIKED");
  const [resolvedBy, setResolvedBy] = useState(defaultJiraStatus);
  const [inputValue, setInputValue] = useState(null);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isFeedbackChatLoading, setIsFeedbackChatLoading] = useState(false);
  const [feedbackSessionData, setFeedbackSessionData] = useState({})
  const [viewBy, setViewBy] = useState('By Feedback');
  const [feedbackFormModal, setFeedBackFormModal] = useState(false);
  const [feedbackFormData, setFeedBackFormData] = useState(null);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [appId, setAppId] = useState(null);
  const [firstCardIndex, setFirstCardIndex] = useState(-1);

  const location = useLocation();
  const [feedbackId, setFeedbackId] = useState(null);
  const [showClear, setShowClear] = useState(false);

  const params = useLocation();

  const history = useHistory();

  useEffect(() => {
    if(params) {
      setFeedbackId(params?.search?.split("=")[1]);
    }
    if(params?.search) {
      setShowClear(true);
    }else{
      setShowClear(false);
    }
  },[params]);

  const getAppId = (pathname) => {
    const regex = /\/custom\/([a-zA-Z0-9-]+)/;
    const match = pathname.match(regex);
    return match ? match[1] : null;
  };

  const applicationId = getAppId(location.pathname);

  useEffect(() => {
    setAppId(applicationId)
  }, [applicationId])

  const handleViewByChange = e => {
    setViewBy(e.target.value);
  };

  const filterHandler = (value, option, key) => {
    if (key === 'User') {
      setSelectedUsers(option?.map((opt) => opt.value));
    }
    if (key === 'Category') {
      setFeedbackCategory(option?.map((opt) => opt.value));
    }
    if (key === 'Type') {
      setCurrentPage(1);
      setFeedbackType(value);
    }
    if (key === 'Status') {
      setResolvedBy(value);
    }
  };

  const navigateWithoutFeedbackId = () => {
    const url = window.location.href;
    let newUrl = url.split('?')[0];
    let newPath = new URL(newUrl).pathname;
    history.push(newPath);
  };

  const resetFilter = () => {
    setShowClear(false);
    navigateWithoutFeedbackId();
  }

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleClick = (chatId) => {
    setIsFeedbackChatLoading(true)
    getFeedbackByChatId(chatId, 10, 10);
  };

  const getFeedbackByChatId = async (chatId, prev, next) => {
    try {
      setIsFeedbackChatLoading(true);
      const res = await CustomGptApiServices.getFeedbackByChatId(
        id,
        chatId,
        prev,
        next
      );
      if (res.success) {
        setIsFeedbackChatLoading(false);
        setFeedbackSessionData(res);
      }
    } catch (error) {
      setIsFeedbackChatLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const handleResolve = (id, feedbackComment) => {
    const payload = {
      feedback_id: id,
      resolution_status: 'RESOLVED',
      resolution_comment: feedbackComment,
    };
    resolveFeedback(payload);
  };

  const resolveFeedback = async (payload) => {
    try {
      setIsLoading(true);
      const res = await CustomGptApiServices.resolveFeedback(id, payload);
      if (res.success) {
        setIsLoading(true);
        getFeedbackList();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  const openFeedBackFormModal = (question, feedback, categories, id, e) => {
    e.stopPropagation();
    const obj = {
      question: question,
      category: CustomGptService.formatCategories(categories),
      feedback: feedback,
      id: id
    };
    setFeedBackFormData(obj);
    setFeedBackFormModal(true);
  };

  const closeFeedBackmodal = () => {
    setFeedBackFormModal(false);
    setFeedBackFormData(null);
  };

  const handleDatePicker = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const startDate = dates[0]?.toDate();
      const endDate = dates[1]?.toDate();

      const formattedStartDate = startDate.toISOString();
      const formattedEndDate = endDate.toISOString();

      setStartDateValue(formattedStartDate);
      setEndDateValue(formattedEndDate);
    }
  };

  const getFeedbackList = async (page) => {
    try {
      setIsLoading(true);
      const payload = {
        filters: buildFilters(),
      };
      const res = await CustomGptApiServices.getFeedbackList(id, payload, page === undefined ? 1 : page);
      setFeedbackData(res.data);
      setTotalItems(res.data?.total);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: "bottomRight"
      });
    }
  };

  useEffect(() => {
    if (!isLoading && feedbackData && feedbackData.data && feedbackData.data.length > 0) {
      setFirstCardIndex(0);
    }
  }, [isLoading, feedbackData]);

  const buildFilters = () => {
    const filters = {
      user_ids: selectedUsers,
      feedback_from_date: startDateValue,
      feedback_to_date: endDateValue,
      feedback_type: feedbackType,
      feedback_categories: feedbackCategory,
      jira_statuses: resolvedBy,
      search_term: inputValue,
    };
    if (feedbackId !== null && showClear) {
      filters.feedback_id = feedbackId;
    }
    return filters;
  };

  useEffect(() => {
    if ((feedbackId !== null && feedbackId !== undefined) || (feedbackId === null && !params.search && feedbackId === undefined) || showClear) {
      getFeedbackList(currentPage);
    } else if(feedbackId === undefined && !showClear && feedbackId !== null) {
      getFeedbackList(currentPage);
    }
  }, [ selectedUsers, feedbackCategory, feedbackType, resolvedBy, inputValue, startDateValue, endDateValue, feedbackId, showClear]);

  const userOptionsHandler = (data) => {
    return data?.user_data?.map((iter) => {
      let label = '';

      if (iter.first_name !== null && iter.first_name !== undefined) {
        label += iter.first_name;

        if (iter.last_name !== null && iter.last_name !== undefined) {
          label += ' ' + iter.last_name;
        }
      } else if (iter.email !== null && iter.email !== undefined) {
        label = iter.email;
      }

      return { label: label || '', value: iter.id };
    });
  };

  useEffect(() => {
    if (feedbackData) {
      const data = userOptionsHandler(feedbackData);
      if (data !== undefined && data.length) {
        setUsers(data);
      }
    }
  }, [feedbackData]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getFeedbackList(page);
  };

  return {
    isLoading,
    feedbackData,
    users,
    filterHandler,
    handleInput,
    handleDatePicker,
    currentPage,
    totalItems,
    handlePageChange,
    handleClick,
    handleResolve,
    setIsFeedbackChatLoading,
    isFeedbackChatLoading,
    feedbackSessionData,
    handleViewByChange,
    viewBy,
    appId,
    openFeedBackFormModal,
    closeFeedBackmodal,
    feedbackFormModal,
    feedbackFormData,
    firstCardIndex,
    defaultJiraStatus,
    showClear,
    resetFilter
  };
};
