import React from "react";
import { Route, Switch } from "react-router-dom";
import appUrls from "src/constants/appUrls";
import CreateLabelingTask from "./pages/CreateLabelingTask";
import Dashboard from "./pages/Dashboard";
import TaskDetailView from "./pages/TaskDetailView";

const DataLabelerLayout = () => {
  return (
    <Switch>
      <Route exact path={appUrls.LABELER(":clientId")} component={Dashboard} />
      <Route
        exact
        path={`${appUrls.LABELER(":clientId")}/create`}
        component={CreateLabelingTask}
      />
      <Route
        exact
        path={`${appUrls.LABELER(":clientId")}/:taskId`}
        component={TaskDetailView}
      />
    </Switch>
  );
};

export default DataLabelerLayout;
