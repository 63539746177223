import { css } from '@linaria/core';

const featureList = css`
  max-height: 130px;
  overflow-y: auto;
`;

const detailsContainer = css`
  margin-left: 1rem;
`;

const clusterContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default {
  featureList,
  detailsContainer,
  clusterContainer
}