/* eslint-disable */
import { OktaAuth } from "@okta/okta-auth-js";

const HOST = window.location.origin;
const OKTA_REDIRECT_URI = `${HOST}/okta-callback`;
const CLIENT_ID = REACT_APP_OKTA_CLIENT_ID;
const OKTA_DOMAIN = REACT_APP_OKTA_DOMAIN;
const ISSUER = `https://${OKTA_DOMAIN}/`;
// Should be false in production
const OKTA_TESTING_DISABLE_HTTPS_CHECK =
  process.env.NODE_ENV === 'development' ? true : false;

const OKTA_CONFIG = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: OKTA_REDIRECT_URI,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLE_HTTPS_CHECK
};

export const oktaAuthInstance = new OktaAuth(OKTA_CONFIG);