import { css } from "@linaria/core";

const columnContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const headers = css`
  color: gray;
  font-weight: 600;
`;

const addRowContainer = css`
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
`;

const formContainer = css`
  display: flex;
  flex-direction: column;
`;

const body = css`
  flex-grow: 1;
`;

export default {
  columnContainer,
  headers,
  addRowContainer,
  formContainer,
  body
};
