import { css } from "@linaria/core";

const removeFeedback = css`
  &.ant-btn-default.ant-btn-default {
    background: none;
    border: none;
    padding-left: 5px !important;
    color: white !important;
    &:hover {
      background: none;
      border: none;
      color: #fc3b3b !important;
    }
  }
`;


export default {
  removeFeedback,
};
