import { css } from "@linaria/core";

const genericButton = css`
  &.ant-btn-default {
    border-radius: 8px;
    border: 0px !important;
    background: #db5a4b;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    height: 2.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: white !important;
    :hover {
      border: 0px;
      background: #db5a4b;
      color: white !important;
    }
    :focus {
      border: 0px;
      background: #db5a4b;
      color: white !important;
    }
  }
`;

const cardContainer = css`
  .ant-card-small > .ant-card-head  {
    background: var(--color-full-black);
    color: var(--color-gale-gray)
  }
    .ant-card-bordered {
    border: 0
    }
    .ant-card-small > .ant-card-body {
    background: var(--color-full-black);
    color: var(--color-gale-gray)
    }
`;

const btnsCOntainer = css`
  margin: 24px 0;
  gap:8px;
  width:100%;
  > button {
    width:48%;
  }
  @media screen and (min-width:432px) {
    > button {
      width:49%;
    }
  }  
  @media screen and (min-width:768px) {
    width: auto;
    > button {
      width:auto;
    }
  }
`

export default {
    genericButton,
    cardContainer,
    btnsCOntainer
}