import React from "react";
import hoverStyle from "./treeNodeHover.style";
import Style from "src/modules/ConversationContainer/aiApp.style";
import ConditionalRender from "src/components/conditionalRender";
import {
  CloseOutlined,
  ExpandOutlined,
  MinusOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Tabs } from "antd";
import ActionParser from "src/modules/ConversationContainer/conversations/components/ActionParser";
import MarkdownRender from "src/modules/ConversationContainer/MarkdownRender";
import AiLoader from "src/components/AiLoader";
import CaretIconGenerator from "src/components/CaretIconGenerator";
import CircleCheckIcon from "src/components/CircleCheckIcon";

const TreeNodeHover = ({
  data,
  analysisData = true,
  bestSolution,
  onExpand,
  onClose,
  onShrink,
  isModal = false,
}) => {
  if (!data) return null;
  const renderAnalysis = (data) => (
    <div
      className={`${Style.streamingResponseContainer} ${hoverStyle.hoverStreamingContainer}`}
    >
      {data.intermediate_steps?.map((item, idx) => {
        const totalLength = data.intermediate_steps.length;
        let latestActionValue = "";
        let action_input = item?.action_input;
        let action_output = item?.action_output;
        if (item.type === "ACTION") {
          latestActionValue = item.value;
          let loading = true;
          if (idx !== totalLength - 1) {
            loading = false;
          }
          return (
            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Collapse
                defaultActiveKey={analysisData ? undefined : ["1"]}
                className={'toolCollapse'}
                expandIcon={({ isActive }) => (
                  <ToolOutlined rotate={isActive ? 90 : 0} />
                )}
              >
                <Collapse.Panel
                  key="1"
                  header={latestActionValue}
                  extra={
                    analysisData ? (
                      <CircleCheckIcon size={18} color="var(--color-green)" />
                    ) : (
                      <>
                        <ConditionalRender shouldRender={loading}>
                          <div>
                            <AiLoader width={30} height={30} />
                          </div>
                        </ConditionalRender>
                        <ConditionalRender shouldRender={!loading}>
                          <CircleCheckIcon size={18} color="var(--color-green)" />
                        </ConditionalRender>
                      </>
                    )
                  }
                >
                  <ConditionalRender shouldRender={!!action_input}>
                    <div className={Style.inputLabel}>Input:</div>
                    <div className={Style.actionContainer}>
                      <ActionParser data={action_input} />
                    </div>
                  </ConditionalRender>
                  <ConditionalRender
                    shouldRender={
                      !!item?.action_output && item.value !== "Generating Chart"
                    }
                  >
                    <div className={Style.outputLabel}>Output:</div>
                    <div className={Style.actionContainer}>
                      <ActionParser
                        data={action_output}
                        actionName={item.value}
                      />
                    </div>
                  </ConditionalRender>
                </Collapse.Panel>
              </Collapse>
            </div>
          );
        }
        if (item.type === "LLM_OUTPUT" && analysisData) {
          return (
            <div className={Style.streamingResponseAccordianContainer}>
              <div className={Style.streamingResponseAccordianActionText}>
                <MarkdownRender children={item.value} />
              </div>
            </div>
          );
        }
      })}
    </div>
  );

  const renderNodeData = (data) => (
    <div className={`${hoverStyle.nodeContent} hover-component-modal`}>
      <h3>Steps Taken:</h3>
      {renderAnalysis(data)}
      <h3>AI Self-Assessment:</h3>
      <MarkdownRender children={data.reflection?.reflections} />
      <h5>
        <b>Score: {Math.round(data.value * 1000) / 1000}</b>
      </h5>
    </div>
  );

  const renderBestSolutionData = (data) => (
    <div className={`${hoverStyle.nodeContent} hover-component-modal`}>
      {data.map((item, idx) => {
        return (
          <div>
            {idx !== 0 && <hr style={{width: "95%", opacity: 0.3}}/>}
            <h5>{item.name}</h5>
            {renderAnalysis(item)}
          </div>
        );
      })}
    </div>
  );

  return (
    <div
      className={`${hoverStyle.hoverComponent} hover-component-modal-container`}
    >
      <div className={hoverStyle.nodeHeader}>
        <div className={hoverStyle.nodeActions}>
          {isModal ? (
            <Button icon={<MinusOutlined />} onClick={() => onShrink()} />
          ) : (
            <Button icon={<ExpandOutlined />} onClick={() => onExpand()} />
          )}
          <Button icon={<CloseOutlined />} onClick={() => onClose()} />
        </div>
        <Tabs
          className={hoverStyle.tabs}
          defaultActiveKey="1"
          items={[
            {
              label: data.name,
              key: "1",
              children: renderNodeData(data),
            },
            {
              label: "Final Answer",
              key: "2",
              children: bestSolution && renderBestSolutionData(bestSolution),
              disabled: bestSolution ? false : true,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TreeNodeHover;
