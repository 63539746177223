import React from 'react';
import {
  Form,
  Input,
  Button,
  Card,
  Select,
  Row,
  Col,
  Spin,
  Tooltip,
  Divider,
  Radio,
  DatePicker,
  InputNumber,
} from "antd";
import { LeftOutlined, PlusOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Style from '../NewsAndCulture.style';
import useCreateListing from '../hooks/useCreateListing';
import NcBreadCrumb from '../components/NcBreadCrumb';
import { rangePresets, scheduleIntervals } from '../constants';
import DateRangePickerWithPresets from '../components/RangePicker';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import CreateAnalysisQuery from '../components/CreateAnalysisQuery';
import { LuceneQueryComponent } from '../components/CreateListingComponents/lucentQueryRowItem';

const { TextArea } = Input;
const { Option } = Select;

const CreateListingScreen = () => {
  const {
    currentStep,
    sections,
    isFormComplete,
    showActionSteps,
    form,
    handleBack,
    addSection,
    removeSection,
    addNewsQuery,
    addFacebookQuery,
    addTwitterQuery,
    removeQuery,
    handleBlur,
    handleQueryBlur,
    validateDomain,
    mode,
    analysisCategories,
    buttonLoading,
    saveDraftLoading,
    pageLoading,
    handleSectionNameBlur,
    onFinish,
    onSaveDraft,
    onRangeChange,
    setCurrentStep,
    initialAnalysisData,
    onRadioChange,
    schedule,
    disabledDate,
    isScheduled,
    drawerVisible,
    setDrawerVisible,
    setQueryData,
    queryData,
    addUpdateQuery,
    addTiktokQuery,
    addInstagramQuery,
    addThreadsQuery,
    addUpdateQueryInstagram,
    instaFormData,
    setInstaFormData,
  } = useCreateListing();

  // const isViewOnly = mode === 'Done' && isScheduled === false;
  // making this true for time being
  const isViewOnly = initialAnalysisData ? (initialAnalysisData.can_edit ? false : true) : false;

  const UpdatedAnalysisCategories = analysisCategories.map(option => ({
    label: option.category,
    value: option.value
  }));

  return (
    <div className={Style.newsAndCultureContainer}>
      {pageLoading && (
        <div className={Style.pageLoaderContainer}>
          <Spin tip="Loading..." size="medium" />
        </div>
      )}
      <NcBreadCrumb
        className={Style.breadCrumbCreateScreen}
        name={initialAnalysisData?.name || ""}
        screen={currentStep}
      />
      <div className={Style.formContainer}>
        <Card className={Style.formCard}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            disabled={pageLoading}
          >
            {currentStep === 1 && (
              <>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input
                    onBlur={() => handleBlur("name")}
                    disabled={pageLoading || isViewOnly}
                  />
                </Form.Item>
                <Form.Item
                  label="Tell us more about the client?"
                  name="clientDetails"
                  rules={[{ required: false }]}
                >
                  <TextArea
                    rows={2}
                    onBlur={() => handleBlur("clientDetails")}
                    disabled={pageLoading || isViewOnly}
                  />
                </Form.Item>
                <Form.Item
                  label="Tell us more about the problem statement?"
                  name="problemStatement"
                  rules={[{ required: false }]}
                >
                  <TextArea
                    rows={2}
                    onBlur={() => handleBlur("problemStatement")}
                    disabled={pageLoading || isViewOnly}
                  />
                </Form.Item>
                {showActionSteps && (
                  <Form.Item
                    label="Tell us about your actionable steps"
                    name="actionSteps"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={2} disabled={pageLoading || isViewOnly} />
                  </Form.Item>
                )}
                <Form.Item>
                  {!isViewOnly ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={buttonLoading}
                    >
                      Save & Next
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => setCurrentStep(2)}
                      disabled={pageLoading}
                    >
                      Next
                    </Button>
                  )}
                </Form.Item>
              </>
            )}

            {currentStep === 2 && (
              <>
                <SecondaryButton
                  className={"small"}
                  onClick={handleBack}
                  disabled={pageLoading}
                  style={{ marginBottom: 16, padding: "6px 16px" }}
                >
                  <LeftOutlined
                    style={{ position: "relative", top: "1.5px" }}
                  />{" "}
                  Back
                </SecondaryButton>
                <p className={Style.staticText}>
                  <span style={{ fontWeight: 500, fontSize: "15px" }}>
                    Name:
                  </span>{" "}
                  {form.getFieldValue("name")}
                </p>
                {form.getFieldValue("clientDetails") && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: "15px" }}>
                      Client Details:
                    </span>{" "}
                    {form.getFieldValue("clientDetails")}
                  </p>
                )}
                {form.getFieldValue("problemStatement") && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: "15px" }}>
                      Problem Statement:
                    </span>{" "}
                    {form.getFieldValue("problemStatement")}
                  </p>
                )}
                {form.getFieldValue("actionSteps") && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: "15px" }}>
                      Action Steps:
                    </span>{" "}
                    {form.getFieldValue("actionSteps")}
                  </p>
                )}
                {sections.map((section, sectionIndex) => (
                  <>
                    <div key={section.key} className={Style.sectionContainer}>
                      {!isViewOnly && (
                        <div className={Style.sectionActions}>
                          {sectionIndex > 0 && (
                            <Tooltip title="Delete Query">
                              <DeleteOutlined
                                onClick={() => removeSection(section.sectionId)}
                                className="delete-btn"
                                disabled={pageLoading}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                      <Row className={Style.sectionSubParent}>
                        <div
                          style={{
                            fontWeight: 500,
                            width: "100%",
                            fontSize: "16px",
                          }}
                        >{`Section ${sectionIndex + 1}: `}</div>
                        <Row gutter={[8, 8]} className={Style.sectionHeader}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label="Section Name"
                              name={`sectionName_${sectionIndex}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input query name!",
                                },
                              ]}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Input
                                onBlur={handleSectionNameBlur}
                                disabled={pageLoading || isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label="Query Date"
                              name={`queryDate_${sectionIndex}`}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a date!",
                                },
                              ]}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <DateRangePickerWithPresets
                                format="YYYY-MM-DD"
                                className={Style.dateRangePicker}
                                presets={rangePresets}
                                onRangeChange={(dates) =>
                                  onRangeChange(sectionIndex, dates)
                                }
                                disabled={pageLoading || isViewOnly}
                                value={form.getFieldValue(
                                  `queryDate_${sectionIndex}`,
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                      <div className={Style.queryContainerParent}>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            News Queries:{" "}
                          </div>

                          <div className={Style.queryContainer}>
                            {section.newsQueries.length > 0 ? (
                              section.newsQueries.map((query, queryIndex) => (
                                <div key={query.key}>
                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                    queryType={'newsQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                  />

                                </div>
                              ))
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for News.{" "}
                                </p>
                              </div>
                            )}
                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  className={"small"}
                                  onClick={() => addNewsQuery(sectionIndex)}
                                  disabled={pageLoading}
                                >
                                  Add News Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            Facebook Queries:{" "}
                          </div>
                          <div className={Style.queryContainer}>

                            {section.facebookQueries.length > 0 ? (
                              section.facebookQueries.map(
                                (query, queryIndex) => (
                                <div key={query.key}>
                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                      queryType={'facebookQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                  />
                                </div>
                                ),
                              )
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for Facebook.{" "}
                                </p>
                              </div>
                            )}
                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  onClick={() => addFacebookQuery(sectionIndex)}
                                  className={"small"}
                                  disabled={pageLoading}
                                >
                                  Add Facebook Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            Twitter Queries:{" "}
                          </div>
                          <div className={Style.queryContainer}>

                            {section.twitterQueries.length > 0 ? (
                              section.twitterQueries.map(
                                (query, queryIndex) => (
                                <div key={query.key}>
                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                      queryType={'twitterQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                  />

                                </div>
                                ),
                              )
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for Twitter.{" "}
                                </p>
                              </div>
                            )}
                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  type="default"
                                  onClick={() => addTwitterQuery(sectionIndex)}
                                  className={"small"}
                                  disabled={pageLoading}
                                >
                                  Add Twitter Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            Tiktok Queries:{" "}
                          </div>

                          <div className={Style.queryContainer}>
                            {section?.tiktokQueries?.length > 0 ? (
                              section.tiktokQueries.map((query, queryIndex) => (
                                <div key={query.key}>

                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                    queryType={'tiktokQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                  />

                                </div>
                              ))
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for TikTok.{" "}
                                </p>
                              </div>
                            )}
                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  className={"small"}
                                  onClick={() => addTiktokQuery(sectionIndex)}
                                  disabled={pageLoading}
                                >
                                  Add TikTok Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            Threads Queries:{" "}
                          </div>
                          <div className={Style.queryContainer}>

                            {section?.threadsQueries?.length > 0 ? (
                              section?.threadsQueries?.map(
                                (query, queryIndex) => (
                                <div key={query.key}>
                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                    queryType={'threadsQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                  />
                                </div>
                                ),
                              )
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for Threads.{" "}
                                </p>
                              </div>
                            )}
                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  onClick={() => addThreadsQuery(sectionIndex)}
                                  className={"small"}
                                  disabled={pageLoading}
                                >
                                  Add Threads Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>
                            Instagram Queries:{" "}
                          </div>
                          <div className={Style.queryContainer}>

                            {section?.instagramQueries?.length > 0 ? (
                              section?.instagramQueries?.map(
                                (query, queryIndex) => (
                                <div key={query.key}>
                                  <LuceneQueryComponent
                                    queryText={query.lucene_query}
                                    queryIndex={queryIndex}
                                    setDrawerVisible={setDrawerVisible}
                                    query={query}
                                    setQueryData={setQueryData}
                                    removeQuery={removeQuery}
                                    sectionIndex={sectionIndex}
                                      queryType={'instagramQueries'}
                                    sectionId={section.sectionId}
                                    isViewOnly={isViewOnly}
                                    maxCount={query.max_count}
                                      setInstaFormData={setInstaFormData} />

                                </div>
                                ),
                              )
                            ) : (
                              <div
                                className={
                                  !isViewOnly
                                    ? `${Style.noQueryParent}`
                                    : `${Style.viewOnly}`
                                }
                              >
                                <p>
                                  No Queries added for Instagram.{" "}
                                </p>
                              </div>
                            )}

                            {!isViewOnly && (
                              <div className={Style.addbtnParent}>
                                <SecondaryButton
                                  type="default"
                                  onClick={() => addInstagramQuery(sectionIndex)}
                                  className={"small"}
                                  disabled={pageLoading}
                                >
                                  Add Instagram Query
                                  <PlusOutlined />
                                </SecondaryButton>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div >
                  </>
                ))}
                {!isViewOnly && (
                  <SecondaryButton style={{fontSize: "1rem"}} onClick={addSection} disabled={pageLoading}>
                    Add Section
                    <PlusOutlined />
                  </SecondaryButton>
                )}
                <div className={Style.customTagsContainer}>
                  {!isViewOnly && <h3>Custom Attributes</h3>}
                  <Form.List name="custom_tags">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Card key={field.key} className={Style.customTagCard}>
                            {!isViewOnly && (
                              <div className={Style.sectionActions}>
                                <Tooltip title="Remove Tag">
                                  <DeleteOutlined
                                    onClick={() => remove(field.name)}
                                    className="delete-btn"
                                    disabled={pageLoading}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  label="Attribute Name"
                                  name={[field.name, "tag_name"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Attribute name is required",
                                    },
                                  ]}
                                >
                                  <Input disabled={isViewOnly} />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  label="Attribute Description"
                                  name={[field.name, "tag_prompt"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Attribute description is required",
                                    },
                                  ]}
                                >
                                  <Input disabled={isViewOnly} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.List name={[field.name, "tag_categories"]}>
                              {(categories, { add: addCategory, remove: removeCategory }) => (
                                <>
                                  {categories.map((category, categoryIndex) => (
                                    <Card key={category.key} className={Style.categoryCard}>
                                      {!isViewOnly && (
                                        <div className={Style.sectionActions}>
                                          <Tooltip title="Remove Category">
                                            <DeleteOutlined
                                              onClick={() => removeCategory(category.name)}
                                              className="delete-btn"
                                              disabled={pageLoading}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                      <Row gutter={16}>
                                        <Col span={8}>
                                          <Form.Item
                                            {...category}
                                            label="Category Name"
                                            name={[category.name, "category_name"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Category name is required",
                                              },
                                            ]}
                                          >
                                            <Input disabled={isViewOnly} />
                                          </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                          <Form.Item
                                            {...category}
                                            label="Category Description"
                                            name={[
                                              category.name,
                                              "category_prompt",
                                            ]}
                                          >
                                            <Input disabled={isViewOnly} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Card>
                                  ))}
                                  {!isViewOnly && (
                                    <SecondaryButton
                                      type="default"
                                      onClick={() => addCategory()}
                                      icon={<PlusOutlined />}
                                      className={"small"}
                                      disabled={pageLoading}
                                    >
                                      Add Category
                                    </SecondaryButton>
                                  )}
                                </>
                              )}
                            </Form.List>
                          </Card>
                        ))}
                        {!isViewOnly && (
                          <SecondaryButton
                            type="default"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className={"small"}
                            disabled={pageLoading}
                          >
                            Add Custom Attribute
                          </SecondaryButton>
                        )}
                      </>
                    )}
                  </Form.List>
                </div>
                {!isViewOnly && (
                  <>
                    <div className={Style.scheduleContainer}>
                      <div className={Style.scheduleQs}>
                        <p>Do you want to schedule your analysis?</p>
                        <Form.Item name={"radioBtn"}>
                          <Radio.Group onChange={onRadioChange}>
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      {schedule === 1 && (
                        <div className={Style.scheduleOptionsContainer}>
                          <Form.Item
                            label="End Date:"
                            name="scheduleEndDate"
                            className={Style.scheduleFormRow}
                          >
                            <DatePicker
                              disabledDate={disabledDate}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="No. of hours between runs:"
                            name="scheduleInterval"
                            className={Style.scheduleFormRow}
                            // validate integer
                            rules={[
                              {
                                required: true,
                                message: "Please select cadence",
                              },
                              
                            ]}
                          >
                            <InputNumber placeholder="Enter integer" style={{ width: "100%" }} />
                            {/* <Select
                              maxTagCount={3}
                              options={scheduleIntervals}
                              placeholder="Select cadence"
                              style={{ width: "100%" }}
                            /> */}
                          </Form.Item>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!isViewOnly && (
                  <div className={Style.addSectionContainer}>
                    <Form.Item className={Style.addSectionButtons}>
                      <Row className={Style.addSectionButtonRow}>
                        <PrimaryButton
                          htmlType="button"
                          onClick={onSaveDraft}
                          disabled={!isFormComplete || pageLoading}
                          loading={saveDraftLoading}
                        >
                          {mode === "Edit" ? "Update Draft" : "Save Draft"}
                        </PrimaryButton>
                        <PrimaryButton
                          htmlType="submit"
                          onClick={() => form.submit()}
                          disabled={!isFormComplete || pageLoading}
                          loading={buttonLoading}
                        >
                          {"Run Analysis"}
                        </PrimaryButton>
                      </Row>
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      {drawerVisible && (
        <CreateAnalysisQuery
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          queryData={queryData}
          addUpdateQuery={addUpdateQuery}
          addUpdateQueryInstagram={addUpdateQueryInstagram}
          instaFormData={instaFormData}
          setInstaFormData={setInstaFormData}
          UpdatedAnalysisCategories={UpdatedAnalysisCategories}
        />
      )}
    </div>
  );
};

export default CreateListingScreen;
