import { useEffect, useState } from "react";
import NcApiServices from "../services/NcApiServices";
import { getErrorMessageFromResponse } from "src/lib/utils";
import { notification } from "antd";
import { getData } from "country-list";
import { message } from "antd";

const useQueryBuilderHook = ({ queryData }) => {
  const countries = getData().map((country) => ({
    value: country.code,
    label: country.name,
  }));

  let initialQuery = {
    combinator: "and",
    rules: [],
    id: "a6373d21-c5bd-4e80-bebb-54b362b2681c",
  };

  const [query, setQuery] = useState(initialQuery);
  const [loading, setLoading] = useState(false);
  const [numberOfPosts, setNumberOfPosts] = useState(null);

  useEffect(() => {
    if (queryData?.query?.json_query) {
      setQuery(queryData?.query?.json_query);
      setNumberOfPosts(queryData?.query?.max_count);
    }
  }, [queryData]);

  const handleQueryChange = (newQuery) => {
    setQuery((prev) => {
      return newQuery;
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify({
        query,
        max_count: numberOfPosts,
      }, null, 2));
      message.success("Query copied to clipboard");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      message.error("Failed to copy query");
    }
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const pastedQuery = JSON.parse(text);
      handleQueryChange(pastedQuery?.query);
      setNumberOfPosts(pastedQuery?.max_count);
      message.success("Query pasted successfully");
    } catch (err) {
      console.error("Failed to paste text: ", err);
      message.error("Failed to paste query. Make sure it's a valid JSON.");
    }
  };


  return {
    handleQueryChange,
    query,
    setLoading,
    loading,
    numberOfPosts,
    setNumberOfPosts,
    handleCopy,
    handlePaste,
  };
};

export default useQueryBuilderHook;
