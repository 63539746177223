import React from "react";
import useGoogleSheetCapabilityHook from "../hooks/useGoogleSheetCapabilityHook";
import Style from "./googleSheetCapability.style";
import GoogleSheetAdvancedSettings from "./GoogleSheetAdvancedSettings";
import ConditionalRender from "src/components/conditionalRender";
import { Checkbox, Col, Collapse, Form, Input, Modal, Row, Skeleton } from "antd";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import GoogleSheetModal from "./GoogleSheetModal";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";
import TextArea from "antd/lib/input/TextArea";
import StructuredData from "./StructuredData";
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style.js";

export default function GoogleSheetCapability({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { formRef, loading, configureCapability, initialValues, configureLoading, schemaModal, setSchemaModal } =
    useGoogleSheetCapabilityHook({
      id,
      aiAppData,
      configData,
      updateCustomApp,
      onClose,
      toolData,
      reload,
    });
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form} style={{display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 140px)'}}>
          <div style={{flexGrow: 1}}>
            <Form
              onFinish={(val) => {
                configureCapability(val);
              }}
              requiredMark={false}
              ref={formRef}
              initialValues={initialValues}
              layout="vertical"
            >
              <Form.List name="files">
                {(fields, { add, remove }) => {
                  return (
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.additional !== curValues.additional} noStyle>
                      {({ getFieldsValue }) => {
                        return (
                          <>
                            <div className={Style.addRowContainer} onClick={() => setSchemaModal(true)}>
                              <PlusCircleOutlined />
                              &nbsp;<span>Add google sheet</span>
                            </div>
                            <Modal
                              maskClosable={true}
                              destroyOnClose
                              open={schemaModal}
                              onCancel={() => setSchemaModal(false)}
                              footer={null}
                              width="550px"
                            >
                              <GoogleSheetModal add={add} id={id} setSchemaModal={setSchemaModal} files={getFieldsValue(["files"])} />
                            </Modal>
                            {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                              const files = getFieldsValue(["files"]);
                              const fileName = files?.files?.[idx]?.file_name;
                              return (
                                <div style={{ display: "flex", gap: "1rem" }}>
                                  <div>
                                    <Form.Item
                                      name={[name, "is_active"]}
                                      fieldKey={[fieldKey, "is_active"]}
                                      {...restField}
                                      valuePropName="checked"
                                    >
                                      <Checkbox style={{ marginTop: "10px" }} />
                                    </Form.Item>
                                  </div>
                                  <div style={{ flexGrow: "1" }}>
                                    <Collapse className={'drawerCollapse'}>
                                      <Collapse.Panel key="1" forceRender={true} header={`${fileName ? fileName : `New file ${idx + 1}`}`}>
                                        <Row gutter={[30, 0]}>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "file_name"]}
                                              fieldKey={[fieldKey, "file_name"]}
                                              label="File name"
                                              rules={CustomGptService.validations(
                                                { required: true, max_length_string: true },
                                                "File name",
                                                configData?.config?.files?.fields?.file_name
                                              )}
                                            >
                                              <Input disabled />
                                            </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "google_sheet_url"]}
                                              fieldKey={[fieldKey, "google_sheet_url"]}
                                              label="Schema"
                                              rules={CustomGptService.validations(
                                                { required: true, max_length_string: true },
                                                "Google sheet url",
                                                configData?.config?.files?.fields?.google_sheet_url
                                              )}
                                            >
                                              <Input disabled />
                                            </Form.Item>
                                          </Col>
                                          <Col span={24}>
                                            <Form.Item
                                              {...restField}
                                              name={[name, "file_description"]}
                                              fieldKey={[fieldKey, "file_description"]}
                                              label="Description"
                                              rules={CustomGptService.validations(
                                                {required: true, max_length_string: true },
                                                "Description",
                                                configData?.config?.files?.fields?.file_description
                                              )}
                                            >
                                              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <StructuredData name={name} configData={configData} />
                                      </Collapse.Panel>
                                    </Collapse>
                                  </div>
                                  <div>
                                    <DeleteOutlined className="delete-btn" style={{ marginTop: "12px" }} onClick={() => remove(name)} />
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      }}
                    </Form.Item>
                  );
                }}
              </Form.List>
              <GoogleSheetAdvancedSettings configData={configData} />
            </Form>
          </div>
          <Row justify="end">
            <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
              Save
            </SecondaryButton>
          </Row>
        </div>
      </ConditionalRender>
    </div>
  );
}
