import { Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { CustomGptService } from "src/modules/CustomGpt/services/customGptServices";

export default function SqlInjectionType({ configData }) {
  return (
    <>
      <Row gutter={[30, 0]}>
        <Col xs={24} lg={12}>
          <Form.Item 
            name="master_prompt_injection_type" 
            label="Master prompt injection type*"
            rules={CustomGptService.validations(
              { required: true, },
              "Master prompt injection type",
            )}
          >
            <Select style={{width:'100%'}}>
              {configData?.config?.master_prompt_injection_type?.choices?.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item shouldUpdate>
            {({getFieldValue}) => {
              const injectionType = getFieldValue('master_prompt_injection_type')
              if (injectionType === 'CUSTOM_DATA') {
                return (
                  <Form.Item 
                    name="master_prompt_injection_custom_data" 
                    label="Master prompt custom data*"
                    rules={CustomGptService.validations(
                      { required: true, max_length_string: true},
                      "Master prompt custom data",
                      configData?.config?.master_prompt_injection_custom_data
                    )}
                  >
                    <Input />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
