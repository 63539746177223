import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { RULE_BASED } from "../../constants/constants";
import { DataFeatureType, defaultBehaviourValues, initialQuery } from "../../constants/queryBuilderConstants";
import createAudienceService from "../../services/createAudienceService";

const useDataSourceTabLayout = ({ clientId, selectedAudienceData }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [fields, setFields] = useState({ demographic_features: [], behaviour_features: [] });
  const [rulesRecommendations, setRulesRecommendations] = useState({});
  const [ruleRecommendationLoading, setRuleRecommendationLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [browsedSelectedItems, setBrowsedSelectedItems] = useState([]);


  const [query, setQuery] = useState(initialQuery);

  const transformColumnsToFields = (columns) => {
    return columns.map((column) => {
      const field = {
        name: column.feature_name,
        label: column.feature_readable_name,
        category: column.category,
        source: column.source,
        format: column.format
      };

      if (column.feature_values.length > 0) {
        field.valueEditorType = "select";
        field.values = column.feature_values.map((value) => ({
          value: value.value,
          value_description: value.value_description,
        }));
      } else if (column.feature_type === DataFeatureType.NUMERIC) {
        field.valueEditorType = "number";
      } else if (column.feature_type === DataFeatureType.DATE) {
        field.valueEditorType = "date";
      } else {
        field.valueEditorType = "text";
      }

      return field;
    });
  };

  const transformBehaviourToFields = (fields, behaviourFields = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));

    const behaviourTransformedFields = behaviourFields
      .filter((behaviour) => !existingFieldNames.has(behaviour.behaviour_name))
      .map((behaviour) => ({
        name: behaviour.behaviour_name,
        label: behaviour.behaviour_name,
        category: "Behaviour",
        source: "TransUnion",
        valueEditorType: "select",
        values: defaultBehaviourValues,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const addSearchBehaviourFields = (fields, behaviourFeatureNames = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));
    const behaviourTransformedFields = behaviourFeatureNames
      .filter((behaviour) => !existingFieldNames.has(behaviour))
      .map((behaviour) => ({
        name: behaviour,
        label: behaviour,
        category: "Behaviour",
        source: "TransUnion",
        valueEditorType: "select",
        values: defaultBehaviourValues,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const transformRecommendedData = (recommendedData) => {
    const transformRule = (rule) => {
      if (rule.field) {
        return {
          ...rule,
        };
      } else if (rule.behaviour_name) {
        return {
          ...rule,
          field: rule.behaviour_name,
          field_readable_name: rule.behaviour_name,
          operator: "=",
          value:
            rule.operator === "NOT"
              ? { value: "0", value_description: "0" }
              : { value: "1", value_description: "1" },
        };
      } else if (rule.rules) {
        return {
          ...rule,
          rules: rule.rules.map(transformRule),
        };
      }
      return rule;
    };

    return {
      ...recommendedData,
      rules: recommendedData.rules.map(transformRule),
    };
  };

  const getData = async () => {
    try {
      setLoading(true);
      const metaDataRes = await createAudienceService.getAudienceMetadata(clientId);
      let columns = metaDataRes.data;
      const formattedFields = transformColumnsToFields(columns);
      const demographicFields = formattedFields.filter((field) => field.category !== "Behaviour");
      const behaviourFields = formattedFields.filter((field) => field.category === "Behaviour");
      const isRulesEmpty = !selectedAudienceData.rules || Object.keys(selectedAudienceData.rules).length === 0 || selectedAudienceData.rules.rules.length === 0;
      if (isRulesEmpty) {
        setFields({ demographic_features: demographicFields, behaviour_features: behaviourFields });
      } else {
        const extractBehaviourFields = (rules) => {
          return rules.flatMap((rule) => {
            if (rule.rules) {
              return extractBehaviourFields(rule.rules);
            }
            return rule.behaviour_name ? [{ behaviour_name: rule.behaviour_name, operator: rule.operator }] : [];
          });
        };

        const behaviourFieldsFromRules = extractBehaviourFields(selectedAudienceData.rules.rules);

        const updatedFields = transformBehaviourToFields({ demographic_features: demographicFields, behaviour_features: behaviourFields }, behaviourFieldsFromRules);
        setFields(updatedFields);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onTableChange = (val) => {
    setLoading(true);
    setSelectedTable(val);
    setQuery(initialQuery);
    setLoading(false);
  };

  const getAiRecommendedRules = async (aiPrompt) => {
    try {
      setRuleRecommendationLoading(true);
      const ruleRecommendation = await createAudienceService.getAudienceRecommendations(clientId, aiPrompt, RULE_BASED);

      const behaviourFields = ruleRecommendation.data.rules.flatMap((rule) =>
        (rule.rules || [rule])
          .filter((subRule) => subRule.behaviour_name)
          .map((subRule) => ({
            behaviour_name: subRule.behaviour_name,
            operator: subRule.operator,
          }))
      );

      const updatedFields = transformBehaviourToFields(fields, behaviourFields);
      setFields(updatedFields);
      const updatedRecommendations = transformRecommendedData(ruleRecommendation.data);
      setRulesRecommendations(updatedRecommendations);
      setRuleRecommendationLoading(false);
    } catch (e) {
      console.error(e);
      setRuleRecommendationLoading(false);
    }
  };

  const addFeaturesToFields = (selectedFields) => {
    if (selectedFields.length > 0) {
      const newUpdatedFields = addSearchBehaviourFields(fields, selectedFields);
      setFields(newUpdatedFields);
    }
  }

  const handleSearch = useCallback(
    debounce(async (value) => {
      setSearchLoading(true);
      try {
        const res = await createAudienceService.getAudienceFeature(clientId, value);
        setSearchResults(res.data);
        if (res.data?.behaviour_features && res.data.behaviour_features.length > 0) {
          const newUpdatedFields = addSearchBehaviourFields(fields, res.data.behaviour_features);
          setFields(newUpdatedFields);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
      setSearchLoading(false);
    }, 800),
    [clientId, fields]
  );

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    fields,
    query,
    setQuery,
    table,
    selectedTable,
    onTableChange,
    getAiRecommendedRules,
    rulesRecommendations,
    setRulesRecommendations,
    ruleRecommendationLoading,
    searchResults,
    setSearchResults,
    searchLoading,
    setSearchLoading,
    handleSearch,
    browsedSelectedItems,
    setBrowsedSelectedItems,
    addFeaturesToFields
  };
};

export default useDataSourceTabLayout;