import React from "react";
import useDownloadReports from "./useDownloadReports";
import { Col, Row } from "antd";
import { PrimaryButton } from "src/components/basic";

const DownloadReports = ({ clusterData }) => {
  // Your code here
  const { downloadFiles, loading, progress } = useDownloadReports({ clusterData });

  return (
    <div>
      <Row align="middle">
        <Col span="6">Aggregated Feature Reports</Col>
        <Col>
          <PrimaryButton
            loading={loading && progress === "AGGREGATED_FEATURE_REPORT"}
            disabled={loading && progress !== "AGGREGATED_FEATURE_REPORT"}
            onClick={() => downloadFiles("AGGREGATED_FEATURE_REPORT")}
            className="small"
          >
            Download
          </PrimaryButton>
        </Col>
      </Row>
      <Row align="middle" style={{ marginTop: "2rem" }}>
        <Col span="6">Model Files</Col>
        <Col>
          <PrimaryButton
            loading={loading && progress !== "AGGREGATED_FEATURE_REPORT"}
            disabled={loading && progress === "AGGREGATED_FEATURE_REPORT"}
            onClick={() => downloadFiles("MODEL_FILES")}
            className="small"
          >
            Download
          </PrimaryButton>
        </Col>
      </Row>
    </div>
  );
};

export default DownloadReports;
