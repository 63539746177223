import { Checkbox, Col, Row } from "antd";
import React from "react";
import useExploreDatabaseHook from "./useExploreDatabaseHook";
import Style from "./exploreDatabase.style";
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import ConditionalRender from "src/components/conditionalRender";
import LoadingSpinner from "src/components/loadingSpinner";
import CommonStyle from "../newSqlTool.style";

export default function ExploreDatabase({ selectedConnection, clientId, formRef, setExploreDataBaseDrawer }) {
  const { data, availableSchemaAndTable, onChangeSchemaTable, addTable, schema, loading } = useExploreDatabaseHook({
    selectedConnection,
    clientId,
    formRef,
    setExploreDataBaseDrawer,
  });
  return (
    <>
      <ConditionalRender shouldRender={loading}>
        <LoadingSpinner centered />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.container}>
          <div className={Style.body}>
            {Object?.keys(schema)?.map((item) => {
              const tables = schema[item];
              const tablesArr = Object.keys(tables);
              return (
                <>
                  <div className={Style.schemaLabel}>
                    <b>Schema: </b>
                    {item}
                  </div>
                  <div className={Style.tableContainer}>
                    <Checkbox.Group value={data?.[item] || []} style={{ width: "100%" }} onChange={(val) => onChangeSchemaTable(val, item)}>
                      <Row gutter={[16, 16]}>
                        {tablesArr.map((ele) => {
                          return (
                            <Col span={8}>
                              <div className={Style.column}>
                                <Checkbox disabled={availableSchemaAndTable?.[item]?.includes(ele)} value={ele}>
                                  <span style={{ color: "var(--color-gale-gray)" }}>{ele}</span>
                                </Checkbox>
                                <ul className={Style.columnsContainer}>
                                  {tables[ele]?.map((element) => {
                                    return (
                                      <li className={Style.columnItem}>
                                        <b>{element.name}: </b>
                                        <i>{element?.type ? element?.type?.split(" ")?.[0] : ""}</i>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  </div>
                </>
              );
            })}
          </div>
          <ConditionalRender shouldRender={Object?.keys(schema)?.length > 0}>
            <Row className={CommonStyle.topSaveBar} justify="end">
              <SecondaryButton
                onClick={() => {
                  addTable();
                }}
              >
                Add Table
              </SecondaryButton>
            </Row>
          </ConditionalRender>
        </div>
      </ConditionalRender>
    </>
  );
}
