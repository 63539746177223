import { Checkbox, Collapse, Form, Input, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CustomGptService } from "../../../services/customGptServices";
import Style from "./sqlCapabilit.style";

export default function SqlAdvancedSettings({configData}) {
  return (
    <div className={Style.advancedSettingContainer}>
      <Collapse  className={'drawerCollapse'}>
        <Collapse.Panel forceRender={true} key="1" header="Advanced settings">
          {/* <div className={Style.tableContainer}> */}
            <div>
              <span className={Style.advanceSettinglabel}>
              Sql name*
              </span>
              <Form.Item 
                name="sql_name"
                dependencies={['Panel']}
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Sql name",
                  configData?.config?.sql_name
                )}
              >
                <Input/>
              </Form.Item>
            </div>
            <div>
              <span className={Style.advanceSettinglabel}>
              Sql description*
              </span>
              <Form.Item 
                name="sql_description"
                dependencies={['Panel']}
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Sql description",
                  configData?.config?.sql_description
                )}
              >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            </div>
            <div>
              <span className={Style.advanceSettinglabel}>
                Max results per query*
              </span>
              <Form.Item 
                name="max_results_per_query"
                rules={CustomGptService.validations(
                  { required: true, max_length_number: true },
                  "Max results per query",
                  configData?.config?.max_results_per_query
                )}
              >
                <InputNumber style={{ width: '100%', borderRadius: '5px' }}/>
              </Form.Item>
            </div>
            <div>
              <span className={Style.advanceSettinglabel}>
              Schema fetch description*
              </span>
              <Form.Item 
                name="schema_fetch_description"
                dependencies={['Panel']}
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Schema fetch description",
                  configData?.config?.schema_fetch_description
                )}
              >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            </div>
            <div>
              <span className={Style.advanceSettinglabel}>
              Sql construct description*
              </span>
              <Form.Item 
                name="sql_construct_description"
                dependencies={['Panel']}
                rules={CustomGptService.validations(
                  { required: true, max_length_string: true },
                  "Sql construct description",
                  configData?.config?.sql_construct_description
                )}
              >
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Row align={'middle'} style={{gap:8}}>
              <span className={Style.advanceSettinglabel} style={{marginBottom:0}}>
              Sql allow system prompt injection*
              </span>
              <Form.Item 
                dependencies={['Panel']}
                valuePropName="checked" 
                name="sql_allow_system_prompt_injection"
                style={{marginBottom:0}}
              >
                <Checkbox />
              </Form.Item>
            </Row>
            <Row align={'middle'} style={{gap:8}}>
              <span className={Style.advanceSettinglabel} style={{marginBottom:0}}>
                Schema fetch allow system prompt injection*
              </span>
              <Form.Item 
                dependencies={['Panel']}
                valuePropName="checked"
                name="schema_fetch_allow_system_prompt_injection"
                style={{marginBottom:0}}
              >
                <Checkbox />
              </Form.Item>
            </Row>
            </div>
          {/* </div> */}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}