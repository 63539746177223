import { Checkbox, Col, Form, List, Modal, Row, Select } from "antd";
import React from "react";
import { PrimaryButton } from "src/components/basic";
import useClientSpaceAndPermissionsHook from "./useClientSpaceAndPermissionsHook";
import ConditionalRender from "src/components/conditionalRender";
import Style from "./clientSpaceUsersAndPermissions.style";
import LoadingSpinner from "src/components/loadingSpinner";
import AddUserForm from "./AddUserForm";
import { formatString } from "src/lib/utils";
import { DeleteOutlined } from "@ant-design/icons";

const ClientSpaceUserAndPermissions = ({ metaData, clientId }) => {
  const {
    laoding,
    selectedValues,
    setSelectedValues,
    userMapping,
    updateUserAccess,
    configureLoading,
    setUnsavedChanges,
    addUserModal,
    setAddUserModal,
    ownerRoleId,
    canMakeOwner,
    deleteUser,
    userEmail,
  } = useClientSpaceAndPermissionsHook({
    metaData,
    clientId,
  });
  if (laoding) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className={Style.container}>
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: "1rem" }}>
        <Col>
          <PrimaryButton className="small" comfy onClick={() => setAddUserModal(true)}>
            Add User
          </PrimaryButton>
        </Col>
      </Row>
      <Checkbox
        disabled={configureLoading}
        checked={selectedValues.assign_to_all_users}
        onChange={(e) => {
          updateUserAccess("assignAll", e.target.checked);
        }}
        style={{ marginBottom: "1rem" }}
      >
        Enable for all GALE users
      </Checkbox>
      <List
        className={Style.list}
        loading={configureLoading}
        dataSource={[...selectedValues?.users]}
        header={
          <div className={Style.listHeader}>
            <div style={{ width: "40%" }}>Email</div>
            <div style={{ width: "40%" }}>Name</div>
            <div style={{ width: "26%" }}>Role</div>
          </div>
        }
        renderItem={(item, idx) => {
          let name = "";
          if (userMapping[item?.email]?.first_name) {
            name = name + userMapping[item?.email]?.first_name;
          }
          if (userMapping[item?.email]?.last_name) {
            name = name + " " + userMapping[item?.email]?.last_name;
          }
          let isOwner = false;
          if (item?.role === ownerRoleId) {
            isOwner = true;
          }
          return (
            <div className={Style.listItem}>
              <div style={{ width: "37%" }}>{item?.email}</div>
              <div style={{ width: "37%" }}>{name}</div>
              <div style={{ width: "26%" }} className={Style.deleteContainer}>
                <ConditionalRender shouldRender={isOwner}>Client Owner</ConditionalRender>
                <ConditionalRender shouldRender={!isOwner}>
                  <Select
                    className="small"
                    value={item?.role}
                    style={{ width: "50%" }}
                    onChange={(val) => {
                      updateUserAccess("changeRole", { email: item?.email, role: val, id: item?.id });
                    }}
                  >
                    {metaData?.client_roles?.map((ele) => {
                      if (ele?.name !== "CLIENT_OWNER") {
                        return (
                          <Select.Option key={ele?.id} value={ele?.id}>
                            {formatString(ele?.name)}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </ConditionalRender>
                <div>
                  {isOwner || item?.email === userEmail ? "" : <DeleteOutlined onClick={() => deleteUser(item?.id)} />}
                </div>
              </div>
            </div>
          );
        }}
      />
      <Modal title="Add User" maskClosable={false} destroyOnClose open={addUserModal} onCancel={() => setAddUserModal(false)} footer={null}>
        <AddUserForm
          setUnsavedChanges={setUnsavedChanges}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          metaData={metaData}
          setAddUserModal={setAddUserModal}
          updateUserAccess={updateUserAccess}
        />
      </Modal>
    </div>
  );
};

export default ClientSpaceUserAndPermissions;
