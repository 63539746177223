import { Col, Form, Input, Row, Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { RoundedOutlinedButton, RoundedPrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import { CustomGptService } from 'src/modules/CustomGpt/services/customGptServices';
import useCodeInterpreterTool from '../hooks/useCodeInterpreterTool';
import Style from './codeInterpreterTool.style'
import CommonStyle from "src/modules/CustomGpt/components/capabilities.style.js";

export default function CodeInterpreterTool({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const { formRef, loading, configureCapability, initialValues, configureLoading } = useCodeInterpreterTool({
    id,
    aiAppData,
    configData,
    updateCustomApp,
    onClose,
    toolData,
    reload,
  });
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form}>
          <Form
            onFinish={(val) => {
              configureCapability(val);
            }}
            requiredMark={false}
            ref={formRef}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row gutter={[30, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="name"
                  label="Name*"
                  rules={CustomGptService.validations({ required: true, max_length_string: true }, "Name", configData?.config?.name)}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="description"
                  label="Description*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    "Description",
                    configData?.config?.description
                  )}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" className={CommonStyle.globalSave}>
              <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                Save
              </SecondaryButton>
            </Row>
          </Form>
        </div>
      </ConditionalRender>
    </div>
  )
}