import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Image, Spin, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { DATA_SOURCE_TYPE } from "../../constants";
import * as LabelerStyles from "../../css/labeler";
import LabelerApiService from "../../services/api";
import * as Styles from "./styles";

const { Title, Text } = Typography;

const ReviewForm = ({ formData, clientSpaceId }) => {
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const {
    name,
    description,
    dataSourceType,
    databaseConnection,
    googleSheetUrl,
    inputColumns,
    outputColumns,
  } = formData;

  const inputColumnsData =
    inputColumns?.map((col, index) => ({
      key: index,
      name: col.name,
      type: col.type,
      description: col.description || "-",
    })) || [];

  const outputColumnsData =
    outputColumns?.map((col, index) => ({
      key: index,
      name: col.name,
      description: col.description || "-",
    })) || [];

  const columnsTableColumns = [
    { title: "Column Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Description", dataIndex: "description", key: "description" },
  ];

  const formatPreviewData = (values) => ({
    name: values.name,
    description: values.description,
    data_source: {
      type: values.dataSourceType,
      ...(values.dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS
        ? { sheet_url: values.googleSheetUrl }
        : { sql_connection: parseInt(values.databaseConnection) }),
    },
    input_columns: values.inputColumns,
    output_columns: values.outputColumns,
  });

  const fetchPreview = async () => {
    try {
      setLoading(true);
      setError("");
      setPreviewData(null); // Clear previous data
      const formattedData = formatPreviewData(formData);
      const response = await LabelerApiService.getLabelingTaskPreview(
        clientSpaceId,
        formattedData
      );
      if (response.success) {
        setPreviewData(response.data);
      } else {
        setError(
          response.message ||
            "Failed to generate preview. Please check your data source and configurations."
        );
      }
    } catch (err) {
      setError(
        "An error occurred while generating the preview. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const renderCellContent = (text, columnKey) => {
    // Find the column configuration from input columns
    const columnConfig = inputColumns?.find((col) => col.name === columnKey);

    if (!columnConfig || text === undefined || text === null) return text;

    switch (columnConfig.type) {
      case "IMAGE":
        if (text?.includes("drive.google.com")) {
          return (
            <Typography.Link
              href={text}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Image
            </Typography.Link>
          );
        }
        return <Image src={text} alt={columnKey} width={100} />;

      case "URL":
        return (
          <Typography.Link
            href={text}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </Typography.Link>
        );

      case "DATE":
        return moment(text).format("YYYY-MM-DD");

      case "NUMBER":
        return Number(text).toLocaleString();

      default:
        return text;
    }
  };

  const previewColumns = previewData
    ? Object.keys(previewData[0]).map((key) => ({
        title: key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
        dataIndex: key,
        key: key,
        render: (text) => renderCellContent(text, key),
      }))
    : [];

  return (
    <div className={Styles.reviewContainer}>
      <Title level={3} className={Styles.reviewTitle}>
        Review
      </Title>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          Basic Information
        </Title>
        <div className={Styles.infoGrid}>
          <Text strong>Analysis Name:</Text>
          <Text>{name || "Not specified"}</Text>
          <Text strong>Description:</Text>
          <Text>{description || "Not specified"}</Text>
        </div>
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          Data Source
        </Title>
        <div className={Styles.infoGrid}>
          {dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS && (
            <>
              <Text strong>Google Sheet URL:</Text>
              {googleSheetUrl ? (
                <Typography.Link
                  href={googleSheetUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {googleSheetUrl}
                </Typography.Link>
              ) : (
                <Text>
                  No URL provided. Please specify your Google Sheet URL.
                </Text>
              )}
            </>
          )}
          {dataSourceType === DATA_SOURCE_TYPE.DATABASE && (
            <>
              <Text strong>Database Connection:</Text>
              <Text>{databaseConnection || "Not specified"}</Text>
            </>
          )}
        </div>
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          Input Columns
        </Title>
        <Table
          dataSource={inputColumnsData}
          columns={columnsTableColumns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          className={LabelerStyles.table}
          size="small"
        />
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          Output Columns
        </Title>
        <Table
          dataSource={outputColumnsData}
          columns={columnsTableColumns.filter((col) => col.key !== "type")}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          className={LabelerStyles.table}
          size="small"
        />
      </div>

      <div className={Styles.section}>
        <div className={Styles.sectionHeader}>
          <Title level={5} className={Styles.sectionTitle}>
            Preview Results
            <Tooltip title="Click 'Dry Run' to generate a preview of the results based on your current configuration.">
              <InfoCircleOutlined className={Styles.infoIcon} />
            </Tooltip>
          </Title>
          <Button
            type="primary"
            size="small"
            onClick={fetchPreview}
            loading={loading}
            className={Styles.previewButton}
            aria-label="Generate preview results"
          >
            Dry Run
          </Button>
        </div>
        {loading ? (
          <div className={Styles.loadingOverlay}>
            <Spin tip="Generating preview..." />
          </div>
        ) : error ? (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            className="low-precision-alert"
          />
        ) : previewData ? (
          <Table
            dataSource={previewData}
            columns={previewColumns}
            pagination={{ pageSize: 10 }}
            scroll={{ x: "max-content" }}
            className={LabelerStyles.table}
            size="small"
          />
        ) : (
          <div className={Styles.emptyPreview}>
            <Text>
              No preview data available. Click <strong>Dry Run</strong> to
              generate a preview.
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewForm;
