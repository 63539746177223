import React from "react";
import { Select } from "antd";
import ReactECharts from "echarts-for-react";
import useKnowledgeGraphHooks from "./knowledgeGraph.hooks";
import { ExpandOutlined, CloseSquareOutlined } from "@ant-design/icons";
import Style from "./knowledgeGraph.style";


const KnowledgeGraphComponent = ({
  nodes,
  links,
  categories,
  onNodeClick,
  onLinkClick,
  knowledgeGraphChanged,
}) => {
  const { option, onChartEvents, chartRef, setHighlightedNode, highlightedNode, setIsFullScreen, isFullScreen } =
    useKnowledgeGraphHooks({
      nodes,
      links,
      categories,
      onNodeClick,
      onLinkClick,
      knowledgeGraphChanged,
    });

  return (
    <div className={`${isFullScreen ? Style.fullScreen : ""}`}>
      <Select
        className={Style.selectFilter}
        showSearch={true}
        showArrow
        placeholder="Highlight On-Screen Node"
        value={highlightedNode?.id}
        allowClear
        onClear={() => setHighlightedNode(null)}
        onChange={(value) => (value ? setHighlightedNode({ id: value }) : setHighlightedNode(null))}
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        options={nodes?.map((elem) => ({ value: elem.id, label: elem.name }))}
      />
      <ReactECharts
        className={`${isFullScreen ? Style.fullScreenKnowledgeGraph : ""}`}
        option={option}
        style={{
          height: "595px",
          width: "100%",
          zIndex: 2,
          border: "solid 0.5px #ffffff2e",
          borderRadius: "10px",
          overflow: "hidden",
        }}
        onEvents={onChartEvents}
        ref={chartRef}
      />
      {isFullScreen ? (
        <CloseSquareOutlined className={Style.expandButton} onClick={() => setIsFullScreen(!isFullScreen)} />
      ) : (
        <ExpandOutlined className={Style.expandButton} onClick={() => setIsFullScreen(!isFullScreen)} />
      )}
    </div>
  );
}; 

export default React.memo(KnowledgeGraphComponent);
