import { css } from "@linaria/core";

const container = css`
  overflow-y: auto;
  overflow-x: hidden;
`;

const formContainer = css`
  max-width: 1400px;
`;

const advancedSettingHeader = css`
  color: white;
  margin-bottom: 1rem;
`;

const colorPicker = css`
  margin-top: 1rem;
  .react-colorful {
    height: 150px;
    width: 150px;
  }
`;

const createButton = css`
  background: #db5a4b !important;
  color: white !important;
  font-weight: 600 !important;
  border: 0 !important;
`;
const uploadIcon = css`
  & .ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
    border: 0 none;
    height: auto;
  }
`;

const labelParent = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const uploadLabel = css`
  margin-left: 2px;
  margin-right: 8px;
  color: #c5cbd2;
`;

const assistantIconInvisibleInput = css`
  pointer-events: none;
  max-height: 1px;
  margin-bottom: 0 !important;
  overflow: hidden;
  opacity: 0;
`;

const btnsContainer = css`
  margin: 24px 0;
  gap: 8px;

  > button {
    width: 48%;
  }

  @media screen and (min-width: 768px) {
    > button {
      width: auto;
    }
  }
`;

const conversationStarterContainer = css`
  display: flex;
  gap: 1rem;
  .anticon {
    margin-top: -1.2rem;
    color: red;
  }
`;

export default {
  container,
  formContainer,
  btnsContainer,
  advancedSettingHeader,
  colorPicker,
  createButton,
  uploadIcon,
  labelParent,
  uploadLabel,
  assistantIconInvisibleInput,
  conversationStarterContainer,
};
