import React, { useState, useEffect, useRef } from "react";
import { Slider } from "antd";
import moment from "moment";
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons';

export const DateSlider = ({
  minDate,
  maxDate,
  handleSliderChange,
  selectedDate,
}) => {
  const [playing, setPlaying] = useState(false);
  const [currentValue, setCurrentValue] = useState(moment(selectedDate).endOf("day").valueOf());
  const min = moment(minDate).startOf("day").valueOf();
  const max = moment(maxDate).endOf("day").valueOf();
  const daysInRange = moment(maxDate).endOf("day").diff(moment(minDate).startOf("day"), 'days');
  const minTimePerDay = 2000;
  const minimumDuration = 6000;
  const updateInterval = 50;

  const calculatedDuration = Math.max(daysInRange * minTimePerDay, minimumDuration);
  const totalDuration = max - min;
  const totalFrames = calculatedDuration / updateInterval;
  const increment = totalDuration / totalFrames;
  const animationRef = useRef(null);

  useEffect(() => {
    if (playing) {
      const animate = () => {
        setCurrentValue((prev) => {
          const nextValue = prev + increment;
          if (Math.round(nextValue) <= max) {
            handleSliderChange(moment.utc(nextValue).format("YYYY-MM-DD"));
            return nextValue;
          } else {
            setPlaying(false);
            setCurrentValue(max);
            return prev;
          }
        });

        animationRef.current = setTimeout(animate, updateInterval);
      };

      animate();

      return () => {
        clearTimeout(animationRef.current);
      };
    } else {
      clearTimeout(animationRef.current);
    }
  }, [playing, increment, updateInterval, max, handleSliderChange]);

  const handlePlayPause = () => {
    if (currentValue >= max) {
      setCurrentValue(min);
      handleSliderChange(moment.utc(min).format("YYYY-MM-DD"));
    }
    setPlaying((prev) => !prev);
  };

  const handleSliderChangeInternal = (value) => {
    setCurrentValue(value);
    handleSliderChange(moment.utc(value).endOf("day").format("YYYY-MM-DD"));
  };

  return (
    <div
      className="icon-wrapper"
      style={{ color: "white", position: "relative", display: 'flex', alignItems: 'center' }}
    >
      {playing ?
        <PauseCircleOutlined onClick={handlePlayPause} style={{ marginRight: 10, fontSize: 30 }} />
        :
        <PlayCircleOutlined onClick={handlePlayPause} style={{ marginRight: 10, fontSize: 30 }} />
      }

      <div>
        {moment.utc(minDate).format("YYYY-MM-DD")}
      </div>

      <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <Slider
          style={{ width: 200, maxWidth: "300px" }}
          min={min}
          max={max}
          value={currentValue}
          onChange={handleSliderChangeInternal}
          tooltip={{
            formatter: (val) => moment.utc(val).format("YYYY-MM-DD"),
          }}
        />
      </div>

      <div>
        {moment.utc(maxDate).format("YYYY-MM-DD")}
      </div>
    </div>
  );
};
