import React from "react";
import Style from "../../aiApp.style";
import ConditionalRender from "src/components/conditionalRender";
import { Collapse } from "antd";
import ActionParser from "./ActionParser";
import MarkdownRender from "../../MarkdownRender";
import TreeChart from "src/modules/treeChart/treeChart.component";
import AiLoader from "src/components/AiLoader";
import CaretIconGenerator from "src/components/CaretIconGenerator";
import CircleCheckIcon from "src/components/CircleCheckIcon";

export default function StreamingResponseOutput({ streamingData, analysisData = false, feedbackScreen = false }) {
  return (
    <div className={Style.streamingResponseContainer} id="analysisBar">
      {streamingData?.map((item, idx) => {
        const totalLength = streamingData.length;
        let latestActionValue = "";
        let action_input = item?.action_input;
        let action_output = item?.action_output;
        if (item.type === "ACTION") {
          latestActionValue = item.value;
          let loading = true;
          if (idx !== totalLength - 1) {
            loading = false;
          }
          return (
            // <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Collapse
                key={`strem-response-out-put-${idx}`}
                defaultActiveKey={analysisData ? undefined : ["1"]}
                className={'toolCollapse'}
                expandIcon={({ isActive }) => <CaretIconGenerator size={16} rotate={isActive ? 180 : 0} />}
              >
                <Collapse.Panel
                  key="1"
                  header={latestActionValue}
                  className="analysis-item"
                  extra={
                    analysisData ? (
                      <CircleCheckIcon size={18} color="var(--color-green)" />
                    ) : (
                      <>
                        <ConditionalRender shouldRender={loading}>
                          <AiLoader width={30} height={30}/>
                        </ConditionalRender>
                        <ConditionalRender shouldRender={!loading}>
                          <CircleCheckIcon size={18} color="var(--color-green)" />
                        </ConditionalRender>
                      </>
                    )
                  }
                >
                  <ConditionalRender shouldRender={!!action_input}>
                    <div className={Style.inputLabel}>Input:</div>
                    <div className={Style.actionContainer}>
                      <ActionParser data={action_input} />
                    </div>
                  </ConditionalRender>
                  <ConditionalRender
                    shouldRender={
                      !!item?.action_output && item.value !== "Generating Chart"
                    }
                  >
                    <div className={Style.outputLabel}>Output:</div>
                    <div className={Style.actionContainer}>
                      <ActionParser
                        data={action_output}
                        actionName={item.value}
                      />
                    </div>
                  </ConditionalRender>
                </Collapse.Panel>
              </Collapse>
            // </div>
          );
        }
        if (item.type === "TOOL_OUTPUT") {
          return (
            <div>
              <ActionParser data={item?.value} />
            </div>
          );
        }
        else if (item.type === "LLM_OUTPUT" && analysisData) {
          return (
            <div className={Style.streamingResponseAccordianContainer}>
              <div className={Style.streamingResponseAccordianActionText}>
                <MarkdownRender children={item.value}/>
              </div>
            </div>
          );
        }
        else if (item.type === "TREE") {
          return <TreeChart data={item.tree} bestSolution={item.best_solution} feedbackScreen={feedbackScreen}/>;
        }
      })}
    </div>
  );
}
