import React from "react";
import { Select, Row, Col, Card, Empty, Spin, DatePicker, Popover, Switch } from "antd";
import ReactEcharts from "echarts-for-react";
import useQuadrantChart from "./analysisQuadrants.hooks";
import Style from "./analysisQuadrants.style";
import { PrimaryButton } from "src/components/basic";
import FloatLabel from "../floatLabel/floatLabel.component";
import ArticlesTableDrawer from "../ArticlesTableDrawer";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import TrendLineChart from "../quadrantLineChart/quadrantLineChart.component";
const { RangePicker } = DatePicker;

const { Option } = Select;

const QuadrantChartComponent = ({ analysisOverview }) => {
  const {
    echartsInstance,
    options,
    chartData,
    loading,
    filterData,
    handleFilterChange,
    handleApply,
    chartEvents,
    highlightedPost,
    setHighlightedPost,
    minStartDate,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    handles,
    loadingHandlesOptions,
    colorBy,
    setColorBy,
    sizeBy,
    setSizeBy,
    filteredHandles,
    filterApplyTimestamp,
  } = useQuadrantChart(analysisOverview);

  return (
    <div className={Style.tabContent}>
      <Card className={Style.analysisCardView}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <div className={Style.summarizeButton}>
              <Col>
                <FloatLabel
                  label="Color By"
                  style={{ backgroundColor: "var(--color-charcoal-surface-2)" }}
                  value={colorBy}
                  helpText="Color Data by a parameter."
                >
                  <Select
                    style={{ width: "145px" }}
                    onChange={(val) => setColorBy(val)}
                    value={colorBy}
                    popupClassName={Style.selectDropdown}
                  >
                    <Select.Option value={"platform"}>Platform</Select.Option>
                    <Select.Option value={"section"}>Sections</Select.Option>
                    <Select.Option value={"post_sentiment"}>Post Sentiment</Select.Option>
                    <Select.Option value={"comment_sentiment"}>Comment Sentiment</Select.Option>
                    {filteredHandles && filteredHandles.length > 0 && (
                      <Select.Option value={"handles"}>Handles</Select.Option>
                    )}
                    {/* <Select.Option value={"comment_sentiment"}>Comment Sentiment</Select.Option> */}
                  </Select>
                </FloatLabel>
              </Col>
              <Col>
                <FloatLabel
                  label="Size By"
                  style={{ backgroundColor: "var(--color-charcoal-surface-2)" }}
                  value={sizeBy}
                  helpText="Size Data by a parameter."
                >
                  <Select
                    style={{ width: "145px" }}
                    onChange={(val) => setSizeBy(val)}
                    value={sizeBy}
                    popupClassName={Style.selectDropdown}
                  >
                    <Select.Option value={"comments"}>Comments</Select.Option>
                    <Select.Option value={"comments_normalized"}>Comments by Volume</Select.Option>
                  </Select>
                </FloatLabel>
              </Col>
              {/* <PrimaryButton onClick={() => setSummaryDrawer(true)}>Analyze Trends</PrimaryButton> */}
            </div>
          </Col>
        </Row>
        <Row gutter={[10, 10]} className={Style.knowledgeGraphFilters}>
          <Col span={4}>
            <Row gutter={[10, 10]}>
              <div className={Style.knowledgeGraphFiltersWrapper}>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: "2rem",
                    }}
                  >
                    <h3 style={{ fontWeight: "bold" }}>Filter by: </h3>
                  </div>
                </Col>
                <Col>
                  <FloatLabel
                    label="Volume"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={filterData.volume_metric}
                    helpText="Select volume category."
                  >
                    <Select
                      value={filterData.volume_metric}
                      placeholder="Select Volume"
                      className={Style.select}
                      onChange={(value) => handleFilterChange("volume_metric", value)}
                    >
                      <Option value="likes">Likes</Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Efficiency"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={filterData.efficiency_metric}
                    helpText="Select efficiency category."
                  >
                    <Select
                      value={filterData.efficiency_metric}
                      placeholder="Select Efficiency"
                      className={Style.select}
                      onChange={(value) => handleFilterChange("efficiency_metric", value)}
                    >
                      <Option value="comments">Comments</Option>
                      <Option value="shares">Shares</Option>
                      <Option value="reactions">Reactions</Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Platform"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={filterData.platforms}
                    helpText="Source platform of the data in the knowledge graph."
                  >
                    <Select
                      onChange={(value) => handleFilterChange("platforms", value)}
                      style={{ width: "100%" }}
                      value={filterData.platforms}
                      allowClear
                      mode="multiple"
                      maxTagCount={2}
                      maxTagPlaceholder={(omittedValues) => (
                        <Popover
                          color="black"
                          overlayClassName={Style.tagPopover}
                          content={
                            <div className={Style.tagWrapper}>
                              {omittedValues.map(({ label }) => (
                                <div className={Style.tagText}>
                                  {label}&nbsp;&nbsp;&nbsp;
                                  <CloseOutlined
                                    onClick={() => {
                                      handleFilterChange(
                                        "platforms",
                                        filterData.platforms.filter((elem) => elem !== label.toLowerCase()),
                                      );
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <span>+{omittedValues.length}</span>
                        </Popover>
                      )}
                      showArrow
                    >
                      <Select.Option value={"news"}>News</Select.Option>
                      <Select.Option value={"twitter"}>Twitter</Select.Option>
                      <Select.Option value={"facebook"}>Facebook</Select.Option>
                      <Select.Option value={"instagram"}>Instagram</Select.Option>
                      <Select.Option value={"threads"}>Threads</Select.Option>
                      <Select.Option value={"tiktok"}>Tiktok</Select.Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Section"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={filterData.analysis_section_config_ids || undefined}
                    helpText="Section where the post originated."
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      onChange={(value) => handleFilterChange("analysis_section_config_ids", value)}
                      style={{ width: "100%" }}
                      value={filterData.analysis_section_config_ids || undefined}
                      allowClear
                    >
                      {analysisOverview?.section_data?.map((section) => (
                        <Select.Option key={section?.section_id} value={section?.section_id}>
                          {section?.section_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Handles"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={filterData.handles}
                    helpText="Include only posts from these handles"
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      showArrow
                      onPopupScroll={handlePopupScrollHandle}
                      value={filterData.handles}
                      onChange={(value) => handleFilterChange("handles", value)}
                      onSearch={handleSearchHandle}
                      maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <Popover
                          color="black"
                          overlayClassName={Style.tagPopover}
                          content={
                            <div className={Style.tagWrapper}>
                              {omittedValues.map(({ label }) => (
                                <div className={Style.tagText}>
                                  {label}&nbsp;&nbsp;&nbsp;
                                  <CloseOutlined
                                    onClick={() => {
                                      handleFilterChange(
                                        "handles",
                                        filterData?.handles.filter((elem) => elem !== label),
                                      );
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <span>+{omittedValues.length}</span>
                        </Popover>
                      )}
                      allowClear
                      onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                      notFoundContent={<></>}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {handles.length === 0 && !loadingHandlesOptions ? (
                              <Empty description="No data found" style={{ padding: "0.75rem 0" }} />
                            ) : (
                              <>
                                {menu}
                                {loadingHandlesOptions && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      padding: "8px",
                                    }}
                                  >
                                    <Spin size="small" />
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        );
                      }}
                    >
                      {handles?.map((elem, index) => {
                        return (
                          <Select.Option key={index} value={elem}>
                            {elem}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Date Range"
                    style={{ backgroundColor: "var(--color-charcoal-surface-1)" }}
                    value={[moment(filterData?.start_date), moment(filterData?.end_date)]}
                  >
                    <RangePicker
                      onChange={(value) => handleFilterChange("date_range", value)}
                      value={[moment(filterData?.start_date), moment(filterData?.end_date)]}
                      style={{ width: "100%" }}
                      allowClear={false}
                      disabledDate={(val) => {
                        if (val?.isBefore(moment(minStartDate).startOf("d"))) {
                          return true;
                        }
                        if (val?.isAfter(moment().endOf("d"))) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </FloatLabel>
                </Col>
                <Col>
                  Normalize data{" "}
                  <Popover
                    content={
                      "Normalize efficiency metric by dividing it by volume (Normalized Efficiency = Efficiency / Volume). This approach enhances the impact of posts with lower volume but relatively high engagement."
                    }
                    overlayStyle={{ maxWidth: "250px", fontSize: "11px", zIndex: 9999 }}
                  >
                    &nbsp;
                    <InfoCircleOutlined style={{ fontSize: "12px" }} />
                  </Popover>
                  :&nbsp;&nbsp;
                  <Switch
                    style={{ width: "10%" }}
                    checked={filterData?.normalize_data_by_volume}
                    onChange={(value) => handleFilterChange("normalize_data_by_volume", value)}
                  />
                </Col>
              </div>
              <Col span={24}>
                <PrimaryButton
                  loading={loading}
                  className={Style.applyButton}
                  style={{ width: "100%" }}
                  onClick={handleApply}
                >
                  Apply filters
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            {chartData && chartData.length > 0 && (
              <ReactEcharts
                ref={echartsInstance}
                option={options}
                onEvents={chartEvents}
                style={{ height: "595px", border: "solid 0.5px #ffffff2e", borderRadius: "10px", overflow: "hidden" }}
              />
            )}
            {!loading && chartData && chartData.length <= 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "50px",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Empty description="No Data" />
              </div>
            )}
            {loading && chartData && chartData.length <= 0 && (
              <div style={{ textAlign: "center", padding: "50px" }}>
                <Spin size="medium" />
              </div>
            )}
          </Col>
        </Row>
        {/* <Row gutter={[10, 10]}>
          <TrendLineChart
            analysisId={analysisOverview?.id}
            filterData={filterData}
            filterApplyTimestamp={filterApplyTimestamp}
          />
        </Row> */}
      </Card>

      {highlightedPost && (
        <ArticlesTableDrawer
          visible={highlightedPost}
          analysisId={analysisOverview.id}
          onClose={() => setHighlightedPost(null)}
          selectedPostID={highlightedPost}
        />
      )}
    </div>
  );
};

export default React.memo(QuadrantChartComponent);
