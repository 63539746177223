import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Empty, Spin, Anchor } from 'antd';
import Style from '../NewsAndCulture.style';
import { CopyOutlined, DownloadOutlined, UpOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import useOverViewContent from '../hooks/useOverViewContent';
import { cleanText } from 'src/lib/utils';

const { Link } = Anchor;

export const OverviewContent = ({ summaries, loading }) => {
  const {
    activeLink,
    showScrollTop,
    containerRef,
    filteredSummaries,
    handleAnchorChange,
    handleCopy,
    handleDownload,
    handleScrollTop,
  } = useOverViewContent({ summaries, loading });

  return (
    <div className={Style.tabContent}>
      <Card className={Style.summaryCard}>
        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px 0' }}>
            <Spin size="medium" />
          </div>
        ) : filteredSummaries.length === 0 ? (
          <Empty description='No Data' />
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>Overview</h1>
              <div>
                <Button
                  icon={<CopyOutlined />}
                  onClick={handleCopy}
                  className={Style.iconButton}
                />
                <Button
                  icon={<DownloadOutlined />}
                  onClick={handleDownload}
                  className={Style.iconButton}
                />
              </div>
            </div>
            <div style={{ display: 'flex', position:'relative' }}>
              <div
                style={{
                  position: 'sticky',
                  top: '20px',
                  alignSelf: 'flex-start',
                }}
              >
                <Anchor
                  affix={false}
                  showInkInFixed={true}
                  offsetTop={20}
                  onChange={handleAnchorChange}
                  activeLink={activeLink}
                >
                  {filteredSummaries.map((summary, index) => (
                    <Link
                      key={index}
                      href={`#summary-${index}`}
                      title={summary.title}
                    />
                  ))}
                </Anchor>
              </div>
              <div
                id='overview-content'
                className={Style.summaryContent}
                ref={containerRef}
              >
                {filteredSummaries.map((summary, index) => (
                  <div
                    key={index}
                    id={`summary-${index}`}
                    style={{ paddingTop: '80px', marginTop: '-80px' }}
                  >
                    <div className={Style.summaryTitle}>{summary.title}</div>
                    <ReactMarkdown className={Style.summaryText}>
                      {cleanText(summary.value)}
                    </ReactMarkdown>
                  </div>
                ))}
              </div>
            </div>
            {showScrollTop && (
              <Button
                icon={<UpOutlined />}
                onClick={handleScrollTop}
                style={{ position: 'fixed', bottom: '20px', right: '20px' }}
              />
            )}
          </>
        )}
      </Card>
    </div>
  );
};
