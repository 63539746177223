import { DislikeFilled, LikeFilled } from "@ant-design/icons";
import { Form, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { RoundedPrimaryButton, SecondaryButton } from "src/components/basic";
import { formatString } from "src/lib/utils";
import { USER_ROLE } from "../constants/constants";
import Style from './usersAndPermissions.style';

export default function AddUserForm({ addUser}) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  return (
    <>
      <div style={{ fontSize: "1.2rem", fontWeight: "600", marginBottom: "1rem" }}>
        Add user
      </div>
      <div style={{ marginBottom: "1rem" }} className={Style.form}>
        <Form
          requiredMark={false}
          ref={formRef}
          onFinish={async (val) => {
            setLoading(true);
            const payload = {
              ...val,
              action: "ADD",
            }
            await addUser(payload)
            setLoading(false);
          }}
          labelAlign="left"
              labelCol={{ span: 3 }}
        >
          <Form.Item
           label="Email*"
           name="email"
           rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              validator: (_, value) => {
                if (value) {
                  if (!!!value.trim().length) {
                    return Promise.reject(new Error("Email is required."));
                  } else {
                    const test = validateEmail(value);
                    if (test) {
                      if (value.includes('galepartners.com')) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("You can only add GALE users"))
                      }
                    } else {
                      return Promise.reject(new Error("Invalid Email"));
                    }
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
          >
            <Input placeholder="Enter email"/>
          </Form.Item>
          <Form.Item 
            label="Role*"
            name="role"
            rules={[
              {
                required: true,
                message: "Role is required",
              },
            ]}
          >
            <Select placeholder="Enter role">
            {USER_ROLE?.map((ele) => {
              return <Select.Option value={ele}>{formatString(ele)}</Select.Option>;
            })}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Row justify="end">
        <SecondaryButton loading={loading} onClick={() => formRef.current.submit()}>
          Done
        </SecondaryButton>
      </Row>
    </>
  );
}