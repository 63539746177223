import { Skeleton } from 'antd';
import React from 'react';
import ConditionalRender from 'src/components/conditionalRender';
import StreamingResponseOutput from './StreamingResponseOutput';

export default function ViewAnalysis({viewAnalysis, analysisLoading, feedbackScreen=false}) {
  return (
    <div>
      <ConditionalRender shouldRender={analysisLoading}>
        <Skeleton paragraph={{rows: 4}}/>
      </ConditionalRender>
      <ConditionalRender shouldRender={!analysisLoading}>
        <StreamingResponseOutput streamingData={viewAnalysis} analysisData={true} feedbackScreen={feedbackScreen}/>
      </ConditionalRender>
    </div>
  )
}