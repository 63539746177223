import { PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Table } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import AppUrls from 'src/constants/appUrls';
import { AUDIENCE_COLUMNS, AUDIENCE_STATUS_OPTIONS, FILTER_OPTIONS, SORT_OPTIONS } from '../../constants/constants';
import useAudienceListingHook from './useAudienceListingHook';

const { Option } = Select;

export default function AudienceListing({ history, match }) {
  const id = match.params.clientId;
  const location = useLocation();
  const [search, setSearch] = useState('');
  const { state } = location;
  const {
    loading,
    audienceListData,
    audienceListTotal,
    setCurrentPage,
    currentPage,
    setSearchName,
    filterType,
    setFilterType,
    sortBy,
    setSortBy,
    debouncedSearch,
    isAscending,
    setIsAscending,
    handleAudienceClick,
    handleAudienceEdit,
    handleAudienceArchive,
    statusFilter,
    setStatusFilter,
  } = useAudienceListingHook(id, history);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch.current = setTimeout(() => {
      if (debouncedSearch.current) {
        clearTimeout(debouncedSearch.current);
        setSearchName(e.target.value);
      }
    }, 2000);
  };

  const handleFilterTypeChange = (value) => {
    setFilterType(value);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  const handleSortOrderChange = (value) => {
    setSortBy(value);
  };

  const toggleSortOrder = () => {
    setIsAscending(!isAscending);
  };

  if (loading && audienceListData.length === 0) {
    return <LoadingSpinner centered />;
  }

  return (
    <div style={{ color: '#fff', padding: '20px' }}>

      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Col>
          <h1 style={{ color: '#fff' }}>Audience Listing</h1>
        </Col>
        <Col>
          <PrimaryButton onClick={() => history.push(AppUrls.CREATE_AUDIENCE(id))}>
            Create
            <PlusOutlined />
          </PrimaryButton>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px', alignItems: 'center' }}>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>Search by name:</label>
          <Input
            placeholder="Search by name"
            value={search}
            onChange={handleSearch}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>Filter by Status:</label>
          <Select
            placeholder="Select Status"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            style={{ width: '100%', marginRight: '10px' }}
          >
            {AUDIENCE_STATUS_OPTIONS.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>Filter by type:</label>
          <Select
            placeholder="Filter by type"
            value={filterType}
            onChange={handleFilterTypeChange}
            style={{ width: '100%' }}
          >
            {FILTER_OPTIONS.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>Sort by:</label>
          <Select
            placeholder="Sort by"
            value={sortBy}
            onChange={handleSortOrderChange}
            style={{ width: 'calc(100% - 40px)', marginRight: '10px' }}
          >
            {SORT_OPTIONS.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={2}>
          <Button
            icon={isAscending ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
            onClick={toggleSortOrder}
            style={{ width: '20px', height: '30px', marginTop: '20px' }}
          />
        </Col>
      </Row>
      <Table
        columns={AUDIENCE_COLUMNS(handleAudienceEdit, handleAudienceClick, handleAudienceArchive)}
        dataSource={audienceListData}
        className='dark-table'
        style={{ backgroundColor: '#333', color: '#fff', marginBottom: '20px' }}
        pagination={{
          pageSize: 10,
          total: audienceListTotal,
          showSizeChanger: false,
          current: currentPage,
          position: ['bottomCenter'],
          onChange: (page) => setCurrentPage(page)
        }}
        loading={loading && audienceListData.length > 0}
      />
    </div>
  );
}