import { css } from '@linaria/core';

const form = css`
  .ant-input, .ant-input-number  {
    width: 100%;
  }
`;

const advancedSettingContainer = css`
  margin-top: 2rem;
`;

const addRowContainer = css`
  margin-bottom: 1rem;
  font-size: 16px;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
`;

const addSchemaHeader = css`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const columnsHeader = css`
color: gray;
margin-bottom: 1rem
`;

export default {
  form,
  advancedSettingContainer,
  addRowContainer,
  addSchemaHeader,
  columnsHeader
};