import React from "react";
import { Select, Input } from "antd";
import Style from "./instagramQueryBuilder.style";

const InstagramQueryBuilder = ({ instaFormData, setInstaFormData, queryData }) => {
    return (
        <div className={Style.queryBuilder}>
            <div className={Style.articleNumContainer}>
                <label className={Style.articleNumLabel}>No. of Articles: </label>
                <Input
                    placeholder="Enter No. of Articles"
                    className={Style.articleNumInput}
                    value={instaFormData.max_count}
                    onChange={(e) => setInstaFormData({ ...instaFormData, max_count: e.target.value })}
                />
            </div>
            <div className={Style.queryBuilderContainer}>
                <div className={Style.fieldsContainer}>
                    <label className={Style.queryBuilderFieldName}>
                        Instagram Handle:
                    </label>
                    <Select
                        mode="tags"
                        maxTagCount={3}
                        style={{ width: "100%" }}
                        value={instaFormData.handle}
                        placeholder="Instagram Handle"
                        onChange={(value) => {
                            setInstaFormData({ ...instaFormData, handle: value });
                        }}
                    />
                </div>
                <div className={Style.fieldsContainer}>
                    <label className={Style.queryBuilderFieldName}>
                        Hashtags:
                    </label>
                    <Select
                        mode="tags"
                        maxTagCount={3}
                        style={{ width: "100%" }}
                        value={instaFormData.hashtag}
                        placeholder="Tags Mode"
                        onChange={(value) => {
                            const sanitizedValues = value.map(tag => tag.replace(/#/g, ''));
                            setInstaFormData({ ...instaFormData, hashtag: sanitizedValues });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default InstagramQueryBuilder;
