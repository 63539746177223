import React from "react";
import Style from "./clusterDetails.style";
import { Col, Row } from "antd";
import ShowClusterCards from "./showClusterCards";
import MarkdownRender from "src/modules/ConversationContainer/MarkdownRender";
import Markdown from "react-markdown";
import ConditionalRender from "src/components/conditionalRender";

const ClusterDetails = ({ tab, data, clusterData }) => {
  const renderClusterDetails = (tab, item) => {
    
    let demographicFeatures = item?.persona_details?.key_demographic_features;
    let behaviouralFeatures = item?.persona_details?.key_behavioural_features;
    let crmFeatures = item?.persona_details?.key_crm_features || [];
    try {
      demographicFeatures = eval(`(${item?.persona_details?.key_demographic_features})`);
      behaviouralFeatures = eval(`(${item?.persona_details?.key_behavioural_features})`);
      crmFeatures = eval(`(${item?.persona_details?.key_crm_features})`);
    } catch (error) {
      
    }
    
    if (tab === "demographic") {
      return (
        <div className={Style.clusterContainer}>
          <div>
            <b>Key features:</b>
            <div className={Style.featureList}>
              <ul>
                {demographicFeatures?.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <b>Persona:</b>
            <div className={Style.detailsContainer}><Markdown>{item?.cluster_description}</Markdown></div>
          </div>
        </div>
      );
    } else if (tab === "behavioural") {
      return (
        <div className={Style.clusterContainer}>
          <div>
            <b>Key features:</b>
            <div className={Style.featureList}>
              <ul>
                {crmFeatures?.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
          <ConditionalRender shouldRender={clusterData?.clusters_type === 'CRM'}>
          <div>
            <b>Description:</b>
            <div className={Style.detailsContainer}><Markdown>{item?.persona_details?.crm_description}</Markdown></div>
          </div>
          </ConditionalRender>
        </div>
      );
    } else if (tab === "activation") {
      return (
        <div className={Style.clusterContainer}>
          <div>
            <b>Key features</b>
            <div className={Style.featureList}>
              <ul>
                <li><Markdown>{`__Product & Service Usage:__  ${item?.persona_details?.product_n_services}`}</Markdown></li>
                <li><Markdown>{`__Attitude & Opinion__: ${item?.persona_details?.attitude_n_opinion}`}</Markdown></li>
                <li><Markdown>{`__Shopping & Spending behaviour:__ ${item?.persona_details?.shopping_n_spending}`}</Markdown></li>
                <li><Markdown>{`__Media & Technology Usage:__ ${item?.persona_details?.media_n_technology}`}</Markdown></li>
                <li><Markdown>{`__Lifestyle Segmentation:__ ${item?.persona_details?.lifestyle_segment}`}</Markdown></li>
              </ul>
            </div>
          </div>
          <div>
            <b>
              Persona: 
            </b>
            <div className={Style.detailsContainer}>
              <Markdown>{item?.persona_details?.activation_description}</Markdown>
            </div>
          </div>
          <div>
            <b>
              Activation Platforms: 
            </b>
            <div className={Style.detailsContainer}>
              {item?.persona_details?.activation_platforms}
            </div>
          </div>
        </div>
      )
    } else if (tab === "brand") {
      return (
        <div className={Style.clusterContainer}>
          <div>
            <b>
              Persona: 
            </b>
            <div className={Style.detailsContainer}>
              <Markdown>{item?.persona_details?.brand_engagement}</Markdown>
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }
  };
  return (
    <div>
      <Row gutter={[50, 20]}>
        {data.map((item) => {
          let title = item?.cluster_name;
          let extra = `[ ${item?.population_percentage || 0}% of Population vs ${item?.cluster_percentage || 0}% of Customers ]`;
          if (clusterData?.clusters_type === 'CRM') {
            extra = `[ ${item?.revenue_percentage || 0}% of Revenue from ${item?.cluster_percentage || 0}% of Customers ]`;
          }
          return (
            <Col xs={24} md={12}>
              <ShowClusterCards title={title} extra={extra}>
                {renderClusterDetails(tab, item)}
              </ShowClusterCards>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ClusterDetails;
