import { useState, useMemo, useCallback } from "react";

export const useVennDiagram = (data) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isViewArticles, setIsViewArticles] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: "venn",
        backgroundColor: "#262626",
      },
        title: { text: null },
      series: [
        {
          name: "Venn Diagram",
          data: data.map((item) => ({
            sets: item.sections,
            value: item.overlapping_data_count,
            name: item.sections.join(" & "),
            customData: item.overlapping_data,
          })).filter((item) => item.value > 0),
          color: "#FFFFFF",
        },
      ],
      tooltip: {
        headerFormat: "",
        backgroundColor: "#262626",
        style: {
          color: "#FFFFFF", // Set the tooltip text color to white
        },
        pointFormat:
          '<span style="color:{point.color}">\u2022</span> {point.name}: <b>{point.value}</b><br/>',
      },
      plotOptions: {
        venn: {
          dataLabels: {
            enabled: true,
            format: "{point.name}",
            style: {
              color: "#FFFFFF", // Set the data label color to white
            },
          },
        },
      },
    };
  }, [data]);

  const handleSetClick = useCallback((point) => {
    setDrawerContent(point.customData);
    setIsDrawerVisible(true);
  }, []);

  const handleSetHover = useCallback((point) => {
    const topics = Object.entries(point.customData)
      .slice(0, 5)

    setHoverInfo({
      count: point.value,
      topics,
    });
  }, []);

  const handleSetMouseOut = useCallback(() => {
    setHoverInfo(null);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsDrawerVisible(false);
    setDrawerContent(null);
  }, []);

  return {
    chartOptions,
    isDrawerVisible,
    drawerContent,
    hoverInfo,
    handleSetClick,
    handleSetHover,
    handleSetMouseOut,
    handleDrawerClose,
    isViewArticles,
    setIsViewArticles,
  };
};
