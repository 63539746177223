import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  FileOutlined,
  RightOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Progress, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils";
import * as Styles from "./styles";

const statusConfig = {
  DRAFT: {
    icon: <FileOutlined />,
    color: "rgba(245, 121, 0, 0.8)",
    text: "Draft",
  },
  SCHEDULED: {
    icon: <ClockCircleOutlined />,
    color: "rgba(0, 172, 193, 0.8)",
    text: "Scheduled",
  },
  RUNNING: {
    icon: <SyncOutlined spin />,
    color: "rgba(0, 156, 255, 0.9)",
    text: "Running",
  },
  COMPLETED: {
    icon: <CheckOutlined />,
    color: "rgba(50, 205, 50, 0.8)",
    text: "Completed",
  },
  FAILED: {
    icon: <CloseOutlined />,
    color: "rgba(220, 20, 60, 0.8)",
    text: "Failed",
  },
};

const TaskListItem = ({ task, clientId }) => {
  const { id, name, status, progress, data_source, created_on } = task;
  const { icon, color, text } = statusConfig[status];

  return (
    <div
      className={Styles.listItemContainer}
      style={{ borderLeftColor: statusConfig[status].color }}
    >
      <div className={Styles.nameSection}>
        <Tooltip title={name}>
          <span className={Styles.taskName}>{name}</span>
        </Tooltip>
      </div>
      <div className={Styles.statusSection}>
        <span className={Styles.statusText} style={{ color }}>
          {icon} {text}
        </span>
      </div>
      <div className={Styles.progressSection}>
        <Progress
          percent={progress}
          strokeColor="var(--color-link)"
          trailColor="rgba(255,255,255,0.1)"
          status={status === "RUNNING" ? "active" : "normal"}
          format={(percent) => (
            <span className={Styles.metaInfo}>{`${percent}%`}</span>
          )}
        />
      </div>
      <div className={Styles.dataSourceSection}>
        <span className={Styles.metaInfo}>{data_source.type}</span>
      </div>
      <div className={Styles.dateSection}>
        <span className={Styles.metaInfo}>{formatDate(created_on)}</span>
      </div>
      <div className={Styles.actionSection}>
        <Link
          to={`/client/${clientId}/labeler/${id}`}
          className={Styles.viewDetailsLink}
        >
          View <RightOutlined />
        </Link>
      </div>
    </div>
  );
};

export default TaskListItem;
