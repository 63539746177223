// src/modules/newsAndCulture/components/nodePerformance/nodePerformance.style.js
import { css } from "@linaria/core";

const nodePerformanceContainer = css`
  padding: 16px;
`;

const platformDropdownFilter = css`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;

export default {
  nodePerformanceContainer,
  platformDropdownFilter,
};
