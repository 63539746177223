import { Checkbox, Col, Drawer, Modal, Row, Tooltip } from "antd";
import React from "react";
import Style from "./clientSpaceSelectApp.style";
import useClientSpaceSelectAppsHook from "./useClientSpaceSelectAppsHook";
import LoadingSpinner from "src/components/loadingSpinner";
import { SettingOutlined } from "@ant-design/icons";
import AppConfigModal from "./AppConfigModal";
import AddUserToAppForm from "./AddUserToAppForm";
import ClientSpaceAppSettings from "./ClientSpaceAppSettings";
import ConditionalRender from "src/components/conditionalRender";

const ClientSpaceSelectApps = ({ metaData, clientId }) => {
  const {
    loading,
    microApps,
    Images,
    CardTitle,
    data,
    updateData,
    setAppConfigTab,
    appConfigTab,
    updateApps,
    configureLoading,
    addUserModal,
    setAddUserModal,
    appSettingsDrawer,
    setAppSettingsDrawer,
    addUpdateUserToMicroApp,
    deleteUserFromMicroApp
  } = useClientSpaceSelectAppsHook({ metaData, clientId });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <Row gutter={[20, 20]}>
        {microApps?.map((elem) => {
          return (
            <Col>
              <div className={Style.microCardContainer}>
                <div className={Style.microAppLabelContainer}>
                  <img src={Images[elem]?.icon} height={30} width={30} />
                  <div className={Style.configContainer}>
                    <Checkbox
                      disabled={configureLoading}
                      checked={data[elem]?.is_active || false}
                      onChange={(e) => updateApps(elem, "enabled", e.target.checked)}
                    />
                    <ConditionalRender shouldRender={data?.[elem]?.is_active}>
                      <Tooltip title="App Settings">
                        <SettingOutlined
                          className={`${!data[elem]?.is_active && Style.disabledContainer}`}
                          onClick={() => {
                            setAppSettingsDrawer({
                              visible: true,
                              data: {
                                appType: elem,
                                data: data[elem],
                              },
                            });
                          }}
                        />
                      </Tooltip>
                    </ConditionalRender>
                  </div>
                </div>
                {CardTitle?.[elem]}
              </div>
            </Col>
          );
        })}
      </Row>
      <Modal
        title="App Configuration"
        maskClosable={true}
        destroyOnClose
        open={appConfigTab.visible}
        onCancel={() => setAppConfigTab({ visible: false, data: {} })}
        footer={null}
        width="50%"
      >
        <AppConfigModal appConfig={appConfigTab} updateApps={updateApps} configureLoading={configureLoading} />
      </Modal>
      <Modal
        title="Add User"
        maskClosable={false}
        destroyOnClose
        open={addUserModal.visible}
        onCancel={() => setAddUserModal({ visible: false, data: {} })}
        footer={null}
      >
        <AddUserToAppForm
          metaData={metaData}
          setAddUserModal={setAddUserModal}
          data={addUserModal.data}
          addUpdateUserToMicroApp={addUpdateUserToMicroApp}
        />
      </Modal>
      <Drawer
        open={appSettingsDrawer.visible}
        onClose={() => {
          setAppSettingsDrawer({
            visible: false,
            data: {},
          });
        }}
        title={CardTitle?.[appSettingsDrawer.data?.appType]}
        width="80%"
        destroyOnClose
      >
        <ClientSpaceAppSettings
          data={data}
          metaData={metaData}
          elem={appSettingsDrawer?.data?.appType}
          updateData={updateData}
          setAddUserModal={setAddUserModal}
          setAppConfigTab={setAppConfigTab}
          configureLoading={configureLoading}
          updateApps={updateApps}
          addUpdateUserToMicroApp={addUpdateUserToMicroApp}
          deleteUserFromMicroApp={deleteUserFromMicroApp}
        />
      </Drawer>
    </div>
  );
};

export default ClientSpaceSelectApps;
