import { Select } from "antd";
import EChartsReact from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { CHART_FILTER_OPTIONS } from "src/modules/audienceTool/audienceManager/constants/constants";

const { Option } = Select;

export default function FunnelChart({ response }) {
  const [filterType, setFilterType] = useState("funnel_stage");
  const [chartOption, setChartOption] = useState({});

  // JS function to convert this into e charts data format
  function getFunnelChartData(data) {
    let funnelData = [];

    // Aggregate data based on the filter type
    let aggregatedData = data.reduce((acc, item) => {
      let key = item[filterType];
      if (!acc[key]) {
        acc[key] = 0;
      }
      item.weekly_plan.forEach((week) => {
        acc[key] += week.budget;
      });
      return acc;
    }, {});

    // Convert aggregated data to funnel chart format
    for (let key in aggregatedData) {
      funnelData.push({ name: key, value: aggregatedData[key] });
    }

    // Sort data in descending order
    funnelData.sort((a, b) => b.value - a.value);

    return funnelData;
  }

  // Filter the data based on selected filter type
  const filteredData = filterType
    ? response.media_plan.filter((item) => item[filterType])
    : response.media_plan;

  useEffect(() => {
    const funnelData = getFunnelChartData(filteredData);

    const option = {
      title: {
        text: `${
          filterType
            ? `${
                CHART_FILTER_OPTIONS.filter(
                  (item) => item.value === filterType
                )[0].label
              }`
            : ""
        }`,
        left: "center",
        top: 20,
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          return `${params.name}: $${params.value.toLocaleString()}`;
        },
      },
      legend: {
        orient: "horizontal",
        bottom: 0, // Position the legend at the bottom
        data: funnelData.map((item) => item.name),
        textStyle: {
          color: "#fff",
        },
      },
      series: [
        {
          name: "Budget",
          type: "funnel",
          left: "10%",
          top: 60,
          bottom: 60,
          width: "80%",
          min: 0,
          max: funnelData.length > 0 ? funnelData[0].value : 0,
          minSize: "0%",
          maxSize: "100%",
          sort: "descending",
          label: {
            show: true,
            position: "inside",
            formatter: (params) => {
              return `${params.name}: $${params.value.toLocaleString()}`;
            },
            textStyle: {
              color: "#fff",
            },
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid",
            },
          },
          itemStyle: {
            borderColor: "#262626",
            borderWidth: 1,
            color: (params) => {
              // Custom color gradient for the funnel
              const colors = [
                "#5470C6",
                "#91CC75",
                "#FAC858",
                "#EE6666",
                "#73C0DE",
                "#3BA272",
                "#FC8452",
                "#9A60B4",
                "#EA7CCC",
              ];
              return colors[params.dataIndex % colors.length];
            },
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: funnelData,
        },
      ],
    };

    setChartOption(option);
  }, [filterType]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          margin: "1rem",
        }}
      >
        <Select
          placeholder="Select Filter Type"
          style={{ width: 200 }}
          value={filterType}
          onChange={(value) => {
            setFilterType(value);
          }}
        >
          {CHART_FILTER_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <EChartsReact
        option={chartOption}
        notMerge={true}
        style={{ height: "460px", width: "100%" }}
      />
    </div>
  );
}
