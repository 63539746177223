import { useMemo } from "react";
import { Typography } from "antd";
import GlobalTag from 'src/components/globalTag/GlobalTag';
import { TAG_COLOR_NAME } from 'src/components/globalTag/globalTagConstant';

const { Paragraph } = Typography;

const useDataSourceContent = (data, loading) => {
  const excludedKeys = useMemo(
    () => [
      "id",
      "has_image",
      "interaction",
      "ai_sentiment",
      "ai_knowledge_graphs",
      "publish_date",
    ],
    []
  );

  const order = useMemo(
    () => [
      "link",
      "headline",
      "excerpt",
      "impact_on_client",
      "ai_summary",
      "ai_themes",
      "ai_topics",
      "ai_entities",
      "ai_keywords",
    ],
    []
  );

  const renderTags = (items) => (
    <div style={{ margin: '10px 0', display:'flex', gap:3 }}>
      {items.map((item, index) => (
        <GlobalTag color={TAG_COLOR_NAME.BLUE} key={index} style={{ margin: '2px' }}>
          {item}
        </GlobalTag>
      ))}
    </div>
  );

  const fieldRenderers = {
    // excerpt: (value, item) => (
    //   <div>
    //     {value.slice(0, 100)}...
    //     <a
    //       href={item.processed_data.link}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Read more
    //     </a>
    //   </div>
    // ),
    ai_summary: (value) => (
      <Paragraph style={{ color: "#fff" }}>{value}</Paragraph>
    ),
  };

  const renderNestedObject = (obj) => {
    return (
      <div>
        {Object.entries(obj).map(([subKey, subValue], index) => (
          <div key={index}>
            <strong style={{ color: "#fff" }}>
              {subKey.replace(/_/g, " ").toUpperCase()}:
            </strong>{" "}
            {typeof subValue === "object" && subValue !== null
              ? renderNestedObject(subValue)
              : subValue}
          </div>
        ))}
      </div>
    );
  };

  const getFieldValue = (key, value, item) => {
    if (fieldRenderers[key]) {
      return fieldRenderers[key](value, item);
    } else if (Array.isArray(value)) {
      return renderTags(value);
    } else if (typeof value === "object" && value !== null) {
      return renderNestedObject(value);
    }
    return value;
  };

  const processedData = useMemo(() => {
    if (loading || !Array.isArray(data) || data.length === 0) {
      return [];
    }

    return data.map((item, index) => {
      const orderedData = Object.entries(item.processed_data)
        .filter(([key, value]) => value && !excludedKeys.includes(key))
        .sort(([a], [b]) => {
          const indexA = order.indexOf(a);
          const indexB = order.indexOf(b);
          if (indexA === -1 && indexB === -1) return 0;
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        });

      return (
        <div key={index} style={{ marginBottom: '20px', padding: '20px' }}>
          {orderedData.map(([key, value]) => (
            <div
              key={key}
              style={{
                marginBottom: "16px",
                borderBottom: "1px solid #e8e8e8",
                paddingBottom: "8px",
              }}
            >
              <Typography.Text
                strong
                style={{ display: "block", marginBottom: "4px", color: "#fff" }}
              >
                {key === "link" ? (
                  <a href={item.processed_data.link}> View Article</a>
                ) : (
                  key.replace(/_/g, " ").toUpperCase()
                )}
              </Typography.Text>
              {key !== "link" ? (
                <Typography.Text style={{ color: "#fff" }}>
                  {getFieldValue(key, value, item)}
                </Typography.Text>
              ) : null}
            </div>
          ))}
        </div>
      );
    });
  }, [data, loading, excludedKeys, order]);

  return { processedData };
};

export default useDataSourceContent;
