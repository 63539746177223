import axios from 'src/http/index';
import ApiUrls from 'src/constants/apiUrls';
import Cookie from "src/lib/cookie";

export default class MicroAppServices {
  static getDashSso = async (clientId) => {
    const res = await axios.get(ApiUrls.GET_DASH_SSO(clientId));
    return res?.data;
  };

  static getMptSso = async (clientId) => {
    const res = await axios.get(ApiUrls.GET_MPT_SSO(clientId));
    return res?.data;
  };
}
