import { css } from '@linaria/core';

const form = css`
  
`;

const card = css`
  &.ant-card-bordered {
    border-radius: 10px;
    .ant-card-body {
      padding: 24px 14px;
      max-height: calc(100vh - 238px);
      overflow-y: auto;
    }
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const label = css`
  color: gray;
`;


const disabled = css`
  opacity: 0.5;
`;

const clusterNumberContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-form-item { 
    margin-bottom: 0.8rem;
  }
  .ant-slider-rail {
  height: 9px;
  }
  .ant-slider-track {
    height: 9px;
  }
  .ant-slider-handle {
    width: 18px;
    height: 18px;
  }
  .ant-slider-dot {
    width: 14px;
    height: 14px;
  }
    .ant-input-number {
      padding: 8px 16px !important;
    }
`;

const progressBar = css`
  width: 98%
  &.ant-progress-success-bg, .ant-progress-bg  {
  height: 25px !important;
  }
  .ant-progress-status-success .ant-progress-text {
  color: white;
  }
  .ant-progress-success-bg, .ant-progress-bg {
    border-radius: 0;
  }
  .ant-progress-inner {
    border-radius: 0;
    background-color: var(--color-full-black);
  }
`;

const featureContainer = css`
  height: calc(100vh - 546px);
  overflow-y: auto;
  margin-top: 1rem;
  overflow-x: hidden;
  margin-left: 1rem;
`;

const polarityLegend = css`
  padding: 4px 14px;
  background-color: #91dd91;
`;

const disabledFeatureContainer = css`
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
`;

export default {
  form,
  card,
  label,
  disabled,
  clusterNumberContainer,
  progressBar,
  featureContainer,
  polarityLegend,
  disabledFeatureContainer
}