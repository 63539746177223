import { Button, Checkbox, Input } from "antd";
import { useEffect, useState } from "react";
import Style from "../NewsAndCulture.style";

export const CustomCheckboxFilterDropdown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters,
}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchText, setSearchText] = useState("");

    const handleCheckboxChange = (e, value) => {
        if (e.target.checked) {
            setSelectedItems((prevItems) => [...prevItems, value]);
        } else {
            setSelectedItems((prevItems) =>
                prevItems.filter((item) => item !== value)
            );
        }
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        setSelectedKeys(selectedItems);
    }, [selectedItems, setSelectedKeys]);

    const filteredFilters = filters.filter((filter) =>
        filter.text.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div>
            <Input
                placeholder="Search in filters"
                value={searchText}
                onChange={handleSearch}
                className={Style.searchBoxStyle}
            />
            <div className={Style.checkBoxStyle}>
                {filteredFilters.map((filter) => (
                    <Checkbox
                        key={filter.value}
                        onChange={(e) => handleCheckboxChange(e, filter.value)}
                        checked={selectedItems.includes(filter.value)}
                    >
                        {filter.text}
                    </Checkbox>
                ))}
            </div>
            <div
                className={Style.bottomButtonsStyle}
            >
                <Button
                    onClick={() => {
                        setSelectedItems([]);
                        clearFilters();
                        confirm();
                    }}
                    className="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        confirm();
                        clearFilters();
                    }}
                    className="small"
                    style={{ width: 90 }}
                >
                    OK
                </Button>
            </div>
        </div>
    );
};
