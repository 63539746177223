import { Col, Form, InputNumber, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CustomGptService } from "../services/customGptServices";
import Style from "./createForm.style";
import { AGENT_VALUE_TO_TEXT } from "src/modules/CustomGpt/constants/constants";

const LLM_AGENTS_LIMITS = {
  "o1-preview": ["BASE_REACT_AGENT"],
  "o1-mini": ["BASE_REACT_AGENT"],
  "gemini-1.0-pro": ["BASE_REACT_AGENT"],
  "gemini-1.5-pro-preview-0514": ["BASE_REACT_AGENT"],
  "gemini-1.5-flash-preview-0514": ["BASE_REACT_AGENT"],
};

export default function AgentConfig({ metaData, chosenLLM, formRef }) {
  const [options, setOptions] = useState([]);


  useEffect(() => {
    const form = formRef.current;
    const chosenAgentType = form.getFieldValue(["agent_config", "agent_type"]);
    setOptions(LLM_AGENTS_LIMITS[chosenLLM] || metaData?.agent_config?.agent_type?.choices || []);
    if (
      LLM_AGENTS_LIMITS[chosenLLM] &&
      !LLM_AGENTS_LIMITS[chosenLLM].includes(chosenAgentType)
    ) {
      const newAgentType = LLM_AGENTS_LIMITS[chosenLLM][0];
      form.setFieldsValue({ agent_config: { agent_type: newAgentType } });
    }
  }, [formRef, chosenLLM]);

  return (
    <div>
      <div className={Style.advancedSettingHeader}>Agent configuration</div>
      <Row gutter={[30, 0]} style={{ margin: "0 6px" }}>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["agent_config", "agent_type"]}
            rules={CustomGptService.validations({ required: true }, "Model name")}
            label="Agent type*"
            initialValue={options[0]}
          >
            <Select optionLabelProp="label">
              {options.map((item, idx) => (
                <Select.Option
                  key={`agent-config-${idx}`}
                  value={item}
                  label={AGENT_VALUE_TO_TEXT[item] ? AGENT_VALUE_TO_TEXT[item]["text"] : item}
                >
                  {AGENT_VALUE_TO_TEXT[item] ? AGENT_VALUE_TO_TEXT[item]["text"] : item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["agent_config", "max_iterations"]}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Maximum steps ",
              metaData?.agent_config?.max_iterations,
            )}
            label="Max iterations* (from 5 - 30)"
          >
            <InputNumber min={5} max={30} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["agent_config", "chat_history_window"]}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Chat history window",
              metaData?.agent_config?.chat_history_window,
            )}
            label="Chat history window* (from 0 - 20)"
          >
            <InputNumber min={0} max={20} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={["agent_config", "intermediate_steps_chat_history_window"]}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              "Intermediate steps chat history window",
              metaData?.agent_config?.intermediate_steps_chat_history_window,
            )}
            label="Number of previous analysis in chat history* (from 0 - 10)"
          >
            <InputNumber min={0} max={10} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        {formRef?.current?.getFieldValue(["agent_config", "agent_type"]) === "BASE_PLANNER_AGENT" && (
          <Col xl={12} xs={24}>
            <Form.Item
              name={["agent_config", "custom_agent_settings", "planner_llm_model"]}
              label="Planner model name"
              rules={CustomGptService.validations({ required: false }, "Model name")}
              defaultValue={null}
            >
              <Select allowClear>
                {metaData?.llm_config?.model_name?.choices?.map((item, idx) => {
                  return (
                    <Select.Option value={item} key={`planner-meta-data-${idx}`}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </div>
  );
}
