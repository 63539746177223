/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { css } from '@linaria/core';

const SwitchContainer = css`
  border-radius: 40px;
  border: 1px solid var(--color-border);
  padding: 0.3rem 1rem;
  display: inline-block;
  cursor: pointer;
  background-color: var(--color-white);
  transition: color 0.2s, background-color 0.2s;
`;

const SwitchSelected = css`
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-white);
`;

const disabledClass = css`
  cursor: not-allowed;
  background-color: var(--color-light-grey-bg);
  color: var(--color-40-black);
  border-color: var(--color-40-black);
`;

function Switch({
  isSelected,
  onToggle,
  children,
  className,
  disabled,
}) {
  return (
    <div
      aria-disabled={disabled}
      className={`${SwitchContainer} ${isSelected ? SwitchSelected : ''} ${disabled ? disabledClass : ''} ${className || ''}`}
      onClick={disabled ? undefined : onToggle}
    >
      {children}
    </div>
  );
}

export default Switch;
