import { notification } from 'antd';
import { useState, useEffect } from 'react';
import DatasetServices from '../services/datasetServices';

export default function useDatasetHooks({ datasetId, appId, onClose }) {
    const [loading, setLoading] = useState(false);
    const [datasetData, setDatasetData] = useState(datasetId?{}:{
        'name': null,
        'is_conversation_based': true,
        'data': [{'question': '', 'expected_answer': '', 'expected_intermediate_step': null}],
    });

    const createOrEditDataSet = async (payload) => {
        setLoading(true);
        try {
          if(datasetId) {
            payload['id'] = datasetId;
            const res = await DatasetServices.editDataset(appId, payload);
          } else {
            const res = await DatasetServices.createDataset(appId, payload);
          }
          setLoading(false);
          notification.success({
            duration: 5,
            message: datasetId? "Dataset Updated Successfully": "Dataset Created Successfully",
            placement: "bottomRight",
          });
          onClose();
        } catch (error) {
          setLoading(false);
          notification.error({
            duration: 5,
            message: datasetId?"Failed to Edit Dataset": "Failed to Create Dataset",
            placement: "bottomRight"
          });
        }
    };

    const getDataSet = async () => {
      setLoading(true);
      try {
        const res = await DatasetServices.getDataset(datasetId);
        setDatasetData(res?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        notification.error({
          duration: 5,
          message: "Failed to Load Dataset",
          placement: "bottomRight"
        });
      }
  };

  useEffect(() => {
    if(datasetId){
      getDataSet();
    }
  }, [])

  return {
        loading,
        datasetData,
        createOrEditDataSet,
    }
};
