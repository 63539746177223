import { notification } from 'antd';
import { useState, useRef } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { SqlToolApiServices } from '../sqlToolApiServices';

export default function useSqlSettingsHook({ selectedConnection, sqlData, mAiApp }) {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);

  let initialValues = {
    max_limit_per_query: 20,
    query_timeout: 300,
    max_column_unique_values_query_limit: 100,
    max_column_unique_values_fetch_limit: 100,
  }
  if (sqlData?.settings) {
    initialValues = {...initialValues, ...sqlData?.settings}
  }

  const saveConnectionLevelSetting = async (val) => {
    try {
      setLoading(true);
      const payload = {
        connection_id: selectedConnection,
        settings: {
          ...val
        }
      }
      const res = await SqlToolApiServices.postSqlConnectionLevelData(mAiApp, payload);
      notification.success({
        message: 'Settings configured successfully',
        duration: 5,
        placement: 'bottomRight'
      })
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 6,
        placement: 'bottomRight'
      })
    }
  }
  

  return {
    formRef,
    loading,
    initialValues,
    saveConnectionLevelSetting
  }
}